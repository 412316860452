import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { CatalogBulkPrice } from '../../bean/catalog-bulk-price';
import { CatalogContract } from '../../bean/catalog-contract';
import { AppPopupService } from '../app-popup/app-popup.service';
import { TablePluginData } from '../table/interface/table-plugin-data';
import { TableResponseModel } from '../table/model/table-response-model';
import { AppPopupBulkPriceCatalogDetailInfoComponent } from './app-popup-bulk-price-catalog-detail-info.component';

@Component({
  templateUrl: './app-popup-item-contract-catalog-detail-info.component.html'
})
export class AppPopupItemContractCatalogDetailInfoComponent extends BaseModuleComponent {
  @Input() catalogContractList: CatalogContract[];
  @Input() catalogBulkPriceList: CatalogBulkPrice[];
  @Input() selectedCatalogContract: CatalogContract;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public CURRENCY_DIGITS_INFO: string;
  public tableResponse: TableResponseModel<CatalogContract>;

  constructor(
    translateService: TranslateService,
    public currency: CurrencyPipe,
    public datePipe: DatePipe,
    public appPopupService: AppPopupService,
    public activeModal: NgbActiveModal
  ) {
    super('app-catalog-detail-info', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setSelectedRecord();
    this.setStateReady();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'contract.code',
        header: 'table.column.contractNumber'
      },
      {
        field: 'contract.startDate',
        header: 'table.column.contractPeriode',
        plugins: {
          name: 'custom-plugin',
          after: (tablePluginData: TablePluginData): void => {
            if (tablePluginData.value) {
              const startDate = this.datePipe.transform(
                tablePluginData.row.record.contract.startDate,
                this.global.config.parameterModel.dateFormatShort
              );
              const endDate = this.datePipe.transform(
                tablePluginData.row.record.contract.endDate,
                this.global.config.parameterModel.dateFormatShort
              );
              tablePluginData.element.innerText = startDate + ' - ' + endDate;
            }
          }
        }
      },
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'remainingQuantity',
        header: 'table.column.agreementQuantity'
      },
      {
        field: 'isBulkPrice',
        header: 'table.column.bulkPrice',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): void => {
              if (tablePluginData.value) {
                if (tablePluginData.row.record.isBulkPrice) {
                  tablePluginData.element.innerText = 'Yes ';
                } else {
                  tablePluginData.element.innerText = 'No ';
                }
              }
            }
          },
          {
            name: 'icon',
            className: 'pir pi-external-link',
            onClick: this.doViewBulkPrice.bind(this),
            isShowIconFn: this.isShowIconBulkPrice.bind(this),
            tooltip: this.translateService.instant(
              'app-catalog-detail-info.tooltip.clickToViewBulkPrice'
            )
          }
        ]
      },
      {
        field: 'price',
        header: 'table.column.price',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      }
    ]);

    this.catalogContractList.forEach((catalogContract: any) => {
      catalogContract['catalogBulkPriceList'] = this.catalogBulkPriceList
        ? this.catalogBulkPriceList.filter(
            cbulkPrice => cbulkPrice.catalogContract.id === catalogContract.id
          )
        : [];
    });
    this.tableResponse.setRecordList(this.catalogContractList);
  }

  public doViewBulkPrice(catalogContract: CatalogContract): void {
    this.appPopupService.open(AppPopupBulkPriceCatalogDetailInfoComponent, {
      catalogContract
    });
  }

  public isShowIconBulkPrice(catalogContract: CatalogContract): boolean {
    return catalogContract.isBulkPrice;
  }

  public setSelectedRecord(): void {
    if (this.catalogContractList && this.catalogContractList.length !== 0) {
      /*const lowestPrice: number = Math.min.apply(
        Math,
        this.catalogContractList.map(catalogContract => {
          return catalogContract.price;
        })
      );*/

      // default radio button terpilih
      /*const selectedRowList = this.selectedCatalogContract ?
        [this.selectedCatalogContract] :
       ( this.catalogContractList.filter(
          catalogContract =>
            catalogContract.organization.id ===
            this.global.userSession.user.organization.id
        ) ||
        this.catalogContractList.filter(
          catalogContract => catalogContract.price === lowestPrice
        ));*/

      // memindahkan yg terpilih ke paling atas

      this.selectedCatalogContract['catalogBulkPriceList'] =
        this.catalogBulkPriceList.filter(
          cbulkPrice =>
            cbulkPrice.catalogContract.id === this.selectedCatalogContract.id
        );

      const selectedIndex = this.catalogContractList.findIndex(
        cc => cc.id === this.selectedCatalogContract.id
      );
      this.catalogContractList.splice(selectedIndex, 1);
      this.catalogContractList.unshift(this.selectedCatalogContract);

      this.tableResponse.setRecordList(this.catalogContractList);

      const selectedRowList = this.selectedCatalogContract
        ? [this.selectedCatalogContract]
        : [];
      this.tableResponse.setSelectedRowList(selectedRowList);
    }
  }

  public setDecimal(field: number): string {
    if (!field) {
      return this.currency.transform(0, '', '', this.CURRENCY_DIGITS_INFO);
    } else {
      return this.currency.transform(field, '', '', this.CURRENCY_DIGITS_INFO);
    }
  }

  public doChoose(): void {
    if (this.tableResponse.selectedRow.getSelectedRecordList().length !== 0) {
      this.onChange.emit(
        this.tableResponse.selectedRow.getSelectedRecordList()[0]
      );
      this.activeModal.dismiss();
    }
  }
}
