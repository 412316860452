import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';

@Component({
  templateUrl: './attendance-form-information.component.html',
  styleUrls: ['./attendance-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttendanceFormInformationComponent
  extends BaseModuleComponent
  implements OnInit
{
  public aanwijzingId: string;
  public isSuccessSubmit: boolean;
  public icon: string;
  public message: string;

  constructor(
    translateService: TranslateService,
    public activatedRoute: ActivatedRoute
  ) {
    super('attendance-form', translateService);
  }

  public onInit(): void {
    this.getRouterParam();
    this.setStateReady();
  }

  public getRouterParam(): void {
    this.aanwijzingId = this.global.routerParams.get('aanwijzingId');
    this.icon = this.global.routerParams.get('icon');
    this.isSuccessSubmit = this.global.routerParams.get('isSuccessSubmit');
    this.message = this.global.routerParams.get('message');
  }

  public doShowAttendanceList(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('aanwijzingId', this.aanwijzingId);
    this.router.navigate([
      '/portal/aanwijzing-attendance-form/participant-list'
    ]);
  }
}
