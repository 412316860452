import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Rfi } from '../../core/bean/rfi';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './rfi.component.html'
})
export class RfiComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public readonly urlRouterNavigateToAddRequestForInformation = '/pages/request-for-information/add';
  public readonly urlRouterNavigateToEditRequestForInformation = '/pages/request-for-information/edit';
  public readonly urlRouterNavigateToDetailRequestForInformation = '/pages/request-for-information/detail';

  public tableResponse: TableResponseModel<Rfi>;

  constructor(translateService: TranslateService) {
    super('rfi', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'title', header: 'table.column.title', customClass: 'white-space-normal' },
      { field: 'type', header: 'table.column.type', customClass: 'text-center' },
      { field: 'createdDate', header: 'table.column.createdDate', customClass: 'text-center', format: FieldFormatEnum.ShortDate },
      { field: 'responseDeadlineDate', header: 'table.column.responseDeadlineDate', customClass: 'text-center', format: FieldFormatEnum.ShortDate },
      { field: 'status', header: 'table.column.rfiStatus', customClass: 'text-center' }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate([this.urlRouterNavigateToAddRequestForInformation]);
  }

  public doEdit(rfi: Rfi): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('rfiId', rfi.id);
    this.router.navigate([this.urlRouterNavigateToEditRequestForInformation]);
  }

  public doDetail(rfi: Rfi): void {
    this.global.routerParams.set('todo', 'detail');
    this.global.routerParams.set('rfiId', rfi.id);
    this.router.navigate([this.urlRouterNavigateToDetailRequestForInformation]);
  }
}
