import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupTabPartnershipComponent } from './app-popup-tab-partnership.component';
import { TabPartnershipHistoryComponent } from './tab-partnership-history.component';
import { TabPartnershipComponent } from './tab-partnership.component';
@NgModule({
  imports: [SharedModule],
  declarations: [
    TabPartnershipComponent,
    TabPartnershipHistoryComponent,
    AppPopupTabPartnershipComponent
  ],
  entryComponents: [
    TabPartnershipComponent,
    TabPartnershipHistoryComponent,
    AppPopupTabPartnershipComponent
  ],
  exports: [
    TabPartnershipComponent,
    TabPartnershipHistoryComponent,
    AppPopupTabPartnershipComponent
  ]
})
export class TabPartnershipModule {
  constructor(accordionService: AccordionService) {
    accordionService.register('tab-partnership', TabPartnershipComponent);
    accordionService.register(
      'tab-partnership-history',
      TabPartnershipHistoryComponent
    );
  }
}
