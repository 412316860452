import { Condition } from './../bean/condition';
import { WorkflowCondition } from './../bean/workflow-condition';
import { BaseEntity } from './../base/base-entity';
import { ListOfViewModel } from '../model/list-of-view-model';

export class WorkflowConditionDetailEntity extends BaseEntity {
    listOfView: ListOfViewModel[];

    condition: Condition;
    workflowCondition: WorkflowCondition;
    objectId: number;
    name: string;
}
