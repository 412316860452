import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupScoringTemplateComponent } from './app-popup-scoring-template.component';
import { ScoringTemplateEditAddComponent } from './scoring-template-edit-add.component';
import { ScoringTemplateSettingComponent } from './scoring-template-setting.component';
import { ScoringTemplateComponent } from './scoring-template.component';

export const routes = [
  { path: '', component: ScoringTemplateComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: ScoringTemplateEditAddComponent,
    data: { breadcrumb: 'scoring-template.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'view-setting',
    component: ScoringTemplateSettingComponent,
    data: { breadcrumb: 'scoring-template.breadcrumb.setting' }
  },
  {
    path: 'edit',
    component: ScoringTemplateEditAddComponent,
    data: { breadcrumb: 'scoring-template.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'app-popup-scoring-template',
    component: AppPopupScoringTemplateComponent,
    data: { breadcrumb: 'scoring-template.breadcrumb.scoringTemplatePopup' }
  },
  {
    path: 'copy',
    component: ScoringTemplateEditAddComponent,
    data: { breadcrumb: 'scoring-template.breadcrumb.copy' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],

  declarations: [
    ScoringTemplateComponent,
    ScoringTemplateEditAddComponent,
    ScoringTemplateSettingComponent,
    AppPopupScoringTemplateComponent
  ],

  entryComponents: [AppPopupScoringTemplateComponent],

  providers: []
})
export class ScoringTemplateModule {}
