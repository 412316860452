<app-popup header="evaluation-criteria.popup.addTechnicalCriteria" 
  [isShowHeaderCloseButton]="false" [isLoading]="formLoading">
  <form class="form-horizontal popup-technical" [formGroup]="formGroup" novalidate>
    <app-select
      [optionList]="technicalOptionList"
      [isMultiple]="true"
      [search]="true"
      formControlName="technicalCriteria"
      selectAllText="{{ 'evaluation-criteria.checkAll' | translate }}"
    >
    </app-select>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button color="LIGHT" (onClick)="activeModal.close(true)">{{
          'app.button.cancel' | translate }}</app-button>
        <app-button color="PRIMARY" (onClick)="doSubmit()">{{ 'app.button.add'
          | translate }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>