import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppItemRequestModule } from 'src/app/core/components/app-item-request/app-item-request.module';
import { AppProcurementInfoModule } from 'src/app/core/components/app-procurement-info/app-procurement-info.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupDetailHistoryComponent } from './app-popup-oep-detail-history.component';
import { AppPopupPriceReferenceComponent } from './app-popup-oep-price-reference.component';
import { OepEditAddComponent } from './oep-edit-add.component';
import { OepComponent } from './oep.component';
const routes: Routes = [
  { path: '', component: OepComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: OepEditAddComponent,
    data: { breadcrumb: 'oep.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: OepEditAddComponent,
    data: { breadcrumb: 'oep.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: OepEditAddComponent,
    data: { breadcrumb: 'oep.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppProcurementInfoModule,
    AppItemRequestModule,
    AppApprovalPrcsXModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    OepComponent,
    OepEditAddComponent,
    AppPopupPriceReferenceComponent,
    AppPopupDetailHistoryComponent
  ],
  providers: [],
  entryComponents: [
    AppPopupPriceReferenceComponent,
    AppPopupDetailHistoryComponent
  ],
  exports: [AppPopupPriceReferenceComponent, AppPopupDetailHistoryComponent]
})
export class OepModule {}
