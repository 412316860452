<app-layout [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-card
        header="sla-contract.details.title"
        tips="sla-contract.details.tips"
      >
        <form
          [formGroup]="formGroup"
          class="form-horizontal"
          *ngIf="!formLoading"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'sla-contract.form.workflowName' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              {{ slaReportResponse.slaContractView.name }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'sla-contract.form.title' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              {{ slaReportResponse.slaContractView.title }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'sla-contract.form.vendorName' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              {{ slaReportResponse.slaContractView.vendorName }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'sla-contract.form.totalSla' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              {{ slaReportResponse.slaContractView.totalSlaInDays }}
              {{ 'sla-contract.day' | translate }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'sla-contract.form.totalRealization' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              {{
                slaReportResponse.slaContractView.totalSlaRealizedName || '-'
              }}
            </div>
          </div>
        </form>
        <p></p>
      </app-card>
    </div>
  </div>

  <app-card
    header="sla-contract.slaReport.title"
    tips="sla-contract.slaReport.tips"
  >
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      *ngIf="!formLoading"
      novalidate
    >
      <app-table-xx
        [model]="tableResponseSlaReport"
        (onClick)="doAdjustmentWorkflow($event)"
      >
        <ng-template #headerFilter>
          <div class="app-table-filter-item" style="height: 34px">
            <div class="form-group row text-sm-left">
              <div class="col text-left" [ngStyle]="{ 'min-width': '10rem' }">
                <app-dropdown-select
                  size="XL"
                  formControlName="workflowTypeList"
                  (onChange)="onChangeFilter()"
                  container="body"
                  placeholder="{{
                    'sla-contract.placeholder.workflow' | translate
                  }}"
                  [optionList]="workflowTypeOptionList"
                  type="CHOSEN"
                  showLimitValue="1"
                >
                  <ng-template #selectAll>
                    <p>
                      {{ 'sla-contract.placeholder.allWorkflow' | translate }}
                    </p>
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>
          </div>
        </ng-template>
      </app-table-xx>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'sla-contract.form.totalRealization' | translate }} :
        </label>
        <div class="col-sm-4 text-left">
          {{ slaReportResponse.slaContractView.totalSlaRealizedName }}
        </div>
      </div>
    </form>
  </app-card>

  <app-card
    header="sla-contract.history.title"
    tips="sla-contract.history.tips"
  >
    <app-tab [bordered]="true" *ngIf="!formLoading">
      <app-tab-item
        header="{{ 'sla-contract.tab.header.historySla' | translate }}"
      >
        <app-table-xx [model]="tableResponseHistorySla" *ngIf="!formLoading">
        </app-table-xx>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'sla-contract.form.totalRealization' | translate }} :
          </label>
          <div class="col-sm-4 text-left">
            {{ slaReportResponse.slaContractView.totalSlaRealizedName }}
          </div>
        </div>
      </app-tab-item>
      <app-tab-item
        header="{{ 'sla-contract.tab.header.historyAdjustment' | translate }}"
      >
        <app-table-xx
          [model]="tableResponseHistoryAdjustment"
          *ngIf="!formLoading"
        >
        </app-table-xx>
      </app-tab-item>
    </app-tab>
  </app-card>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
