import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorPolicyComponent } from './vendor-policy.component';
const routes: Routes = [{ path: '', component: VendorPolicyComponent }];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [VendorPolicyComponent]
})
export class VendorPolicyModule {}
