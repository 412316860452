import { BlacklistDuration } from '../bean/blaclist-duration';
import { File } from '../bean/file';
import { Vendor } from '../bean/vendor';
import { BaseWorkflowObjectEntity } from './../base/base-workflow-object-entity.model';

export class VendorBlacklistEntity extends BaseWorkflowObjectEntity {
  vendor: Vendor;
  blacklistDuration: BlacklistDuration;
  file: File;
  startDate: Date;
  note: string;
  reason: string;
  sequence: number;
  endDate: Date;
}
