import { BaseEntity } from '../base/base-entity';
import { Aanwijzing } from '../bean/aanwijzing';
import { User } from '../bean/user';

export class AanwijzingCancellationEntity extends BaseEntity {
    aanwijzing: Aanwijzing;
    cancelByUser: User;
    cancelDate: Date;
    note: string;
}
