import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { User } from '../../core/bean/user';
import { UserDelegation } from '../../core/bean/user-delegation';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RecapPrintService } from '../../core/services/recap-print.service';
@Component({
  templateUrl: './user-delegation.component.html'
})
export class UserDelegationComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<UserDelegation>;
  public isLoading: boolean;
  constructor(
    translateService: TranslateService,
    public recapPrintService: RecapPrintService
  ) {
    super('delegation', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    const active = this.translateService.instant('delegation.status.active');
    const notActive = this.translateService.instant(
      'delegation.status.notActive'
    );

    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.userAprovel',
        customClass: 'white-space-normal'
      },
      {
        field: 'delegatedUserName',
        header: 'table.column.delegatedUser',
        customClass: 'white-space-normal'
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'status',
        header: 'table.column.isActive',
        datamap:
          '{"Not Active" : "' + notActive + '" , "Active" : "' + active + '" }'
      }
    ]);
  }

  public doEdit(user: User): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('user', user);
    this.router.navigate(['/pages/user-delegation/edit']);
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService
      .print('/delegation/recap-user-delegation', this.tableResponse.request)
      .subscribe(() => {
        this.isLoading = false;
      });
  }
}
