<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="work-program.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-program.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="code"
              maxlength="32"
            ></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-program.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="name" maxlength="32">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-program.form.period' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <!-- <app-text-field formControlName="activityPeriod" type="integer" size="SM" maxlength="32"></app-text-field> -->
            <app-combo-box
              formControlName="activityPeriod"
              [optionList]="periodList"
            ></app-combo-box>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-program.form.organization' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="organizationName"
              stringUrl="/organization"
              isLazy="true"
              header="Organization"
            >
            </app-text-tree>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'work-program.form.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxlength="512">
            </app-text-area>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            class="mr-1"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}</app-button
          >
          <app-button
            color="PRIMARY"
            (onClick)="doSave()"
            [disabled]="formSaving"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
