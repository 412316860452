export class MonthYearPickerModel {
  constructor(
    public currentYear?: number,
    public selectedMonth?: number,
    public isShowYearRange?: boolean,
    public isShowPopupMontYearPicker?: boolean,
    public startYearRange?: number,
    public endYearRange?: number,
    public currentValue?: string
  ) {}
}
