import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppBadgeCatalogModule } from 'src/app/core/components/app-badge-catalog/app-badge-catalog.module';
import { AppCatalogDetailInfoModule } from 'src/app/core/components/app-catalog-detail-info/app-catalog-detail-info.module';
import { AppCatalogInfoModule } from 'src/app/core/components/app-catalog-info/app-catalog-info.module';
import { AppCatalogItemXModule } from 'src/app/core/components/app-catalog-item-x/app-catalog-item-x.module';
import { AppImageSlideshowModule } from 'src/app/core/components/app-image-slideshow/app-image-slideshow.module';
import { AppItemRequestModule } from 'src/app/core/components/app-item-request/app-item-request.module';
import { AppOfficialReportModule } from 'src/app/core/components/app-official-report/app-official-report.module';
import { AppSimilarProductModule } from 'src/app/core/components/app-similar-product/app-similar-product.module';
import { AppUploadDocumentTableXModule } from 'src/app/core/components/app-upload-document-table-x/app-upload-document-table-x.module';
import { AppVendorCatalogInfoModule } from 'src/app/core/components/app-vendor-catalog-info/app-vendor-catalog-info.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupPrAddBudgetComponent } from './app-popup-pr-add-budget.component';
import { AppPopupPrCancellationComponent } from './app-popup-pr-cancellation.component';
import { AppPopupPrItemDistributorComponent } from './app-popup-pr-item-distributor.component';
import { AppPopupPrItemEditComponent } from './app-popup-pr-item-edit.component';
import { PrAddItemDetailComponent } from './pr-add-item-detail.component';
import { PrCatalogAddComponent } from './pr-catalog-add.component';
import { PrEditAddComponent } from './pr-edit-add.component';
import { PrItemCatalogAddComponent } from './pr-item-catalog-add.component';
import { PrItemEditAddComponent } from './pr-item-edit-add.component';
import { PrVendorDetailComponent } from './pr-vendor-detail.component';
import { PrComponent } from './pr.component';
const routes: Routes = [
  { path: '', component: PrComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: PrEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: PrEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: PrEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add-item',
    component: PrItemEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.addItem' },
    canDeactivate: [ConfirmGuard],
    children: [
      {
        path: '',
        component: PrCatalogAddComponent,
        canDeactivate: [ConfirmGuard]
      },
      {
        path: 'catalog',
        component: PrItemCatalogAddComponent,
        data: { breadcrumb: 'pr.breadcrumb.itemCatalog' },
        canDeactivate: [ConfirmGuard]
      }
    ]
  },
  {
    path: 'edit-item',
    component: PrItemEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.editItem' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add-item-detail',
    component: PrAddItemDetailComponent,
    data: { breadcrumb: 'pr.breadcrumb.catalogDetail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'vendor-detail',
    component: PrVendorDetailComponent,
    data: { breadcrumb: 'pr.breadcrumb.vendorDetail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: PrEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit/:token',
    component: PrEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppImageSlideshowModule,
    AppUploadDocumentTableXModule,
    AppCatalogInfoModule,
    AppCatalogDetailInfoModule,
    AppApprovalPrcsXModule,
    AppVendorCatalogInfoModule,
    AppItemRequestModule,
    AppSimilarProductModule,
    AppBadgeCatalogModule,
    AppCatalogItemXModule,
    AppOfficialReportModule
  ],
  declarations: [
    PrComponent,
    PrEditAddComponent,
    PrItemEditAddComponent,
    AppPopupPrAddBudgetComponent,
    AppPopupPrItemEditComponent,
    PrAddItemDetailComponent,
    PrVendorDetailComponent,
    PrItemCatalogAddComponent,
    PrCatalogAddComponent,
    AppPopupPrCancellationComponent,
    AppPopupPrItemDistributorComponent
  ],
  providers: [],
  entryComponents: [
    AppPopupPrAddBudgetComponent,
    AppPopupPrItemEditComponent,
    AppPopupPrCancellationComponent,
    AppPopupPrItemDistributorComponent
  ],
  exports: [
    AppPopupPrAddBudgetComponent,
    AppPopupPrItemEditComponent,
    AppPopupPrCancellationComponent,
    AppPopupPrItemDistributorComponent
  ]
})
export class PrModule {}
