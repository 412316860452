import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorEntity } from '../../core/bean/vendor-entity';
import { VendorType } from '../../core/bean/vendor-type';
import { OptionListModel } from '../../core/model/option-list-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { Validators } from '../../core/validators';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfileGeneralInformationDTO } from './dto/tab-profile-general-information.dto';

@Component({
  templateUrl: './tab-profile-general-information.component.html'
})
export class TabProfileGeneralInformationComponent extends BaseModuleComponent {
  public userId: number;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfileGeneralInformationDTO: TabProfileGeneralInformationDTO =
    new TabProfileGeneralInformationDTO();
  public vendor: Vendor = new Vendor();
  public now = new Date().getTime();
  public sessionId = null;
  public vendorTypeList: VendorType[] = [];
  public regionList: Region[] = [];
  public vendorEntityList: VendorEntity[] = [];
  public tabGeneralInformation: TabModel;
  public isView: boolean;
  public vendorId: number;
  public isVendorList: boolean;
  public fromOutside: string;
  public roleType: number;
  public activityStatus: string;

  public countryRegionOptionList: OptionListModel<Region> =
    new OptionListModel();
  public countryRegionPhoneOptionList: OptionListModel<Region> =
    new OptionListModel();
  public provinceRegionOptionList: OptionListModel<Region> =
    new OptionListModel();
  public cityRegionOptionList: OptionListModel<Region> = new OptionListModel();
  public districtRegionList: OptionListModel<Region> = new OptionListModel();

  constructor(
    translate: TranslateService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-general-information', translate);
  }

  onInit(): void {
    this.roleType = this.global.userSession.activeUserRole.role.type;
    this.getDataFromRouterParams();
    if (
      this.tabGeneralInformation.routerParams.get(
        'tabProfileGeneralInformationDTO'
      )
    ) {
      this.tabProfileGeneralInformationDTO =
        this.tabGeneralInformation.routerParams.get(
          'tabProfileGeneralInformationDTO'
        );
      this.setOptionListModel();
      this.getAndSetVendorToFormGroup();
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .get<TabProfileGeneralInformationDTO>(
        this.todo
          ? '/tab-profile-general-information/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-general-information/index'
      )
      .subscribe(tabProfileGeneralInfoDTO => {
        this.tabProfileGeneralInformationDTO = tabProfileGeneralInfoDTO;
        this.tabGeneralInformation.routerParams.set(
          'tabProfileGeneralInformationDTO',
          tabProfileGeneralInfoDTO
        );
        this.setOptionListModel();
        this.buildFormGroup();
        this.getAndSetVendorToFormGroup();
      });
  }

  public getDataFromRouterParams(): void {
    this.tabGeneralInformation = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_GENERAL_INFORMATION);
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
    this.todo = this.global.routerParams.get('todo');
    this.activityStatus = this.global.routerParams.get('activityStatus');
  }

  public setOptionListModel(): void {
    this.countryRegionOptionList.setRequestValues(
      this.tabProfileGeneralInformationDTO.countryRegionList
    );
    this.countryRegionPhoneOptionList.stringUrl = '/resource/';
    this.countryRegionPhoneOptionList.fileTypeCode =
      this.global.appConstant.fileType.IMG_ICON;
    this.countryRegionPhoneOptionList.setRequestValues(
      this.tabProfileGeneralInformationDTO.countryRegionList
    );
  }

  public buildFormGroup(): void {
    if (!this.tabGeneralInformation.isFormGroupReady) {
      this.tabGeneralInformation.formGroup = this.formBuilder.group({
        id: [null],
        name: [null, Validators.required()],
        initialName: [null],
        idNumber: [null, Validators.required()],
        taxNumber: [
          null,
          {
            validators: [Validators.required(), Validators.npwp()],
            asyncValidators: [
              Validators.existsAsync(
                '/company-profile/check-vendor-history-exist',
                null,
                null,
                { id: this.companyProfileDTO.vendorHistory.id }
              )
            ]
          }
        ],
        address: [null, Validators.required()],
        coordinateLocation: [null],
        countryRegion: [null, Validators.required()],
        provinceRegion: [null, Validators.required()],
        cityRegion: [null, Validators.required()],
        districtRegion: [null, Validators.required()],
        postCode: [null, Validators.required()],
        email: [
          null,
          {
            validators: [Validators.email(), Validators.required()],
            asyncValidators: [
              Validators.existsAsync(
                '/company-profile/check-vendor-history-exist',
                null,
                null,
                { id: this.companyProfileDTO.vendorHistory.id }
              )
            ]
          }
        ],
        phone: [null, Validators.required()],
        registrationNumber: [null],
        sequence: [null],
        statusApproval: [null],
        vendor: [null],
        isSubmit: [null],
        createdDate: [null],
        vendorType: [null],
        user: [null],
        organization: [null],
        vendorEntity: [null],
        vendorAddressId: [null]
      });
    }
  }

  public doChangeCountryRegion(countryRegion: Region): void {
    this.formGroupService.bulkResetFormControl(
      this.tabGeneralInformation.formGroup,
      ['provinceRegion', 'cityRegion', 'districtRegion']
    );
    this.httpClientService
      .post<Region[]>('/company-profile/change-region-list', countryRegion.id)
      .subscribe(provinceRegionList => {
        this.provinceRegionOptionList.setRequestValues(provinceRegionList);
        this.cityRegionOptionList.reset();
        this.districtRegionList.reset();
      });
  }

  public doChangeProvinceRegion(provinceRegion: Region): void {
    this.tabGeneralInformation.formGroup.get('cityRegion').reset();
    this.tabGeneralInformation.formGroup.get('districtRegion').reset();
    this.httpClientService
      .post<Region[]>('/company-profile/change-region-list', provinceRegion.id)
      .subscribe(cityRegionList => {
        this.cityRegionOptionList.setRequestValues(cityRegionList);
        this.districtRegionList.reset();
      });
  }

  public doChangeCityRegion(cityRegion: Region): void {
    this.tabGeneralInformation.formGroup.get('districtRegion').reset();
    this.httpClientService
      .post<Region[]>('/company-profile/change-region-list', cityRegion.id)
      .subscribe(districtRegionList => {
        this.districtRegionList.setRequestValues(districtRegionList);
      });
  }

  public getAndSetVendorToFormGroup(): void {
    this.vendorId =
      this.companyProfileDTO.vendorHistory !== null
        ? this.companyProfileDTO.vendorHistory.id
        : null;
    if (
      Object.keys(this.tabGeneralInformation.formGroup.controls).length === 0 ||
      this.tabGeneralInformation.formGroup.controls.id.value === null
    ) {
      this.tabGeneralInformation.setIsView(this.isView);
      this.tabGeneralInformation.formGroup.patchValue(
        this.companyProfileDTO.vendorHistory
      );
      const phoneData = {
        number: this.companyProfileDTO.vendorHistory.phone,
        country: this.companyProfileDTO.vendorHistory.phoneRegion
      };
      if (this.tabProfileGeneralInformationDTO.vendorAddressHistory) {
        if (
          this.tabProfileGeneralInformationDTO.vendorAddressHistory
            .countryRegion
        ) {
          this.httpClientService
            .post<Region[]>(
              '/company-profile/change-region-list',
              this.tabProfileGeneralInformationDTO.vendorAddressHistory
                .countryRegion.id
            )
            .subscribe(provinceRegionList => {
              this.provinceRegionOptionList.setRequestValues(
                provinceRegionList
              );
            });
        }
        if (
          this.tabProfileGeneralInformationDTO.vendorAddressHistory
            .provinceRegion
        ) {
          this.httpClientService
            .post<Region[]>(
              '/company-profile/change-region-list',
              this.tabProfileGeneralInformationDTO.vendorAddressHistory
                .provinceRegion.id
            )
            .subscribe(cityRegionList => {
              this.cityRegionOptionList.setRequestValues(cityRegionList);
            });
        }
        if (
          this.tabProfileGeneralInformationDTO.vendorAddressHistory.cityRegion
        ) {
          this.httpClientService
            .post<Region[]>(
              '/company-profile/change-region-list',
              this.tabProfileGeneralInformationDTO.vendorAddressHistory
                .cityRegion.id
            )
            .subscribe(districtRegionList => {
              this.districtRegionList.setRequestValues(districtRegionList);
            });
        }
        this.tabGeneralInformation.formGroup.patchValue(
          this.tabProfileGeneralInformationDTO.vendorAddressHistory
        );
        this.tabGeneralInformation.formGroup.patchValue({
          address:
            this.tabProfileGeneralInformationDTO.vendorAddressHistory.address,
          vendorAddressId:
            this.tabProfileGeneralInformationDTO.vendorAddressHistory
              .vendorAddressId
        });
      }
      this.tabGeneralInformation.formGroup.patchValue({
        phone: phoneData,
        id: this.companyProfileDTO.vendorHistory.id,
        organization: this.companyProfileDTO.vendorHistory.organization
      });
      if (
        this.global.userSession.user.organization.id ===
          this.companyProfileDTO.vendorHistory.vendor.onBoardingByOrganization
            .id && this.activityStatus &&
        this.activityStatus.includes('VERIFICATION')
      ) {
        this.tabGeneralInformation.formGroup.get('name').setIsView(false);
      }
    }
    this.setStateReady();
  }
}
