import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppMapModule } from '../app-map/app-map.module';
import { AppTextFieldModule } from '../app-text-field/app-text-field.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppTextMapComponent } from './app-text-map.component';
@NgModule({
  imports: [VendorModule, AppTextFieldModule, AppMapModule, AppButtonModule, AppTooltipWrapperModule],
  declarations: [AppTextMapComponent],
  exports: [AppTextMapComponent]
})
export class AppTextMapModule {}
