import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ParameterEditAddComponent } from './parameter-edit-add.component';
import { ParameterComponent } from './parameter.component';
const routes: Routes = [
  { path: '', component: ParameterComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: ParameterEditAddComponent,
    data: { breadcrumb: 'parameter.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ParameterComponent, ParameterEditAddComponent]
})
export class ParameterModule {}
