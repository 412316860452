import { CurrencyPipe } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartTooltipItem } from 'chart.js';
import { OrderType } from '../../../../../core/bean/order-type';
import { ChartModel } from '../../../../../core/components/app-chart/models/chart-model';
import { OptionListModel } from '../../../../../core/model/option-list-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetValueOfOrderRequest } from './model/widget-value-of-order-request';
import { WidgetValueOfOrderResponse } from './model/widget-value-of-order-response';

@Component({
  selector: 'dashboard-widget-value-of-order',
  templateUrl: './widget-value-of-order.component.html',
  styleUrls: ['./widget-value-of-order.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetValueOfOrderComponent extends BaseWidgetComponent {
  public yearOptionList: OptionListModel<number> = new OptionListModel(true);
  public orderTypeOptionList: OptionListModel<OrderType> = new OptionListModel(
    true
  );
  public moneyFormatTranslateKeyList: Array<string>;
  public chartModel: ChartModel;
  public widgetValueOfOrderRequest: WidgetValueOfOrderRequest =
    new WidgetValueOfOrderRequest();
  public widgetValueOfOrderResponse: WidgetValueOfOrderResponse =
    new WidgetValueOfOrderResponse();
  public total: string;
  public growthFromPastYear: string;
  public modelList: any[] = [];
  public modelDefaultList: any[] = [];
  public emptyValue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  constructor(
    public translateService: TranslateService,
    public currencyPipe: CurrencyPipe
  ) {
    super('dashboard-widget-value-of-order');
  }

  public onInit(): void {
    this.setStateMoneyFormatTranslateKeyList();
    this.buildFormGroup();
    this.setFormGroup();
  }

  private setStateMoneyFormatTranslateKeyList(): void {
    this.moneyFormatTranslateKeyList = [
      this.global.translateService.instant('app.moneyFormat.thousand'),
      this.global.translateService.instant('app.moneyFormat.million'),
      this.global.translateService.instant('app.moneyFormat.billion'),
      this.global.translateService.instant('app.moneyFormat.trillion'),
      this.global.translateService.instant('app.moneyFormat.quadrillion'),
      this.global.translateService.instant('app.moneyFormat.quantillion'),
      this.global.translateService.instant('app.moneyFormat.sextillion')
    ];
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      year: [new Date().getFullYear()],
      orderType: [null]
    });
    const currentYear = new Date().getFullYear();
    this.yearOptionList.setRequestValues(
      Array(3)
        .fill(0)
        .map((fill: number, index: number) => currentYear - 2 + fill + index)
    );
    this.widgetValueOfOrderRequest.year = this.formGroup.value.year;
  }

  private reload(): void {
    this.total = this.global.converterService.convertToShortMoney(
      this.widgetValueOfOrderResponse.total || 0,
      this.moneyFormatTranslateKeyList
    ) as string;
    this.growthFromPastYear = this.global.converterService.convertToShortMoney(
      this.widgetValueOfOrderResponse.growthFromPastYear || 0,
      this.moneyFormatTranslateKeyList
    ) as string;
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<WidgetValueOfOrderResponse>(
        '/widget-value-of-order/index',
        this.widgetValueOfOrderRequest
      )
      .subscribe((response: WidgetValueOfOrderResponse) => {
        if (response) {
          this.widgetValueOfOrderResponse = response;
          if (response.orderTypeList) {
            this.widgetValueOfOrderRequest.orderTypeList =
              response.orderTypeList;
            this.orderTypeOptionList.setRequestValues(response.orderTypeList);
            this.formGroup
              .get('orderType')
              .patchValue(this.widgetValueOfOrderRequest.orderTypeList);
          }
          this.setChartModel();
        }
        this.setStateReady();
      });
  }

  public setModel(): void {
    const colorList = ['#14B1AB', '#0772B6'];
    this.modelDefaultList = [];
    this.widgetValueOfOrderResponse.widgetValueOfOrderModelResponseList.forEach(
      (widgetValueOfOrderModelResponse, index) => {
        const model = {
          code: widgetValueOfOrderModelResponse.orderType.code,
          name: widgetValueOfOrderModelResponse.orderType.name,
          total: this.global.converterService.convertToShortMoney(
            widgetValueOfOrderModelResponse.total || 0,
            this.moneyFormatTranslateKeyList
          ) as string,
          color: colorList[index]
        };
        this.modelDefaultList.push(model);
      }
    );
  }

  public setChartModel(): void {
    this.reload();
    this.setModel();
    let labels = [];
    const dataSetLabels = [];
    const dataSets = [];
    const orderTypeCodeRequestList =
      this.widgetValueOfOrderRequest.orderTypeList.map(orderType =>
        orderType.code.toLowerCase()
      );
    this.modelList = (!orderTypeCodeRequestList || orderTypeCodeRequestList.length === 0) ? this.modelDefaultList : this.modelDefaultList.filter(model =>
      orderTypeCodeRequestList.includes(model.code.toLowerCase())
    );
    const widgetValueOfOrderModelResponseList = (!orderTypeCodeRequestList || orderTypeCodeRequestList.length === 0) ? this.widgetValueOfOrderResponse.widgetValueOfOrderModelResponseList :
      this.widgetValueOfOrderResponse.widgetValueOfOrderModelResponseList.filter(
        response =>
          orderTypeCodeRequestList.includes(
            response.orderType.code.toLowerCase()
          )
      );
    widgetValueOfOrderModelResponseList.forEach(
      widgetValueOfOrderModelResponse => {
        if (labels.length === 0) {
          labels = [
            ...widgetValueOfOrderModelResponse.widgetValueOfOrderModelList
          ].map(data => data.month);
        }
        dataSets.push({
          data: !this.formGroup.value?.year ? [] : (!orderTypeCodeRequestList || orderTypeCodeRequestList.length === 0) ? this.emptyValue : [
            ...widgetValueOfOrderModelResponse.widgetValueOfOrderModelList
          ].map(data => data.total)
        });
        dataSetLabels.push(
          widgetValueOfOrderModelResponse.orderType.name
        );
      }
    );

    const CURRENCY_PRECISSION_SCALE =
      this.global.appConstant.core.CURRENCY_PRECISSION_SCALE;
    const CURRENCY_DIGITS_INFO = `0.${CURRENCY_PRECISSION_SCALE}-${CURRENCY_PRECISSION_SCALE}`;
    this.chartModel = new ChartModel('bar', labels, dataSets, dataSetLabels, {
      legend: {
        display: false
      },
      defaultColor: this.modelList.map(model => model.color),
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 500000000,
              callback: (value): string => {
                return this.global.converterService.convertToShortMoneyChart(
                  value
                ) as string;
              }
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      },
      tooltips: {
        mode: 'nearest',
        xPadding: 20,
        yPadding: 20,
        titleFontStyle: 'font-weight: normal; padding: 20px',
        bodyFontStyle: 'font-weight: bold',
        footerFontStyle: 'font-weight: normal',
        callbacks: {
          title: (item: ChartTooltipItem[], data: Chart.ChartData): string => {
            return this.global.translateService.instant(
              data.datasets[item[0].datasetIndex].label
            );
          },
          label: () => null,
          afterBody: (item: Chart.ChartTooltipItem[]): string => {
            const data =
              widgetValueOfOrderModelResponseList[item[0].datasetIndex]
                .widgetValueOfOrderModelList[item[0].index];
            return `${this.currencyPipe.transform(
              data.total,
              '',
              '',
              CURRENCY_DIGITS_INFO
            )} ${data.currency || 'IDR'}`;
          }
        }
      }
    });
  }

  public onChangeOrderType(): void {
    if (this.formGroup.value?.orderType) {
      this.widgetValueOfOrderRequest.orderTypeList =
        this.formGroup.value.orderType;
    } else {
      this.widgetValueOfOrderRequest.orderTypeList = [];
    }
    this.setChartModel();
  }
  public onChangeYear(): void {
    if (this.formGroup.value.year) {
      this.widgetValueOfOrderRequest.year = this.formGroup.value.year;
      this.setStateLoading();
      this.setFormGroup();
    } else {
      this.setChartModel();
    }
  }
}
