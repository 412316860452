import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Counter } from '../../core/bean/counter';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';

@Component({
  templateUrl: './counter.component.html'
})
export class CounterComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Counter>;
  constructor(translateService: TranslateService) {
    super('counter', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/counter/add']);
  }

  public doEdit(counter: Counter): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('counterId', counter.id);
    this.router.navigate(['/pages/counter/edit']);
  }
}
