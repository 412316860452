import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ItemType } from '../../core/bean/item-type';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementFilter } from '../../core/bean/procurement-filter';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { ProcurementParticipant } from '../../core/bean/procurement-participant';
import { ProcurementPqDocBuyerStatus } from '../../core/bean/procurement-pq-doc-buyer-status';
import { ProcurementRegion } from '../../core/bean/procurement-region';
import { ProcurementSchedule } from '../../core/bean/procurement-schedule';
import { ProcurementSegQualification } from '../../core/bean/procurement-seg-qualification';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { Region } from '../../core/bean/region';
import { AppPopupChooseVendorService } from '../../core/components/app-popup/app-popup-choose-vendor/app-popup-choose-vendor.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';
import { AppTableComponent } from '../../core/components/table/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { ProcurementNotifResponseModel } from '../../core/model/procurement-notif-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { PopupVendorView } from '../../core/view/entity/bean/popup-vendor-view';
import { RegisteredProcVendorView } from '../../core/view/entity/bean/registered-proc-vendor-view';
import { WorklistPMService } from '../worklist-pm/worklist-pm.service';
import { AppPopupPraQualificationComponent } from './app-popup-pra-qualification-requirement.component';
import { ProcRegBuyerInviteVendorRequest } from './proc-reg-buyer-invite-vendor-request';
import { ProcurementRegBuyerRequest } from './procurement-reg-buyer-request';
import { ProcurementRegBuyerResponse } from './procurement-reg-buyer-response';
import { ProcurementRegBuyerUpdateRequest } from './procurement-reg-buyer-update-request';
@Component({
  templateUrl: './procurement-reg-buyer-edit.component.html'
})
export class ProcurementRegBuyerEditComponent extends BaseModuleComponent {
  public approvalModelPrcsId: number;
  public objectId: number;
  public token: string;
  public procurementId: number;
  public procurement: Procurement;
  public urlBackOutside: string;
  public procurementItemList: ProcurementItem[] = [];
  public itemTypeList: ItemType[] = [];
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public procurementRegBuyerResponse: ProcurementRegBuyerResponse;
  public procurementRegBuyerRequest: ProcurementRegBuyerRequest;
  public procurementFilter: ProcurementFilter;
  public procurementRegionList: ProcurementRegion[] = [];
  public procurementParticipant: ProcurementParticipant;
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<RegisteredProcVendorView>;
  public registeredProcVendorViewList: RegisteredProcVendorView[] = [];
  public procurementPqDocBuyerStatusList: ProcurementPqDocBuyerStatus[];
  public procurementSegQualificationList: ProcurementSegQualification[];
  public procurementSchedule: ProcurementSchedule;
  public minParticipant: number;
  public canceledOrFailedMessage: string;
  public isShowBadgeStatus: boolean;

  public badgeColor = {
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    NEW: 'INFO',
    HOLD: 'DANGER',
    CANCELED: 'DANGER',
    FAILED: 'DANGER'
  };

  constructor(
    translateService: TranslateService,
    public appPopupChooseVendorService: AppPopupChooseVendorService,
    public appPopupService: AppPopupService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute,
    public datePipe: DatePipe,
    public worklistPMService: WorklistPMService
  ) {
    super('procurement-reg-buyer', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken() {
    this.httpClientService
      .get<ProcurementNotifResponseModel>(
        '/procurement-reg-buyer/notification/' + this.token
      )
      .subscribe((response: ProcurementNotifResponseModel) => {
        this.worklistPMService.doNavigateDetail(response, this.moduleCode);
      });
  }

  public setInitializationState() {
    this.setDataFromRouterParams();
    this.doBuildTableResponse();
    this.setFormGroup();
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.procurement = this.global.routerParams.get('procurement');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementVendor.vendor.name',
        header: 'table.column.vendorName',
        plugins: [
          {
            name: 'hyperlink',
            onClick: this.doClick.bind(this)
          },
          {
            name: 'icon',
            className: 'pic pi-exclamation-triangle',
            onClick: this.onClickVendor.bind(this),
            isShowIconFn: this.showIcon.bind(this)
          }
        ]
      },
      {
        field: 'procurementVendor.vendor.email',
        header: 'table.column.vendorEmail'
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'picName',
        header: 'table.column.picName'
      },
      {
        field: 'procurementVendor.registeredDate',
        header: 'table.column.registeredDate',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'procurementVendor.isRegistered',
        header: 'table.column.registrationStatus',
        dataMap:
          '{"false" : "' +
          this.translateService.instant(
            'procurement-reg-buyer.regStatus.notRegistered'
          ) +
          '" , "true" : "' +
          this.translateService.instant(
            'procurement-reg-buyer.regStatus.registered'
          ) +
          '" }'
      },
      {
        field: 'procurementRegBuyerStatus.name',
        header: 'table.column.documentStatus',
        plugins: {
          name: 'badge',
          colorMap: {
            UNAVAILABLE: 'SECONDARY',
            VERIFICATION: 'WARNING',
            REVISION: 'FEEDBACK',
            ACCEPTED: 'SUCCESS',
            DECLINED: 'DANGER',
            EXPIRED: 'DANGER'
          },
          colorField: 'procurementRegBuyerStatus.code'
        }
      }
    ]);
    this.tableResponse.setCustomData({ procurementId: this.procurementId });
  }

  public showIcon(vendor: RegisteredProcVendorView): boolean {
    if (vendor.blacklistRangeYear || vendor.sameCompanyOrgVendorName) {
      return true;
    } else {
      return false;
    }
  }

  public isWarningAlert(): boolean {
    return this.registeredProcVendorViewList.find(
      v => v.blacklistRangeYear || v.sameCompanyOrgVendorName
    )
      ? true
      : false;
  }

  public onClickVendor(data: RegisteredProcVendorView): void {
    let msg = '<ol class="text-left ml-4">';

    const tkeyBlacklist: string = this.global.translateService.instant(
      'procurement-reg-buyer.popup.warning.msg.blacklist'
    );
    const tkeyCommissioner: string = this.global.translateService.instant(
      'procurement-reg-buyer.popup.warning.msg.commissioner'
    );
    if (data.blacklistRangeYear) {
      msg +=
        '<li>' +
        data.procurementVendor.vendor.name +
        ' ' +
        tkeyBlacklist +
        ' ' +
        data.blacklistRangeYear +
        '</li>';
    }

    if (data.sameCompanyOrgVendorName) {
      msg +=
        '<li>' +
        data.procurementVendor.vendor.name +
        ' ' +
        tkeyCommissioner +
        ' ' +
        data.sameCompanyOrgVendorName +
        '</li>';
    }
    msg += '</ol>';

    this.global.modalService.confirmation(
      msg,
      'procurement-reg-buyer.modal.vendorWarning.title',
      null,
      null,
      'pvc pv-exclamation-triangle',
      true
    );
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ProcurementRegBuyerResponse>(
        '/procurement-reg-buyer/edit',
        new RouteRequestModel(
          this.todo,
          this.procurementId || this.procurement.id
        )
      )
      .subscribe((procRegBuyerResponse: ProcurementRegBuyerResponse) => {
        this.procurement = procRegBuyerResponse.procurement;
        this.procurementParticipant =
          procRegBuyerResponse.procurementParticipant;
        this.procurementRegBuyerResponse = procRegBuyerResponse;
        this.procurementItemList = procRegBuyerResponse.procurementItemList;
        this.itemTypeList = procRegBuyerResponse.itemTypeList;
        this.workflowStepInfoModel = procRegBuyerResponse.workflowStepInfoModel;
        this.procurementFilter = procRegBuyerResponse.procurementFilter;
        this.procurementRegionList = procRegBuyerResponse.procurementRegionList;
        this.procurementSegQualificationList =
          procRegBuyerResponse.procurementSegQualificationList;
        this.procurementPqDocBuyerStatusList =
          procRegBuyerResponse.procurementPqDocBuyerStatusList;
        this.registeredProcVendorViewList =
          procRegBuyerResponse.registeredProcVendorViewList;
        this.procurementSchedule = procRegBuyerResponse.procurementSchedule;
        const statusList = [
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
        ];

        if (statusList.includes(this.procurement.procurementStatus.code)) {
          if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'procurement-reg-buyer.alert.msg.sorryWaitingCancelationApproval';
          } else if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'procurement-reg-buyer.alert.msg.sorryWaitingFailedApproval';
          } else {
            this.canceledOrFailedMessage =
              'procurement-reg-buyer.alert.msg.sorryFailedAssessment';
          }
        }
        this.setStateReady();
        this.setIsViewOnly();
      });
  }

  public isProcurementFailed(): boolean {
    const accParticipantList: RegisteredProcVendorView[] =
      this.registeredProcVendorViewList.filter(
        data =>
          data.procurementRegBuyerStatus.code ===
          this.global.appConstant.pm.PROC_REG_BUYER_STATUS_ACCEPTED
      );
    this.minParticipant =
      this.procurement.maxRepeatable !== 0
        ? this.procurementParticipant.minimumParticipant
        : 1;
    if (accParticipantList.length >= this.minParticipant) {
      return false;
    } else {
      return true;
    }
  }

  public doSubmit(): void {
    const currentDate = new Date().getTime();
    const endDate =
      this.procurementSchedule && this.procurementSchedule.endDate
        ? new Date(this.procurementSchedule.endDate).getTime()
        : null;
    if (endDate && currentDate < endDate) {
      const dateTime = this.datePipe.transform(
        this.procurementSchedule.endDate,
        this.global.config.parameterModel.datetimeFormatShort
      );
      this.global.modalService.confirmation(
        this.translateService.instant(
          'procurement-reg-buyer.dueDateAvailable.prompt'
        ) +
          ' ' +
          dateTime,
        this.translateService.instant(
          'procurement-reg-buyer.dueDateAvailable.title'
        ),
        null,
        null,
        'pvc pv-sandglass',
        true
      );
    } else {
      this.validate();
      const isAllVerified =
        this.registeredProcVendorViewList.filter(
          data =>
            data.procurementRegBuyerStatus.code ===
            this.global.appConstant.pm.PROC_REG_BUYER_STATUS_VERIFICATION
        ).length === 0;
      if (isAllVerified) {
        const isProcurementFailed = this.isProcurementFailed();
        if (!isProcurementFailed) {
          this.global.modalService
            .submitConfirmation()
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this.doSubmitProcess(isProcurementFailed);
              }
            });
        } else {
          this.global.modalService
            .submitProcurementFailed(this.minParticipant)
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this.doSubmitProcess(isProcurementFailed);
              }
            });
        }
      } else {
        this.global.alertService.showError(
          this.translateService.instant(
            'procurement-reg-buyer.msg.error.verifyAll'
          )
        );
      }
    }
  }

  public doSubmitProcess(isProcurementFailed: boolean): void {
    const request: ProcurementRegBuyerUpdateRequest =
      new ProcurementRegBuyerUpdateRequest();
    request.procurement = this.procurement;
    request.isProcurementFailed = isProcurementFailed;
    this.setStateProcessing();
    this.httpClientService
      .post<Response<Procurement>>('/procurement-reg-buyer/update', request)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.modalService.submitSuccess().subscribe(result => {
            if (result) {
              this.router.navigate(['/pages/worklist-pm/']);
            } else {
              this.router
                .navigateByUrl('/', {
                  skipLocationChange: true
                })
                .then(() => {
                  this.global.routerParams.clear();
                  this.global.routerParams.set(
                    'procurementId',
                    this.procurementId
                  );
                  this.global.routerParams.set(
                    'urlBackOutside',
                    '/pages/worklist-pm'
                  );
                  this.global.routerParams.set('todo', 'view');
                  this.router.navigate(['/pages/procurement-reg-buyer/detail']);
                });
            }
          });
        } else {
          this.global.alertService.showError(response.statusText);
        }
        this.setStateReady();
      });
  }

  public doClick(data: RegisteredProcVendorView): void {
    if (
      data.procurementRegBuyerStatus.code ===
        this.global.appConstant.pm.PROC_REG_BUYER_STATUS_UNAVAILABLE &&
      this.todo !== 'view'
    ) {
      this.global.modalService
        .confirmation(
          data.procurementVendor.vendor.name +
            this.translateService.instant(
              'procurement-reg-buyer.confirm.msg.sendEmail'
            ),
          'procurement-reg-buyer.confirm.title.sendEmail',
          'app.button.close',
          'procurement-reg-buyer.confirm.button.sendEmail',
          'pvc pv-envelope-reminder',
          false
        )
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<RegisteredProcVendorView>>(
                '/procurement-reg-buyer/send-email-reminder',
                data
              )
              .subscribe((response: Response<RegisteredProcVendorView>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.snackbarService.showWarning(
                    'app.msg.info.reminderEmailSent'
                  );
                  this.tableResponse.reload();
                  this.setStateReady();
                }
              });
          }
        });
    } else if (
      data.procurementRegBuyerStatus.code !==
      this.global.appConstant.pm.PROC_REG_BUYER_STATUS_UNAVAILABLE
    ) {
      const todo =
        data.procurementRegBuyerStatus.code ===
          this.global.appConstant.pm.PROC_REG_BUYER_STATUS_VERIFICATION &&
        this.todo !== 'view'
          ? 'edit'
          : 'view';
      const procurementVendor: ProcurementVendor = data.procurementVendor;
      procurementVendor.procurement = this.procurement;
      const procurementPqDocBuyerStatusList: ProcurementPqDocBuyerStatus[] =
        this.procurementPqDocBuyerStatusList;
      this.appPopupService
        .open(
          AppPopupPraQualificationComponent,
          {
            procurementVendor,
            procurementPqDocBuyerStatusList,
            todo
          },
          { size: 'xl' }
        )
        .subscribe((procurementRegBuyerRequest: ProcurementRegBuyerRequest) => {
          this.setStateProcessing();
          this.httpClientService
            .post<Response<ProcurementRegBuyerRequest>>(
              '/procurement-reg-buyer/update-pq-doc-list',
              procurementRegBuyerRequest
            )
            .subscribe((response: Response<ProcurementRegBuyerRequest>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.httpClientService
                  .get<RegisteredProcVendorView[]>(
                    '/procurement-reg-buyer/get-vendor-list/' +
                      this.procurement.id
                  )
                  .subscribe(
                    (
                      registeredProcVendorViewList: RegisteredProcVendorView[]
                    ) => {
                      this.registeredProcVendorViewList =
                        registeredProcVendorViewList;
                      this.tableResponse.reload();
                      this.setStateReady();
                    }
                  );
              } else {
                this.global.alertService.showError(response.statusText);
                this.setStateReady();
              }
            });
        });
    }
  }

  public doInviteVendor(): void {
    const excludeVendorIdList: number[] = [];
    this.registeredProcVendorViewList.map(data =>
      excludeVendorIdList.push(data.procurementVendor.vendor.id)
    );
    const vendorIdList: number[] = [];
    const procurementFilter: ProcurementFilter = this.procurementFilter;
    const regionList: Region[] = [];
    this.procurementRegionList.map(procRegion =>
      regionList.push(procRegion.region)
    );
    this.appPopupChooseVendorService
      .openProcurementRegist(
        excludeVendorIdList,
        [],
        procurementFilter,
        regionList,
        this.procurementSegQualificationList,
        this.procurement.procurementMethod.id,
        this.procurementParticipant.maximumParticipant
      )
      .subscribe((vendorTempList: PopupVendorView[]) => {
        this.procurement.maxInvitingVendor += -1;
        vendorTempList.map(vendor => vendorIdList.push(vendor.id));
        const procRegBuyerInviteVendorRequest: ProcRegBuyerInviteVendorRequest =
          new ProcRegBuyerInviteVendorRequest();
        procRegBuyerInviteVendorRequest.procurement = this.procurement;
        procRegBuyerInviteVendorRequest.vendorIdList = vendorIdList;
        this.setStateProcessing();
        this.httpClientService
          .post<Response<ProcRegBuyerInviteVendorRequest>>(
            '/procurement-reg-buyer/invite-vendor',
            procRegBuyerInviteVendorRequest
          )
          .subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.snackbarService.showWarning(
                'app.msg.info.invitationEmailSent'
              );
              this.httpClientService
                .get<RegisteredProcVendorView[]>(
                  '/procurement-reg-buyer/get-vendor-list/' +
                    this.procurement.id
                )
                .subscribe(
                  (
                    registeredProcVendorViewList: RegisteredProcVendorView[]
                  ) => {
                    this.registeredProcVendorViewList =
                      registeredProcVendorViewList;
                    this.tableResponse.reload();
                    this.setStateReady();
                  }
                );
            } else {
              this.global.alertService.showError(response.statusText);
              this.setStateReady();
            }
          });
      });
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
