import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorDocMaster } from 'src/app/core/bean/vendor-doc-master';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorAdditionalDocHistory } from '../../core/bean/vendor-additional-doc-history';
import { VendorDocGroup } from '../../core/bean/vendor-doc-group';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';

@Component({
  templateUrl: './tab-document.component.html'
})
export class TabDocumentComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<VendorDocGroup>;
  public vendorDocGroupList: Array<VendorDocGroup> = new Array();
  public tabDocumentList: Array<any> = new Array();
  public tableResponseAdditionalDoc: TableResponseModel<VendorAdditionalDocHistory>;
  public tableResponse: TableResponseModel<VendorDocMaster>;
  constructor(translateService: TranslateService) {
    super('tab-document', translateService);
  }

  onInit(): void {
    this.getAndSetTabDocument();
    this.tableResponseAdditionalDocHistory();
    this.setCustomDataTableAdditional();
  }

  public getAndSetTabDocument(): void {
    this.httpClientService
      .get<Array<VendorDocGroup>>('/tab-document/index/' + this.vendorHistoryId)
      .subscribe((vendorDocGroup: Array<VendorDocGroup>) => {
        this.vendorDocGroupList = vendorDocGroup;
        this.buildFormGroup();
        this.buildTabDocumentList();
        this.model.setStateReady();
      });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorDocGroupList: this.formGroupService.builder(this.vendorDocGroupList)
    });
  }

  public buildTabDocumentList(): void {
    this.vendorDocGroupList.forEach((vendorDocGroup: VendorDocGroup) => {
      this.tableResponse = this.createTableResponse();
      const vendorDocMasterList = vendorDocGroup.vendorDocMasterList || [];
      this.tableResponse.page.records = vendorDocMasterList;
      this.tableResponse.page.totalRecords = vendorDocMasterList.length;
      const tabDocument = {
        header: vendorDocGroup.nameTranslated,
        tableResponse: this.tableResponse
      };
      this.tabDocumentList.push(tabDocument);
    });
  }

  public createTableResponse(): TableResponseModel<VendorDocMaster> {
    return new TableResponseModel(this.moduleCode, [
      {
        field: 'nameTranslated',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      {
        field: 'vendorAttribute.isMandatory',
        header: 'table.column.documentStatus',
        datamap: '{"true" : "Mandatory" , "false" : "Not Mandatory" }',
        customClass: 'text-center'
      },
      {
        field: 'vendorDocHistory.docNumber',
        header: 'table.column.documentNumber',
        customClass: 'text-center'
      },
      {
        field: 'vendorDocHistory.docPublisher',
        header: 'table.column.docPublisher'
      },
      {
        field: 'vendorDocHistory.startDate',
        header: 'table.column.startDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'vendorDocHistory.endDate',
        header: 'table.column.endDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'vendorDocHistory.file.fileName',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'vendorDocHistory.file.uploadedFileName'
      },
      {
        field: 'vendorDocHistory.isVerified',
        header: 'table.column.isVerified',
        datamap: '{"true":"Verified","false":"Not Verified"}'
      }
    ]);
  }

  public tableResponseAdditionalDocHistory(): void {
    this.tableResponseAdditionalDoc = new TableResponseModel(this.moduleCode, [
      {
        field: '0.name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      { field: '1', header: 'table.column.status' },
      {
        field: '0.docNumber',
        header: 'table.column.documentNumber',
        customClass: 'text-center'
      },
      { field: '0.docPublisher', header: 'table.column.docPublisher' },
      {
        field: '0.startDate',
        header: 'table.column.startDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: '0.endDate',
        header: 'table.column.endDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: '0.file.fileName',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: '0.file.uploadedFileName'
      },
      {
        field: '0.isVerified',
        header: 'table.column.isVerified',
        datamap: '{"true":"Verified","false":"Not Verified"}'
      }
    ]);
  }

  public setCustomDataTableAdditional(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponseAdditionalDoc.setCustomData(customData);
  }
}
