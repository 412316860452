import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './rfp-popup-cancel.component.html'
})
export class RfpPopupCancelComponent extends BaseComponentComponent {
  @Output() onChange: EventEmitter<string> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('rfp');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      note: [null, Validators.required()]
    });
  }

  doSubmit(): void {
    const note = this.formGroup.get('note').value;
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(note);
    }
  }
}
