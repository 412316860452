import { PrItem } from 'src/app/core/bean/pr-item';
import { ProcurementType } from 'src/app/core/bean/procurement-type';
import { File } from '../../core/bean/file';
import { ItemType } from '../../core/bean/item-type';
import { RfpProposalCriteria } from '../../core/bean/rfp-proposal-criteria';

export class RfpResponseResponse {
  fileList: File[] = [];
  prItemList: PrItem[] = [];
  responseFileList: File[] = [];
  itemTypeList: ItemType[] = [];
  procurementTypeList: ProcurementType[] = [];
  rfpProposalCriteriaList: RfpProposalCriteria[] = [];
}
