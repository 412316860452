import { BaseDTO } from '../../../../core/base/base-dto';
import { Employee } from '../../../../core/bean/employee';
import { EmployeeInterest } from '../../../../core/bean/employee-interest';
import { EmployeeTags } from '../../../../core/bean/employee-tags';
import { Region } from '../../../../core/bean/region';
import { VendorEntity } from '../../../../core/bean/vendor-entity';
import { FileObject } from '../../../../core/components/upload';
export class EmployeeDTO extends BaseDTO {
  employee: Employee;
  employeeInterestList: EmployeeInterest[];
  employeeTagsList: EmployeeTags[];
  regionPhoneList: Region[];
  fileNPWPList: FileObject[];
  fileEmployeeProcDocList: FileObject[];
  regionParentHierarchyList: Region[];
  vendorCategoryOptionList: VendorEntity[];
}
