<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="vendor-payment-request.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-fieldset legend="Filter">
        <form
          class="form-horizontal"
          [formGroup]="formGroup"
          novalidate
          *ngIf="!formLoading"
        >
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.invoiceDate' | translate
            }}</label>
            <div class="col-sm-9 text-left" style="display: flex">
              <app-date-picker-x size="XL" formControlName="startDate">
              </app-date-picker-x>
              <p class="ml-3 mr-3 control-label">{{
                'payment-request-review.form.until' | translate
              }}</p>
              <app-date-picker-x
                size="XL"
                formControlName="endDate"
                minDate="startDate"
              >
              </app-date-picker-x>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.status' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="paymentStatusList"
                [optionList]="paymentStatusOptionList"
              >
              </app-chosen>
            </div>
          </div>

          <div class="button-group button-group-center mt-5">
            <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()">
              <em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>
            <app-button
              mode="BYPASS"
              color="PRIMARY"
              (onClick)="doApply()"
              [disabled]="formSaving"
            >
              <em class="fas fa-check"></em>
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>
      </app-fieldset>
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/vendor-payment-request/index"
      >
        <ng-template #headerButtons>
          <app-button
            color="PRIMARY"
            class="ml-2"
            (onClick)="doAddBill()"
            title="{{ 'vendor-payment-request.tooltip.addBill' | translate }}"
            ><em class="fas fa-plus"></em
            >{{ 'vendor-payment-request.button.addBill' | translate }}
          </app-button>
        </ng-template>

        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            *ngIf="
              (rowData.payment.paymentStatus.code ===
                global.appConstant.cm.PAYMENT_STATUS_DRAFT ||
                rowData.payment.paymentStatus.code ===
                  global.appConstant.cm.PAYMENT_STATUS_WAITING_CONFIRMATION ||
                rowData.payment.paymentStatus.code ===
                  global.appConstant.cm.PAYMENT_STATUS_REVISION) &&
              (!rowData.payment.workflowModelPrcs ||
                rowData.payment.workflowModelPrcs?.code.toLowerCase() ===
                  moduleCode)
            "
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <em class="fas fa-pencil-alt"></em>
          </app-button>
          <app-button
            size="TN"
            *ngIf="
              rowData.payment.paymentStatus.code ===
              global.appConstant.cm.PAYMENT_STATUS_DRAFT
            "
            (onClick)="doDelete(rowData)"
            title="{{ 'app.tooltip.delete' | translate }}"
          >
            <em class="fas fa-trash"></em>
          </app-button>

          <app-button
            size="TN"
            color="PRIMARY"
            *ngIf="
              rowData.payment.paymentStatus.code ===
                global.appConstant.cm.PAYMENT_STATUS_WAITING_APPROVAL ||
              (rowData.payment.workflowModelPrcs &&
                rowData.payment.workflowModelPrcs?.code.toLowerCase() !==
                  moduleCode)
            "
            (onClick)="doDetail(rowData)"
            mode="BYPASS"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
