/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Global } from '../../global/global';
import { DownloadService } from '../../services/download.service';
import { FormGroupService } from '../../services/form-group.service';
import { HttpClientService } from '../../services/http-client.service';
import { LoadingBlockService } from '../../services/loading-block.service';
import { Log } from '../../services/logger';
import { ServiceLocator } from '../../services/service-locator';
@Component({
  template: ''
})
export abstract class BaseComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup = new FormGroup({});
  public router: Router;
  public formLoading = true;
  public formSaving = false;
  public global: Global;
  public moduleCode: string;
  public isEnabled = false;
  public isViewOnly = false;
  public log: Log = new Log(this);
  protected formBuilder: FormBuilder;
  protected formGroupService: FormGroupService;
  protected httpClientService: HttpClientService;
  protected loadingBlockService: LoadingBlockService;
  protected downloadService: DownloadService;
  protected abstract onBaseInit(): void;
  constructor(@Inject(String) moduleCode: string) {
    this.moduleCode = moduleCode;
    this.global = ServiceLocator.injector.get(Global);
    this.router = ServiceLocator.injector.get(Router);
    this.formBuilder = ServiceLocator.injector.get(FormBuilder);
    this.formGroupService = ServiceLocator.injector.get(FormGroupService);
    this.httpClientService = ServiceLocator.injector.get(HttpClientService);
    this.loadingBlockService = ServiceLocator.injector.get(LoadingBlockService);
    this.downloadService = ServiceLocator.injector.get(DownloadService);
  }

  public ngOnInit(): void {
    this.log.debug('[ Load Base Component ] : ' + this.moduleCode);
    this.onBaseInit();
  }

  public ngOnDestroy(): void {
    if (this.global.isModuleChange) {
      this.httpClientService.unsubscribe();
      this.global.isModuleChange = false;
    }
  }

  public setStateLoading(): void {
    this.formLoading = true;
    this.formSaving = false;
  }

  public setStateReady(): void {
    this.formGroup.enable();
    this.isEnabled = true;
    this.formLoading = false;
    this.formSaving = false;
    this.loadingBlockService.close();
  }

  public setStateProcessing(): void {
    this.formGroup.disable();
    this.isEnabled = false;
    this.formLoading = false;
    this.formSaving = true;
    this.loadingBlockService.showInfo(`app.msg.processing`);
  }

  public setStateDisabled(): void {
    this.isEnabled = false;
    this.formGroup.disable();
  }

  public setStateEnabled(): void {
    this.isEnabled = true;
    this.formGroup.enable();
  }

  public validate(): void {
    this.formGroup.setStateSubmitted();
    this.formGroupService.validateAllFormFields(this.formGroup);
    if (!this.formGroup.valid) {
      this.scrollIntoErrorView();
      this.formGroup.resetStateSubmitted();
    }
  }

  public setViewOnly(): void {
    this.formGroup.setIsView(true);
    this.isViewOnly = true;
  }

  public doDownload(
    event: MouseEvent,
    stringUrl: string,
    fileName: string
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.downloadService.download(stringUrl, fileName);
  }

  public doDownloadToRar(
    event: MouseEvent,
    stringUrl: string,
    uploadedFileNameList: string[],
    fileName: string
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.downloadService.downloadToRar(
      stringUrl,
      uploadedFileNameList,
      fileName
    );
  }

  private scrollIntoErrorView(): void {
    // setTimeOut just handle for after small element error  was created for a few moment
    // and then scroll into view
    setTimeout(() => {
      const HEADER_HEIGHT = 250;
      const errorElement = document
        .querySelectorAll('small.text-danger')
        .item(0);
      if (errorElement) {
        const windowScrollY = window.scrollY;
        console.log(windowScrollY);
        if (window.scrollY) {
          window.scrollTo({
            top:
              windowScrollY -
              HEADER_HEIGHT +
              errorElement.getBoundingClientRect().top,
            behavior: 'smooth'
          });
        }
      }
    });
  }
}
