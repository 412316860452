<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card [isLoading]="formLoading" [isSaving]="formSaving">
      <app-workflow-info
        (onChange)="doClick($event)"
        [position]="currentPosition"
        moduleGroupCode="ESM"
        stringUrl="/app-workflow-info/index"
      >
      </app-workflow-info>
    </app-card>
    <app-card
      header="worklist-pra-procurement.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        #table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/worklist-pra-procurement/index"
      >
        <ng-template #actionButtons let-rowData>
          <div *ngIf="rowData.workflowPrcs !== null">
            <app-button
              size="TN"
              *ngIf="
                rowData.workflowPrcs.workflowStatus.code ==
                  global.appConstant.core.WORKFLOW_CODE_STATUS_ON_GOING ||
                rowData.workflowPrcs.workflowStatus.code ==
                  global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION
              "
              (onClick)="doEdit(rowData)"
              title="{{ 'app.tooltip.edit' | translate }}"
            >
              <em class="fas fa-pencil-alt"></em>
            </app-button>
            <app-button
              size="TN"
              *ngIf="
                rowData.workflowPrcs.workflowStatus.code ==
                  global.appConstant.core.WORKFLOW_CODE_STATUS_FINISH ||
                rowData.workflowPrcs.workflowStatus.code ==
                  global.appConstant.core.WORKFLOW_CODE_STATUS_ON_APPROVAL
              "
              (onClick)="doView(rowData)"
              title="{{ 'app.tooltip.view' | translate }}"
            >
              <em class="fas fa-search"> </em>
            </app-button>
          </div>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
