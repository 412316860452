import { EvaluationCriteria } from '../../core/bean/evaluation-criteria';
import { Module } from '../../core/bean/module';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementCriteria } from '../../core/bean/procurement-criteria';
import { ProcurementCriteriaDetail } from '../../core/bean/procurement-criteria-detail';
import { ProcurementFinalizationCriteria } from '../../core/bean/procurement-finalization-criteria';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path-x';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';

export class EvaluationCriteriaResponse {
  isUserApproval: boolean;
  module: Module = new Module();
  procurement: Procurement = new Procurement();
  workflowStepInfoModel: WorkflowStepInfoModel = new WorkflowStepInfoModel();
  approvalPathResponseModel: ApprovalPathResponseModel =
    new ApprovalPathResponseModel();
  procurementCriteria: ProcurementCriteria = new ProcurementCriteria();
  procurementFinalizationCriteria: ProcurementFinalizationCriteria =
    new ProcurementFinalizationCriteria();
  technicalCriteriaOptList: EvaluationCriteria[] = [];
  administrationCriteriaOptList: EvaluationCriteria[] = [];
  admProcurementCriteriaDetailList: ProcurementCriteriaDetail[] = [];
  tecProcurementCriteriaDetailList: ProcurementCriteriaDetail[] = [];
}
