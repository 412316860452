import { AppPopupChooseBudgetByFilterComponent } from './app-popup-choose-budget-by-filter.component';
import { ProcurementOfflineSubmissionEditAddComponent } from './procurement-offline-submission-edit-add.component';
import { ProcurementOfflineSubmissionComponent } from './procurement-offline-submission.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
export const routes = [
  { path: '', component: ProcurementOfflineSubmissionComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: ProcurementOfflineSubmissionEditAddComponent,
    data: { breadcrumb: 'procurement-offline-submission.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ProcurementOfflineSubmissionEditAddComponent,
    data: { breadcrumb: 'procurement-offline-submission.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  { path: 'detail',
  component: ProcurementOfflineSubmissionEditAddComponent,
  data: { breadcrumb: 'procurement-offline-submission.breadcrumb.detail' }
  },
  { path: 'confirmation',
  component: ProcurementOfflineSubmissionEditAddComponent,
  data: { breadcrumb: 'procurement-offline-submission.breadcrumb.confirmation' } }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ProcurementOfflineSubmissionComponent, ProcurementOfflineSubmissionEditAddComponent, AppPopupChooseBudgetByFilterComponent],
  providers: [],
  entryComponents: [AppPopupChooseBudgetByFilterComponent],
  exports: [AppPopupChooseBudgetByFilterComponent]
})
export class ProcurementOfflineSubmissionModule {}
