import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Response } from 'src/app/core/model/response-model';
import { take } from 'rxjs/operators';
import { PurchaseRequestData } from './../../core/bean/purchase-request-data';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
@Component({
  templateUrl: './procurement-offline-submission.component.html'
})
export class ProcurementOfflineSubmissionComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<PurchaseRequestData>;

  constructor(translateService: TranslateService) {
    super('procurement-offline-submission', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '0.number',
        header: 'table.column.purchaseRequestDataNumber',
        customClass: 'text-center'
      },
      {
        field: '0.createdDate',
        header: 'table.column.purchaseRequestDataDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: '1.number',
        header: 'table.column.procurementNumber',
        customClass: 'text-center'
      },
      {
        field: '1.name',
        header: 'table.column.procurementName'
      },
      {
        field: '1.procurementType.name',
        header: 'table.column.procurementType',
        customClass: 'text-center'
      },
      {
        field: '1.createdDate',
        header: 'table.column.procurementDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: '2.name',
        header: 'table.column.stage',
        customClass: 'text-center'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/procurement-offline-submission/add']);
  }

  public doEdit(purchaseRequest: PurchaseRequestData): void {
    this.log.debug(purchaseRequest);
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('purchaseRequestId', purchaseRequest[0].id);
    this.router.navigate(['/pages/procurement-offline-submission/edit']);
  }

  public doDetail(purchaseRequest: PurchaseRequestData): void {
    this.log.debug(purchaseRequest);
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('purchaseRequestId', purchaseRequest[0].id);
    this.router.navigate(['/pages/procurement-offline-submission/detail']);
  }

  public doDelete(purchaseRequest: PurchaseRequestData, index: number): void {
    this.log.debug(index, purchaseRequest);
    this.global.modalService
    .deleteConfirmation()
    .pipe(take(1))
    .subscribe(result => {
      if (result) {
        this.setStateLoading();
        this.httpClientService
          .post<Response<PurchaseRequestData>>('/procurement-offline-submission/delete', purchaseRequest[0].id)
          .subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.tableResponse.removeAt(index);
              this.global.alertService.showSuccessDelete();
              this.tableResponse.reload();
            } else {
              this.global.alertService.showError(response.statusText);
            }
            this.setStateReady();
          });
      }
    });
  }

}
