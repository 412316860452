import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppBadgeModule } from '../../components/app-badge/app-badge.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetUnprocessedPrComponent } from './app-widget-unprocessed-pr.component';
@NgModule({
  imports: [
    VendorModule,
    AppWidgetModule,
    AppWidgetContentSliderModule,
    AppBadgeModule
  ],
  declarations: [AppWidgetUnprocessedPrComponent],
  entryComponents: [AppWidgetUnprocessedPrComponent],
  exports: [AppWidgetUnprocessedPrComponent]
})
export class AppWidgetUnprocessedPrModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      'app-widget-unprocessed-pr',
      AppWidgetUnprocessedPrComponent
    );
  }
}
