import { DatePipe } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { VendorAddressHistory } from 'src/app/core/bean/vendor-address-history';
import { VendorHistory } from 'src/app/core/bean/vendor-history';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorAdditionalDoc } from '../../core/bean/vendor-additional-doc';
import { VendorAdditionalDocHistory } from '../../core/bean/vendor-additional-doc-history';
import { VendorDocGroup } from '../../core/bean/vendor-doc-group';
import { VendorEntity } from '../../core/bean/vendor-entity';
import { VendorType } from '../../core/bean/vendor-type';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { ReportService } from '../../core/services/report.service';
import { Validators } from '../../core/validators';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfileDocumentDTO } from './dto/tab-profile-document.dto';
import { TabProfileDocumentReportRequest } from './tab-profile-document.report.request';

@Component({
  templateUrl: './tab-profile-document.component.html',
  styles: [
    '.company-profile-insert-doc { color: blue; border-style: solid; border-width: thin; }',
    '.company-profile-update-doc { color: orange; border-style: solid; border-width: thin; }',
    '.company-profile-delete-doc { color: red; border-style: solid; border-width: thin; }',
    '.company-profile-text-crud { margin: 0.5rem }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TabProfileDocumentComponent extends BaseModuleComponent {
  public userId: number;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfileDocumentDTO: TabProfileDocumentDTO =
    new TabProfileDocumentDTO();
  public vendor: Vendor = new Vendor();
  public now = new Date().getTime();
  public isLoading = false;
  public vendorTypeList: VendorType[] = [];
  public regionList: Region[] = [];
  public vendorEntityList: VendorEntity[] = [];
  public tabDocument: TabModel;
  public tabSegmentatiaon: TabModel;
  public tabCompanyData: TabModel;
  public tabGeneralInformation: TabModel;
  public dataReady = false;
  public isView: boolean;
  public hiddenAdditional = false;
  public fileUploaderAdditionalList: FileUploader[] = [];
  public fileUploaderList: FileUploader[] = [];
  public index: number;
  public isApproval = false;
  public isVendorList: boolean;
  public fromOutside: string;
  public activityStatus: string;
  public isMandatorySIUJK = false;
  public isMandatoryFCIUPJTK = false;
  public isSubmitProfiling: boolean;
  public isCheckDocument: boolean;
  public vendorAdditionalDocHistoryList: VendorAdditionalDocHistory[];
  public vendorAdditionalDocList: VendorAdditionalDoc[];
  public verificationOptionList: OptionListModel<any> = new OptionListModel();
  public roleType: number;

  constructor(
    translate: TranslateService,
    public datePipe: DatePipe,
    public reportService: ReportService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-document', translate);
  }

  onInit(): void {
    this.roleType = this.global.userSession.activeUserRole.role.type;
    this.getDataFromRouterParams();
    if (this.tabDocument.routerParams.get('tabProfileDocumentDTO')) {
      this.tabProfileDocumentDTO = this.tabDocument.routerParams.get(
        'tabProfileDocumentDTO'
      );
      this.checkSegmentation();
      this.setOptionListModel();
      this.buildFormGroup();
      this.setTabDocumentIsView();
      if (this.isSubmitProfiling || this.isCheckDocument) {
        this.formGroupService.validateAllFormFields(this.tabDocument.formGroup);
      }
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .get<TabProfileDocumentDTO>(
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_VENDOR
          ? '/tab-profile-document/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-document/index'
      )
      .subscribe(tabProfileDocumentDTO => {
        this.tabProfileDocumentDTO = tabProfileDocumentDTO;
        this.tabDocument.routerParams.set(
          'tabProfileDocumentDTO',
          tabProfileDocumentDTO
        );
        this.checkSegmentation();
        this.setOptionListModel();
        this.buildFormGroup();
        this.setTabDocumentIsView();
        if (this.isSubmitProfiling || this.isCheckDocument) {
          this.formGroupService.validateAllFormFields(
            this.tabDocument.formGroup
          );
        }
      });
  }

  public getDataFromRouterParams(): void {
    this.isSubmitProfiling = this.global.routerParams.get('isSubmitProfiling');
    this.isCheckDocument = this.global.routerParams.get('isCheckDocument');
    this.tabDocument = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_DOCUMENT);
    this.tabSegmentatiaon = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_SEGMENTATION);
    this.tabCompanyData = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_COMPANY_DATA);
    this.tabGeneralInformation = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_GENERAL_INFORMATION);
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.activityStatus = this.global.routerParams.get('activityStatus');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.todo = this.global.routerParams.get('todo');
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
  }

  public setTabDocumentIsView(): void {
    this.tabDocument.setIsView(this.isView);
  }

  public setOptionListModel(): void {
    const verificationList = [
      { name: 'Not Verified', value: false },
      { name: 'Verified', value: true }
    ];
    this.verificationOptionList.setRequestValues(verificationList);
  }

  public checkSegmentation(): void {
    if (this.tabSegmentatiaon.routerParams.get('tabProfileSegmentationDTO')) {
      const tabSegmentation = this.tabSegmentatiaon.routerParams.get(
        'tabProfileSegmentationDTO'
      );
      tabSegmentation.vendorSegmentationHistoryList.forEach(
        vendorSegmentationHistory => {
          if (
            vendorSegmentationHistory.segmentationList[0].code ===
            this.companyProfileDTO.mandatoryDocSIUJK
          ) {
            this.isMandatorySIUJK = true;
          }
          this.companyProfileDTO.mandatoryDocFCIUPJTK.forEach(
            segmentationName => {
              if (
                vendorSegmentationHistory.segmentation.code === segmentationName
              ) {
                this.isMandatoryFCIUPJTK = true;
              }
            }
          );
        }
      );
    } else {
      this.isMandatorySIUJK = this.tabProfileDocumentDTO.isSIUJKMandatory
        ? this.tabProfileDocumentDTO.isSIUJKMandatory
        : false;
      this.isMandatoryFCIUPJTK = this.tabProfileDocumentDTO.isFCIUPJTKMandatory
        ? this.tabProfileDocumentDTO.isFCIUPJTKMandatory
        : false;
    }
  }

  public buildFormGroup(): void {
    this.tabDocument.formGroup = this.formBuilder.group({
      vendorDocGroupList: this.formBuilder.array([]),
      additionalDocList: this.formBuilder.array([])
    });
    if (
      this.tabProfileDocumentDTO.vendorAdditionalDocHistoryList.length !== 0
    ) {
      this.setAdditionalDoc(
        this.tabProfileDocumentDTO.vendorAdditionalDocHistoryList
      );
    } else {
      if (this.isView) {
        this.hiddenAdditional = true;
      } else {
        this.additionalDocList.push(this.newAdditionalDoc());
      }
    }
    this.buildParents(this.tabProfileDocumentDTO.vendorDocGroupList);
  }

  public buildParents(vendorDocGroupList: VendorDocGroup[]): void {
    const parentsForm = this.tabDocument.formGroup.controls
      .vendorDocGroupList as FormArray;
    vendorDocGroupList.forEach(element => {
      parentsForm.push(
        this.formBuilder.group({
          docGroup: element.docGroup,
          fieldName: element.id,
          id: element.id,
          translationKey: element.docGroup.translationKey.key,
          vendorDocMasterList: this.formBuilder.array([])
        })
      );
    });
    this.buildVendorDocMasterList();
    this.tabProfileDocumentDTO.vendorDocGroupList.forEach(vendorDocGroup => {
      vendorDocGroup.vendorDocMasterList.forEach(vendorDocMaster => {
        if (
          vendorDocMaster.fileType.code ===
            this.global.appConstant.fileType.DOC_NIB &&
          vendorDocMaster.vendorDocHistory &&
          vendorDocMaster.vendorDocHistory.file
        ) {
          if (this.tabProfileDocumentDTO.indexDocGroupTDP) {
            const vendorDocMasterListTDP = this.vendorDocGroupList
              .at(this.tabProfileDocumentDTO.indexDocGroupTDP)
              .get('vendorDocMasterList') as FormArray;
            vendorDocMasterListTDP
              .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
              .get('isMandatory')
              .setValue(false);
            vendorDocMasterListTDP
              .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
              .get('isMandatory')
              .updateValueAndValidity();
            vendorDocMasterListTDP
              .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
              .get('mandatoryName')
              .setValue('Not Mandatory');
            vendorDocMasterListTDP
              .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
              .get('mandatoryName')
              .updateValueAndValidity();
            this.clearValidators(
              vendorDocMasterListTDP,
              this.tabProfileDocumentDTO.indexDocMasterTDP,
              false
            );
          }

          if (this.tabProfileDocumentDTO.indexDocMasterSIUP) {
            const vendorDocMasterList = this.vendorDocGroupList
              .at(this.tabProfileDocumentDTO.indexDocGroupSIUP)
              .get('vendorDocMasterList') as FormArray;
            vendorDocMasterList
              .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
              .get('isMandatory')
              .setValue(false);
            vendorDocMasterList
              .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
              .get('isMandatory')
              .updateValueAndValidity();
            vendorDocMasterList
              .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
              .get('mandatoryName')
              .setValue('Not Mandatory');
            vendorDocMasterList
              .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
              .get('mandatoryName')
              .updateValueAndValidity();
            this.clearValidators(
              vendorDocMasterList,
              this.tabProfileDocumentDTO.indexDocMasterSIUP,
              false
            );
          }
        }
      });
    });
  }

  public newAdditionalDoc(): FormGroup {
    const fileUpload: FileUploader = this.fileUploader(
      this.global.appConstant.fileType.DOC_ADDITIONAL_DOCUMENT
    );
    this.fileUploaderAdditionalList.push(fileUpload);
    return this.formBuilder.group({
      id: [null],
      name: [null],
      file: [null],
      endDate: [null],
      startDate: [null],
      docNumber: [null],
      docPublisher: [null],
      isVerified: [null],
      verifiedDate: [null],
      currentDate: new Date(),
      vendorAdditionalDocId: [null],
      startDateActual: [null],
      startDateHistory: [null],
      endDateActual: [null],
      endDateHistory: [null],
      crudInfo: [null]
    });
  }

  public setAdditionalDoc(
    vendorAdditionalDocHistoryList: VendorAdditionalDocHistory[]
  ): void {
    this.getVendorAdditionalDoc();
    vendorAdditionalDocHistoryList.forEach((vendorAdditional, index) => {
      const additionalDoc = this.vendorAdditionalDocList[index];
      let startDateActual = null;
      let endDateActual = null;
      const endDate =
        vendorAdditional.endDate !== null
          ? new Date(vendorAdditional.endDate)
          : null;
      const startDate =
        vendorAdditional.startDate !== null
          ? new Date(vendorAdditional.startDate)
          : null;
      const startDateHistory =
        startDate !== null
          ? this.datePipe.transform(
              startDate,
              this.global.config.parameterModel.dateFormatShort
            )
          : null;
      if (additionalDoc !== undefined) {
        startDateActual =
          additionalDoc.startDate !== null
            ? this.datePipe.transform(
                new Date(additionalDoc.startDate),
                this.global.config.parameterModel.dateFormatShort
              )
            : null;
        endDateActual =
          additionalDoc.endDate !== null
            ? this.datePipe.transform(
                new Date(additionalDoc.endDate),
                this.global.config.parameterModel.dateFormatShort
              )
            : null;
      }
      const endDateHistory =
        endDate !== null
          ? this.datePipe.transform(
              endDate,
              this.global.config.parameterModel.dateFormatShort
            )
          : null;
      const crudInfo = vendorAdditional.crudInfo;
      const fileUpload: FileUploader = this.fileUploader(
        this.global.appConstant.fileType.DOC_ADDITIONAL_DOCUMENT
      );
      this.fileUploaderAdditionalList.push(fileUpload);
      fileUpload.setFile(vendorAdditional.file);
      if (
        vendorAdditional.fileObjectList &&
        vendorAdditional.fileObjectList.length !== 0
      ) {
        fileUpload.fileObjectList = vendorAdditional.fileObjectList;
      }
      let verification: any;
      let verifiedDate: any;
      if (vendorAdditional.isVerified !== null) {
        verification = vendorAdditional.isVerified
          ? { name: 'Verified', value: vendorAdditional.isVerified }
          : { name: 'Not Verified', value: vendorAdditional.isVerified };
        verifiedDate = new Date(vendorAdditional.verifiedDate);
      } else {
        verification = null;
        verifiedDate = null;
      }
      const formGroup = this.formBuilder.group({
        id: vendorAdditional.id,
        name: vendorAdditional.name,
        file: fileUpload.fileObjectList,
        endDate,
        startDate,
        isVerified: [
          verification,
          vendorAdditional.file !== null && this.isView
            ? Validators.required()
            : null
        ],
        verifiedDate,
        docNumber: vendorAdditional.docNumber,
        docPublisher: vendorAdditional.docPublisher,
        vendorAdditionalDocId: vendorAdditional.vendorAdditionalDocId,
        currentDate: new Date(),
        startDateActual,
        startDateHistory,
        endDateActual,
        endDateHistory,
        crudInfo
      });
      formGroup.setIsView(this.isView);
      formGroup.controls['isVerified'].setIsView(false);
      if (this.isVendorList || this.isApproval) {
        formGroup.controls['isVerified'].setIsView(true);
      }
      if (this.activityStatus !== undefined) {
        if (!this.activityStatus.includes('VERIFICATION')) {
          formGroup.controls['isVerified'].setIsView(true);
        }
        if (
          this.activityStatus.includes('VERIFICATION') &&
          this.global.userSession.user.organization.id !==
            this.companyProfileDTO.vendorHistory.vendor.onBoardingByOrganization
              .id
        ) {
          formGroup.controls['isVerified'].setIsView(true);
          formGroup.controls['isVerified'].clearValidators();
          formGroup.controls['isVerified'].updateValueAndValidity();
        }
      }
      this.additionalDocList.push(formGroup);
      if (
        this.isSubmitProfiling &&
        (formGroup.get('docNumber').value ||
          formGroup.get('name').value ||
          formGroup.get('docPublisher').value ||
          formGroup.get('endDate').value ||
          formGroup.get('startDate').value ||
          (formGroup.get('file').value
            ? formGroup.get('file').value.length !== 0
            : formGroup.get('file').value))
      ) {
        this.setAndUpdateValidators(this.additionalDocList, index, true);
      }
    });
  }

  public buildVendorDocMasterList(): void {
    const parentsForm = this.tabDocument.formGroup.controls
      .vendorDocGroupList as FormArray;
    this.index = 0;
    this.tabProfileDocumentDTO.vendorDocGroupList.forEach(
      (vendorDocGroup, index) => {
        vendorDocGroup.vendorDocMasterList.forEach(
          (vendorDocMaster, index2) => {
            let endDate = null;
            let endDateHistory = null;
            let endDateActual = null;
            let startDate = null;
            let startDateActual = null;
            let startDateHistory = null;
            const vendorDocMasterListForm = parentsForm.controls[index].get(
              'vendorDocMasterList'
            ) as FormArray;
            const fileUpload: FileUploader = this.fileUploader(
              vendorDocMaster.fileType.code
            );
            this.fileUploaderList.push(fileUpload);
            let verification = null;
            let verifiedDate = null;
            if (vendorDocMaster.vendorDocHistory !== null) {
              if (vendorDocMaster.vendorDocHistory.endDate !== null) {
                endDate = new Date(vendorDocMaster.vendorDocHistory.endDate);
                endDateHistory = this.datePipe.transform(
                  endDate,
                  this.global.config.parameterModel.dateFormatShort
                );
              }
              if (vendorDocMaster.vendorDocHistory.startDate !== null) {
                startDate = new Date(
                  vendorDocMaster.vendorDocHistory.startDate
                );
                startDateHistory = this.datePipe.transform(
                  startDate,
                  this.global.config.parameterModel.dateFormatShort
                );
              }
              if (vendorDocMaster.vendorDocHistory.isVerified !== null) {
                // tslint:disable-next-line: max-line-length
                verification = vendorDocMaster.vendorDocHistory.isVerified
                  ? {
                      name: 'Verified',
                      value: vendorDocMaster.vendorDocHistory.isVerified
                    }
                  : {
                      name: 'Not Verified',
                      value: vendorDocMaster.vendorDocHistory.isVerified
                    };
              }
              if (vendorDocMaster.vendorDocHistory.verifiedDate !== null) {
                verifiedDate = new Date(
                  vendorDocMaster.vendorDocHistory.verifiedDate
                );
              }
            }
            if (vendorDocMaster.vendorDoc !== null) {
              if (vendorDocMaster.vendorDoc.startDate !== null) {
                startDateActual = this.datePipe.transform(
                  vendorDocMaster.vendorDoc.startDate,
                  this.global.config.parameterModel.dateFormatShort
                );
              }
              if (vendorDocMaster.vendorDoc.endDate !== null) {
                endDateActual = this.datePipe.transform(
                  vendorDocMaster.vendorDoc.endDate,
                  this.global.config.parameterModel.dateFormatShort
                );
              }
            }
            fileUpload.setFile(
              vendorDocMaster.vendorDocHistory !== null
                ? vendorDocMaster.vendorDocHistory.file
                : null
            );
            if (
              vendorDocMaster.fileObjectList &&
              vendorDocMaster.fileObjectList.length !== 0
            ) {
              fileUpload.fileObjectList = vendorDocMaster.fileObjectList;
            }
            const mandatoryName =
              vendorDocMaster.fileType.code ===
              this.global.appConstant.fileType.DOC_SIUJK
                ? this.isMandatorySIUJK
                  ? 'Mandatory'
                  : vendorDocMaster.vendorAttribute.isMandatory
                  ? 'Mandatory'
                  : 'Not Mandatory'
                : vendorDocMaster.fileType.code ===
                    this.global.appConstant.fileType.DOC_IUPJTK &&
                  this.companyProfileDTO.vendorHistory.vendorType.code ===
                    this.global.appConstant.vm.VENDOR_TYPE_LOCAL_COMPANY
                ? this.isMandatoryFCIUPJTK
                  ? 'Mandatory'
                  : vendorDocMaster.vendorAttribute.isMandatory
                  ? 'Mandatory'
                  : 'Not Mandatory'
                : vendorDocMaster.vendorAttribute.isMandatory
                ? 'Mandatory'
                : 'Not Mandatory';
            const formGroup = this.formBuilder.group({
              id: vendorDocMaster.id,
              fieldName: vendorDocMaster.description,
              file: [
                vendorDocMaster.vendorDocHistory !== null
                  ? fileUpload.fileObjectList
                  : null,
                vendorDocMaster.fileType.code ===
                this.global.appConstant.fileType.DOC_SIUJK
                  ? this.isMandatorySIUJK
                    ? Validators.required()
                    : vendorDocMaster.vendorAttribute.isMandatory
                    ? Validators.required()
                    : null
                  : vendorDocMaster.fileType.code ===
                      this.global.appConstant.fileType.DOC_IUPJTK &&
                    this.companyProfileDTO.vendorHistory.vendorType.code ===
                      this.global.appConstant.vm.VENDOR_TYPE_LOCAL_COMPANY
                  ? this.isMandatoryFCIUPJTK
                    ? Validators.required()
                    : vendorDocMaster.vendorAttribute.isMandatory
                    ? Validators.required()
                    : null
                  : vendorDocMaster.vendorAttribute.isMandatory
                  ? Validators.required()
                  : null
              ],
              endDate: [
                endDate,
                vendorDocMaster.fileType.code ===
                this.global.appConstant.fileType.DOC_SIUJK
                  ? this.isMandatorySIUJK
                    ? vendorDocMaster.isExpiry
                      ? Validators.required()
                      : null
                    : vendorDocMaster.vendorAttribute.isMandatory &&
                      vendorDocMaster.isExpiry
                    ? Validators.required()
                    : null
                  : vendorDocMaster.fileType.code ===
                      this.global.appConstant.fileType.DOC_IUPJTK &&
                    this.companyProfileDTO.vendorHistory.vendorType.code ===
                      this.global.appConstant.vm.VENDOR_TYPE_LOCAL_COMPANY
                  ? this.isMandatoryFCIUPJTK
                    ? vendorDocMaster.isExpiry
                      ? Validators.required()
                      : null
                    : vendorDocMaster.vendorAttribute.isMandatory &&
                      vendorDocMaster.isExpiry
                    ? Validators.required()
                    : null
                  : vendorDocMaster.vendorAttribute.isMandatory &&
                    vendorDocMaster.isExpiry
                  ? Validators.required()
                  : null
              ],
              startDate: [
                startDate,
                vendorDocMaster.fileType.code ===
                this.global.appConstant.fileType.DOC_SIUJK
                  ? this.isMandatorySIUJK
                    ? vendorDocMaster.isStart
                      ? Validators.required()
                      : null
                    : vendorDocMaster.vendorAttribute.isMandatory &&
                      vendorDocMaster.isStart
                    ? Validators.required()
                    : null
                  : vendorDocMaster.fileType.code ===
                      this.global.appConstant.fileType.DOC_IUPJTK &&
                    this.companyProfileDTO.vendorHistory.vendorType.code ===
                      this.global.appConstant.vm.VENDOR_TYPE_LOCAL_COMPANY
                  ? this.isMandatoryFCIUPJTK
                    ? vendorDocMaster.isStart
                      ? Validators.required()
                      : null
                    : vendorDocMaster.vendorAttribute.isMandatory &&
                      vendorDocMaster.isStart
                    ? Validators.required()
                    : null
                  : vendorDocMaster.vendorAttribute.isMandatory &&
                    vendorDocMaster.isStart
                  ? Validators.required()
                  : null
              ],
              fileType: vendorDocMaster.fileType,
              fileTypeCode: vendorDocMaster.fileType.code,
              isExpiry: vendorDocMaster.isExpiry,
              isStart: vendorDocMaster.isStart,
              isMandatory: [
                vendorDocMaster.fileType.code ===
                this.global.appConstant.fileType.DOC_SIUJK
                  ? this.isMandatorySIUJK
                    ? this.isMandatorySIUJK
                    : vendorDocMaster.vendorAttribute.isMandatory
                  : vendorDocMaster.fileType.code ===
                      this.global.appConstant.fileType.DOC_IUPJTK &&
                    this.companyProfileDTO.vendorHistory.vendorType.code ===
                      this.global.appConstant.vm.VENDOR_TYPE_LOCAL_COMPANY
                  ? this.isMandatoryFCIUPJTK
                    ? this.isMandatoryFCIUPJTK
                    : vendorDocMaster.vendorAttribute.isMandatory
                  : vendorDocMaster.vendorAttribute.isMandatory
              ],
              translationKey: vendorDocMaster.fileType.translationKey.key,
              docNumber: [
                vendorDocMaster.vendorDocHistory !== null
                  ? vendorDocMaster.vendorDocHistory.docNumber
                  : null,
                vendorDocMaster.fileType.code ===
                this.global.appConstant.fileType.DOC_SIUJK
                  ? this.isMandatorySIUJK
                    ? Validators.required()
                    : vendorDocMaster.vendorAttribute.isMandatory
                    ? Validators.required()
                    : null
                  : vendorDocMaster.fileType.code ===
                      this.global.appConstant.fileType.DOC_IUPJTK &&
                    this.companyProfileDTO.vendorHistory.vendorType.code ===
                      this.global.appConstant.vm.VENDOR_TYPE_LOCAL_COMPANY
                  ? this.isMandatoryFCIUPJTK
                    ? Validators.required()
                    : vendorDocMaster.vendorAttribute.isMandatory
                    ? Validators.required()
                    : null
                  : vendorDocMaster.vendorAttribute.isMandatory
                  ? Validators.required()
                  : null
              ],
              docPublisher: [
                vendorDocMaster.vendorDocHistory !== null
                  ? vendorDocMaster.vendorDocHistory.docPublisher
                  : null,
                vendorDocMaster.fileType.code ===
                this.global.appConstant.fileType.DOC_SIUJK
                  ? this.isMandatorySIUJK
                    ? Validators.required()
                    : vendorDocMaster.vendorAttribute.isMandatory
                    ? Validators.required()
                    : null
                  : vendorDocMaster.fileType.code ===
                      this.global.appConstant.fileType.DOC_IUPJTK &&
                    this.companyProfileDTO.vendorHistory.vendorType.code ===
                      this.global.appConstant.vm.VENDOR_TYPE_LOCAL_COMPANY
                  ? this.isMandatoryFCIUPJTK
                    ? Validators.required()
                    : vendorDocMaster.vendorAttribute.isMandatory
                    ? Validators.required()
                    : null
                  : vendorDocMaster.vendorAttribute.isMandatory
                  ? Validators.required()
                  : null
              ],
              isVerified: [
                verification,
                vendorDocMaster.vendorDocHistory !== null
                  ? vendorDocMaster.vendorDocHistory.file !== null &&
                    this.isView
                    ? Validators.required()
                    : null
                  : null
              ],
              verifiedDate,
              index: this.index,
              vendorAttribute: vendorDocMaster.vendorAttribute,
              vendorDocHistory: vendorDocMaster.vendorDocHistory,
              mandatoryName,
              currentDate: new Date(),
              vendorDoc:
                vendorDocMaster.vendorDoc !== null
                  ? vendorDocMaster.vendorDoc
                  : null,
              startDateActual,
              endDateActual,
              startDateHistory,
              endDateHistory
            });
            this.index += 1;
            formGroup.setIsView(this.isView);
            formGroup.controls['isVerified'].setIsView(false);
            if (this.isVendorList || this.isApproval) {
              formGroup.controls['isVerified'].setIsView(true);
            }
            if (this.activityStatus !== undefined) {
              if (!this.activityStatus.includes('VERIFICATION')) {
                formGroup.controls['isVerified'].setIsView(true);
              }
              if (
                this.activityStatus.includes('VERIFICATION') &&
                this.global.userSession.user.organization.id !==
                  this.companyProfileDTO.vendorHistory.vendor
                    .onBoardingByOrganization.id
              ) {
                formGroup.controls['isVerified'].setIsView(true);
                formGroup.controls['isVerified'].clearValidators();
                formGroup.controls['isVerified'].updateValueAndValidity();
              }
            }
            vendorDocMasterListForm.push(formGroup);
            if (
              this.isSubmitProfiling &&
              (formGroup.get('docNumber').value ||
                formGroup.get('docPublisher').value ||
                formGroup.get('endDate').value ||
                formGroup.get('startDate').value ||
                (formGroup.get('file').value
                  ? formGroup.get('file').value.length !== 0
                  : formGroup.get('file').value))
            ) {
              this.setAndUpdateValidators(
                vendorDocMasterListForm,
                index2,
                false
              );
            }
          }
        );
      }
    );
    this.setStateReady();
  }

  public builder(parents: any): FormGroup {
    const formGroup = {};
    Object.keys(parents).forEach(key => {
      if (
        typeof parents[key] === 'object' &&
        parents[key] &&
        !Array.isArray(parents[key])
      ) {
        Object.assign(formGroup, {
          [key]: this.builder(parents[key])
        });
      } else if (Array.isArray(parents[key]) && parents[key].length > 0) {
        Object.assign(formGroup, {
          [key]: this.formBuilder.array([])
        });

        parents[key].forEach(newParent => {
          formGroup[key].push(this.builder(newParent));
        });
      } else {
        Object.assign(formGroup, { [key]: [parents[key]] });
      }
    });

    return this.formBuilder.group(formGroup);
  }

  public fileUploader(code: string): FileUploader {
    return new FileUploader('/tab-profile-document/', 'document', code);
  }

  public get vendorDocGroupList(): FormArray {
    return this.tabDocument.formGroup.get('vendorDocGroupList') as FormArray;
  }

  public doAddAdditional(): void {
    this.additionalDocList.push(this.newAdditionalDoc());
  }

  public get additionalDocList(): FormArray {
    return this.tabDocument.formGroup.get('additionalDocList') as FormArray;
  }

  public doDelete(value: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.additionalDocList.removeAt(value);
          this.fileUploaderAdditionalList.splice(value, 1);
        }
      });
  }

  public onChangeDoc(event: any, index1: any, index2): void {
    const vendorDocMasterList = this.vendorDocGroupList
      .at(index1)
      .get('vendorDocMasterList') as FormArray;
    if (!vendorDocMasterList.at(index2).get('isMandatory').value) {
      if (event !== '') {
        this.setAndUpdateValidators(vendorDocMasterList, index2, false);
      } else {
        this.clearValidators(vendorDocMasterList, index2, false);
      }
    }
  }

  public onChangeDocUpload(event: any, index1: any, index2): void {
    const vendorDocMasterList = this.vendorDocGroupList
      .at(index1)
      .get('vendorDocMasterList') as FormArray;
    if (!vendorDocMasterList.at(index2).get('isMandatory').value) {
      if (event && event.length !== 0 && !event[0].isDeleted) {
        this.setAndUpdateValidators(vendorDocMasterList, index2, false);
      } else {
        this.clearValidators(vendorDocMasterList, index2, false);
      }
    }
    const fileTypeCode = vendorDocMasterList
      .at(index2)
      .get('fileTypeCode').value;
    const file = vendorDocMasterList.at(index2).get('file').value;
    let vendorDocMasterListTDP: FormArray;
    if (this.tabProfileDocumentDTO.indexDocGroupTDP !== null) {
      vendorDocMasterListTDP = this.vendorDocGroupList
        .at(this.tabProfileDocumentDTO.indexDocGroupTDP)
        .get('vendorDocMasterList') as FormArray;
    }
    if (
      fileTypeCode === this.global.appConstant.fileType.DOC_NIB &&
      file[0] &&
      !file[0].isDeleted
    ) {
      if (this.tabProfileDocumentDTO.indexDocGroupTDP) {
        vendorDocMasterListTDP
          .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
          .get('isMandatory')
          .setValue(false);
        vendorDocMasterListTDP
          .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
          .get('isMandatory')
          .updateValueAndValidity();
        vendorDocMasterListTDP
          .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
          .get('mandatoryName')
          .setValue('Not Mandatory');
        vendorDocMasterListTDP
          .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
          .get('mandatoryName')
          .updateValueAndValidity();
        this.clearValidators(
          vendorDocMasterListTDP,
          this.tabProfileDocumentDTO.indexDocMasterTDP,
          false
        );
      }

      if (this.tabProfileDocumentDTO.indexDocMasterSIUP) {
        vendorDocMasterList
          .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
          .get('isMandatory')
          .setValue(false);
        vendorDocMasterList
          .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
          .get('isMandatory')
          .updateValueAndValidity();
        vendorDocMasterList
          .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
          .get('mandatoryName')
          .setValue('Not Mandatory');
        vendorDocMasterList
          .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
          .get('mandatoryName')
          .updateValueAndValidity();
        this.clearValidators(
          vendorDocMasterList,
          this.tabProfileDocumentDTO.indexDocMasterSIUP,
          false
        );
      }
    } else if (fileTypeCode === this.global.appConstant.fileType.DOC_NIB) {
      if (this.tabProfileDocumentDTO.indexDocGroupTDP) {
        vendorDocMasterListTDP
          .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
          .get('isMandatory')
          .setValue(true);
        vendorDocMasterListTDP
          .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
          .get('isMandatory')
          .updateValueAndValidity();
        vendorDocMasterListTDP
          .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
          .get('mandatoryName')
          .setValue('Mandatory');
        vendorDocMasterListTDP
          .at(this.tabProfileDocumentDTO.indexDocMasterTDP)
          .get('mandatoryName')
          .updateValueAndValidity();
        this.setAndUpdateValidators(
          vendorDocMasterListTDP,
          this.tabProfileDocumentDTO.indexDocMasterTDP,
          false
        );
      }

      if (this.tabProfileDocumentDTO.indexDocMasterSIUP) {
        vendorDocMasterList
          .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
          .get('isMandatory')
          .setValue(true);
        vendorDocMasterList
          .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
          .get('isMandatory')
          .updateValueAndValidity();
        vendorDocMasterList
          .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
          .get('mandatoryName')
          .setValue('Mandatory');
        vendorDocMasterList
          .at(this.tabProfileDocumentDTO.indexDocMasterSIUP)
          .get('mandatoryName')
          .updateValueAndValidity();
        this.setAndUpdateValidators(
          vendorDocMasterList,
          this.tabProfileDocumentDTO.indexDocMasterSIUP,
          false
        );
      }
    }
  }

  public onChangeDocAdditional(event: any, index: any): void {
    if (event !== '') {
      this.setAndUpdateValidators(this.additionalDocList, index, true);
    } else {
      this.clearValidators(this.additionalDocList, index, true);
    }
  }

  public onChangeDocUploadAdditional(event: any, index: any): void {
    if (event && event.length !== 0 && !event[0].isDeleted) {
      this.setAndUpdateValidators(this.additionalDocList, index, true);
    } else {
      this.clearValidators(this.additionalDocList, index, true);
    }
  }

  public setAndUpdateValidators(
    vendorDocMasterList: any,
    index2: any,
    isAdditional: boolean
  ): void {
    if (isAdditional) {
      vendorDocMasterList
        .at(index2)
        .get('name')
        .setValidators([Validators.required()]);
      vendorDocMasterList.at(index2).get('name').updateValueAndValidity();
      vendorDocMasterList
        .at(index2)
        .get('startDate')
        .setValidators([Validators.required()]);
      vendorDocMasterList.at(index2).get('startDate').updateValueAndValidity();
      vendorDocMasterList
        .at(index2)
        .get('endDate')
        .setValidators([Validators.required()]);
      vendorDocMasterList.at(index2).get('endDate').updateValueAndValidity();
      vendorDocMasterList
        .at(index2)
        .get('file')
        .setValidators([Validators.required()]);
      vendorDocMasterList.at(index2).get('file').updateValueAndValidity();
    } else {
      if (vendorDocMasterList.at(index2).get('isStart').value) {
        vendorDocMasterList
          .at(index2)
          .get('startDate')
          .setValidators([Validators.required()]);
        vendorDocMasterList
          .at(index2)
          .get('startDate')
          .updateValueAndValidity();
      }
      if (vendorDocMasterList.at(index2).get('isExpiry').value) {
        vendorDocMasterList
          .at(index2)
          .get('endDate')
          .setValidators([Validators.required()]);
        vendorDocMasterList.at(index2).get('endDate').updateValueAndValidity();
      }
      vendorDocMasterList
        .at(index2)
        .get('file')
        .setValidators([Validators.required()]);
      vendorDocMasterList.at(index2).get('file').updateValueAndValidity();
    }
    vendorDocMasterList
      .at(index2)
      .get('docNumber')
      .setValidators([Validators.required()]);
    vendorDocMasterList.at(index2).get('docNumber').updateValueAndValidity();
    vendorDocMasterList
      .at(index2)
      .get('docPublisher')
      .setValidators([Validators.required()]);
    vendorDocMasterList.at(index2).get('docPublisher').updateValueAndValidity();
  }

  public clearValidators(
    vendorDocMasterList: any,
    index2: any,
    isAdditional: boolean
  ): void {
    if (isAdditional) {
      vendorDocMasterList.at(index2).get('name').clearValidators();
      vendorDocMasterList.at(index2).get('name').updateValueAndValidity();
      vendorDocMasterList.at(index2).get('startDate').clearValidators();
      vendorDocMasterList.at(index2).get('startDate').updateValueAndValidity();
      vendorDocMasterList.at(index2).get('endDate').clearValidators();
      vendorDocMasterList.at(index2).get('endDate').updateValueAndValidity();
      vendorDocMasterList.at(index2).get('file').clearValidators();
      vendorDocMasterList.at(index2).get('file').updateValueAndValidity();
    } else {
      if (vendorDocMasterList.at(index2).get('isStart').value) {
        vendorDocMasterList.at(index2).get('startDate').clearValidators();
        vendorDocMasterList
          .at(index2)
          .get('startDate')
          .updateValueAndValidity();
      }
      if (vendorDocMasterList.at(index2).get('isExpiry').value) {
        vendorDocMasterList.at(index2).get('endDate').clearValidators();
        vendorDocMasterList.at(index2).get('endDate').updateValueAndValidity();
      }
      vendorDocMasterList.at(index2).get('file').clearValidators();
      vendorDocMasterList.at(index2).get('file').updateValueAndValidity();
    }
    vendorDocMasterList.at(index2).get('docNumber').clearValidators();
    vendorDocMasterList.at(index2).get('docNumber').updateValueAndValidity();
    vendorDocMasterList.at(index2).get('docPublisher').clearValidators();
    vendorDocMasterList.at(index2).get('docPublisher').updateValueAndValidity();
  }

  public getVendorAdditionalDoc(): void {
    this.vendorAdditionalDocHistoryList =
      this.tabProfileDocumentDTO.vendorAdditionalDocHistoryList;
    this.vendorAdditionalDocList =
      this.tabProfileDocumentDTO.vendorAdditionalDocList;
  }

  public doPrintDocument(): void {
    let reportRequest = new TabProfileDocumentReportRequest();
    reportRequest = this.prepareDataForRequest(reportRequest);
    const msgLong = reportRequest.isFromGeneral
      ? 'tab-profile-document.popup.generateTemplate.generalMsgLong'
      : 'tab-profile-document.popup.generateTemplate.msgLong';
    this.global.modalService
      .confirm(
        this.translateService.instant(msgLong) +
          '<br/><br/>' +
          this.translateService.instant(
            'tab-profile-document.popup.generateTemplate.msgShort'
          ),
        'tab-profile-document.popup.generateTemplate.title'
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.httpClientService
            .post(
              '/tab-profile-document/update-and-check-data-report',
              reportRequest
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.printReport();
              } else {
                this.global.alertService.showError(response.statusText);
                this.setStateReady();
              }
            });
        }
      });
  }

  public printReport(): void {
    this.isLoading = true;
    this.reportService
      .print('/tab-profile-document/print', this.companyProfileDTO.vendor.id)
      .subscribe(() => {
        this.isLoading = false;
        this.setStateReady();
      });
  }

  public prepareDataForRequest(
    reportRequest: TabProfileDocumentReportRequest
  ): TabProfileDocumentReportRequest {
    const tabSegmentationDTO =
      this.tabSegmentatiaon &&
      this.tabSegmentatiaon.routerParams.get('tabProfileSegmentationDTO');
    const tabCompanyDataDTO =
      this.tabCompanyData &&
      this.tabCompanyData.routerParams.get('tabProfileCompanyDataDTO');
    const tabGeneralInfoDTO =
      this.tabGeneralInformation &&
      this.tabGeneralInformation.routerParams.get(
        'tabProfileGeneralInformationDTO'
      );
    reportRequest.isFromGeneral = this.tabGeneralInformation ? true : false;
    if (tabSegmentationDTO) {
      reportRequest.vendorSegmentationHistoryList =
        tabSegmentationDTO.vendorSegmentationHistoryList;
    }
    let vendorHistory = new VendorHistory();
    if (tabCompanyDataDTO) {
      const formGroupValue = this.tabCompanyData.formGroup.value;
      vendorHistory = this.global.copyFormAttributeToModel(
        vendorHistory,
        this.tabCompanyData.formGroup
      );
      if (formGroupValue.phone) {
        vendorHistory.phone = formGroupValue.phone.number;
        vendorHistory.phoneRegion = formGroupValue.phone.country;
      }
      vendorHistory.email = formGroupValue.email;
      vendorHistory.name = formGroupValue.name;
      vendorHistory.sequence = this.companyProfileDTO.vendorHistory.sequence;
      vendorHistory.statusApproval =
        this.companyProfileDTO.vendorHistory.statusApproval;
      vendorHistory.vendorIndustryHistoryList = null;
      vendorHistory.vendorProductLocationHistoryList = null;
      vendorHistory.vendorProductServiceCategoryHistoryList = null;
      vendorHistory.isSubmit = false;
      if (formGroupValue.isPkp === 1) {
        vendorHistory.isPkp = false;
      } else {
        vendorHistory.isPkp = true;
      }
      if (formGroupValue.keyword) {
        if (formGroupValue.keyword.length !== 0) {
          vendorHistory.keyword = formGroupValue.keyword.toString();
        } else {
          vendorHistory.keyword = null;
        }
      }
      const organization = formGroupValue.registeredInUnit;
      if (organization) {
        organization.forEach((element, index) => {
          if (index === organization.length - 1) {
            vendorHistory.organization = element;
          }
        });
      }
      vendorHistory.establishmentYear =
        formGroupValue.establishmentYear !== ''
          ? formGroupValue.establishmentYear
          : null;
      vendorHistory.totalEmployee =
        formGroupValue.totalEmployee !== ''
          ? formGroupValue.totalEmployee
          : null;

      reportRequest.vendorHistory = vendorHistory;
      reportRequest.vendorAddressHistoryList =
        tabCompanyDataDTO.vendorAddressHistoryList;
    } else if (tabGeneralInfoDTO) {
      const vendorAddressHistory: VendorAddressHistory =
        new VendorAddressHistory();
      const vendorAddressHistoryList: VendorAddressHistory[] = [];
      const formGroupValue = this.tabGeneralInformation.formGroup.value;
      vendorHistory = this.global.copyFormAttributeToModel(
        this.companyProfileDTO.vendorHistory,
        this.tabGeneralInformation.formGroup
      );
      if (formGroupValue.phone) {
        vendorHistory.phone = formGroupValue.phone.number;
        vendorHistory.phoneRegion = formGroupValue.phone.country;
        vendorAddressHistory.phone = formGroupValue.phone.number;
        vendorAddressHistory.phoneRegion = formGroupValue.phone.country;
      }
      vendorHistory.isSubmit = false;
      vendorHistory.email = formGroupValue.email;
      vendorHistory.name = formGroupValue.name;
      vendorHistory.sequence = this.companyProfileDTO.vendorHistory.sequence;
      vendorHistory.statusApproval =
        this.companyProfileDTO.vendorHistory.statusApproval;

      vendorAddressHistory.address = formGroupValue.address;
      vendorAddressHistory.cityRegion = formGroupValue.cityRegion;
      vendorAddressHistory.coordinateLocation =
        formGroupValue.coordinateLocation;
      vendorAddressHistory.countryRegion = formGroupValue.countryRegion;
      vendorAddressHistory.districtRegion = formGroupValue.districtRegion;
      vendorAddressHistory.postCode = formGroupValue.postCode;
      vendorAddressHistory.provinceRegion = formGroupValue.provinceRegion;
      vendorAddressHistory.vendorAddressId = formGroupValue.vendorAddressId;
      vendorAddressHistory.isMain = true;
      vendorAddressHistoryList.push(vendorAddressHistory);

      reportRequest.vendorHistory = vendorHistory;
      reportRequest.vendorAddressHistoryList = vendorAddressHistoryList;
    }
    return reportRequest;
  }
}
