import { Component, ViewEncapsulation } from '@angular/core';
import { BaseWidgetComponent } from '../../base/base-widget.component';
@Component({
  templateUrl: './widget-performance-overall.component.html',
  encapsulation: ViewEncapsulation.None
})
export class WidgetPerformanceOverallComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-performance-overall';
  public performanceOverall: number;
  constructor() {
    super(WidgetPerformanceOverallComponent.moduleCode);
  }

  public onInit(): void {
    this.getAndSetPerformanceOverall();
  }

  public getAndSetPerformanceOverall(): void {
    this.httpClientService
      .get('/widget-performance-overall/index')
      .subscribe((performanceOverall: number) => {
        this.performanceOverall = performanceOverall;
        this.setStateReady();
      });
  }
}
