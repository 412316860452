<app-popup
  header="app-popup-catalog-info-specification.title"
  [isShowHeaderCloseButton]="false"
  [isLoading]="formLoading"
>
<div class="col-lg-12 mb-4" *ngIf="!formLoading">
    <form
    class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
    <div
          class="form-group row text-sm-right ml-1"
        >
        <label class="col-sm-3 control-label">{{
          'app-popup-catalog-info-specification.form.specificationGroup' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            [optionList]="optionList"
            formControlName="attributeGroup"
            (onChange)="doChooseCatalogGroup($event)"
          ></app-combo-box>
        </div>
            <div class="col-lg-12 mb-4">
              <br />
              <app-form-object *ngIf="formGroup.controls.isViewFormObject.value" 
        [model]="formGroup.controls.formObjectModel.value" [isFilter]="true" (onChange)="doOnChange($event)"></app-form-object>
            </div>
        </div>
    </form>
  </div>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button
        class="mr-1"
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button (onClick)="doAdd()">{{
        'app.button.add' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
