import { BaseEntity } from '../base/base-entity';
import { RfpProposalCriteria } from '../bean/rfp-proposal-criteria';
import { RfpVendor } from '../bean/rfp-vendor';

export class RfpVendorScoringEntity extends BaseEntity {
  rfpVendor: RfpVendor = new RfpVendor();
  rfpProposalCriteria: RfpProposalCriteria = new RfpProposalCriteria();
  score: number;
  weightScore: number;
}
