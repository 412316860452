import { File } from 'src/app/core/bean/file';
import { FileObject } from '../components/upload';
import { BaseEntity } from './base-entity';
import { BaseParticipantEntity } from './base-participant-entity';
// @Injectable()
export class BaseChatEntity extends BaseEntity {

    fileObject: FileObject;

    participant: BaseParticipantEntity;
    file: File; /** file uploaded in chatbox */
    chatDate: Date;
    chatText: string;
    isChatSent: boolean;
    isChatNotify: boolean;
}
