import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { DistributionRegion } from 'src/app/core/bean/distribution-region';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './distribution-region.component.html'
})
export class DistributionRegionComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<DistributionRegion>;
  constructor(
    translateService: TranslateService
  ) {
    super('distribution-region', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'white-space-normal'
      },
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/distribution-region/add']);
  }

  public doEdit(distributionRegion: DistributionRegion): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('distributionRegionId', distributionRegion.id);
    this.router.navigate(['/pages/distribution-region/edit']);
  }

  public doDelete(distributionRegion: DistributionRegion): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<DistributionRegion>>(
              '/distribution-region/delete',
              distributionRegion
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
