<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="victim-diagnose.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'victim-diagnose.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="code"
              type="code"
              maxLength="32"
              tooltip="{{ 'victim-diagnose.tooltip.code' | translate }}"
            >
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'victim-diagnose.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="name" maxLength="255">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'victim-diagnose.form.codingIcdX' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="codingIcdX" maxLength="255">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'victim-diagnose.form.actionName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="actionName" maxLength="255">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'victim-diagnose.form.actionCodingIcdX' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="actionCodingIcdX" maxLength="255">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'victim-diagnose.form.averageDurationAction' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="averageDurationAction"
              maxLength="255"
            >
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'victim-diagnose.form.possibleComplications' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="possibleComplications"
              maxLength="255"
            >
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'victim-diagnose.form.medicalEquipmentNeeds' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="medicalEquipmentNeeds"
              maxLength="255"
            >
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'victim-diagnose.form.medicationNeeds' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="medicationNeeds" maxLength="255">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'victim-diagnose.form.note' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="note" maxLength="255">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label" for="victimInjuries">{{
            'victim-diagnose.form.victimInjuries' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-chosen
              [optionList]="victimInjuriesOptionList"
              formControlName="victimInjuriesList"
              placeholder="{{
                'victim-diagnose.placeholder.victimInjuries' | translate
              }}"
            ></app-chosen>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'victim-diagnose.form.information' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area maxLength="512" formControlName="information">
            </app-text-area>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
