import { Component, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../base/angular/base-module.component';
import { BudgetAllocation } from '../../../bean/budget-allocation';
import { WorkPlanItem } from '../../../bean/work-plan-item';
import { FieldFormatEnum } from '../../app-table/model/field-format.enum';
import { TableResponseModel } from '../../app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-budget-work-plan-info.component.html'
})
export class AppPopupBudgetWorkPlanInfoComponent extends BaseModuleComponent {
  @Input() public budget: BudgetAllocation;

  tableResponse: TableResponseModel<WorkPlanItem>;
  customData: any;
  passEntry: EventEmitter<any> = new EventEmitter();

  constructor(translateService: TranslateService) {
    super('app-popup-budget-work-plan-info', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'workPlan.number', header: 'table.column.number' },
      { field: 'workPlan.name', header: 'table.column.name' },
      {
        field: 'workPlan.organization.name',
        header: 'table.column.organization'
      },
      {
        field: 'workPlan.createdDate',
        header: 'table.column.createdDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'totalPriceItem',
        header: 'table.column.totalPriceItem',
        sortable: false,
        format: FieldFormatEnum.Currency
      },
      {
        field: 'budget.realizedAmount',
        header: 'table.column.realizedAmount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'workPlan.workPlanStatus.name',
        header: 'table.column.status',
        sortable: false
      }
    ]);
  }
}
