<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="contract-monitoring.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-fieldset legend="Filter">
        <form
          class="form-horizontal"
          [formGroup]="formGroup"
          novalidate
          *ngIf="!formLoading"
        >
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'contract-monitoring.form.contractDate' | translate
            }}</label>
            <div class="col-sm-9 text-left" style="display: flex">
              <app-date-picker
                size="XL"
                formControlName="fromDate"
                [ngStyle]="{
                  'margin-right': !formGroup.isView ? '0px' : '30px'
                }"
              >
              </app-date-picker>
              <p class="ml-3 mr-3 control-label">{{
                'contract-monitoring.form.until' | translate
              }}</p>
              <app-date-picker
                size="XL"
                formControlName="untilDate"
                minDate="fromDate"
              ></app-date-picker>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'contract-monitoring.form.contractNumber' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="contractList"
                [optionList]="contractNumberOptionList"
              ></app-chosen>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'contract-monitoring.form.vendorName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="vendorList"
                [optionList]="vendorOptionList"
              ></app-chosen>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'contract-monitoring.form.procurementName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="procurementList"
                [optionList]="procurementOptionList"
              ></app-chosen>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'contract-monitoring.form.status' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="contractStatusList"
                [optionList]="contractStatusOptionList"
              ></app-chosen>
            </div>
          </div>

          <div class="button-group button-group-center mt-5">
            <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()">
              <em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>
            <app-button
              mode="BYPASS"
              color="PRIMARY"
              (onClick)="doApply()"
              [disabled]="formSaving"
              ><em class="fas fa-check"></em>
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>
      </app-fieldset>
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/contract-monitoring/index"
      >
        <ng-template #headerButtons>
          <app-button
            mode="BYPASS"
            (onClick)="doExport()"
            [disabled]="isLoading"
            ><em
              class="fas"
              [ngClass]="
                !isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'
              "
            ></em
            >{{ 'app.button.export' | translate }}</app-button
          >
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            (onClick)="doDetail(rowData)"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <em class="fas fa-search"></em>
          </app-button>
          <app-button
            size="TN"
            (onClick)="doSendContract(rowData)"
            title="{{ 'contract-monitoring.tooltip.sendContract' | translate }}"
            color="WHITE"
            *ngIf="
              rowData.isContractWillExpired &&
              (rowData.contractStatus.code !==
                global.appConstant.cm.CONTRACT_STATUS_DONE ||
                rowData.contractStatus.code !==
                  global.appConstant.cm.CONTRACT_STATUS_REQUEST_CLOSED_CONTRACT)
            "
            style="background-color: black; border-color: black"
          >
            <span style="color: white" class="fas fa-paper-plane"></span>
          </app-button>
          <app-button
            size="TN"
            (onClick)="doSendGuarantee(rowData)"
            title="{{
              'contract-monitoring.tooltip.sendGuarantee' | translate
            }}"
            color="PRIMARY"
            *ngIf="
              rowData.isContractGuaranteeWillExpired &&
              (rowData.contractStatus.code !==
                global.appConstant.cm.CONTRACT_STATUS_DONE ||
                rowData.contractStatus.code !==
                  global.appConstant.cm.CONTRACT_STATUS_REQUEST_CLOSED_CONTRACT)
            "
          >
            <span class="fas fa-paper-plane"></span>
          </app-button>
          <app-button
            size="TN"
            (onClick)="doContractClosed(rowData)"
            title="{{
              'contract-monitoring.tooltip.contract-closed' | translate
            }}"
            color="PRIMARY"
            *ngIf="
              rowData.contractStatus.code ===
                global.appConstant.cm.CONTRACT_STATUS_REQUEST_CLOSED_CONTRACT ||
              rowData.dealingAgreementType.name ===
                global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
            "
          >
            <span class="fas fa-check-square"></span>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
