import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Global } from '../global/global';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(public global: Global, public router: Router) { }
    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.global.userSession) {
            return true;
        }
        return false;
    }

}
