import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { Rfi } from '../bean/rfi';
import { VendorHistory } from '../bean/vendor-history';

export class RfiVendorEntity extends BaseEntity {
  file: File = new File();
  submittedDate: Date;
  vendorHistory: VendorHistory = new VendorHistory();
  rfi: Rfi = new Rfi();
  isSubmitted: boolean;
  token: string;
}
