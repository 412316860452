<ng-container *ngIf="!ISVIEW; else elseIsViewTmpl">
  <ul
    class="app-check-box m-0"
    [ngClass]="[
      isInvalid ? 'is-invalid' : '',
      isInlineCheckBox ? 'multi is-inline-check-box' : 'multi'
    ]"
    *ngIf="value && value.length > 0; else elseBlock"
  >
    <li
      class="custom-control custom-checkbox"
      *ngFor="let val of value; index as i"
      [ngClass]="positionClassName"
    >
      <input
        type="checkbox"
        class="custom-control-input"
        id="{{ generatedId + i }}"
        (change)="handleChange($event, value)"
        [(ngModel)]="val.isChecked"
        [checked]="val.isChecked"
        [ngClass]="
          formControl.disabled ? 'checkbox-secondary' : 'checkbox-primary'
        "
        [disabled]="formControl.disabled"
        [indeterminate]="indeterminate"
      />
      <label
        class="custom-control-label cursor-pointer"
        for="{{ generatedId + i }}"
      >
        <ng-container *ngIf="!contentTmpl; else elseTemplate">
          {{ (optionView ? val[optionView] : val.name) | translate }}
        </ng-container>
        <ng-template #elseTemplate>
          <ng-container
            *ngTemplateOutlet="contentTmpl; context: { $implicit: val }"
          >
          </ng-container>
        </ng-template>
      </label>
    </li>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </ul>
  <ng-template #elseBlock>
    <div
      class="app-check-box custom-control custom-checkbox"
      [ngClass]="[isInvalid ? 'is-invalid' : '']"
    >
      <input
        type="checkbox"
        class="custom-control-input checkbox-primary"
        id="{{ generatedId }}"
        (change)="handleChange($event, value)"
        [(ngModel)]="value"
        [checked]="value"
        [ngClass]="
          formControl.disabled ? 'checkbox-secondary' : 'checkbox-primary'
        "
        [disabled]="formControl.disabled"
        [indeterminate]="indeterminate"
      />
      <label
        class="custom-control-label cursor-pointer"
        for="{{ generatedId }}"
      >
        <ng-content> </ng-content>
        <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
        </ng-container>
      </label>
    </div>
  </ng-template>
  <small class="text-danger d-block" *ngIf="isInvalid">
    {{ errors.message }}
  </small>
</ng-container>

<ng-template #elseIsViewTmpl>
  <div class="d-inline-block position-relative">
    <ol
      class="m-0"
      [ngStyle]="{ 'list-style-position': 'inside' }"
      *ngIf="totalChecked > 0; else elseTemplate"
    >
      <li *ngFor="let val of viewValue">
        {{ (optionView ? val[optionView] : val.name) || val || '-' }}
      </li>
    </ol>
    <ng-template #elseTemplate>
      {{ isMultipleCheckBox ? '-' : viewValue || '-' }}
    </ng-template>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>

<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"> </ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
