import { EventEmitter } from '@angular/core';
import { BudgetFilterValuesModel } from './budget-filter-values-model';
export class BudgetFilterModel {
  public reloadChanges: EventEmitter<void> = new EventEmitter();
  public filterChanges: EventEmitter<BudgetFilterValuesModel> = new EventEmitter();
  public filterValue: BudgetFilterValuesModel;
  constructor(public defaultFilterValue: BudgetFilterValuesModel) {
    this.filterValue = defaultFilterValue;
  }

  public reload(): void {
    this.reloadChanges.emit();
  }

  public reset(): void {
    this.filterValue = this.defaultFilterValue;
  }

  public setFilterValue(filterValue: BudgetFilterValuesModel): void {
    this.filterValue = { ...this.defaultFilterValue, ...filterValue };
  }
}
