import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { DocRetrievalStatus } from '../../core/bean/doc-retrieval-status';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { DocRetrievalView } from '../../core/view/entity/bean/doc-retrieval-view';
@Component({
  templateUrl: './doc-retrieval-response.component.html'
})
export class DocRetrievalResponseComponent
  extends BaseModuleComponent
  implements OnInit
{
  public tableResponse: TableResponseModel<DocRetrievalView>;
  public docRetrievalStatusOptionList: OptionListModel<DocRetrievalStatus> =
    new OptionListModel();
  constructor(translateService: TranslateService) {
    super('doc-retrieval-response', translateService);
  }
  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      dateTo: [null],
      dateFrom: [null],
      docRetrievalStatusList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<DocRetrievalStatus[]>('/doc-retrieval-response/get-option-list')
      .subscribe((statusList: DocRetrievalStatus[]) => {
        if (statusList && statusList.length > 0) {
          this.docRetrievalStatusOptionList.setRequestValues(statusList);
        }
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementVendor.procurement.code',
        header: 'table.column.procurementNumber',
        plugins: {
          name: 'hyperlink',
          className: 'white-space-normal',
          onClick: this.doClick.bind(this)
        }
      },
      {
        field: 'procurementVendor.procurement.procurementDate',
        header: 'table.column.procurementDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'procurementVendor.procurement.name',
        header: 'table.column.procurementName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'isRepeat',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              RE_HELD: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (
                  tablePluginData.row.record.procurementVendor.procurement
                    .isRepeat
                ) {
                  const RE_HELD: string = this.translateService.instant(
                    'dynamic-master-attribute.procurementFlag.reHeld'
                  );
                  return RE_HELD;
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (
                !tablePluginData.row.record.procurementVendor.procurement
                  .isRepeat
              ) {
                tablePluginData.element.children[1].remove();
              }
            }
          }
        ]
      },
      {
        field: 'retrievalDate',
        header: 'table.column.retrievalDate',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'retrievalProcess.name',
        header: 'table.column.retrievalProcess'
      },
      {
        field: 'retrievalBy',
        header: 'table.column.retriever'
      },
      {
        field: 'docRetrievalStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorMap: {
            NEW: 'INFO',
            DONE: 'SUCCESS',
            HOLD: 'DANGER',
            CANCELED: 'DANGER',
            FAILED: 'DANGER'
          },
          colorField: 'docRetrievalStatus.code'
        }
      }
    ]);
  }

  public doClick(docRetrievalView: DocRetrievalView): void {
    if (
      docRetrievalView.startDateProcSchedule &&
      docRetrievalView.endDateProcSchedule
    ) {
      const currentDate = new Date().getTime();
      const startDate = new Date(
        docRetrievalView.startDateProcSchedule
      ).getTime();
      if (
        docRetrievalView.docRetrievalStatus.code ===
          this.global.appConstant.pm.DOC_RETRIEVAL_STATUS_NEW &&
        currentDate < startDate
      ) {
        this.global.modalService.scheduleMismatch();
      } else {
        this.setRouterParamsAndNavigate(docRetrievalView);
      }
    } else {
      this.setRouterParamsAndNavigate(docRetrievalView);
    }
  }

  public setRouterParamsAndNavigate(docRetrievalView: DocRetrievalView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set(
      'procurementId',
      docRetrievalView.procurementVendor.procurement.id
    );
    this.global.routerParams.set(
      'docRetrievalStatus',
      docRetrievalView.docRetrievalStatus
    );
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/doc-retrieval-response'
    );
    this.global.routerParams.set('todo', 'view');
    this.router.navigate(['/pages/doc-retrieval-response/detail']);
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        dateFrom: this.formGroup.value.date?.from,
        dateTo: this.formGroup.value.date?.to
      });
    } else {
      this.formGroup.get('dateFrom').reset();
      this.formGroup.get('dateTo').reset();
    }

    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
