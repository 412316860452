import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  selector: 'app-widget-content-slider',
  templateUrl: './app-widget-content-slider.component.html',
  styleUrls: ['./app-widget-content-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetContentSliderComponent extends BaseComponentComponent {
  @Input() isShowControl = true;
  @ViewChild('widgetContentSlider') widgetContentSlider: ElementRef;

  @ContentChild('contentOuterLeft') contentOuterLeftTmpl: TemplateRef<any>;
  @ContentChild('contentOuterRight') contentOuterRightTmpl: TemplateRef<any>;

  constructor() {
    super('app-widget-content-slider');
  }

  public onInit(): void {
    this.setStateReady();
  }

  public doScrollToLeft(): void {
    const element = this.widgetContentSlider.nativeElement;
    const parentScrollWidth = this.widgetContentSlider.nativeElement
      .parentElement.scrollWidth;
    const scrollLeft = element.scrollLeft;
    element.scrollTo(-(parentScrollWidth - scrollLeft), 0);
  }

  public doScrollToRight(): void {
    const element = this.widgetContentSlider.nativeElement;
    const parentScrollWidth = this.widgetContentSlider.nativeElement
      .parentElement.scrollWidth;
    const scrollLeft = element.scrollLeft;
    element.scrollTo(parentScrollWidth + scrollLeft, 0);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
