import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ResourceOwnership } from '../../core/bean/resource-ownership';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './resource-ownership-edit-add.component.html'
})
export class ResourceOwnershipEditAddComponent extends BaseModuleComponent {
  public resourceOwnershipId: number;
  public valueOptionList: OptionListModel<any> = new OptionListModel();

  constructor(translateService: TranslateService) {
    super('resource-ownership', translateService);
  }

  public onInit(): void {
    this.setOptionListRequestValue();
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.getAndSetResourceOwnershipToFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.resourceOwnershipId = this.global.routerParams.get(
      'resourceOwnershipId'
    );
  }

  public setOptionListRequestValue(): void {
    const valueList: any[] = [
      { name: '0', value: 0 },
      { name: '1', value: 1 },
      { name: '2', value: 2 },
      { name: '3', value: 3 },
      { name: '4', value: 4 },
      { name: '5', value: 5 }
    ];
    this.valueOptionList.setRequestValues(valueList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      value: [null, Validators.required()],
      description: [null, Validators.maxLength(512)]
    });
  }

  public getAndSetResourceOwnershipToFormGroup(): void {
    this.httpClientService
      .post<ResourceOwnership>(
        '/resource-ownership/add-edit',
        new RouteRequestModel(this.todo, this.resourceOwnershipId)
      )
      .subscribe(resourceOwnership => {
        if (resourceOwnership != null) {
          const { id, name, code, value, description } = resourceOwnership;
          this.formGroup.patchValue({
            id,
            name,
            code,
            value: this.valueOptionList
              .getRequestValues()
              .filter(valueOption => valueOption.value === value)[0],
            description
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const resourceOwnership = this.global.copyFormAttributeToModel(
              new ResourceOwnership(),
              this.formGroup
            );
            resourceOwnership.value = this.formGroup.value.value.value;

            const url =
              this.todo === 'edit'
                ? '/resource-ownership/update'
                : '/resource-ownership/insert';
            this.httpClientService
              .post<Response<ResourceOwnership>>(url, resourceOwnership)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/resource-ownership/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/resource-ownership/']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
