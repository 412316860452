<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="performance-fulfillment.titleContract"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/performance-fulfillment/index-contract"
      >
        <ng-template #headerButtons>
          <app-button mode="BYPASS" (onClick)="doExport()"
            [disabled]="isLoading">
            <em class="fas"
              [ngClass]="!isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'">
            </em>{{ 'app.button.export' | translate }}
          </app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            mode="BYPASS"
            color="PRIMARY"
            size="TN"
            (onClick)="doView(rowData)"
            title="{{ 'app.tooltip.view' | translate }}"
          > 
            <span class="fas fa-search"></span>
          </app-button> <!-- [0] = psw.id -->
        </ng-template>
      </app-table>

      <div class="button-group button-group-center">
        <app-button
          mode="BYPASS"
          color="SECONDARY"
          (onClick)="doBack()"
          >{{ 'app.button.back' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
