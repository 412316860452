import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { PrItem } from '../bean/pr-item';
import { FileObject } from '../components/upload';

export class PrItemImageEntity extends BaseEntity {
  fileObjectList: FileObject[] = [];
  prItem: PrItem = new PrItem();
  file: File = new File();
}
