import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppPopupProgressBarDownloadModule } from '../../components/app-popup/app-popup-progress-bar-download/app-popup-progress-bar-download.module';
import { AppWorklistInfoModule } from '../../components/app-worklist-info/app-worklist-info.module';
import { SharedModule } from '../../shared/shared.module';
import { ReportRecapProcurementPopupChooseColumnComponent } from './report-recap-procurement-popup-choose-column.component';
import { ReportRecapProcurementComponent } from './report-recap-procurement.component';

export const routes = [
  {
    path: '',
    component: ReportRecapProcurementComponent,
    data: { breadcrumb: 'report-recap-procurement.breadcrumb.index' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppWorklistInfoModule,
    AppPopupProgressBarDownloadModule
  ],
  declarations: [
    ReportRecapProcurementComponent,
    ReportRecapProcurementPopupChooseColumnComponent
  ]
})
export class ReportRecapProcurementModule {}
