import { ComplianceType } from '../../core/bean/compliance-type';
import { User } from '../../core/bean/user';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';
import { FileObject } from '../../core/components/upload';
import { ComplianceTestModel } from './compliance-test.model';

export class ComplianceTestUpdateRequest {
  public procurementModelList: ComplianceTestModel[];
  public vendorModelList: ComplianceTestModel[];
  public complianceType: ComplianceType;
  public complianceNote: string;
  public isSubmit: boolean;
  public procurementId: number;
  public isRevise: boolean;
  public isDone: boolean;

  public summaryNote: string;
  public fileObjectList: FileObject[];
  public preparedByUserList: User[];
  public checkedByUserList: User[];

  public appOfficialReportModel: AppOfficialReportModel;
}
