<ng-container *ngIf="!model.isLoading">
  <ng-container *ngFor="let tabDocument of tabDocumentList">
    <h6 class="separator">{{ tabDocument.header }}</h6>
    <app-table [tableResponse]="tabDocument.tableResponse"></app-table>
  </ng-container>
  <h6 class="separator">{{
    'tab-document.separator.additional' | translate
  }}</h6>
  <app-table
    [isServerSide]="true"
    stringUrl="/tab-document/index-additional-doc"
    [model]="tableResponseAdditionalDoc"
  >
  </app-table>
</ng-container>
