<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-x
        [model]="tableResponse"
        stringUrl="/pr-po-verification/index"
        (onClick)="doClick($event)"
        header="{{ 'order.table.header' | translate }}"
        isShowEditTable="true"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <div
              class="form-group row text-sm-left"
              style="margin-bottom: 0.25rem"
            >
              <label class="ml-3" style="margin-top: auto">{{
                'order.form.postDate' | translate
              }}</label>

              <div class="col-sm-3 text-left">
                <app-date-picker-x
                  size="XL"
                  formControlName="postDate"
                  [range]="true"
                  (onChange)="onChangeFilter()"
                >
                </app-date-picker-x>
              </div>

              <label class="ml-3" style="margin-top: auto">{{
                'order.form.requiredDate' | translate
              }}</label>

              <div class="col-sm-3 text-left">
                <app-date-picker-x
                  size="XL"
                  formControlName="requiredDate"
                  [range]="true"
                  (onChange)="onChangeFilter()"
                >
                </app-date-picker-x>
              </div>
              <div class="col-2">
                <app-dropdown-select
                  type="CHOSEN"
                  size="XL"
                  formControlName="organizationList"
                  [optionList]="organizationOptionList"
                  (onChange)="onChangeFilter($event)"
                  showLimitValue="1"
                  placeholder="{{ 'order.placeholder.department' | translate }}"
                >
                  <ng-template #selectAll>
                    <p> {{ 'order.placeholder.allDepartment' | translate }} </p>
                  </ng-template>
                </app-dropdown-select>
              </div>
              <div class="col-2">
                <app-dropdown-select
                  type="CHOSEN"
                  size="XL"
                  formControlName="statusList"
                  [optionList]="statusOptionList"
                  (onChange)="onChangeFilter($event)"
                  showLimitValue="1"
                  placeholder="{{ 'order.placeholder.status' | translate }}"
                >
                  <ng-template #selectAll>
                    <p> {{ 'order.placeholder.allStatus' | translate }} </p>
                  </ng-template>
                  <ng-template #option let-data>
                    <p> {{ data?.name }} </p>
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>
          </form>
        </ng-template>
      </app-table-x>
    </div>
  </div>
</app-layout>