import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { RfpStatus } from '../../core/bean/rfp-status';
import { RfpVendor } from '../../core/bean/rfp-vendor';
import { AppTableXComponent } from '../../core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
@Component({
  templateUrl: './rfp-response.component.html'
})
export class RfpResponseComponent extends BaseModuleComponent {
  @ViewChild(AppTableXComponent) table: AppTableXComponent;
  public tableResponse: TableResponseModel<RfpVendor>;
  public statusOptionList: OptionListModel<RfpStatus> = new OptionListModel(
    false,
    'name'
  );
  public customData = '';
  constructor(translateService: TranslateService) {
    super('rfp-response', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      rfpDate: [null],
      rfpFromDate: [null],
      rfpUntilDate: [null],
      dueDate: [null],
      dueFromDate: [null],
      dueUntilDate: [null],
      statusList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<RfpStatus[]>('/rfp-response/get-option-list')
      .subscribe(response => {
        if (response != null) {
          this.statusOptionList.setRequestValues(response);
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'rfp.code',
        header: 'table.column.rfpNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doEdit.bind(this)
        }
      },
      {
        field: 'rfp.title',
        header: 'table.column.title'
      },
      {
        field: 'submittedDate',
        header: 'table.column.submittedDate',
        plugins: 'date'
      },
      {
        field: 'rfp.endDate',
        header: 'table.column.responseDueDate',
        plugins: 'date'
      },
      {
        field: 'rfpResponseStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'rfpResponseStatus.code',
          colorMap: {
            DRAFT: 'SECONDARY',
            ON_PROGRESS: 'PROGRESS',
            WAITING_APPROVAL: 'WARNING',
            WAITING_RESPONSE: 'GOOD',
            CANCELED: 'DANGER',
            CLOSED_RESPONSE: 'DANGER',
            DONE: 'SUCCESS',
            COMPLETED: 'SUCCESS',
            SUBMITTED: 'SUCCESS'
          }
        }
      }
    ]);

    const isFromWidget = this.global.routerParams.get('isFromWidget');
    if (isFromWidget) {
      this.tableResponse.setCustomData({ isFromWidget: true });
      this.global.routerParams.clear();
    }
  }

  doEdit(rfpVendor: RfpVendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('rfpVendor', rfpVendor);
    this.global.routerParams.set('rfpVendorId', rfpVendor.id);
    this.global.routerParams.set('urlBackOutside', '/pages/rfp-response');
    if (
      rfpVendor.rfpResponseStatus.code ===
        this.global.appConstant.pm.RFP_STATUS_WAITING_RESPONSE ||
      rfpVendor.rfpResponseStatus.code ===
        this.global.appConstant.pm.RFP_STATUS_DRAFT
    ) {
      this.router.navigate(['/pages/rfp-response/edit']);
      this.global.routerParams.set('todo', 'edit');
    } else {
      this.router.navigate(['/pages/rfp-response/detail']);
      this.global.routerParams.set('todo', 'view');
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.rfpDate) {
      this.formGroup.patchValue({
        rfpFromDate: this.formGroup.value.rfpDate.from,
        rfpUntilDate: this.formGroup.value.rfpDate.to
      });
    } else {
      this.formGroup.patchValue({
        rfpFromDate: null,
        rfpUntilDate: null
      });
    }
    if (this.formGroup.value.dueDate) {
      this.formGroup.patchValue({
        dueFromDate: this.formGroup.value.dueDate.from,
        dueUntilDate: this.formGroup.value.dueDate.to
      });
    } else {
      this.formGroup.patchValue({
        dueFromDate: null,
        dueUntilDate: null
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
