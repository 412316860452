<app-popup
  header="order.popup.editCondition.title"
  [isShowHeaderCloseButton]="false"
  [isLoading]="formLoading"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'order.form.term' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        {{
          orderTc.tcDetail.termType.translationKey.module.code.toLowerCase() +
            '.' +
            orderTc.tcDetail.termType.translationKey.key ||
            orderTc.tcDetail.termType.name | translate
        }}
      </div>
    </div>

    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'order.form.condition' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-wysiwyg formControlName="condition" size="XL"> </app-wysiwyg>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button color="PRIMARY" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
