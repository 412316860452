import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { BillingTermEditAddComponent } from './billing-term-edit-add.component';
import { BillingTermComponent } from './billing-term.component';
const routes: Routes = [
  { path: '', component: BillingTermComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: BillingTermEditAddComponent,
    data: { breadcrumb: 'billing-term.breadcrumb.add' }
  },
  {
    path: 'edit',
    component: BillingTermEditAddComponent,
    data: { breadcrumb: 'billing-term.breadcrumb.edit' }
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [BillingTermComponent, BillingTermEditAddComponent]
})
export class BillingTermModule {}
