import { ProcurementMethod } from '../../core/bean/procurement-method';
import { ProcurementType } from 'src/app/core/bean/procurement-type';
import { FileObject } from 'src/app/core/components/upload';
import { File } from '../../core/bean/file';
import { Organization } from '../../core/bean/organization';
import { Currency } from '../../core/bean/currency';
import { CostCenter } from '../../core/bean/cost-center';
import { BudgetType } from '../../core/bean/budget-type';
import { ProcurementOfflinePaymentHistory } from '../../core/bean/procurement-offline-payment-history';
import { PurchaseRequestData } from '../../core/bean/purchase-request-data';
import { ProcurementOfflinePayment } from '../../core/bean/procurement-offline-payment';
import { ProcurementOffline } from 'src/app/core/bean/procurement-offline';

export class ProcurementOfflineResponse {
    isNumberGenerator: number;
    procurementOffline: ProcurementOffline;
    purchaseRequestData: PurchaseRequestData;
    procurementOfflinePaymentList: ProcurementOfflinePayment[] = [];
    procurementOfflinePaymentHistoryList: ProcurementOfflinePaymentHistory[] = [];
    budgetTypeList: BudgetType[] = [];
    costCenterList: CostCenter[] = [];
    currencyList: Currency[] = [];
    procurementTypeList: ProcurementType[] = [];
    procurementMethodList: ProcurementMethod[] = [];
    organizationPurchaseList: Organization[] = [];
    fileList: File[];
    fileObjectList: FileObject[];
}
