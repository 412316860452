<div class="row work-path-row">
  <div class="col-12">
    <app-card>
      <div class="app-work-path" [attr.id]="generatedId">
        <div
          class="loading-wrapper"
          [ngStyle]="{ height: '100%' }"
          *ngIf="formLoading"
        >
          <em class="fas fa-circle-notch fa-spin fa-2x fa-fw"></em>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </app-card>
  </div>
</div>
