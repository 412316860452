import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ApprovalPrcsEditRequest } from '../../../pages/approval-prcs/approval-prcs-edit-request';
import { ApprovalPrcsEditResponse } from '../../../pages/approval-prcs/approval-prcs-edit-response';
import { ApprovalPrcsUpdateRequest } from '../../../pages/approval-prcs/approval-prcs-update-request';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ApprovalModelPrcs } from '../../bean/approval-model-prcs';
import { File } from '../../bean/file';
import { ReviseCondition } from '../../bean/revise-condition';
import { WorkflowModelPrcs } from '../../bean/workflow-model-prcs';
import { WorkflowPrcs } from '../../bean/workflow-prcs';
import { OptionListModel } from '../../model/option-list-model';
import { Response } from '../../model/response-model';
import { ResponseStatusModel } from '../../model/response-status-model';
import { Validators } from '../../validators';
import { ApprovalPathModel } from '../app-approval-path';
import { FieldFormatEnum } from '../app-table-x/model/field-format.enum';
import { TableResponseModel } from '../app-table-x/model/table-response-model';
import { FileUploader } from '../upload/model/v1/file.uploader';
@Component({
  selector: 'app-approval-prcs-x',
  templateUrl: './app-approval-prcs-x.component.html'
})
export class AppApprovalPrcsXComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() private workflowModelPrcs: WorkflowModelPrcs;
  @Input() private approvalModelPrcsId: number;
  @Output() public approveFn: EventEmitter<boolean> = new EventEmitter();
  @Input() public moduleFormGroup: FormGroup = new FormGroup({});

  public isShow: boolean;
  public isLoadingSection: boolean;
  public isShowButtonReject: boolean;
  public fileUploader: FileUploader;
  public approvalModelPrcs: ApprovalModelPrcs;
  public approvalPath: ApprovalPathModel<object>;
  public tableResponse: TableResponseModel<ApprovalModelPrcs>;

  private approvalModelPrcsList: ApprovalModelPrcs[];
  private statusList: OptionListModel<any> = new OptionListModel(true);
  private reviseConditionOptionList: OptionListModel<any> = new OptionListModel(
    true
  );
  public reviseConditionList: ReviseCondition[];

  public referModuleCode: string;
  public workflowPrcs: WorkflowPrcs;
  constructor() {
    super('app-approval-prcs');
  }

  public statusConfirmationList = [
    {
      value: 1,
      title: `${this.moduleCode}.confirmation.approve.title`,
      msg: `${this.moduleCode}.confirmation.approve.msg`
    },
    {
      value: 2,
      title: `${this.moduleCode}.confirmation.revision.title`,
      msg: `${this.moduleCode}.confirmation.revision.msg`
    },
    {
      value: 3,
      title: `${this.moduleCode}.confirmation.hold.title`,
      msg: `${this.moduleCode}.confirmation.hold.msg`
    },
    {
      value: 4,
      title: `${this.moduleCode}.confirmation.reject.title`,
      msg: `${this.moduleCode}.confirmation.reject.msg`
    }
  ];

  onInit(): void {
    this.setInitializationState();
    this.buildFormGroup();
    this.buildTableResponse();
    if (typeof this.workflowModelPrcs === 'undefined') {
      this.setStateLoading();
    }
    this.setIsShowButtonRejectState();
  }

  ngOnChanges(): void {
    this.getAndSetApprovalModelPrcs();
    this.isShow = true;
  }

  private setInitializationState(): void {
    this.approvalPath = new ApprovalPathModel(this.moduleCode, 'READ');
    this.fileUploader = new FileUploader(
      '/approval-prcs/',
      '',
      this.global.appConstant.fileType.DOC_APPROVAL
    );
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      requestedDate: [null, Validators.compose([Validators.required()])],
      file: [new File()],
      note: [null],
      fileApproval: [null],
      status: [null, Validators.compose([Validators.required()])],
      reviseCondition: [null]
    });
  }

  private buildTableResponse(): void {
    this.tableResponse = new TableResponseModel('approval-prcs', [
      { field: 'position.name', header: 'table.column.position' },
      { field: 'processedByName', header: 'table.column.processedBy' },
      {
        field: 'processedDate',
        header: 'table.column.date',
        format: FieldFormatEnum.ShortDateAndTime,
        customClass: 'text-center'
      },
      { field: 'note', header: 'table.column.note' },
      {
        field: 'file.fileName',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName'
      },
      {
        field: 'statusText',
        header: 'table.column.status',
        customClass: 'text-center',
        plugins: {
          name: 'badge',
          field: 'status',
          colorMap: {
            0: 'INFO',
            1: 'SUCCESS',
            2: 'FEEDBACK',
            3: 'DANGER',
            4: 'DANGER'
          }
        }
      }
    ]);
  }

  public getAndSetApprovalModelPrcs(): void {
    this.isLoadingSection = true;
    const approvalPrcsEditRequest: ApprovalPrcsEditRequest =
      new ApprovalPrcsEditRequest();
    approvalPrcsEditRequest.workflowModelPrcs = this.workflowModelPrcs;
    approvalPrcsEditRequest.approvalModelPrcsId = this.approvalModelPrcsId;
    this.httpClientService
      .post<ApprovalPrcsEditResponse>(
        '/approval-prcs/edit-approval-prcs',
        approvalPrcsEditRequest
      )
      .subscribe((approvalPrcsEditResponse: ApprovalPrcsEditResponse) => {
        if (approvalPrcsEditResponse !== null) {
          this.statusList.setRequestValues(
            approvalPrcsEditResponse.approvalPrcsStatusModelList
          );
          const { type, userApproverList } =
            approvalPrcsEditResponse.approvalPathResponseModel;
          this.fileUploader.setFile(
            approvalPrcsEditResponse.approvalModelPrcs.file
          );
          this.approvalModelPrcsList =
            approvalPrcsEditResponse.approvalModelPrcsList;
          this.approvalPath.setApproverList(userApproverList).setType(type);
          this.approvalModelPrcs = approvalPrcsEditResponse.approvalModelPrcs;
          if (
            approvalPrcsEditResponse.reviseConditionList &&
            approvalPrcsEditResponse.reviseConditionList.length > 0
          ) {
            this.reviseConditionOptionList.setRequestValues(
              approvalPrcsEditResponse.reviseConditionList
            );
            this.reviseConditionList =
              approvalPrcsEditResponse.reviseConditionList;
          }
          this.formGroup.patchValue({
            id: approvalPrcsEditResponse.approvalModelPrcs.id,
            requestedDate:
              approvalPrcsEditResponse.approvalModelPrcs.approvalPrcs
                .requestedDate,
            fileApproval: this.fileUploader.fileObjectList,
            file: approvalPrcsEditResponse.approvalModelPrcs.file,
            note: approvalPrcsEditResponse.approvalModelPrcs.note,
            status: approvalPrcsEditResponse.approvalPrcsStatusModelList.find(
              status =>
                status.value ===
                approvalPrcsEditResponse.approvalModelPrcs.status
            ),
            reviseCondition:
              approvalPrcsEditResponse.approvalModelPrcs.reviseCondition
          });
          this.referModuleCode =
            approvalPrcsEditResponse.approvalModelPrcs.approvalPrcs.workflowModelPrcs.code
              .replace('APPROVAL-', '')
              .toLowerCase();
          this.workflowPrcs =
            approvalPrcsEditResponse.approvalModelPrcs.approvalPrcs.workflowModelPrcs.workflowPrcs;
          this.setTableResponseState();
          this.isLoadingSection = false;
          this.setStateReady();
          if (
            approvalPrcsEditResponse.approvalModelPrcs.isViewOnly ||
            approvalPrcsEditResponse.approvalModelPrcs.approvalPrcs
              .workflowModelPrcs.workflowPrcs.workflowStatus.code ===
              this.global.appConstant.core.WORKFLOW_CODE_STATUS_FINISH
          ) {
            this.setViewOnly();
          }
        }
      });
  }

  private setTableResponseState(): void {
    this.tableResponse.records = this.approvalModelPrcsList;
    this.setStateReady();
  }

  public doSave(): void {
    this.validate();
    this.formGroupService.validateAllFormFields(this.moduleFormGroup);
    if (this.formGroup.valid && this.moduleFormGroup.valid) {
      const status = this.formGroup.get('status').value.value;
      const statusConfirmation = this.statusConfirmationList[+status - 1];
      const title = statusConfirmation.title;
      const message = statusConfirmation.msg;
      this.global.modalService
        .confirm(message, title)
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            if (this.approveFn) {
              this.approveFn.emit(true);
            }

            const approvalModelPrcs: ApprovalModelPrcs =
              this.global.copyFormAttributeToModel(
                this.approvalModelPrcs,
                this.formGroup
              );
            const approvalPrcsUpdateRequest: ApprovalPrcsUpdateRequest =
              new ApprovalPrcsUpdateRequest();
            approvalPrcsUpdateRequest.approvalModelPrcs = approvalModelPrcs;
            approvalPrcsUpdateRequest.approvalModelPrcs.status = status;
            approvalPrcsUpdateRequest.fileObjectList =
              this.formGroup.get('fileApproval').value;
            this.httpClientService
              .post<Response<ApprovalModelPrcs>>(
                '/approval-prcs/update',
                approvalPrcsUpdateRequest
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/approval-prcs']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  private setIsShowButtonRejectState(): void {
    const workflowPrcsObjectName =
      this.workflowModelPrcs.workflowPrcs.objectName.toLowerCase();
    this.isShowButtonReject = !(
      workflowPrcsObjectName.includes('vendorperformance') ||
      workflowPrcsObjectName.includes('contract') ||
      workflowPrcsObjectName === 'rfpbean' ||
      workflowPrcsObjectName === 'orderbean'
    );
  }

  public doBack(): void {
    this.router.navigate(['/pages/approval-prcs']);
  }

  public onChangeStatus(event): void {
    if (
      event &&
      event.value === 2 &&
      this.reviseConditionList &&
      this.reviseConditionList.length > 0
    ) {
      this.formGroup
        .get('reviseCondition')
        .setValidators(Validators.required());
      this.formGroup.get('reviseCondition').updateValueAndValidity();
    } else {
      this.formGroup.get('reviseCondition').clearValidators();
      this.formGroup.get('reviseCondition').updateValueAndValidity();
    }

    if (event && event.value !== 1) {
      if (event && event.value === 2) {
        this.formGroup.get('note').setValidators(Validators.required());
        this.formGroup.get('fileApproval').clearValidators();
      } else {
        this.formGroup.get('note').setValidators(Validators.required());
        this.formGroup.get('fileApproval').setValidators(Validators.required());
      }
    } else {
      this.formGroup.get('note').clearValidators();
      this.formGroup.get('fileApproval').clearValidators();
    }
    this.formGroup.get('note').updateValueAndValidity();
    this.formGroup.get('fileApproval').updateValueAndValidity();
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
