import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { ProcurementPq } from '../bean/procurement-pq';
import { ProcurementPqDocBuyerStatus } from '../bean/procurement-pq-doc-buyer-status';
import { ProcurementPqDocVendorStatus } from '../bean/procurement-pq-doc-vendor-status';
import { ProcurementVendor } from '../bean/procurement-vendor';
import { FileObject } from '../components/upload';

export class ProcurementPqDocEntity extends BaseEntity {
  fileObjectList: FileObject[];
  procurementVendor: ProcurementVendor;
  procurementPq: ProcurementPq;
  procurementPqDocBuyerStatus: ProcurementPqDocBuyerStatus;
  procurementPqDocVendorStatus: ProcurementPqDocVendorStatus;
  file: File;
  documentDate: Date;
  note: string;
}
