import { OptionListModel } from '../../../model/option-list-model';
export class BudgetFilterTemplateData {
  viewOptionList: OptionListModel<string> = new OptionListModel(false);
  periodeOptionList: OptionListModel<{
    periode: number;
    currentPeriode: boolean;
  }> = new OptionListModel(false, 'periode', 'periode');
  budgetTotal: string;
  capexRealizedBudgetTotal: { total: string; width: number };
  opexRealizedBudgetTotal: { total: string; width: number };
  capexBudgetTotal: string;
  opexBudgetTotal: string;
  capex: { total: string; width: number };
  opex: { total: string; width: number };
}
