<app-popup
  header="aanwijzing.popup.createSession.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-left">
      <label class="col-sm-4">
        {{ 'aanwijzing.form.aanwijzingType' | translate }}
      </label>
      <div class="col">
        <app-dropdown-select
          size="XL"
          formControlName="transactionType"
          [optionList]="transactionTypeOptionList"
          (onChange)="onChangeType($event)"
        >
        </app-dropdown-select>
      </div>
    </div>

    <div class="form-group row text-sm-left">
      <label class="col-sm-4">
        {{ 'aanwijzing.form.startSession' | translate }}
        <p class="d-inline-block position-relative mb-1 ml-1">
          <app-tooltip-wrapper
            tooltip="{{ 'aanwijzing.tooltip.scheduleRange' | translate }}"
          ></app-tooltip-wrapper>
        </p>
      </label>

      <div class="col">
        <app-date-picker-x
          size="XL"
          formControlName="periodStartDate"
          [time]="true"
          minDate="minStartDate"
          maxDate="maxStartDate"
          (onChange)="onChangeStartDate($event)"
        >
        </app-date-picker-x>
      </div>
    </div>

    <div class="form-group row text-sm-left">
      <label class="col-sm-4">
        {{ 'aanwijzing.form.endSession' | translate }}
        <p class="d-inline-block position-relative mb-1 ml-1">
          <app-tooltip-wrapper
            tooltip="{{ 'aanwijzing.tooltip.scheduleRange' | translate }}"
          ></app-tooltip-wrapper>
        </p>
      </label>
      <div class="col">
        <app-date-picker-x
          size="XL"
          formControlName="periodEndDate"
          [time]="true"
          minDate="minEndDate"
          maxDate="maxEndDate"
          (onChange)="onChangeEndDate($event)"
        >
          <!--[time]="true"-->
        </app-date-picker-x>
      </div>
    </div>

    <div
      *ngIf="
        formGroup.value.transactionType &&
        formGroup.value.transactionType?.code ===
          global.appConstant.core.TRANSACTION_TYPE_OFFLINE
      "
      class="form-group row text-sm-left"
    >
      <label class="col-sm-4">
        {{ 'aanwijzing.form.location' | translate }}
      </label>
      <div class="col">
        <app-text-area
          size="XL"
          formControlName="location"
          maxLength="512"
        ></app-text-area>
      </div>
    </div>

    <div class="form-group row text-sm-left">
      <label class="col-sm-4">
        {{ 'aanwijzing.form.subject' | translate }}
      </label>
      <div class="col">
        <app-text-area
          size="XL"
          formControlName="subject"
          maxLength="128"
        ></app-text-area>
      </div>
    </div>

    <div class="form-group row text-sm-left">
      <label class="col-sm-4">
        {{ 'aanwijzing.form.mandatoryForVendor' | translate }}
      </label>
      <div *ngIf="!previousAanwijzing" class="col">
        <app-toggle-switch
          formControlName="isVendorMandatory"
        ></app-toggle-switch>
        <small class="text-primary">{{
          'aanwijzing.tooltip.cantChangeOptionSession' | translate
        }}</small>
      </div>
      <div *ngIf="previousAanwijzing" class="col">
        {{
          (formGroup.value.isVendorMandatory
            ? 'aanwijzing.isVendorMandatory.yes'
            : 'aanwijzing.isVendorMandatory.no'
          ) | translate
        }}
      </div>
    </div>

    <div *ngIf="previousAanwijzing" class="form-group row text-sm-left">
      <label class="col-sm-4">
        {{ 'aanwijzing.form.extendSession' | translate }}
      </label>
      <div class="col">
        <app-check-box
          formControlName="isExtendSession"
          (onChange)="onChangeExtend($event)"
        >
          {{ 'aanwijzing.form.extendFromSession' | translate }}
          <p> {{ previousAanwijzing.code }} </p>
        </app-check-box>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
        >
          {{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button (onClick)="doCreate()">
          {{ 'aanwijzing.button.createSession' | translate }}</app-button
        >
      </div>
    </ng-template>
  </form>
</app-popup>
