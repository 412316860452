<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-card
      header="contract-termination.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion [isLoading]="formLoading">
        <app-accordion-item
          header="contract-termination.accordion.terminationDataForm"
          [isExpanded]="true"
        >
          <div class="col-12 text-right mb-3">
            <app-button
              *ngIf="todo === 'add'"
              color="PRIMARY"
              (onClick)="doChangeContract()"
              >{{ 'contract-termination.button.changeContract' | translate }}
            </app-button>
          </div>
          <form class="form-horizontal" [formGroup]="formGroup" novalidate>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'contract-termination.form.date' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-date-picker formControlName="date" [minDate]="currentDate">
                </app-date-picker>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'contract-termination.form.uploadFile' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-upload
                  formControlName="terminationFile"
                  [fileUploader]="fileUploader"
                ></app-upload>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'contract-termination.form.description' | translate
              }}</label>
              <div
                class="text-left"
                [ngClass]="formGroup.isView ? 'col-sm-6' : 'col-sm-9'"
              >
                <app-text-area formControlName="description"></app-text-area>
              </div>
            </div>
          </form>
        </app-accordion-item>
        <app-accordion-item
          header="contract-termination.accordion.contractInformation"
        >
          <app-procurement-information-view
            *ngIf="todo !== 'view'"
            [contractId]="contractId"
          >
          </app-procurement-information-view>
          <app-procurement-information-view
            *ngIf="todo === 'view'"
            [contractId]="contractTerminationEditAddResponse.contract.id"
          >
          </app-procurement-information-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-termination.accordion.procurementInformation"
        >
          <app-procurement-information-view
            *ngIf="todo !== 'view'"
            [procurementVendorId]="contract.procurementVendor.id"
          >
          </app-procurement-information-view>
          <app-procurement-information-view
            *ngIf="todo === 'view'"
            [procurementVendorId]="
              contractTerminationEditAddResponse.contract.procurementVendor.id
            "
          >
          </app-procurement-information-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-termination.accordion.vendorInformation"
        >
          <app-vendor-payment-information
            *ngIf="todo !== 'view'"
            [procurementVendorId]="contract.procurementVendor.id"
            [temp]="formGroup.value"
            [todo]="todo"
          ></app-vendor-payment-information>
          <app-vendor-payment-information
            *ngIf="todo === 'view'"
            [procurementVendorId]="
              contractTerminationEditAddResponse.contract.procurementVendor.id
            "
            [temp]="formGroup.value"
            [todo]="todo"
          ></app-vendor-payment-information>
        </app-accordion-item>
        <app-accordion-item
          header="contract-termination.accordion.requestInformation"
        >
          <app-contract-request-view
            *ngIf="todo !== 'view'"
            [contractId]="contractId"
          >
          </app-contract-request-view>
          <app-contract-request-view
            *ngIf="todo === 'view'"
            [contractId]="contractTerminationEditAddResponse.contract.id"
          >
          </app-contract-request-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-termination.accordion.logApproval"
          [isHidden]="todo === 'add'"
        >
          <app-approval-log
            [moduleCode]="moduleCode"
            [workflowPrcs]="workflowPrcs"
          >
          </app-approval-log>
        </app-accordion-item>
        <app-accordion-item
          header="contract-termination.accordion.header.historyReviewContract"
        >
          <app-contract-review-log
            [contractId]="contractId"
            [code]="global.appConstant.cm.REVIEW_TERMINATION"
          >
          </app-contract-review-log>
        </app-accordion-item>
      </app-accordion>
      <div
        class="floating-button mt-5"
        *ngIf="!contractTerminationEditAddResponse.isUserApproval"
      >
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                mode="BYPASS"
                color="SECONDARY"
                (onClick)="doBack()"
                class="mr-1"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}
              </app-button>
              <app-button
                color="PRIMARY"
                (onClick)="doSave()"
                [disabled]="formSaving"
                *ngIf="!formGroup.isView"
                >{{ 'app.button.save' | translate }}</app-button
              >
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
<app-approval-prcs
  *ngIf="contractTerminationEditAddResponse.isUserApproval && !formLoading"
  [workflowModelPrcs]="workflowModelPrcs"
>
</app-approval-prcs>
