import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../../core/guard/confirm.guard';
import { SharedModule } from '../../../core/shared/shared.module';
import { LogoEditAddComponent } from './logo-edit-add.component';
const routes = [
  {
    path: '',
    component: LogoEditAddComponent,
    data: { breadcrumb: 'logo.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
  // { path: 'logo-reload', loadChildren: () => import('src/app/app.module').then(m => m.AppModule), data: { breadcrumb: 'Logo Reload' } }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [LogoEditAddComponent]
})
export class LogoModule {}
