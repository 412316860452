import { BaseEntity } from '../base/base-entity';
import { WorkPlan } from '../bean/work-plan';

export class TorSubmissionEntity extends BaseEntity {
  workPlan: WorkPlan;
  number: string;
  createdDate: Date;
  subject: string;
  description: string;
  sequence: number;
}
