<div class="row">
  <div
    class="col-12"
    *ngIf="
      widgetService.isEdit ||
      (!widgetService.isEdit && model && model.TOP.length > 0)
    "
  >
    <div
      class="widget-wrapper"
      [ngClass]="[widgetService.isEdit ? 'is-edit' : '']"
    >
      <ng-container
        *ngTemplateOutlet="
          widgetWrapperAction;
          context: { $implicit: model.TOP, position: 'TOP' }
        "
      >
      </ng-container>
      <app-drag-and-drop
        dragDropId="topWidgetList"
        [dataList]="model.TOP"
        [connectedTo]="['leftWidgetList', 'rightWidgetList']"
        [disabled]="!widgetService.isEdit"
        (onChange)="doOnChange($event, 'TOP')"
      >
        <ng-template #dragDropItem let-selector let-i="index">
          <div class="widget-content" [selector]="selector">
            <div class="hover-background">
              <div class="button-delete">
                <app-button
                  color="DANGER"
                  (onClick)="handleDeleteContent(model && model.TOP, i)"
                  size="TN"
                >
                  <em class="pir pi-trash"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </div>
              <div class="hb-content">
                <em class="pir pi-expand-arrows-alt pi-3x mr-2"></em>
                <span class="hbc-text">Drag to move widget position</span>
              </div>
            </div>
          </div>
        </ng-template>
        <button class="add-widget" *ngIf="model && model.TOP.length === 0"
          >Empty Column</button
        >
      </app-drag-and-drop>
    </div>
  </div>

  <div
    class="col-6"
    *ngIf="
      widgetService.isEdit ||
      (!widgetService.isEdit && model && model.LEFT.length > 0)
    "
  >
    <div
      class="widget-wrapper"
      [ngClass]="[widgetService.isEdit ? 'is-edit' : '']"
    >
      <ng-container
        *ngTemplateOutlet="
          widgetWrapperAction;
          context: { $implicit: model && model.LEFT, position: 'LEFT' }
        "
      >
      </ng-container>
      <app-drag-and-drop
        dragDropId="leftWidgetList"
        [dataList]="model && model.LEFT"
        [connectedTo]="['topWidgetList', 'rightWidgetList']"
        [disabled]="!widgetService.isEdit"
        (onChange)="doOnChange($event, 'LEFT')"
      >
        <ng-template #dragDropItem let-selector let-i="index">
          <div class="widget-content" [selector]="selector">
            <div class="hover-background">
              <div class="button-delete">
                <app-button
                  color="DANGER"
                  (onClick)="handleDeleteContent(model && model.LEFT, i)"
                  size="TN"
                >
                  <em class="pir pi-trash"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </div>
              <div class="hb-content">
                <em class="pir pi-expand-arrows-alt pi-3x mr-2"></em>
                <span class="hbc-text">Drag to move widget position</span>
              </div>
            </div>
          </div>
        </ng-template>
        <button class="add-widget" *ngIf="model && model.LEFT.length === 0"
          >Empty Column</button
        >
      </app-drag-and-drop>
    </div>
  </div>

  <div
    class="col-6"
    *ngIf="
      widgetService.isEdit ||
      (!widgetService.isEdit && model && model.RIGHT.length > 0)
    "
  >
    <div
      class="widget-wrapper"
      [ngClass]="[widgetService.isEdit ? 'is-edit' : '']"
    >
      <ng-container
        *ngTemplateOutlet="
          widgetWrapperAction;
          context: { $implicit: model.RIGHT, position: 'RIGHT' }
        "
      >
      </ng-container>
      <app-drag-and-drop
        dragDropId="rightWidgetList"
        [dataList]="model && model.RIGHT"
        [connectedTo]="['leftWidgetList', 'topWidgetList']"
        [disabled]="!widgetService.isEdit"
        (onChange)="doOnChange($event, 'RIGHT')"
      >
        <ng-template #dragDropItem let-selector let-i="index">
          <div class="widget-content" [selector]="selector">
            <div class="hover-background">
              <div class="button-delete">
                <app-button
                  color="DANGER"
                  (onClick)="handleDeleteContent(model && model.RIGHT, i)"
                  size="TN"
                >
                  <em class="pir pi-trash"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </div>
              <div class="hb-content">
                <em class="pir pi-expand-arrows-alt pi-3x mr-2"></em>
                <span class="hbc-text">Drag to move widget position</span>
              </div>
            </div>
          </div>
        </ng-template>
        <button class="add-widget" *ngIf="model && model.RIGHT.length === 0"
          >Empty Column</button
        >
      </app-drag-and-drop>
    </div>
  </div>
</div>
<ng-template #widgetWrapperAction let-model let-position="position">
  <ul class="widget-wrapper-action">
    <li
      (click)="handleAddWidget(model, position)"
      title="{{ 'app-widget-announcement.addNewWidget' | translate }}"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
      ><em class="fas fa-plus"></em
    ></li>
    <li
      (click)="handleCopyRow(model)"
      title="{{ 'app-widget-announcement.copyAllWidgets' | translate }}"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
      ><em class="fas fa-copy"></em
    ></li>
    <li
      (click)="handlePasteRow(model)"
      title="{{ 'app-widget-announcement.pasteWidgets' | translate }}"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
      ><em class="fas fa-paste"></em
    ></li>
    <li
      (click)="handleDeleteRow(model)"
      title="{{ 'app-widget-announcement.clearAll' | translate }}"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
      ><em class="fas fa-trash"></em
    ></li>
  </ul>
</ng-template>
