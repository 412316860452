import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupComparisonComponent } from './app-popup-comparison.component';
@NgModule({
    imports: [VendorModule, AppPopupModule, AppButtonModule],
    declarations: [AppPopupComparisonComponent],
    entryComponents: [AppPopupComparisonComponent],
    exports: [AppPopupComparisonComponent]
})
export class AppPopupComparisonModule { }
