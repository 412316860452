import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ReportRecap } from 'src/app/core/bean/report-recap';
import { RoleReportRecap } from 'src/app/core/bean/role-report-recap';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Role } from '../../core/bean/role';
import { RoleDashboardWidget } from '../../core/bean/role-dashboard-widget';
import { Widget } from '../../core/bean/widget';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { RoleRequest } from './role-request';
import { RoleResponse } from './role-response';

@Component({
  templateUrl: 'role-edit-add.component.html'
})
export class RoleEditAddComponent extends BaseModuleComponent {
  public roleId: number;
  public referenceCode: string;
  public dashboardWidgetOptionList: OptionListModel<any> = new OptionListModel(
    false,
    'module.name'
  );
  public reportRecapOptionList: OptionListModel<any> = new OptionListModel(
    false
  );
  public roleFormIdentityOptionList: OptionListModel<any> =
    new OptionListModel();
  constructor(translateService: TranslateService) {
    super('role', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doSetRoleFormIdentityOptionList();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetRoleFormIdentityOptionList(): void {
    const roleFormIdentityOptionList = [
      {
        id: this.global.appConstant.core.ROLE_TYPE_COMMITTEE,
        name: 'role.form.committee'
      },
      {
        id: this.global.appConstant.core.ROLE_TYPE_VENDOR,
        name: 'role.form.vendor'
      },
      {
        id: this.global.appConstant.core.ROLE_TYPE_OTHER,
        name: 'role.form.other'
      },
      {
        id: this.global.appConstant.core.ROLE_TYPE_USER,
        name: 'role.form.user'
      }
    ];
    this.roleFormIdentityOptionList.setRequestValues(
      roleFormIdentityOptionList
    );
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.roleId = this.global.routerParams.get('roleId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      module: [null, Validators.required()],
      report: [null],
      type: [null, Validators.required()]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<RoleResponse>(
        '/role/add-edit',
        new RouteRequestModel(this.todo, this.roleId)
      )
      .subscribe((roleResponse: RoleResponse) => {
        this.dashboardWidgetOptionList.setRequestValues(
          roleResponse.moduleDashboardWidgetList
        );
        this.reportRecapOptionList.setRequestValues(
          roleResponse.reportRecapList
        );
        if (roleResponse.role != null) {
          const { id, code, name, type, referenceCode } = roleResponse.role;
          this.formGroup.patchValue({ id, code, name, type });
          this.referenceCode = referenceCode;
          this.doSetDashboardWidgetAndReportRecap(roleResponse);
        }
        this.setStateReady();
      });
  }

  public doSetDashboardWidgetAndReportRecap(roleResponse: RoleResponse): void {
    const widgetList: Array<Widget> = roleResponse.roleDashboardWidgetList.map(
      (roleDashboardWidget: RoleDashboardWidget) => roleDashboardWidget.widget
    );
    const reportList: Array<ReportRecap> = roleResponse.roleReportRecapList.map(
      (roleReportRecap: RoleReportRecap) => roleReportRecap.reportRecap
    );
    this.formGroup.patchValue({
      module: widgetList,
      report: reportList
    });
    // this.formGroup.get('module').patchValue(widgetList);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url = this.todo === 'edit' ? '/role/update' : '/role/insert';
            const roleRequest = new RoleRequest();
            const role: Role = new Role();
            role.id = this.formGroup.get('id').value;
            role.code = this.formGroup.get('code').value;
            role.name = this.formGroup.get('name').value;
            role.type = this.formGroup.get('type').value;
            role.referenceCode = this.referenceCode;
            roleRequest.role = role;
            roleRequest.moduleDashboardWidgetList =
              this.formGroup.get('module').value;
            roleRequest.reportRecapList = this.formGroup.get('report').value;
            this.httpClientService
              .post<Response<RoleResponse>>(url, roleRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/role/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/role/']);
  }
}
