import { BaseEntity } from '../base/base-entity';
import { EvaluationCriteria } from '../bean/evaluation-criteria';
import { ProcurementCriteria } from '../bean/procurement-criteria';

export class ProcurementCriteriaDetailEntity extends BaseEntity {
  crudOperation: number;

  procurementCriteria: ProcurementCriteria = new ProcurementCriteria();
  evaluationCriteria: EvaluationCriteria = new EvaluationCriteria();
  score: number;
}
