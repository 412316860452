import { BaseEntity } from '../base/base-entity';
import { Currency } from '../bean/currency';
import { File } from '../bean/file';
import { VendorFinancialStatus } from '../bean/vendor-financial-status';
import { VendorHistory } from '../bean/vendor-history';
import { FileObject } from '../components/upload';
export class VendorFinancialHistoryEntity extends BaseEntity {
    returnOfEquity: string;
    fileObjectList: FileObject[] = [];
    crudInfo: string;

    vendorHistory: VendorHistory;
    file: File;
    vendorFinancialStatus: VendorFinancialStatus;
    currency: Currency;
    createdDate: Date;
    year: number;
    auditNumber: string;
    date: Date;
    auditor: string;
    currentAsset: number;
    currentLiability: number;
    currentRatio: number;
    totalLiability: number;
    equity: number;
    deptEquityRatio: number;
    netIncome: number;
    roe: number;
    cash: number;
    totalActiva: number;
    vendorFinancialId: number;
}
