import { BaseEntity } from '../base/base-entity';
import { BiddingEvaluation } from '../bean/bidding-evaluation';
import { BiddingSubmissionType } from '../bean/bidding-submission-type';
import { CurrencyType } from '../bean/currency-type';
import { DocumentSubmissionType } from '../bean/document-submission-type';
import { NegotiationType } from '../bean/negotiation-type';
import { PriceEvaluationType } from '../bean/price-evaluation-type';
import { ProcurementMethod } from '../bean/procurement-method';
import { ThresholdRange } from '../bean/threshold-range';
import { VendorSelectionType } from '../bean/vendor-selection-type';

export class ProcurementMatrixEntity extends BaseEntity {
  thresholdRange: ThresholdRange;
  biddingEvaluation: BiddingEvaluation;
  currencyType: CurrencyType;
  procurementMethod: ProcurementMethod;
  documentSubmissionType: DocumentSubmissionType;
  negotiationType: NegotiationType;
  biddingSubmissionType: BiddingSubmissionType;
  priceEvaluationType: PriceEvaluationType;
  vendorSelectionType: VendorSelectionType;
  name: string;
  description: string;
  isPq: boolean;
  isShowVendorResult: boolean;
}
