import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { PerformanceFulfillmentDetailContractComponent } from './performance-fulfillment-detail-contract.component';
import { PerformanceFulfillmentDetailTerminComponent } from './performance-fulfillment-detail-termin.component';
import { PerformanceFulfillmentDetailComponent } from './performance-fulfillment-detail.component';
import { PerformanceFulfillmentEditComponent } from './performance-fulfillment-edit.component';
import { PerformanceFulfillmentComponent } from './performance-fulfillment.component';
const routes = [
  {
    path: '',
    component: PerformanceFulfillmentComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    data: { breadcrumb: 'performance-fulfillment.breadcrumb.contract' },
    children: [
      { path: '', component: PerformanceFulfillmentDetailComponent },
      {
        path: 'detail-termin',
        component: PerformanceFulfillmentDetailTerminComponent,
        data: { breadcrumb: 'performance-fulfillment.breadcrumb.detailContract' }
      },
      {
        path: 'edit',
        component: PerformanceFulfillmentEditComponent,
        data: { breadcrumb: 'performance-fulfillment.breadcrumb.edit' }
      },
      {
        path: 'detail-result',
        component: PerformanceFulfillmentDetailContractComponent,
        data: { breadcrumb: 'performance-fulfillment.breadcrumb..detailResult' }
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    PerformanceFulfillmentComponent,
    PerformanceFulfillmentDetailComponent,
    PerformanceFulfillmentDetailTerminComponent,
    PerformanceFulfillmentEditComponent,
    PerformanceFulfillmentDetailContractComponent
  ],
  providers: []
})
export class PerformanceFulfillmentModule {}
