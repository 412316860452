import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/internal/operators/take';
import { Validators } from 'src/app/core/validators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Contract } from '../../core/bean/contract';
import { ContractNegotiation } from '../../core/bean/contract-negotiation';
import { ContractNegotiationHistory } from '../../core/bean/contract-negotiation-history';
import { ContractWorklistStatus } from '../../core/bean/contract-worklist-status';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { AppPopupVersionHistoryService } from '../../core/components/app-popup/app-popup-version-history/app-popup-version-history.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ContractNegotiationEditResponse } from './contract-negotiation-edit-response';
import { ContractNegotiationPopupContractReviewerComponent } from './contract-negotiation-popup-contract-reviewer.component';
import { ContractNegotiationUpdateRequest } from './contract-negotiation-update-request';

@Component({
  templateUrl: './contract-negotiation-edit-add.component.html'
})
export class ContractNegotiationEditAddComponent extends BaseModuleComponent {
  public contractNegotiation: ContractNegotiation = new ContractNegotiation();
  public contractNegotiationHistory: ContractNegotiationHistory;
  public contract: Contract = new Contract();
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public contractNegotiationEditResponse: ContractNegotiationEditResponse =
    new ContractNegotiationEditResponse();
  public contractNegotiationUpdateRequest: ContractNegotiationUpdateRequest =
    new ContractNegotiationUpdateRequest();

  public statusOptionList: OptionListModel<any> = new OptionListModel(false);
  public fileUploader: FileUploader = new FileUploader(
    '/contract-negotiation/',
    '',
    this.global.appConstant.fileType.DOC_CONTRACT,
    false,
    1
  );
  public fileReceivedUploader: FileUploader = new FileUploader(
    '/contract-negotiation/',
    '',
    this.global.appConstant.fileType.DOC_CONTRACT,
    false,
    1
  );

  public urlBackOutside: string;
  public contractWorklistStatus: ContractWorklistStatus;
  public contractNegotiationId: number;
  public approvalModelPrcsId: number;
  public objectId: number;
  public contractId: number;
  public isRevision = false;
  public roleCode: string;
  public token: string;

  public badgeColor = {
    NEW: 'INFO',
    REVISION: 'FEEDBACK',
    WAITING_CONFIRMATION: 'GOOD',
    ON_PROGRESS: 'PROGRESS',
    REVISION_BY_VENDOR: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING'
  };
  public getColorAndIconConfirm = {
    APPROVED: { color: 'text-success', icon: 'pic pi-thumbs-up-circle' },
    REVISION: { color: 'text-primary', icon: 'pic pi-pencil-circle' }
  };
  public APPROVED = 1;
  public REVISED = 2;

  constructor(
    translateService: TranslateService,
    public appPopupVersionHistoryService: AppPopupVersionHistoryService,
    public appPopupService: AppPopupService,
    public activatedRoute: ActivatedRoute
  ) {
    super('contract-negotiation', translateService);
  }

  onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<ContractNegotiation>(
        '/contract-negotiation/notification/' + this.token
      )
      .subscribe((contractNego: ContractNegotiation) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('contractId', contractNego.contract.id);
        const roleCode = this.global.userSession.activeUserRole.role.code;
        let urlBack = '/pages/contract-negotiation';
        let contractWorklistStatus = contractNego.vendorNegotiationStatus;
        let todo = '';
        if (roleCode !== this.global.appConstant.ROLE_CODE_VENDOR) {
          urlBack = '/pages/worklist-cm';
          contractWorklistStatus = contractNego.contract.contractWorklistStatus;
          if (
            contractNego.contract.contractWorklistStatus?.code ===
              this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_NEW ||
            contractNego.contract.contractWorklistStatus?.code ===
              this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_DRAFT ||
            contractNego.contract.contractWorklistStatus?.code ===
              this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_REVISION ||
            contractNego.contract.contractWorklistStatus?.code ===
              this.global.appConstant.cm
                .CONTRACT_WORKLIST_STATUS_REVISION_BY_VENDOR
          ) {
            todo = 'edit';
          } else {
            todo = 'view';
          }
        } else {
          this.global.routerParams.set('contractNegotiation', contractNego);
          this.global.routerParams.set('contract', contractNego.contract);
          if (
            contractNego.vendorNegotiationStatus.code ===
              this.global.appConstant.cm
                .CONTRACT_WORKLIST_STATUS_WAITING_CONFIRMATION ||
            contractNego.vendorNegotiationStatus.code ===
              this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_ON_PROGRESS
          ) {
            todo = 'view';
          } else {
            todo = 'edit';
          }
        }
        this.global.routerParams.set('todo', todo);
        this.global.routerParams.set('urlBackOutside', urlBack);
        this.global.routerParams.set(
          'contractWorklistStatus',
          contractWorklistStatus
        );
        this.router.navigate(['/pages/contract-negotiation/edit']);
      });
  }

  public setInitializationState(): void {
    this.doSetStatusOptionList();
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    this.contract = this.global.routerParams.get('contract');
    this.contractId = this.global.routerParams.get('contractId');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId != null) {
      this.contractId = this.objectId;
    }
    this.contractWorklistStatus = this.global.routerParams.get(
      'contractWorklistStatus'
    );
    this.contractNegotiation = this.global.routerParams.get(
      'contractNegotiation'
    );
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.roleCode = this.global.userSession.activeUserRole.role.code;
  }

  public doSetStatusOptionList(): void {
    const statusList = [
      {
        name: this.global.translateService.instant(
          'contract-negotiation.status.approve'
        ),
        value: 1,
        code: 'APPROVED'
      },
      {
        name: this.global.translateService.instant(
          'contract-negotiation.status.revision'
        ),
        value: 2,
        code: 'REVISION'
      }
    ];
    this.statusOptionList.setRequestValues(statusList);
  }

  public onClickVersionHistory(): void {
    this.appPopupVersionHistoryService.open(
      this.contractNegotiationEditResponse.appPopupVersionHistoryModelList
    );
  }

  public onChangeStatus(): void {
    const status = this.formGroup.value.status?.value;
    if (status === this.REVISED) {
      this.isRevision = true;
      this.formGroup
        .get('note')
        .setValidators([Validators.required(), Validators.maxLength(512)]);
      this.formGroup.get('note').updateValueAndValidity();
      this.formGroup.get('docFile').setIsView(false);
      this.formGroup.get('docFile').setValidators(Validators.required());
      this.formGroup.get('docFile').updateValueAndValidity();
    } else if (status === this.APPROVED) {
      this.isRevision = false;
      this.formGroup.get('note').clearValidators();
      this.formGroup.get('note').updateValueAndValidity();
      this.formGroup.get('docFile').setIsView(true);
      this.formGroup.get('docFile').clearValidators();
      this.formGroup.get('docFile').updateValueAndValidity();
    }
  }

  public setIsViewOnly(): void {
    if (
      this.todo === 'view' ||
      this.contractWorklistStatus.code ===
        this.global.appConstant.cm
          .CONTRACT_WORKLIST_STATUS_WAITING_CONFIRMATION ||
      this.contractWorklistStatus.code ===
        this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_ON_PROGRESS ||
      this.contractWorklistStatus.code ===
        this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_WAITING_APPROVAL
    ) {
      this.setViewOnly();
    }
    this.formGroup.get('docReceivedFile').setIsView(true);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      docFile: [null, Validators.required()],
      status: [
        this.statusOptionList
          .getRequestValues()
          .filter(status => status.value === this.APPROVED)[0],
        Validators.required()
      ],
      note: [null],
      docReceivedFile: [null]
    });

    if (this.roleCode === this.global.appConstant.ROLE_CODE_VENDOR) {
      this.formGroup.get('docFile').setIsView(true);
      this.formGroup.get('docFile').clearValidators();
      this.formGroup.get('docFile').updateValueAndValidity();
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ContractNegotiationEditResponse>(
        '/contract-negotiation/add-edit',
        new RouteRequestModel(this.todo, this.contractId)
      )
      .subscribe(contractNegotiationEditResponse => {
        this.contractNegotiationEditResponse = contractNegotiationEditResponse;
        this.contract = this.contractNegotiationEditResponse.contract;
        this.contractNegotiation =
          this.contractNegotiationEditResponse.contractNegotiation;
        this.contractNegotiationHistory =
          this.contractNegotiationEditResponse.contractNegotiationHistory;
        this.fileUploader.setFile(this.contractNegotiation.docFile);
        this.fileReceivedUploader.setFile(
          this.contractNegotiationHistory.docFile
        );
        this.formGroup.patchValue({
          docReceivedFile: this.fileReceivedUploader.fileObjectList
        });

        if (!this.contractWorklistStatus) {
          this.contractWorklistStatus =
            this.contractNegotiation.userNegotiationStatus;
        }

        this.setIsViewOnly();
        if (this.isViewOnly) {
          this.formGroup.patchValue({
            docFile: this.fileUploader.fileObjectList,
            note: this.contractNegotiationHistory.note,
            status: this.contractNegotiation.isVendorRevised
              ? this.statusOptionList
                  .getRequestValues()
                  .filter(status => status.value === this.REVISED)[0]
              : this.statusOptionList
                  .getRequestValues()
                  .filter(status => status.value === this.APPROVED)[0]
          });
        }
        this.setStateReady();
        if (this.roleCode === this.global.appConstant.ROLE_CODE_VENDOR) {
          this.onChangeStatus();
        } else if (this.contractNegotiation.sequence === 1 && this.isViewOnly) {
          this.formGroup.patchValue({
            docFile: this.fileUploader.fileObjectList
          });
        }
      });
  }

  public doViewReviewer(): void {
    this.appPopupService.open(
      ContractNegotiationPopupContractReviewerComponent,
      {
        contractReviewerList:
          this.contractNegotiationEditResponse.contractReviewerList
      }
    );
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      const request = this.preparedData();
      console.log(this.formGroup.value);
      this.global.modalService
        .newSubmitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            console.log(request);
            this.httpClientService
              .post<Response<ContractNegotiation>>(
                '/contract-negotiation/update',
                request
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate([this.urlBackOutside]);
                      } else {
                        if (
                          this.roleCode ===
                          this.global.appConstant.ROLE_CODE_VENDOR
                        ) {
                          this.contractWorklistStatus =
                            response.body.vendorNegotiationStatus;
                        } else {
                          this.contractWorklistStatus =
                            response.body.userNegotiationStatus;
                        }
                        this.router
                          .navigateByUrl('/', {
                            skipLocationChange: true
                          })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set(
                              'contract',
                              this.contract
                            );
                            this.global.routerParams.set(
                              'contractId',
                              this.contractId
                            );
                            this.global.routerParams.set('todo', 'view');
                            this.global.routerParams.set(
                              'contractNegotiation',
                              this.contractNegotiation
                            );
                            this.global.routerParams.set(
                              'contractWorklistStatus',
                              this.contractWorklistStatus
                            );
                            this.router.navigate([
                              '/pages/contract-negotiation/detail'
                            ]);
                          });
                      }
                    });
                }
              });
          }
        });
    }
  }

  public preparedData(): ContractNegotiationUpdateRequest {
    const request = new ContractNegotiationUpdateRequest();
    request.contractNegotiation = this.contractNegotiation;
    const contractNegoHistory = new ContractNegotiationHistory();
    contractNegoHistory.note = this.formGroup.value.note;
    if (this.roleCode === this.global.appConstant.ROLE_CODE_VENDOR) {
      contractNegoHistory.userType =
        this.global.appConstant.cm.USER_TYPE_VENDOR;

      const status = this.formGroup.value.status?.value;
      request.isVendorApproved = status === this.APPROVED;
    } else {
      contractNegoHistory.userType = this.global.appConstant.cm.USER_TYPE_STAFF;
    }
    request.contractNegotiationHistory = contractNegoHistory;
    request.fileObjectList = this.formGroup.value.docFile;

    return request;
  }
}
