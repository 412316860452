<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="contact-us.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="" formArrayName="socialList">
          <div
            [formGroupName]="i"
            *ngFor="
              let social of formGroup | resolveFormArray: 'socialList';
              let i = index
            "
          >
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">
                {{ social.get('name').value }}
              </label>
              <div class="col-sm-4 text-left">
                <app-text-field
                  autofocus="true"
                  size="XL"
                  formControlName="value"
                  maxLength="64"
                ></app-text-field>
              </div>
              <app-toggle-switch
                formControlName="isActive"
                (onChange)="onChangeDisabled(i, $event)"
              ></app-toggle-switch>
            </div>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'contact-us.form.location' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <app-text-map size="XL" formControlName="location"></app-text-map>
          </div>
        </div>
        <br />
        <app-tab formArrayName="contactList" bordered="true">
          <app-tab-item
            header="{{ contact.value.lang.name }}"
            *ngFor="
              let contact of formGroup | resolveFormArray: 'contactList';
              let i = index
            "
            [formGroup]="contact"
          >
            <div class="form-group row text-sm-right mt-3">
              <label class="col-sm-2 control-label"
                >{{ 'contact-us.form.text' | translate }} <br
              /></label>
              <div class="col-sm-9 text-left">
                <app-wysiwyg formControlName="value" size="XL"></app-wysiwyg>
              </div>
            </div>
          </app-tab-item>
        </app-tab>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
