import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { Wanprestasi } from '../../core/bean/wanprestasi';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './wanprestasi.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class WanprestasiComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Wanprestasi>;
  constructor(translateService: TranslateService) {
    super('wanprestasi', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name', customClass: 'text-left' },
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      { field: 'vendorType.name', header: 'table.column.vendorType' },
      { field: 'email', header: 'table.column.email' },
      {
        field: 'vendorActivityStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      },
      {
        field: 'totalWanprestasi',
        header: 'table.column.qty',
        customClass: 'text-right',
        format: FieldFormatEnum.Integer
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/wanprestasi/add']);
  }

  public doView(vendor: Vendor) {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorId', vendor.id);
    this.router.navigate(['/pages/wanprestasi/detail']);
  }
}
