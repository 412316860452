import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { CoaComponent } from './coa.component';
const routes = [
  {
    path: '',
    component: CoaComponent,
    data: { breadcrumb: '' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [CoaComponent]
})
export class CoaModule {}
