<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-x
        [model]="tableResponse"
        stringUrl="/hospital-stock/index"
        (onClick)="doClick($event)"
        header="{{ 'hospital-stock.table.header' | translate }}"
      >
      </app-table-x>
    </div>
  </div>
</app-layout>