import { BaseEntity } from '../base/base-entity';
import { Coa } from '../bean/coa';
import { TaxMethod } from '../bean/tax-method';
import { TaxType } from '../bean/tax-type';

export class TaxEntity extends BaseEntity {
  public taxMethod: TaxMethod;
  public taxType: TaxType;
  public coa: Coa;
  public name: string;
  public description: string;
  public amount: number;
  public idGift: string;
}
