<ng-container *ngIf="!model.isLoading">
  <h5 class="separator">{{ 'company-profile.form-group.expert' | translate }}
    <span class="control-label"></span></h5>
  <app-table #vendorExpert [isServerSide]="true"
    stringUrl="/tab-expert-and-equipment/index-expert"
    [model]="tableExpertResponse">
    <ng-template #actionButtons let-rowData>
      <app-button size="TN" (onClick)="doViewExpertise(rowData)"
        title="{{ 'app.tooltip.detail' | translate }}">
        <em class="fas fa-search"></em>
      </app-button>
    </ng-template>
  </app-table>
  <div class="form-group row" *ngIf="!model.isLoading">
    <div class="col-lg-12">
      <h5 class="separator">{{ 'company-profile.form-group.equipment' |
        translate }} <span class="control-label"></span></h5>
      <app-table #vendorEquipment [isServerSide]="true"
        stringUrl="/tab-expert-and-equipment/index-equipment"
        [model]="tableEquipmentResponse">
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" (onClick)="doViewEquipment(rowData)"
            title="{{ 'app.tooltip.detail' | translate }}">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </div>
  </div>
</ng-container>