import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { BillingMemoView } from 'src/app/core/view/entity/bean/billing-memo-view';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { PaymentStatus } from 'src/app/core/bean/payment-status';
import { Procurement } from 'src/app/core/bean/procurement';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { AppPopupSyncBillingMemoComponent } from './app-popup-sync-billing-memo.component';
import { WebSocketBillingMemoService } from './websocket-billing-memo.service';
import { BillingMemoOptionListResponse } from './billing-memo-option-list.response';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';

@Component({
  templateUrl: 'billing-memo.component.html',
  providers: [
    { provide: WebSocketBillingMemoService },
    { provide: AppPopupService }
  ]
})
export class BillingMemoComponent extends BaseModuleComponent {
  public procurementOptionList: OptionListModel<Procurement> = new OptionListModel(
    false
  );
  public agreementOptionList: OptionListModel<String> = new OptionListModel(
    false
  );
  public vendorOptionList: OptionListModel<String> = new OptionListModel(false);
  public paymentInvoiceOptionList: OptionListModel<PaymentInvoice> = new OptionListModel(
    false,
    'number'
  );
  public paymentStatusOptionList: OptionListModel<PaymentStatus> = new OptionListModel(
    false
  );
  public tableResponse: TableResponseModel<BillingMemoView>;
  public isLoadingSync: boolean;
  public isSyncRunning = false;
  public isAllowAccess = false;

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public websocketBillingMemoService: WebSocketBillingMemoService
  ) {
    super('billing-memo', translate);
  }

  public onInit(): void {
    this.initializeWebSocketBillingMemoConnection();
    this.chekedUserAccessButton();
    this.isSynchronizeRunning();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
      agreementList: [null],
      procurementList: [null],
      invoiceNumberList: [null],
      vendorNameList: [null],
      statusList: [null]
    });
  }

  public getAndSetOptionList(): void {
    this.httpClientService
      .get<BillingMemoOptionListResponse>('/billing-memo/get-option-list')
      .subscribe(
        (billingMemoOptionListResponse: BillingMemoOptionListResponse) => {
          this.procurementOptionList.setRequestValues(
            billingMemoOptionListResponse.procurementList
          );
          this.paymentStatusOptionList.setRequestValues(
            billingMemoOptionListResponse.paymentStatusList
          );
          this.paymentInvoiceOptionList.setRequestValues(
            billingMemoOptionListResponse.invoiceNumberList
          );
          this.vendorOptionList.setRequestValues(
            billingMemoOptionListResponse.vendorNameList
          );
          this.agreementOptionList.setRequestValues(
            billingMemoOptionListResponse.agreementNumberList
          );
          this.setStateReady();
        }
      );
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'invoiceNumber',
        header: 'table.column.invoiceNumber',
        customClass: 'text-center'
      },
      {
        field: 'requestDate',
        header: 'table.column.requestDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'memoNumber',
        header: 'table.column.memoNumber',
        customClass: 'text-center'
      },
      {
        field: 'vendorName',
        header: 'table.column.vendorName'
      },
      {
        field: 'agreementNumber',
        header: 'table.column.agreementNumber',
        customClass: 'text-center'
      },
      {
        field: 'procurementNumber',
        header: 'table.column.procurementNumber',
        customClass: 'text-center'
      },
      {
        field: 'procurementName',
        header: 'table.column.procurementName'
      },
      {
        field: 'stageName',
        header: 'table.column.stageName',
        customClass: 'text-center'
      },
      {
        field: 'paymentTermName',
        header: 'table.column.paymentTermName',
        customClass: 'text-center'
      },
      {
        field: 'billingMethod',
        header: 'table.column.billingMethod',
        customClass: 'text-center',
        datamap:
          '{"true" : "' +
          this.global.translateService.instant('billing-memo.status.partial') +
          '", "false" : "' +
          this.global.translateService.instant(
            'billing-memo.status.nonPartial'
          ) +
          '"}'
      },
      {
        field: 'billingAmount',
        header: 'table.column.billingAmount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'paymentStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      },
      {
        field: 'billingMemoStatusName',
        header: 'table.column.statusBJBGift',
        customClass: 'text-center'
      }
    ]);
  }

  public doEdit(billingMemoView: BillingMemoView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('paymentId', billingMemoView.id);
    this.global.routerParams.set(
      'procurementVendorId',
      billingMemoView.procurementVendor.id
    );
    this.global.routerParams.set('currency', billingMemoView.currency);
    this.global.routerParams.set(
      'paymentInvoiceId',
      billingMemoView.paymentInvoice.id
    );
    this.global.routerParams.set(
      'paymentStatus',
      billingMemoView.paymentStatus
    );
    this.global.routerParams.set(
      'billingAmount',
      billingMemoView.billingAmount
    );
    this.router.navigate(['/pages/billing-memo/edit']);
  }

  public doView(billingMemoView: BillingMemoView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('paymentId', billingMemoView.id);
    this.global.routerParams.set(
      'procurementVendorId',
      billingMemoView.procurementVendor.id
    );
    this.global.routerParams.set('currency', billingMemoView.currency);
    this.global.routerParams.set(
      'paymentInvoiceId',
      billingMemoView.paymentInvoice.id
    );
    this.global.routerParams.set(
      'paymentStatus',
      billingMemoView.paymentStatus
    );
    this.global.routerParams.set(
      'billingAmount',
      billingMemoView.billingAmount
    );
    this.router.navigate(['/pages/billing-memo/view']);
  }

  public doApply(): void {
    this.global.routerParams.clear();
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doSynchronize(): void {
    this.httpClientService
      .post<Response>('/billing-memo/bjb-gift-sync-check', {})
      .subscribe(response => {
        this.loadingBlockService.showInfo(
          this.translateService.instant('billing-memo.info.preparing')
        );
        if (response && response.length !== 0) {
          this.appPopupService
            .open(
              AppPopupSyncBillingMemoComponent,
              { onCloseModal: this.websocketBillingMemoService.onCloseModal },
              {
                backdrop: 'static',
                size: 'lg'
              }
            )
            .subscribe();
          this.websocketBillingMemoService.appPopupSyncBillingMemoComponent = this.appPopupService.componentInstance;
        } else {
          this.loadingBlockService.showCompleteAndClose(
            this.translateService.instant('billing-memo.info.successSync')
          );
        }
      });
  }

  public initializeWebSocketBillingMemoConnection(): void {
    this.websocketBillingMemoService.connect().then(result => {
      if (this.isSyncRunning) {
        this.isSyncRunning = false;
        this.buildFormGroup();
        this.buildTableResponse();
        this.getAndSetOptionList();
      } else if (!this.isSyncRunning && result) {
        this.tableResponse.reload();
      }
      this.websocketBillingMemoService.disconnect();
      this.initializeWebSocketBillingMemoConnection();
      this.isLoadingSync = false;
    });
  }

  public isSynchronizeRunning(): void {
    this.httpClientService
      .post<Boolean>('/billing-memo/is-payment-status-synchronize-running', {
        data: null
      })
      .subscribe((isSynchronizeRunning: boolean) => {
        this.isSyncRunning = isSynchronizeRunning;
        if (!this.isSyncRunning) {
          this.buildFormGroup();
          this.buildTableResponse();
          this.getAndSetOptionList();
        }
      });
  }

  public chekedUserAccessButton(): void {
    if (
      this.global.config.parameterModel.bjbGiftEnableSyncDataOnUnit.includes(
        this.global.userSession.user.organization.code
      ) ||
      this.global.userSession.user.organization.parentId === null
    ) {
      this.isAllowAccess = true;
    }
  }
}
