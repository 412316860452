import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { WebContent } from '../../../core/bean/web-content';
import { FileUploader } from '../../../core/components/upload';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
// import { UserTocModel } from './user-toc-model';
// import { File } from '../../../core/bean/file';
import { File } from '../../../core/bean/file';
import { Validators } from '../../../core/validators';
import { UserTocModel } from './user-toc-model';
import { UserTocRequest } from './user-toc-request';
@Component({
  templateUrl: './user-toc-edit-add.component.html'
})
export class UserTocEditAddComponent extends BaseModuleComponent {
  constructor(translateService: TranslateService) {
    super('user-toc', translateService);
  }
  public userTocRequest: UserTocRequest;
  public fileUploader: FileUploader = new FileUploader(
    '/user-toc/',
    '',
    // this.global.appConstant.fileType.DOC_USER_TOC,
    this.global.appConstant.fileType.DOC_REGIST_VENDOR,
    false,
    1
  );

  public onInit(): void {
    this.doSetTodoFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetTodoFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      userTocList: this.formBuilder.array([])
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<Array<UserTocModel>>('/user-toc/add-edit', {})
      .subscribe((userTocModelList: Array<UserTocModel>) => {
        userTocModelList.forEach(userTocModel => {
          const { id, code, lang, key, description, value } =
            userTocModel.userToc;
          this.addDocFileToFileUploaderlist(userTocModel.file);
          const formGroup = this.formBuilder.group({
            id: [id],
            code: [code],
            lang: [lang],
            key: [key],
            description: [description],
            value: [value],
            file: [this.fileUploader, Validators.required()]
          });

          (this.formGroup.get('userTocList') as FormArray).push(formGroup);
        });
        this.setStateReady();
      });
  }

  public addDocFileToFileUploaderlist(file: File): void {
    this.fileUploader.setFile(file);
    this.fileUploader.titleRequired = false;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const userTocRequestList: Array<UserTocRequest> = new Array();
            this.formGroup.value.userTocList.forEach((userToc: WebContent) => {
              this.userTocRequest = new UserTocRequest();
              this.userTocRequest.userToc = userToc;
              this.userTocRequest.fileObjectList =
                this.fileUploader.fileObjectList;
              userTocRequestList.push(this.userTocRequest);
            });
            this.httpClientService
              .post<Response<WebContent>>(
                '/user-toc/update',
                userTocRequestList
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/content/']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/content/']);
  }

  public get formArrayUserTocList(): FormArray {
    return this.formGroup.get('userTocList') as FormArray;
  }
}
