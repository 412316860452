<app-popup header="dr.popup.shippedItem" [isShowHeaderCloseButton]="isView">
  <div class="alert alert-dismissible show alert-info" role="alert">
    <div>
      <em
        style="font-size: 2rem; top: 5px; left: auto; font-weight: 500"
        class="pir pi-map-marker-alt mr-2 text-primary"
      ></em>
      <span
        class="d-block text-primary"
        style="margin-left: 3rem; font-weight: 500"
        maxLine
        >{{ address.addressDetail }}</span
      >
    </div>
  </div>
  <div class="popup-order-split-item">
    <app-tab
      [bordered]="true"
    >
      <app-tab-item
        header="{{ 'dr.tab.header.goods' | translate }}"
      >
        <app-table-xx
          [model]="tableResponseGoods"
          [isShowPagination]="false"
        >
        </app-table-xx>
      </app-tab-item>
      <!-- <app-tab-item
        header="{{ 'dr.tab.header.service' | translate }}"
      >
        <app-table-xx
          [model]="tableResponseService"
          [isShowPagination]="false"
        >
        </app-table-xx>
      </app-tab-item> -->
    </app-tab>  
  </div>
</app-popup>
