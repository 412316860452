import { BaseEntity } from './../base/base-entity';
import { Approval } from './../bean/approval';
import { Module } from './../bean/module';
import { Workflow } from './../bean/workflow';

export class WorkflowModelEntity extends BaseEntity {
    workflow: Workflow;
    module: Module;
    approval: Approval;
    type: number;
    sequence: number;
    slaInDays: number;
    slaApprovalInDays: number;
}
