<div class="app-upload-document-table-x">
  <div *ngIf="mode !== 'view'">
    <div
      class="drag-drop-content-wrapper"
      uploadDragAndDrop
      (onChange)="onDragAndDropChange($event)"
    >
      <div class="content-border px-2">
        <em
          class="fas fa-cloud-upload-alt fa-4x"
          *ngIf="!isUploading || (isUploading && model.error)"
        ></em>
        <span
          class="text mx-4"
          *ngIf="!isUploading || (isUploading && model.error)"
        >
          {{ 'app-upload-document-table.info.dragAndDrop' | translate }}
        </span>
        <input
          #inputFileAdd
          type="file"
          (change)="onUploadChange($event)"
          multiple
          aria-label="choose-file"
          hidden
          [disabled]="formLoading"
        />
        <app-button
          (onClick)="inputFileAdd.click()"
          *ngIf="!isUploading || (isUploading && model.error)"
          [disabled]="formLoading"
        >
          {{
            'app-upload-document-table.button.browseFile' | translate
          }}</app-button
        >
        <span
          class="text-uploading fw-500 text-primary"
          *ngIf="isUploading && !model.error"
          >{{ 'app-upload-document-table.info.uploading' | translate }}</span
        >
        <div
          class="upload-progress-wrapper"
          *ngIf="isUploading && !model.error"
        >
          <span
            class="upload-progress"
            [ngStyle]="{ width: progressValue + '%' }"
          ></span>
        </div>
      </div>
    </div>
    <span class="file-requirements d-block my-3 text-label">
      {{
        fileUploader?.maxFile +
          ' ' +
          ('app-upload-document-table.info.maxFile' | translate) +
          ' ' +
          model.options?.maxSize / 1000000 +
          'MB ' +
          ('app-upload-document-table.info.format' | translate) +
          ' ' +
          model.options?.allowedExtension
      }}
    </span>
    <span class="upload-error d-block mt-3"></span>
  </div>
  <div
    id="upload-error-info"
    class="alert alert-danger alert-dismissible show"
    role="alert"
    *ngIf="model.error && mode !== 'view'"
  >
    <strong class="mr-2">
      <em class="fas fa-exclamation-triangle mr-2"></em>
    </strong>
    <span style="padding: 0 2rem">{{ model.error?.message }}</span>

    <button
      aria-label="Close"
      class="close"
      data-dismiss="alert"
      type="button"
      (click)="onClick()"
      style="display: block"
    >
      <span aria-hidden="true"
        ><em
          class="pir pi-times"
          style="top: auto; left: 0; font-size: 1.5rem; color: #000000"
        ></em
      ></span>
    </button>
  </div>

  <div
    id="upload-error-info"
    class="alert alert-danger alert-dismissible show"
    role="alert"
    *ngIf="!isValidFormControl && mode !== 'view'"
  >
    <strong class="mr-2">
      <em class="fas fa-exclamation-triangle mr-2"></em>
    </strong>
    <span style="padding: 0 2rem">{{ errors?.message }}</span>
  </div>
  <br />
  <form
    class="form-horizontal"
    [formGroup]="formGroupUploadDoc"
    novalidate
    *ngIf="!formLoading"
  >
    <app-select
      *ngIf="uploadDocList.value && uploadDocList.value.length > 0"
      [optionList]="uploadDocOptionList"
      [isMultiple]="true"
      formControlName="uploadDocSelect"
      maxHeight="fit-content"
      selectAllText="{{ 'app-upload-document-table.selectAll' | translate }}"
      size="XL"
    >
      <ng-template #actionButtons>
        <app-button
          size="TN"
          (onClick)="doDelete(rowData)"
          title="{{ 'app.tooltip.delete' | translate }}"
          color="LIGHT"
          [outline]="false"
          ><em class="pir pi-trash-alt mr-2" style="font-size: 24px"></em>
          {{ 'app.button.delete' | translate }}
        </app-button>
      </ng-template>
      <ng-template #content let-i="index" let-data>
        <ng-container [formGroup]="uploadDocList.controls[i]">
          <div class="row border" [ngStyle]="{ 'border-radius': '5px' }">
            <div class="col-12 col-sm-4">
              <div class="d-flex flex-column pt-3">
                <app-upload-x
                  formControlName="fileObject"
                  [fileUploader]="fileUploader"
                ></app-upload-x>
                <div class="form-group row text-sm-left border-top pt-4">
                  <label
                    class="col-sm-12 pr-0 pl-3 pb-2"
                    [ngStyle]="{ 'margin-block': 'auto' }"
                  >
                    <div class="text-label">
                      {{
                        'app-upload-document-table.form.accessibility'
                          | translate
                      }}
                    </div>
                  </label>
                  <div
                    class="col-sm-12 text-left"
                    [ngStyle]="{ 'min-width': '10rem' }"
                  >
                    <app-dropdown-select
                      size="XL"
                      formControlName="accessibility"
                      type="COMBOBOX"
                      [optionList]="accessibilityOptionList"
                      placeholder="{{
                        'app-upload-document-table.placeholder.accessibility'
                          | translate
                      }}"
                      (onChange)="doCheckValue()"
                      *ngIf="
                        !formGroupUploadDoc.isView &&
                        !uploadDocList.controls[i].controls.accessibility.isView
                      "
                    >
                      <ng-template #option let-option>
                        <ng-container
                          *ngTemplateOutlet="
                            customContent;
                            context: { $implicit: option }
                          "
                        >
                        </ng-container>
                      </ng-template>
                    </app-dropdown-select>
                    <div
                      class="pb-3"
                      *ngIf="
                        formGroupUploadDoc.isView ||
                        uploadDocList.controls[i].controls.accessibility.isView
                      "
                    >
                      <app-badge-catalog
                        style="position: absolute"
                        [color]="badgeColor[data.accessibility.code]"
                        >{{
                          getTranslation(data.accessibility)
                        }}</app-badge-catalog
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-8 border-left">
              <div class="text-label pt-3 pl-3">
                {{
                  'app-upload-document-table.procDocType.msg.info' | translate
                }}

                <em
                  class="pir pi-question-circle"
                  [ngbTooltip]="
                    'app-upload-document-table.procDocType.msg.tooltip'
                      | translate
                  "
                  container="body"
                  placement="top"
                ></em>
              </div>
              <div class="form-group row text-sm-left">
                <div class="col text-left" [ngStyle]="{ 'min-width': '10rem' }">
                  <app-select
                    [optionList]="procurementDocTypeOptionList"
                    [isMultiple]="true"
                    formControlName="procDocType"
                    (onChange)="doCheckValue()"
                    *ngIf="!formGroupUploadDoc.isView"
                  >
                    <ng-template #content let-option>
                      <ng-container
                        *ngTemplateOutlet="
                          customContent;
                          context: { $implicit: option }
                        "
                      >
                      </ng-container>
                    </ng-template>
                  </app-select>
                  <small
                    class="text-danger"
                    *ngIf="
                      uploadDocList.controls[i].touched &&
                      uploadDocList.controls[i].get('procDocType').invalid
                    "
                  >
                    {{
                      uploadDocList.controls[i].get('procDocType').errors
                        ?.message
                    }}
                  </small>
                  <div *ngIf="formGroupUploadDoc.isView" class="pl-3 pt-4">
                    {{ getProcDocTypeView(data.procDocType) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </app-select>
  </form>
  <div
    *ngIf="
      mode === 'view' &&
      !(uploadDocList?.value && uploadDocList?.value?.length > 0)
    "
  >
    <div class="no-data-table-wrapper lpy-4 text-center">
      <ng-container>
        <div class="ndtw-icon">
          <div
            class="pvc pv-file-list"
            style="height: 89px; width: 67px; margin: 0 auto"
          ></div>
        </div>
        <div
          class="ndtw-content"
          style="text-align: center; color: var(--gray-500); font-weight: 500"
        >
          <ng-container>
            <span class="ndtwc-text">
              {{ 'app-table.noDataAvailable' | translate }}
            </span>
            <br />
            <span class="ndtwc-text">
              {{ 'app-table.letsTryToAddList' | translate }}
            </span>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #customContent let-option>
    <span maxLine *ngIf="option.translationKey">
      {{
        (option.translationKey.module.code | lowercase) +
          '.' +
          option.translationKey.key | translate
      }}
    </span>
    <span maxLine *ngIf="!option.translationKey">
      {{ option.name }}
    </span>
  </ng-template>
</div>
