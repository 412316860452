import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupBudgetComponent } from './app-popup-budget.component';
@Injectable()
export class AppPopupBudgetService {
  constructor(public appPopupService: AppPopupService) {}
  public open(totalPrice?: number): Observable<any> {
    return this.appPopupService.open(
      AppPopupBudgetComponent,
      { totalPrice },
      { size: 'lg', windowClass: 'modal-custom' }
    );
  }
}
