import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../../../core/base/angular/base-component.component';
import { EquipmentCondition } from '../../../../core/bean/equipment-condition';
import { EquipmentType } from '../../../../core/bean/equipment-type';
import { Region } from '../../../../core/bean/region';
import { ResourceOwnership } from '../../../../core/bean/resource-ownership';
import { Uom } from '../../../../core/bean/uom';
import { VendorEquipment } from '../../../../core/bean/vendor-equipment';
import { VendorEquipmentDoc } from '../../../../core/bean/vendor-equipment-doc';
import { VendorEquipmentHistory } from '../../../../core/bean/vendor-equipment-history';
import { FileUploader } from '../../../../core/components/upload';
import { OptionListModel } from '../../../../core/model/option-list-model';
import { Validators } from '../../../../core/validators';
import { VendorPopupVendorEquipmentDTO } from '../../dto/vendor-popup-vendor-equipment.dto';

@Component({
  templateUrl: './app-popup-equipment.component.html',
  styles: [
    '.company-profile-insert { color: blue; border-style: solid; border-width: thin; float: right }',
    '.company-profile-update { color: orange; border-style: solid; border-width: thin; float: right }',
    '.company-profile-delete { color: red; border-style: solid; border-width: thin; float: right }',
    '.company-profile-text-crud { margin: 0.5rem }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupEquipmentComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public vendorEquipmentHistoryEdit: VendorEquipmentHistory;
  @Input() public vendorEquipmentHistoryList: VendorEquipmentHistory[];
  @Input() public vendorEquipment: VendorEquipment;
  @Input() public vendorEquipmentList: VendorEquipment[];
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();

  public vendorPopupVendorEquipmentDTO: VendorPopupVendorEquipmentDTO =
    new VendorPopupVendorEquipmentDTO();
  public regionList: Region[] = [];
  public documentList: any[] = [];
  public isVendorList: boolean;
  public fromOutside: string;
  public startDateActualList: string[] = [];
  public startDateHistoryList: string[] = [];
  public endDateActualList: string[] = [];
  public endDateHistoryList: string[] = [];
  public capacityUom: string;
  public capacityUomHistory: string;

  public fileUploaderList: FileUploader[] = [];
  public vendorEquipmentDocList: VendorEquipmentDoc[] = [];

  public equipmentTypeOptionList: OptionListModel<EquipmentType> =
    new OptionListModel(true);
  public uomOptionList: OptionListModel<Uom> = new OptionListModel(true);
  public equipmentConditionOptionList: OptionListModel<EquipmentCondition> =
    new OptionListModel(true);
  public resourceOwnershipOptionList: OptionListModel<ResourceOwnership> =
    new OptionListModel(true);
  constructor(public datePipe: DatePipe, public activeModal: NgbActiveModal) {
    super('tab-profile-expert-and-equipment');
  }

  public onInit(): void {
    this.setParam();
    this.getVendorEquipment();
    this.buildFormGroup();
    this.setFormGroup();
    if (this.todo === 'detail') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      equipment: this.newEquipmentType(),
      ownershipDocumentList: this.formBuilder.array([
        this.newOwnershipDocument()
      ]),
      vendorEquipmentId: [null]
    });
  }

  public setParam(): void {
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
  }

  public setFormGroup(): void {
    this.getAppPopupVendorEquipmentFromServer(
      '/tab-profile-expert-and-equipment/add-edit-popup-vendor-equipment'
    ).subscribe(vendorPopupVendorEquipmentDTO => {
      this.vendorPopupVendorEquipmentDTO = vendorPopupVendorEquipmentDTO;
      this.setOptionListModel();
      if (this.vendorEquipmentHistoryEdit !== undefined) {
        this.httpClientService
          .post<Uom[]>(
            '/tab-profile-expert-and-equipment/change-uom-list',
            this.vendorEquipmentHistoryEdit.equipmentType
          )
          .subscribe(uomList => {
            this.uomOptionList.setRequestValues(uomList);
          });
        const {
          id,
          brand,
          quantity,
          capacity,
          equipmentCondition,
          equipmentType,
          year,
          uom,
          resourceOwnership,
          regionList,
          vendorEquipmentId
        } = this.vendorEquipmentHistoryEdit;
        const capacities = { capacity, uom };
        const equipment = {
          equipmentType,
          quantity,
          capacities,
          brand,
          equipmentCondition,
          region: regionList,
          resourceOwnership,
          year
        };
        if (
          this.fileUploaderList[0].fileObjectList &&
          this.fileUploaderList[0].fileObjectList.length === 0 &&
          this.vendorEquipmentHistoryEdit.vendorEquipmentDocHistoryList
            .length !== 0 &&
          this.vendorEquipmentHistoryEdit.vendorEquipmentDocHistoryList[0]
            .certificateNumber !== null
        ) {
          this.fileUploaderList = [];
        }
        this.vendorEquipmentHistoryEdit.vendorEquipmentDocHistoryList.forEach(
          vendorEquipmentDoc => {
            const certificateNumber = vendorEquipmentDoc.certificateNumber;
            const startDate =
              vendorEquipmentDoc.startDate !== null
                ? new Date(vendorEquipmentDoc.startDate)
                : null;
            const endDate =
              vendorEquipmentDoc.endDate !== null
                ? new Date(vendorEquipmentDoc.endDate)
                : null;
            const fileUploader: FileUploader = new FileUploader(
              '/tab-profile-expert-and-equipment/',
              'document',
              this.global.appConstant.fileType.DOC_OWNERSHIP
            );
            fileUploader.setFile(vendorEquipmentDoc.file);
            if (
              vendorEquipmentDoc.fileObjectList &&
              vendorEquipmentDoc.fileObjectList.length !== 0
            ) {
              fileUploader.fileObjectList = vendorEquipmentDoc.fileObjectList;
            }
            this.fileUploaderList.push(fileUploader);
            const validationDate = { startDate, endDate };
            const vendorEquipmentDocId =
              vendorEquipmentDoc.vendorEquipmentDocId;
            const ownershipDocument = {
              certificateNumber,
              validationDate,
              vendorEquipmentDocId,
              fileDocument: fileUploader.fileObjectList,
              fileUploader,
              crudInfo: vendorEquipmentDoc.crudInfo,
              file: vendorEquipmentDoc.file
            };
            this.documentList.push(ownershipDocument);
          }
        );

        for (let index = 0; index < this.documentList.length; index++) {
          if (index + 1 !== this.documentList.length) {
            this.ownershipDocumentList.push(this.newOwnershipDocument());
          }
        }
        this.formGroup.patchValue({
          id,
          equipment,
          ownershipDocumentList: this.documentList,
          vendorEquipmentId
        });
      }
      this.setStateReady();
    });
  }

  public setOptionListModel(): void {
    this.equipmentTypeOptionList.setRequestValues(
      this.vendorPopupVendorEquipmentDTO.equipmentTypeList
    );
    this.equipmentConditionOptionList.setRequestValues(
      this.vendorPopupVendorEquipmentDTO.equipmentConditionList
    );
    this.resourceOwnershipOptionList.setRequestValues(
      this.vendorPopupVendorEquipmentDTO.resourceOwnershipList
    );
  }

  public newEquipmentType(): FormGroup {
    const currentDate = new Date();

    const formGroup = this.formBuilder.group({
      equipmentType: [null, Validators.required()],
      quantity: [
        null,
        Validators.compose([Validators.required(), Validators.min(1)])
      ],
      capacities: this.newCapacity(),
      brand: [null, Validators.required()],
      equipmentCondition: [null, Validators.required()],
      region: [null],
      resourceOwnership: [null, Validators.required()],
      // tslint:disable-next-line: max-line-length
      year: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.actualLength(
            4,
            'tab-profile-expert-and-equipment.validation.year.actualLength'
          ),
          Validators.max(
            currentDate.getFullYear(),
            'company-profile.validation.maxYear'
          )
        ])
      ]
    });
    if (this.todo === 'detail') {
      formGroup.setIsView(true);
    }
    return formGroup;
  }

  public newCapacity(): FormGroup {
    const formGroup = this.formBuilder.group({
      capacity: [
        null,
        Validators.compose([Validators.required(), Validators.min(1)])
      ],
      uom: [null, Validators.required()]
    });
    if (this.todo === 'detail') {
      formGroup.setIsView(true);
    }
    return formGroup;
  }

  public newOwnershipDocument(): FormGroup {
    const fileUploader: FileUploader = new FileUploader(
      '/tab-profile-expert-and-equipment/',
      'document',
      this.global.appConstant.fileType.DOC_OWNERSHIP
    );
    this.fileUploaderList.push(fileUploader);
    const formGroup = this.formBuilder.group({
      certificateNumber: [null],
      validationDate: this.newYearFromAndTo(),
      fileDocument: [null],
      vendorEquipmentDocId: [null],
      fileUploader: [null],
      crudInfo: [null],
      file: [null]
    });
    if (this.todo === 'detail') {
      formGroup.setIsView(true);
    }
    return formGroup;
  }

  public newYearFromAndTo(): FormGroup {
    const formGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null]
    });
    if (this.todo === 'detail') {
      formGroup.setIsView(true);
    }
    return formGroup;
  }

  public addOwnershipDocument(): void {
    this.ownershipDocumentList.push(this.newOwnershipDocument());
  }

  public doDeleteOwnershipDocument(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.ownershipDocumentList.removeAt(index);
        }
      });
  }

  public get ownershipDocumentList(): FormArray {
    return this.formGroup.get('ownershipDocumentList') as FormArray;
  }

  public getVendorEquipment(): void {
    if (this.vendorEquipmentList) {
      this.vendorEquipmentHistoryList.forEach(equipmentHistory => {
        if (equipmentHistory === this.vendorEquipmentHistoryEdit) {
          this.vendorEquipmentList.forEach(equipment => {
            if (equipment.id === equipmentHistory.vendorEquipmentId) {
              this.vendorEquipment = equipment;
              this.capacityUom =
                this.vendorEquipment.capacity.toString() +
                ' ' +
                this.vendorEquipment.uom.name;
            }
          });
        }
      });
      if (this.vendorEquipment === undefined) {
        this.vendorEquipment = new VendorEquipment();
      }
    }
    if (this.vendorEquipmentHistoryEdit !== undefined) {
      this.capacityUomHistory =
        this.vendorEquipmentHistoryEdit.capacity.toString() +
        ' ' +
        this.vendorEquipmentHistoryEdit.uom.name;
      this.vendorEquipmentHistoryEdit.vendorEquipmentDocHistoryList.forEach(
        (vendorEquipmentDoc, index) => {
          let equipemntDoc = null;
          if (
            this.vendorEquipmentHistoryEdit.vendorEquipmentDocList !== undefined
          ) {
            equipemntDoc =
              this.vendorEquipmentHistoryEdit.vendorEquipmentDocList[index];
          }
          const startDate =
            vendorEquipmentDoc.startDate !== null
              ? new Date(vendorEquipmentDoc.startDate)
              : null;
          const endDate =
            vendorEquipmentDoc.endDate !== null
              ? new Date(vendorEquipmentDoc.endDate)
              : null;
          const startDateHistory =
            startDate !== null
              ? this.datePipe.transform(
                  startDate,
                  this.global.config.parameterModel.dateFormatShort
                )
              : null;
          const endDateHistory =
            endDate !== null
              ? this.datePipe.transform(
                  endDate,
                  this.global.config.parameterModel.dateFormatShort
                )
              : null;
          this.startDateHistoryList.push(startDateHistory);
          this.endDateHistoryList.push(endDateHistory);
          if (equipemntDoc !== null && equipemntDoc !== undefined) {
            const startDateActual =
              equipemntDoc.startDate !== null
                ? this.datePipe.transform(
                    new Date(equipemntDoc.startDate),
                    this.global.config.parameterModel.dateFormatShort
                  )
                : null;
            const endDateActual =
              equipemntDoc.endDate !== null
                ? this.datePipe.transform(
                    new Date(equipemntDoc.endDate),
                    this.global.config.parameterModel.dateFormatShort
                  )
                : null;
            this.startDateActualList.push(startDateActual);
            this.endDateActualList.push(endDateActual);
          }
        }
      );
    }
  }

  public doSave(): void {
    this.validate();
    this.formGroup.get('equipment').setStateSubmitted();
    this.formGroup.get('equipment').get('capacities').setStateSubmitted();
    if (
      this.formGroup.valid &&
      this.formGroup.get('equipment').valid &&
      this.formGroup.get('equipment').get('capacities').valid
    ) {
      this.onChange.emit(this.formGroup.value);
      this.activeModal.dismiss();
    }
  }

  public onChangeEquipment(value: any): void {
    this.httpClientService
      .post<Uom[]>('/tab-profile-expert-and-equipment/change-uom-list', value)
      .subscribe(uomList => {
        this.uomOptionList.setRequestValues(uomList);
      });
  }

  public getAppPopupVendorEquipmentFromServer(
    urlVendorExpertisePopup: string
  ): Observable<VendorPopupVendorEquipmentDTO> {
    return this.httpClientService.post<VendorPopupVendorEquipmentDTO>(
      urlVendorExpertisePopup,
      null
    );
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
