import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { AddressBook } from 'src/app/core/bean/address-book';
import { OrderShippingItem } from 'src/app/core/bean/order-shipping-item';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { DrShippingItemView } from 'src/app/core/view/entity/bean/dr-shipping-item-view';
import { DrShippingItem } from '../../core/bean/dr-shipping-item';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';

@Component({
  templateUrl: './dr-popup-shipped-item.component.html'
})
export class DrPopupShippedItemComponent extends BaseComponentComponent {
  @Input() public address: AddressBook;
  @Input() public drShippingItemList: DrShippingItemView[];
  @Input() public allOShipItemList: DrShippingItemView[];
  @Input() public isView = false;

  public CURRENCY_DIGITS_INFO: string;
  public tableResponseGoods: TableResponseModel<DrShippingItem>;
  public tableResponseService: TableResponseModel<DrShippingItem>;
  @Output() onChange: EventEmitter<OrderShippingItem[]> = new EventEmitter();
  constructor(
    public currency: CurrencyPipe,
    public translateService: TranslateService
  ) {
    super('dr');
  }
  public onInit(): void {
    this.doBuildTableResponse();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public doBuildTableResponse(): void {
    this.tableResponseGoods = new TableResponseModel(this.moduleCode, [
      {
        field: 'merk',
        header: 'popup.table.column.itemMerk'
      },
      {
        field: 'drItem.prItem.item.code',
        header: 'popup.table.column.itemCode'
      },
      {
        field: 'drItem.prItem.item.uom.name',
        header: 'popup.table.column.uom'
      },
      {
        field: 'receivedQuantity',
        header: 'popup.table.column.receiveQty',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'quantity',
        header: 'popup.table.column.qty',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      }
    ]);

    this.tableResponseService = new TableResponseModel(this.moduleCode, [
      {
        field: 'drItem.prItem.item.name',
        header: 'popup.table.column.itemName'
      },
      {
        field: 'drItem.prItem.item.code',
        header: 'popup.table.column.itemCode'
      },
      {
        field: 'drItem.prItem.item.uom.name',
        header: 'popup.table.column.uom'
      },
      {
        field: 'receivedQuantity',
        header: 'popup.table.column.receiveQty',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          before: (tablePlugin: TablePluginData): string => {
            return (
              this.global.converterService.convertDecimal(tablePlugin.value) +
              ' %'
            );
          }
        }
      },
      {
        field: 'quantity',
        header: 'popup.table.column.qty',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          before: (tablePlugin: TablePluginData): string => {
            return (
              this.global.converterService.convertDecimal(tablePlugin.value) +
              ' %'
            );
          }
        }
      }
    ]);

    this.tableResponseGoods.setRecordList(
      this.drShippingItemList.filter(
        x =>
          x.drItem.prItem.item.itemType.parentCode ===
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      )
    );
    this.tableResponseService.setRecordList(
      this.drShippingItemList.filter(
        x =>
          x.drItem.prItem.item.itemType.parentCode ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      )
    );
  }

  // public onAfterRowCreated(tableRow: TableRow): void {
  //   const remainingQuantity = this.getRemainingQuantityItem(tableRow);
  //   if (tableRow.formGroup.get('quantity').value) {
  //     tableRow.formGroup
  //       .get('remainingQuantity')
  //       .patchValue(
  //         (
  //           remainingQuantity + +tableRow.formGroup.get('quantity').value
  //         ).toString()
  //       );
  //   } else {
  //     tableRow.formGroup
  //       .get('quantity')
  //       .patchValue(remainingQuantity.toString());
  //     tableRow.formGroup
  //       .get('remainingQuantity')
  //       .patchValue(remainingQuantity.toString());
  //   }
  //   if (!this.isView) {
  //     if (tableRow && tableRow.columnList[3]) {
  //       const plugins = tableRow.columnList[3].column.plugins as CountPlugin;
  //       plugins.max = +tableRow.formGroup.get('remainingQuantity').value;
  //       this.tableResponse.currentRowChange.row.columnList[3].column.plugins =
  //         plugins;
  //     }
  //     tableRow.formGroup
  //       .get('quantity')
  //       .setValidators(
  //         Validators.max(+tableRow.formGroup.get('remainingQuantity').value)
  //       );
  //     tableRow.formGroup.get('quantity').updateValueAndValidity();
  //     tableRow.formGroup.get('quantity').markAsTouched();
  //   }
  // }

  public setDecimal(field: number): string {
    return this.currency.transform(field, '', '', this.CURRENCY_DIGITS_INFO);
  }
}
