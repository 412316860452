import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppTipsComponent } from './app-tips.component';
@NgModule({
  imports: [VendorModule, AppButtonModule],
  declarations: [AppTipsComponent],
  exports: [AppTipsComponent]
})
export class AppTipsModule {}
