import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { VendorModule } from '../../vendor/vendor.module';
import { AppBadgeModule } from '../app-badge/app-badge.module';
import { AppTabModule } from '../app-tab';
import { AppLayoutComponent } from './app-layout.component';
@NgModule({
  imports: [VendorModule, AppTabModule, AppBadgeModule, PerfectScrollbarModule],
  declarations: [AppLayoutComponent],
  exports: [AppLayoutComponent]
})
export class AppLayoutModule {}
