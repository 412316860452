import { NgModule } from '@angular/core';
import { AppActualPlanBarChartModule } from '../../components/app-actual-plan-bar-chart/app-actual-plan-bar-chart.module';
import { AppBudgetFilterModule } from '../../components/app-budget-filter/app-budget-filter.module';
import { AppButtonModule } from '../../components/app-button/app-button.module';
import { AppCardModule } from '../../components/app-card/app-card.module';
import { AppComboBoxModule } from '../../components/app-combo-box/app-combo-box.module';
import { AppOrganizationRKInfoModule } from '../../components/app-organization-rk-info/app-organization-rk-info.module';
import { AppPlanComparisonModule } from '../../components/app-plan-comparison/app-plan-comparison.module';
import { AppPopupModule } from '../../components/app-popup/app-popup.module';
import { AppTableModule } from '../../components/app-table/app-table.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetBudgetSpendComponent } from './app-widget-budget-spend.component';
import { BarChartComparePopupComponent } from './bar-chart-compare-popup.component';
@NgModule({
  imports: [
    VendorModule,
    AppActualPlanBarChartModule,
    AppBudgetFilterModule,
    AppCardModule,
    AppOrganizationRKInfoModule,
    AppBudgetFilterModule,
    AppPlanComparisonModule,
    AppTableModule,
    AppButtonModule,
    AppWidgetModule,
    AppPopupModule,
    AppComboBoxModule
  ],
  declarations: [AppWidgetBudgetSpendComponent, BarChartComparePopupComponent],
  entryComponents: [
    AppWidgetBudgetSpendComponent,
    BarChartComparePopupComponent
  ],
  providers: [BarChartComparePopupComponent]
})
export class AppWidgetBudgetSpendModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      'app-widget-budget-spend',
      AppWidgetBudgetSpendComponent
    );
  }
}
