import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebContent } from 'src/app/core/bean/web-content';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppPopupManageCatalogTOCComponent } from './app-popup-manage-catalog-toc.component';
@Injectable({ providedIn: 'root' })
export class AppPopupManageCatalogTOCService {
  constructor(public appPopupService: AppPopupService) {}
  public open(content: WebContent): Observable<any> {
    return this.appPopupService.open(
      AppPopupManageCatalogTOCComponent,
      { title: 'toc.title', content: content },
      { windowClass: 'app-popup-toc' }
    );
  }
}
