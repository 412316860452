import { Component, ViewChild } from '@angular/core';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WorkPlan } from '../../core/bean/work-plan';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: 'principle-permit-submission.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class PrinciplePermitSubmissionComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<WorkPlan>;

  constructor(translateService: TranslateService) {
    super('principle-permit', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.workPlanName' },
      { field: 'number', header: 'table.column.workPlanNumber' }
    ]);
  }

  public doEdit(workPlan: WorkPlan): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('workPlanId', workPlan.id);
    if ((workPlan.workflowPrcs !== null) && (workPlan.workflowPrcs.workflowStatus.code === this.global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION)) {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate(['/pages/principle-permit/edit']);
    } else {
      this.global.routerParams.set('todo', 'add');
      this.router.navigate(['/pages/principle-permit/add']);
    }

  }
}
