import { BaseEntity } from '../base/base-entity';
import { Procurement } from '../bean/procurement';
import { ProcurementItem } from '../bean/procurement-item';
import { RefutationReviewStatus } from '../bean/refutation-review-status';

export class RefutationReviewEntity extends BaseEntity {
  procurement: Procurement;
  procurementItem: ProcurementItem;
  refutationReviewStatus: RefutationReviewStatus;
  refutationDate: Date;
  note: string;
}
