<app-popup header="Profile">
  <div class="form-group row">
    <label class="col-sm-3 control-label">{{ 'app-chat.popup.form.fullName' | translate }}</label>
    <div class="col-sm-9 text-left"> {{ model.name || '-' }} </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 control-label">{{ 'app-chat.popup.form.role' | translate }}</label>
    <div class="col-sm-9 text-left">{{ model.participantRole?.name || '-' }}</div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 control-label">{{ 'app-chat.popup.form.position' | translate }}</label>
    <div class="col-sm-9 text-left">{{ model.positionName || '-' }}</div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 control-label">{{ 'app-chat.popup.form.phoneNumber' | translate }}</label>
    <div class="col-sm-9 text-left">{{ (model.phoneRegion?.callingCode || '-' ) + ( model.phone || '' ) }} </div>
  </div>
  <div 
  *ngIf="global.userSession && global.userSession.activeUserRole.role.code === global.appConstant.ROLE_CODE_BUYER
  && !isMe"
  class="button-group button-group-center">
    <app-button color="DANGER" [outline]="true" 
      (onClick)="doBlock()">
      <em class="pir pi-times-circle"></em>
      Block
    </app-button>
    <app-button color="SECONDARY" [outline]="true"
    (onClick)="doKick()">
      <em class="pir pi-sign-out"></em>
      Out From Session
    </app-button>
  </div>
</app-popup>
