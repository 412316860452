import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetTaskActivityComponent } from './app-widget-task-activity.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, AppWidgetContentSliderModule],
  declarations: [AppWidgetTaskActivityComponent],
  entryComponents: [AppWidgetTaskActivityComponent],
  exports: [AppWidgetTaskActivityComponent]
})
export class AppWidgetTaskActivityModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-task-activity', AppWidgetTaskActivityComponent);
  }
}
