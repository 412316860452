import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { PrItem } from 'src/app/core/bean/pr-item';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { Currency } from '../../core/bean/currency';
import { ItemType } from '../../core/bean/item-type';
import { RfqVendor } from '../../core/bean/rfq-vendor';
import { RfqVendorItem } from '../../core/bean/rfq-vendor-item';
import { TableColumn } from '../../core/components/table/domain/table-column';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { RfqResponseRequest } from './rfq-response.request';
import { RfqResponseResponse } from './rfq-response.response';

@Component({
  templateUrl: './rfq-response-edit.component.html'
})
export class RfqResponseEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  @ViewChild('selectorItem')
  tableItem: AppTableXComponent;
  @ViewChild('selectorVendor')
  tableVendor: AppTableXComponent;

  public timeOut: any;
  public rfqVendorId: number;
  public isView: boolean;
  public urlBackOutside: string;
  public cancellationAlert: string;
  public CURRENCY_DIGITS_INFO: string;
  public currentDate: Date = new Date();
  public rfqVendor: RfqVendor = new RfqVendor();
  public rfqRequest: RfqResponseRequest = new RfqResponseRequest();
  public rfqResponse: RfqResponseResponse = new RfqResponseResponse();
  public prItemList: PrItem[] = [];
  public vendorList: PopupVendorView[] = [];
  public isSubmit = false;
  public isShowCloseButton = false;
  public tableResponseItem: TableResponseModel<any>;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    true
  );
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );
  public token: string;
  public fileUploader: FileUploader = new FileUploader(
    '/rfq/',
    'rfq.form.file',
    'DOC_RFQ_TEMPLATE',
    false,
    5
  );
  public fileUploaderResponse: FileUploader = new FileUploader(
    '/rfq-response/',
    'rfq-response.form.file',
    'DOC_RFQ_RESPONSE',
    false,
    5
  );
  readonly SEARCH_INTERVAL = 1000;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public currency: CurrencyPipe,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute
  ) {
    super('rfq-response', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<RfqVendor>('/rfq-response/notification/' + this.token)
      .subscribe((rfqVendor: RfqVendor) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('rfqVendor', rfqVendor);
        this.global.routerParams.set('rfqVendorId', rfqVendor.id);
        this.global.routerParams.set('urlBackOutside', '/pages/rfq-response');
        if (
          rfqVendor.responseRfqStatus.code ===
            this.global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE ||
          rfqVendor.responseRfqStatus.code ===
            this.global.appConstant.pm.RFQ_STATUS_DRAFT
        ) {
          this.router.navigate(['/pages/rfq-response/edit']);
          this.global.routerParams.set('todo', 'edit');
        } else {
          this.router.navigate(['/pages/rfq-response/detail']);
          this.global.routerParams.set('todo', 'view');
        }
      });
  }

  public setInitializationState(): void {
    this.doSetDataFromRouterParams();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.rfqVendorId = this.global.routerParams.get('rfqVendorId');
    this.rfqVendor = this.global.routerParams.get('rfqVendor');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.formGroup.get('responseFile').setIsView(true);
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      title: [null],
      date: [null],
      note: [null],
      itemType: [null],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null],
      startDate: [null],
      endDate: [null],
      file: [null],
      responseFile: [null],
      recordList: this.tableResponseItem.formArray
    });
    this.formGroup.get('code').setIsView(true);
    this.formGroup.get('file').setIsView(true);
    this.formGroup.get('title').setIsView(true);
    this.formGroup.get('date').setIsView(true);
    this.formGroup.get('note').setIsView(true);
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<RfqResponseResponse>(
        '/rfq-response/add-edit',
        new RouteRequestModel(this.todo, this.rfqVendorId)
      )
      .subscribe((rfqResponse: RfqResponseResponse) => {
        this.rfqResponse = rfqResponse;

        if (this.rfqVendor.rfq) {
          const startDate: Date = this.rfqVendor.rfq.startDate
            ? new Date(this.rfqVendor.rfq.startDate)
            : null;
          const endDate: Date = this.rfqVendor.rfq.endDate
            ? new Date(this.rfqVendor.rfq.endDate)
            : null;
          const date = { from: startDate, to: endDate };
          const { id, code, title, note } = this.rfqVendor.rfq;
          this.currencyOptionList.setRequestValues(rfqResponse.currencyList);
          this.fileUploader.setFileList(this.rfqResponse.fileList);
          this.itemTypeOptionList.setRequestValues(
            this.rfqResponse.itemTypeList
          );
          this.formGroup.patchValue({
            id,
            code,
            title,
            startDate,
            endDate,
            date,
            note,
            file: this.fileUploader.fileObjectList
          });
        }
        if (
          !rfqResponse.rfqVendorItemList ||
          rfqResponse.rfqVendorItemList.length === 0
        ) {
          const rfqVendorItemList: RfqVendorItem[] = [];
          this.rfqResponse.rfqItemList.forEach((element: any) => {
            const rfqVendorItem = new RfqVendorItem();
            rfqVendorItem.rfqItem = element;
            rfqVendorItem.rfqVendor = this.rfqVendor;
            rfqVendorItem.totalBidPrice = 0;
            rfqVendorItemList.push(rfqVendorItem);
          });
          this.rfqResponse.rfqVendorItemList = rfqVendorItemList;
        }
        if (
          (rfqResponse.rfqVendorItemList &&
            rfqResponse.rfqVendorItemList.length > 0 &&
            this.rfqVendor.responseRfqStatus.code ===
              this.global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE) ||
          (this.rfqVendor.responseRfqStatus.code ===
            this.global.appConstant.pm.RFQ_STATUS_DRAFT &&
            this.todo !== 'view')
        ) {
          this.rfqResponse.rfqVendorItemList.forEach((element: any) => {
            if (element.bidPrice) {
              const bidPrice = {
                price: element.bidPrice,
                currency: this.rfqResponse.currencyList[0]
              };
              element.bidPrice = bidPrice;
            }
            if (element.totalBidPrice) {
              const totalBidPrice = {
                price: element.totalBidPrice,
                currency: this.rfqResponse.currencyList[0]
              };
              element.totalBidPrice = totalBidPrice;
            }
          });
        }

        this.tableResponseItem.setRecordList(
          this.rfqResponse.rfqVendorItemList
        );
        this.tableResponseItem.reload();
        if (
          this.rfqResponse.responseFileList &&
          this.rfqResponse.responseFileList.length > 0
        ) {
          this.fileUploaderResponse.setFileList(
            this.rfqResponse.responseFileList
          );
          this.formGroup.patchValue({
            responseFile: this.fileUploaderResponse.fileObjectList
          });
        }

        if (!this.rfqVendor.rfpVendor) {
          this.formGroup
            .get('responseFile')
            .setValidators([Validators.required()]);
          this.formGroup.get('responseFile').updateValueAndValidity();
        }
        this.doSetAmount();
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    if (
      this.rfqVendor.responseRfqStatus.code ===
        this.global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE ||
      (this.rfqVendor.responseRfqStatus.code ===
        this.global.appConstant.pm.RFQ_STATUS_DRAFT &&
        this.todo !== 'view')
    ) {
      this.tableResponseItem = new TableResponseModel(this.moduleCode, [
        {
          field: 'rfqItem.prItem.prItemImageList',
          header: 'table.column.thumbnail',
          className: 'text-center',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'rfqItem.prItem.item.itemType.code'
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'rfqItem.prItem.item.name',
          header: 'table.column.itemName'
        },

        {
          field: 'rfqItem.prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'rfqItem.prItem.item.itemType.code'
          },
          isSortable: false
        },
        {
          field: 'rfqItem.prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-center',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return this.global.converterService.convertDecimal(
                tablePlugin.value
              );
            }
          }
        },
        {
          field: 'rfqItem.prItem.item.uom.name',
          header: 'table.column.uom',
          className: 'text-center'
        },
        {
          field: 'bidPrice',
          header: 'table.column.bidPrice',
          className: 'text-right',
          plugins: [
            {
              name: 'currency',
              optionList: this.currencyOptionList,
              onInput: this.onInputPrice.bind(this),
              validators: Validators.required()
            }
          ],
          isSearchTable: false
        },
        {
          field: 'totalBidPrice',
          header: 'table.column.totalBidPrice',
          className: 'text-right',
          plugins: [
            {
              name: 'currency',
              optionList: this.currencyOptionList,
              isView: true
            }
          ],
          isSearchTable: false
        }
      ]);
    } else {
      this.tableResponseItem = new TableResponseModel(this.moduleCode, [
        {
          field: 'rfqItem.prItem.prItemImageList',
          header: 'table.column.thumbnail',
          className: 'text-center',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'rfqItem.prItem.item.itemType.code'
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'rfqItem.prItem.item.name',
          header: 'table.column.itemName'
        },

        {
          field: 'rfqItem.prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'rfqItem.prItem.item.itemType.code'
          },
          isSortable: false
        },
        {
          field: 'rfqItem.prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-center',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return this.global.converterService.convertDecimal(
                tablePlugin.value
              );
            }
          }
        },
        {
          field: 'rfqItem.prItem.item.uom.name',
          header: 'table.column.uom',
          className: 'text-center'
        },
        {
          field: 'bidPrice',
          header: 'table.column.bidPrice',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        },
        {
          field: 'totalBidPrice',
          header: 'table.column.totalBidPrice',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: ' text-right'
        }
      ]);
    }
  }

  public setDecimal(field: number): string {
    if (!field) {
      return this.currency.transform(0, '', '', this.CURRENCY_DIGITS_INFO);
    } else {
      return this.currency.transform(field, '', '', this.CURRENCY_DIGITS_INFO);
    }
  }

  public onChangeItem(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      const rfqVendorItemList = [];
      this.rfqResponse.rfqVendorItemList.forEach(
        (rfqVendorItem: RfqVendorItem) => {
          if (rfqVendorItem.rfqItem.prItem.item.itemType.id === itemType.id) {
            rfqVendorItemList.push(rfqVendorItem);
          }
        }
      );
      this.tableResponseItem.setRecordList(rfqVendorItemList);
    } else {
      this.tableResponseItem.setRecordList(this.rfqResponse.rfqVendorItemList);
    }
    this.tableResponseItem.reload();
    this.doSetAmount();
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;

    this.tableResponseItem
      .getRecordList()
      .forEach((rfqVendorItem: RfqVendorItem) => {
        let totalBidPrice = 0;
        if (
          this.rfqVendor.responseRfqStatus.code ===
            this.global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE ||
          (this.rfqVendor.responseRfqStatus.code ===
            this.global.appConstant.pm.RFQ_STATUS_DRAFT &&
            this.todo !== 'view')
        ) {
          totalBidPrice = rfqVendorItem.totalBidPrice
            ? rfqVendorItem.totalBidPrice['price']
            : 0;
        } else {
          totalBidPrice = rfqVendorItem.totalBidPrice
            ? rfqVendorItem.totalBidPrice
            : 0;
        }
        if (
          rfqVendorItem.rfqItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        ) {
          goodsAmountTotal = +goodsAmountTotal + +totalBidPrice;
        }
        if (
          rfqVendorItem.rfqItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        ) {
          serviceAmountTotal = +serviceAmountTotal + +totalBidPrice;
        }
      });
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });
  }

  public onInputPrice(event): void {
    this.formGroup.markAsDirty();
    const currentRowChange = this.tableResponseItem.currentRowChange;
    if (+event.price > +currentRowChange.row.record.rfqItem.prItem.price) {
      const bidPriceForm = currentRowChange.row.formGroup.get('bidPrice');
      bidPriceForm.setValidators([
        Validators.max(+currentRowChange.row.record.rfqItem.prItem.price)
      ]);
      bidPriceForm.setErrors({
        message: this.translateService.instant('app.validation.max')
      });
      bidPriceForm.markAsTouched({ onlySelf: true });
    } else if (!+event.price) {
      const bidPriceForm = currentRowChange.row.formGroup.get('bidPrice');
      bidPriceForm.setValidators([Validators.required()]);
      bidPriceForm.setErrors({
        message: this.translateService.instant('app.validation.required')
      });
      bidPriceForm.markAsTouched({ onlySelf: true });
    }

    const tableColumnTotalBidPrice: TableColumn =
      currentRowChange.row.columnList[6];
    const tableColumnQuantity: TableColumn = currentRowChange.row.columnList[3];
    const objTotalBidPrice = {
      price: +tableColumnQuantity.value * +event.price,
      currency: this.rfqResponse.currencyList[0]
    };
    tableColumnTotalBidPrice.changeValue(objTotalBidPrice);
    tableColumnTotalBidPrice.reload();

    const recordList = this.tableResponseItem.getRecordList().map(record => {
      const indexOfR = this.tableResponseItem
        .getUpdatedRecordList()
        .findIndex(
          updateRecord => updateRecord.rfqItem.id === record.rfqItem.id
        );
      return indexOfR === -1
        ? record
        : this.tableResponseItem.getUpdatedRecordList()[indexOfR];
    });

    this.tableResponseItem.setRecordList(recordList);
    this.rfqResponse.rfqVendorItemList = recordList;
    this.doSetAmount();
  }

  public doViewItem(rfqVendorItem: RfqVendorItem): void {
    this.appPopupPrItemInfoService.open(rfqVendorItem.rfqItem.prItem.id);
  }

  public doSetMarkAsDirty(): void {
    this.formGroup.markAsDirty();
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.tableResponseItem.getRecordList().forEach(element => {
        element.bidPrice = element.bidPrice
          ? element.bidPrice['price']
            ? element.bidPrice['price']
            : element.bidPrice
          : 0;
        element.totalBidPrice = element.totalBidPrice
          ? element.totalBidPrice['price']
            ? element.totalBidPrice['price']
            : element.totalBidPrice
          : 0;
      });
      this.rfqVendor.amount = this.formGroup.value.amount;
      this.rfqVendor.goodsAmount = this.formGroup.value.goodsAmount;
      this.rfqVendor.serviceAmount = this.formGroup.value.serviceAmount;
      this.rfqRequest.rfqVendor = this.rfqVendor;
      this.rfqRequest.rfqVendorItemList =
        this.tableResponseItem.getRecordList();
      this.rfqRequest.fileObjectList = this.fileUploaderResponse.fileObjectList;
      this.rfqRequest.isSubmit = false;

      this.setStateProcessing();
      const url =
        this.rfqVendor.responseRfqStatus.code ===
        this.global.appConstant.pm.RFQ_STATUS_DRAFT
          ? '/rfq-response/update'
          : '/rfq-response/insert';
      this.httpClientService
        .post<Response<RfqVendor>>(url, this.rfqRequest)
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.snackbarService.showWarning('app.msg.info.sucsessSave');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('rfqVendor', response.body);
                this.global.routerParams.set('rfqVendorId', response.body.id);
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/rfq-response'
                );
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/rfq-response/edit']);
              });
          } else {
            this.global.alertService.showError(response.statusText);
          }
          this.setStateReady();
        });
    }
  }
  public doSubmit(): void {
    this.isSubmit = true;
    this.validate();
    if (this.formGroup.valid) {
      this.tableResponseItem.getRecordList().forEach(element => {
        element.bidPrice = element.bidPrice
          ? element.bidPrice['price']
            ? element.bidPrice['price']
            : element.bidPrice
          : 0;
        element.totalBidPrice = element.totalBidPrice
          ? element.totalBidPrice['price']
            ? element.totalBidPrice['price']
            : element.totalBidPrice
          : 0;
      });
      this.rfqVendor.amount = this.formGroup.value.amount;
      this.rfqVendor.goodsAmount = this.formGroup.value.goodsAmount;
      this.rfqVendor.serviceAmount = this.formGroup.value.serviceAmount;
      this.rfqRequest.rfqVendor = this.rfqVendor;
      this.rfqRequest.rfqVendorItemList =
        this.tableResponseItem.getRecordList();
      this.rfqRequest.fileObjectList = this.fileUploaderResponse.fileObjectList;
      this.rfqRequest.isSubmit = true;

      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url =
              this.rfqVendor.responseRfqStatus.code ===
              this.global.appConstant.pm.RFQ_STATUS_DRAFT
                ? '/rfq-response/update'
                : '/rfq-response/insert';
            this.httpClientService
              .post<Response<RfqVendor>>(url, this.rfqRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService.confirmation(
                    this.translateService.instant(
                      'rfq-response.confirm.msg.submitSuccess'
                    ),
                    'rfq-response.confirm.title.submitSuccess',
                    null,
                    null,
                    'pvc pv-confetti',
                    true
                  );
                  this.todo = 'view';
                  this.setViewOnly();
                  this.router
                    .navigateByUrl('/', { skipLocationChange: true })
                    .then(() => {
                      this.global.routerParams.clear();
                      this.global.routerParams.set('rfqVendor', response.body);
                      this.global.routerParams.set(
                        'rfqVendorId',
                        response.body.id
                      );
                      this.global.routerParams.set(
                        'urlBackOutside',
                        '/pages/rfq-response'
                      );
                      this.global.routerParams.set('todo', 'view');
                      this.router.navigate(['/pages/rfq-response/edit']);
                    });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }
}
