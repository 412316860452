<div
  class="similar-product"
  *ngIf="!formLoading && model && model.getPageRecords().length !== 0"
>
  <div class="similar-product-header">
    <h4>{{ 'app-similar-product.title' | translate }}</h4>
    <div
      class="float-right mb-3"
      *ngIf="model && model.getPageRecords().length > 0"
    >
      <app-button
        color="SECONDARY"
        size="TN"
        (onClick)="onPageChangeLeft()"
        [ngStyle]="{ 'padding-right': '10px' }"
        [disabled]="model.pagination.page === 1"
      >
        <em class="pir pi-chevron-left"></em>
      </app-button>
      <app-button
        color="PRIMARY"
        size="TN"
        (onClick)="onPageChangeRight()"
        [ngStyle]="{ 'padding-right': '10px' }"
      >
        <em class="pir pi-chevron-right"></em>
      </app-button>
    </div>
  </div>
  <app-catalog-item-x [customModel]="model"></app-catalog-item-x>
</div>
