import { NgModule } from '@angular/core';
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import { PipesModule } from 'src/app/core/pipe/pipe.module';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppAccordionModule } from '../../app-accordion/app-accordion.module';
import { AppBadgeCatalogModule } from '../../app-badge-catalog/app-badge-catalog.module';
import { AppBadgeModule } from '../../app-badge/app-badge.module';
import { AppCardModule } from '../../app-card/app-card.module';
import { AppCurrencyModule } from '../../app-currency/app-currency.module';
import { TableModule } from '../../table/table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupFinalPriceDetailFieldComponent } from './app-popup-final-price-detail-field.component';
import { AppPopupFinalPriceDetailComponent } from './app-popup-final-price-detail.component';
import { AppPopupFinalPriceDetailService } from './app-popup-final-price-detail.service';

@NgModule({
  imports: [
    VendorModule,
    DirectivesModule,
    AppCurrencyModule,
    TableModule,
    PipesModule,
    AppBadgeModule,
    AppBadgeCatalogModule,
    AppPopupModule,
    AppAccordionModule,
    AppCardModule
  ],
  declarations: [
    AppPopupFinalPriceDetailComponent,
    AppPopupFinalPriceDetailFieldComponent
  ],
  entryComponents: [
    AppPopupFinalPriceDetailComponent,
    AppPopupFinalPriceDetailFieldComponent
  ],
  exports: [
    AppPopupFinalPriceDetailComponent,
    AppPopupFinalPriceDetailFieldComponent
  ],
  providers: [AppPopupFinalPriceDetailService]
})
export class AppPopupFinalPriceDetailModule {}
