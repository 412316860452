import { Global } from '../../global/global';
import { ServiceLocator } from '../../services/service-locator';
import { ValidatorMessage } from './error-message-constant';
export class CustomValidatorError {
  public static create(type: string, message: string, defaultError = {}): CustomValidatorError {
    const global = ServiceLocator.injector.get(Global);
    const validatorMessage: ValidatorMessage = new ValidatorMessage();
    const errorCode = validatorMessage['ERROR_MESSAGE_' + type.toUpperCase()];
    const newMessage = global.translateService.instant(message || errorCode);
    return new CustomValidatorError(type, newMessage, defaultError);
  }

  constructor(type: string, message: string, defaultError: object = {}) {
    Object.assign(this, { [type]: true, message, ...defaultError });
    Object.freeze(this);
  }
}
