import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { ContractChangeHistory } from 'src/app/core/bean/contract-change-history';
import { ContractFileHistory } from 'src/app/core/bean/contract-file-history';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { AppPopupContractMonitoringEditContractComponent } from './app-popup-contract-monitoring-edit-contract.component';
import { AppPopupContractMonitoringSignedDocumentComponent } from './app-popup-contract-monitoring-signed-document.component';
import { ContractMonitoringResponse } from './contract-monitoring.response';

@Component({
  templateUrl: './contract-monitoring-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None
})
export class ContractMonitoringEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ContractChangeHistory>;
  public contractId: number;
  public urlBack: string;
  public isLoading: boolean;
  public contract: Contract = new Contract();
  public contractFileHistory: ContractFileHistory = new ContractFileHistory();
  public contractMonitoringResponse: ContractMonitoringResponse =
    new ContractMonitoringResponse();
  public fileUploader: FileUploader = new FileUploader(
    '/contract-monitoring/',
    '',
    this.global.appConstant.fileType.DOC_CONTRACT
  );
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('contract-monitoring', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
    this.buildTableResponseContractHistory();
    this.tableResponse.setCustomData(this.contractId);
  }

  public setDataFromRouterParams(): void {
    const todoLocalStorage = localStorage.getItem('todo');
    if (todoLocalStorage) {
      this.todo = todoLocalStorage;
      localStorage.removeItem('todo');
    } else {
      this.todo = this.global.routerParams.get('todo');
    }
    this.contractId =
      localStorage.getItem('contractId') ||
      this.global.routerParams.get('contractId');
    const contract = localStorage.getItem('contract');
    if (contract) {
      this.contract = JSON.parse(localStorage.getItem('contract'));
    } else {
      this.global.routerParams.get('contract');
    }
    this.urlBack =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    localStorage.removeItem('contractId');
    localStorage.removeItem('contract');
    localStorage.removeItem('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      contractFile: [null],
      description: [null],
      uploadBy: [null],
      uploadDate: [null],
      version: [null]
    });
    this.setViewOnly();
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ContractMonitoringResponse>(
        '/contract-monitoring/detail',
        new RouteRequestModel('', this.contractId)
      )
      .subscribe((contractMonitoringResponse: ContractMonitoringResponse) => {
        this.contractMonitoringResponse = contractMonitoringResponse;
        this.contractFileHistory =
          this.contractMonitoringResponse.contractFileHistory;
        if (this.contractMonitoringResponse.contractFileHistory != null) {
          this.fileUploader.setFile(
            this.contractMonitoringResponse.contractFileHistory.file
          );
          const uploadDate: Date = new Date(
            this.contractMonitoringResponse.createdAt
          );
          this.formGroup.patchValue({
            description:
              this.contractMonitoringResponse.contractFileHistory.description,
            contractFile: this.fileUploader.fileObjectList,
            uploadBy: this.contractMonitoringResponse.uploadBy.name,
            uploadDate,
            version:
              this.contractMonitoringResponse.contractFileHistory.sequence
          });
        }
        this.setStateReady();
      });
  }

  public doAddDocument(): void {
    const header = this.translateService.instant(
      'contract-monitoring.popup.title.signedDocument'
    );
    const contract = this.contract;
    const contractFileHistory =
      this.contractMonitoringResponse.contractFileHistory;
    const contractMonitoringResponse = this.contractMonitoringResponse;
    this.appPopupService
      .open(AppPopupContractMonitoringSignedDocumentComponent, {
        header,
        contract,
        contractFileHistory,
        contractMonitoringResponse
      })
      .subscribe(contractFileHistory => {
        this.setStateReady();
        this.isLoading = true;
        this.log.debug(contractFileHistory);
        this.httpClientService
          .post<ContractMonitoringResponse>(
            '/contract-monitoring/detail',
            new RouteRequestModel('', this.contractId)
          )
          .subscribe(
            (contractMonitoringResponse: ContractMonitoringResponse) => {
              this.contractMonitoringResponse = contractMonitoringResponse;
              this.contractFileHistory =
                this.contractMonitoringResponse.contractFileHistory;
              if (this.contractMonitoringResponse.contractFileHistory != null) {
                this.setViewOnly();
                this.fileUploader.setFile(
                  this.contractMonitoringResponse.contractFileHistory.file
                );
                const uploadDate: Date = new Date(
                  this.contractMonitoringResponse.createdAt
                );
                const contractFile = this.fileUploader.fileObjectList;
                this.formGroup.patchValue({
                  description:
                    this.contractMonitoringResponse.contractFileHistory
                      .description,
                  contractFile,
                  uploadBy: this.contractMonitoringResponse.uploadBy.name,
                  uploadDate,
                  version:
                    this.contractMonitoringResponse.contractFileHistory.sequence
                });
                this.setStateReady();
              }
              this.isLoading = false;
            }
          );
      });
  }

  public doEditContract(): void {
    const contract = this.contract;
    const contractFileHistory =
      this.contractMonitoringResponse.contractFileHistoryNego;
    const vendorBank = this.contractMonitoringResponse.vendorBank;
    this.appPopupService
      .open(AppPopupContractMonitoringEditContractComponent, {
        contract,
        contractFileHistory,
        vendorBank
      })
      .subscribe(contract => {
        this.setStateReady();
        this.isLoading = true;
        this.contract.startDate = contract.startDate;
        this.contract.endDate = contract.endDate;
        this.isLoading = false;
        this.tableResponse.reloadClient();
      });
  }
  public buildTableResponseContractHistory(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'user.name',
        header: 'table.column.user'
      },
      {
        field: 'date',
        header: 'table.column.createdDate',
        format: FieldFormatEnum.LongDateAndTime
      },
      {
        field: 'context',
        header: 'table.column.context'
      },
      {
        field: 'newValue',
        header: 'table.column.newValue',
        customClass: 'white-space-normal'
      },
      {
        field: 'oldValue',
        header: 'table.column.oldValue',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public doCancel(): void {
    if (this.urlBack === 'close-window') {
      window.close();
    } else {
      this.router.navigate(['/pages/contract-monitoring']);
    }
  }
}
