import { WorkProgram } from './../../core/bean/work-program';
import { Currency } from './../../core/bean/currency';
import { BudgetType } from './../../core/bean/budget-type';
import { Coa } from './../../core/bean/coa';
import { CostCenter } from './../../core/bean/cost-center';
import { Organization } from './../../core/bean/organization';
export class PurchaseRequestBudgetModel {
    isCheckedAll = false;
    recordPageMap: Map<number, ChangeBudget> = new Map<number, ChangeBudget>();
}
export interface ChangeBudget {
    id: number;
    isChecked: boolean;
    plannedBudget: number;
    organization: Organization;
    costCenter: CostCenter;
    coa: Coa;
    budgetType: BudgetType;
    currency: Currency;
    workProgram: WorkProgram;
}
