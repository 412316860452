<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="contract-request.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/contract-request/index"
        isShowEditTable="true"
      >
        <ng-template #headerButtons>
          <app-button (onClick)="doAdd()"
            ><em class="fas fa-plus"></em
            >{{ 'app.button.add' | translate }}</app-button
          >
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            *ngIf="
              rowData.contractStatus.code ===
                global.appConstant.cm.CONTRACT_STATUS_REVISION ||
              rowData.contractStatus.code ===
                global.appConstant.cm.CONTRACT_STATUS_DRAFT
            "
            size="TN"
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <em class="fas fa-pencil-alt"></em>
          </app-button>
          <app-button
            *ngIf="
              rowData.contractStatus.code !==
                global.appConstant.cm.CONTRACT_STATUS_REVISION &&
              rowData.contractStatus.code !==
                global.appConstant.cm.CONTRACT_STATUS_DRAFT
            "
            size="TN"
            (onClick)="doDetail(rowData)"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <em class="fas fa-search"></em>
          </app-button>
          <!-- <app-button
            *ngIf="
              rowData.contractStatus.code !==
                global.appConstant.cm.CONTRACT_STATUS_CANCEL &&
              !rowData.isFinalized
            "
            size="TN"
            color="DANGER"
            (onClick)="doCancel(rowData)"
            title="{{ 'app.tooltip.cancel' | translate }}"
          >
            <em class="fas fa-ban"></em>
          </app-button> -->
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
