<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="project.titleDetail"
      detail="{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'project.form.name' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ formGroup.controls.name.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'project.form.organization' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ formGroup.controls.organizationName.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'project.form.description' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ formGroup.controls.description.value }}
          </div>
        </div>
      </form>

      <div class="col-lg-12 mb-4">
        <app-card [isLoading]="formLoading" [isSaving]="formSaving">
          <app-table
            [tableResponse]="tableResponse"
            [isServerSide]="true"
            stringUrl="/project/work-plan/index"
            customData="{{ projectId }}"
          >
            <ng-template #headerButtons> </ng-template>
          </app-table>
          <div class="row justify-content-center mt-5">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="doCancel()"
              >{{ 'app.button.back' | translate }}</button
            >
            <button
              class="btn btn-primary ml-2"
              (click)="doReport(projectResponse.project)"
              ><em class="fas fa-print"></em>&nbsp;
              {{ 'app.button.print' | translate }}</button
            >
          </div>
        </app-card>
      </div>
    </app-card>
  </div>
</div>
