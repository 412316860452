import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { HospitalStockMonitoringDetailComponent } from './hospital-stock-monitoring-detail.component';
import { HospitalStockMonitoringComponent } from './hospital-stock-monitoring.component';
const routes: Routes = [
  { path: '', component: HospitalStockMonitoringComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: HospitalStockMonitoringDetailComponent,
    data: { breadcrumb: 'hospital-stock.breadcrumb.monitoring.detail' }
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [HospitalStockMonitoringComponent, HospitalStockMonitoringDetailComponent]
})
export class HospitalStockMonitoringModule {}
