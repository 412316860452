<app-layout
  [isLoading]="formLoading"
  header="procurement-reg-vendor.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="!formLoading && isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      class="cml-2"
      *ngIf="
        procurementRegVendorStatusCode ===
        global.appConstant.pm.PROC_REG_VENDOR_STATUS_NEW
      "
      color="INFO"
    >
      {{ 'procurement-reg-vendor.badge.new' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        procurementRegVendorStatusCode ===
        global.appConstant.pm.PROC_REG_VENDOR_STATUS_VERIFICATION
      "
      color="WARNING"
    >
      {{ 'procurement-reg-vendor.badge.verification' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        procurementRegVendorStatusCode ===
        global.appConstant.pm.PROC_REG_VENDOR_STATUS_ACCEPTED
      "
      color="SUCCESS"
    >
      {{ 'procurement-reg-vendor.badge.accepted' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        procurementRegVendorStatusCode ===
          global.appConstant.pm.PROC_REG_VENDOR_STATUS_DECLINED ||
        procurementRegVendorStatusCode ===
          global.appConstant.pm.PROC_REG_VENDOR_STATUS_EXPIRED ||
        procurementRegVendorStatusCode ===
          global.appConstant.pm.PROC_REG_VENDOR_STATUS_HOLD ||
        procurementRegVendorStatusCode ===
          global.appConstant.pm.PROC_REG_VENDOR_STATUS_CANCELED ||
        procurementRegVendorStatusCode ===
          global.appConstant.pm.PROC_REG_VENDOR_STATUS_FAILED
      "
      color="DANGER"
    >
      {{
        'procurement-reg-vendor.badge.' +
          procurementRegVendorStatusCode.toLowerCase() | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        procurementRegVendorStatusCode ===
        global.appConstant.pm.PROC_REG_VENDOR_STATUS_REVISION
      "
      color="FEEDBACK"
    >
      {{ 'procurement-reg-vendor.badge.revision' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        procurementRegVendorStatusCode ===
        global.appConstant.pm.PROC_REG_VENDOR_STATUS_DRAFT
      "
      color="SECONDARY"
    >
      {{ 'procurement-reg-vendor.badge.draft' | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          (procurement?.procurementStatus?.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT)
        "
      >
        {{ canceledOrFailedMessage | translate }}
      </app-alert-x>
      <app-alert-x
        *ngIf="
          procurementRegVendorStatusCode ===
          global.appConstant.pm.PROC_REG_VENDOR_STATUS_DECLINED
        "
        color="DANGER"
        >{{ 'procurement-reg-vendor.msg.declined' | translate }}</app-alert-x
      >
      <app-alert-x
        *ngIf="
          procurementRegVendorStatusCode ===
          global.appConstant.pm.PROC_REG_VENDOR_STATUS_EXPIRED
        "
        color="DANGER"
        >{{ 'procurement-reg-vendor.msg.expired' | translate }}</app-alert-x
      >
      <app-alert-x
        *ngIf="
          !formLoading &&
          procurementRegVendorStatusCode ===
            global.appConstant.pm.PROC_REG_VENDOR_STATUS_NEW &&
          procurementRegVendorResponse &&
          !procurementRegVendorResponse.isMeetTheCriteria
        "
        color="DANGER"
      >
        <span style="float: left"
          >{{
            'procurement-reg-vendor.alert.danger.doesntMeetCriteria' | translate
          }}
        </span>

        <span style="float: right; position: relative; left: 2rem">
          <span
            ><em
              class="pir pi-info-circle"
              style="
                color: var(--secondary);
                position: relative;
                font-size: 1.25rem;
                top: 0.05rem;
              "
            ></em
          ></span>
          <a
            style="text-decoration: underline; float: right"
            (click)="doOpenPopupCriteria()"
          >
            {{ 'procurement-reg-vendor.alert.danger.hyperlink' | translate }}
          </a>
        </span>
      </app-alert-x>

      <app-alert-icon
        color="INFO"
        icon="FILE_DOWNLOAD"
        *ngIf="
          procurementRegVendorStatusCode !==
            global.appConstant.pm.PROC_REG_VENDOR_STATUS_DECLINED &&
          procurementRegVendorStatusCode !==
            global.appConstant.pm.PROC_REG_VENDOR_STATUS_EXPIRED &&
          procurementRegVendorStatusCode !==
            global.appConstant.pm.PROC_REG_VENDOR_STATUS_NEW &&
          procurementRegVendorStatusCode !==
            global.appConstant.pm.PROC_REG_VENDOR_STATUS_DRAFT &&
          procurementRegVendorResponse &&
          procurementRegVendorResponse.procurementVendor &&
          procurementRegVendorResponse.procurementVendor.isRegistered
        "
      >
        <div style="display: grid">
          <span
            >{{ 'procurement-reg-vendor.alert.msg.succesRegister' | translate
            }}{{ ' '
            }}{{
              procurementRegVendorResponse.procurementVendor.registeredDate
                | date: global.config.parameterModel.datetimeFormatLong
            }}
          </span>
          <span class="mt-3" (click)="doPrint()">
            <a
              class="mr-2"
              style="text-decoration-line: underline; font-weight: 500"
            >
              {{ 'procurement-reg-vendor.alert.msg.clickHere' | translate }}
              <span
                ><em
                  class="pir pi-download-line ml-1"
                  style="
                    top: auto;
                    left: auto;
                    font-size: 16px;
                    font-weight: 500;
                  "
                ></em>
              </span>
            </a>
            <span class="ml-3"
              >{{ ' '
              }}{{
                'procurement-reg-vendor.alert.msg.downloadLetterReg' | translate
              }}</span
            >
          </span>
        </div>
      </app-alert-icon>

      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="procurement-reg-vendor.procDetail.general.title"
          tips="procurement-reg-vendor.procDetail.general.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-item-request
          [objectList]="procurementItemList"
          [isView]="true"
          [isShowPrice]="false"
          [itemTypeList]="itemTypeList"
        >
        </app-item-request>

        <app-card *ngIf="procurement.isPq && procurementPqList.length > 0">
          <app-table-xx
            [model]="tableResponse"
            header="procurement-reg-vendor.pqRequirement.general.title"
            tips="procurement-reg-vendor.pqRequirement.general.tips"
            [isView]="todo === 'view'"
            (onAfterRowCreated)="onAfterRowCreated($event)"
            [isShowPagination]="false"
          >
            <ng-template #headerButtons>
              <app-button (onClick)="doEditProfile()">
                {{
                  'procurement-reg-vendor.pqRequirement.button.editProfile'
                    | translate
                }}
              </app-button>
            </ng-template>
          </app-table-xx>
        </app-card>

        <div
          *ngIf="
            !formGroup.isView && procurementRegVendorResponse.isMeetTheCriteria
          "
        >
          <app-alert-x
            color="WARNING"
            [isShowCloseButton]="true"
            [isBold]="false"
            >{{
              'procurement-reg-vendor.msg.warning.accToProceed' | translate
            }}</app-alert-x
          >
          <div style="position: relative; top: -1rem">
            <app-check-box formControlName="isAccepted" position="HORIZONTAL">{{
              'procurement-reg-vendor.regist.confirmation' | translate
            }}</app-check-box>
          </div>
        </div>
        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center cmt-4"
                *ngIf="!formGroup.isView"
              >
                <app-button
                  *ngIf="procurement.isPq && procurementPqList.length > 0"
                  color="PRIMARY"
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="isButtonDisabled()"
                  >{{ 'app.button.save' | translate }}
                </app-button>
                <app-button
                  (onClick)="doSubmit()"
                  [disabled]="isButtonDisabled()"
                >
                  <span
                    *ngIf="
                      procurementRegVendorStatusCode !==
                      global.appConstant.pm.PROC_REG_VENDOR_STATUS_REVISION
                    "
                    >{{
                      'procurement-reg-vendor.button.register' | translate
                    }}</span
                  >
                  <span
                    *ngIf="
                      procurementRegVendorStatusCode ===
                      global.appConstant.pm.PROC_REG_VENDOR_STATUS_REVISION
                    "
                    >{{
                      'procurement-reg-vendor.button.reRegister' | translate
                    }}</span
                  >
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
