import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Attribute } from '../../core/bean/attribute';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './attribute.component.html'
})
export class AttributeComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<Attribute>;
  public readonly urlRouterNavigateToDeleteAttribute = '/attribute/delete';

  constructor(translateService: TranslateService) {
    super('attribute', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '0.groupName',
        header: 'table.column.group',
        customClass: 'text-center'
      },
      {
        field: '1',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      {
        field: '0.formObject.name',
        header: 'table.column.objectType',
        customClass: 'text-center'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/attribute/add']);
  }

  public doEdit(rowData: any): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('attributeId', rowData[0].id);
    this.router.navigate(['/pages/attribute/edit']);
  }

  public doDelete(rowData: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.deleteAttribute(rowData[0]);
        }
      });
  }

  public deleteAttribute(attribute: Attribute): void {
    this.deleteAttributeFromServer(
      this.urlRouterNavigateToDeleteAttribute,
      attribute
    ).subscribe(response => {
      this.showAlertMessage(response);
    });
  }

  public showAlertMessage(response: Response<Attribute>): void {
    if (response.status === ResponseStatusModel.OK) {
      this.global.alertService.showSuccessDelete();
      this.table.reload();
    } else {
      this.global.alertService.showError(response.statusText);
    }
  }

  public deleteAttributeFromServer(
    urlDeleteFileType: string,
    attribute: Attribute
  ): Observable<Response<Attribute>> {
    return this.httpClientService.post<Response<Attribute>>(
      urlDeleteFileType,
      attribute
    );
  }
}
