import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorBlacklist } from '../../core/bean/vendor-blacklist';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppPopupTabBlacklistHistoryComponent } from './app-popup-tab-blacklist-history.component';
@Component({
  templateUrl: './tab-blacklist-history.component.html',
  styles: [
    '.blacklist-reason-width { white-space: normal !important; min-width: 250px }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TabBlacklistHistoryComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<VendorBlacklist>;
  public tableResponse: TableResponseModel<VendorBlacklist>;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-blacklist-history', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'blacklistDuration.name', header: 'table.column.duration' },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'reason',
        header: 'table.column.reason',
        customClass: 'blacklist-reason-width'
      },
      {
        field: 'file.fileName',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName'
      }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }

  public doDetail(vendorBlacklist: VendorBlacklist): void {
    this.appPopupService.open(
      AppPopupTabBlacklistHistoryComponent,
      { vendorBlacklist },
      { size: 'lg', backdrop: 'static' }
    );
  }
}
