import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { Currency } from '../bean/currency';
import { PaymentStatus } from '../bean/payment-status';
import { PaymentWorklistStatus } from '../bean/payment-worklist-status';
import { ProcurementSowPaymentTerm } from '../bean/procurement-sow-payment-term';
import { VendorBank } from './../bean/vendor-bank';
export class PaymentEntity extends BaseWorkflowObjectEntity {
  paymentStatus: PaymentStatus;
  procurementSowPaymentTerm: ProcurementSowPaymentTerm;
  paymentWorklistStatus: PaymentWorklistStatus;
  currency: Currency;
  amount: number;
  vendorBank: VendorBank;
}
