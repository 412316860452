import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { CostCenter } from '../../core/bean/cost-center';
import { Currency } from '../../core/bean/currency';
import { OtherCost } from '../../core/bean/other-cost';
import { PaymentVoucherOtherCost } from '../../core/bean/payment-voucher-other-cost';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './payment-voucher-popup-other-cost.component.html'
})
export class PaymentVoucherPopupOtherCostComponent extends BaseComponentComponent {
  @Input() public currencyList: Currency[] = [];
  @Input() public otherCostList: OtherCost[] = [];
  @Input() public costCenterList: CostCenter[] = [];
  @Input() public paymentVoucherOtherCost: PaymentVoucherOtherCost;
  @Input() public todo: string;
  @Output() onChange: EventEmitter<PaymentVoucherOtherCost> =
    new EventEmitter();

  public otherCostOptionList: OptionListModel<OtherCost> = new OptionListModel(
    true
  );
  public balanceTypeOptionList: OptionListModel<any> = new OptionListModel(
    true
  );
  public costCenterOptionList: OptionListModel<CostCenter> =
    new OptionListModel(true);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );

  public typeList = [
    {
      name: this.global.translateService.instant(
        'payment-voucher.balanceType.debit'
      ),
      value: 1
    },
    {
      name: this.global.translateService.instant(
        'payment-voucher.balanceType.credit'
      ),
      value: 2
    }
  ];

  constructor() {
    super('payment-voucher');
  }

  onInit(): void {
    this.buildFomGroup();
    this.setFormGroup();
  }

  public setOptionList(): void {
    this.otherCostOptionList.setRequestValues(this.otherCostList);
    this.costCenterOptionList.setRequestValues(this.costCenterList);
    this.currencyOptionList.setRequestValues(this.currencyList);
    this.balanceTypeOptionList.setRequestValues(this.typeList);
  }

  public buildFomGroup(): void {
    this.formGroup = this.formBuilder.group({
      otherCost: [null, Validators.required()],
      coa: [null, Validators.required()],
      costCenter: [null, Validators.required()],
      balanceType: [null, Validators.required()],
      amount: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    if (this.paymentVoucherOtherCost) {
      const amount = {
        price: this.paymentVoucherOtherCost.amount,
        currency: this.currencyList[0]
      };
      const typeList = this.typeList.filter(
        type => type.value === this.paymentVoucherOtherCost.balanceType
      );
      const otherCost = this.paymentVoucherOtherCost.otherCost
        ? this.paymentVoucherOtherCost.otherCost
        : { name: this.paymentVoucherOtherCost.name };
      this.formGroup.patchValue({
        otherCost,
        coa: this.paymentVoucherOtherCost.coa,
        costCenter: this.paymentVoucherOtherCost.costCenter,
        balanceType: typeList[0],
        amount
      });
    }
    if (this.todo === 'view') {
      this.setViewOnly();
    }
    this.setOptionList();
    this.setStateReady();
  }

  public doAdd(): void {
    this.validate();
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      if (!this.paymentVoucherOtherCost) {
        this.paymentVoucherOtherCost = new PaymentVoucherOtherCost();
      }
      this.paymentVoucherOtherCost.coa = this.formGroup.value.coa;
      this.paymentVoucherOtherCost.costCenter = this.formGroup.value.costCenter;
      if (this.formGroup.value.otherCost && this.formGroup.value.otherCost.id) {
        this.paymentVoucherOtherCost.otherCost = this.formGroup.value.otherCost;
      }
      this.paymentVoucherOtherCost.name = this.formGroup.value.otherCost?.name;
      this.paymentVoucherOtherCost.balanceType =
        +this.formGroup.value.balanceType.value;
      this.paymentVoucherOtherCost.amount = +this.formGroup.value.amount.price;
      this.onChange.emit(this.paymentVoucherOtherCost);
    }
  }
}
