import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { ProcurementScopeWorkView } from 'src/app/core/view/entity/bean/procurement-scope-work-view';

@Component({
  templateUrl: './procurement-scope-work.component.html'
})
export class ProcurementScopeWorkComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ProcurementScopeWorkView>;

  constructor(translateService: TranslateService) {
    super('procurement-scope-work', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurement.number',
        header: 'table.column.procurementNumber',
        customClass: 'text-center'
      },
      {
        field: 'procurement.name',
        header: 'table.column.procurementName'
      },
      {
        field: 'procurement.organization.name',
        header: 'table.column.organization',
        customClass: 'text-center'
      },
      {
        field: 'vendorName',
        header: 'table.column.vendorName'
      },
      {
        field: 'number',
        header: 'table.column.poSpkNumber',
        customClass: 'text-center'
      },
      {
        field: 'date',
        header: 'table.column.poSpkDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'procurementScopeWork.scopeWorkStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
  }

  public doEdit(procurementScopeWorkView: ProcurementScopeWorkView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set(
      'procurementVendorId',
      procurementScopeWorkView.id
    );
    if (procurementScopeWorkView.procurementScopeWork) {
      this.global.routerParams.set('todo', 'edit');
      this.global.routerParams.set(
        'procurementScopeWorkId',
        procurementScopeWorkView.procurementScopeWork?.id
      );
    } else {
      this.global.routerParams.set('todo', 'add');
      this.global.routerParams.set(
        'procurementScopeWorkId',
        procurementScopeWorkView.id
      );
    }
    this.router.navigate(['/pages/procurement-scope-work/edit']);
  }

  public doDetail(procurementScopeWorkView: ProcurementScopeWorkView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set(
      'procurementScopeWorkId',
      procurementScopeWorkView.procurementScopeWork.id
    );
    this.global.routerParams.set(
      'procurementVendorId',
      procurementScopeWorkView.id
    );
    this.router.navigate(['/pages/procurement-scope-work/edit']);
  }
}
