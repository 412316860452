import { BaseEntity } from '../base/base-entity';
import { Qualification } from '../bean/qualification';
import { Segmentation } from '../bean/segmentation';
import { Vendor } from '../bean/vendor';
import { VendorAssociation } from '../bean/vendor-association';

export class VendorSegmentationEntity extends BaseEntity {
    segmentationList: Segmentation[];
    segmentationName: string;
    vendorAssociationList: VendorAssociation[];

    segmentation: Segmentation;
    qualification: Qualification;
    vendor: Vendor;
}
