import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ProcurementMatrixEditAddComponent } from './procurement-matrix-edit-add.component';
import { ProcurementMatrixComponent } from './procurement-matrix.component';

const routes = [
  { path: '', component: ProcurementMatrixComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: ProcurementMatrixEditAddComponent,
    data: { breadcrumb: 'procurement-matrix.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ProcurementMatrixEditAddComponent,
    data: { breadcrumb: 'procurement-matrix.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ProcurementMatrixComponent, ProcurementMatrixEditAddComponent],
  providers: []
})
export class ProcurementMatrixModule {}
