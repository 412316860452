import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { BillingMemoBudgetDetail } from '../../bean/billing-memo-budget-detail';
import { BudgetAllocation } from '../../bean/budget-allocation';
import { Currency } from '../../bean/currency';
import { FieldFormatEnum } from '../app-table/model/field-format.enum';
import { TableResponseModel } from '../app-table/model/table-response-model';

@Component({
  templateUrl: './app-popup-billing-memo-info-view-budget-detail.component.html'
})
export class AppPopupBillingMemoInfoViewBudgetDetailComponent extends BaseComponentComponent {
  @Input() public billingMemoBudgetDetailList: BillingMemoBudgetDetail[];
  @Input() public totalBilling: number;
  @Input() public taxPercentage: number;
  @Input() public budgetAllocation: BudgetAllocation;
  @Input() public currency: Currency;

  public tableResponse: TableResponseModel<BillingMemoBudgetDetail>;
  public totalTemp: number;

  constructor() {
    super('');
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      'app-billing-memo-information-view',
      [
        {
          field: 'coa.code',
          header: 'table.column.coa',
          customClass: 'text-center'
        },
        {
          field: 'costCenter.name',
          header: 'table.column.costCenter',
          customClass: 'text-center'
        },
        {
          field: 'taxPercentageValue',
          header: 'table.column.taxPercentage',
          customClass: 'text-right'
        },
        {
          field: 'taxBaseAmount',
          header: 'table.column.taxBase',
          customClass: 'text-right',
          format: FieldFormatEnum.Currency
        },
        {
          field: 'vatAmount',
          header: 'table.column.vat',
          customClass: 'text-right',
          format: FieldFormatEnum.Currency
        },
        {
          field: 'totalAmount',
          header: 'table.column.total',
          customClass: 'text-right',
          format: FieldFormatEnum.Currency
        }
      ]
    );

    this.tableResponse.setRecords(this.billingMemoBudgetDetailList);
    this.tableResponse.setTotalRecords(this.billingMemoBudgetDetailList.length);
  }
}
