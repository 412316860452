<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="bank-account.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'bank-account.form.code' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field type="code" autofocus="true" formControlName="code"
              maxLength="64"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'bank-account.form.bank' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-auto-complete formControlName="bank"
              stringUrl="/bank-account/get-bank-list"
              (onChange)="autoCompleteValueChange($event)"></app-auto-complete>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'bank-account.form.accountNumber' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field type="integer" formControlName="accountNumber"
              maxLength="32"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'bank-account.form.accountHolder' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field type="alphanumeric" formControlName="accountHolder"
              maxLength="128"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'bank-account.form.branch' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field type="alphanumeric" formControlName="branch"
              maxLength="128"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'bank-account.form.description' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doCancel()"
            [disabled]="formSaving">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()"
            [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>

      </form>
    </app-card>
  </div>
</div>