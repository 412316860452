<div class="row">
  <div class="col-12 mb-4">
    <div class="text-center py-4" *ngIf="formLoading">
      <img src="assets/img/common/loader-small.gif" alt="Loading" />
    </div>
    <form
      class="form-horizontal"
      [formGroup]="tabDocument.formGroup"
      *ngIf="!formLoading"
    >
      <ng-container
        *ngIf="
          tabProfileDocumentDTO.vendorDocTemplateList.length !== 0 && !isView
        "
      >
        <h5 class="separator">{{
          'tab-profile-document.form.documentTemplate' | translate
        }}</h5>
        <div class="form-group row text-sm-right">
          <div class="card border-0 w-0" [ngStyle]="{ margin: '1px' }">
            <div class="card-body">
              <div
                class="table-responsive custom-table float-none w-100"
                [ngStyle]="{ margin: '0 auto' }"
              >
                <table
                  class="table table-striped box-shadow"
                  aria-describedby="Table"
                >
                  <thead>
                    <tr>
                      <th class="text-left" scope="col">{{
                        'tab-profile-document.table.column.document' | translate
                      }}</th>
                      <th class="text-left" scope="col">{{
                        'tab-profile-document.table.column.file' | translate
                      }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let vendorDocTemplate of tabProfileDocumentDTO.vendorDocTemplateList;
                        let i = index
                      "
                    >
                      <td
                        class="text-left"
                        [ngStyle]="{ 'white-space': 'normal' }"
                      >
                        <p>{{ vendorDocTemplate.docName }}</p>
                      </td>
                      <td
                        [ngStyle]="{ 'white-space': 'normal' }"
                        [ngClass]="
                          vendorDocTemplate.docName.includes('Rekanan')
                            ? 'text-center'
                            : 'text-left'
                        "
                      >
                        <div
                          *ngIf="
                            vendorDocTemplate.docName.includes('Rekanan');
                            then buttonPrint;
                            else fileName
                          "
                        ></div>
                        <ng-template #fileName>
                          <a
                            href="#"
                            (click)="
                              doDownload(
                                $event,
                                vendorDocTemplate && vendorDocTemplate.file
                                  ? '/company-profile/file/view/' +
                                      vendorDocTemplate.file.uploadedFileName +
                                      '/' +
                                      global.appConstant.fileType
                                        .DOC_TEMPLATE_VENDOR
                                  : null,
                                vendorDocTemplate?.file?.fileName
                              )
                            "
                          >
                            <p>{{
                              vendorDocTemplate?.file?.fileName || '-'
                            }}</p>
                          </a>
                        </ng-template>
                        <ng-template #buttonPrint>
                          <app-button
                            (onClick)="doPrintDocument()"
                            tooltip="{{
                              'tab-profile-document.tooltip.print' | translate
                            }}"
                          >
                            <em
                              class="fas"
                              [ngClass]="
                                !isLoading
                                  ? 'fa-print'
                                  : 'fa-circle-notch fa-spin'
                              "
                            ></em>
                            {{ 'app.button.print' | translate }}
                          </app-button>
                        </ng-template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container formArrayName="vendorDocGroupList">
        <ng-container
          *ngFor="
            let vendorDocGroup of tabDocument.formGroup.controls
              .vendorDocGroupList.controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <h5 class="separator">{{
            'dynamic-attribute.' + vendorDocGroup.controls.translationKey.value
              | translate
          }}</h5>
          <ng-container formArrayName="vendorDocMasterList">
            <div class="form-group row text-sm-right">
              <div
                class="card border-0"
                [ngStyle]="{ margin: '1px', width: '-webkit-fill-available' }"
              >
                <div class="card-body">
                  <div
                    class="table-responsive custom-table float-none w-100"
                    [ngStyle]="{ margin: '0 auto' }"
                  >
                    <table
                      class="table table-striped box-shadow"
                      aria-describedby="Table"
                    >
                      <thead>
                        <tr>
                          <th class="text-left" scope="col">{{
                            'tab-profile-document.table.column.document'
                              | translate
                          }}</th>
                          <th class="text-center" scope="col" *ngIf="isView">{{
                            'tab-profile-document.table.column.documentStatus'
                              | translate
                          }}</th>
                          <th class="text-center" scope="col">{{
                            'tab-profile-document.table.column.documentNumber'
                              | translate
                          }}</th>
                          <th class="text-left" scope="col">{{
                            'tab-profile-document.table.column.documentPublisher'
                              | translate
                          }}</th>
                          <th class="text-center" scope="col">{{
                            'tab-profile-document.table.column.startDate'
                              | translate
                          }}</th>
                          <th class="text-center" scope="col">{{
                            'tab-profile-document.table.column.endDate'
                              | translate
                          }}</th>
                          <th class="text-left" scope="col" *ngIf="!isView">{{
                            'tab-profile-document.table.column.uploadFile'
                              | translate
                          }}</th>
                          <th class="text-left" scope="col" *ngIf="isView">{{
                            'tab-profile-document.table.column.file' | translate
                          }}</th>
                          <th
                            class="text-center"
                            scope="col"
                            *ngIf="
                              isView &&
                              !isVendorList &&
                              roleType !==
                                global.appConstant.core.ROLE_TYPE_VENDOR &&
                              (fromOutside === '/pages/on-boarding' ||
                                fromOutside === '/pages/approval-prcs')
                            "
                            >{{
                              'tab-profile-document.table.column.verification'
                                | translate
                            }}</th
                          >
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let vendorDocMaster of vendorDocGroup.controls
                              .vendorDocMasterList.controls;
                            let j = index
                          "
                          [formGroupName]="j"
                        >
                          <td
                            class="text-left"
                            [ngStyle]="{
                              'max-width': '240px',
                              'white-space': 'normal',
                              'min-width': '200px'
                            }"
                          >
                            {{
                              'dynamic-attribute.' +
                                vendorDocMaster.controls.translationKey.value
                                | translate
                            }}
                            <span
                              class="font-weight-bold text-danger"
                              *ngIf="
                                vendorDocMaster.controls.isMandatory.value &&
                                !tabDocument.formGroup.isView
                              "
                              >*</span
                            >
                          </td>
                          <td class="text-center" *ngIf="isView">
                            <app-text-field
                              size="XL"
                              formControlName="mandatoryName"
                            ></app-text-field>
                          </td>
                          <td class="text-center">
                            <app-text-field
                              size="XL"
                              formControlName="docNumber"
                              maxLength="32"
                              (onChange)="onChangeDoc($event, i, j)"
                            >
                              <app-flag-new
                                *ngIf="
                                  roleType !==
                                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                                  tabDocument.formGroup.isView &&
                                  !isVendorList &&
                                  (fromOutside === '/pages/on-boarding' ||
                                    fromOutside === '/pages/approval-prcs')
                                "
                                [oldValue]="
                                  tabProfileDocumentDTO.vendorDocGroupList[i]
                                    .vendorDocMasterList[j].vendorDoc
                                    ? tabProfileDocumentDTO.vendorDocGroupList[
                                        i
                                      ].vendorDocMasterList[j].vendorDoc
                                        .docNumber
                                    : null
                                "
                                [newValue]="
                                  tabProfileDocumentDTO.vendorDocGroupList[i]
                                    .vendorDocMasterList[j].vendorDocHistory
                                    ? tabProfileDocumentDTO.vendorDocGroupList[
                                        i
                                      ].vendorDocMasterList[j].vendorDocHistory
                                        .docNumber
                                    : null
                                "
                              ></app-flag-new>
                            </app-text-field>
                          </td>
                          <td class="text-left">
                            <app-text-field
                              size="XL"
                              formControlName="docPublisher"
                              type="alphabet"
                              maxLength="64"
                              (onChange)="onChangeDoc($event, i, j)"
                            >
                              <app-flag-new
                                *ngIf="
                                  roleType !==
                                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                                  tabDocument.formGroup.isView &&
                                  !isVendorList &&
                                  (fromOutside === '/pages/on-boarding' ||
                                    fromOutside === '/pages/approval-prcs')
                                "
                                [oldValue]="
                                  tabProfileDocumentDTO.vendorDocGroupList[i]
                                    .vendorDocMasterList[j].vendorDoc
                                    ? tabProfileDocumentDTO.vendorDocGroupList[
                                        i
                                      ].vendorDocMasterList[j].vendorDoc
                                        .docPublisher
                                    : null
                                "
                                [newValue]="
                                  tabProfileDocumentDTO.vendorDocGroupList[i]
                                    .vendorDocMasterList[j].vendorDocHistory
                                    ? tabProfileDocumentDTO.vendorDocGroupList[
                                        i
                                      ].vendorDocMasterList[j].vendorDocHistory
                                        .docPublisher
                                    : null
                                "
                              ></app-flag-new>
                            </app-text-field>
                          </td>
                          <td
                            class="text-center"
                            *ngIf="vendorDocMaster.controls.isStart.value"
                            [ngStyle]="{ 'min-width': '175px' }"
                          >
                            <app-date-picker
                              size="XL"
                              formControlName="startDate"
                              maxDate="currentDate"
                            >
                              <app-flag-new
                                *ngIf="
                                  roleType !==
                                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                                  tabDocument.formGroup.isView &&
                                  !isVendorList &&
                                  (fromOutside === '/pages/on-boarding' ||
                                    fromOutside === '/pages/approval-prcs')
                                "
                                [oldValue]="
                                  vendorDocMaster.controls.startDateActual.value
                                "
                                [newValue]="
                                  vendorDocMaster.controls.startDateHistory
                                    .value
                                "
                              ></app-flag-new>
                            </app-date-picker>
                          </td>
                          <td
                            class="text-center"
                            *ngIf="!vendorDocMaster.controls.isStart.value"
                            [ngStyle]="{ 'min-width': '175px' }"
                          >
                            -
                          </td>
                          <td
                            class="text-center"
                            *ngIf="
                              vendorDocMaster.controls.isExpiry.value &&
                              vendorDocMaster.controls.isStart.value
                            "
                            [ngStyle]="{ 'min-width': '175px' }"
                          >
                            <app-date-picker
                              size="XL"
                              formControlName="endDate"
                              minDate="startDate"
                            >
                              <app-flag-new
                                *ngIf="
                                  roleType !==
                                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                                  tabDocument.formGroup.isView &&
                                  !isVendorList &&
                                  (fromOutside === '/pages/on-boarding' ||
                                    fromOutside === '/pages/approval-prcs')
                                "
                                [oldValue]="vendorDocMaster.value.endDateActual"
                                [newValue]="
                                  vendorDocMaster.value.endDateHistory
                                "
                              ></app-flag-new>
                            </app-date-picker>
                          </td>
                          <td
                            class="text-center"
                            *ngIf="
                              vendorDocMaster.controls.isExpiry.value &&
                              !vendorDocMaster.controls.isStart.value
                            "
                            [ngStyle]="{ 'min-width': '175px' }"
                          >
                            <app-date-picker
                              size="XL"
                              formControlName="endDate"
                              minDate="currentDate"
                            >
                              <app-flag-new
                                *ngIf="
                                  roleType !==
                                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                                  tabDocument.formGroup.isView &&
                                  !isVendorList &&
                                  (fromOutside === '/pages/on-boarding' ||
                                    fromOutside === '/pages/approval-prcs')
                                "
                                [oldValue]="vendorDocMaster.value.endDateActual"
                                [newValue]="
                                  vendorDocMaster.value.endDateHistory
                                "
                              ></app-flag-new>
                            </app-date-picker>
                          </td>
                          <td
                            class="text-center"
                            *ngIf="!vendorDocMaster.controls.isExpiry.value"
                            [ngStyle]="{ 'min-width': '175px' }"
                          >
                            -
                          </td>
                          <td
                            class="text-left"
                            [ngStyle]="{
                              'min-width': '210px',
                              'max-width': !tabDocument.formGroup.isView
                                ? null
                                : '300px'
                            }"
                          >
                            <app-upload
                              size="XL"
                              formControlName="file"
                              [fileUploader]="
                                fileUploaderList[
                                  vendorDocMaster.controls.index.value
                                ]
                              "
                              (onChange)="onChangeDocUpload($event, i, j)"
                            >
                              <app-flag-new
                                *ngIf="
                                  roleType !==
                                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                                  tabDocument.formGroup.isView &&
                                  !isVendorList &&
                                  (fromOutside === '/pages/on-boarding' ||
                                    fromOutside === '/pages/approval-prcs')
                                "
                                [oldValue]="
                                  tabProfileDocumentDTO.vendorDocGroupList[i]
                                    .vendorDocMasterList[j].vendorDoc
                                    ? tabProfileDocumentDTO.vendorDocGroupList[
                                        i
                                      ].vendorDocMasterList[j].vendorDoc.file
                                      ? tabProfileDocumentDTO
                                          .vendorDocGroupList[i]
                                          .vendorDocMasterList[j].vendorDoc.file
                                          .id
                                      : null
                                    : null
                                "
                                [newValue]="
                                  tabProfileDocumentDTO.vendorDocGroupList[i]
                                    .vendorDocMasterList[j].vendorDocHistory
                                    ? tabProfileDocumentDTO.vendorDocGroupList[
                                        i
                                      ].vendorDocMasterList[j].vendorDocHistory
                                        .file
                                      ? tabProfileDocumentDTO
                                          .vendorDocGroupList[i]
                                          .vendorDocMasterList[j]
                                          .vendorDocHistory.file.id
                                      : null
                                    : null
                                "
                                [hoverValue]="
                                  tabProfileDocumentDTO.vendorDocGroupList[i]
                                    .vendorDocMasterList[j].vendorDoc
                                    ? tabProfileDocumentDTO.vendorDocGroupList[
                                        i
                                      ].vendorDocMasterList[j].vendorDoc.file
                                      ? tabProfileDocumentDTO
                                          .vendorDocGroupList[i]
                                          .vendorDocMasterList[j].vendorDoc.file
                                          .fileName
                                      : null
                                    : null
                                "
                              ></app-flag-new>
                            </app-upload>
                          </td>
                          <td
                            class="text-center"
                            *ngIf="
                              isView &&
                              !isVendorList &&
                              roleType !==
                                global.appConstant.core.ROLE_TYPE_VENDOR &&
                              (fromOutside === '/pages/on-boarding' ||
                                fromOutside === '/pages/approval-prcs')
                            "
                            [ngStyle]="{ 'min-width': '130px' }"
                          >
                            <app-combo-box
                              size="XL"
                              formControlName="isVerified"
                              [optionList]="verificationOptionList"
                              container="body"
                            ></app-combo-box>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <label
                      class="float-left"
                      *ngIf="
                        !isView && tabProfileDocumentDTO.indexDocGroupSIUP === i
                      "
                      >*)
                      {{
                        'tab-profile-document.validation.documents' | translate
                      }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <br />
        </ng-container>
      </ng-container>
      <ng-container formArrayName="additionalDocList" *ngIf="!hiddenAdditional">
        <h5 class="separator">{{
          'tab-profile-document.form.additionalDocument' | translate
        }}</h5>
        <div class="text-right">
          <ng-container *ngIf="!isView">
            <app-button (onClick)="doAddAdditional()"
              ><em class="fas fa-plus"></em>
              {{ 'app.button.add' | translate }}</app-button
            >
          </ng-container>
        </div>
        <div class="form-group row text-sm-right">
          <div
            class="card border-0"
            [ngStyle]="{ margin: '1px', width: '-webkit-fill-available' }"
          >
            <div class="card-body">
              <div
                class="table-responsive custom-table float-none w-100"
                [ngStyle]="{ margin: '0 auto' }"
              >
                <table
                  class="table table-striped box-shadow"
                  aria-describedby="Table"
                >
                  <thead>
                    <tr>
                      <th class="text-left" scope="col">{{
                        'tab-profile-document.table.column.document' | translate
                      }}</th>
                      <th class="text-center" scope="col" *ngIf="isView">{{
                        'tab-profile-document.table.column.documentStatus'
                          | translate
                      }}</th>
                      <th class="text-center" scope="col">{{
                        'tab-profile-document.table.column.documentNumber'
                          | translate
                      }}</th>
                      <th class="text-left" scope="col">{{
                        'tab-profile-document.table.column.documentPublisher'
                          | translate
                      }}</th>
                      <th class="text-center" scope="col">{{
                        'tab-profile-document.table.column.startDate'
                          | translate
                      }}</th>
                      <th class="text-center" scope="col">{{
                        'tab-profile-document.table.column.endDate' | translate
                      }}</th>
                      <th class="text-left" scope="col" *ngIf="!isView">{{
                        'tab-profile-document.table.column.uploadFile'
                          | translate
                      }}</th>
                      <th class="text-left" scope="col" *ngIf="isView">{{
                        'tab-profile-document.table.column.file' | translate
                      }}</th>
                      <th
                        class="text-center"
                        scope="col"
                        *ngIf="
                          isView &&
                          !isVendorList &&
                          roleType !==
                            global.appConstant.core.ROLE_TYPE_VENDOR &&
                          (fromOutside === '/pages/on-boarding' ||
                            fromOutside === '/pages/approval-prcs')
                        "
                        >{{
                          'tab-profile-document.table.column.verification'
                            | translate
                        }}</th
                      >
                      <th
                        class="text-center"
                        scope="col"
                        *ngIf="
                          isView &&
                          !isVendorList &&
                          roleType !==
                            global.appConstant.core.ROLE_TYPE_VENDOR &&
                          companyProfileDTO.vendor.vendorStatus.code ===
                            'ACTIVE'
                        "
                        >{{
                          'tab-profile-document.table.column.status' | translate
                        }}</th
                      >
                      <th class="text-center" scope="col" *ngIf="!isView">{{
                        'app.table.column.action' | translate
                      }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let additionalDoc of tabDocument.formGroup.controls
                          .additionalDocList.controls;
                        let j = index
                      "
                      [formGroupName]="j"
                    >
                      <td
                        class="text-left"
                        [ngStyle]="{
                          'max-width': '240px',
                          'white-space': 'normal',
                          'min-width': '200px'
                        }"
                      >
                        <app-text-field
                          size="XL"
                          formControlName="name"
                          type="alphabet"
                          maxLength="128"
                          (onChange)="onChangeDocAdditional($event, j)"
                        >
                          <app-flag-new
                            *ngIf="
                              roleType !==
                                global.appConstant.core.ROLE_TYPE_VENDOR &&
                              tabDocument.formGroup.isView &&
                              !isVendorList &&
                              (fromOutside === '/pages/on-boarding' ||
                                fromOutside === '/pages/approval-prcs')
                            "
                            [oldValue]="vendorAdditionalDocList[j].name"
                            [newValue]="vendorAdditionalDocHistoryList[j].name"
                          ></app-flag-new>
                        </app-text-field>
                      </td>
                      <td class="text-center" *ngIf="isView">
                        <label>Additional</label>
                      </td>
                      <td class="text-center">
                        <app-text-field
                          size="XL"
                          formControlName="docNumber"
                          maxLength="32"
                          (onChange)="onChangeDocAdditional($event, j)"
                        >
                          <app-flag-new
                            *ngIf="
                              roleType !==
                                global.appConstant.core.ROLE_TYPE_VENDOR &&
                              tabDocument.formGroup.isView &&
                              !isVendorList &&
                              (fromOutside === '/pages/on-boarding' ||
                                fromOutside === '/pages/approval-prcs')
                            "
                            [oldValue]="vendorAdditionalDocList[j].docNumber"
                            [newValue]="
                              vendorAdditionalDocHistoryList[j].docNumber
                            "
                          ></app-flag-new>
                        </app-text-field>
                      </td>
                      <td class="text-left">
                        <app-text-field
                          size="XL"
                          formControlName="docPublisher"
                          type="alphabet"
                          maxLength="64"
                          (onChange)="onChangeDocAdditional($event, j)"
                        >
                          <app-flag-new
                            *ngIf="
                              roleType !==
                                global.appConstant.core.ROLE_TYPE_VENDOR &&
                              tabDocument.formGroup.isView &&
                              !isVendorList &&
                              (fromOutside === '/pages/on-boarding' ||
                                fromOutside === '/pages/approval-prcs')
                            "
                            [oldValue]="vendorAdditionalDocList[j].docPublisher"
                            [newValue]="
                              vendorAdditionalDocHistoryList[j].docPublisher
                            "
                          ></app-flag-new>
                        </app-text-field>
                      </td>
                      <td
                        class="text-center"
                        [ngStyle]="{ 'min-width': '170px' }"
                      >
                        <app-date-picker
                          size="XL"
                          formControlName="startDate"
                          maxDate="currentDate"
                        >
                          <app-flag-new
                            *ngIf="
                              roleType !==
                                global.appConstant.core.ROLE_TYPE_VENDOR &&
                              tabDocument.formGroup.isView &&
                              !isVendorList &&
                              (fromOutside === '/pages/on-boarding' ||
                                fromOutside === '/pages/approval-prcs')
                            "
                            [oldValue]="
                              additionalDoc.controls.startDateActual.value
                            "
                            [newValue]="
                              additionalDoc.controls.startDateHistory.value
                            "
                          ></app-flag-new>
                        </app-date-picker>
                      </td>
                      <td
                        class="text-center"
                        [ngStyle]="{ 'min-width': '179px' }"
                      >
                        <app-date-picker
                          size="XL"
                          formControlName="endDate"
                          minDate="startDate"
                        >
                          <app-flag-new
                            *ngIf="
                              roleType !==
                                global.appConstant.core.ROLE_TYPE_VENDOR &&
                              tabDocument.formGroup.isView &&
                              !isVendorList &&
                              (fromOutside === '/pages/on-boarding' ||
                                fromOutside === '/pages/approval-prcs')
                            "
                            [oldValue]="additionalDoc.value.endDateActual"
                            [newValue]="additionalDoc.value.endDateHistory"
                          ></app-flag-new>
                        </app-date-picker>
                      </td>
                      <td
                        class="text-left"
                        [ngStyle]="{
                          'min-width': '210px',
                          'max-width': !tabDocument.formGroup.isView
                            ? null
                            : '300px'
                        }"
                      >
                        <app-upload
                          size="XL"
                          formControlName="file"
                          [fileUploader]="fileUploaderAdditionalList[j]"
                        >
                          <app-flag-new
                            *ngIf="
                              roleType !==
                                global.appConstant.core.ROLE_TYPE_VENDOR &&
                              tabDocument.formGroup.isView &&
                              !isVendorList &&
                              (fromOutside === '/pages/on-boarding' ||
                                fromOutside === '/pages/approval-prcs')
                            "
                            [oldValue]="
                              vendorAdditionalDocList[j].file
                                ? vendorAdditionalDocList[j].file.id
                                : null
                            "
                            [newValue]="
                              vendorAdditionalDocHistoryList[j].file
                                ? vendorAdditionalDocHistoryList[j].file.id
                                : null
                            "
                            [hoverValue]="
                              vendorAdditionalDocList[j].file
                                ? vendorAdditionalDocList[j].file.fileName
                                : null
                            "
                          ></app-flag-new>
                        </app-upload>
                      </td>
                      <td
                        class="text-center"
                        *ngIf="
                          isView &&
                          !isVendorList &&
                          roleType !==
                            global.appConstant.core.ROLE_TYPE_VENDOR &&
                          (fromOutside === '/pages/on-boarding' ||
                            fromOutside === '/pages/approval-prcs')
                        "
                        [ngStyle]="{ 'min-width': '130px' }"
                      >
                        <app-combo-box
                          size="XL"
                          formControlName="isVerified"
                          [optionList]="verificationOptionList"
                          container="body"
                        ></app-combo-box>
                      </td>
                      <td
                        class="text-center"
                        *ngIf="
                          isView &&
                          !isVendorList &&
                          roleType !==
                            global.appConstant.core.ROLE_TYPE_VENDOR &&
                          companyProfileDTO.vendor.vendorStatus.code ===
                            'ACTIVE'
                        "
                        [ngStyle]="{ 'min-width': '130px' }"
                      >
                        <div
                          innerHtml="{{
                            vendorAdditionalDocHistoryList[j].crudInfo
                          }}"
                          *ngIf="vendorAdditionalDocHistoryList[j].crudInfo"
                        ></div>
                        <div
                          class="text-center"
                          *ngIf="
                            vendorAdditionalDocHistoryList[j].crudInfo === null
                          "
                          >-</div
                        >
                      </td>
                      <td class="text-center" *ngIf="!isView">
                        <app-button
                          size="TN"
                          color="DANGER"
                          (onClick)="doDelete(j)"
                          title="{{ 'app.tooltip.delete' | translate }}"
                          *ngIf="j !== 0"
                        >
                          <span class="fas fa-times"></span>
                        </app-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>
