import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './tab-financial-statement.component.html'
})
export class TabFinancialStatementComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<Array<object>>;
  public tableResponse: TableResponseModel<any>;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-financial-statement', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '0.year',
        header: 'table.column.financialYear',
        customClass: 'text-center'
      },
      {
        field: '0.currentAsset',
        header: 'table.column.currentAsset',
        format: FieldFormatEnum.Currency,
        customClass: 'text-right',
        currencyCodeRef: '0.currency.code'
      },
      {
        field: '0.currentLiability',
        header: 'table.column.currentLiability',
        format: FieldFormatEnum.Currency,
        customClass: 'text-right',
        currencyCodeRef: '0.currency.code'
      },
      {
        field: '0.currentRatio',
        header: 'table.column.currentRatio',
        customClass: 'text-right'
      },
      {
        field: '0.totalLiability',
        header: 'table.column.totalLiability',
        format: FieldFormatEnum.Currency,
        customClass: 'text-right',
        currencyCodeRef: '0.currency.code'
      },
      {
        field: '0.equity',
        header: 'table.column.equity',
        format: FieldFormatEnum.Currency,
        customClass: 'text-right',
        currencyCodeRef: '0.currency.code'
      },
      {
        field: '0.deptEquityRatio',
        header: 'table.column.deptEquityRatio',
        customClass: 'text-right'
      },
      {
        field: '0.netIncome',
        header: 'table.column.netIncome',
        format: FieldFormatEnum.Currency,
        customClass: 'text-right',
        currencyCodeRef: '0.currency.code'
      },
      {
        field: '1',
        header: 'table.column.returnOfEquity',
        customClass: 'text-right'
      },
      {
        field: '0.cash',
        header: 'table.column.cash',
        format: FieldFormatEnum.Currency,
        customClass: 'text-right',
        currencyCodeRef: '0.currency.code'
      },
      {
        field: '0.totalActiva',
        header: 'table.column.totalActiva',
        format: FieldFormatEnum.Currency,
        customClass: 'text-right',
        currencyCodeRef: '0.currency.code'
      },
      {
        field: '0.vendorFinancialStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      },
      { field: '0.auditor', header: 'table.column.auditorName' },
      {
        field: '0.auditNumber',
        header: 'table.column.auditNumber',
        customClass: 'text-center'
      },
      {
        field: '0.date',
        header: 'table.column.auditDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: '0.file.fileName',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: '0.file.uploadedFileName'
      }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }
}
