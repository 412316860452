<app-layout
  [isLoading]="formLoading"
  [backTo]="urlBackOutside"
  header="payment-voucher.breadcrumb.index"
>
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge class="cml-2" [color]="badgeColor[paymentWorklistStatus.code]">
      {{
        paymentWorklistStatus.translationKey.module.code.toLowerCase() +
          '.' +
          paymentWorklistStatus.translationKey.key | translate
      }}
    </app-badge>
  </ng-template>
  <form [formGroup]="formGroup">
    <app-workflow-step-info
      [isLoading]="formLoading"
      [model]="paymentVoucherEditResponse.workflowStepInfoModel"
      *ngIf="!formLoading"
    >
    </app-workflow-step-info>
    <app-card
      header="payment-voucher.detail.card.details.title"
      tips="payment-voucher.detail.card.details.tips"
      *ngIf="!formLoading"
    >
      <ng-template #headerRight>
        <div class="button-group button-group-right">
          <app-button
            color="PRIMARY"
            [outline]="true"
            (onClick)="doViewRequestDetail()"
            >{{
              'payment-voucher.detail.button.viewRequestDetail' | translate
            }}</app-button
          >
          <app-button (onClick)="doViewAgreementDetail()">{{
            'payment-voucher.detail.button.viewAgreementDetail' | translate
          }}</app-button>
        </div>
      </ng-template>
      <app-fullfilled></app-fullfilled>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'payment-voucher.detail.form.voucherNumber' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          <app-text-generated
            formControlName="voucherNumber"
            maxLength="64"
            [moduleCode]="moduleCode"
            numberGeneratorCode="NUMBER"
            (onChange)="onChangeDetailsInput()"
          ></app-text-generated>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'payment-voucher.detail.form.voucherDate' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          <app-date-picker-x
            formControlName="voucherDate"
            (onChange)="onChangeDetailsInput()"
          ></app-date-picker-x>
        </div>
      </div>
    </app-card>

    <app-card
      header="payment-voucher.detail.card.deliverableItem.title"
      tips="payment-voucher.detail.card.deliverableItem.tips"
      *ngIf="!formLoading && !paymentVoucherEditResponse.isNoItem"
    >
      <app-deliverable-item [paymentId]="paymentId"> </app-deliverable-item>
    </app-card>

    <app-card
      header="payment-voucher.detail.card.paymentVoucher.title"
      tips="payment-voucher.detail.card.paymentVoucher.tips"
      *ngIf="!formLoading"
    >
      <app-alert-icon color="INFO" icon="WALLET">
        <div style="display: grid">
          <span class="mb-3">
            {{ 'payment-voucher.detail.info.totalRequestBill' | translate }}
          </span>
          <h2 class="text-info"
            >{{
              global.converterService.convertMoney(
                paymentVoucherEditResponse.payment.amount
              )
            }}&nbsp;{{ paymentVoucherEditResponse.currencyList[0].code }}</h2
          >
        </div>
      </app-alert-icon>
      <div
        class="d-flex align-items-center mb-1"
        [ngClass]="todo === 'view' ? '' : 'justify-content-between'"
      >
        <h3>
          {{ 'payment-voucher.detail.form.billingValue' | translate }}
        </h3>
        <app-button *ngIf="todo !== 'view'" (onClick)="doAddBudget()"
          ><em class="pir pi-plus-circle"></em>&nbsp;{{
            'payment-voucher.detail.button.addBudget' | translate
          }}</app-button
        >
      </div>
      <h3 class="separator"></h3>
      <app-fullfilled *ngIf="todo !== 'view'"></app-fullfilled>
      <div
        class="no-data-wrapper"
        *ngIf="paymentVoucherBillingList?.length === 0"
      >
        <div class="book-wrapper pvc pv-doughnut-chart-dollar-sign"></div>
        <div class="text-no-data">
          <span class="text-no-data">
            {{ 'app-table.noDataAvailable' | translate }}
          </span>
          <span class="text-no-data">
            {{ 'app-table.letsTryToAddList' | translate }}
          </span>
        </div>
      </div>
      <app-table-xx
        *ngIf="paymentVoucherBillingList?.length !== 0"
        [model]="tableResponseBillingValue"
        [isMultipleSelect]="true"
        [isShowCheckBox]="formGroup.isView ? false : true"
        [isShowPagination]="false"
        (onAfterRowCreated)="onAfterRowBillingCreated($event)"
      >
        <ng-template #headerFilter>
          <div class="form-group row text-left mb-0">
            <label class="col-sm-8 control-label">{{
              'payment-voucher.detail.form.includeVat' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              <app-toggle-switch
                formControlName="includeVat"
                (onChange)="onChangeIncludeVat($event)"
              ></app-toggle-switch>
            </div>
          </div>
        </ng-template>
        <ng-container *ngIf="!formGroup.isView">
          <ng-template #actionButtons let-rowData>
            <app-button
              size="TN"
              (onClick)="doDeleteBilling(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}"
            >
              <em class="pir pi-trash-alt"></em>
              {{ 'app.button.delete' | translate }}
            </app-button>
          </ng-template>
        </ng-container>
        <ng-template #row let-state>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td> </td>
            <td colspan="99" class="text-right">
              {{ 'payment-voucher.detail.form.total' | translate }}:&nbsp;
              <strong>
                {{ totalPaid | resolveNumberToCurrency }}&nbsp;{{
                  paymentVoucherEditResponse?.currencyList[0].code
                }}
              </strong>
              <br />
              <small
                class="text-danger"
                *ngIf="totalPaid !== paymentVoucherEditResponse.payment.amount"
              >
                <em class="pir pi-info-circle"></em>
                {{
                  'payment-voucher.detail.form.validation.totalAmount'
                    | translate
                }}
              </small>
            </td>
          </tr>
        </ng-template>
      </app-table-xx>

      <div
        class="d-flex align-items-center mb-1"
        [ngClass]="todo === 'view' ? '' : 'justify-content-between'"
      >
        <h3>
          {{ 'payment-voucher.detail.form.incomeTax' | translate }}
        </h3>
        <app-button *ngIf="todo !== 'view'" (onClick)="doAddTax()"
          ><em class="pir pi-plus-circle"></em>&nbsp;{{
            'payment-voucher.detail.button.addTax' | translate
          }}</app-button
        >
      </div>
      <h3 class="separator"></h3>
      <app-alert-x *ngIf="todo !== 'view'">{{
        'payment-voucher.detail.alert.infoTax' | translate
      }}</app-alert-x>
      <div class="no-data-wrapper" *ngIf="paymentVoucherTaxList?.length === 0">
        <div class="book-wrapper pvc pv-calculator"></div>
        <div class="text-no-data">
          <span class="text-no-data">
            {{ 'app-table.noDataAvailable' | translate }}
          </span>
          <span class="text-no-data">
            {{ 'app-table.letsTryToAddList' | translate }}
          </span>
        </div>
      </div>
      <app-table-xx
        *ngIf="paymentVoucherTaxList?.length !== 0"
        [model]="tableResponseTax"
        (onClick)="doClickTax($event)"
        [isMultipleSelect]="true"
        [isShowCheckBox]="formGroup.isView ? false : true"
        [isShowPagination]="false"
      >
        <ng-container *ngIf="!formGroup.isView">
          <ng-template #actionButtons let-rowData>
            <app-button
              size="TN"
              (onClick)="doDeleteTax(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}"
            >
              <em class="pir pi-trash-alt"></em>
              {{ 'app.button.delete' | translate }}
            </app-button>
          </ng-template>
        </ng-container>
        <ng-template #row let-state>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td> </td>
            <td colspan="99" class="text-right">
              {{ 'payment-voucher.detail.form.total' | translate }}:&nbsp;
              <strong>
                {{ totalIncomeTax | resolveNumberToCurrency }}&nbsp;{{
                  paymentVoucherEditResponse?.currencyList[0].code
                }}
              </strong>
            </td>
          </tr>
        </ng-template>
      </app-table-xx>

      <div
        class="d-flex align-items-center mb-1"
        [ngClass]="todo === 'view' ? '' : 'justify-content-between'"
      >
        <h3>
          {{ 'payment-voucher.detail.form.otherCost' | translate }}
        </h3>
        <app-button *ngIf="todo !== 'view'" (onClick)="doAddOtherCost()"
          ><em class="pir pi-plus-circle"></em>&nbsp;{{
            'payment-voucher.detail.button.addItem' | translate
          }}</app-button
        >
      </div>
      <h3 class="separator"></h3>
      <app-alert-x *ngIf="todo !== 'view'">{{
        'payment-voucher.detail.alert.infoOtherCost' | translate
      }}</app-alert-x>
      <app-table-xx
        [model]="tableResponseOtherCost"
        (onClick)="doClickOtherCost($event)"
        [isMultipleSelect]="true"
        [isShowCheckBox]="formGroup.isView ? false : true"
        [isShowPagination]="false"
      >
        <ng-container *ngIf="!formGroup.isView">
          <ng-template #actionButtons let-rowData>
            <app-button
              size="TN"
              (onClick)="doDeleteOtherCost(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}"
            >
              <em class="pir pi-trash-alt"></em>
              {{ 'app.button.delete' | translate }}
            </app-button>
          </ng-template>
        </ng-container>
        <ng-template #row let-state>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td colspan="99" class="text-right">
              {{ 'payment-voucher.detail.form.total' | translate }}:&nbsp;
              <strong>
                {{ totalOtherCost | resolveNumberToCurrency }}&nbsp;{{
                  paymentVoucherEditResponse?.currencyList[0].code
                }}
              </strong>
            </td>
          </tr>
        </ng-template>
      </app-table-xx>
    </app-card>

    <app-vendor-payment-information
      [paymentId]="paymentId"
      [todo]="'view'"
    ></app-vendor-payment-information>

    <app-card
      header="payment-voucher.detail.card.paidToVendor.title"
      tips="payment-voucher.detail.card.paidToVendor.tips"
      *ngIf="!formLoading"
    >
      <div
        class="no-data-wrapper"
        *ngIf="
          paymentVoucherBillingList?.length === 0 &&
          paymentVoucherTaxList?.length === 0 &&
          paymentVoucherOtherCostList?.length === 0
        "
      >
        <div class="book-wrapper pvc pv-transparency"></div>
        <div class="text-no-data">
          <span class="text-no-data">
            {{ 'app-table.noDataAvailable' | translate }}
          </span>
          <span class="text-no-data">
            {{ 'app-table.letsTryToAddList' | translate }}
          </span>
        </div>
      </div>
      <app-table-xx
        *ngIf="
          paymentVoucherBillingList?.length !== 0 ||
          paymentVoucherTaxList?.length !== 0 ||
          paymentVoucherOtherCostList?.length !== 0
        "
        [model]="tableResponsePaidToVendor"
        [isShowPagination]="false"
        [isShowSearch]="false"
      >
        <ng-template #row let-state>
          <tr>
            <td></td>
            <td class="text-right">
              {{
                'payment-voucher.detail.form.netTotalPaidToVendor' | translate
              }}:&nbsp;
              <strong>
                {{ totalPaidToVendor | resolveNumberToCurrency }}&nbsp;{{
                  paymentVoucherEditResponse?.currencyList[0].code
                }}
              </strong>
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td class="text-right">
              (&nbsp;{{ +totalPaidToVendor | resolveNumberToWords }}
              Rupiah&nbsp;)
            </td>
            <td></td>
          </tr>
        </ng-template>
      </app-table-xx>
    </app-card>
    <app-approval-monitoring
      *ngIf="
        !formLoading &&
        ((todo === 'view' &&
          paymentVoucherEditResponse &&
          paymentVoucherEditResponse.approvalPathResponseModel &&
          !paymentVoucherEditResponse.isUserApproval) ||
          paymentWorklistStatus.code ===
            global.appConstant.cm.PAYMENT_WORKLIST_STATUS_REVISION)
      "
      [workflowPrcs]="paymentVoucherEditResponse.payment?.workflowPrcs"
      [moduleCode]="moduleCode"
      [approvalPathResponseModel]="
        paymentVoucherEditResponse.approvalPathResponseModel
      "
    >
    </app-approval-monitoring>
    <app-approval-prcs-x
      *ngIf="!formLoading && paymentVoucherEditResponse.isUserApproval"
      [workflowModelPrcs]="
        paymentVoucherEditResponse.payment?.workflowModelPrcs
      "
      [approvalModelPrcsId]="approvalModelPrcsId"
    >
    </app-approval-prcs-x>

    <div class="floating-button mt-5" *ngIf="!formLoading && todo !== 'view'">
      <div class="floating-button-wrapper">
        <div class="floating-button-content">
          <div class="button-group button-group-center">
            <app-button
              [outline]="true"
              (onClick)="doSave()"
              [disabled]="formSaving"
              >{{ 'app.button.save' | translate }}</app-button
            >
            <app-button (onClick)="doSubmit()" [disabled]="formSaving">{{
              'app.button.submit' | translate
            }}</app-button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
