<app-card
  header="app-approval-monitoring.title"
  tips="app-approval-monitoring.tips"
>
  <div class="col-lg-12">
    <app-approval-path-x
      [model]="approvalPath"
      [isApprovalView]="true"
    ></app-approval-path-x>
  </div>
  <div class="col-lg-12">
    <h4 class="separator">{{
      'app-approval-monitoring.separator.historyApproval' | translate
    }}</h4>
    <app-approval-log [moduleCode]="moduleCode" [workflowPrcs]="workflowPrcs">
    </app-approval-log>
  </div>
</app-card>
