import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Aanwijzing } from 'src/app/core/bean/aanwijzing';
import { AanwijzingParticipant } from 'src/app/core/bean/aanwijzing-participant';
import { Organization } from 'src/app/core/bean/organization';
import { ParticipantRole } from 'src/app/core/bean/participant-role';
import { Vendor } from 'src/app/core/bean/vendor';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { AanwijzingPopupInviteParticipantNewComponent } from './aanwijzing-popup-invite-participant-new.component';

@Component({
    templateUrl: './aanwijzing-popup-invite-participant.component.html'
})
export class AanwijzingPopupInviteParticipantComponent extends BaseComponentComponent {

    @Input() public aanwijzing: Aanwijzing;
    @Input() readonly aanwijzingParticipantList: AanwijzingParticipant[];
    @Input() participantRoleList: ParticipantRole[];
    @Input() vendorList: Vendor[];
    @Input() departmentList: Organization[];
    @Input() existAanwijzingParticipantList: AanwijzingParticipant[];

    @Output() public onChange: EventEmitter<AanwijzingParticipant[]> = new EventEmitter();

    /** filter optionlist */
    public participantRoleOptionList: OptionListModel<ParticipantRole> = new OptionListModel(true);
    public departmentOptionList: OptionListModel<Organization> = new OptionListModel(true);
    public vendorOptionList: OptionListModel<Vendor> = new OptionListModel(true);

    /** menampung aanwijzing participant */
    public participantOptionList: OptionListModel<AanwijzingParticipant> = new OptionListModel(true, 'email');

    constructor(public translateService: TranslateService,
                public appPopupService: AppPopupService,
                public snackBarService: SnackbarService,
                public toastService: ToastService) {
        super('aanwijzing');
    }

    public onInit(): void {
        this.buildFormGroup();
        this.setFormGroup();
    }

    public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            participantRole: [null],
            participantList: [null],
            department: [null],
            vendor: [null]
        });
    }

    public setFormGroup(): void {
        this.departmentOptionList.setRequestValues(this.departmentList);
        this.vendorOptionList.setRequestValues(this.vendorList);
        this.participantRoleOptionList.setRequestValues(this.participantRoleList.filter(role => role.code !== this.global.appConstant.pm.PARTICIPANT_ROLE_OTHERS));

        const existParticipantIdList = this.existAanwijzingParticipantList.map(p => p.id);
        const partList = this.aanwijzingParticipantList.filter(p => !existParticipantIdList.includes(p.id));

        this.participantOptionList.setRequestValues(partList);

        this.formGroup.patchValue({
            participantList: partList
        });

        this.setStateReady();
    }

    public doAddNewParticipant(): void {
        const participantRoleList = this.participantRoleList.filter(prole => prole.code !== this.global.appConstant.pm.PARTICIPANT_ROLE_INTERNAL);
        const vendorList = this.vendorList;
        const participantList = this.participantOptionList.getRequestValues();
        this.appPopupService.open(AanwijzingPopupInviteParticipantNewComponent,
            {participantRoleList, vendorList, participantList}).subscribe((participant: AanwijzingParticipant) => {
                this.log.debug(participant);
                participant.companyName = participant.vendor?.name;
                participant.aanwijzing = this.aanwijzing;

                let participantList: AanwijzingParticipant[] = this.formGroup.value.participantList as Array<AanwijzingParticipant>;
                participantList = !participantList ? [] : participantList;
                participantList.unshift(participant);

                this.aanwijzingParticipantList.unshift(participant);

                this.participantOptionList.setRequestValues(this.aanwijzingParticipantList);

                this.formGroup.patchValue({
                    participantList
                });

                this.log.debug(this.formGroup.value);
            });
    }

    public doInvite(): void {
        const aanwijzingParticipantList: AanwijzingParticipant[] = this.formGroup.value.participantList;
        if (aanwijzingParticipantList && aanwijzingParticipantList.length > 0) {

            const title = 'aanwijzing.confirm.invite.title';
            const buttonLeft = 'app.button.cancel';
            const buttonRight = 'aanwijzing.button.invite';

            this.global.modalService
                .confirmation(
                    null,
                    title,
                    buttonLeft,
                    buttonRight,
                    null,
                    true
                )
                .pipe(take(1)).subscribe(result => {
                    if (result) {
                        aanwijzingParticipantList.forEach(apart => {
                            apart.id = null;
                            apart.aanwijzing = this.aanwijzing;
                        });

                        this.setStateProcessing();
                        this.httpClientService.post<AanwijzingParticipant[]>(
                            '/aanwijzing/invite', aanwijzingParticipantList)
                            .subscribe((response: Response<AanwijzingParticipant[]>) => {
                                this.log.debug(response);
                                this.setStateReady();
                                this.snackBarService.showWarning(this.global.translateService.instant('aanwijzing.alert.msg.invitationSent'));
                                setTimeout(() => {
                                    this.onChange.emit(response.body);
                                }, 1000);

                            });
                    }
                });

        } else {
            this.toastService.showError(this.global.translateService.instant('aanwijzing.alert.msg.pleaseChooseParticipant'));
        }

    }

    public doReset(): void {
        this.formGroup.reset();
        this.participantOptionList.setRequestValues(this.aanwijzingParticipantList);
    }

    public doApply(): void {
        const role: ParticipantRole = this.formGroup.value.participantRole;
        const vendor: Vendor = this.formGroup.value.vendor;
        const department: Organization = this.formGroup.value.department;

        let list: AanwijzingParticipant[] = [];
        if (role && vendor) {
            list = this.aanwijzingParticipantList.filter(apart => apart.participantRole.id === role.id
                && (apart.vendorPIC?.vendor.id === vendor.id || apart['vendor']?.id === vendor.id ));

        } else if (role && department) {
            list = this.aanwijzingParticipantList.filter(apart => apart.participantRole.id === role.id
                && (apart.user?.organization?.id === department.id ));

        } else if (role) {
            list = this.aanwijzingParticipantList.filter(apart => apart.participantRole.id === role.id);
        } else {
            list = this.aanwijzingParticipantList;
        }
        this.participantOptionList.setRequestValues(list);
    }

    public getTranslateKey(data): string {
        if (data?.translationKey) {
            return data.translationKey.module.code.toLowerCase() + '.' + data.translationKey.key;
        } else {
            return data?.name;
        }
    }

}
