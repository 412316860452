import { Currency } from 'src/app/core/bean/currency';
import { BaseDTO } from '../../../core/base/base-dto';
import { ResponseRfiQuestion } from '../../../core/bean/response-rfi-question';
import { Rfi } from '../../../core/bean/rfi';
import { RfiItem } from '../../../core/bean/rfi-item';
import { RfiQuestion } from '../../../core/bean/rfi-question';
import { RfiVendor } from '../../../core/bean/rfi-vendor';
import { VendorAddress } from '../../../core/bean/vendor-address';

export class ResponseRfiDTO extends BaseDTO {
    rfiItemList: RfiItem[];
    rfiQuestionList: RfiQuestion[];
    responseRfiList: ResponseRfiQuestion[];
    answer: string[];
    rfi: Rfi;
    rfiVendor: RfiVendor = new RfiVendor();
    vendorAddress: VendorAddress;
    responseRfi: ResponseRfiQuestion;
    currencyList: Currency[] = [];
}


