import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { ReportService } from 'src/app/core/services/report.service';

@Component({
  templateUrl: './app-popup-print-contract-request-info.component.html'
})
export class AppPopupPrintContractRequestInfoComponent extends BaseComponentComponent {
  @Input() contractId: number;
  public tableResponse: TableResponseModel<Object>;
  public accordionItemList = [];
  public accordionItemListTemp = new Array();
  public isPrint = false;

  constructor(
    public reportService: ReportService,
    public translateService: TranslateService
  ) {
    super('app-contract-request-info');
  }

  public onInit(): void {
    this.setAccoridionItemList();
    this.buildTableResponse();
    this.setTableResponse();
  }

  public setAccoridionItemList(): void {
    const accordionList = [
      {
        name: this.translateService.instant(
          'app-contract-request-info.accordion.procurementInformation'
        ),
        moduleCode: 'APP-PROCUREMENT-INFORMATION-VIEW'
      },
      {
        name: this.translateService.instant(
          'app-contract-request-info.accordion.contractInformation'
        ),
        moduleCode: 'APP-CONTRACT-INFORMATION-VIEW'
      },
      {
        name: this.translateService.instant(
          'app-contract-request-info.accordion.activityHistory'
        ),
        moduleCode: 'APP-ACTIVITY-HISTORY-VIEW'
      }
    ];
    this.accordionItemList = accordionList;
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.coloumn.listContractHistory' }
    ]);
  }

  public setTableResponse(): void {
    this.tableResponse.page.records = this.accordionItemList;
    this.tableResponse.page.totalRecords = this.accordionItemList.length;
    this.tableResponse.reloadClient();
  }

  public doCheckAll(event: any): void {
    if (event.target.checked) {
      this.accordionItemListTemp.splice(0);
      this.accordionItemListTemp.push(...this.accordionItemList);
    } else {
      this.accordionItemListTemp.splice(0);
    }
  }

  public doCheck(accordionItem: any): void {
    const indexOfAccordionItem: number =
      this.accordionItemListTemp.indexOf(accordionItem);
    indexOfAccordionItem === -1
      ? this.accordionItemListTemp.push(accordionItem)
      : this.accordionItemListTemp.splice(indexOfAccordionItem, 1);
  }

  public doPrint(): void {
    this.isPrint = true;
    if (this.accordionItemListTemp.length > 0) {
      this.loadingBlockService.showInfo(
        this.translateService.instant(
          'app-contract-request-info.info.preparingData'
        )
      );
      const tabList = this.accordionItemListTemp.map(
        (accordionItem: any) => accordionItem.moduleCode
      );
      const moduleCodeList = tabList.toString();
      this.reportService
        .print('/app-contract-request-info/print', {
          moduleCodeList,
          contractId: this.contractId
        })
        .subscribe(() => {
          this.loadingBlockService.showCompleteAndClose(
            this.translateService.instant(
              'app-contract-request-info.info.successImported'
            )
          );
        });
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
