import { NgModule } from '@angular/core';
import { AppBudgetFilterModule } from '../../components/app-budget-filter/app-budget-filter.module';
import { AppOrganizationRKInfoModule } from '../../components/app-organization-rk-info/app-organization-rk-info.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetWorkPlanSpendComponent } from './app-widget-work-plan-spend.component';
@NgModule({
  imports: [
    VendorModule,
    AppOrganizationRKInfoModule,
    AppBudgetFilterModule,
    AppWidgetModule
  ],
  declarations: [AppWidgetWorkPlanSpendComponent],
  entryComponents: [AppWidgetWorkPlanSpendComponent]
})
export class AppWidgetWorkPlanSpendModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      'app-widget-work-plan-spend',
      AppWidgetWorkPlanSpendComponent
    );
  }
}
