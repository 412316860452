<app-popup
  header="app-popup-choose-vendor-multi-winner.popup.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <div class="popup-choose-vendor">
    <app-alert-x color="DANGER" *ngIf="isMustFullFill">{{
      'vendor-reference.detail.alertInfo.mustBeFullfilled' | translate
    }}</app-alert-x>
    <app-alert-x
      color="WARNING"
      *ngIf="!isMustFullFill && isThingsToConsider"
      >{{
        'vendor-reference.detail.alertInfo.thingsToConsider' | translate
      }}</app-alert-x
    >
    <app-table-xx
      *ngIf="!formLoading"
      [model]="tableResponse"
      [isShowCheckBox]="true"
      [isMultipleSelect]="false"
      (onChange)="doOnChange()"
    >
    </app-table-xx>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          class="mr-1"
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button (onClick)="doEmitData()">{{
          'vendor-reference.button.saveChange' | translate
        }}</app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
