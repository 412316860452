import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorExpiredDocumentDetailComponent } from './vendor-expired-document-detail.component';
import { VendorExpiredDocumentComponent } from './vendor-expired-document.component';
export const routes = [
    { path: '', component: VendorExpiredDocumentComponent, data: { breadcrumb: '' } },
    { path: 'detail', component: VendorExpiredDocumentDetailComponent, data: { breadcrumb: 'vendor-expired-document.breadcrumb.detail' } }
];
@NgModule({
    imports: [SharedModule, RouterModule.forChild(routes)],
    declarations: [VendorExpiredDocumentComponent, VendorExpiredDocumentDetailComponent],
    providers: []
})

export class VendorExpiredDocumentModule { }
