import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';
import { AppPopupPreviewImageComponent } from '../components/app-popup/app-popup-preview-image/app-popup-preview-image.component';
import { AppPopupService } from '../components/app-popup/app-popup.service';
const selector = 'img';
@Directive({ selector })
export class ImgDirective implements OnInit {
  @Input() default: string;
  @Input() disabled: boolean;
  parentElement: any;
  constructor(
    public elementRef: ElementRef,
    public renderer2: Renderer2,
    private appPopupService: AppPopupService
  ) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.setAttribute('hidden', 'true');
  }

  @HostListener('load', ['$event'])
  handleLoad(): void {
    this.elementRef.nativeElement.removeAttribute('hidden');
  }

  @HostListener('error', ['$event'])
  handleError(event: any): void {
    this.elementRef.nativeElement.removeAttribute('hidden');
    event.target.src = this.default || 'assets/img/app/no-image.png';
  }

  @HostListener('click', ['$event'])
  onPreviewImage(event: PointerEvent): void {
    if (!this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      this.appPopupService.open(
        AppPopupPreviewImageComponent,
        {
          imageUrl: this.elementRef.nativeElement.src
        },
        { backdrop: true, windowClass: 'app-popup-preview-image' }
      );
    }
  }
}
