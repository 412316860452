<app-layout [backTo]="urlBackOutside" [isLoading]="formLoading">
    <ng-template #additionalTitle *ngIf="!formLoading">
      <app-badge class="cml-2" [color]="badgeColor[contractWorklistStatus.code]">
        {{
          contractWorklistStatus.translationKey.module.code.toLowerCase() +
            '.' +
            contractWorklistStatus.translationKey.key | translate
        }}
      </app-badge>
    </ng-template>
    <app-workflow-step-info
      [isLoading]="formLoading"
      [model]="response.workflowStepInfoModel"
      *ngIf="!formLoading && roleCode !== global.appConstant.ROLE_CODE_VENDOR"
    >
    </app-workflow-step-info>
    <app-contract-request-info [contractId]="contractId"></app-contract-request-info>
    <form [formGroup]="formGroup">
      <app-card
        *ngIf="!formLoading"
        header="contract-finalization.detail.card.contractFinalization.title"
        tips="contract-finalization.detail.card.contractFinalization.tips"
      >
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{
              'contract-finalization.detail.form.finalContract' | translate
            }}
          </label>
          <div class="col-sm-7 text-left">
            <div style="margin: 0 auto; display: flex; position: relative;
              border-radius: 5px;
              box-sizing: border-box;
              height: 78px;
              border: 1px solid var(--gray-400);">
              <div
                style="
                  width: 60px;
                  height: 60px;
                  overflow: hidden;
                  border-radius: 60px;
                  display: contents;
                "
                class="mr-3"
              >
                <img
                  [attr.src]="
                    '/assets/component/fileuploader/logo-icon/' +
                    model.docFile.extension.toLowerCase() +
                    '.png'
                  "
                  [attr.alt]="model.docFile.fileName"
                  [default]="global.appConstant.core.URL_DEFAULT_ICON"
                  class="img-thumbnail"
                  style="width: 60px; height: 60px; border: none"
                />
              </div>
    
              <div style="flex: 1; padding: 0.25rem 1rem" class="d-inline-block">
                <a
                  class="item-content-name"
                  href="#"
                  (click)="
                    doDownload(
                      $event,
                      '/contract-draft/file/view/' +
                        model.docFile.uploadedFileName +
                        '/' +
                        model.docFile.fileType.code,
                      model.docFile.fileName
                    )
                  "
                  maxLine
                  [attr.title]="model.docFile.fileName"
                >
                  {{ model.docFile.fileName }} 
                </a>
                <ng-container>
                  <span class="d-inline-flex">
                    <strong class="mr-1">{{ model.version }}</strong>
                    <span class="item-content-size">
                      {{ model.docFile.size / 1000000 | number: '1.2-2' }} MB
                    </span>
                  </span>
                  <p class="text-secondary">
                    {{ 'app-popup-version-history.form.uploadedBy' | translate }}
                    {{ model.uploaderName }}
                    {{ 'app-popup-version-history.form.at' | translate }}
                    {{
                      model.lastModifiedDate
                        | date: global.appConstant.core.FORMAT_SHORT_DATE_AND_TIME
                    }}
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'contract-finalization.detail.form.documentVersion' | translate }}
          </label>
          <div class="col-sm-6 text-left">
            <label>Ver.&nbsp;{{ contractNegotiationHistory.sequence }}</label
            >&nbsp;
            <span
              class="hyperlink-text cursor-pointer"
              (click)="onClickVersionHistory()"
            >
              <strong>
                {{'contract-finalization.detail.from.viewVersionHistory' | translate }}
              </strong>
            </span>
          </div>
        </div>
        <div
          *ngIf="
            !formLoading &&
            !response.module.isNumberGenerator || !formLoading && formGroup.isView
          "
          class="form-group row text-sm-left"
        >
          <label class="col-sm-3">{{
            'contract-finalization.detail.form.contractNumber' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            <app-text-generated
              formControlName="contractNumber"
              maxLength="64"
              [moduleCode]="moduleCode"
              numberGeneratorCode="CONTRACT_NUMBER"
              autofocus="true"
            ></app-text-generated>
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'contract-finalization.detail.form.contractPeriod' | translate }}
          </label>
          <div class="col-sm-6 text-left">
            <app-date-picker-x
              size="XL"
              formControlName="date"
              [range]="true"
              minDate="currentDate"
              maxDate="endDateValidate"
            >
            </app-date-picker-x>
          </div>
        </div>
      </app-card>
    </form>
    <app-card *ngIf="!formLoading" 
      header="contract-finalization.detail.card.parameter.title" 
      tips="contract-finalization.detail.card.parameter.tips"
    >
      <app-alert-x color="INFO" *ngIf="!formGroup.isView">
        {{ 'contract-finalization.parameter.info.alert' | translate }}
      </app-alert-x>
      <form
          class="form-horizontal"
          *ngIf="!formLoading"
          [formGroup]="formGroupParameter"
          novalidate
      >
        <div *ngIf="isParameterReady">
            <div class="removeSearchText app-table-search">
            <ng-container formArrayName="contractTemplateParamList">
                <div class="form-group row text-sm-right">
                <div
                    [ngStyle]="{
                    width: '100%'
                    }"
                >
                    <div>
                    <div
                        class="float-none w-100"
                        [ngStyle]="{ margin: '0 auto' }"
                    >
                        <table
                        class="table table-borderless"
                        aria-describedby="Table"
                        >
                        <tbody>
                            <tr
                            *ngFor="
                                let contractTemplateParam of contractTemplateParamList.controls;
                                let i = index
                            "
                            [formGroupName]="i"
                            >
                            <td
                                class="text-left col-sm-4"
                            >
                                {{
                                contractTemplateParam.controls.name
                                    .value
                                }}
                                <span
                                class="font-weight-bold text-danger"
                                *ngIf="
                                    (!formGroup.isView &&
                                    !contractTemplateParam.controls.code.value.includes(
                                        'PERUBAHAN'
                                    ) &&
                                    contractTemplateParam.controls.code
                                        .value !==
                                        global.appConstant.cm
                                        .CONTRACT_PARAMETER_NOMOR_KONTRAK) ||
                                    (!formGroup.isView &&
                                    contract &&
                                    contract.contractType.code !==
                                        global.appConstant.cm
                                        .CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                                    contractTemplateParam.controls.code
                                        .value ===
                                        global.appConstant.cm
                                        .CONTRACT_PARAMETER_NOMOR_KONTRAK)
                                "
                                >*
                                </span>
                            </td>
                            <td class="col-sm-8 text-left">
                                <div
                                *ngIf="
                                    contractTemplateParam.controls
                                    .valueTypeCode.value ===
                                    global.appConstant.core
                                    .VALUE_TYPE_STRING
                                "
                                >
                                <app-text-field
                                    size="LG"
                                    formControlName="value"
                                    (onChange)="onChangeValue()"
                                ></app-text-field>
                                </div>
                                <div
                                *ngIf="
                                    contractTemplateParam.controls
                                    .valueTypeCode.value ===
                                    global.appConstant.core
                                    .VALUE_TYPE_NUMBER
                                "
                                >
                                <app-text-field
                                    size="LG"
                                    formControlName="value"
                                    type="integer"
                                    (onChange)="onChangeValue()"
                                ></app-text-field>
                                </div>
                                <div
                                *ngIf="
                                    contractTemplateParam.controls
                                    .valueTypeCode.value ===
                                    global.appConstant.core
                                        .VALUE_TYPE_DATE_FORMAT_SHORT ||
                                    contractTemplateParam.controls
                                    .valueTypeCode.value ===
                                    global.appConstant.core
                                        .VALUE_TYPE_DATE_FORMAT_LONG
                                "
                                >
                                <app-date-picker-x
                                    size="LG"
                                    formControlName="value"
                                    (onChange)="onChangeValue()"
                                >
                                </app-date-picker-x>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </ng-container>
            </div>
        </div>
      </form>
    </app-card>
    <app-vendor-information
      *ngIf="!formLoading && roleCode !== global.appConstant.ROLE_CODE_VENDOR"
      [vendorId]="contract.vendor.id"
    ></app-vendor-information>
  
    <app-approval-monitoring
      *ngIf="
        !formLoading &&
        roleCode !== global.appConstant.ROLE_CODE_VENDOR &&
        response?.isProcessed &&
        ((todo === 'view' &&
        response &&
        response.approvalPathResponseModel &&
          !response.isUserApproval) ||
          contractWorklistStatus?.code ===
            global.appConstant.cm.CONTRACT_WORKLIST_STATUS_REVISION)
      "
      [workflowPrcs]="contract?.workflowPrcs"
      [moduleCode]="moduleCode"
      [approvalPathResponseModel]="
      response.approvalPathResponseModel
      "
    >
    </app-approval-monitoring>
    <app-approval-prcs-x
      *ngIf="!formLoading && response.isUserApproval"
      [workflowModelPrcs]="contract?.workflowModelPrcs"
      [approvalModelPrcsId]="approvalModelPrcsId"
    >
    </app-approval-prcs-x>
  
    <div
      class="floating-button mt-5"
      *ngIf="!formLoading && todo !== 'view' && !formGroup.isView"
    >
      <div class="floating-button-wrapper">
        <div class="floating-button-content">
          <div class="button-group button-group-center">
            <app-button
              (onClick)="doSubmit()"
              [disabled]="formSaving"
              *ngIf="!formGroup.isView"
              >{{ 'app.button.submit' | translate }}</app-button
            >
          </div>
        </div>
      </div>
    </div>
  
    <ng-template #contentSidebarRight>
      <app-tips
        *ngIf="!formLoading"
      ></app-tips>
    </ng-template>
  </app-layout>
  