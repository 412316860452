import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './review-contract.component.html'
})
export class ReviewContractComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Contract>;
  public filter: string;
  constructor(translateService: TranslateService) {
    super('review-contract', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.doSetCustomData();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'number',
        header: 'table.column.contractNumber',
        customClass: 'text-center'
      },
      {
        field: 'procurementVendor.procurement.name',
        header: 'table.column.procurementName'
      },
      {
        field: 'organization.name',
        header: 'table.column.organization',
        customClass: 'text-center'
      },
      {
        field: 'procurementVendor.vendor.name',
        header: 'table.column.vendorName',
        customClass: 'white-space-normal'
      },
      {
        field: 'contractType.name',
        header: 'table.column.contractType',
        customClass: 'text-center'
      },
      {
        field: 'dealingAgreementType.name',
        header: 'table.column.dealingAgreementType',
        customClass: 'text-center'
      },
      {
        field: 'requestNumber',
        header: 'table.column.requestNumber',
        customClass: 'text-center'
      },
      {
        field: 'createdDate',
        header: 'table.column.requestDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'contractStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      },
      {
        field: 'stage',
        header: 'table.column.stage',
        customClass: 'text-center'
      }
    ]);
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customFilter = {
        contractStatusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customFilter);
      this.tableResponse.reload();
    }
  }

  public doEdit(contract: Contract): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('contractId', contract.id);
    this.global.routerParams.set('urlBackOutside', '/pages/review-contract');
    this.router.navigate(['/pages/review-contract/edit']);
  }
}
