<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="watermark-report.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <div class="table-responsive custom-table">
        <table
          class="table table-striped"
          [formGroup]="formGroup"
          aria-describedby="Table"
        >
          <thead>
            <tr>
              <th
                scope="col"
                [ngStyle]="{ width: '20%' }"
                class="text-center"
                >{{ 'watermark-report.table.column.name' | translate }}</th
              >
              <th
                scope="col"
                [ngStyle]="{ width: '50%' }"
                class="text-center"
                >{{ 'watermark-report.table.column.upload' | translate }}</th
              >
            </tr>
          </thead>
          <tbody formArrayName="resourceList">
            <tr
              [formGroupName]="i"
              *ngFor="
                let resource of formArrayResourceList.controls;
                let i = index
              "
            >
              <td>{{
                resource.value !== null && resource.value.name !== null
                  ? resource.value.name
                  : '-'
              }}</td>
              <td>
                <app-upload
                  size="LG"
                  formControlName="file"
                  [fileUploader]="fileUploaderList[i]"
                >
                </app-upload>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="button-group button-group-center mt-5">
        <app-button
          color="SECONDARY"
          (onClick)="doCancel()"
          [disabled]="formSaving"
        >
          {{ 'app.button.back' | translate }}
        </app-button>
        <app-button (onClick)="doSave()" [disabled]="formSaving">
          {{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
