<div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-card header="distribution-region.title" [isLoading]="formLoading"
        [isSaving]="formSaving">
        <app-table [model]="tableResponse" [isServerSide]="true"
          stringUrl="/distribution-region/index">
          <ng-template #headerButtons>
            <app-button (onClick)="doAdd()"><em class="fas fa-plus"></em>{{
              'app.button.add' | translate }}</app-button>
          </ng-template>
          <ng-template #actionButtons let-rowData>
            <app-button size="TN" (onClick)="doEdit(rowData)"
              title="{{ 'app.tooltip.edit' | translate }}">
              <em class="fas fa-pencil-alt"></em>
            </app-button>
            <app-button size="TN" color="DANGER" (onClick)="doDelete(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}">
              <em class="fa fas fa-trash"></em>
            </app-button>
          </ng-template>
        </app-table>
      </app-card>
    </div>
  </div>