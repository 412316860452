import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AddressBook } from 'src/app/core/bean/address-book';
import { Contract } from 'src/app/core/bean/contract';
import { ContractDetail } from 'src/app/core/bean/contract-detail';
import { ContractPreparation } from 'src/app/core/bean/contract-preparation';
import { ContractShipping } from 'src/app/core/bean/contract-shipping';
import { ContractShippingDetail } from 'src/app/core/bean/contract-shipping-detail';
import { ContractType } from 'src/app/core/bean/contract-type';
import { DealingAgreementType } from 'src/app/core/bean/dealing-agreement-type';
import { Fines } from 'src/app/core/bean/fines';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { ItemType } from 'src/app/core/bean/item-type';
import { PrItemReleased } from 'src/app/core/bean/pr-item-released';
import { Sow } from 'src/app/core/bean/sow';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { AppPopupAddressService } from 'src/app/core/components/app-popup/app-popup-address/app-popup-address.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { AddressPic } from '../../core/bean/address-pic';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { AppPopupContractRequestCancelContractComponent } from './app-popup-contract-request-cancel-contract.component';
import { AppPopupContractRequestChooseContractComponent } from './app-popup-contract-request-choose-contract.component';
import { AppPopupContractRequestChooseProcurementForContractComponent } from './app-popup-contract-request-choose-procurement-for-contract.component';
import { AppPopupContractRequestSplitItemComponent } from './app-popup-contract-request-split-item.component';
import { ContractRequestXRequest } from './contract-request-x.request';
import { ContractRequestXResponse } from './contract-request-x.response';

@Component({
  templateUrl: './contract-request-edit-add-x.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ContractRequestEditAddXComponent
  extends BaseModuleComponent
  implements OnInit
{
  public token: string;
  public contractId: number;
  public objectId: number;
  public contract: Contract;
  public approvalModelPrcsId: number;
  public contractTypeOptionList: OptionListModel<ContractType> =
    new OptionListModel(true);
  public dealingAgreementTypeOptionList: OptionListModel<DealingAgreementType> =
    new OptionListModel(true);
  public response: ContractRequestXResponse = new ContractRequestXResponse();
  public today = new Date();
  public contractShippingOptionList: OptionListModel<ContractShipping> =
    new OptionListModel(true, 'address.address');
  public formGroupCanceled: FormGroup = new FormGroup({});
  public isBlanketOrder: boolean;
  public fileUploader: FileUploader = new FileUploader(
    '/contract-request/',
    'contract-request.title',
    this.global.appConstant.fileType.DOC_CONTRACT_CANCELED
  );

  public badgeColor = {
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    ON_PROGRESS: 'PROGRESS',
    REQUEST_ON_PROGRESS: 'PROGRESS',
    DONE: 'SUCCESS',
    TERMINATED: 'DANGER',
    CLOSED: 'DANGER',
    CANCELED: 'DANGER'
  };

  public statusForApprovalList = [
    this.global.appConstant.cm.CONTRACT_STATUS_WAITING_APPROVAL,
    this.global.appConstant.cm.CONTRACT_STATUS_WAITING_CANCEL_APPROVAL,
    this.global.appConstant.cm.CONTRACT_STATUS_ON_PROGRESS,
    this.global.appConstant.cm.CONTRACT_STATUS_REQUEST_ON_PROGRESS,
    this.global.appConstant.cm.CONTRACT_STATUS_REVISION,
    this.global.appConstant.cm.CONTRACT_STATUS_DONE,
    this.global.appConstant.cm.CONTRACT_STATUS_TERMINATED,
    this.global.appConstant.cm.CONTRACT_STATUS_CANCEL,
    this.global.appConstant.cm.CONTRACT_STATUS_CLOSED
  ];

  public contractPreparation: ContractPreparation;
  public amount: number;
  public goodsAmount: number;
  public serviceAmount: number;
  public tableResponseVendor: TableResponseModel<Vendor>;
  public sowList: Sow[] = [];
  public finesList: Fines[] = [];
  public guaranteeList: Guarantee[] = [];
  public prItemReleasedList: PrItemReleased[] = [];
  public itemTypeList: ItemType[] = [];
  public isCanceled: boolean;
  public contractShippingDetailList: ContractShippingDetail[] = [];
  public isRevisions: boolean;
  public request: ContractRequestXRequest = new ContractRequestXRequest();
  public isAppSowReady = false;
  public isSourceReady = false;
  public isSourceLoading = true;
  public dealingAgreementTypeOld: DealingAgreementType;
  public vendorId: number;
  public isFines: boolean;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupAddressService: AppPopupAddressService,
    public toastService: ToastService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute
  ) {
    super('contract-request', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Contract>('/contract-request/notification/' + this.token)
      .subscribe((contract: Contract) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('contractId', contract.id);
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/contract-request'
        );
        this.global.routerParams.set('todo', 'view');
        this.global.routerParams.set('isCanceled', contract.isCanceled);
        this.router.navigate(['/pages/contract-request/detail']);
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.buildTableResponseVendor();
    this.setFormGroup();
    this.setIsViewOnlyFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.contractId = this.global.routerParams.get('contractId');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.contractId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
      this.formGroup.setIsView(true);
      this.formGroupCanceled.setIsView(true);
    } else if (this.todo === 'cancel') {
      this.setViewOnly();
      this.formGroup.setIsView(true);
      this.formGroupCanceled.setIsView(true);
    }
  }

  public buildTableResponseVendor(): void {
    this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.vendorName'
      },
      {
        field: 'email',
        header: 'table.column.email'
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'pic',
        header: 'table.column.pic'
      }
    ]);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      contractType: [null, Validators.required()],
      requestNumber: [null],
      requestDate: [this.today, Validators.required()],
      dealingAgreementType: [null, Validators.required()],
      description: [null, Validators.required()],
      goodsAmount: [null],
      contractStatus: [null],
      serviceAmount: [null],
      amount: [null],
      contractShipping: [null],
      contractShippingList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ContractRequestXResponse>(
        '/contract-request/add-edit-x',
        new RouteRequestModel(
          this.todo,
          this.objectId ? this.objectId : this.contractId
        )
      )
      .subscribe((response: ContractRequestXResponse) => {
        this.response = response;
        this.contractTypeOptionList.setRequestValues(response.contractTypeList);
        this.contractShippingOptionList.setRequestValues([]);
        this.itemTypeList = response.itemTypeList;
        if (response.contract) {
          const {
            id,
            code,
            title,
            contractType,
            dealingAgreementType,
            contractStatus,
            requestNumber,
            requestDate,
            description,
            goodsAmount,
            serviceAmount,
            amount
          } = response.contract;

          this.formGroup.patchValue({
            id,
            code,
            title,
            contractType,
            dealingAgreementType,
            contractStatus,
            requestNumber,
            requestDate: new Date(requestDate),
            description,
            goodsAmount,
            serviceAmount,
            amount,
            contractShippingList: response.contractShippingList
          });
          this.contract = response.contract;
          response.contract.vendor.phone =
            response.contract.vendor.phoneRegion?.callingCode +
            response.contract.vendor.phone;
          response.contract.vendor['address'] = response.vendorAddress.address;
          response.contract.vendor['pic'] = response.vendorPic?.name;
          this.tableResponseVendor.setRecordList([response.contract.vendor]);
          this.tableResponseVendor.reload();
          this.contractPreparation = response.contract.contractPreparation;

          /** contract detail, contract ship, contract ship detail */
          this.prItemReleasedList = response.contractDetailList.map(
            detail => detail.prItemReleased
          );

          this.contractShippingOptionList.setRequestValues(
            response.contractShippingList
          );
          this.contractShippingDetailList =
            this.response.contractShippingDetailList;
          this.sowList = response.sowList;
          this.finesList = response.finesList;
          this.guaranteeList = response.guaranteeList;
          this.amount = response.contract.amount;
          this.isBlanketOrder = response.isBlanketOrder;

          if (
            response.contract.contractStatus.code ===
            this.global.appConstant.cm.CONTRACT_STATUS_CANCEL
          ) {
            this.isCanceled = true;
            this.formGroupCanceled = this.formBuilder.group({
              id: [null],
              fileDoc: [null],
              cancelDate: [null],
              stage: [null],
              note: [null]
            });
            if (this.response.contractCancelationDoc !== null) {
              this.fileUploader.setFile(
                this.response.contractCancelationDoc.file
              );
              this.formGroupCanceled.patchValue({
                id: this.response.contractCancelation.id,
                fileDoc: this.fileUploader.fileObjectList,
                cancelDate: this.response.contractCancelation.cancelDate,
                stage: this.response.contractCancelation.module
                  ? this.response.contractCancelation.module.name
                  : null,
                note: this.response.contractCancelation.note
              });
            }
            this.formGroupCanceled.setIsView(true);
          }
          this.isAppSowReady = true;
        }
        this.isSourceLoading = false;
        this.setStateReady();
        if (this.prItemReleasedList.length > 0) {
          this.isSourceReady = true;
        }
      });
  }

  public doChangeContractType(): void {
    this.isSourceLoading = true;
    if (this.contractPreparation) {
      this.global.modalService
        .confirm(
          this.translateService.instant('contract-request.confirmation.body'),
          this.translateService.instant('contract-request.confirmation.header')
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.sowList = [];
            this.finesList = [];
            this.guaranteeList = [];
            this.prItemReleasedList = [];
            this.tableResponseVendor
              .getRecordList()
              .splice(0, this.tableResponseVendor.getRecordList().length);
            this.tableResponseVendor.reload();
            this.contractShippingOptionList.reset();
            this.formGroup.patchValue({
              contractShipping: null
            });
            this.amount = null;
            this.vendorId = null;
            this.isSourceReady = false;
            this.contractPreparation = null;
            this.formGroup.get('dealingAgreementType').reset();
          } else {
            this.formGroup.patchValue({
              contractType:
                this.formGroup.value.dealingAgreementType.contractType
            });
          }
        });
    } else {
      this.formGroup.get('dealingAgreementType').reset();
    }
    const contractType = this.formGroup.value.contractType;
    if (contractType) {
      this.dealingAgreementTypeOptionList.reset();
      this.httpClientService
        .get<DealingAgreementType[]>(
          '/contract-request/change-dealing-agreement-type-list/' +
            contractType.id
        )
        .subscribe(dealingAgrementTypeList => {
          this.dealingAgreementTypeOptionList.setRequestValues(
            dealingAgrementTypeList
          );
        });
    } else {
      this.dealingAgreementTypeOptionList.reset();
    }
    this.isSourceLoading = false;
  }

  public doOnChangeDealingAgreementType(): void {
    this.isSourceLoading = true;
    if (this.contractPreparation) {
      this.global.modalService
        .confirm(
          this.translateService.instant('contract-request.confirmation.body'),
          this.translateService.instant('contract-request.confirmation.header')
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.sowList = [];
            this.finesList = [];
            this.guaranteeList = [];
            this.prItemReleasedList = [];
            this.tableResponseVendor
              .getRecordList()
              .splice(0, this.tableResponseVendor.getRecordList().length);
            this.tableResponseVendor.reload();
            this.contractShippingOptionList.reset();
            this.amount = null;
            this.vendorId = null;
            this.isSourceReady = false;
            this.formGroup.get('contractShipping').reset();
          } else {
            this.formGroup.patchValue({
              dealingAgreementType: this.dealingAgreementTypeOld
            });
            this.dealingAgreementTypeOld =
              this.formGroup.value.dealingAgreementType;
          }
        });
    }
    this.isSourceLoading = false;
    this.dealingAgreementTypeOld = this.formGroup.value.dealingAgreementType;
  }

  public doChooseProcurement(): void {
    const contractPreparation = this.contractPreparation;
    const contractPreparationOld = this.contractPreparation;
    this.appPopupService
      .open(
        AppPopupContractRequestChooseProcurementForContractComponent,
        {
          contractPreparation
        },
        { size: 'xl' }
      )
      .subscribe(contractPreparation => {
        this.isSourceLoading = true;
        if (
          contractPreparationOld &&
          contractPreparationOld.id !== contractPreparation.id
        ) {
          this.sowList = [];
          this.finesList = [];
          this.guaranteeList = [];
          this.prItemReleasedList = [];
          this.tableResponseVendor
            .getRecordList()
            .splice(0, this.tableResponseVendor.getRecordList().length);
          this.tableResponseVendor.reload();
          this.contractShippingOptionList.reset();
          this.amount = null;
          this.vendorId = null;
          this.isSourceReady = false;
          this.formGroup.get('contractShipping').reset();
        }
        this.contractPreparation = contractPreparation;
        if (this.contractPreparation) {
          this.amount = this.contractPreparation.amount;
          this.vendorId = this.contractPreparation.vendor.id;
          const cPrep: ContractPreparation = this.contractPreparation;
          const vendor: Vendor = this.contractPreparation.vendor;
          this.httpClientService
            .get<ContractRequestXResponse>(
              '/contract-request/get-procurement-result-data/' + cPrep.id,
              {}
            )
            .subscribe((response: ContractRequestXResponse) => {
              this.response = response;
              vendor['address'] = response.vendorAddress.address;
              vendor['pic'] = response.vendorPic?.name;
              this.tableResponseVendor.setRecordList([vendor]); // 1 vendor saja
              this.tableResponseVendor.reload();
              this.prItemReleasedList =
                response.contractPreparationDetailList.map(
                  cpd => cpd.prItemReleased
                );
              if (this.contractPreparation.source === 'ORDER') {
                this.sowList = response.sowList;
                this.finesList = response.finesList;
                this.guaranteeList = response.guaranteeList;
              }
              this.contractShippingOptionList.setRequestValues(
                response.contractShippingList
              );
              this.contractShippingDetailList =
                response.contractShippingDetailList;
              this.isBlanketOrder = response.isBlanketOrder;

              this.isSourceReady = true;
              this.isSourceLoading = false;
            });
        }
      });
  }

  public doChooseContract(): void {
    const contract = this.formGroup.value;
    const contractOld = this.formGroup.value;
    const contractId = this.contractId || null;
    this.appPopupService
      .open(
        AppPopupContractRequestChooseContractComponent,
        {
          contract,
          contractId
        },
        { size: 'xl' }
      )
      .subscribe(contract => {
        this.isSourceLoading = true;
        if (contractOld && contractOld.id !== contract.id) {
          this.sowList = [];
          this.finesList = [];
          this.guaranteeList = [];
          this.prItemReleasedList = [];
          this.tableResponseVendor
            .getRecordList()
            .splice(0, this.tableResponseVendor.getRecordList().length);
          this.tableResponseVendor.reload();
          this.contractShippingOptionList.reset();
          this.amount = null;
          this.vendorId = null;
          this.isSourceReady = false;
          this.formGroup.get('contractShipping').reset();
        }
        this.formGroup.patchValue({
          id: contract.id,
          code: contract.code,
          title: contract.title,
          contractType: contract.contractType,
          dealingAgreementType: contract.dealingAgreementType,
          contractStatus: contract.contractStatus,
          requestNumber: contract.requestNumber,
          requestDate: new Date(),
          description: contract.description,
          goodsAmount: contract.goodsAmount,
          serviceAmount: contract.serviceAmount,
          amount: contract.amount
        });
        this.contractId = contract.id;
        this.isSourceReady = true;
        this.isSourceLoading = false;
      });
  }

  public doCancel(): void {
    const contract = this.contract;
    this.appPopupService
      .open(
        AppPopupContractRequestCancelContractComponent,
        {
          contract
        },
        { size: 'lg' }
      )
      .subscribe();
  }

  public onChangeItemRequest(event): void {
    this.log.debug(event);
    const delContractShipDetailList = [];
    if (
      this.contractShippingDetailList &&
      this.contractShippingDetailList.length > 0
    ) {
      delContractShipDetailList.push(
        ...this.contractShippingDetailList.filter(csd => {
          return !event.prItemReleasedList
            .map(prItemRel => prItemRel.id)
            .includes(csd.contractDetail.prItemReleased.id);
        })
      );
    }

    this.prItemReleasedList = event.prItemReleasedList;

    if (this.prItemReleasedList && this.prItemReleasedList.length > 0) {
      const vendor: Vendor = { ...event.prItemReleasedList[0].vendor };
      this.httpClientService
        .get<VendorAddress>('/order/get-vendor-address/' + vendor.id, {})
        .subscribe((vendorAddress: VendorAddress) => {
          vendor['address'] = vendorAddress.address;
          this.tableResponseVendor.setRecordList([vendor]); // 1 vendor saja
          this.tableResponseVendor.reload();
        });
    } else {
      this.tableResponseVendor
        .getRecordList()
        .splice(0, this.tableResponseVendor.getRecordList().length);
      this.tableResponseVendor.reload();
    }

    this.amount = event.amountValue.amount;
    this.goodsAmount = event.amountValue.goodsAmount;
    this.serviceAmount = event.amountValue.serviceAmount;

    // if (this.response.contractDetailList && this.response.contractDetailList.length > 0) {
    //   this.response.contractDetailList
    //     .filter(cDetail => {
    //       return !this.prItemReleasedList
    //         .map(prItemRel => prItemRel.id)
    //         .includes(cDetail.prItemReleased.id);
    //     })
    //     .forEach(deleteContractDetail => {
    //       if (
    //         deleteContractDetail.id &&
    //         !this.orderRequest.deleteOrderItemList.find(
    //           del => del.id === deleteContractDetail.id
    //         )
    //       ) {
    //         deleteContractDetail.crudOperation =
    //           this.global.appConstant.core.CRUD_OPERATION_DELETE;
    //         this.orderRequest.deleteOrderItemList.push(deleteContractDetail);
    //       }
    //     });
    // }

    if (
      this.contractShippingDetailList &&
      this.contractShippingDetailList.length > 0 &&
      delContractShipDetailList &&
      delContractShipDetailList.length > 0
    ) {
      delContractShipDetailList.forEach(delCsd => {
        const indexDelCsd = this.contractShippingDetailList.findIndex(
          csd =>
            csd.contractDetail.prItemReleased.id ===
              delCsd.contractDetail.prItemReleased.id &&
            csd.contractShipping.address.id ===
              delCsd.contractShipping.address.id
        );
        this.contractShippingDetailList.splice(indexDelCsd, 1);
      });
    }

    /** reset sowList */
    if (this.sowList && this.sowList.length > 0) {
      this.sowList.forEach(sow => {
        sow.crudOperation = this.global.appConstant.core.CRUD_OPERATION_DELETE;
        if (
          sow.id &&
          ((this.request.deleteSowList.length > 0 &&
            this.request.deleteSowList.filter(
              deletedSow => deletedSow.id && deletedSow.id === sow.id
            ).length === 0) ||
            this.request.deleteSowList.length === 0)
        ) {
          this.request.deleteSowList.push(sow);
        }
      });
    }
    this.sowList = [];
    this.isAppSowReady = false;
    setTimeout(() => {
      this.isAppSowReady = true;
    }, 50);
  }

  public doAddAddress(): void {
    const addressBookList = this.contractShippingOptionList.getRequestValues()
      ? (
          this.contractShippingOptionList.getRequestValues() as Array<ContractShipping>
        ).map(cship => cship.address)
      : [];

    this.appPopupAddressService
      .open(addressBookList, true)
      .subscribe((addrsList: AddressBook[]) => {
        const contractShippingList: ContractShipping[] = [];
        addrsList.forEach(addrs => {
          const contractShipping = new ContractShipping();
          contractShipping.address = addrs;
          contractShippingList.push(contractShipping);
        });

        this.contractShippingOptionList.setRequestValues(contractShippingList);

        this.formGroup.markAsDirty();
        /** reset quantity shippingitem */
      });
  }

  public doDeleteShipping(): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const contractShippingList = Array.from(
            this.formGroup.get('contractShipping').value
          );
          const contractShippingOptionList =
            this.contractShippingOptionList.getRequestValues();
          contractShippingList.forEach((contractShipping: ContractShipping) => {
            const index = contractShippingOptionList.findIndex(
              cship => cship.address.id === contractShipping.address.id
            );
            const cship = contractShippingOptionList[index];

            if (index !== -1) {
              contractShippingOptionList.splice(index, 1);
              if (
                this.contractShippingDetailList &&
                this.contractShippingDetailList.length > 0
              ) {
                const delOsiList = this.contractShippingDetailList.filter(
                  delCsd =>
                    delCsd.contractShipping.address.id ===
                    contractShipping.address.id
                );
                delOsiList.forEach(delCsd => {
                  const indexDelOsi = this.contractShippingDetailList.findIndex(
                    osi =>
                      osi.contractDetail.prItemReleased.id ===
                        delCsd.contractDetail.prItemReleased.id &&
                      osi.contractShipping.address.id ===
                        delCsd.contractShipping.address.id
                  );
                  this.contractShippingDetailList.splice(indexDelOsi, 1);
                });
              }

              if (
                cship.id &&
                !this.request.deleteContractShippingList.find(
                  del => del.id === cship.id
                )
              ) {
                cship.crudOperation =
                  this.global.appConstant.core.CRUD_OPERATION_DELETE;
                this.request.deleteContractShippingList.push(cship);
              }
            }
          });
          this.contractShippingOptionList.setRequestValues(
            contractShippingOptionList
          );
          this.formGroup.patchValue({
            contractShipping: null
          });
          this.formGroup.markAsDirty();
        }
      });
  }

  public doViewItem(contractShip: ContractShipping): void {
    const contractShippingDetailList = this.contractShippingDetailList.filter(
      csi => csi.contractShipping.id === contractShip.id
    );
    const address = contractShip.address;
    const isView = this.formGroup.isView;

    this.appPopupService.open(
      AppPopupContractRequestSplitItemComponent,
      {
        address,
        contractShippingDetailList,
        isView,
        allCShipItemList: this.contractShippingDetailList
      },
      { size: 'xl' }
    );
  }

  public doSplit(contractShip: ContractShipping): void {
    this.log.debug(contractShip);
    const address = contractShip.address;
    const contractShippingDetailList: ContractShippingDetail[] =
      this.contractShippingDetailList.filter(
        osi => osi.contractShipping.address.id === contractShip.address.id
      );

    /** kondisi jika revisi data dari response, kynya gak perlu krn sudah diatur saat processreq */
    this.prItemReleasedList.forEach(prItemRel => {
      if (
        (contractShippingDetailList &&
          contractShippingDetailList.length === 0) ||
        (contractShippingDetailList &&
          contractShippingDetailList.length > 0 &&
          !contractShippingDetailList.find(
            cshitem => cshitem.contractDetail.prItemReleased.id === prItemRel.id
          ))
      ) {
        const contractShippingDetail = new ContractShippingDetail();
        contractShippingDetail.contractShipping = contractShip;
        contractShippingDetail.contractDetail = new ContractDetail();
        contractShippingDetail.contractDetail.prItemReleased = prItemRel;

        contractShippingDetail.quantity =
          contractShippingDetail.contractDetail.prItemReleased.quantity > 0
            ? null
            : 0;
        contractShippingDetailList.push(contractShippingDetail);
      }
    });

    this.appPopupService
      .open(AppPopupContractRequestSplitItemComponent, {
        isView: this.todo === 'view',
        address,
        contractShippingDetailList,
        allCShipItemList: this.contractShippingDetailList
      })
      .subscribe((contractShippingDetailList: ContractShippingDetail[]) => {
        /** remove old oshipItem having same address */
        this.contractShippingDetailList =
          this.contractShippingDetailList.filter(
            cshipDetail =>
              cshipDetail.contractShipping.address.id !==
              contractShip.address.id
          );
        const delContractShippingItemList = contractShippingDetailList.filter(
          csi => csi.quantity === 0
        );

        this.contractShippingDetailList.push(...contractShippingDetailList);

        /** crud operation for update */
        this.contractShippingDetailList.forEach(cShippingDetail => {
          if (
            this.response.contractDetailList &&
            this.response.contractDetailList.length > 0
          ) {
            const existingContractDetail =
              this.response.contractDetailList.find(
                cd =>
                  cd.prItemReleased.id ===
                  cShippingDetail.contractDetail.prItemReleased.id
              );
            if (existingContractDetail) {
              /** existing */
              cShippingDetail.contractDetail.id = existingContractDetail.id;
              cShippingDetail.contractDetail.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              cShippingDetail.contractDetail.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          }

          if (
            this.response.contractShippingList &&
            this.response.contractShippingList.length > 0
          ) {
            const existingContractShip =
              this.response.contractShippingList.find(
                cship =>
                  cship.address.id ===
                  cShippingDetail.contractShipping.address.id
              );
            if (existingContractShip) {
              /** existing */
              cShippingDetail.contractShipping.id = existingContractShip.id;
              cShippingDetail.contractShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              cShippingDetail.contractShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          }
        });

        delContractShippingItemList.forEach(delCsd => {
          const index = this.contractShippingDetailList.findIndex(
            csd =>
              csd.contractDetail.prItemReleased.id ===
                delCsd.contractDetail.prItemReleased.id &&
              csd.contractShipping.address.id ===
                delCsd.contractShipping.address.id
          );
          this.contractShippingDetailList.splice(index, 1);
        });

        this.log.debug(this.contractShippingDetailList);
      });
  }

  public onChangeSowList(event): void {
    this.sowList = event;

    if (this.response.sowList && this.response.sowList.length > 0) {
      this.response.sowList
        .filter(sow => {
          return !this.sowList.map(sw => sw.id).includes(sow.id);
        })
        .forEach(deleteSow => {
          if (
            deleteSow.id &&
            !this.request.deleteSowList.find(del => del.id === deleteSow.id)
          ) {
            deleteSow.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_DELETE;
            this.request.deleteSowList.push(deleteSow);
          }
        });
    }
  }

  public onChangeFinesList(event): void {
    this.finesList = event;
  }

  public onChangeGuaranteeList(event): void {
    this.guaranteeList = event;
  }

  public goToVendorProfile(): void {
    const vendor: Vendor = this.tableResponseVendor.getRecordList()[0];
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorId', vendor.id);
    this.global.routerParams.set('urlBackOutside', 'close');
    localStorage.setItem('vendorId', vendor.id.toString());
    localStorage.setItem('urlBackOutside', 'close-window');
    localStorage.setItem('todo', 'view');
    window.open('/pages/company-profile');
  }

  public viewDetail(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('objectId', this.contractPreparation.objectId);
    this.global.routerParams.set('todo', 'view');
    localStorage.setItem(
      'objectId',
      this.contractPreparation.objectId.toString()
    );
    localStorage.setItem('todo', 'view');
    localStorage.setItem('urlBackOutside', 'close');
    localStorage.setItem('isShowBadgeStatus', false.toString());
    if (this.contractPreparation.source === 'ORDER') {
      this.global.routerParams.set(
        'orderId',
        this.contractPreparation.objectId
      );
      localStorage.setItem(
        'orderId',
        this.contractPreparation.objectId.toString()
      );
    } else if (this.contractPreparation.source === 'RFP') {
      this.global.routerParams.set('rfpId', this.contractPreparation.objectId);
      localStorage.setItem(
        'rfpId',
        this.contractPreparation.objectId.toString()
      );
    } else if (this.contractPreparation.source === 'RFQ') {
      this.global.routerParams.set('rfqId', this.contractPreparation.objectId);
      localStorage.setItem(
        'rfqId',
        this.contractPreparation.objectId.toString()
      );
    } else if (this.contractPreparation.source === 'PROCUREMENT') {
      this.global.routerParams.set(
        'procurementId',
        this.contractPreparation.objectId
      );
      localStorage.setItem(
        'procurementId',
        this.contractPreparation.objectId.toString()
      );
    }
    if (this.contractPreparation.module.code === 'ORDER') {
      window.open(
        '/pages/' + this.contractPreparation.module.code.toLowerCase() + '/edit'
      );
    } else if (this.contractPreparation.source === 'PROCUREMENT') {
      window.open('/pages/procurement-history/edit');
    } else {
      window.open(
        '/pages/' +
          this.contractPreparation.module.code.toLowerCase() +
          '/detail'
      );
    }
  }

  public doSaveDraft(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.setStateProcessing();

      const url =
        this.todo === 'edit'
          ? '/contract-request/updateX'
          : '/contract-request/insertX';
      this.httpClientService
        .post<Response<ContractRequestXResponse>>(
          url,
          this.getProcessedRequest(false)
        )
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.snackbarService.showWarning('app.alert.msg.saveSuccess');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('contractId', response.body.id);
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/contract-request'
                );
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/contract-request/edit']);
              });
          } else {
            this.toastService.showError(response.statusText);
          }
          this.setStateReady();
        });
    }
  }

  public doSubmit(): void {
    this.validate();
    this.isFines = true;
    const isItemAddressedList: boolean[] = [];
    this.prItemReleasedList.forEach(prItemRel => {
      if (
        prItemRel.quantity > 0 &&
        this.contractShippingOptionList.getRequestValues().length > 1
      ) {
        /** max quantity > 0 */
        let maxQuantity = 0;
        if (
          prItemRel.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        ) {
          maxQuantity = 100;
        } else {
          maxQuantity = prItemRel.quantity;
        }
        let totalUsedQuantity = 0;
        if (
          this.contractShippingDetailList &&
          this.contractShippingDetailList.length > 1
        ) {
          const contractShipDetailList = this.contractShippingDetailList.filter(
            csd => csd.contractDetail.prItemReleased.id === prItemRel.id
          );
          contractShipDetailList.forEach(cShipDetail => {
            totalUsedQuantity += cShipDetail.quantity;
          });
        }
        const remainingQuantity = maxQuantity - totalUsedQuantity;
        if (remainingQuantity === 0) {
          isItemAddressedList.push(true);
        } else {
          isItemAddressedList.push(false);
        }
      }
    });

    const indexItemAddressedFalse = isItemAddressedList.findIndex(
      isiadd => isiadd === false
    );

    const isFullyAddressed: boolean[] = [];
    if (this.contractShippingOptionList.getRequestValues().length > 1) {
      this.contractShippingOptionList.getRequestValues().forEach(cs => {
        const csdList = this.contractShippingDetailList.filter(
          csd => csd.contractShipping.address.id === cs.address.id
        );
        if (
          csdList &&
          csdList.length > 0 &&
          this.prItemReleasedList &&
          this.prItemReleasedList.length > 0
        ) {
          let totalQuantity = 0;
          this.prItemReleasedList.forEach(pri => {
            const csd = csdList.find(
              csd => csd.contractDetail.prItemReleased.id === pri.id
            );
            if (csd) {
              totalQuantity += csd.quantity;
            }
          });
          if (totalQuantity > 0) {
            isFullyAddressed.push(true);
          } else {
            isFullyAddressed.push(false);
          }
        } else {
          isFullyAddressed.push(false);
        }
      });
    }
    const indexFullAdd = isFullyAddressed.findIndex(isFull => isFull === false);

    if (
      this.formGroup.value.contractType.code ===
      this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
    ) {
      if (this.finesList.length === 0) {
        this.isFines = false;
      }
    }

    let remainingAmount: number;
    if (this.isBlanketOrder) {
      remainingAmount = this.sowList.length > 0 ? 0 : null;
    } else {
      remainingAmount =
        this.sowList.length > 0
          ? this.sowList[this.sowList.length - 1].sowPaymentTermList[
              this.sowList[this.sowList.length - 1].sowPaymentTermList.length -
                1
            ].remainingAmount
          : null;
    }

    const isValid =
      this.formGroup.valid &&
      this.isFines &&
      this.sowList &&
      this.sowList.length > 0 &&
      indexItemAddressedFalse === -1 &&
      indexFullAdd === -1 &&
      this.contractShippingOptionList.getRequestValues().length > 0 &&
      remainingAmount === 0;

    if (isValid) {
      this.setIsItemExist();
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const url =
              this.todo === 'edit'
                ? '/contract-request/updateX'
                : '/contract-request/insertX';
            this.httpClientService
              .post<Response<ContractRequestXResponse>>(
                url,
                this.getProcessedRequest(true)
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccessCreateNew()
                    .subscribe(result => {
                      if (result) {
                        this.setStateLoading();
                        this.isAppSowReady = false;
                        setTimeout(() => {
                          this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(() => {
                              this.global.routerParams.clear();
                              this.global.routerParams.set('todo', 'add');
                              this.router.navigate([
                                '/pages/contract-request/add'
                              ]);
                              this.global.routerParams.set(
                                'urlBackOutside',
                                '/pages/contract-request'
                              );
                            });

                          this.setStateReady();
                          this.isAppSowReady = true;
                        }, 50);
                      } else {
                        this.router.navigate(['/pages/contract-request']);
                      }
                    });
                } else {
                  this.setStateReady();
                  this.toastService.showError(response.statusText);
                }
              });
          }
        });
    } else if (
      !this.prItemReleasedList ||
      this.prItemReleasedList.length === 0
    ) {
      this.toastService.showError(
        this.translateService.instant(
          'contract-request.alert.msg.minimumOneItem'
        )
      );
    } else if (
      this.contractShippingOptionList.getRequestValues().length === 0
    ) {
      this.toastService.showError(
        this.translateService.instant(
          'contract-request.alert.msg.minimumOneAddress'
        )
      );
    } else if (indexItemAddressedFalse !== -1 || indexFullAdd !== -1) {
      this.toastService.showError(
        this.translateService.instant(
          'contract-request.alert.msg.allItemsShouldBeAddressed'
        )
      );
    } else if (!this.sowList || this.sowList.length === 0) {
      this.toastService.showError(
        this.translateService.instant(
          'contract-request.alert.msg.minimumOneSow'
        )
      );
      //     not valid to submit
    } else if (remainingAmount !== 0) {
      this.toastService.showError(
        this.translateService.instant(
          'contract-request.alert.msg.percentagePaymentNotOneHundredYet'
        )
      );
    } else if (!this.isFines) {
      this.toastService.showError(
        this.translateService.instant(
          'contract-request.alert.msg.minimumOneFines'
        )
      );
    }
  }

  public getProcessedRequest(isSubmit: boolean): ContractRequestXRequest {
    this.request.contract = this.formGroup.value;
    this.request.contract.contractPreparation = this.contractPreparation;
    this.request.contract.requestDate = this.request.contract.requestDate
      ? new Date(this.request.contract.requestDate)
      : new Date();
    this.request.contract.vendor = this.tableResponseVendor.getRecordList()[0];
    this.request.contract.amount = this.amount;
    this.request.contract.goodsAmount = this.goodsAmount;
    this.request.contract.serviceAmount = this.serviceAmount;

    // this.request.fileList = this.formGroup.value.docList;

    const contractShippingList =
      this.contractShippingOptionList.getRequestValues() as Array<ContractShipping>;

    if (contractShippingList && contractShippingList.length === 1) {
      /** one Address */
      this.contractShippingDetailList = [];

      (contractShippingList as Array<ContractShipping>).forEach(cShip => {
        this.prItemReleasedList.forEach(prItemRel => {
          const contractShippingDetail = new ContractShippingDetail();
          contractShippingDetail.id =
            this.response.contractShippingDetailList.find(
              contractShipDetail =>
                contractShipDetail.contractDetail.prItemReleased.id ===
                prItemRel.id
            )?.id;
          contractShippingDetail.contractShipping = cShip;
          contractShippingDetail.contractDetail = new ContractDetail();
          contractShippingDetail.contractDetail.prItemReleased = prItemRel;
          contractShippingDetail.quantity = prItemRel.quantity || 0; // max quantity
          contractShippingDetail.remainingQuantity = 0; /** fully used */

          /** crud operation for revision */
          if (
            this.response.contractDetailList &&
            this.response.contractDetailList.length > 0
          ) {
            const existingContractDetail =
              this.response.contractDetailList.find(
                oi =>
                  oi.prItemReleased.id ===
                  contractShippingDetail.contractDetail.prItemReleased.id
              );
            if (existingContractDetail) {
              /** existing */
              contractShippingDetail.contractDetail.id =
                existingContractDetail.id;
              contractShippingDetail.contractDetail.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              contractShippingDetail.contractDetail.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          } else {
            contractShippingDetail.contractDetail.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }

          if (
            this.response.contractShippingList &&
            this.response.contractShippingList.length > 0
          ) {
            const existingContractShip =
              this.response.contractShippingList.find(
                oship =>
                  oship.address.id ===
                  contractShippingDetail.contractShipping.address.id
              );
            if (existingContractShip) {
              /** existing */
              contractShippingDetail.contractShipping.id =
                existingContractShip.id;
              contractShippingDetail.contractShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              contractShippingDetail.contractShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          } else {
            contractShippingDetail.contractShipping.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }

          this.contractShippingDetailList.push(contractShippingDetail);
        });
      });
    } else if (contractShippingList && contractShippingList.length > 1) {
      /** more than one address */
      this.contractShippingDetailList.forEach(contractShippingDetail => {
        contractShippingDetail.id = contractShippingDetail.id;
        /** crud operation for revision */
        if (
          this.response.contractDetailList &&
          this.response.contractDetailList.length > 0
        ) {
          const existingContractDetail = this.response.contractDetailList.find(
            oi =>
              oi.prItemReleased.id ===
              contractShippingDetail.contractDetail.prItemReleased.id
          );
          if (existingContractDetail) {
            /** existing */
            contractShippingDetail.contractDetail.id =
              existingContractDetail.id;
            contractShippingDetail.contractDetail.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          } else {
            contractShippingDetail.contractDetail.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }
        } else {
          contractShippingDetail.contractDetail.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }

        if (
          this.response.contractShippingList &&
          this.response.contractShippingList.length > 0
        ) {
          const existingContractShip = this.response.contractShippingList.find(
            oship =>
              oship.address.id ===
              contractShippingDetail.contractShipping.address.id
          );
          if (existingContractShip) {
            /** existing */
            contractShippingDetail.contractShipping.id =
              existingContractShip.id;
            contractShippingDetail.contractShipping.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          } else {
            contractShippingDetail.contractShipping.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }
        } else {
          contractShippingDetail.contractShipping.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }

        contractShippingDetail.remainingQuantity =
          this.getRemainingQuantityItem(contractShippingDetail);
      });
    }

    this.request.contractShippingDetailList = this.contractShippingDetailList;

    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.prItemReleasedList.forEach((prItemRel: PrItemReleased) => {
      if (
        prItemRel.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        goodsAmountTotal = +goodsAmountTotal + +prItemRel.totalPrice;
      }
      if (
        prItemRel.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      ) {
        serviceAmountTotal = +serviceAmountTotal + +prItemRel.totalPrice;
      }
    });

    this.request.contract.goodsAmount = goodsAmountTotal;
    this.request.contract.serviceAmount = serviceAmountTotal;
    this.request.contract.amount = +goodsAmountTotal + +serviceAmountTotal;

    if (
      !this.contractShippingDetailList ||
      (this.contractShippingDetailList &&
        this.contractShippingDetailList.length === 0)
    ) {
      this.request.prItemReleasedList = this.prItemReleasedList;
    }

    this.request.sowList = this.sowList;

    this.request.finesList = this.finesList;
    this.request.guaranteeList = this.guaranteeList;

    /** crud operation for revision */
    if (this.response.sowList && this.response.sowList.length > 0) {
      this.request.sowList.forEach(sow => {
        if (this.response.sowList.find(sw => sw.id === sow.id)) {
          sow.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_UPDATE;
        } else {
          sow.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }
      });
    } else {
      this.request.sowList.forEach(sow => {
        sow.crudOperation = this.global.appConstant.core.CRUD_OPERATION_INSERT;
      });
    }

    this.request.isSubmit = isSubmit;
    return this.request;
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public getRemainingQuantityItem(
    contractShippingDetail: ContractShippingDetail
  ): number {
    const maxQuantity =
      contractShippingDetail.contractDetail.prItemReleased.quantity;
    let totalUsedQuantity = 0;
    if (
      this.contractShippingDetailList &&
      this.contractShippingDetailList.length > 1
    ) {
      const cShipDetailList = this.contractShippingDetailList.filter(
        csd =>
          csd.contractDetail.prItemReleased.id ===
          contractShippingDetail.contractDetail.prItemReleased.id
      );
      cShipDetailList.forEach(cShipDetail => {
        totalUsedQuantity += cShipDetail.quantity;
      });
    }
    const remainingQuantity = maxQuantity - totalUsedQuantity;
    return remainingQuantity;
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }

  public setIsItemExist(): void {
    this.sowList.forEach(sow => {
      sow.sowPaymentTermList.forEach(sowPaymentTerm => {
        let isItemExist = false;
        sowPaymentTerm.sowPaymentTermItemList.forEach(sowPaymentTermItem => {
          if (sowPaymentTermItem.quantity > 0) {
            isItemExist = true;
          }
        });
        sowPaymentTerm.isItemExist = isItemExist;
      });
    });
  }
}
