import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ProcurementWorklistStatus } from 'src/app/core/bean/procurement-worklist-status';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { PriceEvaluationVendorView } from 'src/app/core/view/entity/bean/price-evaluation-vendor-view';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';

@Component({
  templateUrl: './price-evaluation-vendor.component.html'
})
export class PriceEvaluationVendorComponent extends BaseModuleComponent {
  public statusOptionList: OptionListModel<ProcurementWorklistStatus> =
    new OptionListModel(true);

  public tableResponse: TableResponseModel<PriceEvaluationVendorView>;

  public constructor(translateService: TranslateService) {
    super('price-evaluation-vendor', translateService);
  }
  public onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      statusList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<ProcurementWorklistStatus[]>(
        '/price-evaluation-vendor/get-option-list'
      )
      .subscribe(response => {
        this.statusOptionList.setRequestValues(response);
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'quotation.procurementVendor.procurement.code',
        header: 'table.column.procurementNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickProcurement.bind(this)
        }
      },
      {
        field: 'quotation.procurementVendor.procurement.procurementDate',
        header: 'table.column.procurementDate',
        plugins: 'date'
      },
      {
        field: 'quotation.procurementVendor.procurement.name',
        header: 'table.column.procurementName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'quotation.procurementVendor.procurement.isRepeat',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              RE_HELD: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (
                  tablePluginData.row.record.quotation.procurementVendor
                    .procurement.isRepeat
                ) {
                  const RE_HELD: string = this.translateService.instant(
                    'dynamic-master-attribute.procurementFlag.reHeld'
                  );
                  return RE_HELD;
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (
                !tablePluginData.row.record.quotation.procurementVendor
                  .procurement.isRepeat
              ) {
                tablePluginData.element.children[1].remove();
              }
            }
          }
        ]
      },
      {
        field: 'quotation.procurementVendor.procurement.procurementMethod.name',
        header: 'table.column.procureemntMethod'
      },
      {
        field: 'quotation.priceEvaluationStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'quotation.priceEvaluationStatus.code',
          colorMap: {
            NEW: 'INFO',
            DONE: 'SUCCESS',
            ON_PROGRESS: 'PROGRESS',
            HOLD: 'DANGER'
          }
        }
      }
    ]);
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    } else {
      this.formGroup.value.fromDate = null;
      this.formGroup.value.untilDate = null;
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doClickProcurement(
    priceEvaluationView: PriceEvaluationVendorView
  ): void {
    this.global.routerParams.clear();
    this.global.routerParams.set(
      'procurementId',
      priceEvaluationView.quotation.procurementVendor.procurement.id
    );
    this.global.routerParams.set(
      'quotationId',
      priceEvaluationView.quotation.id
    );
    this.global.routerParams.set(
      'priceEvaluationStatusCode',
      priceEvaluationView.quotation.priceEvaluationStatus.code
    );
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/price-evaluation-vendor'
    );
    if (priceEvaluationView.startDate && priceEvaluationView.endDate) {
      const currentDate = new Date().getTime();
      const endDate = new Date(priceEvaluationView.endDate).getTime();
      if (currentDate > endDate) {
        this.global.modalService.scheduleMismatch();
      } else {
        this.router.navigate(['/pages/price-evaluation-vendor/edit']);
      }
    } else {
      this.router.navigate(['/pages/price-evaluation-vendor/edit']);
    }
  }
}
