import { Component, EventEmitter, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';

@Component({
  selector: 'app-popup-vendor-bank-alert',
  templateUrl: './app-popup-vendor-bank-alert.component.html'
})
export class AppPopupVendorBankAlertComponent extends BaseComponentComponent {
  @Output() public onChange: EventEmitter<boolean> = new EventEmitter();
  public isAgree: boolean;
  constructor() {
    super('');
  }

  public onInit(): void {
    this.setStateReady();
  }

  public doNo(): void {
    this.isAgree = false;
    this.onChange.emit(this.isAgree);
  }

  public doYes(): void {
    this.isAgree = true;
    this.onChange.emit(this.isAgree);
  }
}
