import { BaseEntity } from '../base/base-entity';
import { VendorPerformanceSummary } from '../bean/vendor-performance-summary';

export class VendorPerformanceScoreEntity extends BaseEntity {

    vendorPerformanceSummary: VendorPerformanceSummary = new VendorPerformanceSummary();
    scoringCriteriaName: string;
    scoringCriteriaCode: string;
    scoringCriteriaGroupName: string;
    scoringCriteriaGroupCode: string;
    value: string;
    result: number;
}
