import { ModuleWithProviders, NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipe/pipe.module';
import { DirectivesModule } from '../../../core/directives/directives.module';
import { PromiseThemeSharedModule } from '../shared/promise-theme-shared.module';
import { LayoutComponent } from './layout.component';
import { LayoutService } from './layout.service';
@NgModule({
  imports: [PromiseThemeSharedModule, DirectivesModule, PipesModule],
  declarations: [LayoutComponent],
  exports: [LayoutComponent]
})
export class LayoutModule {
  public static forRoot(): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: [LayoutService]
    };
  }

  // public static forChild(options?: object): ModuleWithProviders<LayoutModule> {
  //   return {
  //     ngModule: LayoutModule,
  //     providers: [LayoutService],
  //   };
  // }
}
