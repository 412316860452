import { Component, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './lang-flag-icon-popup.component.html'
})
export class LangFlagIconPopupComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<any>;
  public onChange: EventEmitter<string> = new EventEmitter();
  constructor(translateService: TranslateService) {
    super('lang', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
  }

  public doBuildTableResponse(): void {
    const langIconList = 'flag-icon-id,flag-icon-en'.split(',');
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'span',
        header: 'Icon',
        format: FieldFormatEnum.Html,
        sortable: false
      }
    ]);
    const records: Array<object> = [];
    langIconList.forEach(icon => {
      const span = `<span class="flag-icon ${icon}"></span>`;
      records.push({ icon, span });
    });
    this.tableResponse.setRecords(records);
  }

  public doChoose(flagIcon: any): void {
    this.onChange.emit(flagIcon.icon);
  }
}
