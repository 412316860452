<div class="row">
  <div class="col-lg-12 mb-4">
    <div class="text-center py-4" *ngIf="formLoading">
      <img src="assets/img/common/loader-small.gif" alt="Loading" />
    </div>
    <form
      class="form-horizontal"
      [formGroup]="tabGeneralInformation.formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <h5 class="separator">{{
        'tab-profile-general-information.form-group.generalInformation'
          | translate
      }}</h5>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.registrationNumber' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <p>{{
            tabGeneralInformation.formGroup.get('registrationNumber').value
          }}</p>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabGeneralInformation.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.personName' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            autofocus="true"
            formControlName="name"
            maxLength="255"
            type="alphanumeric"
            include="-.\',"
          >
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileGeneralInformationDTO.vendor.name"
              [newValue]="tabProfileGeneralInformationDTO.vendorHistory.name"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.initialName' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            formControlName="initialName"
            type="alphabet"
            maxLength="32"
          >
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileGeneralInformationDTO.vendor.initialName"
              [newValue]="
                tabProfileGeneralInformationDTO.vendorHistory.initialName
              "
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabGeneralInformation.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.identityNumber' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            formControlName="idNumber"
            maxLength="32"
            type="integer"
          >
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileGeneralInformationDTO.vendor.idNumber"
              [newValue]="
                tabProfileGeneralInformationDTO.vendorHistory.idNumber
              "
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabGeneralInformation.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.taxNumber' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-npwp formControlName="taxNumber">
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileGeneralInformationDTO.vendor.taxNumber"
              [newValue]="
                tabProfileGeneralInformationDTO.vendorHistory.taxNumber
              "
            ></app-flag-new>
          </app-npwp>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabGeneralInformation.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.address' | translate
        }}</label>
        <div
          class="text-left"
          [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
        >
          <app-text-area
            formControlName="address"
            maxLength="512"
            placeholder="{{
              'tab-profile-general-information.placeholder.address' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileGeneralInformationDTO.vendorAddress.address"
              [newValue]="
                tabProfileGeneralInformationDTO.vendorAddressHistory.address
              "
            ></app-flag-new>
          </app-text-area>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.coordinateLocation' | translate
        }}</label>
        <div
          class="text-left"
          [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
        >
          <app-text-map formControlName="coordinateLocation">
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileGeneralInformationDTO.vendorAddress.coordinateLocation
              "
              [newValue]="
                tabProfileGeneralInformationDTO.vendorAddressHistory
                  .coordinateLocation
              "
            ></app-flag-new>
          </app-text-map>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabGeneralInformation.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.country' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            [optionList]="countryRegionOptionList"
            formControlName="countryRegion"
            (onChange)="doChangeCountryRegion($event)"
            tooltip="{{
              'tab-profile-general-information.tooltip.country' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileGeneralInformationDTO.vendorAddress.countryRegion
                  ? tabProfileGeneralInformationDTO.vendorAddress.countryRegion
                      .name
                  : null
              "
              [newValue]="
                tabProfileGeneralInformationDTO.vendorAddressHistory
                  .countryRegion
                  ? tabProfileGeneralInformationDTO.vendorAddressHistory
                      .countryRegion.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabGeneralInformation.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.province' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            [optionList]="provinceRegionOptionList"
            formControlName="provinceRegion"
            (onChange)="doChangeProvinceRegion($event)"
          >
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileGeneralInformationDTO.vendorAddress.provinceRegion
                  ? tabProfileGeneralInformationDTO.vendorAddress.provinceRegion
                      .name
                  : null
              "
              [newValue]="
                tabProfileGeneralInformationDTO.vendorAddressHistory
                  .provinceRegion
                  ? tabProfileGeneralInformationDTO.vendorAddressHistory
                      .provinceRegion.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabGeneralInformation.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.city' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            [optionList]="cityRegionOptionList"
            formControlName="cityRegion"
            (onChange)="doChangeCityRegion($event)"
          >
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileGeneralInformationDTO.vendorAddress.cityRegion
                  ? tabProfileGeneralInformationDTO.vendorAddress.cityRegion
                      .name
                  : null
              "
              [newValue]="
                tabProfileGeneralInformationDTO.vendorAddressHistory.cityRegion
                  ? tabProfileGeneralInformationDTO.vendorAddressHistory
                      .cityRegion.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabGeneralInformation.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.district' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            [optionList]="districtRegionList"
            formControlName="districtRegion"
          >
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileGeneralInformationDTO.vendorAddress.districtRegion
                  ? tabProfileGeneralInformationDTO.vendorAddress.districtRegion
                      .name
                  : null
              "
              [newValue]="
                tabProfileGeneralInformationDTO.vendorAddressHistory
                  .districtRegion
                  ? tabProfileGeneralInformationDTO.vendorAddressHistory
                      .districtRegion.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabGeneralInformation.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.postalCode' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="SM"
            formControlName="postCode"
            maxLength="5"
            type="integer"
          >
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileGeneralInformationDTO.vendorAddress.postCode
              "
              [newValue]="
                tabProfileGeneralInformationDTO.vendorAddressHistory.postCode
              "
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabGeneralInformation.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.email' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            formControlName="email"
            maxLength="64"
            tooltip="{{
              'tab-profile-general-information.tooltip.email' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileGeneralInformationDTO.vendor.email"
              [newValue]="tabProfileGeneralInformationDTO.vendorHistory.email"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabGeneralInformation.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-general-information.form.phone' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-phone
            formControlName="phone"
            maxLength="32"
            [optionList]="countryRegionPhoneOptionList"
          >
            <app-flag-new
              *ngIf="
                roleType !== global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabGeneralInformation.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileGeneralInformationDTO.vendorAddress.phoneNumber
              "
              [newValue]="
                tabProfileGeneralInformationDTO.vendorAddressHistory.phoneNumber
              "
            ></app-flag-new>
          </app-phone>
        </div>
      </div>
    </form>
  </div>
</div>
