import { BiddingEvaluation } from 'src/app/core/bean/bidding-evaluation';
import { BiddingSubmissionType } from 'src/app/core/bean/bidding-submission-type';
import { CatalogType } from 'src/app/core/bean/catalog-type';
import { CommitteeParticipant } from 'src/app/core/bean/committee-participant';
import { CurrencyType } from 'src/app/core/bean/currency-type';
import { DocumentSubmissionType } from 'src/app/core/bean/document-submission-type';
import { InitiationType } from 'src/app/core/bean/initiation-type';
import { ItemType } from 'src/app/core/bean/item-type';
import { Module } from 'src/app/core/bean/module';
import { NegotiationType } from 'src/app/core/bean/negotiation-type';
import { Pq } from 'src/app/core/bean/pq';
import { PriceEvaluationType } from 'src/app/core/bean/price-evaluation-type';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementFilter } from 'src/app/core/bean/procurement-filter';
import { ProcurementItem } from 'src/app/core/bean/procurement-item';
import { ProcurementMatrix } from 'src/app/core/bean/procurement-matrix';
import { ProcurementMethod } from 'src/app/core/bean/procurement-method';
import { ProcurementParticipant } from 'src/app/core/bean/procurement-participant';
import { ProcurementPq } from 'src/app/core/bean/procurement-pq';
import { ProcurementRegion } from 'src/app/core/bean/procurement-region';
import { ProcurementSegQualification } from 'src/app/core/bean/procurement-seg-qualification';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { Qualification } from 'src/app/core/bean/qualification';
import { RequirementDoc } from 'src/app/core/bean/requirement-doc';
import { TermQualification } from 'src/app/core/bean/term-qualification';
import { ThresholdRange } from 'src/app/core/bean/threshold-range';
import { VendorSelectionType } from 'src/app/core/bean/vendor-selection-type';
import { ApprovalPathResponseModel } from 'src/app/core/components/app-approval-path-x';
import { AppItemRequestInitiationCatalogModel } from 'src/app/core/components/app-item-request/app-item-request-initiation-catalog-model';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { Currency } from '../../../core/bean/currency';
import { DirectPurchaseDelivery } from '../../../core/bean/direct-purchase-delivery';
import { DirectPurchaseDeliveryStatus } from '../../../core/bean/direct-purchase-delivery-status';
import { DirectPurchasePayment } from '../../../core/bean/direct-purchase-payment';
import { DirectPurchasePaymentDoc } from '../../../core/bean/direct-purchase-payment-doc';
import { DirectPurchaseRealization } from '../../../core/bean/direct-purchase-realization';
import { DirectPurchaseShipping } from '../../../core/bean/direct-purchase-shipping';
import { PaymentMethod } from '../../../core/bean/payment-method';
import { ProcurementCancelation } from '../../../core/bean/procurement-cancelation';
import { ProcurementCancelationDoc } from '../../../core/bean/procurement-cancelation-doc';
import { ProcurementFailed } from '../../../core/bean/procurement-failed';
import { ProcurementFailedDoc } from '../../../core/bean/procurement-failed-doc';
import { ProcurementResult } from '../../../core/bean/procurement-result';
import { ProcurementStatus } from '../../../core/bean/procurement-status';
import { ProfitLossStatus } from '../../../core/bean/profit-loss-status';
import { Region } from '../../../core/bean/region';
import { VendorAddress } from '../../../core/bean/vendor-address';
import { VendorBank } from '../../../core/bean/vendor-bank';
import { VendorPIC } from '../../../core/bean/vendor-pic';
import { AppOfficialReportModel } from '../../../core/components/app-official-report/app-official-report-model';
import { AppUploadDocumentTableModel } from '../../../core/components/app-upload-document-table-x/app-upload-document-table-model';
import { VendorView } from '../../../core/view/entity/bean/vendor-view';

export class InitiationAddEditResponse {
  procurement: Procurement = new Procurement();
  procurementMatrixList: ProcurementMatrix[] = []; /* recommendation */
  procurementMethodList: ProcurementMethod[] = [];
  thresholdRangeList: ThresholdRange[] = [];
  itemTypeList: ItemType[] = [];
  procurementParticipantList: ProcurementParticipant[] = [];
  regionList: Region[] = [];
  currencyList: Currency[] = [];
  paymentMethodList: PaymentMethod[] = [];

  documentSubmissionTypeList: DocumentSubmissionType[] = [];
  negotiationTypeList: NegotiationType[] = [];
  priceEvaluationTypeList: PriceEvaluationType[] = [];
  biddingEvaluationList: BiddingEvaluation[] = [];
  biddingSubmissionTypeList: BiddingSubmissionType[] = [];
  vendorSelectionTypeList: VendorSelectionType[] = [];
  currencyTypeList: CurrencyType[] = [];
  pqList: Pq[] = [];

  termQualificationList: TermQualification[] = [];
  qualificationList: Qualification[] = [];

  procurementFilter: ProcurementFilter;
  procurementRegionList: ProcurementRegion[] = [];
  procurementVendorList: ProcurementVendor[] = [];
  popupVendorViewList: PopupVendorView[] = [];

  procurementItemList: ProcurementItem[] = [];
  procurementPqList: ProcurementPq[] = [];
  procurementSegQualificationList: ProcurementSegQualification[] = [];
  requirementDocList: RequirementDoc[] = [];
  committeeParticipantList: CommitteeParticipant[] = [];

  isUserApproval: boolean;
  approvalPathResponseModel: ApprovalPathResponseModel =
    new ApprovalPathResponseModel();
  module: Module = new Module();

  appUploadDocumentTableModelList: AppUploadDocumentTableModel[] = [];
  draftProcurementStatus: ProcurementStatus;

  procurementCancelation: ProcurementCancelation;
  procurementCancelationDocList: ProcurementCancelationDoc[] = [];

  procurementFailed: ProcurementFailed;
  procurementFailedDocList: ProcurementFailedDoc[] = [];
  failedReason: string;

  directPurchaseDeliveryStatusList: DirectPurchaseDeliveryStatus[] = [];
  profitLossStatusList: ProfitLossStatus[] = [];
  appOfficialReportModel: AppOfficialReportModel;

  /** data DIRECT PURCHASE yg udah saved */
  vendorView: VendorView;
  procurementVendor: ProcurementVendor;
  vendorPIC: VendorPIC;
  vendorAddress: VendorAddress;
  vendorBank: VendorBank;
  directPurchasePayment: DirectPurchasePayment;
  directPurchasePaymentDocList: DirectPurchasePaymentDoc[] = [];
  directPurchaseShippingList: DirectPurchaseShipping[] = [];
  directPurchaseDeliveryList: DirectPurchaseDelivery[] = [];
  directPurchaseRealizationList: DirectPurchaseRealization[] = [];
  procurementResultList: ProcurementResult[] = [];

  companyCurrency: Currency;

  prResponse: AppItemRequestInitiationCatalogModel;
  catalogType: CatalogType;

  initiationType: InitiationType;
}
