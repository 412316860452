import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BudgetAllocation } from '../../core/bean/budget-allocation';
import { SlaJustificationLog } from '../../core/bean/sla-justification-log';
import { WorkPlan } from '../../core/bean/work-plan';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Response } from './../../core/model/response-model';
@Component({
  templateUrl: './popup-justification.component.html'
})
export class PopupJustificationComponent extends BaseModuleComponent {
  @Input() public workflowModelPrcs: WorkflowModelPrcs;
  @Input() public budget: BudgetAllocation;
  @Input() public workPlan: WorkPlan;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  slaJustificationLog: SlaJustificationLog = new SlaJustificationLog();

  constructor(translateService: TranslateService) {
    super('app-popup-justification', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      // 'numberBudget': this.budget.number,
      numberWorkPlan: this.workPlan.number,
      realizeDate: this.workflowModelPrcs.processedDate,
      processDayTotal: this.workflowModelPrcs.slaRealizedInMinutes,
      user: this.workPlan.user,
      userName: this.workPlan.user.name,
      workPlan: this.workPlan,
      createdDate: new Date(),
      workflowModelPrcs: this.workflowModelPrcs,
      justificationDayTotal: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])]
    });
  }

  public doSave(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.slaJustificationLog = this.global.copyFormAttributeToModel(
            this.slaJustificationLog,
            this.formGroup
          );
          this.slaJustificationLog.justificationAfterDayTotal =
            this.workflowModelPrcs.slaRealizedInMinutes -
            +this.slaJustificationLog.justificationDayTotal;
          this.httpClientService
            .post<Response<SlaJustificationLog>>(
              '/app-popup-sla-justification-log/insert',
              this.slaJustificationLog
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessSavingOnNextRoute();
                this.onChange.emit(response);
              } else {
                this.setStateReady();
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doCancel(): void {}
}
