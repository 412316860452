<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="directors-decree-submission.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [tableResponse]="tableResponse"
        [isServerSide]="true"
        stringUrl="/directors-decree-submission/index"
      >
        <ng-template #actionButtons let-rowData>
          <app-button
            class="btn btn-primary btn-sm mr-2"
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <span class="fas fa-pencil-alt"></span>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
