<div class="app-plan-comparison">
  <ng-container
    *ngFor="let planComparisonTemplateDataModelList of templateDataList"
  >
    <div
      class="app-plan-comparison-card"
      *ngFor="
        let planComparisonTemplateDataModel of planComparisonTemplateDataModelList
      "
    >
      <div class="app-plan-comparison-card-header">
        <h5
          class="card-title"
          title=" {{ planComparisonTemplateDataModel.title | translate }}
          ({{ planComparisonTemplateDataModel.type }})"
        >
          {{ planComparisonTemplateDataModel.title | translate }}
          ({{ planComparisonTemplateDataModel.type }})
        </h5>
      </div>
      <div class="app-plan-comparison-card-body">
        <app-chart
          [model]="planComparisonTemplateDataModel.chartModel"
        ></app-chart>
      </div>
      <div class="app-plan-comparison-card-footer">
        <div class="card-row">
          <div class="card-col">
            <span class="title">
              {{
                planComparisonTemplateDataModel.firstDataSlot.title | translate
              }}
            </span>
            <h5 class="total">
              {{ planComparisonTemplateDataModel.firstDataSlot.value }}
            </h5>
          </div>
          <div class="card-col">
            <span class="title">
              {{
                planComparisonTemplateDataModel.secondDataSlot.title | translate
              }}
            </span>
            <h5
              class="total"
              [ngStyle]="{
                color:
                  planComparisonTemplateDataModel.chartModel.options
                    .defaultColor[0]
              }"
            >
              {{ planComparisonTemplateDataModel.secondDataSlot.value }}
            </h5>
          </div>
        </div>
        <div class="card-row">
          <div class="card-col">
            <span class="title">
              {{
                planComparisonTemplateDataModel.thirdDataSlot.title | translate
              }}
            </span>
            <h5 class="total">
              {{ planComparisonTemplateDataModel.thirdDataSlot.value }}
            </h5>
          </div>
          <div class="card-col">
            <span
              class="title title-link"
              (click)="
                doShowMoreDetailWorkPlan(
                  planComparisonTemplateDataModel.fourthDataSlot
                    .statusWorkplanFromWidget +
                    ',' +
                    planComparisonTemplateDataModel.type
                )
              "
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)"
            >
              {{
                planComparisonTemplateDataModel.fourthDataSlot.title | translate
              }}
            </span>
            <h5 class="total">
              {{ planComparisonTemplateDataModel.fourthDataSlot.value }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
