import { BaseEntity } from '../base/base-entity';
import { RfqItem } from '../bean/rfq-item';
import { RfqVendor } from '../bean/rfq-vendor';

export class RfqVendorItemEntity extends BaseEntity {
  rfqVendor: RfqVendor = new RfqVendor();
  rfqItem: RfqItem = new RfqItem();
  bidPrice: number;
  totalBidPrice: number;
}
