import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Module } from 'src/app/core/bean/module';
import { OfficialReport } from 'src/app/core/bean/official-report';
import { OfficialReportToken } from 'src/app/core/bean/official-report-token';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { File } from '../../core/bean/file';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OfficialReportRequest } from './official-report-request';
import { OfficialReportResponse } from './official-report-response';

@Component({
  templateUrl: './official-report-edit.component.html'
})
export class OfficialReportEditAddComponent extends BaseModuleComponent {
  public officialReportId: number;
  public modul: Module;
  public officialResponse: OfficialReportResponse =
    new OfficialReportResponse();
  public officialReportRequest: OfficialReportRequest =
    new OfficialReportRequest();
  public tableResponse: TableResponseModel<OfficialReport>;
  public tableResponseOfficialReportToken: TableResponseModel<OfficialReportToken>;
  public file: File = new File();
  public fileUploader: FileUploader = new FileUploader(
    '/official-report/',
    'official-report',
    this.global.appConstant.fileType.DOC_OFFICIAL_REPORT_TEMPLATE,
    false,
    1
  );

  constructor(translateService: TranslateService) {
    super('official-report', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
    this.buildTableResponse();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.officialReportId = this.global.routerParams.get('officialReportId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      name: [null],
      modulename: [null],
      description: [null, Validators.maxLength(512)],
      officialReportTokenList: [],
      file: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<OfficialReport>(
        '/official-report/edit',
        new RouteRequestModel(this.todo, this.officialReportId)
      )
      .subscribe((officialReportResponse: OfficialReportResponse) => {
        if (officialReportResponse.officialReport != null) {
          const { id, code, name, description, module } =
            officialReportResponse.officialReport;
          const { name: modulename } =
            officialReportResponse.officialReport.module;
          const { officialReportTokenList } = officialReportResponse;
          console.log(officialReportResponse.officialReport.file);
          this.modul = officialReportResponse.officialReport.module;
          this.file = officialReportResponse.officialReport.file;
          this.tableResponseOfficialReportToken.setRecordList(
            officialReportResponse.officialReportTokenList
          );
          this.tableResponseOfficialReportToken.reload();
          this.fileUploader.setFile(this.file);
          this.formGroup.patchValue({
            id,
            code,
            name,
            description,
            file: this.fileUploader.fileObjectList,
            modulename,
            officialReportTokenList,
            module
          });
        }
        this.setStateReady();
        this.formGroup.get('code').setIsView(true);
        this.formGroup.get('name').setIsView(true);
        this.formGroup.get('modulename').setIsView(true);
      });
  }

  public buildTableResponse(): void {
    this.tableResponseOfficialReportToken = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'code',
          header: 'tokencode',
          className: 'd-flex justify-content-between',
          plugins: [
            'default',
            {
              name: 'icon',
              className: 'pir pi-files-alt',
              onClick: this.doCopy
            }
          ]
        },
        {
          field: 'description',
          header: 'description',
          className: 'white-space-normal'
        }
      ]
    );
  }

  public doCopy(record): void {
    const textAreaElement: any = document.createElement('textarea');
    textAreaElement.textContent = record.code;
    document.body.appendChild(textAreaElement);
    textAreaElement.select();
    document.execCommand('copy');
    document.body.removeChild(textAreaElement);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const officialReport: OfficialReport =
              this.global.copyFormAttributeToModel(
                new OfficialReport(),
                this.formGroup
              );
            officialReport.file = null;
            officialReport.module = this.modul;
            const officialReportRequest: OfficialReportRequest =
              new OfficialReportRequest();
            officialReportRequest.fileObjectList = this.formGroup.value.file;
            officialReportRequest.officialReport = officialReport;
            const url = this.todo === 'edit' ? '/official-report/update' : '';
            this.httpClientService
              .post<Response<OfficialReportRequest>>(url, officialReportRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccessCreateNew()
                    .subscribe(result => {
                      if (result) {
                        this.global.routerParams.clear();
                        this.global.routerParams.set('todo', 'edit');
                        this.router.navigate(['/pages/official-report/edit/']);
                      } else {
                        this.router.navigate(['/pages/official-report/']);
                      }
                    });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
}
