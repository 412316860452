import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAlertXModule } from '../app-alert-x/app-alert-x.module';
import { AppBadgeCatalogModule } from '../app-badge-catalog/app-badge-catalog.module';
import { AppBadgeModule } from '../app-badge/app-badge.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppImageSlideshowModule } from '../app-image-slideshow/app-image-slideshow.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppTooltipModule } from '../app-tooltip/app-tooltip.module';
import { TableModule } from '../table/table.module';
import { AppCatalogDetailInfoComponent } from './app-catalog-detail-info.component';
import { AppPopupBulkPriceCatalogDetailInfoComponent } from './app-popup-bulk-price-catalog-detail-info.component';
import { AppPopupItemContractCatalogDetailInfoComponent } from './app-popup-item-contract-catalog-detail-info.component';

@NgModule({
  imports: [
    VendorModule,
    AppImageSlideshowModule,
    DirectivesModule,
    AppBadgeModule,
    AppBadgeCatalogModule,
    PipesModule,
    AppTooltipModule,
    AppPopupModule,
    TableModule,
    AppAlertXModule,
    AppButtonModule
  ],
  declarations: [
    AppCatalogDetailInfoComponent,
    AppPopupItemContractCatalogDetailInfoComponent,
    AppPopupBulkPriceCatalogDetailInfoComponent
  ],
  entryComponents: [
    AppPopupItemContractCatalogDetailInfoComponent,
    AppPopupBulkPriceCatalogDetailInfoComponent
  ],
  exports: [
    AppCatalogDetailInfoComponent,
    AppPopupItemContractCatalogDetailInfoComponent,
    AppPopupBulkPriceCatalogDetailInfoComponent
  ]
})
export class AppCatalogDetailInfoModule {}
