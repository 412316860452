import { BaseEntity } from '../base/base-entity';
import { Organization } from '../bean/organization';
import { User } from '../bean/user';
import { Workload } from '../bean/workload';
import { WorkloadStatus } from '../bean/workload-status';

export class WorkloadPrcsEntity extends BaseEntity {
  public workload: Workload;
  public workloadStatus: WorkloadStatus;
  public userDelegated: User;
  public userDelegatedBy: User;
  public organizationTenant: Organization;
  public organizationDelegated: Organization;
  public objectId: number;
  public objectName: string;
}
