import { DueDiligenceAssigner } from 'src/app/core/bean/due-diligence-assigner';
import { DueDiligenceDoc } from 'src/app/core/bean/due-diligence-doc';
import { DueDiligenceExecutor } from 'src/app/core/bean/due-diligence-executor';
import { DueDiligenceOTS } from 'src/app/core/bean/due-diligence-ots';
import { DueDiligenceRealization } from 'src/app/core/bean/due-diligence-realization';
import { DueDiligenceSchedule } from 'src/app/core/bean/due-diligence-schedule';
import { DueDiligenceTemplateOTS } from 'src/app/core/bean/due-diligence-template-OTS';
import { VendorHistory } from 'src/app/core/bean/vendor-history';
import { VendorVerification } from 'src/app/core/bean/vendor-verification';

export class OtsResponse {

    vendorHistory: VendorHistory = new VendorHistory();
    vendorVerification: VendorVerification = new VendorVerification();
    isUserApproval: boolean;

    isTemplateDifferent: boolean;
    dueDiligenceScheduleOTS: DueDiligenceSchedule = new DueDiligenceSchedule();
    dueDiligenceAssignerTaskGiverList: DueDiligenceAssigner[] = [];
    dueDiligenceAssignerAcknowledgedByList: DueDiligenceAssigner[] = [];
    dueDiligenceExecutorOTSList: DueDiligenceExecutor[] = [];

    dueDiligenceTemplateOTSList: DueDiligenceTemplateOTS[] = [];
    dueDiligenceRealizationOTS: DueDiligenceRealization = new DueDiligenceRealization();
    dueDiligenceOTSList: DueDiligenceOTS[] = [];
    dueDiligenceDocList: DueDiligenceDoc[] = [];
}
