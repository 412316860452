import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppOfficialReportModule } from '../../core/components/app-official-report/app-official-report.module';
import { AppPopupFinalPriceDetailModule } from '../../core/components/app-popup/app-popup-final-price-detail/app-popup-final-price-detail.module';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupVendorRefutationComponent } from './app-popup-vendor-refutation.component';
import { RefutationEditComponent } from './refutation-edit.component';
import { RefutationItemReviewComponent } from './refutation-item-review.component';

export const routes = [
  {
    path: 'add',
    component: RefutationEditComponent,
    data: { breadcrumb: 'refutation.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: RefutationEditComponent,
    data: { breadcrumb: 'refutation.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: RefutationEditComponent,
    data: { breadcrumb: 'refutation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: RefutationEditComponent,
    data: { breadcrumb: 'refutation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'allocated-item/details',
    component: RefutationItemReviewComponent,
    data: { breadcrumb: 'refutation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AppWorkflowStepInfoModule,
    AppProcurementInfoModule,
    AppItemRequestModule,
    AppApprovalPrcsXModule,
    AppOfficialReportModule,
    AppPopupFinalPriceDetailModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    RefutationEditComponent,
    AppPopupVendorRefutationComponent,
    RefutationItemReviewComponent
  ],
  entryComponents: [AppPopupVendorRefutationComponent],
  exports: [AppPopupVendorRefutationComponent]
})
export class RefutationModule {}
