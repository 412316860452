import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { WebContent } from 'src/app/core/bean/web-content';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Validators } from 'src/app/core/validators';
@Component({
  templateUrl: './app-popup-manage-catalog-toc.component.html',
  styleUrls: ['./app-popup-manage-catalog-toc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupManageCatalogTOCComponent extends BaseComponentComponent {
  termsAndCondition: any;
  @Input() content: WebContent;

  constructor(
    public activeModal: NgbActiveModal,
    public authenticationService: AuthenticationService,
    public sanitized: DomSanitizer
  ) {
    super('app-popup-manage-catalog-toc');
  }

  onInit(): void {
    this.test();
    this.buildFormGroup();
    this.setStateReady();
  }

  test(): void {
    this.termsAndCondition = this.sanitized.bypassSecurityTrustHtml(
      this.content.value.toString()
    );
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      toc: [null],
      check: [
        null,
        Validators.isChecked(null, 'app-popup-manage-catalog-toc.validation.isChecked')
      ]
    });
  }

}
