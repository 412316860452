import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppCatalogItemXModule } from '../app-catalog-item-x/app-catalog-item-x.module';
import { AppComboBoxModule } from '../app-combo-box/app-combo-box.module';
import { AppPhoneModule } from '../app-phone/app-phone.module';
import { AppRatingModule } from '../app-rating/app-rating.module';
import { AppTextFieldModule } from '../app-text-field/app-text-field.module';
import { AppVendorCatalogInfoComponent } from './app-vendor-catalog-info.component';

@NgModule({
    imports: [VendorModule, AppComboBoxModule, AppCatalogItemXModule, AppCardModule,
      AppTextFieldModule, AppButtonModule, AppRatingModule, PipesModule, AppPhoneModule],
    declarations: [AppVendorCatalogInfoComponent],
    entryComponents: [AppVendorCatalogInfoComponent],
    exports: [AppVendorCatalogInfoComponent]
  })
export class AppVendorCatalogInfoModule {

}
