import { Contract } from 'src/app/core/bean/contract';
import { ContractNegotiationHistory } from 'src/app/core/bean/contract-negotiation-history';
import { ContractReviewer } from 'src/app/core/bean/contract-reviewer';
import { ContractTermination } from 'src/app/core/bean/contract-termination';
import { ContractTerminationDoc } from 'src/app/core/bean/contract-termination-doc';
import { Module } from 'src/app/core/bean/module';
import { AppPopupVersionHistoryModel } from 'src/app/core/components/app-popup/app-popup-version-history/app-popup-version-history-model';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path-x';

export class ContractTerminationEditAddResponse {
  public contractTermination: ContractTermination;
  public contract: Contract;
  public appPopupVersionHistoryModelList: AppPopupVersionHistoryModel[];
  public versionHistoryModel: AppPopupVersionHistoryModel;
  public contractNegotiationHistory: ContractNegotiationHistory;
  public approvalPathResponseModel: ApprovalPathResponseModel;
  public isUserApproval: boolean;
  public isProcessed: boolean;
  public startDate: Date;
  public endDate: Date;
  public contractReviewerList: ContractReviewer[];
  public contractTerminationDocList: ContractTerminationDoc[];
  public module: Module;
  public contractDocModel: AppPopupVersionHistoryModel;
}
