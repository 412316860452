import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { NotificationJobViewComponent } from './notification-job-detail.component';
import { NotificationJobComponent } from './notification-job.component';
const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: '', component: NotificationJobComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: NotificationJobViewComponent,
    data: { breadcrumb: 'notification-log.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [NotificationJobComponent, NotificationJobViewComponent]
})
export class NotificationJobModule {}
