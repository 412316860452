<app-layout [isLoading]="formLoading">
  <app-table-xx
    header="workload.table.header.assignmentByStage"
    [model]="tableResponse"
    stringUrl="/workload/index"
    (onClick)="doClickWorkload($event)"
  >
    <ng-template #headerFilterGroup>
      <form
        class="form-horizontal pt-4"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left ml-1">
          <div class="col-sm-2 text-left">
            <app-dropdown-select
              *ngIf="!formLoading"
              size="XL"
              formControlName="moduleGroupList"
              placeholder="{{ 'workload.placeholder.moduleGroup' | translate }}"
              [optionList]="moduleGroupOptionList"
              (onChange)="onChangeFilter()"
              type="CHOSEN"
              [ngStyle]="{
                width: 'inherit'
              }"
              showLimitValue="4"
            ></app-dropdown-select>
          </div>
        </div>
      </form>
    </ng-template>
  </app-table-xx>
</app-layout>
