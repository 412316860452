<app-widget *ngIf="!formLoading" [header]="moduleCode + '.title'" class="app-widget-vendor-information">
  <p *ngIf="vendorInformationResponse == null">{{
    'app-widget-top-vendor-performance.sorryYourRoleCannotUseThisWidget' |
    translate }}</p>
  <app-widget-content-slider [isShowControl]="isShowSliderControl" *ngIf="vendorInformationResponse != null">
    <app-widget-card *ngIf="isPerformanceShow"
      header="{{ 'app-widget-vendor-information.performanceOverall' | translate }}">
      <app-rating [value]="vendorInformationResponse.vendorPerformance" [isViewOnly]="true"></app-rating>
      <div class="widget-card-footer mt-2">
        <p>{{'' || "."}}</p>
      </div>
    </app-widget-card>
    <app-widget-card *ngIf="isDocumentShow" header="{{ 'app-widget-vendor-information.docWillExp' | translate }}">
      <h4>{{ vendorInformationResponse.numberOfExpiredDoc }}</h4>
      <div class="widget-card-footer mt-2">
        <p class="m-0 cursor-pointer more-detail" (click)="goToMoreDetail(vendorId)" (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)">
          {{ 'app-widget-vendor-information.moreDetail' | translate }}
          <!-- <em class="fas fa-chevron-circle-right"></em> -->
        </p>
      </div>
    </app-widget-card>
    <app-widget-card *ngIf="isRfiShow" header="{{ 'app-widget-vendor-information.rfiRequest' | translate }}">
      <h4>{{ vendorInformationResponse.numberOfRfi }}</h4>
      <div class="widget-card-footer mt-2">
        <p>{{'' || "."}}</p>
      </div>
    </app-widget-card>
  </app-widget-content-slider>
</app-widget>