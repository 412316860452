import { EmployeeProcurementDoc } from '../bean/employee-procurement-doc';
import { EmployeeTags } from '../bean/employee-tags';
import { File } from '../bean/file';
import { Region } from '../bean/region';
import { VendorEntity } from '../bean/vendor-entity';
import { BaseEntity } from './../base/base-entity';
import { EmployeeCountry } from './../bean/employee-country';
import { EmployeeInterest } from './../bean/employee-interest';
export class EmployeeEntity extends BaseEntity {
  name: string;
  gender: number;
  birthDate: Date;
  address: string;
  region: Region;
  salary: number;
  email: string;
  password: string;
  profileImgFile: string;
  about: string;
  isHasPet: boolean;
  rating: number;
  interest: string;
  tags: string;
  employeeInterestList: EmployeeInterest[];
  employeeTagsList: EmployeeTags[];
  employeeCountryList: EmployeeCountry[];
  vendorCategory: VendorEntity;
  regionPhone: Region;
  phoneNumber: string;
  npwpfile: File;
  employeeProcurementDocList: EmployeeProcurementDoc[];
  startDate: Date;
  endDate: Date;
  uom: string;
  makeSure: boolean;
  javaScript: boolean;
  zodiac: number;
  yearOfBirth: number;
  java: boolean;
  python: boolean;
  ruby: boolean;
}
