import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpLoaderFactory, initApp } from './app-factory';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import { AppErrorHandler } from './core/errors/app-error-handler';
import { Global } from './core/global/global';
import { RequestInterceptor } from './core/interceptor/request.interceptor';
import { ResponseInterceptor } from './core/interceptor/response.interceptor';
import { AppLoadService } from './core/services/app-load.service';
import { AuthenticationService } from './core/services/authentication.service';
import { CookieService } from './core/services/cookie.service';
import { ServiceLocator } from './core/services/service-locator';
import { SharedModule } from './core/shared/shared.module';
import './custom.prototype';
import { TimeService } from './core/services/time.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AppSettings,
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    Global,
    AuthenticationService,
    AppLoadService,
    CookieService,
    TimeService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppLoadService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    ServiceLocator.injector = injector;
  }
}
