import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Contract } from 'src/app/core/bean/contract';
import { DealingAgreementType } from 'src/app/core/bean/dealing-agreement-type';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';

@Component({
  templateUrl: './app-popup-contract-request-choose-contract.component.html'
})
export class AppPopupContractRequestChooseContractComponent extends BaseComponentComponent {
  @Input() contractId: number;
  @Input() public contract: Contract;
  @Input() public dealingAgreementType: DealingAgreementType;
  @Output() public onChange: EventEmitter<Contract> = new EventEmitter();
  public tableResponseContract: TableResponseModel<Contract>;

  constructor(public translateService: TranslateService) {
    super('contract-request');
  }

  onInit(): void {
    this.buildTableResponse();
    this.buildFormGroup();
    this.setCustomData();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      contract: null,
      contractId: null,
      dealingAgreementType: null
    });
  }
  public setCustomData(): void {
    this.formGroup.patchValue({
      contract: this.contract,
      contractId: this.contractId,
      dealingAgreementType: this.dealingAgreementType
    });

    this.tableResponseContract.setCustomData(this.formGroup.value);
  }
  public buildTableResponse(): void {
    this.tableResponseContract = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementVendor.procurement.name',
        header: 'table.column.procurement'
      },
      {
        field: 'organization.name',
        header: 'table.column.organization',
        customClass: 'text-center'
      },
      {
        field: 'vendor.name',
        header: 'table.column.vendor',
        customClass: 'white-space-normal'
      },
      {
        field: 'contractType.name',
        header: 'table.column.contractType',
        customClass: 'text-center'
      },
      {
        field: 'dealingAgreementType.name',
        header: 'table.column.contractType',
        customClass: 'text-center'
      },
      {
        field: 'number',
        header: 'table.column.contractNumber',
        customClass: 'text-center'
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      }
    ]);
  }

  public doCheckContract(contract: Contract): void {
    this.contract = contract;
  }

  public doSave(): void {
    if (this.contract) {
      if (
        this.formGroup.value.contract &&
        this.formGroup.value.contract.id !== this.contract.id
      ) {
        this.global.modalService
          .confirm(
            this.translateService.instant('contract-request.confirmation.body'),
            this.translateService.instant(
              'contract-request.confirmation.header'
            )
          )
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.onChange.emit(this.contract);
            }
          });
      } else {
        this.onChange.emit(this.contract);
      }
    } else {
      this.global.alertService.showError(
        this.global.translateService.instant('app.validation.chooseOneData'),
        '.main-row-popup-choose-contract'
      );
    }
  }

  public doSortContract(sortField: string): void {
    this.tableResponseContract.setSortField(sortField);
    this.tableResponseContract.reload();
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
