import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../../core/guard/confirm.guard';
import { SharedModule } from '../../../core/shared/shared.module';
import { DisclaimerEditAddComponent } from './disclaimer-edit-add.component';
const routes = [
  {
    path: '',
    component: DisclaimerEditAddComponent,
    data: { breadcrumb: 'disclaimer.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [DisclaimerEditAddComponent]
})
export class DisclaimerModule {}
