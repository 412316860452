<app-layout [isLoading]="formLoading">
  <div
    class="input-group input-group-search"
    [ngStyle]="{ 'max-width': 'none', 'margin-bottom': '46px' }"
  >
    <div class="input-group-prepend">
      <span class="input-group-text">
        <em class="pir pi-search"></em>
      </span>
    </div>
    <input
      #inputSearch
      type="text"
      class="form-control"
      [placeholder]="'report-recap.placeholder.search' | translate"
      [attr.aria-label]="'report-recap.placeholder.search' | translate"
      (input)="onInputSearch($event.target.value)"
    />
    <em
      class="pir pi-times btn-delete pi-2x text-danger"
      *ngIf="isFiltered"
      (click)="doResetInputSearch(inputSearch)"
    ></em>
  </div>
  <div
    *ngIf="!formLoading && generalReportRecapList.length > 0"
    style="border-bottom: 1px solid var(--gray-200)"
  >
    <app-content-slider>
      <ng-template #customHeader>
        <div class="slider-title"
          ><em
            class="pir pi-file-alt pi-3x slider-title-icon"
            [ngStyle]="{ 'background-color': '#009DC1' }"
          ></em>
          <h3>
            {{ 'report-recap.slider.title.generalReports' | translate }}
          </h3>
        </div>
      </ng-template>
      <ng-template #customContent>
        <div
          class="slider-item-report cursor-pointer"
          *ngFor="let report of generalReportRecapList"
          (click)="doClickReport(report)"
        >
          <div style="margin: 18px 12px">
            <div class="slider-icon-back">
              <em
                class="pir pi-file-alt pi-4x slider-title-icon"
                [ngStyle]="{ 'background-color': '#009DC1' }"
              ></em>
            </div>
            <h4 style="margin-bottom: 8px">{{
              moduleCode + '.' + report.translationKey.key | translate
            }}</h4>
            <p style="white-space: normal">{{ report.description || '' }}</p>
          </div>
        </div>
      </ng-template>
    </app-content-slider>
  </div>
  <div
    *ngIf="!formLoading && cmReportRecapList.length > 0"
    style="border-bottom: 1px solid var(--gray-200); padding-top: 32px"
  >
    <app-content-slider>
      <ng-template #customHeader>
        <div class="slider-title"
          ><em
            class="pir pi-user-cog pi-3x slider-title-icon"
            [ngStyle]="{ 'background-color': 'var(--info)' }"
          ></em>
          <h3>{{ 'report-recap.slider.title.cmReports' | translate }}</h3>
        </div>
      </ng-template>
      <ng-template #customContent>
        <div
          class="slider-item-report cursor-pointer"
          *ngFor="let report of cmReportRecapList"
          (click)="doClickReport(report)"
        >
          <div style="margin: 18px 12px">
            <div class="slider-icon-back">
              <em
                class="pir pi-user-cog pi-4x slider-title-icon"
                [ngStyle]="{ 'background-color': 'var(--info)' }"
              ></em>
            </div>
            <h4 style="margin-bottom: 8px">{{
              moduleCode + '.' + report.translationKey.key | translate
            }}</h4>
            <p style="white-space: normal">{{ report.description || '' }}</p>
          </div>
        </div>
      </ng-template>
    </app-content-slider>
  </div>
  <div
    *ngIf="!formLoading && esmReportRecapList.length > 0"
    style="border-bottom: 1px solid var(--gray-200); padding-top: 32px"
  >
    <app-content-slider>
      <ng-template #customHeader>
        <div class="slider-title"
          ><em
            class="pir pi-dollar-sign-circle pi-3x slider-title-icon"
            [ngStyle]="{ 'background-color': 'var(--feedback)' }"
          ></em>
          <h3>
            {{ 'report-recap.slider.title.esmReports' | translate }}
          </h3>
        </div>
      </ng-template>
      <ng-template #customContent>
        <div
          class="slider-item-report cursor-pointer"
          *ngFor="let report of esmReportRecapList"
          (click)="doClickReport(report)"
        >
          <div style="margin: 18px 12px">
            <div class="slider-icon-back">
              <em
                class="pir pi-dollar-sign-circle pi-4x slider-title-icon"
                [ngStyle]="{ 'background-color': 'var(--feedback)' }"
              ></em>
            </div>
            <h4 style="margin-bottom: 8px">{{
              moduleCode + '.' + report.translationKey.key | translate
            }}</h4>
            <p style="white-space: normal">{{ report.description || '' }}</p>
          </div>
        </div>
      </ng-template>
    </app-content-slider>
  </div>
  <div
    *ngIf="!formLoading && pmReportRecapList.length > 0"
    style="border-bottom: 1px solid var(--gray-200); padding-top: 32px"
  >
    <app-content-slider>
      <ng-template #customHeader>
        <div class="slider-title"
          ><em
            class="pir pi-clipboard pi-3x slider-title-icon"
            [ngStyle]="{ 'background-color': 'var(--progress)' }"
          ></em>
          <h3>{{ 'report-recap.slider.title.pmReports' | translate }}</h3>
        </div>
      </ng-template>
      <ng-template #customContent>
        <div
          class="slider-item-report cursor-pointer"
          *ngFor="let report of pmReportRecapList"
          (click)="doClickReport(report)"
        >
          <div style="margin: 18px 12px">
            <div class="slider-icon-back">
              <em
                class="pir pi-clipboard pi-4x slider-title-icon"
                [ngStyle]="{ 'background-color': 'var(--progress)' }"
              ></em>
            </div>
            <h4 style="margin-bottom: 8px">{{
              moduleCode + '.' + report.translationKey.key | translate
            }}</h4>
            <p style="white-space: normal">{{ report.description || '' }}</p>
          </div>
        </div>
      </ng-template>
    </app-content-slider>
  </div>
  <div
    *ngIf="!formLoading && vmReportRecapList.length > 0"
    style="border-bottom: 1px solid var(--gray-200); padding-top: 32px"
  >
    <app-content-slider>
      <ng-template #customHeader>
        <div class="slider-title"
          ><em
            class="pir pi-user pi-3x slider-title-icon"
            [ngStyle]="{ 'background-color': 'var(--service)' }"
          ></em>
          <h3>{{ 'report-recap.slider.title.vmReports' | translate }}</h3>
        </div>
      </ng-template>
      <ng-template #customContent>
        <div
          class="slider-item-report cursor-pointer"
          *ngFor="let report of vmReportRecapList"
          (click)="doClickReport(report)"
        >
          <div style="margin: 18px 12px">
            <div class="slider-icon-back">
              <em
                class="pir pi-user pi-4x slider-title-icon"
                [ngStyle]="{ 'background-color': 'var(--service)' }"
              ></em>
            </div>
            <h4 style="margin-bottom: 8px">{{
              moduleCode + '.' + report.translationKey.key | translate
            }}</h4>
            <p style="white-space: normal">{{ report.description || '' }}</p>
          </div>
        </div>
      </ng-template>
    </app-content-slider>
  </div>

  <div
    *ngIf="
      !formLoading &&
      (reportBuilderList.length > 0 ||
        (reportBuilderList.length === 0 && !isFiltered))
    "
    style="padding-top: 32px"
  >
    <app-content-slider>
      <ng-template #customHeader>
        <div class="slider-title"
          ><em
            class="pir pi-cog pi-3x slider-title-icon"
            [ngStyle]="{ 'background-color': 'var(--warning)' }"
          ></em>
          <h3>{{ 'report-recap.slider.title.reportBuilder' | translate }}</h3>
        </div>
      </ng-template>

      <ng-template #customContent>
        <div
          class="slider-custom-report cursor-pointer"
          (click)="doAddCustomReport()"
          *ngIf="!isFiltered"
          [ngStyle]="{ position: 'relative' }"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              padding: 20px;
              white-space: normal;
              left: 12px;
            "
            [ngStyle]="{ position: 'absolute' }"
          >
            <em
              class="pir pi-plus-circle pi-3x"
              style="padding-right: 18px; color: var(--primary)"
            ></em>
            <strong style="font-size: 16px; color: var(--primary)">{{
              'report-recap.button.createCustomReport' | translate
            }}</strong>
          </div>
        </div>
        <div
          class="slider-item-report cursor-pointer"
          *ngFor="let report of reportBuilderList"
          (click)="doClickCustomReport(report)"
        >
          <div style="margin: 18px 12px">
            <div class="slider-icon-back">
              <em
                class="pir pi-cog pi-4x slider-title-icon"
                [ngStyle]="{ 'background-color': 'var(--warning)' }"
              ></em
            ></div>
            <h4 style="margin-bottom: 8px">{{ report.name }}</h4>
            <p style="white-space: normal">{{ report.description || '' }}</p>
          </div>
        </div>
      </ng-template>
    </app-content-slider>
  </div>
</app-layout>
