import { BudgetAllocation } from '../bean/budget-allocation';
import { User } from '../bean/user';

export class BudgetHistoryEntity extends BudgetAllocation {
  budgetAllocation: BudgetAllocation = new BudgetAllocation();
  user: User = new User();
  totalBudget: number;
  usedBudget: number;
  availableBudget: number;
  bookedBudget: number;
  paidOffBudget: number;
  updatedDate: Date;
  note: string;
  form: string;
  sequence: number;
}
