import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BaseModuleComponent } from '../../base/angular/base-module.component';
import { WorkPlan } from '../../bean/work-plan';
import { WorkPlanDoc } from '../../bean/work-plan-doc';
import { WorkPlanItem } from '../../bean/work-plan-item';
import { AppTableComponent } from '../app-table/app-table.component';
import { FieldFormatEnum } from '../app-table/model/field-format.enum';
import { TableResponseModel } from '../app-table/model/table-response-model';
import { Organization } from './../../bean/organization';
import { WorkPlanInfoRequest } from './request/work-plan-info-request';

@Component({
  selector: 'app-work-plan-info',
  templateUrl: './app-work-plan-info.component.html',
  styleUrls: ['./app-work-plan-info.component.scss']
})
export class AppWorkPlanInfoComponent extends BaseModuleComponent {
  @Input() public workPlanId: number;
  public totalProductCost: number;
  public totalServiceCost: number;
  public tableReady = false;
  @ViewChild('selectorProduct') tableWorkPlanProduct: AppTableComponent;
  @ViewChild('selectorService') tableWorkPlanService: AppTableComponent;
  public workPlan: WorkPlan;
  public workPlanDocList: WorkPlanDoc[];
  public totalItemPrice: number;
  public workPlanInfoRequest: WorkPlanInfoRequest = new WorkPlanInfoRequest();
  public tableResponseService: TableResponseModel<WorkPlanItem>;
  public tableResponseProduct: TableResponseModel<WorkPlanItem>;

  public stringUrl = '/app-work-plan-info/index';

  constructor(translateService: TranslateService) {
    super('approval-workflow', translateService);
  }

  public onInit(): void {
    this.setDataToWorkplanAndWorkplanDocList();
  }

  public buildTableResponse(): void {
    this.tableResponseProduct = new TableResponseModel(
      'app-work-plan-item-info',
      [
        {
          field: 'item.code',
          header: 'table.product.column.itemCode',
          customClass: 'text-center'
        },
        { field: 'item.name', header: 'table.product.column.itemName' },
        {
          field: 'item.uom.name',
          header: 'table.product.column.uom',
          customClass: 'text-center'
        },
        {
          field: 'item.itemCategory.name',
          header: 'table.product.column.category'
        },
        {
          field: 'quantity',
          header: 'table.product.column.quantity',
          customClass: 'text-right'
        },
        {
          field: 'unitPrice',
          header: 'table.product.column.unitPrice',
          format: FieldFormatEnum.Currency,
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'totalPrice',
          header: 'table.product.column.totalPrice',
          format: FieldFormatEnum.Currency,
          currencyCodeRef: 'currency.code'
        }
      ]
    );
    this.tableResponseProduct.page.records = this.workPlanInfoRequest.workPlanItemProductList;
    this.tableResponseProduct.page.totalRecords =
      this.workPlanInfoRequest.workPlanItemProductList &&
      this.workPlanInfoRequest.workPlanItemProductList.length;
    this.tableResponseProduct.reloadClient();

    this.tableResponseService = new TableResponseModel(
      'app-work-plan-item-info',
      [
        {
          field: 'item.code',
          header: 'table.service.column.itemCode',
          customClass: 'text-center'
        },
        { field: 'item.name', header: 'table.service.column.itemName' },
        {
          field: 'item.uom.name',
          header: 'table.service.column.uom',
          customClass: 'text-center'
        },
        {
          field: 'item.itemCategory.name',
          header: 'table.service.column.category'
        },
        {
          field: 'quantity',
          header: 'table.service.column.quantity',
          customClass: 'text-right'
        },
        {
          field: 'unitPrice',
          header: 'table.service.column.unitPrice',
          format: FieldFormatEnum.Currency,
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'totalPrice',
          header: 'table.service.column.totalPrice',
          format: FieldFormatEnum.Currency,
          currencyCodeRef: 'currency.code'
        }
      ]
    );
    this.tableResponseService.page.records = this.workPlanInfoRequest.workPlanItemServiceList;
    this.tableResponseService.page.totalRecords =
      this.workPlanInfoRequest.workPlanItemServiceList &&
      this.workPlanInfoRequest.workPlanItemServiceList.length;
    this.tableResponseService.reloadClient();

    this.totalItemPrice =
      +this.workPlanInfoRequest.totalProductCost +
      +this.workPlanInfoRequest.totalServiceCost;
  }

  public setDataToWorkplanAndWorkplanDocList(): void {
    this.dataFromServer.subscribe(workPlanInfoRequest => {
      this.workPlanInfoRequest = workPlanInfoRequest;
      this.workPlan = workPlanInfoRequest.workPlan;
      this.workPlan.organization.name = '';
      this.workPlanInfoRequest.organizationList.forEach(
        (organization: Organization, index: number) => {
          this.workPlan.organization.name +=
            index !== this.workPlanInfoRequest.organizationList.length - 1
              ? organization.name + ' > '
              : organization.name;
        }
      );

      this.workPlanDocList = workPlanInfoRequest.workPlanDocList;
      this.setStateReady();
      this.buildTableResponse();
    });
  }

  public get dataFromServer(): Observable<WorkPlanInfoRequest> {
    return this.httpClientService
      .get<WorkPlanInfoRequest>(this.stringUrl + '/' + this.workPlanId);
  }
}
