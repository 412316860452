<form
  class="form-horizontal"
  [formGroup]="formGroup"
  novalidate
>
  <div class="form-group row">
    <div class="col-lg-12">
      <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/tab-performance-history/index">
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" (onClick)="doView(rowData)" title="{{ 'app.tooltip.detail' | translate }}">
            <span class="fas fa-search"></span>
          </app-button>
        </ng-template>
      </app-table>
    </div>
  </div>
</form>

