<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="role.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'role.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="code"
              type="code"
              maxLength="32"
              tooltip="{{ 'role.tooltip.code' | translate }}"
            >
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'role.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="name" maxLength="64">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'role.form.dashboard' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-chosen
              formControlName="module"
              [optionList]="dashboardWidgetOptionList"
            ></app-chosen>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'role.form.report' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-chosen
              formControlName="report"
              [optionList]="reportRecapOptionList"
            ></app-chosen>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'role.form.identity' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-radio
              formControlName="type"
              [optionList]="roleFormIdentityOptionList"
              position="HORIZONTAL"
            >
            </app-radio>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
