import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from './../../core/shared/shared.module';
import { StageOfWorkEditAddComponent } from './stage-of-work-edit-add.component';
import { StageOfWorkComponent } from './stage-of-work.component';
export const routes = [
  { path: '', component: StageOfWorkComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: StageOfWorkEditAddComponent,
    data: { breadcrumb: 'stage-of-work.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: StageOfWorkEditAddComponent,
    data: { breadcrumb: 'stage-of-work.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [StageOfWorkComponent, StageOfWorkEditAddComponent],
  providers: []
})
export class StageOfWorkModule {}
