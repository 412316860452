import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupImportQuantityComponent } from './app-popup-import-quantity.component';

@Injectable({ providedIn: 'root' })
export class AppPopupImportQuantityService {
  constructor(
    public appPopupService: AppPopupService
  ) {}
  onCloseModal: EventEmitter<boolean> = new EventEmitter();
  public open(urlSave: string): Observable<boolean> {
    return this.appPopupService.open(
      AppPopupImportQuantityComponent,
      {
        urlSave,
        title: 'Import Quantity'
      },
      {
        backdrop: 'static',
        size: 'lg'
      }
    );
  }
}
