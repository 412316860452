import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BudgetAllocation } from '../../core/bean/budget-allocation';
import { BudgetType } from '../../core/bean/budget-type';
import { Coa } from '../../core/bean/coa';
import { Currency } from '../../core/bean/currency';
import { AppPopupBudgetWorkPlanInfoService } from '../../core/components/app-popup/app-popup-budget-work-plan-info/app-popup-budget-work-plan-info.service';
import { AppPopupImportService } from '../../core/components/app-popup/app-popup-import/app-popup-import.service';
import { ImportFileWebsocketService } from '../../core/components/app-popup/app-popup-import/import-file-websocket.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { BudgetAllocationByCoaView } from '../../core/view/entity/bean/budget-allocation-by-coa-view';
import { BudgetAllocationByOrganizationView } from '../../core/view/entity/bean/budget-allocation-by-organization-view';
import { Response } from './../../core/model/response-model';
import { RecapPrintService } from './../../core/services/recap-print.service';
import { AppPopupBudgetAllocationComponent } from './app-popup-budget-allocation.component';
import { AppPopupExportBudgetComponent } from './app-popup-export-budget-allocation.component';
import { BudgetAllocationDTO } from './dto/budget-allocation.dto';

@Component({
  templateUrl: './budget-allocation.component.html',
  styles: ['.display-hidden {  display: none;  }']
})
export class BudgetAllocationComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponseOrganization: TableResponseModel<BudgetAllocationByOrganizationView>;
  public tableResponseCoa: TableResponseModel<BudgetAllocationByCoaView>;
  public budgetAllocationDTO: BudgetAllocationDTO = new BudgetAllocationDTO();
  public isLoading = false;
  public isLoadingReport: boolean;
  public isLoadingSync: boolean;
  public budgetInfoFormGroup: FormGroup;
  public currencyCode: string;
  public budgetTypeOptionList: OptionListModel<BudgetType> =
    new OptionListModel();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public coaOptionList: OptionListModel<Coa> = new OptionListModel();
  public budgetAllocationOptionList: OptionListModel<BudgetAllocation> =
    new OptionListModel(false, 'activityPeriod');

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupImportService: AppPopupImportService,
    public appPopupBudgetWorkPlanInfoService: AppPopupBudgetWorkPlanInfoService,
    public recapPrintService: RecapPrintService,
    public importFileWebsocketService: ImportFileWebsocketService
  ) {
    super('budget-allocation', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildFormGroupBudgetInfo();
    this.buildTableResponseOrganization();
    this.buildTableResponseCoa();
    this.setResumeBudget();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      coaList: [null],
      budgetTypeList: [null],
      fromPeriod: [null],
      untilPeriod: [null],
      organizationList: [null],
      currency: [null]
    });
  }

  public buildFormGroupBudgetInfo(): void {
    this.budgetInfoFormGroup = this.formBuilder.group({
      totalBudgetResume: [null],
      bookedBudgetResume: [null],
      usedBudgetResume: [null],
      paidOffBudgetResume: [null],
      availableBudgetResume: [null]
    });
  }

  public setOptionModel(): void {
    this.budgetTypeOptionList.setRequestValues(
      this.budgetAllocationDTO.budgetTypeList
    );
    this.coaOptionList.setRequestValues(this.budgetAllocationDTO.coaList);
    this.currencyOptionList.setRequestValues(
      this.budgetAllocationDTO.currencyList
    );
    this.budgetAllocationOptionList.setRequestValues(
      this.budgetAllocationDTO.budgetAllocationList
    );
  }

  public setResumeBudget(): void {
    this.httpClientService
      .post<BudgetAllocationDTO>(
        '/budget-allocation/info',
        this.formGroup.value
      )
      .subscribe(budgetAllocationDTO => {
        this.budgetAllocationDTO = budgetAllocationDTO;
        this.budgetInfoFormGroup.patchValue({
          totalBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationDTO.totalBudgetResume.toString()
          ),
          bookedBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationDTO.bookedBudgetResume.toString()
          ),
          usedBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationDTO.usedBudgetResume.toString()
          ),
          paidOffBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationDTO.paidOffBudgetResume.toString()
          ),
          availableBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationDTO.availableBudgetResume.toString()
          )
        });
        this.isLoading = true;
        this.setOptionModel();
        this.setStateReady();
      });
  }
  public buildTableResponseOrganization(): void {
    this.tableResponseOrganization = new TableResponseModel(this.moduleCode, [
      { field: 'organization.name', header: 'table.column.organization' },
      {
        field: 'activityPeriod',
        header: 'table.column.period',
        customClass: 'text-center'
      },
      {
        field: 'totalBudget',
        header: 'table.column.totalBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'bookedBudget',
        header: 'table.column.bookedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'usedBudget',
        header: 'table.column.usedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'paidOffBudget',
        header: 'table.column.paidOffBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'availableBudget',
        header: 'table.column.availableBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      }
    ]);
  }

  public buildTableResponseCoa(): void {
    this.tableResponseCoa = new TableResponseModel(this.moduleCode, [
      {
        field: 'coa.code',
        header: 'table.column.coaCode',
        customClass: 'text-center',
        fn: this.handleHyperlink.bind(this)
      },
      { field: 'coa.name', header: 'table.column.coaName' },
      {
        field: 'activityPeriod',
        header: 'table.column.period',
        customClass: 'text-center'
      },
      {
        field: 'totalBudget',
        header: 'table.column.totalBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'bookedBudget',
        header: 'table.column.bookedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'usedBudget',
        header: 'table.column.usedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'paidOffBudget',
        header: 'table.column.paidOffBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'availableBudget',
        header: 'table.column.availableBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.set('todo', 'add');
    this.global.routerParams.set('backUrl', '/pages/budget-allocation');
    this.router.navigate(['/pages/budget-allocation/add']);
  }

  public doEdit(budgetAllocation: BudgetAllocation): void {
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('budgetId', budgetAllocation.id);
    this.global.routerParams.set('backUrl', '/pages/budget-allocation');
    this.router.navigate(['/pages/budget-allocation/edit']);
  }

  public doDetailOrganization(budgetAllocation: BudgetAllocation): void {
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set(
      'organizationId',
      budgetAllocation.organization.id
    );
    this.global.routerParams.set(
      'activityPeriod',
      budgetAllocation.activityPeriod
    );
    this.global.routerParams.set('backUrl', '/pages/budget-allocation');
    this.router.navigate([
      '/pages/budget-allocation/organization/detail/index'
    ]);
  }
  public doDetailCoa(budgetAllocation: BudgetAllocationByCoaView): void {
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('coaId', budgetAllocation.coa.id);
    this.global.routerParams.set('backUrl', '/pages/budget-allocation');
    this.router.navigate(['/pages/budget-allocation/coa/detail/index']);
  }

  public doDetailCoaOrganization(
    budgetAllocation: BudgetAllocationByCoaView
  ): void {
    const coaId = budgetAllocation.coa.id;
    this.appPopupService
      .open(AppPopupBudgetAllocationComponent, { coaId })
      .subscribe(() => {});
  }

  public handleHyperlink(value: any, record: any): void {
    this.log.debug(value);
    let aElement: any = record.coa.code;
    if (!record.coa.isHasChild) {
      aElement = document.createElement('a');
      aElement.style.color = 'var(--primary)';
      aElement.addEventListener('click', () => {
        this.doDetailCoaOrganization(record);
      });
      aElement.textContent = record.coa.code;
    }
    return aElement;
  }

  public doImport(): void {
    this.appPopupImportService
      .open('/budget-allocation/import-excel')
      .subscribe(result => {
        if (result) {
          this.tableResponseCoa.reload();
          this.tableResponseOrganization.reload();
        }
      });
    this.importFileWebsocketService.appPopupImportComponent =
      this.appPopupImportService.appPopupService.componentInstance;
  }

  public setBudgetInfo(): void {
    this.httpClientService
      .post<BudgetAllocationDTO>(
        '/budget-allocation/info',
        this.formGroup.value
      )
      .subscribe(budgetAllocationDTO => {
        this.budgetAllocationDTO = budgetAllocationDTO;
        this.budgetInfoFormGroup.patchValue({
          totalBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationDTO.totalBudgetResume.toString()
          ),
          bookedBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationDTO.bookedBudgetResume.toString()
          ),
          usedBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationDTO.usedBudgetResume.toString()
          ),
          paidOffBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationDTO.paidOffBudgetResume.toString()
          ),
          availableBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationDTO.availableBudgetResume.toString()
          )
        });
      });
  }

  public doApply(): void {
    this.global.routerParams.clear();
    if (this.formGroup.value.fromPeriod !== null) {
      this.formGroup.value.fromPeriod =
        this.formGroup.value.fromPeriod.activityPeriod;
    }
    if (this.formGroup.value.untilPeriod !== null) {
      this.formGroup.value.untilPeriod =
        this.formGroup.value.untilPeriod.activityPeriod;
    }
    this.tableResponseOrganization.setCustomData(this.formGroup.value);
    this.tableResponseCoa.setCustomData(this.formGroup.value);
    this.tableResponseOrganization.reload();
    this.tableResponseCoa.reload();
    this.setBudgetInfo();
    this.currencyCode =
      this.formGroup.value.currency === null
        ? null
        : this.formGroup.value.currency.code;
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponseOrganization.resetCustomData();
    this.tableResponseCoa.setCustomData(this.formGroup.value);
    this.tableResponseOrganization.reload();
    this.tableResponseCoa.reload();
    this.setBudgetInfo();
    this.currencyCode = null;
  }

  public doExport(): void {
    this.appPopupService
      .open(AppPopupExportBudgetComponent, null, {
        backdrop: true,
        size: null
      })
      .subscribe(data => {
        this.isLoadingReport = true;
        let url: string;
        if (data === 'projection') {
          url = '/budget-allocation/export-projection-budget';
        } else if (data === 'realization') {
          url = '/budget-allocation/export-realization-budget';
        }
        if (url) {
          this.recapPrintService
            .print(url, this.tableResponseOrganization.request)
            .subscribe(() => {
              this.isLoadingReport = false;
            });
        }
      });
  }

  public doSync(): void {
    this.isLoadingSync = true;
    this.httpClientService
      .post<Response<BudgetAllocationDTO>>(
        '/budget-allocation/bjb-gift-sync',
        null
      )
      .subscribe((response: Response<BudgetAllocationDTO>) => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccess(
            this.translateService.instant('budget-allocation.msg.success.sync')
          );
          this.tableResponseOrganization.reload();
          this.tableResponseCoa.reload();
        } else {
          this.global.alertService.showError(response.statusText);
        }
      });
  }
}
