import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { DashboardResponse } from '../../../../pages/dashboard/dashboard-response';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { WebContent } from '../../../bean/web-content';
import { Validators } from '../../../validators';
import { AppPopupChangePasswordService } from '../app-popup-change-password/app-popup-change-password.service';
import { Response } from './../../../model/response-model';
import { ResponseStatusModel } from './../../../model/response-status-model';
import { AuthenticationService } from './../../../services/authentication.service';
@Component({
  templateUrl: './app-popup-toc.component.html',
  styleUrls: ['./app-popup-toc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupTOCComponent extends BaseComponentComponent {
  readonly urlRouterNavigateToDashboard = '/dashboard';
  termsAndCondition: any;
  @Input() dashboardResponse: DashboardResponse;

  constructor(
    public activeModal: NgbActiveModal,
    public authenticationService: AuthenticationService,
    public sanitized: DomSanitizer,
    public appPopupChangePasswordService: AppPopupChangePasswordService
  ) {
    super('app-popup-toc');
  }

  onInit(): void {
    this.test();
    this.buildFormGroup();
    this.setStateReady();
  }

  test(): void {
    this.termsAndCondition = this.sanitized.bypassSecurityTrustHtml(
      this.dashboardResponse.contentVendor.value.toString()
    );
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      toc: [null],
      check: [
        null,
        Validators.isChecked(null, 'app-popup-toc.validation.isChecked')
      ]
    });
  }

  doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<WebContent>>('/dashboard/save-toc', {})
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.activeModal.dismiss();
                  if(this.dashboardResponse.contentChangePassword != null ){
                  this.showPopupUserChangePassword(this.dashboardResponse);
                }
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  private showPopupUserChangePassword(
    dashboardResponse: DashboardResponse
  ): void {
    this.appPopupChangePasswordService.open(dashboardResponse);
  }

  onClosePopup(): void {
    this.authenticationService.logout();
  }
}
