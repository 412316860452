import { createDatePickerDate } from '../helper/create-date-picker-date-helper';
import { createDatePickerDateList } from '../helper/create-date-picker-date-list-helper';
import { DatePickerState } from '../model';
export const setDatePickerMonth = (
  state: DatePickerState,
  payload: { month: number }
): void => {
  const date = new Date(state.currentDate.date);
  let month: number;
  if (payload.month === -1) {
    month = 11;
    date.setFullYear(state.currentDate.year - 1);
  } else if (payload.month === 12) {
    month = 0;
    date.setFullYear(state.currentDate.year + 1);
  } else {
    month = payload.month;
  }
  date.setMonth(month);
  const datePickerItem = createDatePickerDate(date);
  state.dateList = createDatePickerDateList(date);
  state.currentDate = datePickerItem;
  state.view = 'DATE';
};
