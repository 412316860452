import { BaseParticipantEntity } from '../base/base-participant-entity';
import { Aanwijzing } from '../bean/aanwijzing';
import { File } from '../bean/file';
import { Vendor } from '../bean/vendor';
import { VendorPIC } from '../bean/vendor-pic';

export class AanwijzingParticipantEntity extends BaseParticipantEntity {
  aanwijzing: Aanwijzing;
  vendor: Vendor;
  vendorPIC: VendorPIC;
  signatureImgFile: File;
  attendanceDate: Date;
  lastReminderDate: Date;
}
