import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BudgetAllocation } from '../../../bean/budget-allocation';
import { AppPopupService } from '../app-popup.service';
import { AppPopupBillingMemoBudgetDetailComponent } from './app-popup-billing-memo-budget-detail.component';

@Injectable()
export class AppPopupBillingMemoBudgetDetailService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    budgetAllocation?: BudgetAllocation
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupBillingMemoBudgetDetailComponent,
      {
        budgetAllocation
      },
      { size: 'lg' }
    );
  }
}
