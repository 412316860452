import { TransactionLog } from '../../core/bean/transaction-log';
import { Vendor } from '../../core/bean/vendor';
import { VendorHistory } from '../../core/bean/vendor-history';
import { VendorReactivation } from '../../core/bean/vendor-reactivation';
export class VendorReactivationResponse {
  vendor: Vendor = new Vendor();
  vendorReactivation: VendorReactivation = new VendorReactivation();
  vendorHistory: VendorHistory = new VendorHistory();
  approvalNote: string;
  isUserApproval: boolean;
  transactionLog: TransactionLog = new TransactionLog();
}
