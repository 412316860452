import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { News } from '../../core/bean/news';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './news.component.html',
  styles: [
    '.width-org-name { min-width : 250px; white-space: normal ! important; text-align: center }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class NewsComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<News>;
  constructor(translateService: TranslateService) {
    super('news', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'title',
        header: 'table.column.title',
        customClass: 'white-space-normal'
      },
      {
        field: 'organization.name',
        header: 'table.column.department',
        customClass: 'width-org-name'
      },
      {
        field: 'createdDate',
        header: 'table.column.createdDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'publishDate',
        header: 'table.column.publishDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'isActive',
        header: 'table.column.isActive',
        customClass: 'text-center',
        datamap:
          '{"false" : "' +
          this.translateService.instant('news.notActive') +
          '" , "true" : "' +
          this.translateService.instant('news.active') +
          '" }'
      },
      {
        field: 'viewCount',
        header: 'table.column.viewCount',
        customClass: 'text-center'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/news/add']);
  }

  public doEdit(news: News): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('newsId', news.id);
    this.router.navigate(['/pages/news/edit']);
  }
}
