import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AppItemRequestInitiationCatalogModel } from 'src/app/core/components/app-item-request/app-item-request-initiation-catalog-model';


@Component({
  templateUrl: './initiation-vendor-detail.component.html'
})
export class InitiationVendorDetailComponent
  extends BaseModuleComponent
  implements OnInit
{
  public prResponse: AppItemRequestInitiationCatalogModel = new AppItemRequestInitiationCatalogModel();
  public vendorId: number;
  constructor(translateService: TranslateService) {
    super('initiation', translateService);
  }
  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorId = this.global.routerParams.get('vendorId');
    this.prResponse = this.global.routerParams.get('prResponse');
  }
}
