import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppBadgeModule } from '../../components/app-badge/app-badge.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetTotalSlaComponent } from './app-widget-total-sla.component';
@NgModule({
  imports: [
    VendorModule,
    AppWidgetModule,
    AppWidgetContentSliderModule,
    AppBadgeModule
  ],
  declarations: [AppWidgetTotalSlaComponent],
  entryComponents: [AppWidgetTotalSlaComponent],
  exports: [AppWidgetTotalSlaComponent]
})
export class AppWidgetTotalSlaModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-total-sla', AppWidgetTotalSlaComponent);
  }
}
