import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { VendorHistory } from '../../core/bean/vendor-history';
import { VendorPerformance } from '../../core/bean/vendor-performance';
import { VendorPerformanceOverallDetail } from '../../core/bean/vendor-performance-overall-detail';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
@Component({
  templateUrl: './all-performance-detail.component.html'
})
export class AllPerformanceDetailComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<VendorPerformance>;
  public vendorPerformanceOverallId: number;
  public vendor: Vendor = new Vendor();
  public vendorHistoryId: number;
  public vendorPerformanceOverallDetailList: VendorPerformanceOverallDetail[] = [];
  public rating = 0;
  public monthList: string[] = [];
  public header = 'all-performance.titleDetail';

  constructor(translateService: TranslateService) {
    super('all-performance', translateService);
  }

  public onInit(): void {
    this.setList();
    this.buildFormGroup();
    this.setDataFromRouterParam();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      rating: [null]
    });
  }

  public setDataFromRouterParam(): void {
    this.vendorPerformanceOverallId = this.global.routerParams.get('vendorPerformanceOverallId');
    this.log.debug('vendorPerformanceOverallId ' + this.vendorPerformanceOverallId);
    this.log.debug(this.global.routerParams);
  }

  public setList(): void {
    this.monthList = [
      this.translateService.instant('all-performance.month.january'),
      this.translateService.instant('all-performance.month.february'),
      this.translateService.instant('all-performance.month.march'),
      this.translateService.instant('all-performance.month.april'),
      this.translateService.instant('all-performance.month.may'),
      this.translateService.instant('all-performance.month.june'),
      this.translateService.instant('all-performance.month.july'),
      this.translateService.instant('all-performance.month.august'),
      this.translateService.instant('all-performance.month.september'),
      this.translateService.instant('all-performance.month.october'),
      this.translateService.instant('all-performance.month.november'),
      this.translateService.instant('all-performance.month.december')
    ];
  }

  public setFormGroup(): void {
    this.httpClientService.get<VendorPerformanceOverallDetail[]>
    ('/all-performance/get-overall-detail-list/' + this.vendorPerformanceOverallId)
      .subscribe((list: VendorPerformanceOverallDetail[]) => {
        const vendorPerformanceOverall = list[0].vendorPerformanceOverall;

        this.header = (vendorPerformanceOverall.startYear === vendorPerformanceOverall.endYear) ?
        this.translateService.instant('all-performance.titlePeriod') + ' ' + this.monthList[+vendorPerformanceOverall.startMonth - 1]
        + ' - ' + this.monthList[+vendorPerformanceOverall.endMonth - 1] + ' ' + vendorPerformanceOverall.endYear :
        this.translateService.instant('all-performance.titlePeriod') + ' ' + this.monthList[+vendorPerformanceOverall.startMonth - 1]
        + ' ' + vendorPerformanceOverall.startYear
        + ' - ' + this.monthList[+vendorPerformanceOverall.endMonth - 1] + ' ' + vendorPerformanceOverall.endYear;

        this.vendorPerformanceOverallDetailList = list;
        this.vendor = vendorPerformanceOverall.vendor;
        this.rating = vendorPerformanceOverall.rating;
        this.formGroup.patchValue({
          rating: this.rating
        });
        this.setViewOnly();
        this.setStateReady();
      });
  }

  public doView(vendorPerformance: VendorPerformance): void {
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorPerformanceId', vendorPerformance.id);
    this.global.routerParams.set('vendorPerformanceOverallId', this.vendorPerformanceOverallId);
    this.router.navigate(['/pages/all-performance/detail/detail-result']);
  }

  public buildTableResponse(): void {

    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.performanceCode', customClass: 'text-center' },
      { field: 'createdDate', header: 'table.column.performanceDate', format: FieldFormatEnum.ShortDate },
      { field: 'moduleScoringCriteria.name', header: 'table.column.module', customClass: 'text-center' },
      { field: 'rating', header: 'table.column.rating', format: FieldFormatEnum.Rating, customClass: 'text-center' }
    ]);

    this.tableResponse.setCustomData(this.vendorPerformanceOverallId.toString());
  }

  public doViewProfile(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorId', this.vendor.id);
    this.global.routerParams.set('urlBackOutside', '/pages/all-performance/detail');
    this.global.routerParams.set('vendorPerformanceOverallId', this.vendorPerformanceOverallId);
    this.router.navigate(['/pages/company-profile']);
  }

  public doGetVendorHistory(vendorId: number): void {
    this.httpClientService.post<VendorHistory>('/all-performance/get-last-vendor-history', new RouteRequestModel('view', vendorId))
      .subscribe(vendorHistory => {
        this.vendorHistoryId = vendorHistory.id;
      });
  }

  public doBack(): void {
    this.router.navigate(['/pages/all-performance']);
  }

}
