import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupPartnershipComponent } from './popup/app-popup-partnership.component';
import { TabProfilePartnershipComponent } from './tab-profile-partnership.component';

export const routes = [
  {
    path: '',
    component: TabProfilePartnershipComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    TabProfilePartnershipComponent,
    AppPopupPartnershipComponent
  ],
  entryComponents: [
    AppPopupPartnershipComponent
  ],
  exports: [
    TabProfilePartnershipComponent,
    AppPopupPartnershipComponent
  ]
})

export class TabProfilePartnershipModule {}
