import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Module } from '../../core/bean/module';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
import { WorkflowAdditional } from './../../core/bean/workflow-additional';
import { WorkflowModel } from './../../core/bean/workflow-model';
@Component({
  templateUrl: './workflow-additional-popup.component.html'
})
export class WorkflowAdditionalPopupComponent extends BaseModuleComponent {
  @Input() public workflowAdditional: WorkflowAdditional;
  @Input() public sourceModuleList: Module[];
  @Input() public targetModuleList: Module[];
  @Output() public onChange: EventEmitter<FormGroup> = new EventEmitter();
  public sourceModuleOptionList: OptionListModel<Module> =
    new OptionListModel();
  public targetModuleOptionList: OptionListModel<Module> =
    new OptionListModel();
  public targetModuleTempOptionList: OptionListModel<Module> =
    new OptionListModel();
  constructor(translateService: TranslateService) {
    super('workflow', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doSetOptionListRequestValues();
    this.doSetFormGroup();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      workflowModel: [null],
      module: [null, Validators.compose([Validators.required()])],
      afterModule: [null, Validators.compose([Validators.required()])],
      beforeModule: [null, Validators.compose([Validators.required()])],
      isMandatory: [false]
    });
  }

  public doSetOptionListRequestValues(): void {
    this.sourceModuleOptionList.setRequestValues(this.sourceModuleList);
    this.targetModuleOptionList.setRequestValues(this.targetModuleList);
  }

  public doSetFormGroup(): void {
    if (this.workflowAdditional != null) {
      const indexModule = this.sourceModuleList.findIndex(
        module => module.id === this.workflowAdditional.workflowModel.module.id
      );
      const indexAfterModule = this.targetModuleList.findIndex(
        module => module.id === this.workflowAdditional.afterModule.id
      );
      const indexBeforeModule = this.targetModuleList.findIndex(
        module => module.id === this.workflowAdditional.beforeModule.id
      );
      this.doSetTargetModuleTempOptionList(this.workflowAdditional.afterModule);
      this.formGroup.patchValue({
        id: this.workflowAdditional.id,
        workflowModel: this.workflowAdditional.workflowModel,
        module: this.sourceModuleList[indexModule],
        afterModule: this.targetModuleList[indexAfterModule],
        beforeModule: this.targetModuleList[indexBeforeModule],
        isMandatory: this.workflowAdditional.isMandatory
      });
    }
  }

  public doOnChangeAfterModule(): void {
    this.formGroup.get('beforeModule').reset();
    this.doSetTargetModuleTempOptionList(
      this.formGroup.get('afterModule').value
    );
  }

  public doSetTargetModuleTempOptionList(afterModule: Module): void {
    const indexAfter = this.targetModuleList.findIndex(
      module => module.id === afterModule.id
    );
    const targetModuleTempList: Array<Module> = new Array();
    this.targetModuleList.forEach((module, index) => {
      if (index > indexAfter) {
        targetModuleTempList.push(module);
      }
    });
    this.targetModuleTempOptionList.setRequestValues(targetModuleTempList);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const newWorkflowModel: WorkflowModel = new WorkflowModel();
            if (this.formGroup.get('id').value === null) {
              newWorkflowModel.id = null;
            }
            newWorkflowModel.module = this.formGroup.get('module').value;
            this.formGroup.patchValue({ workflowModel: newWorkflowModel });
            this.onChange.emit(this.formGroup);
          }
        });
    }
  }
}
