import { BaseEntity } from '../base/base-entity';
import { Module } from '../bean/module';
import { ModuleGroup } from '../bean/module-group';

export class WorkflowEntity extends BaseEntity {
    moduleGroup: ModuleGroup;
    module: Module;
    name: string;
    description: string;
    slaUpdatedDate: Date;
    totalSlaInDays: number;
}
