import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedModule } from '../core/shared/shared.module';
import { PromiseThemeModule } from '../themes/promise/promise-theme.module';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
@NgModule({
  imports: [
    PerfectScrollbarModule,
    PagesRoutingModule,
    SharedModule,
    PromiseThemeModule
  ],
  declarations: [PagesComponent]
})
export class PagesModule {}
