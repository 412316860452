import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { TransactionType } from 'src/app/core/bean/transaction-type';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { CertificateStatus } from '../../core/bean/certificate-status';
import { ProcMethodVActStatus } from '../../core/bean/proc-method-v-act-status';
import { ProcMethodVCertStatus } from '../../core/bean/proc-method-v-cert-status';
import { ProcMethodVStatus } from '../../core/bean/proc-method-v-status';
import { ProcurementMethod } from '../../core/bean/procurement-method';
import { ProcurementRegType } from '../../core/bean/procurement-reg-type';
import { Translation } from '../../core/bean/translation';
import { VendorActivityStatus } from '../../core/bean/vendor-activity-status';
import { VendorStatus } from '../../core/bean/vendor-status';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { ProcurementMethodRequest } from './procurement-method-request';
import { ProcurementMethodResponse } from './procurement-method-response';
@Component({
  templateUrl: './procurement-method-edit-add.component.html'
})
export class ProcurementMethodEditAddComponent extends BaseModuleComponent {
  private DELETE = 0;
  private UPDATE = 1;
  private INSERT = 2;
  public procurementMethodId: number;
  public langList = [];
  public translationListLabel = [];
  public transactionTypeOptionList: OptionListModel<TransactionType> =
    new OptionListModel();
  public registrationTypeOptionList: OptionListModel<ProcurementRegType> =
    new OptionListModel();
  public vStatusOptionList: OptionListModel<VendorStatus> =
    new OptionListModel();
  public vActStatusOptionList: OptionListModel<VendorActivityStatus> =
    new OptionListModel();
  public vCertStatusOptionList: OptionListModel<CertificateStatus> =
    new OptionListModel();
  public procurementMethodRequest: ProcurementMethodRequest =
    new ProcurementMethodRequest();
  public procurementMethodResponse: ProcurementMethodResponse =
    new ProcurementMethodResponse();
  constructor(translateService: TranslateService) {
    super('procurement-method', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procurementMethodId = this.global.routerParams.get(
      'procurementMethodId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      langList: this.formBuilder.array([]),
      translationList: this.formBuilder.array([]),
      translationKey: [null],
      transactionType: [null, Validators.required()],
      procurementRegType: [null, Validators.required()],
      vStatusList: [null, Validators.required()],
      vActStatusList: [null, Validators.required()],
      vCertStatusList: [null, Validators.required()],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ProcurementMethod>(
        '/procurement-method/add-edit',
        new RouteRequestModel(this.todo, this.procurementMethodId)
      )
      .subscribe((procurementMethodResponse: ProcurementMethodResponse) => {
        this.procurementMethodResponse = procurementMethodResponse;
        this.transactionTypeOptionList.setRequestValues(
          procurementMethodResponse.procurementTransactionTypeOptionList
        );
        this.registrationTypeOptionList.setRequestValues(
          procurementMethodResponse.procurementRegTypeOptionList
        );
        this.vStatusOptionList.setRequestValues(
          this.procurementMethodResponse.vStatusOptionList
        );
        this.vActStatusOptionList.setRequestValues(
          this.procurementMethodResponse.vActStatusOptionList
        );
        this.vCertStatusOptionList.setRequestValues(
          this.procurementMethodResponse.vCertStatusOptionList
        );

        this.langList = procurementMethodResponse.langList;
        this.setTranslation(procurementMethodResponse);
        const procurementMethod: ProcurementMethod =
          procurementMethodResponse.procurementMethod;
        if (procurementMethod != null) {
          const {
            id,
            code,
            description,
            translationKey,
            transactionType,
            procurementRegType
          } = procurementMethod;

          this.formGroup.patchValue({
            id,
            code,
            description,
            translationKey,
            transactionType,
            procurementRegType
          });
          const vStatusList: VendorStatus[] =
            procurementMethodResponse.procMethodVStatusList.map(
              data => data.vendorStatus
            );
          const vActStatusList: VendorActivityStatus[] =
            procurementMethodResponse.procMethodVActStatusList.map(
              data => data.vendorActivityStatus
            );
          const vCertStatusList: CertificateStatus[] =
            procurementMethodResponse.procMethodVCertStatusList.map(
              data => data.certificateStatus
            );

          this.formGroup.patchValue({
            vActStatusList,
            vStatusList,
            vCertStatusList
          });
        }
        this.setStateReady();
      });
  }

  public setTranslation(
    procurementMethodResponse: ProcurementMethodResponse
  ): void {
    if (procurementMethodResponse.translationList.length > 0) {
      procurementMethodResponse.translationList.forEach(translation => {
        this.translationListLabel.push(translation.lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: translation.value
          })
        );
      });
    } else {
      procurementMethodResponse.langList.forEach(lang => {
        this.translationListLabel.push(lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: [null, Validators.required()]
          })
        );
      });
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const url =
              this.todo === 'edit'
                ? '/procurement-method/update'
                : '/procurement-method/insert';
            const procurementMethod: ProcurementMethod =
              this.global.copyFormAttributeToModel(
                new ProcurementMethod(),
                this.formGroup
              );
            this.procurementMethodRequest =
              this.setProcurementMethodRequest(procurementMethod);
            this.httpClientService
              .post<Response<ProcurementMethodRequest>>(
                url,
                this.procurementMethodRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/procurement-method']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public setProcurementMethodRequest(
    procurementMethod: ProcurementMethod
  ): ProcurementMethodRequest {
    const procurementMethodRequest: ProcurementMethodRequest =
      new ProcurementMethodRequest();
    procurementMethodRequest.procurementMethod = procurementMethod;
    procurementMethodRequest.translation = this.formGroup
      .get('translationList')
      .value.map((a: Translation) => a.value);

    const vendorStatusList: VendorStatus[] =
      this.formGroup.get('vStatusList').value;
    const vendorActStatusList: VendorActivityStatus[] =
      this.formGroup.get('vActStatusList').value;
    const certStatusList: CertificateStatus[] =
      this.formGroup.get('vCertStatusList').value;

    const procMethodVActStatusList: ProcMethodVActStatus[] = [];
    const procMethodVStatusList: ProcMethodVStatus[] = [];
    const procMethodVCertStatusList: ProcMethodVCertStatus[] = [];

    if (procurementMethod.id) {
      vendorStatusList.forEach(vendorStatus => {
        let procMethodVStatus: ProcMethodVStatus =
          this.procurementMethodResponse.procMethodVStatusList.find(
            procMethodVStat => procMethodVStat.vendorStatus === vendorStatus
          );
        if (procMethodVStatus) {
          procMethodVStatus.crudOperation = this.UPDATE;
          procMethodVStatusList.push(procMethodVStatus);
        } else {
          procMethodVStatus = new ProcMethodVStatus();
          procMethodVStatus.crudOperation = this.INSERT;
          procMethodVStatus.vendorStatus = vendorStatus;
          procMethodVStatusList.push(procMethodVStatus);
        }
      });

      this.procurementMethodResponse.procMethodVStatusList.forEach(
        procMethodVStatus => {
          const vendorStatus: VendorStatus = vendorStatusList.find(
            vs => vs === procMethodVStatus.vendorStatus
          );
          if (!vendorStatus) {
            procMethodVStatus.crudOperation = this.DELETE;
            procMethodVStatusList.push(procMethodVStatus);
          }
        }
      );

      vendorActStatusList.forEach(vendorActStatus => {
        let procMethodVActStatus: ProcMethodVActStatus =
          this.procurementMethodResponse.procMethodVActStatusList.find(
            procMethodVActStat =>
              procMethodVActStat.vendorActivityStatus === vendorActStatus
          );
        if (procMethodVActStatus) {
          procMethodVActStatus.crudOperation = this.UPDATE;
          procMethodVActStatusList.push(procMethodVActStatus);
        } else {
          procMethodVActStatus = new ProcMethodVActStatus();
          procMethodVActStatus.crudOperation = this.INSERT;
          procMethodVActStatus.vendorActivityStatus = vendorActStatus;
          procMethodVActStatusList.push(procMethodVActStatus);
        }
      });

      this.procurementMethodResponse.procMethodVActStatusList.forEach(
        procMethodVActStatus => {
          const vendorActStatus: VendorActivityStatus =
            vendorActStatusList.find(
              vas => vas === procMethodVActStatus.vendorActivityStatus
            );
          if (!vendorActStatus) {
            procMethodVActStatus.crudOperation = this.DELETE;
            procMethodVActStatusList.push(procMethodVActStatus);
          }
        }
      );

      certStatusList.forEach(certStatus => {
        let procMethodVCertStatus: ProcMethodVCertStatus =
          this.procurementMethodResponse.procMethodVCertStatusList.find(
            procMethodVCertStat =>
              procMethodVCertStat.certificateStatus === certStatus
          );
        if (procMethodVCertStatus) {
          procMethodVCertStatus.crudOperation = this.UPDATE;
          procMethodVCertStatusList.push(procMethodVCertStatus);
        } else {
          procMethodVCertStatus = new ProcMethodVCertStatus();
          procMethodVCertStatus.crudOperation = this.INSERT;
          procMethodVCertStatus.certificateStatus = certStatus;
          procMethodVCertStatusList.push(procMethodVCertStatus);
        }
      });

      this.procurementMethodResponse.procMethodVCertStatusList.forEach(
        procMethodVCertStatus => {
          const certStatus: CertificateStatus = certStatusList.find(
            cs => cs === procMethodVCertStatus.certificateStatus
          );
          if (!certStatus) {
            procMethodVCertStatus.crudOperation = this.DELETE;
            procMethodVCertStatusList.push(procMethodVCertStatus);
          }
        }
      );
    } else {
      vendorStatusList.forEach(vendorStatus => {
        const procMethodVStatus: ProcMethodVStatus = new ProcMethodVStatus();
        procMethodVStatus.vendorStatus = vendorStatus;
        procMethodVStatusList.push(procMethodVStatus);
      });
      vendorActStatusList.forEach(vendorActStatus => {
        const procMethodVActStatus: ProcMethodVActStatus =
          new ProcMethodVActStatus();
        procMethodVActStatus.vendorActivityStatus = vendorActStatus;
        procMethodVActStatusList.push(procMethodVActStatus);
      });
      certStatusList.forEach(certStatus => {
        const procMethodVCertStatus: ProcMethodVCertStatus =
          new ProcMethodVCertStatus();
        procMethodVCertStatus.certificateStatus = certStatus;
        procMethodVCertStatusList.push(procMethodVCertStatus);
      });
    }

    procurementMethodRequest.procMethodVStatusList = procMethodVStatusList;
    procurementMethodRequest.procMethodVActStatusList =
      procMethodVActStatusList;
    procurementMethodRequest.procMethodVCertStatusList =
      procMethodVCertStatusList;
    return procurementMethodRequest;
  }
  public doCancel(): void {
    this.router.navigate(['/pages/procurement-method']);
  }

  public get translationList(): FormArray {
    return this.formGroup.get('translationList') as FormArray;
  }

  public translateTransactionType(transactionType: TransactionType): string {
    let translateTransactionType: string = null;
    if (transactionType) {
      translateTransactionType = this.global.translateService.instant(
        transactionType.translationKey.module.code.toLowerCase() +
          '.' +
          transactionType.translationKey.key
      );
    }
    return translateTransactionType;
  }

  public translateRegType(regType: ProcurementRegType): string {
    let translateRegType: string = null;
    if (regType) {
      translateRegType = this.global.translateService.instant(
        regType.translationKey.module.code.toLowerCase() +
          '.' +
          regType.translationKey.key
      );
    }
    return translateRegType;
  }
}
