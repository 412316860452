import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupPreviewImageComponent } from './app-popup-preview-image.component';
@NgModule({
  imports: [VendorModule, AppPopupModule],
  declarations: [AppPopupPreviewImageComponent],
  entryComponents: [AppPopupPreviewImageComponent],
  exports: [AppPopupPreviewImageComponent]
})
export class AppPopupPreviewImageModule {}
