import { TeamProject } from '../../core/bean/team-project';
import { WorkPlan } from '../../core/bean/work-plan';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path/model/approval-path-response-model';

export class ProjectTeamSubmissionResponse {
  teamProjectList: TeamProject[];
  workPlan: WorkPlan;
  isUserApproval: boolean;
  approvalPathResponseModel: ApprovalPathResponseModel;
}
