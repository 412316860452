<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="vendor-blacklist.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-table [tableResponse]="tableResponse" [isServerSide]="true"
        stringUrl="/vendor-blacklist/index">
        <ng-template #headerButtons>
          <app-button (onClick)="doAdd()"><em class="fas fa-plus"></em> {{
            'app.button.add' | translate }}</app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            *ngIf="rowData.vendorActivityStatus.code == 'REVISION_VENDOR_BLACKLIST'"
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}" size="TN">
            <em class="fas fa-pencil-alt"></em>
          </app-button>
          <app-button
            *ngIf="rowData.vendorActivityStatus.code != 'REVISION_VENDOR_BLACKLIST'"
            (onClick)="doDetail(rowData)"
            title="{{ 'app.tooltip.view' | translate }}" size="TN"
            mode="BYPASS">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>