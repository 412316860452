import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorType } from '../../core/bean/vendor-type';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './vendor-attribute.component.html'
})
export class VendorAttributeComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<VendorType>;
  @ViewChild(AppTableComponent) table: AppTableComponent;
  constructor(translateService: TranslateService) {
    super('vendor-attribute', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: 'name',
        header: 'table.column.vendorType'
      }
    ]);
  }

  public doEdit(vendorType: VendorType): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('vendorType', vendorType);
    this.router.navigate(['/pages/vendor-attribute/edit']);
  }
}
