import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppOfficialReportModule } from 'src/app/core/components/app-official-report/app-official-report.module';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AdministrationEvaluationEditComponent } from './administration-evaluation-edit.component';
import { AdministrationEvaluationPopupDetailComponent } from './administration-evaluation-popup-detail.component';
const routes = [
  {
    path: 'add',
    component: AdministrationEvaluationEditComponent,
    data: { breadcrumb: 'administration-evaluation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: AdministrationEvaluationEditComponent,
    data: { breadcrumb: 'administration-evaluation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: AdministrationEvaluationEditComponent,
    data: { breadcrumb: 'administration-evaluation.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppWorkflowStepInfoModule,
    AppProcurementInfoModule,
    AppItemRequestModule,
    AppApprovalPrcsXModule,
    AppOfficialReportModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    AdministrationEvaluationEditComponent,
    AdministrationEvaluationPopupDetailComponent
  ],
  exports: [AdministrationEvaluationPopupDetailComponent],
  entryComponents: [AdministrationEvaluationPopupDetailComponent]
})
export class AdministrationEvaluationModule {}
