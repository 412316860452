import { BaseDTO } from '../../../core/base/base-dto';
import { Vendor } from '../../../core/bean/vendor';
import { VendorHistory } from '../../../core/bean/vendor-history';
import { VendorTab } from '../../../core/bean/vendor-tab';
import { TabProfileBankDTO } from '../../tab-profile-bank/dto/tab-profile-bank.dto';
import { TabProfileCompanyDataDTO } from '../../tab-profile-company-data/dto/tab-profile-company-data.dto';
import { TabProfileCompanyOrganizationDTO } from '../../tab-profile-company-organization/dto/tab-profile-company-organization.dto';
import { TabProfileDocumentDTO } from '../../tab-profile-document/dto/tab-profile-document.dto';
import { TabProfileExperienceDTO } from '../../tab-profile-experience/dto/tab-profile-experience.dto';
import { TabProfileExpertAndEquipmentDTO } from '../../tab-profile-expert-and-equipment/dto/tab-profile-expert-and-equipment.dto';
import { TabProfileFinancialStatementDTO } from '../../tab-profile-financial-statement/dto/tab-profile-financial-statement.dto';
import { TabProfileGeneralInformationDTO } from '../../tab-profile-general-information/dto/tab-profile-general-information.dto';
import { TabProfileMarketingDTO } from '../../tab-profile-marketing/dto/tab-profile-marketing.dto';
import { TabProfilePartnershipDTO } from '../../tab-profile-partnership/dto/tab-profile-partnership.dto';
import { TabProfileRepAgentDTO } from '../../tab-profile-rep-agent/dto/tab-profile-rep-agent.dto';
import { TabProfileSegmentationDTO } from '../../tab-profile-segmentation/dto/tab-profile-segmentation.dto';

export class CompanyProfileDTO extends BaseDTO {
  public vendor: Vendor;
  public vendorHistory: VendorHistory;
  public vendorTabList: VendorTab[];

  public tabProfileCompanyDataDTO: TabProfileCompanyDataDTO;
  public tabProfileCompanyOrganizationDTO: TabProfileCompanyOrganizationDTO;
  public tabProfileBankDTO: TabProfileBankDTO;
  public tabProfileSegmentationDTO: TabProfileSegmentationDTO;
  public tabProfileExpertAndEquipmentDTO: TabProfileExpertAndEquipmentDTO;
  public tabProfileExperienceDTO: TabProfileExperienceDTO;
  public tabProfilePartnershipDTO: TabProfilePartnershipDTO;
  public tabProfileFinancialStatementDTO: TabProfileFinancialStatementDTO;
  public tabProfileMarketingDTO: TabProfileMarketingDTO;
  public tabProfileRepAgentDTO: TabProfileRepAgentDTO;
  public tabProfileGeneralInformationDTO: TabProfileGeneralInformationDTO;
  public tabProfileDocumentDTO: TabProfileDocumentDTO;

  public mandatoryDocSIUJK: string;
  public mandatoryDocFCIUPJTK: string[];
  public isSIUJKMandatory: boolean;
  public isFCIUPJTKMandatory: boolean;
}
