<div class="row">
    <div class="col-12">
        <app-card>
          <app-tab [model]="tabResponse" (onChange)="doOnChangeTab($event)">
            <div class="row">
                <div class="col-12">
                    <router-outlet></router-outlet>
                </div>
            </div>
          </app-tab>
        </app-card>
    </div>
</div>
  