import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { ContractType } from 'src/app/core/bean/contract-type';
import { DealingAgreementType } from 'src/app/core/bean/dealing-agreement-type';
import { FinesMaster } from 'src/app/core/bean/fines-master';
import { GuaranteeBondType } from 'src/app/core/bean/guarantee-bond-type';
import { ProcurementFines } from 'src/app/core/bean/procurement-fines';
import { ProcurementGuarantee } from 'src/app/core/bean/procurement-guarantee';
import { ProcurementSow } from 'src/app/core/bean/procurement-sow';
import { StageOfWork } from 'src/app/core/bean/stage-of-work';
import { WorkflowModelPrcs } from 'src/app/core/bean/workflow-model-prcs';
import { WorkflowPrcs } from 'src/app/core/bean/workflow-prcs';
import { AppPopupProcurementFinesService } from 'src/app/core/components/app-popup/app-popup-procurement-fines/app-popup-procurement-fines.service';
import { AppPopupProcurementGuaranteeService } from 'src/app/core/components/app-popup/app-popup-procurement-guarantee/app-popup-procurement-guarantee.service';
import { AppPopupProcurementScopeWorkService } from 'src/app/core/components/app-popup/app-popup-procurement-scope-work/app-popup-procurement-scope-work.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { AppPopupContractRequestChooseContractComponent } from './app-popup-contract-request-choose-contract.component';
import { AppPopupContractRequestChooseProcurementComponent } from './app-popup-contract-request-choose-procurement.component';
import { ContractRequestRequest } from './contract-request.request';
import { ContractRequestResponse } from './contract-request.response';

@Component({
  templateUrl: './contract-request-edit-add.component.html',
  selector: 'app-contract-request-edit-add-component',
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class ContractRequestEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  @ViewChild('selectorStageOfWork') tableStageOfWork: AppTableComponent;
  @ViewChild('selectorGuarantee') tableGuarantee: AppTableComponent;
  @ViewChild('selectorFines') tableFines: AppTableComponent;

  public contractId: number;
  public objectId: number;
  public isSave = false;
  public isSaveDraft = false;
  public isChangeContractType = true;
  public isLoadingProcData: boolean;
  public isLoading: boolean;
  public formGroupCanceled: FormGroup;
  public contractRequestResponse: ContractRequestResponse =
    new ContractRequestResponse();
  public contractRequestRequest: ContractRequestRequest =
    new ContractRequestRequest();
  public contractTypeOptionList: OptionListModel<ContractType> =
    new OptionListModel(false);
  public dealingAgreementTypeOptionList: OptionListModel<DealingAgreementType> =
    new OptionListModel(false);
  public formGroupProcurement: FormGroup;
  public tableResponseStageOfWork: TableResponseModel<ProcurementSow>;
  public tableResponseGuarantee: TableResponseModel<ProcurementGuarantee>;
  public tableResponseFines: TableResponseModel<ProcurementFines>;
  public fileUploader: FileUploader = new FileUploader(
    '/contract-request/',
    'contract-request.title',
    this.global.appConstant.fileType.DOC_CONTRACT_CANCELED
  );
  public workflowPrcs: WorkflowPrcs = new WorkflowPrcs();
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public isCanceled = false;
  public totalAmountSow: number;
  public availableAmountSow: number;
  public procurementVendorId: number;
  public stageOfWorkList: StageOfWork[] = [];
  public guaranteeList: GuaranteeBondType[] = [];
  public finesList: FinesMaster[] = [];
  public isWorkflowProgress: boolean;
  public contract: Contract = new Contract();
  public urlBack: string;
  public workflowModelPrcsId: number;
  public dealingAgreementTypeOld: DealingAgreementType;
  public CURRENCY_DIGITS_INFO: string;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupProcurementScopeWorkService: AppPopupProcurementScopeWorkService,
    public appPopupProcurementFinesService: AppPopupProcurementFinesService,
    public appPopupProcurementGuaranteeService: AppPopupProcurementGuaranteeService,
    public currency: CurrencyPipe
  ) {
    super('contract-request', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public setDataFromRouterParams(): void {
    const todoLocalStorage = localStorage.getItem('todo');
    if (todoLocalStorage) {
      this.todo = todoLocalStorage;
    } else {
      this.todo = this.global.routerParams.get('todo');
    }
    this.contractId = this.global.routerParams.get('contractId');
    this.objectId = this.global.routerParams.get('objectId');
    this.isCanceled = this.global.routerParams.get('isCanceled');
    this.isWorkflowProgress =
      this.global.routerParams.get('isWorkflowProgress');
    if (this.isWorkflowProgress) {
      this.contract = this.global.routerParams.get('contract');
    }
    this.urlBack = this.global.routerParams.get('urlBackOutside');
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
      if (this.isCanceled) {
        this.formGroupCanceled.setIsView(true);
      }
    } else if (this.todo === 'cancel') {
      this.setViewOnly();
      this.formGroup.setIsView(true);
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      contractType: [null, Validators.required()],
      dealingAgreementType: [null, Validators.required()],
      contractTypeId: [null],
      contract: [null],
      requestNumber: [null],
      number: [null],
      procurementVendor: [null],
      procurementSowList: this.formBuilder.array([])
    });

    if (this.todo === 'cancel' || this.isCanceled) {
      this.formGroupCanceled = this.formBuilder.group({
        fileDoc: [null, Validators.required()],
        note: [null, Validators.required()]
      });
    }
    this.formGroupProcurement = this.formBuilder.group({
      isRepeatOrder: [null],
      contractId: [null]
    });
  }
  public setFormGroup(): void {
    this.httpClientService
      .post<ContractRequestResponse>(
        '/contract-request/add-edit',
        new RouteRequestModel(
          this.todo,
          this.objectId ? this.objectId : this.contractId
        )
      )
      .subscribe((contractRequestResponse: ContractRequestResponse) => {
        let data = localStorage.getItem('temp') as Object;
        if (data) {
          data = JSON.parse(data.toString());
          this.contractRequestResponse = data as ContractRequestResponse;
          localStorage.removeItem('temp');
          localStorage.removeItem('todo');
        } else {
          this.contractRequestResponse = contractRequestResponse;
        }
        this.stageOfWorkList = this.contractRequestResponse.stageOfWorkList;
        this.finesList = this.contractRequestResponse.finesMasterList;
        this.guaranteeList = this.contractRequestResponse.guaranteeBondTypeList;
        this.contractTypeOptionList.setRequestValues(
          this.contractRequestResponse.contractTypeList
        );
        if (this.contractRequestResponse.contract !== null) {
          this.formGroup.patchValue({
            contractType: this.contractRequestResponse.contract.contractType,
            dealingAgreementType:
              this.contractRequestResponse.contract.dealingAgreementType,
            contractTypeId:
              this.contractRequestResponse.contract.contractType.id,
            contract: this.contractRequestResponse.contract,
            procurementVendor:
              this.contractRequestResponse.contract.procurementVendor
          });
          this.procurementVendorId =
            this.formGroup.value.contract.procurementVendor.id;
          this.doOnChangeDealingAgreementType();
          if (this.formGroup.value.contractType) {
            this.httpClientService
              .get<DealingAgreementType[]>(
                '/contract-request/change-dealing-agreement-type-list/' +
                  this.formGroup.value.contractType.id
              )
              .subscribe(dealingAgrementTypeList => {
                this.dealingAgreementTypeOptionList.setRequestValues(
                  dealingAgrementTypeList
                );
              });
          }
          this.workflowPrcs =
            this.contractRequestResponse.contract.workflowPrcs;
          this.workflowModelPrcs =
            this.contractRequestResponse.contract.workflowModelPrcs;
          this.getProcurementItemList();
        }
        this.doSetProcurementSowList();

        if (this.contractRequestResponse.contractCancelationDoc !== null) {
          this.fileUploader.setFile(
            this.contractRequestResponse.contractCancelationDoc.file
          );
          this.formGroupCanceled.patchValue({
            fileDoc: this.fileUploader.fileObjectList,
            note: this.contractRequestResponse.contractCanceled.note
          });
        }
        this.setStateReady();
        this.buildTableResponse();
        console.log(localStorage.getItem('formGroup'));
      });
  }

  public get procurementSowList(): FormArray {
    return this.formGroup.get('procurementSowList') as FormArray;
  }

  public doSetProcurementSowList(): void {
    this.contractRequestResponse.procurementSowList.forEach(procurementSow => {
      const procurementSowPaymentTermList = [];
      procurementSowPaymentTermList.push(
        procurementSow.procurementSowPaymentTermList
      );
      let amount = 0;
      procurementSow.procurementSowPaymentTermList.forEach(
        (procurementSowPaymentTerm: any) => {
          procurementSowPaymentTerm.procurementSowPaymentTermItemProductList =
            [];
          procurementSowPaymentTerm.procurementSowPaymentTermItemServiceList =
            [];
          amount = amount + +procurementSowPaymentTerm.amount;
          procurementSowPaymentTerm.procurementSowPaymentTermItemList.forEach(
            procurementSowItem => {
              if (
                procurementSowItem.procurementResult.procurementItem.item
                  .itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ) {
                procurementSowPaymentTerm.procurementSowPaymentTermItemProductList.push(
                  {
                    procurementResult: procurementSowItem.procurementResult,
                    quantity: procurementSowItem.quantity,
                    remainingQuantity: procurementSowItem.remainingQuantity
                  }
                );
              }
              if (
                procurementSowItem.procurementResult.procurementItem.item
                  .itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
              ) {
                procurementSowPaymentTerm.procurementSowPaymentTermItemServiceList.push(
                  {
                    procurementResult: procurementSowItem.procurementResult,
                    quantity: procurementSowItem.quantity,
                    remainingQuantity: procurementSowItem.remainingQuantity
                  }
                );
              }
            }
          );
        }
      );
      const formGroup = this.formBuilder.group({
        procurementScopeWork: procurementSow.procurementScopeWork,
        stageOfWork: procurementSow.stageOfWork,
        procurementSowPaymentTermList,
        startDate: procurementSow.startDate,
        endDate: procurementSow.endDate,
        totalTermin: procurementSow.procurementSowPaymentTermList.length,
        amount,
        periodType: procurementSow.periodType,
        duration: procurementSow.duration,
        durationType: procurementSow.durationType,
        isMonthlyPayment: procurementSow.isMonthlyPayment,
        status: procurementSow.status,
        id: procurementSow.id
      });
      this.procurementSowList.push(formGroup);
    });
  }

  public doOnChangeDealingAgreementType(): void {
    this.isSave = false;
    this.isSaveDraft = false;
    if (
      (this.formGroup.value.procurementVendor ||
        this.formGroup.value.contract) &&
      this.dealingAgreementTypeOld
    ) {
      this.global.modalService
        .confirm(
          this.translateService.instant('contract-request.confirmation.body'),
          this.translateService.instant('contract-request.confirmation.header')
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.procurementSowList.clear();
            this.tableResponseStageOfWork.setRecords(
              this.procurementSowList.value
            );
            this.tableResponseStageOfWork.setTotalRecords(
              this.procurementSowList.length
            );
            this.tableStageOfWork.reload();
            this.contractRequestResponse.procurementGuaranteeList = [];
            this.tableResponseGuarantee.page.records =
              this.contractRequestResponse.procurementGuaranteeList;
            this.tableResponseGuarantee.page.totalRecords =
              this.contractRequestResponse.procurementGuaranteeList.length;
            this.tableGuarantee.reload();
            this.contractRequestResponse.procurementFinesList = [];
            this.tableResponseFines.page.records =
              this.contractRequestResponse.procurementFinesList;
            this.tableResponseFines.page.totalRecords =
              this.contractRequestResponse.procurementFinesList.length;
            this.tableFines.reload();
            this.tableResponseFines.resetRecords();
            this.tableResponseGuarantee.resetRecords();
            this.tableResponseStageOfWork.resetRecords();
            this.formGroup.get('procurementVendor').reset();
            this.formGroup.get('contract').reset();
          } else {
            this.formGroup.patchValue({
              dealingAgreementType: this.dealingAgreementTypeOld
            });
            this.dealingAgreementTypeOld =
              this.formGroup.value.dealingAgreementType;
          }
        });
    }

    const dealingAgreementType = this.formGroup.value.dealingAgreementType;
    if (dealingAgreementType && this.isChangeContractType) {
      this.formGroup.patchValue({
        contractTypeId: dealingAgreementType.contractType.id
      });
      this.isChangeContractType = false;
      this.dealingAgreementTypeOld = this.formGroup.value.dealingAgreementType;
    }
  }

  public doChangeContractType(): void {
    if (
      this.formGroup.value.procurementVendor ||
      this.formGroup.value.contract
    ) {
      this.global.modalService
        .confirm(
          this.translateService.instant('contract-request.confirmation.body'),
          this.translateService.instant('contract-request.confirmation.header')
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.procurementSowList.clear();
            this.tableResponseStageOfWork.setRecords(
              this.procurementSowList.value
            );
            this.tableResponseStageOfWork.setTotalRecords(
              this.procurementSowList.length
            );
            this.tableStageOfWork.reload();
            this.contractRequestResponse.procurementGuaranteeList = [];
            this.tableResponseGuarantee.page.records =
              this.contractRequestResponse.procurementGuaranteeList;
            this.tableResponseGuarantee.page.totalRecords =
              this.contractRequestResponse.procurementGuaranteeList.length;
            this.tableGuarantee.reload();
            this.contractRequestResponse.procurementFinesList = [];
            this.tableResponseFines.page.records =
              this.contractRequestResponse.procurementFinesList;
            this.tableResponseFines.page.totalRecords =
              this.contractRequestResponse.procurementFinesList.length;
            this.tableFines.reload();
            this.tableResponseFines.resetRecords();
            this.tableResponseGuarantee.resetRecords();
            this.tableResponseStageOfWork.resetRecords();
            this.formGroup.get('dealingAgreementType').reset();
            this.formGroup.get('procurementVendor').reset();
            this.formGroup.get('contract').reset();
          } else {
            this.formGroup.patchValue({
              contractType:
                this.formGroup.value.dealingAgreementType.contractType
            });
            this.dealingAgreementTypeOld =
              this.formGroup.value.dealingAgreementType;
          }
        });
    } else {
      this.formGroup.get('dealingAgreementType').reset();
    }
    const contractType = this.formGroup.value.contractType;
    if (contractType) {
      this.dealingAgreementTypeOptionList.reset();
      this.httpClientService
        .get<DealingAgreementType[]>(
          '/contract-request/change-dealing-agreement-type-list/' +
            contractType.id
        )
        .subscribe(dealingAgrementTypeList => {
          this.dealingAgreementTypeOptionList.setRequestValues(
            dealingAgrementTypeList
          );
        });
    } else {
      this.dealingAgreementTypeOptionList.reset();
    }
    this.isChangeContractType = true;
  }

  public getProcurementItemList(): void {
    this.isLoading = true;
    const procurementVendor = this.formGroup.get('procurementVendor').value;
    const contract = this.formGroup.get('contract').value;
    const id = procurementVendor
      ? procurementVendor.id
      : contract.procurementVendor.id;
    this.httpClientService
      .get<ContractRequestResponse>(
        '/contract-request/get-procurement-result-list/' + id
      )
      .subscribe((contractRequestResponse: ContractRequestResponse) => {
        this.contractRequestResponse.procurementResultProductList =
          contractRequestResponse.procurementResultProductList;
        this.contractRequestResponse.procurementResultServiceList =
          contractRequestResponse.procurementResultServiceList;
        this.isLoading = false;
      });
  }

  public getProcurementDataList(): void {
    const contract = this.formGroup.get('contract').value;
    const id = contract.id;
    this.isLoadingProcData = true;
    this.httpClientService
      .get<ContractRequestResponse>(
        '/contract-request/get-procurement-sow-list/' + id
      )
      .subscribe((contractRequestResponse: ContractRequestResponse) => {
        this.contractRequestResponse.procurementSowList =
          contractRequestResponse.procurementSowList;
        this.doSetProcurementSowList();
        this.tableResponseStageOfWork.page.records =
          this.procurementSowList.value;
        this.tableResponseStageOfWork.page.totalRecords =
          this.procurementSowList.value.length;
        this.tableResponseStageOfWork.reload();
        this.contractRequestResponse.procurementGuaranteeList =
          contractRequestResponse.procurementGuaranteeList;
        this.tableResponseGuarantee.page.records =
          this.contractRequestResponse.procurementGuaranteeList;
        this.tableResponseGuarantee.page.totalRecords =
          this.contractRequestResponse.procurementGuaranteeList.length;
        this.tableResponseGuarantee.reload();
        this.contractRequestResponse.procurementFinesList =
          contractRequestResponse.procurementFinesList;
        this.tableResponseFines.page.records =
          this.contractRequestResponse.procurementFinesList;
        this.tableResponseFines.page.totalRecords =
          this.contractRequestResponse.procurementFinesList.length;
        this.tableResponseFines.reload();

        this.isLoadingProcData = false;
      });
  }

  public buildTableResponse(): void {
    this.tableResponseStageOfWork = new TableResponseModel(this.moduleCode, [
      {
        field: 'stageOfWork.name',
        header: 'table.column.stageOfWork'
      },
      {
        field: 'totalTermin',
        header: 'table.column.totalTermin',
        customClass: 'text-center'
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        format: FieldFormatEnum.ShortDate
      }
    ]);
    this.tableResponseStageOfWork.page.records = this.procurementSowList.value;
    this.tableResponseStageOfWork.page.totalRecords =
      this.procurementSowList.value.length;

    this.tableResponseGuarantee = new TableResponseModel(this.moduleCode, [
      {
        field: 'guaranteeBondType.name',
        header: 'table.column.type',
        customClass: 'text-center'
      },
      {
        field: 'name',
        header: 'table.column.name'
      },
      {
        field: 'percentageValue',
        header: 'table.column.percentageValue',
        customClass: 'text-right',
        fn: this.setDecimal.bind(this)
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'submissionDate',
        header: 'table.column.submissionDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      }
    ]);
    this.tableResponseGuarantee.page.records =
      this.contractRequestResponse.procurementGuaranteeList;
    this.tableResponseGuarantee.page.totalRecords =
      this.contractRequestResponse.procurementGuaranteeList.length;

    this.tableResponseFines = new TableResponseModel(this.moduleCode, [
      {
        field: 'fines.name',
        header: 'table.column.type',
        customClass: 'text-center'
      },
      {
        field: 'percentageValue',
        header: 'table.column.percentageValue',
        customClass: 'text-right',
        fn: this.setDecimal.bind(this)
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      }
    ]);
    this.tableResponseFines.page.records =
      this.contractRequestResponse.procurementFinesList;
    this.tableResponseFines.page.totalRecords =
      this.contractRequestResponse.procurementFinesList.length;
  }
  public doAddStageOfWork(): void {
    this.doSetTotalAmountSow();
    const todo = 'add';
    const header = this.translateService.instant(
      'contract-request.popup.title.addStageOfWork'
    );
    const stageOfWorkList = this.contractRequestResponse.stageOfWorkList.filter(
      stageOfWork =>
        this.procurementSowList.value.every(
          procurementSow => procurementSow.stageOfWork.id !== stageOfWork.id
        )
    );
    const paymentTermList = this.contractRequestResponse.paymentTermList;
    const billingTermList = this.contractRequestResponse.billingTermList;
    const contract = this.formGroup.value.contract;
    const procurementVendor = this.formGroup.value.procurementVendor;
    const procurementResultProductList =
      this.contractRequestResponse.procurementResultProductList;
    const procurementResultServiceList =
      this.contractRequestResponse.procurementResultServiceList;
    let procurementPaymentItemList: any;
    if (this.procurementSowList.length > 0) {
      procurementPaymentItemList =
        this.procurementSowList.value[this.procurementSowList.length - 1]
          .procurementSowPaymentTermList[0];
    }
    const availableAmountSow = this.availableAmountSow;
    const procurementSowList = this.procurementSowList;
    const dealingAgreementType = this.formGroup.value.dealingAgreementType;
    if (availableAmountSow === 0) {
      this.global.alertService.showError(
        this.translateService.instant('contract-request.alert.msg.maxValue')
      );
    } else {
      this.appPopupProcurementScopeWorkService
        .open(
          todo,
          header,
          null,
          stageOfWorkList,
          paymentTermList,
          billingTermList,
          procurementResultServiceList,
          procurementResultProductList,
          contract,
          procurementVendor,
          availableAmountSow,
          procurementPaymentItemList,
          procurementSowList,
          null,
          null,
          dealingAgreementType,
          false
        )
        .subscribe(procurementSow => {
          let amount = 0;
          const procurementSowPaymentTermList = [];
          procurementSowPaymentTermList.push(
            procurementSow.procurementSowPaymentTermList
          );
          procurementSow.procurementSowPaymentTermList.forEach(
            procurementSowPaymentTerm => {
              amount = amount + +procurementSowPaymentTerm.amount;
            }
          );
          const formGroup = this.formBuilder.group({
            contract: procurementSow.contract,
            stageOfWork: procurementSow.stageOfWork,
            procurementSowPaymentTermList,
            startDate: procurementSow.startDate,
            endDate: procurementSow.endDate,
            totalTermin: procurementSow.procurementSowPaymentTermList.length,
            amount,
            periodType: procurementSow.periodType,
            duration: procurementSow.duration,
            durationType: procurementSow.durationType,
            isMonthlyPayment: procurementSow.isMonthlyPayment,
            status: procurementSow.status
          });
          this.procurementSowList.push(formGroup);
          this.procurementSowList.controls.sort(
            (a, b) => a.value.stageOfWork.id - b.value.stageOfWork.id
          );
          this.procurementSowList.value.sort(
            (a, b) => a.stageOfWork.id - b.stageOfWork.id
          );
          this.contractRequestResponse.procurementSowList =
            this.procurementSowList.value;
          this.tableResponseStageOfWork.page.records =
            this.procurementSowList.value;
          this.tableResponseStageOfWork.page.totalRecords =
            this.procurementSowList.length;
          this.tableResponseStageOfWork.sortRecords(
            this.procurementSowList.value
          );
          this.tableResponseStageOfWork.sortOrder = 'DESC';
          this.tableResponseStageOfWork.setSortField('stageOfWork.name');
          this.tableResponseStageOfWork.reload();
          this.doSetTotalAmountSow();
        });
    }
  }
  public doEditStageOfWork(procurementSowEdit: any): void {
    this.doSetTotalAmountSow();
    const todo = 'edit';
    const header = this.translateService.instant(
      'contract-request.popup.title.editStageOfWork'
    );
    const stageOfWorkList = this.contractRequestResponse.stageOfWorkList.filter(
      stageOfWork =>
        this.procurementSowList.value.every(
          procurementSow =>
            procurementSow.stageOfWork.id !== stageOfWork.id ||
            procurementSow.stageOfWork.id === procurementSowEdit.stageOfWork.id
        )
    );
    const paymentTermList = this.contractRequestResponse.paymentTermList;
    const billingTermList = this.contractRequestResponse.billingTermList;
    const contract = this.formGroup.value.contract;
    const procurementVendor = this.formGroup.value.procurementVendor;
    const procurementResultProductList =
      this.contractRequestResponse.procurementResultProductList;
    const procurementResultServiceList =
      this.contractRequestResponse.procurementResultServiceList;
    const availableAmountSow =
      this.availableAmountSow + +procurementSowEdit.amount;
    let procurementPaymentItemList: any;
    if (this.procurementSowList.length > 0) {
      procurementPaymentItemList =
        this.procurementSowList.value[this.procurementSowList.length - 1]
          .procurementSowPaymentTermList[0];
    }
    const procurementSowList = this.procurementSowList;
    const contractType = this.formGroup.value.contractType;
    const dealingAgreementType = this.formGroup.value.dealingAgreementType;
    this.appPopupProcurementScopeWorkService
      .open(
        todo,
        header,
        procurementSowEdit,
        stageOfWorkList,
        paymentTermList,
        billingTermList,
        procurementResultServiceList,
        procurementResultProductList,
        contract,
        procurementVendor,
        availableAmountSow,
        procurementPaymentItemList,
        procurementSowList,
        null,
        contractType,
        dealingAgreementType,
        false
      )
      .subscribe(procurementSowTemp => {
        this.procurementSowList.controls.sort(
          (a, b) => a.value.stageOfWork.id - b.value.stageOfWork.id
        );
        this.procurementSowList.value.sort(
          (a, b) => a.stageOfWork.id - b.stageOfWork.id
        );
        this.procurementSowList.value.forEach((procurementSow, index) => {
          if (
            JSON.stringify(procurementSow) ===
            JSON.stringify(procurementSowEdit)
          ) {
            this.procurementSowList.value[index] = procurementSowTemp;
            this.procurementSowList.controls[index].patchValue(
              procurementSowTemp
            );
          }
        });
        this.contractRequestResponse.procurementSowList =
          this.procurementSowList.value;
        this.tableResponseStageOfWork.page.records =
          this.procurementSowList.value;
        this.tableResponseStageOfWork.page.totalRecords =
          this.procurementSowList.length;
        this.tableResponseStageOfWork.sortRecords(
          this.procurementSowList.value
        );
        this.tableResponseStageOfWork.sortOrder = 'DESC';
        this.tableResponseStageOfWork.setSortField('stageOfWork.name');
        this.tableResponseStageOfWork.reload();
        this.doSetTotalAmountSow();
      });
  }
  public doDeleteStageOfWork(procurementSowDelete: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.procurementSowList.controls.sort(
            (a, b) => a.value.stageOfWork.id - b.value.stageOfWork.id
          );
          this.procurementSowList.value.sort(
            (a, b) => a.stageOfWork.id - b.stageOfWork.id
          );
          this.procurementSowList.value.forEach((procurementSow, index) => {
            if (
              JSON.stringify(procurementSow) ===
              JSON.stringify(procurementSowDelete)
            ) {
              const procurementSowPaymentTermList =
                this.procurementSowList.controls[index].get(
                  'procurementSowPaymentTermList'
                ) as FormArray;
              this.procurementSowList.value.splice(index, 1);
              this.procurementSowList.removeAt(index);
              const procurementSowPaymentTermItemServiceList =
                procurementSowPaymentTermList.value[0]
                  .procurementSowPaymentTermItemServiceList;
              const procurementSowPaymentTermItemProductList =
                procurementSowPaymentTermList.value[0]
                  .procurementSowPaymentTermItemProductList;
              if (this.procurementSowList.length > 0) {
                if (
                  procurementSowPaymentTermItemServiceList &&
                  procurementSowPaymentTermItemProductList
                ) {
                  procurementSowPaymentTermList.value.forEach(paymentTerm => {
                    paymentTerm.procurementSowPaymentTermItemServiceList.forEach(
                      itemService => {
                        this.procurementSowList.controls.forEach(
                          procurementSow => {
                            procurementSow.value.procurementSowPaymentTermList.forEach(
                              payment => {
                                payment.procurementSowPaymentTermItemServiceList.forEach(
                                  (sowProduct, j) => {
                                    if (
                                      itemService.procurementResult.id ===
                                      sowProduct.procurementResult.id
                                    ) {
                                      payment.procurementSowPaymentTermItemServiceList[
                                        j
                                      ].remainingQuantity =
                                        +sowProduct.remainingQuantity +
                                        +itemService.quantity;
                                    }
                                  }
                                );
                              }
                            );
                          }
                        );
                      }
                    );
                    paymentTerm.procurementSowPaymentTermItemProductList.forEach(
                      itemProduct => {
                        this.procurementSowList.controls.forEach(
                          procurementSow => {
                            procurementSow.value.procurementSowPaymentTermList.forEach(
                              payment => {
                                payment.procurementSowPaymentTermItemProductList.forEach(
                                  (sowProduct, j) => {
                                    if (
                                      itemProduct.procurementResult.id ===
                                      sowProduct.procurementResult.id
                                    ) {
                                      payment.procurementSowPaymentTermItemProductList[
                                        j
                                      ].remainingQuantity =
                                        +sowProduct.remainingQuantity +
                                        +itemProduct.quantity;
                                    }
                                  }
                                );
                              }
                            );
                          }
                        );
                      }
                    );
                  });

                  this.procurementSowList.controls.forEach(procurementSow => {
                    procurementSow.value.procurementSowPaymentTermList.forEach(
                      payment => {
                        payment.procurementSowPaymentTermItemList = [];
                        payment.procurementSowPaymentTermItemProductList.forEach(
                          itemProduct => {
                            payment.procurementSowPaymentTermItemList.push(
                              itemProduct
                            );
                          }
                        );
                        payment.procurementSowPaymentTermItemServiceList.forEach(
                          itemService => {
                            payment.procurementSowPaymentTermItemList.push(
                              itemService
                            );
                          }
                        );
                      }
                    );
                  });
                }
              }
            }
          });
          this.contractRequestResponse.procurementSowList =
            this.procurementSowList.value;
          this.tableResponseStageOfWork.page.records =
            this.procurementSowList.value;
          this.tableResponseStageOfWork.page.totalRecords =
            this.procurementSowList.value.length;
          this.tableResponseStageOfWork.sortRecords(
            this.procurementSowList.value
          );
          this.tableResponseStageOfWork.sortOrder = 'DESC';
          this.tableResponseStageOfWork.setSortField('stageOfWork.name');
          this.tableResponseStageOfWork.reload();
        }
      });
  }

  public doDetailStageOfWork(procurementSowEdit: any): void {
    this.doSetTotalAmountSow();
    const todo = 'view';
    const header = this.translateService.instant(
      'contract-request.popup.title.viewStageOfWork'
    );
    const stageOfWorkList = this.contractRequestResponse.stageOfWorkList.filter(
      stageOfWork =>
        this.procurementSowList.value.every(
          procurementSow =>
            procurementSow.stageOfWork.id !== stageOfWork.id ||
            procurementSow.stageOfWork.id === procurementSowEdit.stageOfWork.id
        )
    );
    const paymentTermList = this.contractRequestResponse.paymentTermList;
    const billingTermList = this.contractRequestResponse.billingTermList;
    const contract = this.formGroup.value.contract;
    const procurementVendor = this.formGroup.value.procurementVendor;
    const procurementResultProductList =
      this.contractRequestResponse.procurementResultProductList;
    const procurementResultServiceList =
      this.contractRequestResponse.procurementResultServiceList;
    const availableAmountSow =
      this.availableAmountSow + +procurementSowEdit.amount;
    let procurementSowPaymentList: any;
    if (this.procurementSowList.length > 0) {
      procurementSowPaymentList =
        this.procurementSowList.value[this.procurementSowList.length - 1]
          .procurementSowPaymentTermList[0];
    }
    const procurementSowList = this.procurementSowList;
    const dealingAgreementType = this.formGroup.value.dealingAgreementType;
    this.appPopupProcurementScopeWorkService.open(
      todo,
      header,
      procurementSowEdit,
      stageOfWorkList,
      paymentTermList,
      billingTermList,
      procurementResultServiceList,
      procurementResultProductList,
      contract,
      procurementVendor,
      availableAmountSow,
      null,
      procurementSowList,
      procurementSowPaymentList,
      null,
      dealingAgreementType,
      false
    );
  }

  public doAddGuarantee(): void {
    const todo = 'add';
    const header = this.translateService.instant(
      'contract-request.popup.title.addGuarantee'
    );
    const guaranteeList =
      this.contractRequestResponse.guaranteeBondTypeList.filter(guarantee =>
        this.contractRequestResponse.procurementGuaranteeList.every(
          procurementGuarantee =>
            procurementGuarantee.guaranteeBondType.id !== guarantee.id
        )
      );
    const guaranteeBondTypeList = guaranteeList;
    const contract = this.formGroup.value.contract;
    const procurementVendor = this.formGroup.value.procurementVendor;
    this.appPopupProcurementGuaranteeService
      .open(
        todo,
        header,
        null,
        guaranteeBondTypeList,
        contract,
        procurementVendor
      )
      .subscribe(procurementGuarantee => {
        this.contractRequestResponse.procurementGuaranteeList.push(
          procurementGuarantee
        );
        this.tableResponseGuarantee.page.records =
          this.contractRequestResponse.procurementGuaranteeList;
        this.tableResponseGuarantee.page.totalRecords =
          this.contractRequestResponse.procurementGuaranteeList.length;
        this.tableGuarantee.reloadClient();
      });
  }
  public doEditGuarantee(procurementGuaranteeEdit: ProcurementGuarantee): void {
    const todo = 'edit';
    const header = this.translateService.instant(
      'contract-request.popup.title.editGuarantee'
    );
    const guaranteeList =
      this.contractRequestResponse.guaranteeBondTypeList.filter(guarantee =>
        this.contractRequestResponse.procurementGuaranteeList.every(
          procurementGuarantee =>
            procurementGuarantee.guaranteeBondType.id !== guarantee.id ||
            procurementGuarantee.guaranteeBondType.id ===
              procurementGuaranteeEdit.guaranteeBondType.id
        )
      );
    const guaranteeBondTypeList = guaranteeList;
    const contract = this.formGroup.value.contract;
    const procurementVendor = this.formGroup.value.procurementVendor;
    this.appPopupProcurementGuaranteeService
      .open(
        todo,
        header,
        procurementGuaranteeEdit,
        guaranteeBondTypeList,
        contract,
        procurementVendor
      )
      .subscribe(procurementGuaranteeTemp => {
        this.contractRequestResponse.procurementGuaranteeList.forEach(
          (procurementGuarantee, index) => {
            if (procurementGuarantee === procurementGuaranteeEdit) {
              this.contractRequestResponse.procurementGuaranteeList[index] =
                procurementGuaranteeTemp;
            }
          }
        );

        this.tableResponseGuarantee.page.records =
          this.contractRequestResponse.procurementGuaranteeList;
        this.tableResponseGuarantee.page.totalRecords =
          this.contractRequestResponse.procurementGuaranteeList.length;
        this.tableGuarantee.reloadClient();
      });
  }
  public doDeleteGuarantee(
    procurementGuaranteeDelete: ProcurementGuarantee
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.contractRequestResponse.procurementGuaranteeList.forEach(
            (procurementGuarantee, index) => {
              if (procurementGuarantee === procurementGuaranteeDelete) {
                this.contractRequestResponse.procurementGuaranteeList.splice(
                  index,
                  1
                );
              }
            }
          );
          this.tableResponseGuarantee.page.records =
            this.contractRequestResponse.procurementGuaranteeList;
          this.tableResponseGuarantee.page.totalRecords =
            this.contractRequestResponse.procurementGuaranteeList.length;
          this.tableGuarantee.reloadClient();
        }
      });
  }

  public doAddFines(): void {
    const todo = 'add';
    const header = this.translateService.instant(
      'contract-request.popup.title.addFines'
    );
    const finesList = this.contractRequestResponse.finesMasterList.filter(fines =>
      this.contractRequestResponse.procurementFinesList.every(
        procurementFines => procurementFines.fines.id !== fines.id
      )
    );
    const contract = this.formGroup.value.contract;
    const procurementVendor = this.formGroup.value.procurementVendor;
    this.appPopupProcurementFinesService
      .open(todo, header, null, finesList, contract, procurementVendor)
      .subscribe(procurementFines => {
        this.contractRequestResponse.procurementFinesList.push(
          procurementFines
        );

        this.tableResponseFines.page.records =
          this.contractRequestResponse.procurementFinesList;
        this.tableResponseFines.page.totalRecords =
          this.contractRequestResponse.procurementFinesList.length;
        this.tableFines.reloadClient();
      });
  }
  public doEditFines(procurementFinesEdit: ProcurementFines): void {
    const todo = 'edit';
    const header = this.translateService.instant(
      'contract-request.popup.title.editFines'
    );
    const finesList = this.contractRequestResponse.finesMasterList.filter(fines =>
      this.contractRequestResponse.procurementFinesList.every(
        procurementFines =>
          procurementFines.fines.id !== fines.id ||
          procurementFines.fines.id === procurementFinesEdit.fines.id
      )
    );
    const contract = this.formGroup.value.contract;
    const procurementVendor = this.formGroup.value.procurement;
    this.appPopupProcurementFinesService
      .open(
        todo,
        header,
        procurementFinesEdit,
        finesList,
        contract,
        procurementVendor
      )
      .subscribe(procurementFinesTemp => {
        this.contractRequestResponse.procurementFinesList.forEach(
          (procurementFines, index) => {
            if (procurementFines === procurementFinesEdit) {
              this.contractRequestResponse.procurementFinesList[index] =
                procurementFinesTemp;
            }
          }
        );

        this.tableResponseFines.page.records =
          this.contractRequestResponse.procurementFinesList;
        this.tableResponseFines.page.totalRecords =
          this.contractRequestResponse.procurementFinesList.length;
        this.tableFines.reloadClient();
      });
  }
  public doDeleteFines(procurementFinesDelete: ProcurementFines): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.contractRequestResponse.procurementFinesList.forEach(
            (procurementFines, index) => {
              if (procurementFines === procurementFinesDelete) {
                this.contractRequestResponse.procurementFinesList.splice(
                  index,
                  1
                );
              }
            }
          );
          this.tableResponseFines.page.records =
            this.contractRequestResponse.procurementFinesList;
          this.tableResponseFines.page.totalRecords =
            this.contractRequestResponse.procurementFinesList.length;
          this.tableFines.reloadClient();
        }
      });
  }

  public doSetTotalAmountSow(): void {
    const amount = this.formGroup.value.contract
      ? this.formGroup.value.contract.procurementVendor.amount
      : this.formGroup.value.procurementVendor.amount;
    let totalAmountSow = 0;
    this.procurementSowList.value.forEach(procurementSow => {
      totalAmountSow = totalAmountSow + +procurementSow.amount;
    });
    this.totalAmountSow = totalAmountSow;
    this.availableAmountSow = +amount - +this.totalAmountSow;
  }

  public doSave(): void {
    this.contractRequestRequest.procurementSowList =
      this.formGroup.get('procurementSowList').value;
    this.isSave = true;
    this.contractRequestRequest.procurementFinesList =
      this.contractRequestResponse.procurementFinesList;
    this.contractRequestRequest.procurementGuaranteeList =
      this.contractRequestResponse.procurementGuaranteeList;
    this.validate();
    if (
      this.formGroup.valid &&
      this.contractRequestRequest.procurementSowList.length > 0 &&
      this.contractRequestRequest.procurementFinesList.length > 0
    ) {
      this.doSetTotalAmountSow();
      if (this.availableAmountSow !== 0) {
        this.global.alertService.showError(
          this.translateService.instant('contract-request.alert.contractValue')
        );
      } else {
        this.global.modalService
          .saveConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              const url =
                this.todo === 'edit'
                  ? '/contract-request/update'
                  : '/contract-request/insert';
              const contract: Contract =
                this.todo === 'edit'
                  ? this.contractRequestResponse.contract
                  : this.formGroup.value;
              contract.contractType = this.formGroup.value.contractType;
              contract.dealingAgreementType =
                this.formGroup.value.dealingAgreementType;
              contract.procurementVendor = this.formGroup.value
                .procurementVendor
                ? this.formGroup.value.procurementVendor
                : this.formGroup.value.contract.procurementVendor;
              contract.fromContract =
                this.contractRequestResponse.contract.fromContract;
              this.contractRequestRequest.contract = contract;
              if (
                contract.contractType.code ===
                this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
              ) {
                this.contractRequestRequest.contract.id =
                  this.formGroup.value.contract.id;
              }
              this.contractRequestRequest.isSaveDraft = false;
              this.httpClientService
                .post<Response<Contract>>(url, this.contractRequestRequest)
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.alertService.showSuccessSavingOnNextRoute();
                    this.router.navigate(['/pages/contract-request/']);
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      }
    } else if (
      this.formGroup.valid &&
      (!this.formGroup.value.procurementVendor ||
        !this.formGroup.value.contract)
    ) {
      if (
        this.formGroup.value.contractType.code ===
        this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
      ) {
        this.global.alertService.showError(
          this.translateService.instant('contract-request.alert.chooseContract')
        );
      } else {
        this.global.alertService.showError(
          this.translateService.instant(
            'contract-request.alert.chooseProcurement'
          )
        );
      }
    }
  }

  public doSaveDraft(): void {
    this.contractRequestRequest.procurementSowList =
      this.formGroup.get('procurementSowList').value;
    this.contractRequestRequest.procurementFinesList =
      this.contractRequestResponse.procurementFinesList;
    this.contractRequestRequest.procurementGuaranteeList =
      this.contractRequestResponse.procurementGuaranteeList;
    this.isSaveDraft = true;
    this.validate();
    if (
      this.formGroup.valid &&
      (this.formGroup.value.contract || this.formGroup.value.procurementVendor)
    ) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url =
              this.todo === 'edit'
                ? '/contract-request/update'
                : '/contract-request/insert';
            const contract: Contract =
              this.todo === 'edit'
                ? this.contractRequestResponse.contract
                : this.formGroup.value;
            contract.contractType = this.formGroup.value.contractType;
            contract.dealingAgreementType =
              this.formGroup.value.dealingAgreementType;
            contract.procurementVendor = this.formGroup.value.procurementVendor
              ? this.formGroup.value.procurementVendor
              : this.formGroup.value.contract.procurementVendor;
            contract.fromContract =
              this.contractRequestResponse.contract.fromContract;
            this.contractRequestRequest.contract = contract;
            if (
              contract.contractType.code ===
              this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
            ) {
              this.contractRequestRequest.contract.id =
                this.formGroup.value.contract.id;
            }
            this.contractRequestRequest.procurementSowList =
              this.formGroup.get('procurementSowList').value;
            this.contractRequestRequest.isSaveDraft = true;
            this.contractRequestRequest.procurementFinesList =
              this.contractRequestResponse.procurementFinesList;
            this.contractRequestRequest.procurementGuaranteeList =
              this.contractRequestResponse.procurementGuaranteeList;
            this.httpClientService
              .post<Response<Contract>>(url, this.contractRequestRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/contract-request/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    } else if (
      this.formGroup.valid &&
      !this.formGroup.value.procurementVendor &&
      !this.formGroup.value.contract
    ) {
      if (
        this.formGroup.value.contractType.code ===
        this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
      ) {
        this.global.alertService.showError(
          this.translateService.instant('contract-request.alert.chooseContract')
        );
      } else {
        this.global.alertService.showError(
          this.translateService.instant(
            'contract-request.alert.chooseProcurement'
          )
        );
      }
    }
  }

  public doSaveCancel(): void {
    this.validate();
    this.formGroupCanceled['isValid'] = true;
    this.formGroupCanceled['submitted'] = true;
    this.formGroupService.validateAllFormFields(this.formGroupCanceled);
    if (!this.formGroupCanceled.valid) {
      this.formGroupCanceled['submitted'] = false;
    }
    if (this.formGroupCanceled.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.formGroupCanceled.disable();
            this.setStateProcessing();
            this.contractRequestRequest.contractCanceled =
              this.formGroupCanceled.value;
            this.contractRequestRequest.contractCanceled.contract =
              this.formGroup.value.contract;
            this.contractRequestRequest.fileList =
              this.formGroupCanceled.value.fileDoc;
            this.httpClientService
              .post<Response<ContractRequestResponse>>(
                '/contract-request/cancel',
                this.contractRequestRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/contract-request/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/contract-request']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public doChooseProcurement(): void {
    let isRepeatOrder: number;
    let contractId: number;
    const procurementVendor = this.formGroup.value.procurementVendor;
    const procurementVendorOld = this.formGroup.value.procurementVendor;
    if (
      this.formGroup.value.dealingAgreementType.contractType.code ===
      this.global.appConstant.cm.CONTRACT_TYPE_NEW_CONTRACT
    ) {
      isRepeatOrder = 0;
      contractId = this.contractId || null;
    } else {
      isRepeatOrder = 1;
      contractId = this.contractId || null;
    }
    this.appPopupService
      .open(
        AppPopupContractRequestChooseProcurementComponent,
        {
          isRepeatOrder,
          contractId,
          procurementVendor
        },
        { size: 'xl' }
      )
      .subscribe(procurementVendor => {
        if (
          procurementVendorOld &&
          procurementVendorOld.id !== procurementVendor.id
        ) {
          this.procurementSowList.clear();
          this.tableResponseStageOfWork.setRecords(
            this.procurementSowList.value
          );
          this.tableResponseStageOfWork.setTotalRecords(
            this.procurementSowList.length
          );
          this.tableStageOfWork.reload();
          this.contractRequestResponse.procurementGuaranteeList = [];
          this.tableResponseGuarantee.page.records =
            this.contractRequestResponse.procurementGuaranteeList;
          this.tableResponseGuarantee.page.totalRecords =
            this.contractRequestResponse.procurementGuaranteeList.length;
          this.tableGuarantee.reload();
          this.contractRequestResponse.procurementFinesList = [];
          this.tableResponseFines.page.records =
            this.contractRequestResponse.procurementFinesList;
          this.tableResponseFines.page.totalRecords =
            this.contractRequestResponse.procurementFinesList.length;
          this.tableFines.reload();
          this.tableResponseFines.resetRecords();
          this.tableResponseGuarantee.resetRecords();
          this.tableResponseStageOfWork.resetRecords();
          this.formGroup.get('procurementVendor').reset();
          this.formGroup.get('contract').reset();
        }
        this.formGroup.patchValue({
          procurementVendor
        });
        this.getProcurementItemList();
        const contractResponse: Contract =
          this.todo === 'edit'
            ? this.contractRequestResponse.contract
            : this.formGroup.value;
        contractResponse.contractType = this.formGroup.value.contractType;
        contractResponse.dealingAgreementType =
          this.formGroup.value.dealingAgreementType;
        contractResponse.procurementVendor = this.formGroup.value
          .procurementVendor
          ? this.formGroup.value.procurementVendor
          : this.formGroup.value.contract.procurementVendor;
        this.contractRequestResponse.contract = contractResponse;
      });
  }

  public doChooseContract(): void {
    const contract = this.formGroup.value.contract;
    const contractOld = this.formGroup.value.contract;
    const contractId = this.contractId || null;
    this.appPopupService
      .open(
        AppPopupContractRequestChooseContractComponent,
        {
          contract,
          contractId
        },
        { size: 'xl' }
      )
      .subscribe(contract => {
        if (contractOld && contractOld.id !== contract.id) {
          this.procurementSowList.clear();
          this.tableResponseStageOfWork.setRecords(
            this.procurementSowList.value
          );
          this.tableResponseStageOfWork.setTotalRecords(
            this.procurementSowList.length
          );
          this.tableStageOfWork.reload();
          this.contractRequestResponse.procurementGuaranteeList = [];
          this.tableResponseGuarantee.page.records =
            this.contractRequestResponse.procurementGuaranteeList;
          this.tableResponseGuarantee.page.totalRecords =
            this.contractRequestResponse.procurementGuaranteeList.length;
          this.tableGuarantee.reload();
          this.contractRequestResponse.procurementFinesList = [];
          this.tableResponseFines.page.records =
            this.contractRequestResponse.procurementFinesList;
          this.tableResponseFines.page.totalRecords =
            this.contractRequestResponse.procurementFinesList.length;
          this.tableFines.reload();
          this.tableResponseFines.resetRecords();
          this.tableResponseGuarantee.resetRecords();
          this.tableResponseStageOfWork.resetRecords();
          this.formGroup.get('procurementVendor').reset();
          this.formGroup.get('contract').reset();
        }
        this.formGroup.patchValue({
          contract,
          dealingAgreementType: contract.dealingAgreementType
        });
        this.formGroup.value.contract.procurementVendor =
          contract.procurementVendor;
        this.procurementVendorId = contract.procurementVendor.id;
        this.getProcurementItemList();
        this.getProcurementDataList();
        const contractResponse: Contract =
          this.todo === 'edit'
            ? this.contractRequestResponse.contract
            : this.formGroup.value;
        contractResponse.contractType = this.formGroup.value.contractType;
        contractResponse.dealingAgreementType =
          this.formGroup.value.dealingAgreementType;
        contractResponse.procurementVendor = this.formGroup.value
          .procurementVendor
          ? this.formGroup.value.procurementVendor
          : this.formGroup.value.contract.procurementVendor;
        this.contractRequestResponse.contract = contractResponse;
      });
  }

  public setDecimal(field: number): string {
    return this.currency.transform(field, '', '', this.CURRENCY_DIGITS_INFO);
  }
}
