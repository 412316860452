import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstant } from '../constant/app-constant';
import { ServiceLocator } from './service-locator';

@Injectable({
  providedIn: 'root'
})
export class ConverterService {
  constructor() {}

  public convertMoney(amount: any): string {
    const val = amount ? amount : 0;
    const currencyPipe = ServiceLocator.injector.get(CurrencyPipe);
    const appConstant = new AppConstant();
    const CURRENCY_DIGITS_INFO = `0.${appConstant.core.CURRENCY_PRECISSION_SCALE}-${appConstant.core.CURRENCY_PRECISSION_SCALE}`;
    return currencyPipe.transform(val, '', '', CURRENCY_DIGITS_INFO);
  }

  public uConvertMoney(val: string): any {
    if (val) {
      const dataArr = val.toString().split(',');
      let amount = '';
      let i;
      for (i = 0; i < dataArr.length; i++) {
        amount = amount + dataArr[i];
      }
      const dataArr2 = amount.split('.');
      if (dataArr2[1] !== '00') {
        return amount;
      } else {
        return dataArr2[0];
      }
    }
    return 0;
  }

  public convertNumber(strValue: string): string {
    if (strValue !== '') {
      strValue = strValue.toString().replace(',', '.');
      strValue = strValue.toString().replace(/\$|\,/g, '');
      let dblValue: any = parseFloat(strValue);

      const blnSign = dblValue === (dblValue = Math.abs(dblValue));

      dblValue = Math.floor(dblValue * 100 + 0.50000000001);

      const intCents: number = dblValue % 100;

      let strCents: string = intCents.toString();

      dblValue = Math.floor(dblValue / 100).toString();

      if (intCents === 0) {
        strCents = '';
      } else if (intCents > 0 && intCents < 10) {
        strCents = '.0' + strCents;
      } else {
        strCents = '.' + strCents;
      }

      let i;
      for (i = 0; i < Math.floor((dblValue.length - (1 + i)) / 3); i++) {
        dblValue =
          dblValue.substring(0, dblValue.length - (4 * i + 3)) +
          ',' +
          dblValue.substring(dblValue.length - (4 * i + 3));
      }

      return (blnSign ? '' : '-') + dblValue + strCents;
    } else {
      return '0.00';
    }
  }

  public uConvertNumber(val: string): string {
    const dataArr = val.split(',');
    let amount = '';
    let i;
    for (i = 0; i < dataArr.length; i++) {
      amount = amount + dataArr[i];
    }
    return amount;
  }

  public convertToShortMoney(
    value: number | string,
    customFormat?: any[]
  ): string | number {
    const translateService: TranslateService =
      ServiceLocator.injector.get(TranslateService);
    value = typeof value === 'number' ? Number(value).toFixed(2) : value;
    // default result is number
    let result = value;
    // default array money format
    let arrayMoneyFormat =
      'thousand,million,billion,trillion,quadrillion,quantillion,sextillion'.split(
        ','
      );
    // // set array money format if customFormat exists
    arrayMoneyFormat = customFormat ? customFormat : arrayMoneyFormat;
    // set coma to number
    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const dotIndexLength = value.split('.')[0].split(',').length;

    if (value.split('.')[0].indexOf(',') !== -1) {
      const newName = Number(
        '0.' +
          value
            .slice(value.indexOf(',') + 1)
            .split(',')
            .join('')
            .split('.')
            .join('')
      ).toFixed(2);

      const format = customFormat
        ? arrayMoneyFormat[dotIndexLength - 2]
        : translateService.instant(
            `app.moneyFormat.${arrayMoneyFormat[dotIndexLength - 2]}`
          );
      value = value.slice(0, value.indexOf(','));
      result = (+value + +newName).toFixed(2) + ' ' + format;
    }

    return result;
  }

  public convertToShortMoneyChart(
    value: number | string,
    customFormat?: any[]
  ): string | number {
    const translateService: TranslateService =
      ServiceLocator.injector.get(TranslateService);
    value = typeof value === 'number' ? Number(value).toFixed(1) : value;
    // default result is number
    let result = value;
    // default array money format
    let arrayMoneyFormat =
      'thousand,million,billion,trillion,quadrillion,quantillion,sextillion'.split(
        ','
      );
    // // set array money format if customFormat exists
    arrayMoneyFormat = customFormat ? customFormat : arrayMoneyFormat;
    // set coma to number
    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const dotIndexLength = value.split('.')[0].split(',').length;

    if (value.split('.')[0].indexOf(',') !== -1) {
      // const newName = ()
      const newName = Number(
        '0.' +
          value
            .slice(value.indexOf(',') + 1)
            .split(',')
            .join('')
            .split('.')
            .join('')
      ).toFixed(1);

      const format = customFormat
        ? arrayMoneyFormat[dotIndexLength - 2]
        : translateService.instant(
            `app.moneyFormat.short.${arrayMoneyFormat[dotIndexLength - 2]}`
          );
      value = value.slice(0, value.indexOf(','));
      result = (+value + +newName).toString().replace('.0', '') + ' ' + format;
      return result;
    }
  }

  public convertDecimal(value: string | number): string {
    const val = value ? value : 0;
    const decimalPipe = ServiceLocator.injector.get(CurrencyPipe);
    const appConstant = new AppConstant();
    const DECIMAL_DIGITS_INFO = `0.${appConstant.core.NUMBER_PRECISSION_SCALE}-${appConstant.core.NUMBER_PRECISSION_SCALE}`;
    return decimalPipe.transform(val, '', '', DECIMAL_DIGITS_INFO);
  }

  public convertMonthToYears(month: number): string {
    const months = month % 12;
    const year = Math.floor(month / 12);
    if (months > 0) {
      return (year === 0 ? '' : year + ' Year ') + months + ' Month';
    } else if (months === 0) {
      return month / 12 + ' Year';
    }
  }

  public convertExponentialNumberToNumber(
    exponensialNumber: number
  ): string | number {
    const numString = exponensialNumber.toString();
    const isIncludeExponent = numString.includes('e');
    if (isIncludeExponent) {
      const numOnly = numString.split('e')[0].toString().replace(/\./g, '');
      const isNumberLessThanZero = numString.includes('-');
      const totalNumberAfterDecimal =
        +numString.split(isNumberLessThanZero ? '-' : '+')[1] +
        (isNumberLessThanZero ? numOnly.length - 1 : 1);
      if (isNumberLessThanZero) {
        const decimalNumber = Array(totalNumberAfterDecimal)
          .fill(0)
          .map((val, index) => {
            if (index >= totalNumberAfterDecimal - numOnly.length) {
              return numOnly[
                numOnly.length - (totalNumberAfterDecimal - index)
              ];
            } else {
              return val;
            }
          })
          .join('');
        return '0.' + decimalNumber;
      } else {
        const numberResult = Array(totalNumberAfterDecimal)
          .fill(0)
          .map((val, index) => {
            if (numOnly[index]) {
              return numOnly[index];
            } else {
              return val;
            }
          })
          .join('');
        return numberResult;
      }
    } else {
      return exponensialNumber;
    }
  }
}
