import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Attribute } from '../../../bean/attribute';
import { TableResponseModel } from '../../app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-attribute.component.html'
})
export class AppPopupAttributeComponent extends BaseComponentComponent {
  @Input() attributeCustomData: any;
  @Input() customData: any;
  @Input() attributeIdList: number[];
  @Input() urlCustom: string;
  @Output() onChange: EventEmitter<Attribute> = new EventEmitter();

  tableResponse: TableResponseModel<Attribute>;

  constructor(public activeModal: NgbActiveModal) {
    super('app-popup-attribute');
  }

  onInit(): void {
    this.getAppPopupAttributeFromServer(this.urlCustom);
    this.buildTableResponse();
  }

  getAppPopupAttributeFromServer(urlAttributePopup: string): void {
    if (
      urlAttributePopup === null ||
      urlAttributePopup === '' ||
      urlAttributePopup === undefined
    ) {
      this.urlCustom = '/app-popup-attribute/index'; // default
      this.attributeCustomData = JSON.stringify(this.attributeIdList);
    } else {
      if (this.customData === null) {
        this.customData = false;
      }
      this.attributeCustomData = JSON.stringify(this.customData);
    }
  }

  buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '0.code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      { field: '1', header: 'table.column.name', customClass: 'text-center' }
    ]);
  }

  doChoose(attribute: Attribute): void {
    this.onChange.emit(attribute);
  }
}
