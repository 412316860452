import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Contract } from '../../core/bean/contract';
import { ContractDoc } from '../../core/bean/contract-doc';
import { ContractStatus } from '../../core/bean/contract-status';
import { AppPopupVersionHistoryModel } from '../../core/components/app-popup/app-popup-version-history/app-popup-version-history-model';
import { AppPopupVersionHistoryService } from '../../core/components/app-popup/app-popup-version-history/app-popup-version-history.service';
import { FileUploader } from '../../core/components/upload';
import { ContractHistoryVendorDetailResponse } from './contract-history-vendor-detail-response';

@Component({
  templateUrl: './contract-history-vendor-detail.component.html'
})
export class ContractHistoryVendorDetailComponent extends BaseModuleComponent {
  public contractId: number;
  public urlBack: string;
  public header: string;
  public token: string;
  public contract: Contract = new Contract();
  public contractDoc: ContractDoc;
  public contractStatus: ContractStatus;
  public detailResponse: ContractHistoryVendorDetailResponse;
  public model: AppPopupVersionHistoryModel;
  public modelList: AppPopupVersionHistoryModel[] = [];

  public fileUploader: FileUploader;
  public fileUploaderTermination: FileUploader = new FileUploader(
    '/contract-history=vendor/',
    'contract-history-vendor.detail.form.fileTermination',
    this.global.appConstant.fileType.DOC_CONTRACT_TERMINATION,
    false,
    5
  );

  public badgeColor = {
    DONE: 'SUCCESS',
    ON_PROGRESS: 'PROGRESS',
    CLOSED: 'DANGER',
    EXPIRED: 'DANGER',
    TERMINATED: 'DANGER',
    WAITING_FOR_TERMINATION: 'WARNING'
  };

  constructor(
    translateService: TranslateService,
    public appPopupVersionHistryService: AppPopupVersionHistoryService,
    public activatedRoute: ActivatedRoute
  ) {
    super('contract-history-vendor', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Contract>('/contract-history-vendor/notification/' + this.token)
      .subscribe((contract: Contract) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('todo', 'view');
        this.global.routerParams.set('contractId', contract.id);
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/contract-history-vendor'
        );

        this.router.navigate(['/pages/contract-history-vendor/detail']);
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.contractId = this.global.routerParams.get('contractId');
    this.urlBack = this.global.routerParams.get('urlBackOutside');
    this.header = this.global.routerParams.get('header');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      file: [null],
      fileTermination: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<ContractHistoryVendorDetailResponse>(
        '/contract-history-vendor/detail/' + this.contractId
      )
      .subscribe((response: ContractHistoryVendorDetailResponse) => {
        this.detailResponse = response;
        this.contract = response.contract;
        this.fileUploader = new FileUploader(
          '/contract-history-vendor/',
          'contract-history-vendor.detail.form.file',
          this.global.appConstant.fileType.DOC_CLOSED_CONTRACT,
          false,
          this.detailResponse.maxFileCloseContract
        );
        if (this.detailResponse.contractClosedDocList) {
          const fileList = this.detailResponse.contractClosedDocList.map(
            closedDoc => closedDoc.file
          );
          this.fileUploader.setFileList(fileList);
        }
        if (this.detailResponse.contractTerminationDocList) {
          const fileList = this.detailResponse.contractTerminationDocList.map(
            terminationDoc => terminationDoc.file
          );
          this.fileUploaderTermination.setFileList(fileList);
        }
        this.formGroup.patchValue({
          date: {
            from: this.contract.startDate,
            to: this.contract.endDate
          },
          file: this.fileUploader.fileObjectList,
          fileTermination: this.fileUploaderTermination.fileObjectList
        });
        this.formGroup.setIsView(true);
        this.contractStatus = this.contract.contractStatus;
        this.contractDoc = response.contractDoc;
        this.model = response.versionHistoryModelList[0];
        this.modelList = response.versionHistoryModelList;
        if (!this.contractDoc) {
          this.contractDoc = new ContractDoc();
          this.contractDoc.contract = this.contract;
        }
        this.setStateReady();
      });
  }

  public doViewDetailDR(): void {
    localStorage.setItem('drId', this.contract.dr.id.toString());
    localStorage.setItem('objectId', this.contract.dr.id.toString());
    localStorage.setItem('todo', 'view');
    localStorage.setItem('urlBackOutside', 'close-window');
    window.open('/pages/dr/detail');
  }

  public doClickVersionHistory(): void {
    this.appPopupVersionHistryService.open(this.modelList);
  }
}
