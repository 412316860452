import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorCertificateDetailComponent } from './vendor-certificate-detail.component';
import { VendorCertificateComponent } from './vendor-certificate.component';
const routes = [
    { path: '', component: VendorCertificateComponent, data: { breadcrumb: '' } },
    { path: 'detail', component: VendorCertificateDetailComponent, data: { breadcrumb: 'vendor-certificate.breadcrumb.detail' } }
];
@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(routes)
    ],
    declarations: [
        VendorCertificateComponent,
        VendorCertificateDetailComponent
    ],
    providers: [
    ]
})
export class VendorCertificateModule { }
