<app-popup header="dr.popup.selectDeliveredAddress" [isLoading]="formLoading">
  <form
    class="form-horizontal popup-address"
    [formGroup]="formGroup"
    novalidate
  >
    <app-select
      *ngIf="!formLoading"
      [optionList]="addressBookOptionList"
      [isMultiple]="false"
      [search]="true"
      isShowBorder="true"
      formControlName="addressBook"
    >
      <ng-template #content let-data>
        <p>{{ data.organization.name }}</p>
        <h5>{{ getUserNameList(data.addressPicList) }}</h5>
        <p>{{ data.addressDetail }}</p>
        <p>{{ data.phone }}</p>
      </ng-template>
    </app-select>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button color="PRIMARY" (onClick)="doSubmit()">{{
          'app.button.select' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
