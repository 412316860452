import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorHistoryView } from 'src/app/core/view/entity/bean/vendor-history-view';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorType } from '../../core/bean/vendor-type';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
@Component({
  templateUrl: './vendor-history.component.html',
  styles: ['.width-company-name { min-width : 250px; white-space: normal ! important }'],
  encapsulation: ViewEncapsulation.None
})
export class VendorHistoryComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<VendorHistoryView>;
  public vendorTypeOptionList: OptionListModel<VendorType> = new OptionListModel();
  public vendorTypeList: VendorType[] = [];
  public vendorTypeId: number;

  constructor(translate: TranslateService) {
    super('vendor-history', translate);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setVendorTypeList();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorType: [null]
    });
  }

  public setVendorTypeList(): void {
    this.httpClientService.get<VendorType[]>('/vendor-history/get-list-vendor-type')
      .subscribe(vendorTypeList => {
        this.vendorTypeList = vendorTypeList;
        this.vendorTypeOptionList.setRequestValues(this.vendorTypeList);
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'vendor.name', header: 'table.column.name', customClass: 'width-company-name' },
      { field: 'vendor.code', header: 'table.column.code', customClass: 'text-center' },
      { field: 'vendor.vendorType.name', header: 'table.column.vendorType', customClass: 'text-center' },
      { field: 'vendor.email', header: 'table.column.email' },
      { field: 'vendor.vendorStatus.name', header: 'table.column.status', customClass: 'text-center' },
      { field: 'totalProcurement', header: 'table.column.procurementQty', customClass: 'text-center' },
      { field: 'totalContract', header: 'table.column.contractQty', customClass: 'text-center' }
    ]);
  }

  public doDetail(vendorHistoryView: VendorHistoryView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorId', vendorHistoryView.vendor.id);
    this.router.navigate(['/pages/vendor-history/detail']);
  }

  public doApply(): void {
    this.vendorTypeId = this.formGroup.get('vendorType').value.id;
    this.tableResponse.setCustomData(this.formGroup.get('vendorType').value.id);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.vendorTypeId = null;
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }
}
