import { BaseEntity } from '../base/base-entity';
import { ScoringCriteria } from '../bean/scoring-criteria';
import { ScoringGroupTemplate } from '../bean/scoring-group-template';
import { ModuleScoringCriteria } from './../bean/module-scoring-criteria';

export class ScoringCriteriaGroupEntity extends BaseEntity {
  scoringCriteriaList: ScoringCriteria[] = [];
  weight: number;
  totalResult: number;
  scoringGroupTemplateList: ScoringGroupTemplate[] = [];
  moduleScoringCriteria: ModuleScoringCriteria;
  name: string;
  type: number;
  description: string;
}
