<div class="block-ui-wrapper" *ngIf="global.blockMsg.active">
  <div class="block-ui-backdrop"></div>
  <div class="card border-0 box-shadow">
    <!-- <div *ngIf="this.global.blockMsg.type === 'relogin'"
      class="card-body text-center pb-1">
      <div class="row">
        <div class="col-sm-12 text-center mb-2">
          <h6>Loading...</h6>
        </div>
        <div class="col-sm-12 text-center mb-2">
          <img src="assets/img/common/loader-small.gif" alt="Loading"
            height="11" width="40" />
        </div>
      </div>
    </div> -->
    <div class="block-message" *ngIf="this.global.blockMsg.type === 'error'">
      <div class="card card-danger bg-danger">
        <div class="card-header text-white">
          <em class="fas fa-exclamation-circle"></em> Error!
        </div>
        <div class="card-body bg-white">
          <em class="far fa-clone copy-error-text" title="Copy Error Text"
            (click)="doCopyErrorMessage(customTostCopyElement, contentElement)"></em>
          <p class="text-secondary" innerHTML={{this.global.blockMsg.msg}} #contentElement></p>
          <div class="custom-tost-copy" #customTostCopyElement>
            <div class="custom-tost-copy-content">
              <em class="far fa-clipboard mr-1"></em>
              Copied to clipboard!
            </div>
          </div>
        </div>
        <div class="card-footer text-right bg-white">
          <button type="button" class="btn btn-secondary mb-2" (click)="doCloseBlockUI()">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="app" [ngClass]="settings.theme.skin" [class.navbar-fixed]="settings.theme.navbarIsFixed"
  [class.sidebar-fixed]="settings.theme.sidebarIsFixed" [class.horizontal-menu]="settings.theme.menu == 'horizontal'"
  [class.compact]="settings.theme.menuType == 'compact'" [class.mini]="settings.theme.menuType == 'mini'"
  [class.menu-hide]="!settings.theme.showMenu">
  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>
</div>