import { DatePickerState } from '../model';

export const resetDatePickerTimeHtmlText = (
  state: DatePickerState,
  payload: {
    event: FocusEvent & { target: HTMLElement };
    type: 'HOURS' | 'MINUTES';
  }
): void => {
  const { event, type } = payload;
  if (
    type === 'HOURS' &&
    (!state.currentTime ||
      (state.currentTime && !state.currentTime.hoursString))
  ) {
    event.target.innerText = '';
  } else if (
    type === 'MINUTES' &&
    (!state.currentTime ||
      (state.currentTime && !state.currentTime.minutesString))
  ) {
    event.target.innerText = '';
  }
};
