<div class="row mt-3">
  <div class="col-12 mb-3" *ngIf="!formLoading">
    <div class="dashboard-card">
      <div class="dc-left">
        <img
          src="/assets/svg/dashboard/business-3d-waving-hello-man.svg"
          alt="img"
        />
      </div>
      <div class="dc-right">
        <h4>Welcome, {{ global.userSession.user.name }}</h4>
        <h2 class="font-weight-bold">
          Don't forget to check your daily task & schedule
        </h2>
        <p
          >Last Login
          {{
            lastLogin | date: global.config.parameterModel.datetimeFormatShort
          }}
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 mb-3">
    <div class="button-group button-group-right">
      <ng-container *ngIf="!isShowEditDashboard">
        <app-button [outline]="true" (onClick)="doShowEditDashboard()">
          <em class="pir pi-cog"></em>
          Setting Dashboard
        </app-button>
      </ng-container>
      <ng-container *ngIf="isShowEditDashboard">
        <app-button
          color="DANGER"
          [outline]="true"
          (onClick)="doShowEditDashboard()"
        >
          Cancel
        </app-button>
        <app-button [outline]="true" (onClick)="doSaveWidget()">
          Save Change
        </app-button>
      </ng-container>
    </div>
  </div>
</div>
<app-widget-wrapper-row
  [model]="model && model.dragAndDropValues"
  (onChange)="onChange($event)"
></app-widget-wrapper-row>
