import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { GuaranteeBondType } from '../../core/bean/guarantee-bond-type';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './guarantee-bond-type-edit-add.component.html'
})
export class GuaranteeBondTypeEditAddComponent extends BaseModuleComponent {
  public guaranteeBondTypeId: number;
  public percentageRuleOptionList: OptionListModel<any> = new OptionListModel();

  constructor(translateService: TranslateService) {
    super('guarantee-bond-type', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.doSetPercentageRuleOptionList();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.guaranteeBondTypeId = this.global.routerParams.get(
      'guaranteeBondTypeId'
    );
  }

  public doSetPercentageRuleOptionList(): void {
    const percentageRuleList = [
      {
        name: this.translateService.instant('guarantee-bond-type.form.exact'),
        value: 1
      },
      {
        name: this.translateService.instant('guarantee-bond-type.form.minimum'),
        value: 2
      },
      {
        name: this.translateService.instant('guarantee-bond-type.form.maximum'),
        value: 3
      }
    ];

    this.percentageRuleOptionList.setRequestValues(percentageRuleList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [
        null,
        {
          validators: [Validators.required(), Validators.maxLength(128)],
          asyncValidators: [
            Validators.existsAsync(
              '/guarantee-bond-type/check-exist',
              null,
              null,
              { id: this.guaranteeBondTypeId }
            )
          ]
        }
      ],
      code: [
        null,
        {
          validators: [Validators.required(), Validators.maxLength(32)],
          asyncValidators: [
            Validators.existsAsync(
              '/guarantee-bond-type/check-exist',
              null,
              null,
              { id: this.guaranteeBondTypeId }
            )
          ]
        }
      ],
      percentageValue: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.maxLength(15),
          Validators.min(1),
          Validators.max(100)
        ])
      ],
      description: [null],
      percentageRule: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<GuaranteeBondType>(
        '/guarantee-bond-type/add-edit',
        new RouteRequestModel(this.todo, this.guaranteeBondTypeId)
      )
      .subscribe(guaranteeBondType => {
        const percentageRule = this.percentageRuleOptionList
          .getRequestValues()
          .filter(
            percentageRule =>
              percentageRule.value === guaranteeBondType.percentageRule
          )[0]?.name;
        if (guaranteeBondType != null) {
          this.formGroup.patchValue(guaranteeBondType);
          this.formGroup.patchValue({
            percentageRule
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const guaranteeBondType = this.global.copyFormAttributeToModel(
              new GuaranteeBondType(),
              this.formGroup
            );
            guaranteeBondType.percentageRule = this.formGroup.value.percentageRule = this.formGroup.get(
              'percentageRule'
            ).value.value;
            const url =
              this.todo === 'edit'
                ? '/guarantee-bond-type/update'
                : '/guarantee-bond-type/insert';
            this.httpClientService
              .post<Response<GuaranteeBondType>>(url, guaranteeBondType)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/guarantee-bond-type']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/guarantee-bond-type']);
  }
}
