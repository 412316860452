<app-popup
  header="price-evaluation.popup.priceEvaluation"
  [isLoading]="formLoading"
>
  <div class="form-group row">
    <div class="col-sm-3">
      {{ 'price-evaluation.popup.label.itemName' | translate }}
    </div>
    <div class="col-sm-9">
      {{ procurementItem.prItem.item.name }}
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3">
      {{ 'price-evaluation.popup.label.itemCode' | translate }}
    </div>
    <div class="col-sm-9">
      {{ procurementItem.prItem.item.code }}
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3">
      {{ 'price-evaluation.popup.label.uom' | translate }}
    </div>
    <div class="col-sm-9">
      {{ procurementItem.prItem.item.uom.name }}
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3">
      {{ 'price-evaluation.popup.label.quantity' | translate }}
    </div>
    <div class="col-sm-9">
      {{ procurementItem.prItem.quantity | resolveNumberToCurrency }}
    </div>
  </div>
  <div class="form-group row" *ngIf="isHasOep && !formLoading">
    <div class="col-sm-3">
      {{ 'price-evaluation.popup.label.oep' | translate }}
    </div>
    <div class="col-sm-9">
      {{ popupPriceEvalResponse.hcsAmount | resolveNumberToCurrency }}&nbsp;
      <strong>IDR</strong>
    </div>
  </div>
  <div class="form-group row" *ngIf="!formLoading">
    <div class="col-sm-3">
      {{ 'price-evaluation.popup.label.upperThreshold' | translate }}
    </div>
    <div class="col-sm-9">
      {{
        popupPriceEvalResponse.upperThreshold
          ? global.converterService.convertMoney(
              popupPriceEvalResponse.upperThreshold
            ) + '%'
          : '-'
      }}
    </div>
  </div>
  <div class="form-group row" *ngIf="!formLoading">
    <div class="col-sm-3">
      {{ 'price-evaluation.popup.label.lowerThreshold' | translate }}
    </div>
    <div class="col-sm-9">
      {{
        popupPriceEvalResponse.lowerThreshold
          ? global.converterService.convertMoney(
              popupPriceEvalResponse.lowerThreshold
            ) + '%'
          : '-'
      }}
    </div>
  </div>
  <h3 class="separator"></h3>
  <app-table-xx
    [model]="tableResponse"
    [isShowSearch]="false"
    [isShowPagination]="false"
    stringUrl="/price-evaluation/table-item-price"
    *ngIf="!formLoading"
  >
  </app-table-xx>
</app-popup>
