import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppPopupProgressBarDownloadModule } from '../../components/app-popup/app-popup-progress-bar-download/app-popup-progress-bar-download.module';
import { AppWorklistInfoModule } from '../../components/app-worklist-info/app-worklist-info.module';
import { SharedModule } from '../../shared/shared.module';
import { ReportRecapRfqPopupChooseColumnComponent } from './report-recap-rfq-popup-choose-column.component';
import { ReportRecapRfqPopupFullViewComponent } from './report-recap-rfq-popup-full-view.component';
import { ReportRecapRfqComponent } from './report-recap-rfq.component';

export const routes = [
  {
    path: '',
    component: ReportRecapRfqComponent,
    data: { breadcrumb: 'report-recap-rfq.breadcrumb.index' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppWorklistInfoModule,
    AppPopupProgressBarDownloadModule
  ],
  declarations: [
    ReportRecapRfqComponent,
    ReportRecapRfqPopupFullViewComponent,
    ReportRecapRfqPopupChooseColumnComponent
  ]
})
export class ReportRecapRfqModule {}
