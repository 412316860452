import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppPopUpTOCService } from '../../core/components/app-popup/app-popup-toc/app-popup-toc.service';
import { DashboardResponse } from './dashboard-response';
import { WidgetResponseModel } from './widget/model/widget-response-model';
@Component({
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent extends BaseModuleComponent {
  public widgetModel: WidgetResponseModel = new WidgetResponseModel();
  constructor(
    translate: TranslateService,
    private appPopUpTOCService: AppPopUpTOCService
  ) {
    super('dashboard', translate);
  }

  onInit(): void {
    this.setWidgetModelState();
  }

  private setWidgetModelState(): void {
    this.httpClientService
      .get('/dashboard/load-my-dashboard')
      .subscribe((response: DashboardResponse) => {
        if (response.contentVendor != null) {
          this.showPopupTremAndCondition(response);
        }
        this.widgetModel.setWidgetList(response.userDashboardWidgetList);
        this.setStateReady();
      });
  }

  private showPopupTremAndCondition(
    dashboardResponse: DashboardResponse
  ): void {
    this.appPopUpTOCService.open(dashboardResponse);
  }
}
