import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WorkProgram } from '../../core/bean/work-program';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { WebSocketBudgetService } from './../budget-allocation-bjb/websocket-budget.service';
import { AppPopupSyncWorkProgramComponent } from './app-popup-sync-work-program.component';
import { WebSocketWorkProgramService } from './websocket-work-program.service';
@Component({
  templateUrl: './work-program.component.html',
  styleUrls: ['./work-program.component.scss'],
  providers: [
    { provide: WebSocketBudgetService },
    { provide: WebSocketWorkProgramService },
    { provide: AppPopupService }
  ],
  encapsulation: ViewEncapsulation.None
})
export class WorkProgramComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<WorkProgram>;
  public isLoadingSync: boolean;
  public isSyncRunning = false;
  public isAllowAccess = false;

  @ViewChild(AppTableComponent) table: AppTableComponent;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public websocketWorkProgramService: WebSocketWorkProgramService,
    public websocketBudgetService: WebSocketBudgetService
  ) {
    super('work-program', translateService);
  }

  public onInit(): void {
    this.initializeWebSocketWorkProgramConnection();
    this.initializeWebSocketBudgetConnection();
    this.chekedUserAccessButton();
    this.isSynchronizeRunning();
  }

  public initializeWebSocketWorkProgramConnection(): void {
    this.websocketWorkProgramService.connect().then(result => {
      if (this.isSyncRunning) {
        this.isSyncRunning = false;
        this.buildTableResponse();
      } else if (!this.isSyncRunning && result) {
        this.tableResponse.reload();
      }
      this.websocketWorkProgramService.disconnect();
      this.initializeWebSocketWorkProgramConnection();
      this.isLoadingSync = false;
    });
  }
  public initializeWebSocketBudgetConnection(): void {
    this.websocketBudgetService.connect().then(result => {
      if (this.isSyncRunning) {
        this.isSyncRunning = false;
        this.buildTableResponse();
      } else if (!this.isSyncRunning && result) {
        this.tableResponse.reload();
      }
      this.websocketBudgetService.disconnect();
      this.initializeWebSocketBudgetConnection();
      this.isLoadingSync = false;
    });
  }

  public isSynchronizeRunning(): void {
    this.httpClientService
      .get<Boolean>('/work-program/is-work-program-synchronize-running')
      .subscribe((isSynchronizeRunning: boolean) => {
        this.isSyncRunning = isSynchronizeRunning;
        this.setStateReady();
        if (!this.isSyncRunning) {
          this.buildTableResponse();
        }
      });
  }

  public chekedUserAccessButton(): void {
    if (
      this.global.config.parameterModel.bjbGiftEnableSyncDataOnUnit.includes(
        this.global.userSession.user.organization.code
      ) ||
      this.global.userSession.user.organization.parentId === null
    ) {
      this.isAllowAccess = true;
    }
  }

  /* BJB */
  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code'
      },
      { field: 'name', header: 'table.column.name' }
    ]);
  }

  /* Product */
  /* public buildTableResponse(): void {
        this.tableResponse = new TableResponseModel(
            this.moduleCode, [
            { field: 'code', header: 'table.column.code', customClass: 'text-center' },
            { field: 'name', header: 'table.column.name' },
            { field: 'organization.name', header: 'table.column.organization' },
            { field: 'activityPeriod', header: 'table.column.period', customClass: 'text-center' },       ]);
    }
    */
  /*BJB*/
  public doSync(): void {
    this.appPopupService
      .open(
        AppPopupSyncWorkProgramComponent,
        { onCloseModal: this.websocketWorkProgramService.onCloseModal },
        {
          backdrop: 'static',
          size: 'lg'
          // container: '#work-program',
          // backdropClass: 'work-program-backdrop',
          // windowClass: 'work-program-window'
        }
      )
      .subscribe();
    this.websocketWorkProgramService.appPopupSyncWorkProgramComponent =
      this.appPopupService.componentInstance;
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/work-program/add']);
  }

  public doEdit(workProgram: WorkProgram): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('workProgramId', workProgram.id);
    this.router.navigate(['/pages/work-program/edit']);
  }

  public doDelete(workProgram: WorkProgram): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<WorkProgram>>('/work-program/delete', workProgram)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
