import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ProjectDetailComponent } from './project-detail.component';
import { ProjectEditAddComponent } from './project-edit-add.component';
import { ProjectComponent } from './project.component';
const routes = [
  { path: '', component: ProjectComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: ProjectEditAddComponent,
    data: { todo: 'edit', breadcrumb: 'project.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: ProjectEditAddComponent,
    data: { todo: 'add', breadcrumb: 'project.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: ProjectDetailComponent,
    data: { breadcrumb: 'project.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgbModule],
  declarations: [
    ProjectComponent,
    ProjectEditAddComponent,
    ProjectDetailComponent
  ],
  providers: []
})
export class ProjectModule {}
