/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './app-modal-message-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppModalMessageDialogComponent {
  title: string;
  message: string;
}
