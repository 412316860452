<app-popup
  header="dashboard-widget-total-payment-bills.popup.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <form
    *ngIf="!formLoading"
    class="form-horizontal popup-total-bills"
    [formGroup]="formGroup"
    novalidate
  >
    <app-select
      [optionList]="paymentBillingOptionList"
      [isMultiple]="false"
      [search]="false"
      isShowBorder="true"
      formControlName="paymentBilling"
    >
      <ng-template #content let-data>
        <p
          >{{ data.stageOfWork.name }} -
          {{ data.sowPaymentTerm.paymentTerm.name }}</p
        >
      </ng-template>
    </app-select>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button color="PRIMARY" (onClick)="doSubmit()">{{
          'dashboard-widget-total-payment-bills.button.view' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
