<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="team-project.title" [isLoading]="formLoading"
            [isSaving]="formSaving">
            <app-table [tableResponse]="tableResponse" [isServerSide]="true"
                stringUrl="/team-project/work-plan/index">
                <ng-template #actionButtons let-rowData>
                    <app-button class="btn btn-primary btn-sm"
                        (onClick)="doEdit(rowData)"
                        title="{{ 'app.tooltip.edit' | translate }}">
                        <span class="fas fa-pencil"></span>
                    </app-button>
                </ng-template>
            </app-table>
        </app-card>
    </div>
</div>