import { BaseEntity } from '../base/base-entity';
import { PaymentMethod } from '../bean/payment-method';
import { ProcurementVendor } from '../bean/procurement-vendor';
import { VendorBank } from '../bean/vendor-bank';

export class DirectPurchasePaymentEntity extends BaseEntity {
  procurementVendor: ProcurementVendor;
  vendorBank: VendorBank;
  paymentMethod: PaymentMethod;
  invoiceNumber: string;
  invoiceDate: Date;
  billAmount: number;
  creditCardNumber: string;
}
