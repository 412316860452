<!-- 
  Source: https://codepen.io/shankarcabus/pen/GzAfb
  last access: September, 07 2021 
-->
<div class="app-session-timer d-flex">
  <div class="progress-pie-chart" [ngClass]="percentage > 50 ? 'gt-50' : ''">
    <div class="ppc-progress">
      <div
        class="ppc-progress-fill"
        [ngStyle]="{ transform: 'rotate(' + deg + 'deg)' }"
      ></div>
    </div>
    <div class="ppc-percents">
      <div class="pcc-percents-wrapper">
        <h3 class="text-duration">{{ moduleCode + '.text.duration' | translate }}</h3>
        <h2 class="fw-500 text-primary d-block">
          {{ dateInTime | date: 'HH : mm : ss':'+0' }}
        </h2>
        <app-badge
          [color]="
            startDate && endDate
              ? 'DANGER'
              : startDate
              ? 'SUCCESS'
              : 'SECONDARY'
          "
        >
          {{
            moduleCode +
              '.badge.' +
              (startDate && endDate
                ? 'end'
                : startDate
                ? 'onProgress'
                : 'notStarted') | translate
          }}
        </app-badge>
      </div>
    </div>
  </div>
  <div class="app-session-timer-action" *ngIf="isShowActionButton">
    <app-button
      color="SUCCESS"
      (onClick)="doStartSession()"
      [disabled]="!!startDate || isDisableStartButton"
    >
      {{ moduleCode + '.button.startSession' | translate }}
    </app-button>
    <app-button
      color="DANGER"
      (onClick)="doEndSession()"
      [disabled]="!startDate || isDisableEndButton"
    >
      {{ moduleCode + '.button.endSession' | translate }}
    </app-button>
  </div>
</div>
