import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { BiddingEvaluation } from '../bean/bidding-evaluation';
import { BiddingSubmissionType } from '../bean/bidding-submission-type';
import { Committee } from '../bean/committee';
import { ComplianceTestStatus } from '../bean/compliance-test-status';
import { CurrencyType } from '../bean/currency-type';
import { DocumentSubmissionMethod } from '../bean/document-submission-method';
import { DocumentSubmissionType } from '../bean/document-submission-type';
import { File } from '../bean/file';
import { NegotiationType } from '../bean/negotiation-type';
import { Organization } from '../bean/organization';
import { PriceEvaluationMethod } from '../bean/price-evaluation-method';
import { PriceEvaluationType } from '../bean/price-evaluation-type';
import { Procurement } from '../bean/procurement';
import { ProcurementAgreementType } from '../bean/procurement-agreement-type';
import { ProcurementMethod } from '../bean/procurement-method';
import { ProcurementStatus } from '../bean/procurement-status';
import { ProcurementSubmission } from '../bean/procurement-submission';
import { ProcurementType } from '../bean/procurement-type';
import { ProcurementWorklistStatus } from '../bean/procurement-worklist-status';
import { PurchaseRequest } from '../bean/purchase-request';
import { ScheduleStatus } from '../bean/schedule-status';
import { TransactionType } from '../bean/transaction-type';
import { User } from '../bean/user';
import { VendorSelectionType } from '../bean/vendor-selection-type';
import { FileObject } from '../components/upload';
import { AgreementType } from '../bean/agreement-type';
import { InitiationType } from '../bean/initiation-type';
export class ProcurementEntity extends BaseWorkflowObjectEntity {
  purchaseRequestList: PurchaseRequest[];
  fileObjectList: FileObject[];
  fileList: File[];
  procurementType: ProcurementType = new ProcurementType();
  procurementMethod: ProcurementMethod = new ProcurementMethod();
  procurementSubmission: ProcurementSubmission = new ProcurementSubmission();
  procurementManager: User = new User();
  procurementManagerOrganization: Organization = new Organization();
  procurementAgreementType: ProcurementAgreementType = new ProcurementAgreementType();
  procurementTransactionType: TransactionType = new TransactionType();
  agreementType: AgreementType = new AgreementType();

  initiationType: InitiationType;
  fromProcurement: Procurement;
  biddingEvaluation: BiddingEvaluation;
  currencyType: CurrencyType;
  documentSubmissionType: DocumentSubmissionType;
  negotiationType: NegotiationType;
  biddingSubmissionType: BiddingSubmissionType;
  priceEvaluationType: PriceEvaluationType;
  vendorSelectionType: VendorSelectionType;
  committee: Committee;
  procurementStatus: ProcurementStatus;
  procurementWorklistStatus: ProcurementWorklistStatus;
  documentSubmissionMethod: DocumentSubmissionMethod;
  scheduleStatus: ScheduleStatus;
  priceEvaluationMethod: PriceEvaluationMethod;
  complianceTestStatus: ComplianceTestStatus;

  number: string;
  stage: string;
  description: string;
  procurementDate: Date;
  isRepeatOrder: boolean;
  isManual: boolean;
  isPmGroup: boolean;
  hpsAmount: number;

  isPq: boolean;
  announcementNumber: string;
  amount: number;
  goodsAmount: number;
  serviceAmount: number;
  isRepeat: boolean;
  maxRepeatable: number;
  maxInvitingVendor: number;
  completionDate: Date;
  promiseAuctionCode: string;
}
