import { BaseEntity } from '../base/base-entity';
import { Contract } from '../bean/contract';
import { User } from '../bean/user';

export class ContractReviewerEntity extends BaseEntity {
  contract: Contract = new Contract();
  userType: number;
  user: User;
  name: string;
  email: string;
  position: string;
  permissionType: number;
  reviewByUser: User;
}
