import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartType } from 'chart.js';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ChartModel } from '../../components/app-chart/models/chart-model';
import { AppPopupService } from '../../components/app-popup/app-popup.service';
import { AppWidgetContractReportStatisticalFilterPopupComponent } from './app-widget-contract-report-statistical-filter-popup.component';
import { WidgetContractReportStatisticalRequest } from './model/widget-contract-report-statistical-request';
import { WidgetContractReportStatisticalResponse } from './model/widget-contract-report-statistical-response';
@Component({
  selector: 'app-widget-contract-report-statistical',
  templateUrl: './app-widget-contract-report-statistical.component.html',
  styleUrls: ['./app-widget-contract-report-statistical.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetContractReportStatisticalComponent extends BaseComponentComponent {
  public chartModel: ChartModel;
  public widgetContractReportStatisticalRequest: WidgetContractReportStatisticalRequest = new WidgetContractReportStatisticalRequest();
  constructor(
    private appPopupService: AppPopupService,
    public translateService: TranslateService
  ) {
    super('app-widget-contract-report-statistical');
  }

  public onInit(): void {
    this.setStateChartModel();
  }

  public setStateChartModel(): void {
    this.httpClientService
      .post<WidgetContractReportStatisticalResponse>(
        '/widget-contract-report-statistical/index',
        this.widgetContractReportStatisticalRequest
      )
      .subscribe((response: WidgetContractReportStatisticalResponse) => {
        if (response) {
          let labels = [];
          const dataSetLabels = [];
          const dataSets = [];
          response.widgetContractReportStatisticalModelResponseList.forEach(
            widgetContractReportStatisticalModelResponse => {
              if (labels.length === 0) {
                labels = [
                  ...widgetContractReportStatisticalModelResponse.data
                ].map(data => data.month);
              }
              dataSets.push({
                data: [
                  ...widgetContractReportStatisticalModelResponse.data
                ].map(data => data.total)
              });
              dataSetLabels.push(
                this.translateService.instant(
                  'app-widget-contract-per-status.' +
                    widgetContractReportStatisticalModelResponse.label
                      .toLowerCase()
                      .replace(/\s/g, '')
                      .trim()
                )
              );
            }
          );
          this.chartModel = new ChartModel(
            'line',
            labels,
            dataSets,
            dataSetLabels,
            {
              legend: {
                display: true,
                position: 'top',
                labels: {
                  usePointStyle: true
                },
                align: 'start'
              },
              defaultColor: ['#204d95 ', '#782095', '#3d9520', '#334d4d']
            }
          );
        }
        this.setStateReady();
      });
  }

  public changeType(type: ChartType): void {
    this.chartModel.setType(type);
  }

  public doOpenPopupFilter(): void {
    this.appPopupService
      .open(
        AppWidgetContractReportStatisticalFilterPopupComponent,
        {
          model: this.widgetContractReportStatisticalRequest
        },
        { windowClass: 'app-widget-contract-report-statistical-popup' }
      )
      .subscribe((response: WidgetContractReportStatisticalRequest) => {
        console.log(response);
        this.widgetContractReportStatisticalRequest = response;
        this.setStateChartModel();
      });
  }
}
