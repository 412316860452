import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Validators } from '../../validators';
import { BudgetFilterModel } from './model/budget-filter-model';
import { BudgetFilterTemplateData } from './model/budget-filter-template-data-model';
@Component({
  templateUrl: './app-budget-filter-popup.component.html'
})
export class AppBudgetFilterPopupComponent extends BaseComponentComponent {
  @Input() budgetFilterTemplateData: BudgetFilterTemplateData;
  @Input() budgetFilter: BudgetFilterModel;

  @Output() onChange: EventEmitter<object> = new EventEmitter();

  constructor(private ngbActiveModal: NgbActiveModal) {
    super('app-budget-filter-popup');
  }

  public onInit(): void {
    this.buildFormGroupState();
    this.setStateDefaultFormGroup();
    this.setStateReady();
    this.formGroup.get('dateTo').disable();
  }

  private buildFormGroupState(): void {
    this.formGroup = this.formBuilder.group({
      view: [null, Validators.required()],
      organization: [null],
      periode: [null],
      dateFrom: [null, Validators.max('dateTo')],
      dateTo: [null, Validators.min('dateFrom')]
    });
  }

  private setStateDefaultFormGroup(): void {
    this.formGroup.patchValue(this.budgetFilter.filterValue);
    this.setStateDateToValue(this.formGroup.get('dateFrom').value);
  }

  private setStateDateToValue(value: Date): void {
    if (value) {
      const date = new Date(value);
      if (date.getMonth() + 11 > 11) {
        date.setMonth(date.getMonth() - 1);
        date.setFullYear(date.getFullYear() + 1);
      } else {
        date.setMonth(11);
      }
      this.formGroup.get('dateTo').patchValue(date);
    }
  }

  public onChangeDateFrom(value: Date): void {
    this.setStateDateToValue(value);
  }

  public doApply(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.budgetFilter.setFilterValue(this.formGroup.value);
      this.onChange.emit(this.formGroup.value);
    }
  }

  public doReset(): void {
    this.formGroup.reset();
    this.budgetFilter.reset();
    this.setStateDefaultFormGroup();
    this.onChange.emit(this.formGroup.value);
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }
}
