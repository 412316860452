import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { FileObject } from 'src/app/core/components/upload';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorEntity } from '../../core/bean/vendor-entity';
import { VendorFinancialHistory } from '../../core/bean/vendor-financial-history';
import { VendorType } from '../../core/bean/vendor-type';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfileFinancialStatementDTO } from './dto/tab-profile-financial-statement.dto';
import { AppPopupFinancialStatementComponent } from './popup/app-popup-financial-statement.component';

@Component({
  templateUrl: './tab-profile-financial-statement.component.html'
})
export class TabProfileFinancialStatementComponent extends BaseModuleComponent {
  @ViewChild('selectorVendorFinancial')
  public tableVendorFinancial: AppTableComponent;
  public userId: number;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfileFinancialStatementDTO: TabProfileFinancialStatementDTO =
    new TabProfileFinancialStatementDTO();
  public vendor: Vendor = new Vendor();
  public now = new Date().getTime();
  public sessionId = null;
  public vendorTypeList: VendorType[] = [];
  public regionList: Region[] = [];
  public vendorEntityList: VendorEntity[] = [];
  public tabFinancialStatement: TabModel;
  public vendorFinancialHistory: VendorFinancialHistory;
  public returnOfEquity: string;
  public fileData: any;
  public isView: boolean;
  public fromOutside: string;
  public isVendorList: boolean;
  public yearList: string[] = [];

  public tableResponse: TableResponseModel<any>;

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-financial-statement', translate);
  }

  onInit(): void {
    this.getDataFromRouterParams();
    if (
      this.tabFinancialStatement.routerParams.get(
        'tabProfileFinancialStatementDTO'
      )
    ) {
      this.tabProfileFinancialStatementDTO =
        this.tabFinancialStatement.routerParams.get(
          'tabProfileFinancialStatementDTO'
        );
      this.getAndSetVendorToFormGroup();
      this.buildTableResponse();
      this.checkTable();
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .get<TabProfileFinancialStatementDTO>(
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_VENDOR
          ? '/tab-profile-financial-statement/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-financial-statement/index'
      )
      .subscribe(tabProfileFinancialStatementDTO => {
        this.tabProfileFinancialStatementDTO = tabProfileFinancialStatementDTO;
        this.tabFinancialStatement.routerParams.set(
          'tabProfileFinancialStatementDTO',
          tabProfileFinancialStatementDTO
        );
        this.getAndSetVendorToFormGroup();
        this.buildTableResponse();
        this.checkTable();
      });
  }

  public buildTableResponse(): void {
    if (
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
    ) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'year',
          header: 'table.column.financialYear'
        },
        {
          field: 'currentAsset',
          header: 'table.column.currentAsset',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'currentLiability',
          header: 'table.column.currentLiability',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'currentRatio',
          header: 'table.column.currentRatio',
          customClass: 'text-right'
        },
        {
          field: 'totalLiability',
          header: 'table.column.totalLiability',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'equity',
          header: 'table.column.equity',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'deptEquityRatio',
          header: 'table.column.deptEquityRatio',
          customClass: 'text-right'
        },
        {
          field: 'netIncome',
          header: 'table.column.netIncome',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'returnOfEquity',
          header: 'table.column.returnOfEquity',
          customClass: 'text-right'
        },
        {
          field: 'cash',
          header: 'table.column.cash',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'totalActiva',
          header: 'table.column.totalActiva',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'vendorFinancialStatus.name',
          header: 'table.column.financialStatus'
        },
        { field: 'auditor', header: 'table.column.auditorName' },
        {
          field: 'auditNumber',
          header: 'table.column.auditNumber'
        },
        {
          field: 'date',
          header: 'table.column.auditDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'file.fileName',
          header: 'table.column.file',
          format: FieldFormatEnum.DownloadableFile,
          urlFile: 'file.uploadedFileName'
        },
        {
          field: 'crudInfo',
          header: 'table.column.status',
          format: FieldFormatEnum.Html,
          customClass: 'text-center'
        }
      ]);
    } else {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'year',
          header: 'table.column.financialYear'
        },
        {
          field: 'currentAsset',
          header: 'table.column.currentAsset',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'currentLiability',
          header: 'table.column.currentLiability',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'currentRatio',
          header: 'table.column.currentRatio',
          customClass: 'text-right'
        },
        {
          field: 'totalLiability',
          header: 'table.column.totalLiability',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'equity',
          header: 'table.column.equity',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'deptEquityRatio',
          header: 'table.column.deptEquityRatio',
          customClass: 'text-right'
        },
        {
          field: 'netIncome',
          header: 'table.column.netIncome',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'returnOfEquity',
          header: 'table.column.returnOfEquity',
          customClass: 'text-right'
        },
        {
          field: 'cash',
          header: 'table.column.cash',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'totalActiva',
          header: 'table.column.totalActiva',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'vendorFinancialStatus.name',
          header: 'table.column.financialStatus'
        },
        { field: 'auditor', header: 'table.column.auditorName' },
        {
          field: 'auditNumber',
          header: 'table.column.auditNumber'
        },
        {
          field: 'date',
          header: 'table.column.auditDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'file.fileName',
          header: 'table.column.file',
          format: FieldFormatEnum.DownloadableFile,
          urlFile: 'file.uploadedFileName'
        }
      ]);
    }
    this.tableResponse.setRecords(
      this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList
    );
    this.tableResponse.setTotalRecords(
      this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList.length
    );
  }

  public checkTable(): void {
    if (
      this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList.length !==
      0
    ) {
      this.tabFinancialStatement.formGroup.patchValue({ isTableEmpty: false });
    } else {
      this.tabFinancialStatement.formGroup.patchValue({ isTableEmpty: true });
    }
  }

  public doAdd(): void {
    const yearList = this.yearList;
    const todo = 'add';
    this.appPopupService
      .open(
        AppPopupFinancialStatementComponent,
        { todo, yearList },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(financialStatement => {
        financialStatement.year = +financialStatement.year;
        const currentAssetNum = financialStatement.currentAsset
          .toString()
          .replace(/\$|\,/g, '');
        const currentLiabilityNum = financialStatement.currentLiability
          .toString()
          .replace(/\$|\,/g, '');
        const totalLiabilityNum = financialStatement.totalLiability
          .toString()
          .replace(/\$|\,/g, '');
        const equityNum = financialStatement.equity
          .toString()
          .replace(/\$|\,/g, '');
        const netIncomeNum = financialStatement.netIncome
          .toString()
          .replace(/\$|\,/g, '');
        const cashNum = financialStatement.cash
          .toString()
          .replace(/\$|\,/g, '');
        const totalActivaNum = financialStatement.totalActiva
          .toString()
          .replace(/\$|\,/g, '');
        financialStatement.currentRatio = financialStatement.currentRatio
          .toString()
          .includes(',')
          ? parseFloat(financialStatement.currentRatio.replace(',', ''))
          : parseFloat(financialStatement.currentRatio);
        financialStatement.deptEquityRatio = financialStatement.deptEquityRatio
          .toString()
          .includes(',')
          ? parseFloat(financialStatement.deptEquityRatio.replace(',', ''))
          : parseFloat(financialStatement.deptEquityRatio);
        this.vendorFinancialHistory = new VendorFinancialHistory();
        this.vendorFinancialHistory = financialStatement;
        this.vendorFinancialHistory.currentAsset = +currentAssetNum;
        this.vendorFinancialHistory.currentLiability = +currentLiabilityNum;
        this.vendorFinancialHistory.totalLiability = +totalLiabilityNum;
        this.vendorFinancialHistory.equity = +equityNum;
        this.vendorFinancialHistory.netIncome = +netIncomeNum;
        this.vendorFinancialHistory.cash = +cashNum;
        this.vendorFinancialHistory.totalActiva = +totalActivaNum;
        const dataFile: FileObject[] = [];
        const file = financialStatement.file;
        if (file) {
          dataFile.push(financialStatement.file[0]);
        }
        this.vendorFinancialHistory.file = file[0].isDeleted
          ? null
          : financialStatement.file[0].file;
        this.vendorFinancialHistory.fileObjectList = dataFile;
        this.returnOfEquity = financialStatement.roe.toString() + ' %';
        this.vendorFinancialHistory.returnOfEquity = this.returnOfEquity;
        this.yearList.push(this.vendorFinancialHistory.year.toString());
        this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList.push(
          this.vendorFinancialHistory
        );
        this.tableResponse.setRecords(
          this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList
        );
        this.tableResponse.setTotalRecords(
          this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList.length
        );
        this.tableResponse.reloadClient();
        this.checkTable();
      });
  }

  public doEdit(vendorFinancialHistoryEdit: VendorFinancialHistory): void {
    const todo = 'edit';
    const yearList = this.yearList;
    this.appPopupService
      .open(
        AppPopupFinancialStatementComponent,
        { todo, yearList, vendorFinancialHistoryEdit },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(financialStatement => {
        this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList.forEach(
          (vendorFinancial, index) => {
            if (vendorFinancial === vendorFinancialHistoryEdit) {
              financialStatement.year = +financialStatement.year;
              const currentAssetNum = financialStatement.currentAsset
                .toString()
                .replace(/\$|\,/g, '');
              const currentLiabilityNum = financialStatement.currentLiability
                .toString()
                .replace(/\$|\,/g, '');
              const totalLiabilityNum = financialStatement.totalLiability
                .toString()
                .replace(/\$|\,/g, '');
              const equityNum = financialStatement.equity
                .toString()
                .replace(/\$|\,/g, '');
              const netIncomeNum = financialStatement.netIncome
                .toString()
                .replace(/\$|\,/g, '');
              const cashNum = financialStatement.cash
                .toString()
                .replace(/\$|\,/g, '');
              const totalActivaNum = financialStatement.totalActiva
                .toString()
                .replace(/\$|\,/g, '');
              financialStatement.currentRatio = financialStatement.currentRatio
                .toString()
                .includes(',')
                ? parseFloat(financialStatement.currentRatio.replace(',', ''))
                : parseFloat(financialStatement.currentRatio);
              financialStatement.deptEquityRatio =
                financialStatement.deptEquityRatio.toString().includes(',')
                  ? parseFloat(
                      financialStatement.deptEquityRatio.replace(',', '')
                    )
                  : parseFloat(financialStatement.deptEquityRatio);
              this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList[
                index
              ] = financialStatement;
              this.yearList[index] = financialStatement.year.toString();
              const dataFile: FileObject[] = [];
              const file = financialStatement.file;
              if (file) {
                dataFile.push(financialStatement.file[0]);
              }
              this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList[
                index
              ].file =
                file.length !== undefined
                  ? file[0].isDeleted
                    ? null
                    : file[0].file
                  : financialStatement.file;
              this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList[
                index
              ].fileObjectList =
                file.length !== undefined
                  ? dataFile
                  : financialStatement.fileUploader
                  ? financialStatement.fileUploader.fileObjectList
                  : [];
              this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList[
                index
              ].currentAsset = +currentAssetNum;
              this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList[
                index
              ].currentLiability = +currentLiabilityNum;
              this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList[
                index
              ].totalLiability = +totalLiabilityNum;
              this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList[
                index
              ].equity = +equityNum;
              this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList[
                index
              ].netIncome = +netIncomeNum;
              this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList[
                index
              ].cash = +cashNum;
              this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList[
                index
              ].totalActiva = +totalActivaNum;
              this.returnOfEquity = financialStatement.roe.toString() + ' %';
              this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList[
                index
              ].returnOfEquity = this.returnOfEquity;
            }
          }
        );
        this.tableResponse.setRecords(
          this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList
        );
        this.tableResponse.setTotalRecords(
          this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList.length
        );
        this.tableResponse.reloadClient();
      });
  }

  public doView(vendorFinancialHistoryEdit: VendorFinancialHistory): void {
    const todo = 'detail';
    this.appPopupService.open(
      AppPopupFinancialStatementComponent,
      { todo, vendorFinancialHistoryEdit },
      { size: 'lg' }
    );
  }

  public doDelete(vendorFinancialHistoryDelete: VendorFinancialHistory): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList.forEach(
            (vendorFinancial, index) => {
              if (vendorFinancial === vendorFinancialHistoryDelete) {
                this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList.splice(
                  index,
                  1
                );
                this.yearList.splice(index, 1);
              }
            }
          );
          this.tableResponse.page.records =
            this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList;
          this.tableResponse.page.totalRecords =
            this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList.length;
          this.tableResponse.reloadClient();
          this.checkTable();
        }
      });
  }

  public getDataFromRouterParams(): void {
    this.tabFinancialStatement = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_FINANCIAL_STATEMENT);
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.todo = this.global.routerParams.get('todo');
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
  }

  public getAndSetVendorToFormGroup(): void {
    this.tabFinancialStatement.formGroup.patchValue(this.companyProfileDTO);
    this.tabProfileFinancialStatementDTO.vendorFinancialHistoryList.forEach(
      vendorFinancialHistory => {
        this.yearList.push(vendorFinancialHistory.year.toString());
      }
    );
    this.setStateReady();
  }
}
