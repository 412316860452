import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../core/shared/shared.module';
import { DueDiligenceComponent } from './due-diligence.component';
const routes: Routes = [{ path: '', component: DueDiligenceComponent, data: { breadcrumb: '' } }];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [DueDiligenceComponent]
})
export class DueDiligenceModule {}
