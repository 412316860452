import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupChangeDefinitionComponent } from './app-popup-change-definition.component';
import { AppPopupMoveUserComponent } from './app-popup-move-user.component';
import { WorkloadEditComponent } from './workload-edit.component';
import { WorkloadComponent } from './workload.component';

const routes = [
  {
    path: '',
    component: WorkloadComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: WorkloadEditComponent,
    data: { breadcrumb: 'workload.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    WorkloadComponent,
    WorkloadEditComponent,
    AppPopupChangeDefinitionComponent,
    AppPopupMoveUserComponent
  ],
  entryComponents: [
    AppPopupChangeDefinitionComponent,
    AppPopupMoveUserComponent
  ]
})
export class WorkloadModule {}
