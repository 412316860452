<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-xx
        header="procurement-content-management.title"
        [model]="tableResponse"
        stringUrl="/procurement-content-management/index"
        [isShowCheckBox]="false"
        (onClick)="doEdit($event)"
      >
      </app-table-xx>
    </div>
  </div>
</app-layout>
