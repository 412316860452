import { BaseEntity } from '../base/base-entity';
import { BillingMemo } from '../bean/billing-memo';
import { CostCenter } from '../bean/cost-center';
import { Tax } from '../bean/tax';

export class BillingMemoTaxEntity extends BaseEntity {
    billingMemo: BillingMemo;
    costCenter: CostCenter;
    tax: Tax;
    taxBaseAmount: number;
    incomeTaxAmount: number;
    taxPercentageValue: number;
}
