import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Rfp } from '../../core/bean/rfp';
import { RfpStatus } from '../../core/bean/rfp-status';
import { AppTableXComponent } from '../../core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
@Component({
  templateUrl: './rfp.component.html'
})
export class RfpComponent extends BaseModuleComponent {
  @ViewChild(AppTableXComponent) table: AppTableXComponent;
  public tableResponse: TableResponseModel<Rfp>;
  public statusOptionList: OptionListModel<RfpStatus> = new OptionListModel(
    false,
    'name'
  );
  public filter: string;
  public customData = '';
  constructor(translateService: TranslateService) {
    super('rfp', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
    this.doSetCustomData();
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      rfpDate: [null],
      rfpFromDate: [null],
      rfpUntilDate: [null],
      dueDate: [null],
      dueFromDate: [null],
      dueUntilDate: [null],
      statusList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<RfpStatus[]>('/rfp/get-option-list')
      .subscribe(response => {
        if (response != null) {
          this.statusOptionList.setRequestValues(response);
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'code',
          header: 'table.column.rfpNumber',
          plugins: {
            name: 'hyperlink',
            onClick: this.doEdit.bind(this)
          }
        },
        {
          field: 'title',
          header: 'table.column.title'
        },
        {
          field: 'startDate',
          header: 'table.column.rfpDate',
          plugins: 'date'
        },
        {
          field: 'endDate',
          header: 'table.column.responseDueDate',
          plugins: 'date'
        },
        {
          field: 'rfpStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            colorField: 'rfpStatus.code',
            colorMap: {
              DRAFT: 'SECONDARY',
              REVISION: 'FEEDBACK',
              WAITING_APPROVAL: 'WARNING',
              WAITING_RESPONSE: 'GOOD',
              CANCELED: 'DANGER',
              CLOSED_RESPONSE: 'DANGER',
              DONE: 'SUCCESS',
              COMPLETED: 'SUCCESS',
              WAITING_RFQ: 'WARNING',
              RFQ_PROCESS: 'SUCCESS'
            }
          }
        }
      ],
      { checkBoxFn: this.isShowCheckBox.bind(this) }
    );
  }

  public isShowCheckBox(record: Rfp): boolean {
    let isStatusDraft = false;
    if (record.rfpStatus.code !== this.global.appConstant.pm.RFP_STATUS_DRAFT) {
      isStatusDraft = false;
    } else {
      isStatusDraft = true;
    }
    return isStatusDraft;
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/rfp/add']);
    this.global.routerParams.set('urlBackOutside', '/pages/rfp');
  }

  doEdit(rfp: Rfp): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('rfp', rfp);
    this.global.routerParams.set('rfpId', rfp.id);
    this.global.routerParams.set('urlBackOutside', '/pages/rfp');
    if (
      rfp.rfpStatus.code === this.global.appConstant.pm.RFP_STATUS_DRAFT ||
      (rfp.rfpStatus.code === this.global.appConstant.pm.RFP_STATUS_REVISION &&
        rfp.workflowPrcs.code === 'RFP_SUBMISSION')
    ) {
      this.router.navigate(['/pages/rfp/edit']);
      this.global.routerParams.set('todo', 'edit');
    } else {
      this.router.navigate(['/pages/rfp/detail']);
      this.global.routerParams.set('todo', 'view');
    }
  }

  public doDelete(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Rfp[]>>('/rfp/delete', event.selectedRecordList)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customFilter = {
        statusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customFilter);
      this.tableResponse.reload();
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.rfpDate) {
      this.formGroup.patchValue({
        rfpFromDate: this.formGroup.value.rfpDate.from,
        rfpUntilDate: this.formGroup.value.rfpDate.to
      });
    } else {
      this.formGroup.patchValue({
        rfpFromDate: null,
        rfpUntilDate: null
      });
    }
    if (this.formGroup.value.dueDate) {
      this.formGroup.patchValue({
        dueFromDate: this.formGroup.value.dueDate.from,
        dueUntilDate: this.formGroup.value.dueDate.to
      });
    } else {
      this.formGroup.patchValue({
        dueFromDate: null,
        dueUntilDate: null
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
