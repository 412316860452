<app-popup
  header="app-catalog-detail-info.popupBulkPrice.title"
  [isShowHeaderCloseButton]="true"
  [isLoading]="formLoading"
>
  <ng-container *ngIf="!formLoading">
    <div class="col">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <!-- <div class="form-group row text-sm-left mb-1">
          <label class="col-sm-4">
            {{ 'app-catalog-detail-info.form.contractNumber' | translate }}
          </label>
          <div class="col">
            {{ catalogContract.contract.code }}
          </div>
        </div> -->
        <h5 class="separator mb-4"></h5>

        <app-table-xx
          [model]="tableResponse"
          [isShowPagination]="false"
        ></app-table-xx>
      </form>
    </div>
  </ng-container>
</app-popup>
