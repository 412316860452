<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="committee.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table-x
        [model]="tableResponse"
        stringUrl="/committee/index"
        (onClick)="doClick($event)"
      >
        <ng-template #headerButtons>
          <app-button
            (onClick)="doAdd()"
            title="{{ 'app.button.add' | translate }}"
          >
            <em class="fas fa-plus"></em>&nbsp;
            {{ 'committee.index.placeholder.newCommittee' | translate }}
          </app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            (onClick)="doDelete(rowData)"
            title="{{ 'app.tooltip.delete' | translate }}"
          >
            <em class="pir pi-trash-alt"></em>
            {{ 'app.button.delete' | translate }}
          </app-button>
        </ng-template>
      </app-table-x>
    </app-card>
  </div>
</div>
