import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorDocMaster } from '../../core/bean/vendor-doc-master';
import { VendorType } from '../../core/bean/vendor-type';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './vendor-document.component.html'
})
export class VendorDocumentComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;

  public tableResponse: TableResponseModel<VendorDocMaster>;
  public readonly urlRouterNavigateVendorDocMaster = '/pages/vendor-doc-master/edit';

  constructor(translateService: TranslateService) {
    super('vendor-document', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code', customClass: 'text-center' },
      { field: 'name', header: 'table.column.vendorType', customClass: 'text-center' }
    ]);
  }

  public doEdit(vendorType: VendorType): void {
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('vendorTypeId', vendorType.id);
    this.global.routerParams.set('vendorName', vendorType.name);
    this.router.navigate([this.urlRouterNavigateVendorDocMaster]);
  }
}
