import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Pr } from 'src/app/core/bean/pr';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { EvaluationCriteria } from '../../core/bean/evaluation-criteria';
import { Module } from '../../core/bean/module';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementCriteria } from '../../core/bean/procurement-criteria';
import { ProcurementCriteriaDetail } from '../../core/bean/procurement-criteria-detail';
import { ProcurementFinalizationCriteria } from '../../core/bean/procurement-finalization-criteria';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';
import { AppTableComponent } from '../../core/components/table/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { Validators } from '../../core/validators';
import { EvaluationCriteriaRequest } from './evaluation-criteria-request';
import { EvaluationCriteriaResponse } from './evaluation-criteria-response';
import { PopupAddAdministrationCriteriaComponent } from './popup-add-administration-criteria.component';
import { PopupAddTechnicalCriteriaComponent } from './popup-add-technical-criteria.component';

@Component({
  templateUrl: './evaluation-criteria-edit-add.component.html'
})
export class EvaluationCriteriaEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild('selectorAdministration')
  tableAdministration: AppTableComponent;
  @ViewChild('selectorTechnical')
  tableTechnical: AppTableComponent;

  public objectId: number;
  public procurementId: number;
  public totalTechCriteria: number;
  public approvalModelPrcsId: number;
  public totalTechAndPricePercentage: number;
  public urlBackOutside: string;
  public isSubmit = false;
  public module: Module = new Module();
  public procurement: Procurement = new Procurement();
  public workflowStepInfoModel: WorkflowStepInfoModel =
    new WorkflowStepInfoModel();
  public procurementCriteria: ProcurementCriteria = new ProcurementCriteria();
  public evaluationCriteriaResponse: EvaluationCriteriaResponse =
    new EvaluationCriteriaResponse();
  public isShowBadgeStatus: boolean;

  public admProcurementCriteriaDetailList: ProcurementCriteriaDetail[] = [];
  public tecProcurementCriteriaDetailList: ProcurementCriteriaDetail[] = [];
  public delProcurementCriteriaDetailList: ProcurementCriteriaDetail[] = [];
  public procurementFinalizationCriteria: ProcurementFinalizationCriteria =
    new ProcurementFinalizationCriteria();
  public administrationCriteriaOptList: EvaluationCriteria[] = [];
  public technicalCriteriaOptList: EvaluationCriteria[] = [];

  public tableResponseAdministration: TableResponseModel<ProcurementCriteriaDetail>;
  public tableResponseTechnical: TableResponseModel<ProcurementCriteriaDetail>;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public snackbarService: SnackbarService
  ) {
    super('evaluation-criteria', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = localStorage.getItem('todo')
      ? localStorage.getItem('todo')
      : this.global.routerParams.get('todo');
    this.procurementId = localStorage.getItem('procurementId')
      ? localStorage.getItem('procurementId')
      : this.global.routerParams.get('procurementId');
    this.procurement = this.global.routerParams.get('procurement');
    this.urlBackOutside = localStorage.getItem('urlBackOutside')
      ? localStorage.getItem('urlBackOutside')
      : this.global.routerParams.get('urlBackOutside');
    this.objectId = this.global.routerParams.get('objectId');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.todo = this.procurement?.isRepeat ? 'edit' : this.todo;
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildTableResponse(): void {
    this.tableResponseAdministration = new TableResponseModel(this.moduleCode, [
      {
        field: 'evaluationCriteria.name',
        header: 'table.column.administrationCriteria'
      }
    ]);
    if (
      this.procurement?.biddingEvaluation.code ===
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
    ) {
      this.tableResponseTechnical = new TableResponseModel(this.moduleCode, [
        {
          field: 'evaluationCriteria.name',
          header: 'table.column.technicalCriteria'
        }
      ]);
    } else if (
      this.procurement?.biddingEvaluation.code !==
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM &&
      this.todo !== 'view'
    ) {
      this.tableResponseTechnical = new TableResponseModel(this.moduleCode, [
        {
          field: 'evaluationCriteria.name',
          header: 'table.column.technicalCriteria'
        },
        {
          field: 'score',
          header: 'table.column.maximumScore',
          className: 'text-center',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            validators: Validators.required(),
            onInput: this.doOnChangeScore.bind(this),
            onChange: this.doOnChangeScore.bind(this)
          }
        }
      ]);
    } else if (
      this.procurement?.biddingEvaluation.code !==
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM &&
      this.todo === 'view'
    ) {
      this.tableResponseTechnical = new TableResponseModel(this.moduleCode, [
        {
          field: 'evaluationCriteria.name',
          header: 'table.column.technicalCriteria'
        },
        {
          field: 'score',
          header: 'table.column.maximumScore',
          className: 'text-center',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        }
      ]);
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      total: [
        null,
        this.procurement.biddingEvaluation.code ===
          this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT ||
        this.procurement.biddingEvaluation.code ===
          this.global.appConstant.pm.BIDDING_EVALUATION_CODE_PASSING_GRADE
          ? Validators.compose([
              Validators.min(100, 'Total score must be 100'),
              Validators.max(100, 'Total score must be 100'),
              Validators.required('Total score must be 100')
            ])
          : null
      ],
      minimumScore: [null],
      isAppliedFinalScoring: [null],
      isPricingThreshold: [true],
      upperThreshold: [null, Validators.max(100)],
      lowerThreshold: [null, Validators.max(100)],
      pricePercentage: [null],
      technicalPercentage: [null],
      adminList: this.tableResponseAdministration.formArray,
      techList: this.tableResponseTechnical.formArray
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<EvaluationCriteriaResponse>(
        '/evaluation-criteria/add-edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((response: EvaluationCriteriaResponse) => {
        this.evaluationCriteriaResponse = response;
        this.technicalCriteriaOptList = response.technicalCriteriaOptList;
        this.administrationCriteriaOptList =
          response.administrationCriteriaOptList;
        if (!this.procurement) {
          this.procurement = response.procurement;
        }

        if (this.todo !== 'add') {
          this.module = response.module;
          this.totalTechCriteria = response.procurementCriteria?.total || 0;
          this.procurementCriteria = response.procurementCriteria;
          this.admProcurementCriteriaDetailList =
            response.admProcurementCriteriaDetailList;
          this.tecProcurementCriteriaDetailList =
            response.tecProcurementCriteriaDetailList;
          this.procurementFinalizationCriteria =
            response.procurementFinalizationCriteria;

          this.tableResponseAdministration.setRecordList(
            this.admProcurementCriteriaDetailList
          );
          this.tableResponseTechnical.setRecordList(
            this.tecProcurementCriteriaDetailList
          );

          if (
            this.procurementCriteria &&
            this.procurementFinalizationCriteria
          ) {
            const { total, minimumScore, isAppliedFinalScoring } =
              this.procurementCriteria;
            const {
              upperThreshold,
              lowerThreshold,
              pricePercentage,
              technicalPercentage,
              isPricingThreshold
            } = this.procurementFinalizationCriteria;
            this.formGroup.patchValue({
              total,
              minimumScore,
              isAppliedFinalScoring,
              isPricingThreshold,
              upperThreshold,
              lowerThreshold,
              pricePercentage,
              technicalPercentage
            });
          }

          if (
            this.procurement.biddingEvaluation.code !==
            this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
          ) {
            this.setTechnicalCriteriaValue();
          }
        } else {
          this.formGroup.patchValue({
            isPricingThreshold: true
          });
        }

        if (!this.formGroup.get('isPricingThreshold').value) {
          this.formGroup.get('upperThreshold').disable();
          this.formGroup.get('lowerThreshold').disable();
        } else {
          this.formGroup.get('upperThreshold').clearValidators();
          this.formGroup.get('lowerThreshold').clearValidators();
          this.formGroup
            .get('upperThreshold')
            .setValidators([Validators.required(), Validators.max(100)]);
          this.formGroup
            .get('lowerThreshold')
            .setValidators([Validators.required(), Validators.max(100)]);

          this.formGroup.get('upperThreshold').updateValueAndValidity();
          this.formGroup.get('lowerThreshold').updateValueAndValidity();
        }
        if (
          this.procurement.biddingEvaluation.code ===
          this.global.appConstant.pm.BIDDING_EVALUATION_CODE_PASSING_GRADE
        ) {
          this.formGroup.patchValue({
            isAppliedFinalScoring: false
          });
          this.formGroup.get('isAppliedFinalScoring').setIsView(true);
          this.formGroup.get('minimumScore').clearValidators();
          this.formGroup
            .get('minimumScore')
            .setValidators([Validators.required(), Validators.max(100)]);
          this.formGroup.get('minimumScore').updateValueAndValidity();
        } else if (
          this.procurement.biddingEvaluation.code ===
          this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ) {
          this.formGroup.patchValue({
            isAppliedFinalScoring: false
          });
          this.formGroup.get('minimumScore').clearValidators();
          this.formGroup.get('pricePercentage').clearValidators();
          this.formGroup.get('technicalPercentage').clearValidators();
          this.formGroup
            .get('pricePercentage')
            .setValidators([Validators.required(), Validators.max(100)]);
          this.formGroup
            .get('technicalPercentage')
            .setValidators([Validators.required(), Validators.max(100)]);
          this.formGroup
            .get('minimumScore')
            .setValidators([Validators.required(), Validators.max(100)]);

          this.formGroup.get('minimumScore').updateValueAndValidity();
          this.formGroup.get('pricePercentage').updateValueAndValidity();
          this.formGroup.get('technicalPercentage').updateValueAndValidity();
        }
        this.setStateReady();
      });
  }

  public setTechnicalCriteriaValue(): void {
    this.tableResponseTechnical.reload();
    this.totalTechCriteria = 0;
    this.formGroup.get('total').disable();
    this.tableResponseTechnical.getRecordList().forEach(element => {
      this.totalTechCriteria += +element.score;
    });
    this.formGroup.patchValue({
      total: this.totalTechCriteria
    });
    this.tableResponseTechnical.reload();
  }

  public doAddAdministration(): void {
    const administrationCriteriaList = [];
    const administrationCriteriaOptList = this.administrationCriteriaOptList;
    if (
      this.admProcurementCriteriaDetailList &&
      this.admProcurementCriteriaDetailList.length > 0
    ) {
      this.admProcurementCriteriaDetailList.forEach(element => {
        administrationCriteriaList.push(element.evaluationCriteria);
      });
    } else {
      this.admProcurementCriteriaDetailList = [];
    }
    this.appPopupService
      .open(PopupAddAdministrationCriteriaComponent, {
        administrationCriteriaList,
        administrationCriteriaOptList
      })
      .subscribe((administrationCriteriaList: EvaluationCriteria[]) => {
        const newAdmProcCriteriaList: ProcurementCriteriaDetail[] = [];
        administrationCriteriaList.forEach(element => {
          if (
            !this.admProcurementCriteriaDetailList.find(
              x => x.evaluationCriteria.id === element.id
            )
          ) {
            const proCriteriaDetail = new ProcurementCriteriaDetail();
            proCriteriaDetail.evaluationCriteria = element;
            proCriteriaDetail.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
            newAdmProcCriteriaList.push(proCriteriaDetail);
          } else {
            const proCriteriaDetail: ProcurementCriteriaDetail =
              this.admProcurementCriteriaDetailList.find(
                x => x.evaluationCriteria.id === element.id
              );
            if (proCriteriaDetail.id) {
              proCriteriaDetail.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              proCriteriaDetail.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
            newAdmProcCriteriaList.push(proCriteriaDetail);
          }
        });
        this.admProcurementCriteriaDetailList.forEach(procCriteria => {
          if (
            procCriteria.id &&
            !administrationCriteriaList.includes(
              procCriteria.evaluationCriteria
            )
          ) {
            if (
              this.delProcurementCriteriaDetailList.filter(
                el =>
                  el.evaluationCriteria.id ===
                  procCriteria.evaluationCriteria.id
              ).length === 0
            ) {
              procCriteria.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_DELETE;
              this.delProcurementCriteriaDetailList.push(procCriteria);
            }
          }
        });

        this.admProcurementCriteriaDetailList = newAdmProcCriteriaList;
        this.tableResponseAdministration.setRecordList(newAdmProcCriteriaList);
        this.tableResponseAdministration.reload();
        this.formGroup.patchValue({
          adminList: newAdmProcCriteriaList
        });
        this.formGroup.markAsDirty();
      });
  }

  public doDeleteAdministration(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const procurementCriteriaDetailList =
            this.tableResponseAdministration.getRecordList();
          event.selectedRecordList.forEach(
            (record: ProcurementCriteriaDetail) => {
              const indexOfRecord = this.tableResponseAdministration
                .getRecordList()
                .findIndex(
                  r => r.evaluationCriteria.id === record.evaluationCriteria.id
                );
              if (indexOfRecord !== -1) {
                const proCriteriaDetail =
                  this.admProcurementCriteriaDetailList.find(
                    x =>
                      x.evaluationCriteria.id === record.evaluationCriteria.id
                  );
                proCriteriaDetail.crudOperation =
                  this.global.appConstant.core.CRUD_OPERATION_DELETE;
                if (
                  proCriteriaDetail.id &&
                  this.delProcurementCriteriaDetailList.filter(
                    e => e.id === proCriteriaDetail.id
                  ).length === 0
                ) {
                  this.delProcurementCriteriaDetailList.push(proCriteriaDetail);
                }

                procurementCriteriaDetailList.splice(indexOfRecord, 1);
              }
            }
          );
          this.tableResponseAdministration.setRecordList(
            procurementCriteriaDetailList
          );
          this.admProcurementCriteriaDetailList =
            this.tableResponseAdministration.getRecordList();
          this.tableResponseAdministration.reload();
          this.formGroup.markAsDirty();
        }
      });
  }

  public doAddTechnical(): void {
    const technicalCriteriaList = [];
    const technicalCriteriaOptList = this.technicalCriteriaOptList;
    if (
      this.tecProcurementCriteriaDetailList &&
      this.tecProcurementCriteriaDetailList.length > 0
    ) {
      this.tecProcurementCriteriaDetailList.forEach(element => {
        technicalCriteriaList.push(element.evaluationCriteria);
      });
    } else {
      this.tecProcurementCriteriaDetailList = [];
    }
    this.appPopupService
      .open(PopupAddTechnicalCriteriaComponent, {
        technicalCriteriaList,
        technicalCriteriaOptList
      })
      .subscribe(technicalCriteriaList => {
        const newTecProcCriteriaList: ProcurementCriteriaDetail[] = [];
        technicalCriteriaList.forEach(element => {
          if (
            !this.tecProcurementCriteriaDetailList.find(
              x => x.evaluationCriteria.id === element.id
            )
          ) {
            const proCriteriaDetail = new ProcurementCriteriaDetail();
            proCriteriaDetail.evaluationCriteria = element;
            proCriteriaDetail.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
            if (
              this.procurement.biddingEvaluation.code !==
              this.global.appConstant.pm
                .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
            ) {
              proCriteriaDetail.score = 0;
            }
            newTecProcCriteriaList.push(proCriteriaDetail);
          } else {
            const proCriteriaDetail: ProcurementCriteriaDetail =
              this.tecProcurementCriteriaDetailList.find(
                x => x.evaluationCriteria.id === element.id
              );
            if (proCriteriaDetail.id) {
              proCriteriaDetail.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              proCriteriaDetail.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
            newTecProcCriteriaList.push(proCriteriaDetail);
          }
        });

        this.tecProcurementCriteriaDetailList.forEach(procCriteria => {
          if (
            procCriteria.id &&
            !technicalCriteriaList.includes(procCriteria.evaluationCriteria)
          ) {
            if (
              this.delProcurementCriteriaDetailList.filter(
                el =>
                  el.evaluationCriteria.id ===
                  procCriteria.evaluationCriteria.id
              ).length === 0
            ) {
              procCriteria.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_DELETE;
              this.delProcurementCriteriaDetailList.push(procCriteria);
            }
          }
        });

        this.tecProcurementCriteriaDetailList = newTecProcCriteriaList;

        this.tableResponseTechnical.setRecordList(
          this.tecProcurementCriteriaDetailList
        );
        this.tableResponseTechnical.reload();
        if (
          this.procurement.biddingEvaluation.code !==
          this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
        ) {
          this.totalTechCriteria = 0;
          this.tecProcurementCriteriaDetailList.forEach(element => {
            this.totalTechCriteria += element.score;
          });
          this.formGroup.get('total').patchValue(this.totalTechCriteria);
          this.formGroup.get('total').disable();
        }
        this.formGroup.markAsDirty();
      });
  }

  public doDeleteTechnical(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach(
            (record: ProcurementCriteriaDetail) => {
              const indexOfRecord = this.tableResponseTechnical
                .getRecordList()
                .findIndex(
                  r => r.evaluationCriteria.id === record.evaluationCriteria.id
                );
              if (indexOfRecord !== -1) {
                const proCriteriaDetail =
                  this.tecProcurementCriteriaDetailList.find(
                    x =>
                      x.evaluationCriteria.id === record.evaluationCriteria.id
                  );
                proCriteriaDetail.crudOperation =
                  this.global.appConstant.core.CRUD_OPERATION_DELETE;

                if (
                  proCriteriaDetail.id &&
                  this.delProcurementCriteriaDetailList.filter(
                    e => e.id === proCriteriaDetail.id
                  ).length === 0
                ) {
                  this.delProcurementCriteriaDetailList.push(proCriteriaDetail);
                }
                this.tecProcurementCriteriaDetailList.splice(indexOfRecord, 1);
              }
              this.tableResponseTechnical.setRecordList(
                this.tecProcurementCriteriaDetailList
              );
              this.tableResponseTechnical.reload();
            }
          );
          if (
            this.procurement.biddingEvaluation.code !==
            this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
          ) {
            this.totalTechCriteria = 0;
            this.tecProcurementCriteriaDetailList.forEach(element => {
              this.totalTechCriteria += element.score;
            });
            this.formGroup.patchValue({
              total: this.totalTechCriteria
            });
            this.formGroup.markAsDirty();
          }
        }
        this.formGroup.markAsDirty();
      });
  }

  public doOnChangePricingThreshold(): void {
    if (this.formGroup.get('isPricingThreshold').value) {
      this.formGroup.get('upperThreshold').enable();
      this.formGroup.get('lowerThreshold').enable();
      this.formGroup
        .get('upperThreshold')
        .setValidators([Validators.required(), Validators.max(100)]);
      this.formGroup
        .get('lowerThreshold')
        .setValidators([Validators.required(), Validators.max(100)]);
      this.formGroup.get('upperThreshold').updateValueAndValidity();
      this.formGroup.get('lowerThreshold').updateValueAndValidity();
    } else {
      this.formGroup.get('upperThreshold').clearValidators();
      this.formGroup.get('lowerThreshold').clearValidators();
      this.formGroup.get('upperThreshold').updateValueAndValidity();
      this.formGroup.get('lowerThreshold').updateValueAndValidity();
      this.formGroup.patchValue({
        upperThreshold: 0,
        lowerThreshold: 0
      });
    }
  }

  public doOnChangeAppliedFinalScore(): void {
    if (
      this.procurement.biddingEvaluation.code ===
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
    ) {
      if (
        this.formGroup.get('isAppliedFinalScoring').value &&
        this.formGroup.get('technicalPercentage')
      ) {
        this.formGroup.get('minimumScore').clearValidators();
        this.formGroup
          .get('minimumScore')
          .setValidators([
            Validators.required(),
            Validators.max('technicalPercentage')
          ]);
        this.formGroup.get('minimumScore').updateValueAndValidity();
      } else {
        this.formGroup.get('minimumScore').clearValidators();
        this.formGroup
          .get('minimumScore')
          .setValidators([Validators.required(), Validators.max(100)]);
        this.formGroup.get('minimumScore').updateValueAndValidity();
      }
    }
  }

  public doOnChangetechPricingPercentage(): void {
    if (
      this.formGroup.get('technicalPercentage').value &&
      this.formGroup.get('pricePercentage').value
    ) {
      const technicalPercentage = this.formGroup.get(
        'technicalPercentage'
      ).value;
      const pricePercentage = this.formGroup.get('pricePercentage').value;
      this.totalTechAndPricePercentage = technicalPercentage + pricePercentage;
      const validatorPrice = 100 - technicalPercentage;
      this.formGroup.get('pricePercentage').clearValidators();
      this.formGroup
        .get('pricePercentage')
        .setValidators([
          Validators.required(),
          Validators.max(validatorPrice),
          Validators.min(validatorPrice)
        ]);
      this.formGroup.get('pricePercentage').updateValueAndValidity();
      this.doOnChangeAppliedFinalScore();
    }
  }

  public doOnChangeScore(): void {
    this.totalTechCriteria = 0;
    this.formGroup.get('total').disable();
    const currentRowChange = this.tableResponseTechnical.currentRowChange;
    this.tecProcurementCriteriaDetailList.forEach(element => {
      if (
        element.evaluationCriteria.id ===
        currentRowChange.row.record.evaluationCriteria.id
      ) {
        element.score = +currentRowChange.row.formGroup.value?.score;
      }
      this.totalTechCriteria += element.score;
    });

    this.formGroup.patchValue({
      total: this.totalTechCriteria
    });
    this.formGroup.markAsDirty();
  }

  public doSave(): void {
    this.validate();
    if (
      this.formGroup.valid &&
      this.admProcurementCriteriaDetailList.length > 0 &&
      this.tecProcurementCriteriaDetailList.length > 0
    ) {
      this.isSubmit = true;
      if (
        this.admProcurementCriteriaDetailList.length > 0 &&
        this.tecProcurementCriteriaDetailList.length > 0
      ) {
        this.procurementCriteria.procurement = this.procurement;
        this.procurementCriteria.isAppliedFinalScoring = this.formGroup.get(
          'isAppliedFinalScoring'
        ).value;
        this.procurementCriteria.minimumScore =
          this.formGroup.get('minimumScore').value;

        this.procurementFinalizationCriteria.procurement = this.procurement;
        this.procurementFinalizationCriteria.upperThreshold =
          this.formGroup.get('upperThreshold').value;
        this.procurementFinalizationCriteria.lowerThreshold =
          this.formGroup.get('lowerThreshold').value;
        this.procurementFinalizationCriteria.technicalPercentage =
          this.formGroup.get('technicalPercentage').value;
        this.procurementFinalizationCriteria.pricePercentage =
          this.formGroup.get('pricePercentage').value;
        this.procurementFinalizationCriteria.isPricingThreshold =
          this.formGroup.get('isPricingThreshold').value;

        const procurementCriteriaDetailList =
          this.tecProcurementCriteriaDetailList
            .concat(this.admProcurementCriteriaDetailList)
            .concat(this.delProcurementCriteriaDetailList);
        procurementCriteriaDetailList.forEach(element => {
          if (element.crudOperation === null) {
            element.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          }
        });

        const evaluationCriteriaRequest = new EvaluationCriteriaRequest();
        evaluationCriteriaRequest.isSaveDraft = true;
        evaluationCriteriaRequest.procurementFinalizationCriteria =
          this.procurementFinalizationCriteria;
        evaluationCriteriaRequest.procurementCriteriaDetailList =
          procurementCriteriaDetailList;
        evaluationCriteriaRequest.procurementCriteria =
          this.procurementCriteria;
        evaluationCriteriaRequest.procurement = this.procurement;

        this.setStateProcessing();
        const url =
          this.todo === 'add'
            ? '/evaluation-criteria/insert'
            : '/evaluation-criteria/update';
        this.httpClientService
          .post<Response<Pr>>(url, evaluationCriteriaRequest)
          .subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.snackbarService.showWarning('app.msg.info.sucsessSave');
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => {
                  this.global.routerParams.clear();
                  this.global.routerParams.set('procurement', response.body);
                  this.global.routerParams.set(
                    'procurementId',
                    response.body.id
                  );
                  this.global.routerParams.set(
                    'urlBackOutside',
                    '/pages/worklist-pm'
                  );
                  this.global.routerParams.set('todo', 'edit');
                  this.router.navigate(['/pages/evaluation-criteria/edit']);
                });
            } else {
              this.global.alertService.showError(response.statusText);
            }
          });
      }
    }
  }

  public doSubmit(): void {
    this.validate();
    if (
      this.formGroup.valid &&
      this.admProcurementCriteriaDetailList.length > 0 &&
      this.tecProcurementCriteriaDetailList.length > 0
    ) {
      this.isSubmit = true;
      if (
        this.admProcurementCriteriaDetailList.length > 0 &&
        this.tecProcurementCriteriaDetailList.length > 0
      ) {
        this.procurementCriteria.procurement = this.procurement;
        this.procurementCriteria.isAppliedFinalScoring = this.formGroup.get(
          'isAppliedFinalScoring'
        ).value;
        this.procurementCriteria.minimumScore =
          this.formGroup.get('minimumScore').value;

        this.procurementFinalizationCriteria.procurement = this.procurement;
        this.procurementFinalizationCriteria.upperThreshold =
          this.formGroup.get('upperThreshold').value;
        this.procurementFinalizationCriteria.lowerThreshold =
          this.formGroup.get('lowerThreshold').value;
        this.procurementFinalizationCriteria.technicalPercentage =
          this.formGroup.get('technicalPercentage').value;
        this.procurementFinalizationCriteria.pricePercentage =
          this.formGroup.get('pricePercentage').value;
        this.procurementFinalizationCriteria.isPricingThreshold =
          this.formGroup.get('isPricingThreshold').value;

        const procurementCriteriaDetailList =
          this.tecProcurementCriteriaDetailList
            .concat(this.admProcurementCriteriaDetailList)
            .concat(this.delProcurementCriteriaDetailList);
        procurementCriteriaDetailList.forEach(element => {
          if (element.crudOperation === null) {
            element.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          }
        });

        const evaluationCriteriaRequest = new EvaluationCriteriaRequest();
        evaluationCriteriaRequest.isSaveDraft = false;
        evaluationCriteriaRequest.procurementFinalizationCriteria =
          this.procurementFinalizationCriteria;
        evaluationCriteriaRequest.procurementCriteriaDetailList =
          procurementCriteriaDetailList;
        evaluationCriteriaRequest.procurementCriteria =
          this.procurementCriteria;
        evaluationCriteriaRequest.procurement = this.procurement;

        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              const url =
                this.todo === 'add'
                  ? '/evaluation-criteria/insert'
                  : '/evaluation-criteria/update';
              this.httpClientService
                .post<Response<Pr>>(url, evaluationCriteriaRequest)
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.modalService
                      .submitSuccess()
                      .pipe(take(1))
                      .subscribe(result => {
                        if (result) {
                          this.router.navigate(['/pages/worklist-pm']);
                        } else {
                          this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(() => {
                              this.global.routerParams.clear();
                              this.global.routerParams.set(
                                'procurement',
                                response.body
                              );
                              this.global.routerParams.set(
                                'procurementId',
                                response.body.id
                              );
                              this.global.routerParams.set(
                                'urlBackOutside',
                                '/pages/worklist-pm'
                              );
                              this.global.routerParams.set('todo', 'view');
                              this.router.navigate([
                                '/pages/evaluation-criteria/detail'
                              ]);
                            });
                        }
                      });
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      }
    }
  }
}
