import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
@Component({
  selector: 'app-tooltip-wrapper-item',
  templateUrl: './app-tooltip-wrapper-item.component.html',
  styleUrls: ['./app-tooltip-wrapper-item.component.scss']
})
export class AppTooltipWrapperItemComponent extends BaseComponentComponent {
  @Input() position: string;
  constructor() {
    super('app-tooltip-wrapper-item');
  }

  public onInit(): void {
    this.setStateReady();
  }
}
