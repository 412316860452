import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../base/angular/base-module.component';
import { BudgetAllocation } from '../../../bean/budget-allocation';
import { FieldFormatEnum } from '../../app-table/model/field-format.enum';
import { TableResponseModel } from '../../app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-budget.component.html'
})
export class AppPopupBudgetComponent extends BaseModuleComponent {
  @Input() totalPrice: number;
  @Output() onChange: EventEmitter<BudgetAllocation> = new EventEmitter();

  tableResponse: TableResponseModel<BudgetAllocation>;

  constructor(translateService: TranslateService) {
    super('app-popup-budget', translateService);
  }

  onInit(): void {
    this.buildTableresponse();
    this.setStateReady();
  }

  buildTableresponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      { field: 'number', header: 'table.column.number' },
      {
        field: 'amount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'remainingAmount',
        header: 'table.column.remainingAmount',
        format: FieldFormatEnum.Currency
      }
    ]);
  }

  doChoose(budget: BudgetAllocation): void {
    this.onChange.emit(budget);
  }
}
