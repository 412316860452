import { Component, ElementRef, Inject, Input, Provider } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../../base/angular/base-value-accessor.component';
import { TextUtils } from '../../../utils';
import { createUploaderModel } from '../actions';
import { FileObject } from '../model';
import { Uploader } from '../model/uploader';
import { FileUploader } from '../model/v1/file.uploader';
import { UploadService } from '../upload.service';
export function makeUploaderProvider(
  typeComponentClass: object
): Array<Provider> {
  return [
    ...makeProvider(typeComponentClass),
    {
      provide: UploadService
    }
  ];
}
@Component({
  template: ''
})
export abstract class BaseUploadComponent extends BaseValueAccessorComponent<
  Array<FileObject>
> {
  @Input() public fileUploader: FileUploader;
  @Input() public model: Uploader;
  @Input() public fileTypeCode: string;
  @Input() public maxFile: number;
  public generatedId: string = TextUtils.generateRandomString();
  public uploadStatusColor = {
    INQUEE: 'badge-secondary',
    ONPROGRESS: 'badge-primary',
    CANCELED: 'badge-warning',
    DELETED: 'badge-danger',
    UPLOADED: 'badge-success',
    FAILED: 'badge-danger',
    INVALID: 'badge-danger'
  };
  protected abstract onInitBaseUpload(): void;
  constructor(
    @Inject(String) moduleCode: string,
    controlContainer: ControlContainer,
    elementRef: ElementRef,
    protected uploadService: UploadService
  ) {
    super(moduleCode, controlContainer, elementRef);
  }

  public onInitBaseValueAccessor(): void {
    this.doCreateUploaderModel();
    this.onInitBaseUpload();
  }

  protected doSetFileListToFileUploader(): void {
    if (this.fileUploader) {
      this.fileUploader.fileObjectList = this.formControl.value;
    }
  }

  private doCreateUploaderModel(): void {
    this.model = createUploaderModel(this.model, this.fileUploader, {
      fileTypeCode: this.fileTypeCode,
      stringUrl: this.stringUrl,
      maxFile: this.maxFile || 1
    });
  }
}
