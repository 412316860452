import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { TabLoginHistoryComponent } from './tab-login-history.component';
import { TabLoginComponent } from './tab-login.component';
@NgModule({
  imports: [SharedModule],
  declarations: [TabLoginComponent, TabLoginHistoryComponent],
  entryComponents: [TabLoginComponent, TabLoginHistoryComponent],
  exports: [TabLoginComponent, TabLoginHistoryComponent]
})
export class TabLoginModule {
  constructor(accordionService: AccordionService) {
    accordionService.register('tab-login', TabLoginComponent);
    accordionService.register('tab-login-history', TabLoginHistoryComponent);
  }
}
