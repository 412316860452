import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { FileTypeResponse } from './file-type-response';
import { FileTypeRequest } from './file-type-request';
@Component({
  templateUrl: './file-type-edit-add.component.html'
})
export class FileTypeEditAddComponent extends BaseModuleComponent {
  public fileTypeId: number;
  public fileTypeResponse: FileTypeResponse = new FileTypeResponse();
  public fileTypeRequest: FileTypeRequest = new FileTypeRequest();
  public extensionToString: string;
  public moduleGroupCodeToString: string;
  public flagCode: boolean;
  public moduleGroupList = [];
  public extensionNameList = [];
  public moduleGroupCodeList = [];
  public langList = [];
  public translationListLabel = [];
  public extensionOptionList: OptionListModel<any> = new OptionListModel(true);
  public isReadOnly = false;
  constructor(translateService: TranslateService) {
    super('document-type', translateService);
  }

  onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.getAndSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.fileTypeId = this.global.routerParams.get('fileTypeId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      langList: this.formBuilder.array([]),
      maxSize: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(20)])
      ],
      extension: [null, Validators.compose([Validators.required()])],
      description: [null, Validators.maxLength(512)],
      translationList: this.formBuilder.array([]),
      module: [null],
      attribute: [null],
      moduleGroupCode: [null],
      isReadOnly: false,
      isEncrypted: false,
      fileLocation: [null]
    });
  }

  public getAndSetFormGroup(): void {
    this.httpClientService
      .post<FileTypeResponse>(
        '/document-type/add-edit',
        new RouteRequestModel(this.todo, this.fileTypeId)
      )
      .subscribe(fileTypeResponse => {
        this.langList = fileTypeResponse.langList;
        this.setTranslation(fileTypeResponse);
        this.extensionOptionList.setRequestValues(
          fileTypeResponse.fileExtensionList
        );
        if (fileTypeResponse.fileType !== null) {
          this.setFormGroup(fileTypeResponse);
          this.setExtension(fileTypeResponse);
          this.setFlagCode(fileTypeResponse);
        }
        this.setStateReady();
      });
  }

  public setFormGroup(fileTypeResponse: FileTypeResponse): void {
    if (fileTypeResponse.fileType !== null) {
      const {
        id,
        code,
        description,
        module,
        attribute,
        isReadOnly,
        fileLocation
      } = fileTypeResponse.fileType;
      const isEncrypted =
        fileTypeResponse.fileType.isEncrypted !== null
          ? fileTypeResponse.fileType.isEncrypted
          : false;
      this.formGroup.patchValue({
        id,
        code,
        description,
        module,
        attribute,
        isReadOnly,
        isEncrypted,
        fileLocation
      });
      this.formGroup
        .get('maxSize')
        .patchValue(fileTypeResponse.fileType.maxSize / 1000000);
      this.isReadOnly = fileTypeResponse.fileType.isReadOnly;
    }
  }

  public setExtension(fileTypeResponse: FileTypeResponse): void {
    this.formGroup.get('extension').patchValue(fileTypeResponse.fileExtension);
  }

  public setFlagCode(fileTypeResponse: FileTypeResponse): void {
    if (fileTypeResponse.fileType.isReadOnly) {
      this.formGroup.controls['code'].disable();
    }
  }

  public setTranslation(fileTypeResponse: FileTypeResponse): void {
    if (fileTypeResponse.translationList.length > 0) {
      fileTypeResponse.translationList.forEach(translation => {
        this.translationListLabel.push(translation.lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: translation.value
          })
        );
      });
    } else {
      fileTypeResponse.langList.forEach(lang => {
        this.translationListLabel.push(lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: [null, Validators.required()]
          })
        );
      });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/document-type/']);
  }

  public doSave(): void {
    this.validate();
    this.setFormArrayValidation();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.saveFileType();
          }
        });
    }
  }

  public setFormArrayValidation(): void {
    for (const control of this.translationList.controls) {
      control.setStateSubmitted();
    }
  }

  public saveFileType(): void {
    this.setStateProcessing();
    const extension = this.formGroup.get('extension').value;

    for (let i = 0; i <= extension.length - 1; i++) {
      this.extensionNameList.push(extension[i].name);
      this.extensionToString = this.extensionNameList.join();
    }
    this.fileTypeRequest.fileType = this.formGroup.value;
    this.fileTypeRequest.fileType.fileExtension = this.extensionToString;
    this.fileTypeRequest.translation = this.formGroup
      .get('translationList')
      .value.map(a => a.value);
    const url =
      this.todo === 'edit' ? '/document-type/update' : '/document-type/insert';
    this.httpClientService
      .post<Response<FileTypeRequest>>(url, this.fileTypeRequest)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccessSavingOnNextRoute();
          this.router.navigate(['/pages/document-type/']);
          this.translateService.reloadLang(this.global.getCurrentLang());
        } else {
          this.global.alertService.showError(response.statusText);
        }
        this.setStateReady();
      });
  }

  public get translationList(): FormArray {
    return this.formGroup.get('translationList') as FormArray;
  }
}
