import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { CatalogVerficationComponent } from './catalog-verification.component';
import { VerificationComponent } from './verification/verification.component';
const routes: Routes = [
  { path: '', component: CatalogVerficationComponent, data: { breadcrumb: '' } },
  { path: 'edit', component: VerificationComponent, data: { breadcrumb: 'catalog-verification.breadcrumb.edit' } ,
    children: [
      { path: '', loadChildren: () => import('../manage-catalog/manage-catalog-tab.module').then(m => m.ManageCatalogTabModule),
        data: { breadcrumb: 'catalog-verification.breadcrumb.title' } }
  ] },
  { path: 'edit/:token', component: VerificationComponent, data: { breadcrumb: 'catalog-verification.breadcrumb.edit' } ,
  children: [
    { path: '', loadChildren: () => import('../manage-catalog/manage-catalog-tab.module').then(m => m.ManageCatalogTabModule),
      data: { breadcrumb: 'catalog-verification.breadcrumb.title' } }
  ] }
];
@NgModule({
  imports: [ SharedModule, RouterModule.forChild(routes) ],
  declarations: [ CatalogVerficationComponent, VerificationComponent]
})

export class CatalogVerificationModule { }
