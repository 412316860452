import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuotationItemReference } from '../../../bean/quotation-item-reference';
import { AppPopupService } from '../app-popup.service';
import { AppPopupAllocatedItemDetailComponent } from './app-popup-allocated-item-detail.component';

@Injectable()
export class AppPopupAllocatedItemDetailService {
  constructor(public appPopupService: AppPopupService) {}

  open(
    procurementVendorId: number,
    quotationItemReferenceList?: QuotationItemReference[]
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupAllocatedItemDetailComponent,
      {
        quotationItemReferenceList,
        procurementVendorId
      },
      { size: 'xl' }
    );
  }
}
