<form class="form-horizontal" [formGroup]="formGroup" novalidate
  *ngIf="!model.isLoading">
  <div class="form-group row">
    <div class="col-lg-12">
      <app-table [isServerSide]="true" stringUrl="/tab-segmentation/index"
        [model]="tableResponse">
        <ng-template #actionButtons let-rowData>
          <ng-container>
            <app-button size="TN" (onClick)="doDetail(rowData)"
              title="{{ 'app.tooltip.detail' | translate }}">
              <span class="fas fa-search"></span>
            </app-button>
          </ng-container>
        </ng-template>
      </app-table>
    </div>
  </div>
</form>