import { Workflow } from 'src/app/core/bean/workflow';

export class SLAPmRequest {
    historyType: string;
    workflowList: string[];
    workflowPrcsId: number;
    fromDate: Date;
    untilDate: Date;
    workflowName: Workflow;
}
