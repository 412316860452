<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="region.title" [isLoading]="formLoading" [isSaving]="formSaving">
      <app-tree [model]="treeModel" isLazy="true" (onEventChange)="doOnEventChange($event)">
        <ng-template #treePopupContent let-treePopupFormGroup>
          <form class="form-horizontal" [formGroup]="treePopupFormGroup" novalidate>
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'region.form.code' | translate
                }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field autofocus="true" size="LG" formControlName="code" type="code" maxLength="32">
                </app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'region.form.callingCode' | translate
                }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field size="LG" formControlName="callingCode" type="integer" maxLength="8"></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'region.form.postcode' | translate
                }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field size="LG" formControlName="postcode" type="integer" maxLength="8"></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'region.form.value' | translate
                }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field formControlName="value" type="integer" maxLength="1"></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'region.form.name' | translate
                }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field size="LG" formControlName="name" maxLength="64"></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'region.form.flagIcon' | translate
                }}</label>
              <div class="col-sm-4 text-left">
                <span *ngIf="treeModel.formGroup.get('resource').value != null">
                  <img [ngStyle]="{ 'max-width': '36px' }" class="mr-2" [attr.src]="
                  global.config.BACKEND_ADDRESS +
                    '/resource/file/view/' +
                    treeModel.formGroup.get('resource').value.imgFile.uploadedFileName +
                    '/' + global.appConstant.fileType.IMG_ICON
                    | resolveImgSrc
                    | async
                " [attr.alt]="fileName" width="auto" height="auto" />
                </span>
                <app-button size="SM" (onClick)="doChooseFlagIcon()" [disabled]="formSaving">
                  {{ 'app.button.choose' | translate }}
                </app-button>
              </div>
            </div>
          </form>
        </ng-template>
      </app-tree>
    </app-card>
  </div>
</div>