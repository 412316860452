import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { ContractStatus } from 'src/app/core/bean/contract-status';
import { Procurement } from 'src/app/core/bean/procurement';
import { Vendor } from 'src/app/core/bean/vendor';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { RecapPrintService } from 'src/app/core/services/recap-print.service';
import { ReportService } from 'src/app/core/services/report.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppPopupContractMonitoringSendEmailContractComponent } from './app-popup-contract-monitoring-send-email-contract.component';
import { AppPopupContractMonitoringSendEmailGuaranteeComponent } from './app-popup-contract-monitoring-send-email-guarantee.component';
import { ContractMonitoringResponse } from './contract-monitoring.response';
@Component({
  templateUrl: './contract-monitoring.component.html'
})
export class ContractMonitoringComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Contract>;
  public isLoading: boolean;
  public filter: string;

  public contractNumberOptionList: OptionListModel<Contract> =
    new OptionListModel(true, 'number');
  public vendorOptionList: OptionListModel<Vendor> = new OptionListModel(true);
  public contractStatusOptionList: OptionListModel<ContractStatus> =
    new OptionListModel(true);
  public procurementOptionList: OptionListModel<Procurement> =
    new OptionListModel(true);

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public reportService: ReportService,
    public recapPrintService: RecapPrintService
  ) {
    super('contract-monitoring', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.doSetCustomData();
    this.setStateReady();
    localStorage.removeItem('temp');
    localStorage.removeItem('todo');
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      fromDate: [null],
      untilDate: [null],
      contractList: [null],
      vendorList: [null],
      procurementList: [null],
      contractStatusList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<ContractMonitoringResponse>('/contract-monitoring/get-option-list')
      .subscribe((contractMonitoringResponse: ContractMonitoringResponse) => {
        this.contractNumberOptionList.setRequestValues(
          contractMonitoringResponse.contractList
        );
        this.vendorOptionList.setRequestValues(
          contractMonitoringResponse.vendorList
        );
        this.procurementOptionList.setRequestValues(
          contractMonitoringResponse.procurementList
        );
        this.contractStatusOptionList.setRequestValues(
          contractMonitoringResponse.contractStatusList
        );
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'number',
        header: 'table.column.contractNumber',
        customClass: 'text-center'
      },
      {
        field: 'contractType.name',
        header: 'table.column.contractType',
        customClass: 'text-center'
      },
      {
        field: 'procurementVendor.vendor.name',
        header: 'table.column.vendor',
        customClass: 'white-space-normal'
      },
      {
        field: 'procurementVendor.procurement.name',
        header: 'table.column.procurement'
      },
      {
        field: 'contractStatus.name',
        header: 'table.column.contractStatus',
        customClass: 'text-center'
      },
      {
        field: 'procurementVendor.userDelegator.name',
        header: 'table.column.userDelegator'
      },
      {
        field: 'startDate',
        header: 'table.column.startDateContract',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDateContract',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      }
    ]);
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customFilter = {
        contractStatusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customFilter);
      this.tableResponse.reload();
    }
  }

  public doApply(): void {
    this.global.routerParams.clear();
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doDetail(contract: Contract): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('contractId', contract.id);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/contract-monitoring'
    );
    this.global.routerParams.set('contract', contract);
    this.router.navigate(['/pages/contract-monitoring/detail']);
  }

  public doContractClosed(contract: Contract): void {
    if (
      contract.dealingAgreementType.name ===
        this.global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN &&
      !contract.isFinishAssessed
    ) {
      this.global.alertService.showInfo(
        this.global.translateService.instant(
          'contract-monitoring.validation.assessment'
        )
      );
    } else {
      this.global.routerParams.clear();
      this.global.routerParams.set('todo', 'contractClosed');
      this.global.routerParams.set('contractId', contract.id);
      this.global.routerParams.set(
        'urlBackOutside',
        '/pages/contract-monitoring'
      );
      this.global.routerParams.set('contract', contract);
      this.router.navigate(['/pages/contract-monitoring/contract-closed']);
    }
  }

  public doSendContract(contract: Contract): void {
    const header = this.translateService.instant(
      'contract-monitoring.popup.title.contract'
    );
    this.appPopupService
      .open(AppPopupContractMonitoringSendEmailContractComponent, {
        header,
        contract
      })
      .subscribe(vendor => {
        this.log.debug(vendor);
      });
  }
  public doSendGuarantee(contract: Contract): void {
    const header = this.translateService.instant(
      'contract-monitoring.popup.title.guarantee'
    );
    this.appPopupService
      .open(AppPopupContractMonitoringSendEmailGuaranteeComponent, {
        header,
        contract
      })
      .subscribe(vendor => {
        this.log.debug(vendor);
      });
  }

  public doExport(): void {
    this.loadingBlockService.showInfo(
      this.translateService.instant('contract-monitoring.info.preparingData')
    );
    this.recapPrintService
      .print('/contract-monitoring/recap-contract', this.tableResponse.request)
      .subscribe(() => {
        this.loadingBlockService.showCompleteAndClose(
          this.translateService.instant(
            'contract-monitoring.info.successImported'
          )
        );
      });
  }
}
