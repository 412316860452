import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { WebSocketService } from 'src/app/core/services/websocket/websocket.service';
import { WebSocketResponseModel } from './../../core/model/websocket-response-model';
import { CostCenterComponent } from './cost-center.component';
import { AppPopupSyncCostCenterComponent } from './app-popup-sync-cost-center.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class WebSocketCostCenterService extends WebSocketService<string> {
    public router: Router;
    public appPopupSyncCostCenterComponent: AppPopupSyncCostCenterComponent;
    public onCloseModal: EventEmitter<boolean> = new EventEmitter();
    public message: EventEmitter<string> = new EventEmitter();
    public costCenter: CostCenterComponent;

    constructor(public appPopupService: AppPopupService, public translateService: TranslateService) {
        super('synchronize/cost-center');
    }

    public onReceivedMessage(message: string): Promise<any> {
        return new Promise(resolve => {
            const webSocketResponseModel: WebSocketResponseModel =
                JSON.parse(message);
            if (this.appPopupSyncCostCenterComponent) {
                if (
                    webSocketResponseModel &&
                    webSocketResponseModel.status !==
                    this.global.appConstant.core.WEBSOCKET_STATUS_ON_PROGRESS
                ) {
                    const result =
                        webSocketResponseModel.status ===
                            this.global.appConstant.core.WEBSOCKET_STATUS_FINISH
                            ? true
                            : false;
                    setTimeout(() => {
                        this.appPopupSyncCostCenterComponent.isLoadingSync = false;
                        this.appPopupSyncCostCenterComponent = null;
                        this.onCloseModal.emit(true);
                        if (result) {
                            this.global.alertService.showSuccess(
                                this.translateService.instant(
                                    'cost-center.msg.info.successSynchronize'
                                )
                            );
                        }
                        resolve(result);
                    }, 500);
                } else {
                    this.appPopupSyncCostCenterComponent.isLoadingSync = true;
                    this.appPopupSyncCostCenterComponent.webSocketResponseModel =
                        webSocketResponseModel;
                }
            } else {
                let bodyMessage;
                if (
                    webSocketResponseModel &&
                    webSocketResponseModel.status ===
                    this.global.appConstant.core.WEBSOCKET_STATUS_ON_PROGRESS
                ) {
                    bodyMessage =
                        webSocketResponseModel.progressValue +
                        ' ' +
                        webSocketResponseModel.progressUnit;
                    this.message.emit(bodyMessage);
                } else {
                    const result =
                        webSocketResponseModel.status ===
                            this.global.appConstant.core.WEBSOCKET_STATUS_FINISH
                            ? true
                            : false;
                    setTimeout(() => {
                        this.message.emit(null);
                        resolve(result);
                    }, 500);
                }
            }
        });
    }
}
