import { resolveTableRecords } from '../helpers/resolve-table-records.helper';
import { TableState } from '../model/table-state';

let timeOut;
export const searchTable = (
  state: TableState,
  payload: { keywords: string }
): void => {

  clearTimeout(timeOut);
  timeOut = setTimeout(() => {
      state.checkedRecord.reset();
      state.tableRequest.globalFilter = payload.keywords;
      resolveTableRecords(state).then((records: Array<any>) => {
      state.setRecords(records);
      state.setStateReady();
    });
  }, 500);

};
