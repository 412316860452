<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="coa.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-tree [model]="treeModel" (onEventChange)="doOnEventChange($event)">
        <ng-template #customText let-treeItem>
          {{treeItem.code}} - {{treeItem.name}}
        </ng-template>
        <ng-template #treePopupContent let-treePopupFormGroup>
          <form class="form-horizontal" [formGroup]="treePopupFormGroup">
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{ 'coa.form.code' |
                translate }}</label>
              <div class="col-sm-7 text-left">
                <app-text-field autofocus="true" size="XL"
                  formControlName="code" maxLength="32" type="code"
                  tooltip="coa.tooltip.code"></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{ 'coa.form.name' |
                translate }}</label>
              <div class="col-sm-7 text-left">
                <app-text-field size="XL" formControlName="name" maxLength="32">
                </app-text-field>
              </div>
            </div>

            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{ 'coa.form.description' |
                translate }}</label>
              <div class="col-sm-7 text-left">
                <app-text-area size="XL" formControlName="description"
                  maxLength="512"></app-text-area>
              </div>
            </div>

            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label"></label>
              <div class="col-sm-7 text-left">
                <app-check-box size="XL" formControlName="isMultiYears"
                  tooltip="coa.tooltip.isMultiYears"
                  (onChange)="doOnChangeIsMultiYears($event)">
                  {{ 'coa.form.isMultiYears' | translate }}
                </app-check-box>
              </div>
            </div>
          </form>
        </ng-template>
        <ng-template #footerButton>
          <div class="button-group button-group-center mt-5">
            <app-button color="SECONDARY" (onClick)="doCancel()"
              [disabled]="formSaving">
              {{ 'app.button.cancel' | translate }}</app-button>
            <app-button (onClick)="doSave()" [disabled]="formSaving">
              {{ 'app.button.save' | translate }}</app-button>
          </div>
        </ng-template>
      </app-tree>
    </app-card>
  </div>
</div>