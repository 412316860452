import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Aanwijzing } from '../../core/bean/aanwijzing';
import { AanwijzingParticipant } from '../../core/bean/aanwijzing-participant';
import { TablePageModel } from '../../core/components/app-table/model/table-page-model';
import { TableColumnModel } from '../../core/components/table/model/table-column-model';
import { PaginationRequestModel } from '../../core/model/pagination-request-model';

@Component({
  templateUrl: './attendance-form-participant-list.component.html',
  styleUrls: ['./attendance-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttendanceFormParticipantListComponent
  extends BaseModuleComponent
  implements OnInit
{
  public aanwijzingId: string;
  public isFormPage = false;
  public aanwijzingParticipantList: AanwijzingParticipant[] = [];
  public aanwijzing: Aanwijzing = new Aanwijzing();
  private paginationRequestModel: PaginationRequestModel =
    new PaginationRequestModel();
  private isHasMoreData: boolean;
  readonly SEARCH_INTERVAL = 500; // 0.5s
  timeOut: any;

  constructor(
    translateService: TranslateService,
    public activatedRoute: ActivatedRoute
  ) {
    super('attendance-form', translateService);
  }

  public badgeColor = {
    OTHERS: 'SUCCESS',
    INTERNAL: 'INFO',
    VENDOR: 'INFO'
  };

  public onInit(): void {
    this.setPagination();
    this.getRouterParam();
    this.buildFormGroup();
  }

  public setPagination(): void {
    this.paginationRequestModel.customData = JSON.stringify(
      this.global.routerParams.get('aanwijzingId')
    );
    this.paginationRequestModel.globalFilter = '';
    this.paginationRequestModel.first = 0;
    this.paginationRequestModel.rows = 10;
    const colsList: Array<TableColumnModel> = new Array();
    const fieldList = [
      'name',
      'participantRole.name',
      'companyName',
      'phoneRegion.callingCode',
      'phone',
      'email',
      'attendanceDate'
    ];
    fieldList.forEach(field => {
      const tableColumnModel: TableColumnModel = new TableColumnModel();
      tableColumnModel.field = field;
      colsList.push(tableColumnModel);
    });
    this.paginationRequestModel.cols = colsList;
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({});
  }

  public getRouterParam(): void {
    this.aanwijzingId = this.global.routerParams.get('aanwijzingId');
    this.loadData();
  }

  public loadData(): void {
    this.formLoading = true;
    this.httpClientService
      .post<any>(
        '/attendance-form/get-participant-list',
        this.paginationRequestModel
      )
      .subscribe((response: TablePageModel<AanwijzingParticipant>) => {
        this.aanwijzingParticipantList.push(...response.records);
        if (
          this.aanwijzingParticipantList &&
          this.aanwijzingParticipantList.length > 0
        ) {
          this.aanwijzing = this.aanwijzingParticipantList[0].aanwijzing;
          this.isHasMoreData =
            response.totalRecords > this.aanwijzingParticipantList.length;
        }
        this.formLoading = false;
        this.setStateReady();
      });
  }

  public onScrollEnd(): void {
    this.log.debug('onScrollEnd');
    if (this.isHasMoreData) {
      this.formLoading = true;
      this.paginationRequestModel.first =
        this.paginationRequestModel.first + this.paginationRequestModel.rows;
      this.loadData();
    }
  }
  public onInputSearch(searchText: string): void {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.resetPagination();
      this.paginationRequestModel.globalFilter = searchText;
      this.loadData();
    }, this.SEARCH_INTERVAL);
  }

  public doResetInputSearch(): void {
    this.resetPagination();
  }

  public resetPagination(): void {
    this.aanwijzingParticipantList = [];
    this.paginationRequestModel.globalFilter = '';
    this.paginationRequestModel.first = 0;
    this.paginationRequestModel.rows = 10;
  }
}
