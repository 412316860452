<app-layout
  [isLoading]="formLoading"
  [backTo]="urlBackOutside"
  header="price-evaluation-vendor.breadcrumb.index"
>
  <ng-template #additionalTitle *ngIf="!formLoading && isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement?.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge class="cml-2" [color]="badgeColor[priceEvaluationStatusCode]">
      {{ translateLabel(priceEvaluationStatusCode) | translate }}
    </app-badge>
  </ng-template>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <div
        *ngIf="
          priceEvaluationStatusCode ===
          global.appConstant.pm.PRICE_EVALUATION_STATUS_DONE
        "
        class="row"
        [ngStyle]="{
          margin: '0',
          background: 'var(--alert-info)',
          'border-radius': '5px',
          height: '100px'
        }"
      >
        <div class="col-sm-1"></div>
        <div class="col-sm-1 d-flex justify-content-center align-items-center"
          ><div
            class="pvc pv-medal"
            [ngStyle]="{ width: '100%', height: '100%' }"
          ></div
        ></div>
        <div
          class="col-sm-10 d-flex flex-column justify-content-center"
          [ngStyle]="{ 'padding-top': '1rem' }"
        >
          <h2>{{
            'price-evaluation-vendor.banner.congratulation.title' | translate
          }}</h2>
          <p>{{
            'price-evaluation-vendor.banner.congratulation.message' | translate
          }}</p>
        </div>
      </div>
      <app-alert-x
        *ngIf="isCancelation && !formLoading"
        color="DANGER"
        [isBold]="false"
      >
        {{
          'price-evaluation-vendor.alert.msg.cancelationApproval' | translate
        }}
      </app-alert-x>

      <app-alert-x
        *ngIf="isFailedAssessment && !formLoading"
        color="DANGER"
        [isBold]="false"
      >
        {{ 'price-evaluation-vendor.alert.msg.failApproval' | translate }}
      </app-alert-x>
      <app-workflow-step-info
        [isLoading]="formLoading"
        [model]="priceEvaluationVendorEditResponse.workflowStepInfoModel"
        *ngIf="!formLoading"
      >
      </app-workflow-step-info>
      <app-card
        header="price-evaluation-vendor.detail.procurementDetail.title"
        tips="price-evaluation-vendor.detail.procurementDetail.tips"
      >
        <app-procurement-info [procurementId]="procurementId">
        </app-procurement-info>
      </app-card>
      <app-item-request
        [objectList]="priceEvaluationVendorEditResponse.procurementItemList"
        [isShowPrice]="false"
        [itemTypeList]="priceEvaluationVendorEditResponse.itemTypeList"
        [isView]="true"
        *ngIf="!formLoading"
      ></app-item-request>
      <app-card *ngIf="!formLoading">
        <app-table-xx
          header="price-evaluation-vendor.detail.priceEvaluation.title"
          tips="price-evaluation-vendor.detail.priceEvaluation.tips"
          [model]="tableResponsePriceEval"
          *ngIf="!formLoading"
          stringUrl="/price-evaluation-vendor/table-price-evaluation"
        >
        </app-table-xx>
      </app-card>
      <app-card
        header="price-evaluation-vendor.detail.priceEvaluationMethod.title"
        tips="price-evaluation-vendor.detail.priceEvaluationMethod.tips"
        *ngIf="!formLoading"
      >
        <div
          class="no-data-wrapper"
          *ngIf="
            procurement &&
            (!procurement.priceEvaluationMethod ||
              procurement.procurementWorklistStatus.code ===
                global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT)
          "
        >
          <div class="book-wrapper pvc pv-file-list"></div>
          <div class="text-no-data">
            <span class="text-no-data">
              {{ 'price-evaluation-vendor.noPriceEvalMethod' | translate }}
            </span>
          </div>
        </div>
        <div
          *ngIf="
            procurement &&
            procurement.procurementWorklistStatus.code !==
              global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT &&
            procurement.priceEvaluationMethod &&
            (procurement.priceEvaluationMethod.code ===
              global.appConstant.pm
                .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE ||
              procurement.priceEvaluationMethod.code ===
                global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION)
          "
        >
          <app-card>
            <div class="row">
              <div
                class="col-sm-1 d-flex justify-content-center align-items-center"
                ><div
                  class="pvc pv-handshake"
                  [ngStyle]="{ width: '80%', height: '80%' }"
                ></div
              ></div>
              <div class="col-sm-11" [ngStyle]="{ 'padding-top': '1rem' }">
                <h4 class="text-primary"
                  >{{
                    procurement.priceEvaluationMethod.nameTranslationKey.module.code.toLowerCase() +
                      '.' +
                      procurement.priceEvaluationMethod.nameTranslationKey.key
                      | translate
                  }}
                  <span class="ml-1"
                    ><em
                      class="pir pi-question-circle"
                      style="color: var(--primary)"
                      placement="top"
                      ngbTooltip="{{
                        'price-evaluation-vendor.tooltip.dealWithThisPrice'
                          | translate
                      }}"
                    ></em
                  ></span>
                </h4>
                <p>{{
                  'price-evaluation-vendor.priceEvaluationMethod.dealWithThisPrice.description'
                    | translate
                }}</p>
              </div>
            </div>
          </app-card>
        </div>
        <div
          *ngIf="
            procurement &&
            procurement.procurementWorklistStatus.code !==
              global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT &&
            procurement.priceEvaluationMethod &&
            procurement.priceEvaluationMethod.code ===
              global.appConstant.pm.PRICE_EVALUATION_METHOD_AUCTION
          "
        >
          <app-card>
            <div class="row">
              <div
                class="col-sm-1 d-flex justify-content-center align-items-center"
                ><div
                  class="pvc pv-stopwatch"
                  [ngStyle]="{ width: '80%', height: '80%' }"
                ></div
              ></div>
              <div class="col-sm-11" [ngStyle]="{ 'padding-top': '1rem' }">
                <h4 class="text-primary"
                  >{{
                    procurement.priceEvaluationMethod.nameTranslationKey.module.code.toLowerCase() +
                      '.' +
                      procurement.priceEvaluationMethod.nameTranslationKey.key
                      | translate
                  }}
                  <span class="ml-1"
                    ><em
                      class="pir pi-question-circle"
                      style="color: var(--primary)"
                      placement="top"
                      ngbTooltip="{{
                        'price-evaluation-vendor.tooltip.auction' | translate
                      }}"
                    ></em
                  ></span>
                </h4>
                <p>{{
                  'price-evaluation-vendor.priceEvaluationMethod.auction.description'
                    | translate
                }}</p>
              </div>
            </div>
          </app-card>
          <app-card>
            <app-table-xx
              header="price-evaluation-vendor.card.header.auctionSession"
              [model]="tableResponseAuctionSession"
              *ngIf="!formLoading"
              stringUrl="/price-evaluation-vendor/table-auction-session"
            >
            </app-table-xx>
          </app-card>
        </div>
        <div
          *ngIf="
            procurement &&
            procurement.procurementWorklistStatus.code !==
              global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT &&
            procurement.priceEvaluationMethod &&
            (procurement.priceEvaluationMethod.code ===
              global.appConstant.pm.PRICE_EVALUATION_METHOD_NEGOTIATION ||
              procurement.priceEvaluationMethod.code ===
                global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_NEGOTIATION_AUCTION ||
              (procurement.priceEvaluationMethod.code ===
                global.appConstant.pm.PRICE_EVALUATION_METHOD_AUCTION &&
                priceEvaluationVendorEditResponse?.negotiatiaonSchedule))
          "
        >
          <app-card>
            <div class="row">
              <div
                class="col-sm-1 d-flex justify-content-center align-items-center"
                ><div
                  class="pvc pv-user-decision"
                  [ngStyle]="{ width: '80%', height: '80%' }"
                ></div
              ></div>
              <div class="col-sm-11" [ngStyle]="{ 'padding-top': '1rem' }">
                <h4 class="text-primary"
                  >{{
                    'dynamic-master-attribute.priceEvaluationMethod.negotiation.name'
                      | translate
                  }}
                  <span class="ml-1"
                    ><em
                      class="pir pi-question-circle"
                      style="color: var(--primary)"
                      placement="top"
                      ngbTooltip="{{
                        'price-evaluation-vendor.tooltip.negotation' | translate
                      }}"
                    ></em
                  ></span>
                </h4>
                <p>{{
                  'price-evaluation-vendor.priceEvaluationMethod.negotiation.description'
                    | translate
                }}</p>
              </div>
            </div>
          </app-card>
          <app-card
            *ngIf="priceEvaluationVendorEditResponse?.negotiatiaonSchedule"
          >
            <h3 class="separator">{{
              'price-evaluation-vendor.header.negotiationSchedule' | translate
            }}</h3>
            <div
              class="row"
              [ngStyle]="{
                margin: '0'
              }"
            >
              <div
                class="col-sm-1 d-flex justify-content-center align-items-center"
                ><div
                  class="pvc pv-calendar"
                  [ngStyle]="{ width: '100%', height: '100%' }"
                ></div
              ></div>
              <div class="col-sm-11" [ngStyle]="{ margin: '1rem 0' }">
                <h5
                  >{{
                    priceEvaluationVendorEditResponse?.negotiatiaonSchedule
                      .startDate | date: 'EEEE, dd MMMM y HH:mm:ss'
                  }}&nbsp;{{
                    'price-evaluation-vendor.form.until' | translate
                  }}&nbsp;{{
                    priceEvaluationVendorEditResponse?.negotiatiaonSchedule
                      .endDate | date: 'EEEE, dd MMMM y HH:mm:ss'
                  }}</h5
                >
              </div>
            </div>
          </app-card>
          <app-card
            *ngIf="priceEvaluationVendorEditResponse?.reNegotiatiaonSchedule"
          >
            <h3 class="separator">{{
              'price-evaluation-vendor.header.reNegotiationSchedule' | translate
            }}</h3>
            <div
              class="row"
              [ngStyle]="{
                margin: '0'
              }"
            >
              <div
                class="col-sm-1 d-flex justify-content-center align-items-center"
                ><div
                  class="pvc pv-calendar-redo"
                  [ngStyle]="{ width: '100%', height: '100%' }"
                ></div
              ></div>
              <div class="col-sm-11" [ngStyle]="{ margin: '1rem 0' }">
                <h5
                  >{{
                    priceEvaluationVendorEditResponse?.reNegotiatiaonSchedule
                      .startDate | date: 'EEEE, dd MMMM y HH:mm:ss'
                  }}&nbsp;{{
                    'price-evaluation-vendor.form.until' | translate
                  }}&nbsp;{{
                    priceEvaluationVendorEditResponse?.reNegotiatiaonSchedule
                      .endDate | date: 'EEEE, dd MMMM y HH:mm:ss'
                  }}</h5
                >
              </div>
            </div>
          </app-card>
        </div>
      </app-card>
      <app-card
        *ngIf="
          !formLoading &&
          procurement &&
          procurement.priceEvaluationMethod &&
          procurement.priceEvaluationMethod.code !==
            global.appConstant.pm
              .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE &&
          procurement.priceEvaluationMethod.code !==
            global.appConstant.pm
              .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION
        "
      >
        <app-table-xx
          header="price-evaluation-vendor.detail.finalPriceEvaluation.title"
          tips="price-evaluation-vendor.detail.finalPriceEvaluation.tips"
          [model]="tableResponseFinalPriceEval"
          *ngIf="!formLoading"
          stringUrl="/price-evaluation-vendor/table-final-price-evaluation"
        >
        </app-table-xx>
      </app-card>
      <app-card
        header="price-evaluation-vendor.detail.officialReport.title"
        tips="price-evaluation-vendor.detail.officialReport.tips"
        *ngIf="
          !formLoading &&
          priceEvaluationStatusCode ===
            global.appConstant.pm.PRICE_EVALUATION_STATUS_DONE
        "
      >
        <h5 class="separator"></h5>
        <app-official-report
          [objectId]="procurementId"
          [templateCode]="
            procurement?.biddingSubmissionType.code ===
            global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
              ? global.appConstant.pm
                  .OFFICIAL_REPORT_CODE_ORT_PRICE_EVALUATION_ITEM_LETTER
              : global.appConstant.pm
                  .OFFICIAL_REPORT_CODE_ORT_PRICE_EVALUATION_BULK_LETTER
          "
          [appOfficialReportModel]="appOfficialReportModel"
          [isShowButtonGenerate]="false"
          mode="view"
        ></app-official-report>
      </app-card>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
