<div class="app-widget-contract-status" *ngIf="!formLoading">
    <app-widget [header]="moduleCode + '.title'">
      <app-widget-content-slider>
        <div class="widget-card" *ngFor="let data of contractDataList" (click)="goToMoreDetail(data)" (keyup)="onKeyUp($event)">
          <h6>{{ data.translationKey | translate }}</h6>
          <span class="m-0">{{ data.value }}</span>
          <em class="icon-bg" [ngClass]="data.icon"></em>
        </div>
      </app-widget-content-slider>
    </app-widget>
  </div>