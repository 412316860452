import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { OepDoc } from 'src/app/core/bean/oep-doc';
import { OepItem } from 'src/app/core/bean/oep-item';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { PopupOepResponse } from './popup-oep.response';

@Component({
  templateUrl: './app-popup-oep.component.html'
})
export class AppPopupOepComponent extends BaseComponentComponent {
  @Input() public procurementId: number;

  public tableResponse: TableResponseModel<OepItem>;
  public popupOepResponse: PopupOepResponse;

  public fileUploader: FileUploader = new FileUploader(
    '/price-evaluation/',
    '',
    this.global.appConstant.fileType.DOC_OEP,
    false,
    5
  );

  public constructor() {
    super('price-evaluation');
  }
  public onInit(): void {
    this.buildTableResponse();
    this.buildFormGroup();
    this.getDataFromServer();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      oepFileList: [null]
    });
  }

  public getDataFromServer(): void {
    this.httpClientService
      .get<PopupOepResponse>(
        '/price-evaluation/popup-oep/' + this.procurementId
      )
      .subscribe((response: PopupOepResponse) => {
        this.popupOepResponse = response;
        this.tableResponse.setRecordList(response.oepItemList);
        const fileList = response.oepDocList.map(
          (oepDoc: OepDoc) => oepDoc.file
        );
        this.fileUploader.setFileList(fileList);
        this.formGroup.patchValue({
          oepFileList: this.fileUploader.fileObjectList
        });
        this.formGroup.setIsView(true);
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.pr.code',
        header: 'table.column.prNumber',
        plugins: { name: 'hyperlink', onClick: this.doViewItem.bind(this) }
      },
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'procurementItem.prItem.item.code',
        header: 'table.column.itemCode'
      },
      {
        field: 'procurementItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'procurementItem.prItem.item.itemType.code'
        }
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'procurementItem.prItem.item.uom.name',
        header: 'table.column.unit'
      },
      {
        field: 'amount',
        header: 'table.column.oep',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'totalAmount',
        header: 'table.column.totalOep',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      }
    ]);
  }

  public doViewItem(): void {
    console.log('View item');
  }
}
