import { EventEmitter, Injectable } from '@angular/core';
import { ThemeSettingsModel } from './model/theme-settings-model';

@Injectable({ providedIn: 'root' })
export class PromiseThemeService {
  public settings: ThemeSettingsModel = new ThemeSettingsModel();
  public event: EventEmitter<any> = new EventEmitter();

  public DATA_THEME_NAME = 'data-theme';
  public SIDEBAR_SMALL_WIDTH = 'sidebar-small-width';
  public SIDEBAR_FULL_WIDTH = 'sidebar-full-width';

  constructor() {}

  public hideSidebarLeft(): void {
    const dataTheme = this.getDataTheme();
    if (dataTheme.toLowerCase().includes(this.SIDEBAR_SMALL_WIDTH)) {
      this.removeDataFromDataTheme(this.SIDEBAR_SMALL_WIDTH);
    }

    if (dataTheme.toLowerCase().includes(this.SIDEBAR_FULL_WIDTH)) {
      this.removeDataFromDataTheme(this.SIDEBAR_FULL_WIDTH);
    }
  }

  public changeSidebarWidth(width?: 'SMALL' | 'FULL' | 'NONE'): void {
    const dataTheme = this.getDataTheme();
    let sidebarWidth = dataTheme;
    if (width !== 'NONE') {
      const isSmallSidebarWidth = dataTheme
        .toLowerCase()
        .includes(this.SIDEBAR_SMALL_WIDTH);
      const isFullSidebarWidth = dataTheme
        .toLowerCase()
        .includes(this.SIDEBAR_FULL_WIDTH);
      if (!isSmallSidebarWidth && !isFullSidebarWidth) {
        sidebarWidth = `${dataTheme} ${this.SIDEBAR_SMALL_WIDTH}`;
      }
      dataTheme !== sidebarWidth &&
        this.getHtmlElement().setAttribute(this.DATA_THEME_NAME, sidebarWidth);
    } else {
      this.hideSidebarLeft();
    }
  }

  public enableContentSidebar(position: 'left' | 'right'): void {
    this.setDataToDataTheme(`content-sidebar-${position}`);
  }

  public disableContentSidebar(position: 'left' | 'right'): void {
    this.removeDataFromDataTheme(`content-sidebar-${position}`);
  }

  public getHtmlElement(): HTMLElement {
    return document.getElementsByTagName('html').item(0);
  }

  public getDataTheme(): string {
    return this.getHtmlElement().getAttribute(this.DATA_THEME_NAME);
  }

  public removeDataFromDataTheme(data: string): void {
    const dataTheme = this.getDataTheme();
    if (dataTheme.toLowerCase().includes(data.toLowerCase())) {
      this.getHtmlElement().setAttribute(
        this.DATA_THEME_NAME,
        dataTheme.replace(data.toLowerCase(), '')
      );
    }
  }

  public setDataToDataTheme(data: string): void {
    const dataTheme = this.getDataTheme();
    if (!dataTheme.toLowerCase().includes(data.toLowerCase())) {
      this.getHtmlElement().setAttribute(
        this.DATA_THEME_NAME,
        `${dataTheme} ${data.toLowerCase()}`
      );
    }
  }
}
