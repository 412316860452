import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Contract } from '../../core/bean/contract';
import { ContractStatus } from '../../core/bean/contract-status';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';

@Component({
  templateUrl: './contract-history-vendor.component.html'
})
export class ContractHistoryVendorComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Contract>;
  public contractStatusOptionList: OptionListModel<ContractStatus> =
    new OptionListModel(true);

  constructor(translateService: TranslateService) {
    super('contract-history-vendor', translateService);
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      contractStatusList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<ContractStatus[]>('/contract-history-vendor/get-option-list')
      .subscribe((response: ContractStatus[]) => {
        this.contractStatusOptionList.setRequestValues(response);
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.contractNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doDetail.bind(this)
        }
      },
      {
        header: 'table.column.contractPeriod',
        columnList: [
          {
            field: 'startDate',
            header: 'table.column.startDate',
            plugins: {
              name: 'date'
            }
          },
          {
            field: 'endDate',
            header: 'table.column.endDate',
            plugins: {
              name: 'date'
            }
          }
        ]
      },
      {
        field: 'title',
        header: 'table.column.title'
      },
      {
        field: 'contractType.name',
        header: 'table.column.contractType'
      },
      {
        field: 'contractStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'contractStatus.code',
          colorMap: {
            WAITING_FOR_TERMINATION: 'WARNING',
            DONE: 'SUCCESS',
            EXPIRED: 'DANGER',
            CANCELED: 'DANGER',
            ON_PROGRESS: 'PROGRESS',
            TERMINATED: 'DANGER',
            CLOSED: 'DANGER'
          }
        }
      }
    ]);
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    } else {
      this.formGroup.value.fromDate = null;
      this.formGroup.value.untilDate = null;
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doDetail(contract: Contract): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('contractId', contract.id);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/contract-history-vendor'
    );

    this.router.navigate(['/pages/contract-history-vendor/detail']);
  }
}
