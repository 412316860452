import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { RecapPrintService } from 'src/app/core/services/recap-print.service';
import { ReportService } from 'src/app/core/services/report.service';
import { PaymentRequestView } from 'src/app/core/view/entity/bean/payment-request-view';
import { PaymentHistoryOptionResponse } from './payment-history-option.response';
import { PaymentView } from 'src/app/core/view/entity/bean/payment-view';
@Component({
    templateUrl: './payment-history.component.html'
})
export class PaymentHistoryComponent
    extends BaseModuleComponent
    implements OnInit {
    @ViewChild(AppTableComponent) public table: AppTableComponent;
    public tableResponse: TableResponseModel<PaymentRequestView>;

    public procurementOptionList: OptionListModel<String> = new OptionListModel(
        false
    );
    public agreementOptionList: OptionListModel<String> = new OptionListModel(
        false
    );
    public vendorOptionList: OptionListModel<String> = new OptionListModel(false);
    public paymentInvoiceOptionList: OptionListModel<PaymentInvoice> =
        new OptionListModel(false, 'number');

    constructor(
        translateService: TranslateService,
        public appPopupService: AppPopupService,
        public reportService: ReportService,
        public recapPrintService: RecapPrintService
    ) {
        super('payment-history', translateService);
    }

    public onInit(): void {
        this.setOptionList();
        this.buildFormGroup();
        this.buildTableResponse();
        this.setStateReady();
    }

    public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            startDate: [null],
            endDate: [null],
            agreementList: [null],
            procurementList: [null],
            paymentInvoiceList: [null],
            vendorList: [null]
        });
    }

    public setOptionList(): void {
        this.httpClientService
            .get<PaymentHistoryOptionResponse>('/payment-history/get-option-list')
            .subscribe(
                (paymentHistoryOptionResponse: PaymentHistoryOptionResponse) => {
                    this.procurementOptionList.setRequestValues(
                        paymentHistoryOptionResponse.procurementList
                    );
                    this.agreementOptionList.setRequestValues(
                        paymentHistoryOptionResponse.agreementList
                    );
                    this.paymentInvoiceOptionList.setRequestValues(
                        paymentHistoryOptionResponse.paymentInvoiceList
                    );
                    this.vendorOptionList.setRequestValues(
                        paymentHistoryOptionResponse.vendorList
                    );
                }
            );
    }
    
    public buildTableResponse(): void {
        this.tableResponse = new TableResponseModel(this.moduleCode, [
            {
                field: 'paymentInvoice.code',
                header: 'table.column.invoiceNumber'
            },
            {
                field: 'paymentInvoice.invoiceDate',
                header: 'table.column.invoiceDate',
                format: FieldFormatEnum.ShortDate
            },
            {
                field: 'paymentVoucher.code',
                header: 'table.column.voucherNumber'
            },
            {
                field: 'paymentBilling.vendor.name',
                header: 'table.column.vendorName'
            },
            {
                field: 'paymentBilling.title',
                header: 'table.column.procurementName'
            },
            {
                field: 'paymentStatus.name',
                header: 'table.column.status'
            }
        ]);
    }

    public doApply(): void {
        this.global.routerParams.clear();
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
    }

    public doReset(): void {
        this.formGroup.reset();
        this.tableResponse.resetCustomData();
        this.tableResponse.reload();
    }

    public doDetail(paymentView: PaymentView): void {
        this.global.routerParams.clear();
        this.global.routerParams.set('todo', 'view');
        this.global.routerParams.set('paymentId', paymentView.id);
        this.global.routerParams.set(
            'paymentInvoiceId',
            paymentView.paymentInvoice.id
        );
        this.global.routerParams.set(
            'paymentVoucherId',
            paymentView.paymentVoucher.id
        );

        this.global.routerParams.set('paymentView', paymentView);
        this.router.navigate(['/pages/payment-history/detail']);
    }
}
