import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAccordionModule } from '../app-accordion/app-accordion.module';
import { AppFieldsetModule } from '../app-fieldset/app-fieldset.module';
import { AppCardModule } from './../app-card/app-card.module';
import { AppTableModule } from './../app-table/app-table.module';
import { AppWorkPlanInfoComponent } from './app-work-plan-info.component';

@NgModule({
  imports: [
    VendorModule,
    AppTableModule,
    AppFieldsetModule,
    AppCardModule,
    AppAccordionModule
  ],
  declarations: [AppWorkPlanInfoComponent],
  exports: [AppWorkPlanInfoComponent]
})
export class AppWorkPlanInfoModule {}
