<div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-card header="Rabbit MQ" [isLoading]="formLoading"
        [isSaving]="formSaving">
        <div class="button-group button-group-center">
            <app-button (onClick)="doInterface('INSTITUTION')">
                {{ 'Do interface Institution' }}
            </app-button>
            <app-button (onClick)="doInterface('EMPLOYEE')">
                {{ 'Do interface Employee' }}
            </app-button>
        </div>
      </app-card>
    </div>
</div>