<app-card
  header="app-item-request.title"
  tips="app-item-request.detail.itemRequest.tips"
>
  <ng-template #headerRight>
    <app-button
      (onClick)="doAddItem()"
      title="{{ 'app.button.add' | translate }}"
      *ngIf="!isView"
    >
      <em class="pir pi-plus-circle"></em>
      {{ 'app-item-request.button.addList' | translate }}
    </app-button>
  </ng-template>
  <app-fullfilled *ngIf="isShowRequired"></app-fullfilled>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <app-table-xx [model]="tableResponse" (onClick)="doViewItem($event)">
      <ng-template #headerFilter>
        <div class="app-table-filter-item" style="height: 34px">
          <div class="form-group row text-sm-left">
            <div class="col text-left" [ngStyle]="{ 'min-width': '10rem' }">
              <app-dropdown-select
                size="XL"
                formControlName="itemType"
                type="COMBOBOX"
                [optionList]="itemTypeOptionList"
                (onChange)="onChangeItem()"
                container="body"
                placeholder="{{
                  'app-item-request.placeholder.type' | translate
                }}"
              >
                <ng-template #value let-value>
                  {{ getTranslateKey(value) | translate }}
                </ng-template>
                <ng-template #option let-data>
                  {{ getTranslateKey(data) | translate }}
                </ng-template>
              </app-dropdown-select>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-container *ngIf="isShowPrice">
        <ng-template #row>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td *ngIf="initiationType !== 'BLANKET_ORDER'"></td>
            <td class="text-right" colspan="3">
              {{ 'app-item-request.footer.goodsAmount' | translate }}
            </td>
            <td colspan="99" class="text-right">
              <strong>
                {{ formGroup.value.goodsAmount | resolveNumberToCurrency }}
                IDR
              </strong>
            </td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td *ngIf="initiationType !== 'BLANKET_ORDER'"></td>
            <td class="text-right" colspan="3">
              {{ 'app-item-request.footer.serviceAmount' | translate }}
            </td>
            <td colspan="99" class="text-right">
              <strong>
                {{ formGroup.value.serviceAmount | resolveNumberToCurrency }}
                IDR
              </strong>
            </td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td *ngIf="initiationType !== 'BLANKET_ORDER'"></td>
            <td class="text-right" colspan="3">
              {{ 'app-item-request.footer.amount' | translate }}
            </td>
            <td colspan="99" class="text-right">
              <strong>
                {{ formGroup.value.amount | resolveNumberToCurrency }}
                IDR
              </strong>
              <br />
              <i>
                ({{
                  formGroup.value.amount
                    | resolveNumberToWords: global.currentLang
                }}
                {{ 'Rupiah' }})
              </i>
            </td>
          </tr>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="!isView">
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            (onClick)="doDeleteItem(rowData)"
            title="{{ 'app.tooltip.delete' | translate }}"
          >
            <em class="pir pi-trash-alt"></em>
            {{ 'app.button.delete' | translate }}
          </app-button>
        </ng-template>
      </ng-container>
    </app-table-xx>
  </form>
</app-card>
