import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { AppWorkDefinitionResponse } from './app-work-definition-response';

@Component({
  selector: 'app-work-definition',
  templateUrl: './app-work-definition.component.html'
})
export class AppWorkDefinitionComponent extends BaseComponentComponent {
  @Input() public contractId: number;

  public response: AppWorkDefinitionResponse;

  constructor() {
    super('app-work-definition');
  }

  public onInit(): void {
    this.getData();
  }

  public getData(): void {
    this.httpClientService
      .get<AppWorkDefinitionResponse>(
        '/app-work-definition/index/' + this.contractId
      )
      .subscribe(response => {
        this.response = response;
        this.setStateReady();
      });
  }
}
