import { NgModule } from '@angular/core';
import { SharedModule } from '../../core/shared/shared.module';
import { ContentRoutingModule } from './content-routing.module';
import { ContentComponent } from './content.component';
@NgModule({
    imports: [ SharedModule, ContentRoutingModule ],
    declarations: [ ContentComponent ]
})
export class ContentModule {
}
