import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Bank } from '../../core/bean/bank';
import { Region } from '../../core/bean/region';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { BankResponse } from './bank-response';

@Component({
  templateUrl: './bank-edit-add.component.html'
})
export class BankEditAddComponent extends BaseModuleComponent {
  public bankId: number;
  public valueList: OptionListModel<any> = new OptionListModel();

  public bankResponse: BankResponse = new BankResponse();
  public regionList: OptionListModel<Region> = new OptionListModel();
  constructor(translateService: TranslateService) {
    super('bank', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.setValueListRequestValue();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.bankId = this.global.routerParams.get('bankId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null, Validators.required()],
      region: [null, Validators.required()],
      description: [null],
      code: [
        null,
        {
          validators: [Validators.required()],
          asyncValidators: [
            Validators.existsAsync('/bank/check-exist', null, null, {
              id: this.bankId
            })
          ]
        }
      ],
      key: [
        null,
        {
          validators: [Validators.required()],
          asyncValidators: [
            Validators.existsAsync('/bank/check-exist', null, null, {
              id: this.bankId
            })
          ]
        }
      ],
      swift: [
        null,
        {
          validators: [Validators.maxLength(32)],
          asyncValidators: [
            Validators.existsAsync('/bank/check-exist', null, null, {
              id: this.bankId
            })
          ]
        }
      ],
      value: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<BankResponse>(
        '/bank/add-edit',
        new RouteRequestModel(this.todo, this.bankId)
      )
      .subscribe(bankResponse => {
        this.bankResponse = bankResponse;
        this.regionList.setRequestValues(this.bankResponse.regionList);

        if (this.bankResponse.bank != null) {
          this.formGroup.patchValue(this.bankResponse.bank);
          const value = this.valueList
            .getRequestValues()
            .filter(
              valueOption => valueOption.value === bankResponse.bank.value
            )[0];
          this.formGroup.patchValue({
            value
          });
        }
        this.setStateReady();
      });
  }

  public setValueListRequestValue(): void {
    const valueList = [
      { name: '0', value: 0 },
      { name: '1', value: 1 },
      { name: '2', value: 2 },
      { name: '3', value: 3 },
      { name: '4', value: 4 },
      { name: '5', value: 5 }
    ];
    this.valueList.setRequestValues(valueList);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const bank: Bank = this.global.copyFormAttributeToModel(
              new Bank(),
              this.formGroup
            );
            bank.value = this.formGroup.get('value').value.value;
            const url: string =
              this.todo === 'edit' ? '/bank/update' : '/bank/insert';
            this.httpClientService
              .post<Response<Bank>>(url, bank)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/bank/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/bank/']);
  }
}
