<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="number-generator.title" detail={{module.name}}
      [isLoading]="formLoading" [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/number-generator/detail">
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}">
            <em class="fas fa-pencil-alt"></em>
          </app-button>
        </ng-template>
      </app-table>
      <div class="button-group button-group-center mt-5">
        <app-button mode="BYPASS" color="SECONDARY" (onClick)="doCancel()">
          {{ 'app.button.back' | translate}}
        </app-button>
      </div>
    </app-card>
  </div>
</div>