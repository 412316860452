<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="scheduler.title" detail="app.action.{{todo}}" [isLoading]="formLoading"
            [isSaving]="formSaving">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                <div class="form-group row text-sm-right">
                    <label class="col-sm-3 control-label">{{
                        'scheduler.form.code' | translate }}</label>
                    <div class="col-sm-9 text-left ">
                        {{ formGroup.controls.code.value }}
                    </div>
                </div>
                <div class="form-group row text-sm-right">
                    <label class="col-sm-3 control-label">{{
                        'scheduler.form.name' | translate }}</label>
                    <div class="col-sm-9 text-left ">
                        {{ formGroup.controls.name.value }}
                    </div>
                </div>
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'scheduler.form.cron' | translate }}</label>
                    <div class="col-sm-9 text-left ">
                        <app-text-field formControlName="cron" maxLength="64" autofocus="true"></app-text-field>
                        <small class="d-block mt-2">{{
                            'scheduler.form.cronInformation' | translate }} <a [attr.href]="'https://www.freeformatter.com/cron-expression-generator-quartz.html'"
                                target="_blank" rel=noopener>Cron Formatter</a></small><br>
                    </div>
                </div>
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'scheduler.form.status' | translate }}</label>
                    <div class="col-sm-9 text-left">
                        <app-combo-box formControlName="isActive" [optionList]="schedulerStatusList"></app-combo-box>
                    </div>
                </div>
                <div class="button-group button-group-center mt-5">
                    <app-button color="SECONDARY" (onClick)="doCancel()" [disabled]="formSaving">
                        {{ 'app.button.back' | translate}}
                    </app-button>
                    <app-button (onClick)="doSave()" [disabled]="formSaving">
                        {{ 'app.button.save' | translate}}
                    </app-button>
                </div>
            </form>
        </app-card>
    </div>
</div>