import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartTooltipItem } from 'chart.js';
import { Organization } from 'src/app/core/bean/organization';
import { ChartModel } from 'src/app/core/components/app-chart/models/chart-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetBudgetIndicatorRequest } from './widget-budget-indicator.request';
import { WidgetBudgetIndicatorResponse } from './widget-budget-indicator.response';

@Component({
  templateUrl: './widget-budget-indicator.component.html'
})
export class WidgetBudgetIndicatorComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-budget-indicator';
  public chartModel: ChartModel;
  public yearOptionList: OptionListModel<number> = new OptionListModel(true);
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);
  public widgetBudgetIndicatorRequest: WidgetBudgetIndicatorRequest =
    new WidgetBudgetIndicatorRequest();
  public widgetBudgetIndicatorResponse: WidgetBudgetIndicatorResponse =
    new WidgetBudgetIndicatorResponse();
  public modelList: any[] = [];
  public totalBudget: number;
  public modelDefaultList: any[] = [];
  public moneyFormatTranslateKeyList: Array<string>;
  public colorList = ['#14B1AB'];
  public legendList: Array<any>;

  constructor(public translateService: TranslateService) {
    super(WidgetBudgetIndicatorComponent.moduleCode);
  }

  public onInit(): void {
    this.setStateMoneyFormatTranslateKeyList();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      year: [null],
      organization: [null],
      isAllPeriod: [false]
    });
  }

  public setFormGroup(): void {
    this.widgetBudgetIndicatorRequest = this.formGroup.value;

    this.httpClientService
      .post<WidgetBudgetIndicatorResponse>(
        '/widget-budget-indicator/index',
        this.widgetBudgetIndicatorRequest
      )
      .subscribe((response: WidgetBudgetIndicatorResponse) => {
        if (response) {
          this.widgetBudgetIndicatorResponse = response;
          this.modelList = response.widgetBudgetIndicatorModelList;

          this.yearOptionList.setRequestValues(response.periodList);
          this.organizationOptionList.setRequestValues(
            response.organizationList
          );

          if (
            !this.formGroup.get('year').value &&
            !this.formGroup.get('isAllPeriod').value &&
            response.periodList &&
            response.periodList.length > 0
          ) {
            this.formGroup
              .get('year')
              .patchValue(response.periodList[response.periodList.length - 1]);
          }
          this.setChartModel();
        }
      });
  }

  public setChartModel(): void {
    this.setDataGroup();

    const colorList = this.colorList.map(color =>
      getComputedStyle(document.body).getPropertyValue(color)
    );

    this.legendList =
      this.widgetBudgetIndicatorResponse.widgetBudgetIndicatorModelList.map(
        (data, index) => {
          return {
            total: data.total,
            label: this.global.translateService.instant(
              this.moduleCode + '.label.' + data.name
            ),
            color: colorList[index]
          };
        }
      );
    const label = this.legendList.map(val => val.label);
    const dataSets = this.legendList.map(val => val.total);
    const dataSetLabels = [];
    dataSetLabels.push(
      this.translateService.instant(this.moduleCode + '.label.spending')
    );
    this.chartModel = new ChartModel('bar', label, dataSets, dataSetLabels, {
      defaultColor: this.modelDefaultList.map(model => model.color),
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 50000000,
              callback: (value): string => {
                return this.global.converterService.convertToShortMoneyChart(
                  value
                ) as string;
              }
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      },
      tooltips: {
        mode: 'nearest',
        xPadding: 20,
        yPadding: 20,
        titleFontStyle: 'font-weight: normal; padding: 20px',
        bodyFontStyle: 'font-weight: bold',
        footerFontStyle: 'font-weight: normal',
        callbacks: {
          title: (item: ChartTooltipItem[]): string => {
            return this.global.translateService.instant(
              this.moduleCode +
                '.label.' +
                this.legendList[item[0].index].label.toLowerCase()
            );
          },
          label: () => null,
          afterBody: (item: Chart.ChartTooltipItem[]): string => {
            const data = this.legendList[item[0].index];

            return `${this.global.converterService.convertMoney(data.total)} ${
              data.currency || 'IDR'
            }`;
          }
        }
      }
    });

    this.setStateReady();
  }

  public onChangeFormGroup(): void {
    if (this.formGroup.value) {
      if (!this.formGroup.get('year').value) {
        this.formGroup.get('isAllPeriod').patchValue(true);
      }
      this.setStateLoading();
      this.setFormGroup();
    }
  }

  public setDataGroup(): void {
    this.totalBudget = this.widgetBudgetIndicatorResponse.totalBudget;
    this.modelDefaultList = [
      {
        code: this.translateService.instant(
          this.moduleCode + '.label.budgetIndicator'
        ),
        total: this.global.converterService.convertToShortMoney(
          this.totalBudget || 0,
          this.moneyFormatTranslateKeyList
        ) as string,
        color: '#14B1AB'
      }
    ];
  }

  private setStateMoneyFormatTranslateKeyList(): void {
    this.moneyFormatTranslateKeyList = [
      this.global.translateService.instant('app.moneyFormat.thousand'),
      this.global.translateService.instant('app.moneyFormat.million'),
      this.global.translateService.instant('app.moneyFormat.billion'),
      this.global.translateService.instant('app.moneyFormat.trillion'),
      this.global.translateService.instant('app.moneyFormat.quadrillion'),
      this.global.translateService.instant('app.moneyFormat.quantillion'),
      this.global.translateService.instant('app.moneyFormat.sextillion')
    ];
  }
}
