<form class="form-horizontal" [formGroup]="formGroup" novalidate
  *ngIf="!model.isLoading">
  <div class="form-group row">
    <div class="col-lg-12">
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/tab-financial-statement/index"
      >
      </app-table>
    </div>
  </div>
</form>


