import { Contract } from '../../core/bean/contract';
import { ContractNegotiation } from '../../core/bean/contract-negotiation';
import { ContractNegotiationHistory } from '../../core/bean/contract-negotiation-history';
import { ContractReviewer } from '../../core/bean/contract-reviewer';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path-x';
import { AppPopupVersionHistoryModel } from '../../core/components/app-popup/app-popup-version-history/app-popup-version-history-model';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';

export class ContractNegotiationEditResponse {
  public appPopupVersionHistoryModelList: AppPopupVersionHistoryModel[];
  public contractReviewerList: ContractReviewer[];
  public contractNegotiation: ContractNegotiation;
  public contractNegotiationHistory: ContractNegotiationHistory;
  public contract: Contract;
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public approvalPathResponseModel: ApprovalPathResponseModel;
  public isUserApproval: boolean;
  public isProcessed: boolean;
}
