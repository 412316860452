import { BaseEntity } from '../base/base-entity';
import { SowPaymentTerm } from '../bean/sow-payment-term';
import { StageOfWork } from '../bean/stage-of-work';
import { Vendor } from '../bean/vendor';
import { DrType } from './../bean/dr-type';
import { PaymentTerm } from './../bean/payment-term';
export class PaymentBillingEntity extends BaseEntity {
  vendor: Vendor = new Vendor();
  sowPaymentTerm: SowPaymentTerm = new SowPaymentTerm();
  stageOfWork: StageOfWork = new StageOfWork();
  paymentTerm: PaymentTerm = new PaymentTerm();
  drType: DrType;
  amount: number;
  remainingAmount: number;
  isSubmitted: boolean;
  isInternal: boolean;
  requiredDate: Date;
  title: string;
}
