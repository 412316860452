import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ApprovalPathEvent } from './events/approval-path-event';
import { ApprovalPathModel } from './model/approval-path-model';
@Component({
  selector: 'app-approval-path',
  templateUrl: './app-approval-path.component.html',
  styleUrls: ['./app-approval-path.component.scss']
})
export class AppApprovalPathComponent extends BaseComponentComponent {
  @Input() public model: ApprovalPathModel<Array<object>>;

  @Output() private onChoose: EventEmitter<void> = new EventEmitter();
  @Output() private onEdit: EventEmitter<number> = new EventEmitter();
  @Output() private onChange: EventEmitter<ApprovalPathEvent<object>> =
    new EventEmitter();

  @ContentChild('customText') public customTextTmpl: TemplateRef<object>;

  constructor() {
    super('app-approval-path');
  }

  public onInit(): void {
    this.doHandleModelChanges();
  }

  private doHandleModelChanges(): void {
    this.model.approvalEventChanges.subscribe(
      (event: ApprovalPathEvent<object>) => {
        this.onChange.emit(event);
      }
    );
  }

  public doEditApprover(index: number): void {
    if (this.model.mode === 'WRITE') {
      this.onEdit.emit(index);
    }
  }

  public doChooseApprover(): void {
    this.onChoose.emit();
  }

  public doDeleteApprover(index: number): void {
    if (this.model.mode === 'WRITE') {
      this.global.modalService
        .deleteConfirmation()
        .pipe(take(1))
        .subscribe((result: boolean) => {
          if (result) {
            this.model.removeAt(index);
          }
        });
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
