import { PicklistOptions } from '../interface/pick-list-options';
export class PickListModel<T> {
  public sourceTitle: string;
  public targetTitle: string;
  public searchSourcePlaceholder: string;
  public searchTargetPlaceholder: string;
  constructor(
    public moduleCode: string,
    public sourceItemList: Array<T>,
    public targetItemList: Array<T>,
    public viewField: string = 'name',
    public options?: PicklistOptions
  ) {
    this.sourceTitle =
      options && options.sourceTitle
        ? options.sourceTitle
        : moduleCode + '.appPickList.sourceTitle';
    this.targetTitle =
      options && options.targetTitle
        ? options.targetTitle
        : moduleCode + '.appPickList.targetTitle';
    this.searchSourcePlaceholder =
      options && options.searchSourcePlaceholder
        ? options.searchSourcePlaceholder
        : moduleCode + '.appPickList.sourceSearchPlaceholder';
    this.searchTargetPlaceholder =
      options && options.searchSourcePlaceholder
        ? options.searchSourcePlaceholder
        : moduleCode + '.appPickList.sourceSearchPlaceholder';
  }
}
