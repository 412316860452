import { BaseDTO } from '../../../core/base/base-dto';
import { CertificatePeriod } from '../../../core/bean/certificate-period';
import { DueDiligenceTemplate } from '../../../core/bean/due-diligence-template';
import { Organization } from '../../../core/bean/organization';
import { VendorAdditionalDocHistory } from '../../../core/bean/vendor-additional-doc-history';
import { VendorCategory } from '../../../core/bean/vendor-category';
import { VendorCertificate } from '../../../core/bean/vendor-certificate';
import { VendorDocGroup } from '../../../core/bean/vendor-doc-group';
import { VendorHistory } from '../../../core/bean/vendor-history';
import { VendorVerification } from '../../../core/bean/vendor-verification';
import { VerificationVendorCategory } from '../../../core/bean/verification-vendor-category';

export class TabProfileVerificationDTO extends BaseDTO {
    approvalNote: string;
    vendorHistory: VendorHistory;
    vendorVerification: VendorVerification;
    vendorCategory: VendorCategory = new VendorCategory();
    vendorCertificate: VendorCertificate = new VendorCertificate();
    verificationVendorCategoryList: VerificationVendorCategory[] = [];
    dueDiligenceTemplateList: DueDiligenceTemplate[];
    certificatePeriodList: CertificatePeriod[];
    vendorCategoryList: VendorCategory[];
    organizationList: Organization[];
    vendorVerificationList: VendorVerification[] = [];
    isUserApproval: boolean;

    // Tab Document Company Profile
    vendorDocGroupList: VendorDocGroup[];
    vendorAdditionalDocHistoryList: VendorAdditionalDocHistory[];
}
