import { EventEmitter } from '@angular/core';

export class ChatMenuModel {
    public roomCode: string;
    public isDownloadSession: boolean;
    public isDownloadUserList: boolean;
    public isCanSendMessage: boolean;
    public isAllowAttachFile: boolean;

    public menuModelChanges: EventEmitter<ChatMenuModel> = new EventEmitter();

    public setMenuModel(model: this): this {
        this.roomCode = model.roomCode;
        this.isDownloadSession = model.isDownloadSession;
        this.isDownloadUserList = model.isDownloadUserList;
        this.isAllowAttachFile = model.isAllowAttachFile;
        this.isCanSendMessage = model.isCanSendMessage;
        this.menuModelChanges.emit(model);
        return this;
    }

}
