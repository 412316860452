import { Catalog } from './catalog';
import { CatalogCategory } from './catalog-category';
import { CatalogImage } from './catalog-image';
import { CatalogInventory } from './catalog-inventory';
import { CatalogSpecification } from './catalog-specification';

export class CatalogBeans {

    catalog: Catalog = new Catalog();
    catalogCategoryList: CatalogCategory[] = [];
    catalogImageList: CatalogImage[] = [];
    catalogInventoryList: CatalogInventory[] = [];
    catalogSpesificationList: CatalogSpecification[] = [];
}
