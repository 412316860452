<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="procurement-scope-work.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/procurement-scope-work/index"
      >
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
            *ngIf="
              !rowData.procurementScopeWork ||
              rowData.procurementScopeWork?.deliveryStatus === 1
            "
          >
            <em class="fas fa-pencil-alt"></em>
          </app-button>
          <app-button
            size="TN"
            (onClick)="doDetail(rowData)"
            title="{{ 'app.tooltip.view' | translate }}"
            *ngIf="
              rowData.procurementScopeWork &&
              rowData.procurementScopeWork?.deliveryStatus !== 1
            "
          >
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
