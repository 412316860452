import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
export const rotateAnimation = (
  rotateTo = '180deg',
  animateTime = '250ms'
): AnimationTriggerMetadata => {
  return trigger('rotate', [
    state('default', style({ transform: 'rotate(0)' })),
    state('rotated', style({ transform: `rotate(${rotateTo})` })),
    transition('default<=>rotated', animate(animateTime))
  ]);
};
