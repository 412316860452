import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { Workflow } from './../../core/bean/workflow';
import { WorkflowModel } from './../../core/bean/workflow-model';
@Component({
  templateUrl: './sla-edit-add.component.html'
})
export class SlaEditAddComponent extends BaseModuleComponent {
  public workflowModelList: Array<WorkflowModel> = new Array();
  public workflow: Workflow;
  public workflowModelMain: WorkflowModel[] = [];
  public workflowModelAdditional: WorkflowModel[] = [];
  public workflowModelApproval: WorkflowModel[] = [];
  public isAdditionalFound = false;
  public isApprovalFound = false;
  public isMainFound = false;
  public isValid = true;

  @ViewChild('selectorMain') public tableMain: AppTableComponent;
  public tableResponseMain: TableResponseModel<WorkflowModel>;
  @ViewChild('selectorAdditional') public tableAdditional: AppTableComponent;
  public tableResponseAdditional: TableResponseModel<WorkflowModel>;
  @ViewChild('selectorApproval') public tableApproval: AppTableComponent;
  public tableResponseApproval: TableResponseModel<WorkflowModel>;

  constructor(translateService: TranslateService) {
    super('sla', translateService);
  }

  onInit(): void {
    this.doSetDataFromRouterParams();
    this.doGetAndSetWorkflowModelList();
    this.buildTableResponseMain();
    this.buildTableResponseAdditional();
    this.buildTableResponseApproval();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.workflow = this.global.routerParams.get('workflow');
  }

  public doGetAndSetWorkflowModelList(): void {
    this.httpClientService
      .post<WorkflowModel[]>('/sla/add-edit', this.workflow.id)
      .subscribe(workflowModelList => {
        this.workflowModelList = workflowModelList;
        this.workflowModelList.forEach(workflowModel => {
          if (workflowModel.type === 3) {
            this.workflowModelAdditional.push(workflowModel);
            this.isAdditionalFound = true;
          }
          if (workflowModel.approval !== null) {
            this.workflowModelApproval.push(workflowModel);
            this.isApprovalFound = true;
          }
          if (workflowModel.type !== 3) {
            this.workflowModelMain.push(workflowModel);
            this.isMainFound = true;
          }
        });
        this.workflowModelMain.forEach(workflowMain => {
          this.formGroup = this.formBuilder.group({
            slaApproval: [workflowMain.slaInDays, Validators.required()]
          });
        });
        this.tableResponseMain.setRecords(this.workflowModelMain);
        this.tableResponseMain.setTotalRecords(this.workflowModelMain.length);
        this.tableMain.reloadClient();

        if (this.workflowModelAdditional !== null) {
          this.workflowModelAdditional.forEach(workflowAdditional => {
            this.formGroup = this.formBuilder.group({
              slaApproval: [workflowAdditional.slaInDays, Validators.required()]
            });
          });
          this.tableResponseAdditional.setRecords(this.workflowModelAdditional);
          this.tableResponseAdditional.setTotalRecords(
            this.workflowModelAdditional.length
          );
          this.tableAdditional.reloadClient();
        }

        if (this.workflowModelApproval !== null) {
          this.workflowModelApproval.forEach(workflowApproval => {
            this.formGroup = this.formBuilder.group({
              slaApproval: [
                workflowApproval.slaApprovalInDays,
                Validators.required()
              ]
            });
          });
          this.tableResponseApproval.setRecords(this.workflowModelApproval);
          this.tableResponseApproval.setTotalRecords(
            this.workflowModelApproval.length
          );
          this.tableApproval.reloadClient();
        }
        this.setStateReady();
      });
  }

  public buildTableResponseMain(): void {
    this.tableResponseMain = new TableResponseModel(this.moduleCode, [
      { field: 'module.name', header: 'table.column.stage' },
      {
        field: 'slaInDays',
        header: 'table.column.day',
        format: FieldFormatEnum.InputNumber
      }
    ]);
  }

  public buildTableResponseAdditional(): void {
    this.tableResponseAdditional = new TableResponseModel(this.moduleCode, [
      { field: 'module.name', header: 'table.column.stage' },
      {
        field: 'slaInDays',
        header: 'table.column.day',
        format: FieldFormatEnum.InputNumber
      }
    ]);
  }

  public buildTableResponseApproval(): void {
    this.tableResponseApproval = new TableResponseModel(this.moduleCode, [
      { field: 'module.name', header: 'table.column.stage' },
      {
        field: 'slaApprovalInDays',
        header: 'table.column.day',
        format: FieldFormatEnum.InputNumber
      }
    ]);
  }

  public doOnBlurValid(): void {
    for (const workflowModel of this.workflowModelList) {
      this.isValid = true;
      if (workflowModel.slaInDays === null) {
        this.formSaving = true;
        this.isValid = false;
        break;
      } else {
        this.isValid = true;
      }
    }
  }

  public doSave(): void {
    if (this.isValid === true) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const workflowModelResult: Array<WorkflowModel> = new Array();
            this.workflowModelList = null;
            this.workflowModelMain.forEach(workflowMain => {
              if (workflowMain.approval != null) {
                this.workflowModelApproval.forEach(workflowApproval => {
                  if (workflowApproval.id === workflowMain.id) {
                    workflowMain.slaApprovalInDays =
                      workflowApproval.slaApprovalInDays;
                  }
                });
              }
              workflowModelResult.push(workflowMain);
            });

            this.workflowModelAdditional.forEach(workflowAdditional => {
              if (workflowAdditional.approval != null) {
                this.workflowModelApproval.forEach(workflowApproval => {
                  if (workflowApproval.id === workflowAdditional.id) {
                    workflowAdditional.slaApprovalInDays =
                      workflowApproval.slaApprovalInDays;
                  }
                });
              }
              workflowModelResult.push(workflowAdditional);
            });
            this.httpClientService
              .post<Response<WorkflowModel[]>>(
                '/sla/update',
                workflowModelResult
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/sla']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/sla']);
  }
}
