import { BaseEntity } from '../base/base-entity';
import { Payment } from '../bean/payment';

export class PaymentHistoryEntity extends BaseEntity {
  picName: string;
  date: Date;

  payment: Payment;
  amount: number;
  note: string;
  userType: number;
  status: number;
  sequence: number;
  isSubmit: boolean;
  isActive: boolean;
}
