<app-layout>
    <div class="row main-row">
        <div class="col-lg-12 mb-4">
            <div *ngIf="!formLoading" style="
          border-bottom: 1px solid var(--light-tertiary-color);
          padding-top: 32px;
        ">
                <app-content-slider>
                    <app-content-slider-item>
                        <ng-template #customHeader>
                    	    <div></div>
                        </ng-template>
                        <ng-template #customContent>
                            <!-- <div *ngIf="
                                (roleCode === global.appConstant.ROLE_CODE_BUYER)" class="slider-item-report cursor-pointer"
                                (click)="doClickHistoryType('procurement')" [ngStyle]="
                                historyType === 'procurement'
                                ? { border: '1px solid var(--primary)' }
                                : ''
                            ">
                                <div class="slider-title">
                                    <div class="slider-title-icon pvc pv-box-envelope"></div>
                                    <h4 class="text-primary">
                                        {{
                                        'sla-pm.slider.procurement.title' | translate
                                    }}</h4>
                                </div>
                            </div> -->
                            <div *ngIf="
                                (roleCode === global.appConstant.ROLE_CODE_SUPER_ADMIN || 
                                roleCode === global.appConstant.ROLE_CODE_PJB_PBJ_PUSAT)" 
                                class="slider-item-report cursor-pointer"
                                (click)="doClickHistoryType('purchase request')" [ngStyle]="
                                historyType === 'purchase request'
                                ? { border: '1px solid var(--primary)' }
                                : ''
                            ">
                                <div class="slider-title">
                                    <div class="slider-title-icon pvc pv-purchase"></div>
                                    <h4 class="text-primary">
                                        {{
                                        'sla-pm.slider.purchase-request.title' | translate
                                    }}</h4>
                                </div>
                            </div>
                            <!-- <div *ngIf="
                                (roleCode === global.appConstant.ROLE_CODE_BUYER)" class="slider-item-report cursor-pointer"
                                (click)="doClickHistoryType('rfp')" [ngStyle]="
                                historyType === 'rfp'
                                ? { border: '1px solid var(--primary)' }
                                : ''
                            ">
                                <div class="slider-title">
                                    <div class="slider-title-icon pvc pv-proposal"></div>
                                    <h4 class="text-primary">
                                        {{
                                        'sla-pm.slider.rfp.title' | translate
                                    }}</h4>
                                </div>
                            </div>
                            <div *ngIf="
                                (roleCode === global.appConstant.ROLE_CODE_BUYER)" class="slider-item-report cursor-pointer"
                                (click)="doClickHistoryType('rfq')" [ngStyle]="
                                historyType === 'rfq'
                                ? { border: '1px solid var(--primary)' }
                                : ''
                            ">
                                <div class="slider-title">
                                    <div class="slider-title-icon pvc pv-quotation"></div>
                                    <h4 class="text-primary">
                                        {{
                                        'sla-pm.slider.rfq.title' | translate
                                    }}</h4>
                                </div>
                            </div> -->
                            <div *ngIf="
                                (roleCode === global.appConstant.ROLE_CODE_SUPER_ADMIN || 
                                roleCode === global.appConstant.ROLE_CODE_PJB_PBJ_PUSAT ||
                                roleCode === global.appConstant.ROLE_CODE_BUYER ||
                                roleCode === global.appConstant.ROLE_CODE_DELIVERY)" 
                                class="slider-item-report cursor-pointer"
                                (click)="doClickHistoryType('order process')" [ngStyle]="
                                historyType === 'order process'
                                ? { border: '1px solid var(--primary)' }
                                : ''
                            ">
                                <div class="slider-title">
                                    <div class="slider-title-icon pvc pv-order-process"></div>
                                    <h4 class="text-primary">
                                        {{
                                        'sla-pm.slider.order-process.title' | translate
                                    }}</h4>
                                </div>
                            </div>
                        </ng-template>
                    </app-content-slider-item>
                </app-content-slider>
            </div>
            <app-table-xx *ngIf="formGroup.get('historyType').value === 'procurement'"
                [model]="tableResponseProcurement" stringUrl="/sla-pm/index" (onClick)="doClick($event)">
                <ng-template #headerFilterGroup>
                    <form class="form-horizontal" [formGroup]="formGroup" novalidate *ngIf="!formLoading">
                        <div class="form-group row text-sm-left">
                            <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
                                <label class="cml-3 d-flex align-items-center"
                                    style="margin-top: auto">{{ 'sla-pm.form.date' | translate }}</label>
                                <div class="cml-3 text-left">
                                    <app-date-picker-x size="XL" formControlName="date" [range]="true"
                                        (onChange)="onChangeFilter()">
                                    </app-date-picker-x>
                                </div>
                                <div class="cml-3 text-left col-sm-2">
                                    <app-dropdown-select size="XL" formControlName="workflowName"
                                        (onChange)="onChangeFilter()" showLimitValue="1" type="CHOSEN" isMultiple="true"
                                        placeholder="sla-pm.placeholder.workflowName" [optionList]="workflowList">
                                        <ng-template #selectAll>
                                            <p>
                                                {{
                                        'sla-pm.placeholder.allWorkflowName' | translate
                                    }}
                                            </p>
                                        </ng-template>
                                    </app-dropdown-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>


                <!--<ng-template #headerFilterGroup>
                    <form class="form-horizontal pt-4" [formGroup]="formGroup" novalidate *ngIf="!formLoading">
                        <div class="form-group row text-sm-left">
                            <label class="col-sm-1" style="margin-top: auto">{{
                          'compliance-test.form.date' | translate
                        }}</label>
                            <div class="col-sm-7 text-left" style="display: flex">
                                <app-date-picker-x size="XL" formControlName="date" [range]="true"
                                    (onChange)="onChangeFilter()" [ngStyle]="{
                              'margin-right': '7px',
                              width: 'inherit'
                            }">
                                </app-date-picker-x>
                                <app-dropdown-select size="XL" formControlName="workflowName"
                                    (onChange)="onChangeFilter()" placeholder="{{
                  'sla-pm.placeholder.workflowName' | translate
                }}" [optionList]="workflowList" type="CHOSEN" [ngStyle]="{
                  'margin-right': '7px',
                  width: '467px'
                }" showLimitValue="1">
                                    <ng-template #selectAll>
                                        <p>
                                            {{
                      'sla-pm.placeholder.allWorkflowName'
                        | translate
                    }}
                                        </p>
                                    </ng-template>
                                </app-dropdown-select>
                            </div>
                        </div>
                    </form>
                </ng-template>-->
            </app-table-xx>
            <app-table-xx *ngIf="formGroup.get('historyType').value === 'purchase request'"
                [model]="tableResponsePurchaseRequest" stringUrl="/sla-pm/index" (onClick)="doClick($event)">
                <ng-template #headerFilterGroup>
                    <form class="form-horizontal" [formGroup]="formGroup" novalidate *ngIf="!formLoading">
                        <div class="form-group row text-sm-left">
                            <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
                                <label class="cml-3 d-flex align-items-center"
                                    style="margin-top: auto">{{ 'sla-pm.form.date' | translate }}</label>
                                <div class="cml-3 text-left">
                                    <app-date-picker-x size="XL" formControlName="date" [range]="true"
                                        (onChange)="onChangeFilter()">
                                    </app-date-picker-x>
                                </div>
                                <div class="cml-3 text-left col-sm-2">
                                    <app-dropdown-select size="XL" formControlName="workflowName"
                                        (onChange)="onChangeFilter()" showLimitValue="1" type="CHOSEN" isMultiple="true"
                                        placeholder="sla-pm.placeholder.workflowName" [optionList]="workflowList">
                                        <ng-template #selectAll>
                                            <p>
                                                {{
                                        'sla-pm.placeholder.allWorkflowName' | translate
                                    }}
                                            </p>
                                        </ng-template>
                                    </app-dropdown-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </app-table-xx>
            <app-table-xx *ngIf="formGroup.get('historyType').value === 'rfp'" [model]="tableResponseRfp"
                stringUrl="/sla-pm/index" (onClick)="doClick($event)">
                <ng-template #headerFilterGroup>
                    <form class="form-horizontal" [formGroup]="formGroup" novalidate *ngIf="!formLoading">
                        <div class="form-group row text-sm-left">
                            <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
                                <label class="cml-3 d-flex align-items-center"
                                    style="margin-top: auto">{{ 'sla-pm.form.date' | translate }}</label>
                                <div class="cml-3 text-left">
                                    <app-date-picker-x size="XL" formControlName="date" [range]="true"
                                        (onChange)="onChangeFilter()">
                                    </app-date-picker-x>
                                </div>
                                <div class="cml-3 text-left col-sm-2">
                                    <app-dropdown-select size="XL" formControlName="workflowName"
                                        (onChange)="onChangeFilter()" showLimitValue="1" type="CHOSEN" isMultiple="true"
                                        placeholder="sla-pm.placeholder.workflowName" [optionList]="workflowList">
                                        <ng-template #selectAll>
                                            <p>
                                                {{
                                        'sla-pm.placeholder.allWorkflowName' | translate
                                    }}
                                            </p>
                                        </ng-template>
                                    </app-dropdown-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </app-table-xx>
            <app-table-xx *ngIf="formGroup.get('historyType').value === 'rfq'" [model]="tableResponseRfq"
                stringUrl="/sla-pm/index" (onClick)="doClick($event)">
                <ng-template #headerFilterGroup>
                    <form class="form-horizontal" [formGroup]="formGroup" novalidate *ngIf="!formLoading">
                        <div class="form-group row text-sm-left">
                            <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
                                <label class="cml-3 d-flex align-items-center"
                                    style="margin-top: auto">{{ 'sla-pm.form.date' | translate }}</label>
                                <div class="cml-3 text-left">
                                    <app-date-picker-x size="XL" formControlName="date" [range]="true"
                                        (onChange)="onChangeFilter()">
                                    </app-date-picker-x>
                                </div>
                                <div class="cml-3 text-left col-sm-2">
                                    <app-dropdown-select size="XL" formControlName="workflowName"
                                        (onChange)="onChangeFilter()" showLimitValue="1" type="CHOSEN" isMultiple="true"
                                        placeholder="sla-pm.placeholder.workflowName" [optionList]="workflowList">
                                        <ng-template #selectAll>
                                            <p>
                                                {{
                                        'sla-pm.placeholder.allWorkflowName' | translate
                                    }}
                                            </p>
                                        </ng-template>
                                    </app-dropdown-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </app-table-xx>
            <app-table-xx *ngIf="formGroup.get('historyType').value === 'order process'"
                [model]="tableResponseOrderProcess" stringUrl="/sla-pm/index" (onClick)="doClick($event)">
                <ng-template #headerFilterGroup>
                    <form class="form-horizontal" [formGroup]="formGroup" novalidate *ngIf="!formLoading">
                        <div class="form-group row text-sm-left">
                            <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
                                <label class="cml-3 d-flex align-items-center"
                                    style="margin-top: auto">{{ 'sla-pm.form.date' | translate }}</label>
                                <div class="cml-3 text-left">
                                    <app-date-picker-x size="XL" formControlName="date" [range]="true"
                                        (onChange)="onChangeFilter()">
                                    </app-date-picker-x>
                                </div>
                                <div class="cml-3 text-left col-sm-2">
                                    <app-dropdown-select size="XL" formControlName="workflowName"
                                        (onChange)="onChangeFilter()" showLimitValue="1" type="CHOSEN" isMultiple="true"
                                        placeholder="sla-pm.placeholder.workflowName" [optionList]="workflowList">
                                        <ng-template #selectAll>
                                            <p>
                                                {{
                                        'sla-pm.placeholder.allWorkflowName' | translate
                                    }}
                                            </p>
                                        </ng-template>
                                    </app-dropdown-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </app-table-xx>
        </div>
    </div>
</app-layout>