import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { File } from '../../../core/bean/file';
import { PurchaseRequest } from '../../../core/bean/purchase-request';
import { PurchaseRequestItem } from '../../../core/bean/purchase-request-item';
import { WorkPlanItem } from '../../../core/bean/work-plan-item';
import { AppTableComponent } from '../../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../../core/components/upload';
import { PurchaseRequestModel } from '../../../core/model/purchase-request-model';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { WorkPlanPurchaseRequestDTO } from '../dto/work-plan-purchase-request.dto';
@Component({
  templateUrl: 'work-plan-purchase-request-edit-add.component.html',
  encapsulation: ViewEncapsulation.None
})
export class WorkPlanPurchaseRequestComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<File>;
  public workPlanId: number;
  public workPlanPurchaseRequestDTO: WorkPlanPurchaseRequestDTO = new WorkPlanPurchaseRequestDTO();
  public organizationName: string;
  public workPlanItemList: WorkPlanItem[];
  public routeList: string[] = [
    'work-plan',
    'purchase-request',
    'procurement',
    'contract',
    'purchase-order',
    'delivery-received',
    'payment'
  ];
  public fileUploaderList: FileUploader[] = new Array();
  public isAllUploaded = false;
  public url: string;
  public isHavePR = false;
  public isHaveProcurement = false;
  public isHaveContract = false;
  public isHavePO = false;
  public isHaveDR = false;
  public purchaseRequestModelList: PurchaseRequestModel[] = new Array();
  public isQuantityValidList: boolean[] = new Array();
  public isAllQuantityValid = true;
  public totalBudgetList: number[] = new Array();
  public isPRNumberValidList: boolean[] = new Array();
  public isAllPRNumberValid = true;
  public isAllQtyUsed = false;
  public totalQtyUsed = new Array();
  public samePRNumberList: string[] = new Array();
  constructor(translateService: TranslateService) {
    super('work-plan-purchase-request', translateService);
  }

  onInit(): void {
    this.workPlanId = this.global.routerParams.get('workPlanId');
    this.formGroup = this.formBuilder.group({
      purchaseRequestModelList: this.formBuilder.array([])
    });
    this.httpClientService
      .post<WorkPlanPurchaseRequestDTO>(
        '/work-plan-purchase-request/add-edit',
        new RouteRequestModel(this.todo, this.workPlanId)
      )
      .subscribe(workPlanPurchaseRequestDTO => {
        this.organizationName =
          workPlanPurchaseRequestDTO.workPlan.organization.name;
        this.workPlanItemList = workPlanPurchaseRequestDTO.workPlanItemList;
        let length = this.workPlanItemList.length;
        while (length !== 0) {
          this.isQuantityValidList.push(true);
          length--;
        }
        this.workPlanPurchaseRequestDTO.workPlan =
          workPlanPurchaseRequestDTO.workPlan;
        if (
          workPlanPurchaseRequestDTO.purchaseRequestModelList != null &&
          workPlanPurchaseRequestDTO.purchaseRequestModelList.length > 0
        ) {
          workPlanPurchaseRequestDTO.purchaseRequestModelList.forEach(
            (purchaseRequestModel, index) => {
              this.addPurchaseRequest(false);
              this.patchFormGroup(purchaseRequestModel, index);
              this.checkTotalBudget(index);
            }
          );
          this.isAllUploaded = true;
          this.isHavePR = true;
          this.global.routerParams.set(
            'workPlanRealizeId',
            workPlanPurchaseRequestDTO.purchaseRequestModelList[0]
              .purchaseRequest.workPlanRealize.id
          );
        } else {
          this.isHavePR = false;
          this.addPurchaseRequest(true);
        }
        this.checkQuantity();
        this.setStateReady();
      });
  }

  public onQtyChange(formGroup: FormGroup, j: number, i: number): void {
    const quantity = formGroup.get('quantity').value;
    const totalPrice =
      quantity === ''
        ? 0
        : quantity * Number(this.workPlanItemList[j].unitPrice);
    formGroup.patchValue({
      quantity,
      totalPrice
    });
    this.checkTotalBudget(i);
    this.checkQuantity();
  }

  public checkTotalBudget(i: number): void {
    const purchaseRequestModelList: PurchaseRequestModel[] = this.formGroup.get(
      'purchaseRequestModelList'
    ).value;
    const purchaseRequestItemList: PurchaseRequestItem[] =
      purchaseRequestModelList[i].purchaseRequestItemList;
    let total = 0;
    for (const itemPurchaseRequest of purchaseRequestItemList) {
      total = +total + +itemPurchaseRequest.totalPrice;
    }
    this.totalBudgetList[i] = total;
  }

  public checkQuantity(): void {
    const totalQty: number[] = new Array();
    const purchaseRequestModelList: PurchaseRequestModel[] = this.formGroup.get(
      'purchaseRequestModelList'
    ).value;
    let isAllValid = true;
    let isAllQtyUsed = true;
    for (let i = 0; i < this.workPlanItemList.length; i++) {
      totalQty.push(0);
      purchaseRequestModelList.forEach(purchaseRequestModel => {
        totalQty[i] =
          +totalQty[i] +
          +purchaseRequestModel.purchaseRequestItemList[i].quantity;
      });
      if (this.workPlanItemList[i].quantity < totalQty[i]) {
        this.isQuantityValidList[i] = false;
        isAllValid = false;
      } else {
        if (this.workPlanItemList[i].quantity !== totalQty[i]) {
          isAllQtyUsed = false;
        }
        this.isQuantityValidList[i] = true;
      }
    }
    this.isAllQuantityValid = isAllValid;
    this.isAllQtyUsed = isAllQtyUsed;
    this.totalQtyUsed = totalQty;
  }

  public onTabChange(event): void {
    this.router.navigate([
      '/pages/work-plan-realize/' + this.routeList[event.index]
    ]);
  }

  public buildPurchaseRequest(): void {
    const purchaseRequestModelList = this.formGroup.controls
      .purchaseRequestModelList as FormArray;
    purchaseRequestModelList.push(
      this.formBuilder.group({
        purchaseRequest: this.formBuilder.group({
          id: [null],
          number: [null, Validators.compose([Validators.required])],
          name: [null, Validators.compose([Validators.required])],
          description: [null, Validators.compose([Validators.required])],
          createdDate: [null, Validators.compose([Validators.required])],
          workPlanRealize: [null],
          file: [null]
        }),
        purchaseRequestItemList: this.formBuilder.array([])
      })
    );
  }

  public buildPurchaseRequestItem(
    workPlanItemList: WorkPlanItem[],
    i: number,
    isNoData: boolean
  ): void {
    const purchaseRequestModelList = this.formGroup.controls.purchaseRequestModelList.get(
      '' + i
    ) as FormGroup;
    const purchaseRequestItemList = purchaseRequestModelList.controls
      .purchaseRequestItemList as FormArray;
    workPlanItemList.forEach((workPlanItem, index) => {
      let qty = 0;
      let totalPrice = 0;
      if (this.totalQtyUsed.length > 0) {
        qty = +workPlanItem.quantity - +this.totalQtyUsed[index];
      } else if (isNoData) {
        qty = +workPlanItem.quantity;
      }
      totalPrice = +qty * +workPlanItem.unitPrice;
      purchaseRequestItemList.push(
        this.formBuilder.group({
          id: [null],
          unitPrice: workPlanItem.unitPrice,
          unitPriceRealize: [null],
          totalPriceRealize: [null],
          quantity: qty,
          totalPrice,
          description: [null],
          workPlanItem,
          purchaseRequest: [{}]
        })
      );
    });
  }

  public addPurchaseRequest(isNoData: boolean): void {
    const purchaseRequestModelListForm = this.formGroup.controls
      .purchaseRequestModelList.value;
    const length = purchaseRequestModelListForm.length;
    this.isPRNumberValidList.push(true);
    this.buildPurchaseRequest();
    this.buildPurchaseRequestItem(
      this.workPlanItemList,
      purchaseRequestModelListForm.length,
      isNoData
    );
    this.totalBudgetList.push(0);
    this.fileUploaderList.push(
      new FileUploader(
        '/work-plan-purchase-request/',
        null,
        this.global.appConstant.fileType.DOC_PURCHASE_REQUEST,
        false,
        5
      )
    );
    this.isAllUploaded = false;
    this.checkTotalBudget(length);
  }

  public doAddPurchaseRequest(): void {
    this.addPurchaseRequest(false);
    this.checkQuantity();
  }

  public doDeletePurchaseRequest(i: number): void {
    const purchaseRequestModelList = this.formGroup.controls
      .purchaseRequestModelList as FormArray;
    if (purchaseRequestModelList.length > 1) {
      purchaseRequestModelList.removeAt(i);
      this.fileUploaderList.splice(i, 1);
      this.totalBudgetList.splice(i, 1);
    } else {
      this.global.alertService.showError(
        this.translateService.instant(
          'work-plan-purchase-request.alert.purchaseRequestMinimum'
        )
      );
    }
    this.checkQuantity();
    this.checkPRNumber(i - 1);
    this.checkUploadedStatus();
  }

  public onChangeFileUpload(): void {
    this.checkUploadedStatus();
  }

  public checkUploadedStatus(): void {
    let isAllUploaded = true;
    this.fileUploaderList.forEach(fileuploader => {
      if (!fileuploader.isAllUploaded) {
        isAllUploaded = fileuploader.isAllUploaded;
      }
    });
    this.isAllUploaded = isAllUploaded;
  }

  public patchFormGroup(
    purchaseRequestModel: PurchaseRequestModel,
    index: number
  ): void {
    const purchaseRequestModelForm = this.formGroup.controls.purchaseRequestModelList.get(
      '' + index
    ) as FormGroup;
    const purchaseRequestItemListForm = purchaseRequestModelForm.controls
      .purchaseRequestItemList as FormArray;
    purchaseRequestModelForm.get('purchaseRequest').patchValue({
      id: purchaseRequestModel.purchaseRequest.id,
      number: purchaseRequestModel.purchaseRequest.number,
      name: purchaseRequestModel.purchaseRequest.name,
      description: purchaseRequestModel.purchaseRequest.description,
      createdDate: new Date(purchaseRequestModel.purchaseRequest.createdDate),
      workPlanRealize: purchaseRequestModel.purchaseRequest.workPlanRealize,
      file: purchaseRequestModel.fileList
    });
    this.fileUploaderList[index].setFileList(purchaseRequestModel.fileList);
    this.workPlanItemList.forEach((workPlanItem, index2) => {
      purchaseRequestModel.purchaseRequestItemList.forEach(
        purchaseRequestItem => {
          if (workPlanItem.id === purchaseRequestItem.workPlanItem.id) {
            purchaseRequestItemListForm.get('' + index2).patchValue({
              id:
                purchaseRequestItem.quantity === 0
                  ? null
                  : purchaseRequestItem.id,
              unitPrice: purchaseRequestItem.unitPrice,
              unitPriceRealize: purchaseRequestItem.unitPriceRealize,
              totalPriceRealize: purchaseRequestItem.totalPriceRealize,
              quantity: purchaseRequestItem.quantity,
              totalPrice: purchaseRequestItem.totalPrice,
              description: purchaseRequestItem.description,
              purchaseRequest: purchaseRequestItem.purchaseRequest
            });
          }
        }
      );
    });
  }

  public doSave(): void {
    this.validate();
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.workPlanPurchaseRequestDTO = this.global.copyFormAttributeToModel(
            this.workPlanPurchaseRequestDTO,
            this.formGroup
          );
          this.workPlanPurchaseRequestDTO.purchaseRequestModelList.forEach(
            (purchaseRequestModel, i) => {
              purchaseRequestModel.fileObjectList = this.fileUploaderList[
                i
              ].fileObjectList;
              purchaseRequestModel.purchaseRequestItemList.forEach(
                purchaseRequestItem => {
                  purchaseRequestItem.unitPrice = +purchaseRequestItem
                    .workPlanItem.unitPrice;
                  purchaseRequestItem.totalPrice = +purchaseRequestItem
                    .workPlanItem.totalPrice;
                }
              );
            }
          );
          this.url = '/work-plan-purchase-request/insert-update';
          this.httpClientService
            .post<Response<WorkPlanPurchaseRequestDTO>>(
              this.url,
              this.workPlanPurchaseRequestDTO
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                response.body.purchaseRequestModelList.forEach(
                  (purchaseRequestModel, index) => {
                    this.patchFormGroup(purchaseRequestModel, index);
                  }
                );
                this.global.routerParams.set(
                  'workPlanRealizeId',
                  response.body.purchaseRequestModelList[0].purchaseRequest
                    .workPlanRealize.id
                );
                this.isHavePR = true;
                this.global.alertService.showSuccessSaving();
                this.setStateReady();
              } else {
                this.setStateReady();
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doNext(): void {
    const data = { index: 2 };
    this.onTabChange(data);
  }

  public doPrevious(): void {
    const data = { index: 0 };
    this.onTabChange(data);
  }

  public checkPRNumber(index: number): void {
    let next = true;
    const purchaseRequestModelList: PurchaseRequestModel[] = this.formGroup
      .controls.purchaseRequestModelList.value;
    const PRNumber = purchaseRequestModelList[index].purchaseRequest.number;
    const PR = purchaseRequestModelList[index].purchaseRequest;
    purchaseRequestModelList.forEach((purchaseRequestModel, index2) => {
      this.isPRNumberValidList[index2] = true;
      purchaseRequestModelList.forEach((purchaseRequestModel2, index3) => {
        if (
          index2 !== index3 &&
          purchaseRequestModel.purchaseRequest.number ===
            purchaseRequestModel2.purchaseRequest.number
        ) {
          this.isPRNumberValidList[index2] = false;
          this.samePRNumberList[index2] =
            purchaseRequestModel.purchaseRequest.number;
          next = false;
        }
      });
    });
    if (next && PRNumber !== '') {
      this.httpClientService
        .post<Response<PurchaseRequest>>(
          '/work-plan-purchase-request/check-pr',
          PR
        )
        .subscribe(response => {
          if (response.status !== ResponseStatusModel.OK) {
            this.isPRNumberValidList[index] = false;
            this.isAllPRNumberValid = false;
            this.samePRNumberList[index] = response.body.number;
          }
        });
    }
    this.isAllPRNumberValid = next;
  }

  public get formArrayPurchaseRequestModelList(): FormArray {
    return this.formGroup.get('purchaseRequestModelList') as FormArray;
  }
}
