import { NgModule } from '@angular/core';
import { AppWidgetAdminOprWorklistModule } from './app-widget-admin-opr-worklist/app-widget-admin-opr-worklist.module';
import { AppWidgetAnnouncementModule } from './app-widget-announcement/app-widget-announcement.module';
import { AppWidgetBudgetSpendModule } from './app-widget-budget-spend/app-widget-budget-spend.module';
import { AppWidgetClaimVerificationModule } from './app-widget-claim-verification/app-widget-claim-verification.module';
import { AppWidgetContractInformationModule } from './app-widget-contract-information/app-widget-contract-information.module';
import { AppWidgetContractPerCategoryModule } from './app-widget-contract-per-category/app-widget-contract-per-category.module';
import { AppWidgetContractPerStatusModule } from './app-widget-contract-per-status/app-widget-contract-per-status.module';
import { AppWidgetContractReportStatisticalModule } from './app-widget-contract-report-statistical/app-widget-contract-report-statistical.module';
import { AppWidgetContractRequestByTypeModule } from './app-widget-contract-request-by-type/app-widget-contract-request-by-type.module';
import { AppWidgetContractStatusModule } from './app-widget-contract-status/app-widget-contract-status.module';
import { AppWidgetContractModule } from './app-widget-contract/app-widget-contract.module';
import { AppWidgetContractedVendorModule } from './app-widget-contracted-vendor/app-widget-contracted-vendor.module';
import { AppWidgetLoginInformationVendorModule } from './app-widget-login-information-vendor/app-widget-login-information-vendor.module';
import { AppWidgetLoginInformationModule } from './app-widget-login-information/app-widget-login-information.module';
import { AppWidgetNumberOfOrderModule } from './app-widget-number-of-roder/app-widget-number-of-order.module';
import { AppWidgetOrderStatusModule } from './app-widget-order-status/app-widget-order-status.module';
import { AppWidgetPaymentMonitoringModule } from './app-widget-payment-monitoring/app-widget-payment-monitoring.module';
import { AppWidgetPerformanceOverallModule } from './app-widget-performance-overall/app-widget-performance-overall.module';
import { AppWidgetPrStatusModule } from './app-widget-pr-status/app-widget-pr-status.module';
import { AppWidgetProcurementActivityModule } from './app-widget-procurement-activity/app-widget-procurement-activity.module';
import { AppWidgetProcurementMonitoringOverdueModule } from './app-widget-procurement-monitoring-overdue/app-widget-procurement-monitoring-overdue.module';
import { AppWidgetProgressCompleteProfileModule } from './app-widget-progress-complete-profile/app-widget-progress-complete-profile.module';
import { AppWidgetRfpStatusModule } from './app-widget-rfp-status/app-widget-rfp-status.module';
import { AppWidgetRfqStatusModule } from './app-widget-rfq-status/app-widget-rfq-status.module';
import { AppWidgetTaskActivityModule } from './app-widget-task-activity/app-widget-task-activity.module';
import { AppWidgetTopVendorPerformance } from './app-widget-top-vendor-performance/app-widget-top-vendor-performance.module';
import { AppWidgetTotalSlaModule } from './app-widget-total-sla/app-widget-total-sla.module';
import { AppWidgetUnprocessedPrModule } from './app-widget-unprocessed-pr/app-widget-unprocessed-pr.module';
import { AppWidgetVendorContractModule } from './app-widget-vendor-contract/app-widget-vendor-contract.module';
import { AppWidgetVendorInformationModule } from './app-widget-vendor-information/app-widget-vendor-information.module';
import { AppWidgetVendorStatusInformationModule } from './app-widget-vendor-status-information/app-widget-vendor-status-information.module';
import { AppWidgetWelcomeModule } from './app-widget-welcome/app-widget-welcome.module';
import { AppWidgetWorkPlanSpendModule } from './app-widget-work-plan-spend/app-widget-work-plan-spend.module';
import { AppWidgetWrapperModule } from './app-widget-wrapper/app-widget-wrapper.module';
import { WidgetService } from './widgets.service';
@NgModule({
  imports: [
    // dashboard widget
    AppWidgetAdminOprWorklistModule,
    AppWidgetContractInformationModule,
    AppWidgetLoginInformationModule,
    AppWidgetLoginInformationVendorModule,
    AppWidgetProgressCompleteProfileModule,
    AppWidgetTaskActivityModule,
    AppWidgetTopVendorPerformance,
    AppWidgetVendorContractModule,
    AppWidgetVendorInformationModule,
    AppWidgetVendorStatusInformationModule,
    AppWidgetWelcomeModule,
    AppWidgetBudgetSpendModule,
    AppWidgetWorkPlanSpendModule,
    AppWidgetContractModule,
    AppWidgetContractStatusModule,
    AppWidgetPaymentMonitoringModule,
    AppWidgetContractRequestByTypeModule,
    AppWidgetContractReportStatisticalModule,
    AppWidgetContractPerStatusModule,
    AppWidgetContractPerCategoryModule,
    AppWidgetPrStatusModule,
    AppWidgetRfpStatusModule,
    AppWidgetRfqStatusModule,
    AppWidgetOrderStatusModule,
    AppWidgetNumberOfOrderModule,
    AppWidgetUnprocessedPrModule,
    AppWidgetProcurementActivityModule,
    AppWidgetTotalSlaModule,
    AppWidgetContractedVendorModule,
    AppWidgetProcurementMonitoringOverdueModule,
    AppWidgetPerformanceOverallModule,
    AppWidgetClaimVerificationModule,
    // portal widget
    AppWidgetAnnouncementModule,
    // others widget
    AppWidgetWrapperModule
  ],
  exports: [
    // dashboard widget
    AppWidgetAdminOprWorklistModule,
    AppWidgetContractInformationModule,
    AppWidgetLoginInformationModule,
    AppWidgetLoginInformationVendorModule,
    AppWidgetProgressCompleteProfileModule,
    AppWidgetTaskActivityModule,
    AppWidgetTopVendorPerformance,
    AppWidgetVendorContractModule,
    AppWidgetVendorInformationModule,
    AppWidgetVendorStatusInformationModule,
    AppWidgetWelcomeModule,
    AppWidgetBudgetSpendModule,
    AppWidgetWorkPlanSpendModule,
    AppWidgetContractModule,
    AppWidgetContractStatusModule,
    AppWidgetPaymentMonitoringModule,
    AppWidgetPrStatusModule,
    AppWidgetRfpStatusModule,
    AppWidgetRfqStatusModule,
    AppWidgetOrderStatusModule,
    AppWidgetNumberOfOrderModule,
    AppWidgetUnprocessedPrModule,
    AppWidgetProcurementActivityModule,
    AppWidgetTotalSlaModule,
    AppWidgetContractedVendorModule,
    AppWidgetProcurementMonitoringOverdueModule,
    AppWidgetPerformanceOverallModule,
    AppWidgetClaimVerificationModule,
    // portal widget
    AppWidgetAnnouncementModule,
    // others widget
    AppWidgetWrapperModule,

  ],
  providers: [WidgetService]
})
export class SharedWidgetsModule {}
