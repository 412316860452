import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ProcurementFines } from 'src/app/core/bean/procurement-fines';
import { ProcurementGuarantee } from 'src/app/core/bean/procurement-guarantee';
import { ProcurementScopeWork } from 'src/app/core/bean/procurement-scope-work';
import { ProcurementSow } from 'src/app/core/bean/procurement-sow';
import { AppPopupProcurementFinesService } from 'src/app/core/components/app-popup/app-popup-procurement-fines/app-popup-procurement-fines.service';
import { AppPopupProcurementGuaranteeService } from 'src/app/core/components/app-popup/app-popup-procurement-guarantee/app-popup-procurement-guarantee.service';
import { AppPopupProcurementScopeWorkService } from 'src/app/core/components/app-popup/app-popup-procurement-scope-work/app-popup-procurement-scope-work.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { ProcurementScopeWorkRequest } from './procurement-scope-work.request';
import { ProcurementScopeWorkResponse } from './procurement-scope-work.response';
@Component({
  templateUrl: './procurement-scope-work-edit.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class ProcurementScopeWorkEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  @ViewChild('selectorStageOfWork') tableStageOfWork: AppTableComponent;
  @ViewChild('selectorGuarantee') tableGuarantee: AppTableComponent;
  @ViewChild('selectorFines') tableFines: AppTableComponent;
  public procurementScopeWorkId: number;
  public procurementVendorId: number;
  public procurementScopeWorkResponse: ProcurementScopeWorkResponse =
    new ProcurementScopeWorkResponse();
  public procurementScopeWorkRequest: ProcurementScopeWorkRequest =
    new ProcurementScopeWorkRequest();
  public procurementScopeWork: ProcurementScopeWork =
    new ProcurementScopeWork();
  public tableResponseStageOfWork: TableResponseModel<ProcurementSow>;
  public tableResponseGuarantee: TableResponseModel<ProcurementGuarantee>;
  public tableResponseFines: TableResponseModel<ProcurementFines>;
  public availableAmountSow: number;
  public totalAmountSow: number;
  public isSaveDraft: boolean;
  public isSave: boolean;
  public CURRENCY_DIGITS_INFO: string;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupProcurementScopeWorkService: AppPopupProcurementScopeWorkService,
    public appPopupProcurementFinesService: AppPopupProcurementFinesService,
    public appPopupProcurementGuaranteeService: AppPopupProcurementGuaranteeService,
    public currency: CurrencyPipe
  ) {
    super('procurement-scope-work', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public setDataFromRouterParams(): void {
    const todoTemp = localStorage.getItem('todo');
    if (todoTemp) {
      this.todo = todoTemp;
    } else {
      this.todo = this.global.routerParams.get('todo');
    }
    this.procurementScopeWorkId = this.global.routerParams.get(
      'procurementScopeWorkId'
    );
    this.procurementVendorId = this.global.routerParams.get(
      'procurementVendorId'
    );
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      procurementSowList: this.formBuilder.array([])
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ProcurementScopeWorkResponse>(
        '/procurement-scope-work/edit',
        new RouteRequestModel(this.todo, this.procurementScopeWorkId)
      )
      .subscribe(
        (procurementScopeWorkResponse: ProcurementScopeWorkResponse) => {
          let data = localStorage.getItem('temp') as Object;
          if (data) {
            data = JSON.parse(data.toString());
            this.procurementScopeWorkResponse =
              data as ProcurementScopeWorkResponse;
            localStorage.removeItem('temp');
            localStorage.removeItem('todo');
          } else {
            this.procurementScopeWorkResponse = procurementScopeWorkResponse;
          }
          this.procurementScopeWork =
            this.procurementScopeWorkResponse.procurementScopeWork;
          this.doSetprocurementSowList();
          this.setStateReady();
          this.buildTableResponse();
        }
      );
  }

  public get procurementSowList(): FormArray {
    return this.formGroup.get('procurementSowList') as FormArray;
  }

  public doSetprocurementSowList(): void {
    this.procurementScopeWorkResponse.procurementSowList.forEach(
      procurementSow => {
        const procurementSowPaymentTermList = [];
        procurementSowPaymentTermList.push(
          procurementSow.procurementSowPaymentTermList
        );
        let amount = 0;
        procurementSow.procurementSowPaymentTermList.forEach(
          (procurementSowPaymentTerm: any) => {
            procurementSowPaymentTerm.procurementSowPaymentTermItemProductList =
              [];
            procurementSowPaymentTerm.procurementSowPaymentTermItemServiceList =
              [];
            amount = amount + +procurementSowPaymentTerm.amount;
            procurementSowPaymentTerm.procurementSowPaymentTermItemList.forEach(
              procurementSowItem => {
                if (
                  procurementSowItem.procurementResult.procurementItem.item
                    .itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                ) {
                  procurementSowPaymentTerm.procurementSowPaymentTermItemProductList.push(
                    {
                      id: procurementSowItem.id,
                      procurementResult: procurementSowItem.procurementResult,
                      quantity: procurementSowItem.quantity,
                      remainingQuantity: procurementSowItem.remainingQuantity
                    }
                  );
                }
                if (
                  procurementSowItem.procurementResult.procurementItem.item
                    .itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
                ) {
                  procurementSowPaymentTerm.procurementSowPaymentTermItemServiceList.push(
                    {
                      id: procurementSowItem.id,
                      procurementResult: procurementSowItem.procurementResult,
                      quantity: procurementSowItem.quantity,
                      remainingQuantity: procurementSowItem.remainingQuantity
                    }
                  );
                }
              }
            );
          }
        );
        const formGroup = this.formBuilder.group({
          id: procurementSow.id,
          procurementScopeWork: procurementSow.procurementScopeWork,
          stageOfWork: procurementSow.stageOfWork,
          procurementSowPaymentTermList,
          startDate: procurementSow.startDate,
          endDate: procurementSow.endDate,
          totalTermin: procurementSow.procurementSowPaymentTermList.length,
          amount,
          periodType: procurementSow.periodType,
          duration: procurementSow.duration,
          durationType: procurementSow.durationType,
          isMonthlyPayment: procurementSow.isMonthlyPayment
        });
        this.procurementSowList.push(formGroup);
      }
    );
  }

  public buildTableResponse(): void {
    this.tableResponseStageOfWork = new TableResponseModel(this.moduleCode, [
      {
        field: 'stageOfWork.name',
        header: 'table.column.stageOfWork'
      },
      {
        field: 'totalTermin',
        header: 'table.column.totalTermin',
        customClass: 'text-center'
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      }
      // hide for bjb
      // {
      //   field: 'startDate',
      //   header: 'table.column.startDate',
      //   format: FieldFormatEnum.ShortDate
      // },
      // {
      //   field: 'endDate',
      //   header: 'table.column.endDate',
      //   format: FieldFormatEnum.ShortDate
      // }
    ]);
    this.tableResponseStageOfWork.setRecords(this.procurementSowList.value);
    this.tableResponseStageOfWork.setTotalRecords(
      this.procurementSowList.value.length
    );
    this.tableResponseStageOfWork.reloadClient();

    this.tableResponseGuarantee = new TableResponseModel(this.moduleCode, [
      {
        field: 'guaranteeBondType.name',
        header: 'table.column.type',
        customClass: 'text-center'
      },
      {
        field: 'name',
        header: 'table.column.name'
      },
      {
        field: 'percentageValue',
        header: 'table.column.percentageValue',
        customClass: 'text-right',
        fn: this.setDecimal.bind(this)
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'submissionDate',
        header: 'table.column.submissionDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      }
    ]);
    this.tableResponseGuarantee.page.records =
      this.procurementScopeWorkResponse.procurementGuaranteeList;
    this.tableResponseGuarantee.page.totalRecords =
      this.procurementScopeWorkResponse.procurementGuaranteeList.length;

    this.tableResponseFines = new TableResponseModel(this.moduleCode, [
      {
        field: 'fines.name',
        header: 'table.column.type',
        customClass: 'text-center'
      },
      {
        field: 'percentageValue',
        header: 'table.column.percentageValue',
        customClass: 'text-right',
        fn: this.setDecimal.bind(this)
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      }
    ]);
    this.tableResponseFines.page.records =
      this.procurementScopeWorkResponse.procurementFinesList;
    this.tableResponseFines.page.totalRecords =
      this.procurementScopeWorkResponse.procurementFinesList.length;
  }

  public doAddStageOfWork(): void {
    this.doSetTotalAmountSow();
    const todo = 'add';
    const header = this.translateService.instant(
      'procurement-scope-work.popup.title.addStageOfWork'
    );
    const stageOfWorkList =
      this.procurementScopeWorkResponse.stageOfWorkList.filter(stageOfWork =>
        this.procurementSowList.value.every(
          procurementSow => procurementSow.stageOfWork.id !== stageOfWork.id
        )
      );
    const paymentTermList = this.procurementScopeWorkResponse.paymentTermList;
    const billingTermList = this.procurementScopeWorkResponse.billingTermList;
    const procurementVendor =
      this.procurementScopeWorkResponse.procurementVendor;
    const procurementResultProductList =
      this.procurementScopeWorkResponse.procurementResultProductList;
    const procurementResultServiceList =
      this.procurementScopeWorkResponse.procurementResultServiceList;
    let procurementPaymentItemList: any;
    if (this.procurementSowList.length > 0) {
      procurementPaymentItemList =
        this.procurementSowList.value[this.procurementSowList.length - 1]
          .procurementSowPaymentTermList[0];
    }
    const availableAmountSow = this.availableAmountSow;
    const procurementSowList = this.procurementSowList;
    if (availableAmountSow === 0) {
      this.global.alertService.showError(
        this.translateService.instant(
          'procurement-scope-work.alert.msg.maxValue'
        )
      );
    } else {
      this.appPopupProcurementScopeWorkService
        .open(
          todo,
          header,
          null,
          stageOfWorkList,
          paymentTermList,
          billingTermList,
          procurementResultServiceList,
          procurementResultProductList,
          null,
          procurementVendor,
          availableAmountSow,
          procurementPaymentItemList,
          procurementSowList,
          null,
          null,
          null,
          true
        )
        .subscribe(procurementSow => {
          let amount = 0;
          const procurementSowPaymentTermList = [];
          procurementSowPaymentTermList.push(
            procurementSow.procurementSowPaymentTermList
          );
          procurementSow.procurementSowPaymentTermList.forEach(
            procurementSowPaymentTerm => {
              amount = amount + +procurementSowPaymentTerm.amount;
            }
          );
          const formGroup = this.formBuilder.group({
            id: procurementSow.id,
            contract: procurementSow.contract,
            stageOfWork: procurementSow.stageOfWork,
            procurementSowPaymentTermList,
            startDate: procurementSow.startDate,
            endDate: procurementSow.endDate,
            totalTermin: procurementSow.procurementSowPaymentTermList.length,
            amount,
            periodType: procurementSow.periodType,
            duration: procurementSow.duration,
            durationType: procurementSow.durationType,
            isMonthlyPayment: procurementSow.isMonthlyPayment
          });
          this.procurementSowList.push(formGroup);
          this.procurementSowList.controls.sort(
            (a, b) => a.value.stageOfWork.id - b.value.stageOfWork.id
          );
          this.procurementSowList.value.sort(
            (a, b) => a.stageOfWork.id - b.stageOfWork.id
          );
          this.procurementScopeWorkResponse.procurementSowList =
            this.procurementSowList.value;
          this.tableResponseStageOfWork.page.records =
            this.procurementSowList.value;
          this.tableResponseStageOfWork.page.totalRecords =
            this.procurementSowList.length;
          this.tableResponseStageOfWork.sortRecords(
            this.procurementSowList.value
          );
          this.tableResponseStageOfWork.sortOrder = 'DESC';
          this.tableResponseStageOfWork.setSortField('stageOfWork.name');
          this.tableResponseStageOfWork.reload();
          this.doSetTotalAmountSow();
        });
    }
  }
  public doEditStageOfWork(procurementSowEdit: any): void {
    this.doSetTotalAmountSow();
    const todo = 'edit';
    const header = this.translateService.instant(
      'procurement-scope-work.popup.title.editStageOfWork'
    );
    const stageOfWorkList =
      this.procurementScopeWorkResponse.stageOfWorkList.filter(stageOfWork =>
        this.procurementSowList.value.every(
          procurementSow =>
            procurementSow.stageOfWork.id !== stageOfWork.id ||
            procurementSow.stageOfWork.id === procurementSowEdit.stageOfWork.id
        )
      );
    const paymentTermList = this.procurementScopeWorkResponse.paymentTermList;
    const billingTermList = this.procurementScopeWorkResponse.billingTermList;
    const procurementVendor =
      this.procurementScopeWorkResponse.procurementVendor;
    const procurementResultProductList =
      this.procurementScopeWorkResponse.procurementResultProductList;
    const procurementResultServiceList =
      this.procurementScopeWorkResponse.procurementResultServiceList;
    const availableAmountSow =
      this.availableAmountSow + +procurementSowEdit.amount;
    let procurementSowPaymentList: any;
    if (this.procurementSowList.length > 0) {
      procurementSowPaymentList =
        this.procurementSowList.value[this.procurementSowList.length - 1]
          .procurementSowPaymentTermList[0];
    }
    let procurementPaymentItemList: any;
    if (this.procurementSowList.length > 0) {
      procurementPaymentItemList =
        this.procurementSowList.value[this.procurementSowList.length - 1]
          .procurementSowPaymentTermList[0];
    }
    const procurementSowList = this.procurementSowList;
    this.appPopupProcurementScopeWorkService
      .open(
        todo,
        header,
        procurementSowEdit,
        stageOfWorkList,
        paymentTermList,
        billingTermList,
        procurementResultServiceList,
        procurementResultProductList,
        null,
        procurementVendor,
        availableAmountSow,
        procurementPaymentItemList,
        procurementSowList,
        procurementSowPaymentList,
        null,
        null,
        true
      )
      .subscribe(procurementSowTemp => {
        this.procurementSowList.controls.sort(
          (a, b) => a.value.stageOfWork.id - b.value.stageOfWork.id
        );
        this.procurementSowList.value.forEach((procurementSow, index) => {
          if (
            JSON.stringify(procurementSow) ===
            JSON.stringify(procurementSowEdit)
          ) {
            this.procurementSowList.value[index] = procurementSowTemp;
            this.procurementSowList.controls[index].patchValue(
              procurementSowTemp
            );
          }
        });
        this.procurementScopeWorkResponse.procurementSowList =
          this.procurementSowList.value;
        this.tableResponseStageOfWork.page.records =
          this.procurementSowList.value;
        this.tableResponseStageOfWork.page.totalRecords =
          this.procurementSowList.value.length;
        this.tableResponseStageOfWork.sortRecords(
          this.procurementSowList.value
        );
        this.tableResponseStageOfWork.sortOrder = 'DESC';
        this.tableResponseStageOfWork.setSortField('stageOfWork.name');
        this.tableResponseStageOfWork.reload();
        this.doSetTotalAmountSow();
      });
  }
  public doDeleteStageOfWork(procurementSowDelete: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.procurementSowList.controls.sort(
            (a, b) => a.value.stageOfWork.id - b.value.stageOfWork.id
          );
          this.procurementSowList.value.sort(
            (a, b) => a.stageOfWork.id - b.stageOfWork.id
          );
          this.procurementSowList.value.forEach((procurementSow, index) => {
            if (
              JSON.stringify(procurementSow) ===
              JSON.stringify(procurementSowDelete)
            ) {
              const procurementSowPaymentTermList =
                this.procurementSowList.controls[index].get(
                  'procurementSowPaymentTermList'
                ) as FormArray;
              this.procurementSowList.value.splice(index, 1);
              this.procurementSowList.removeAt(index);
              const procurementSowPaymentTermItemServiceList =
                procurementSowPaymentTermList.value[0]
                  .procurementSowPaymentTermItemServiceList;
              const procurementSowPaymentTermItemProductList =
                procurementSowPaymentTermList.value[0]
                  .procurementSowPaymentTermItemProductList;
              if (this.procurementSowList.length > 0) {
                if (
                  procurementSowPaymentTermItemServiceList &&
                  procurementSowPaymentTermItemProductList
                ) {
                  procurementSowPaymentTermList.value.forEach(paymentTerm => {
                    paymentTerm.procurementSowPaymentTermItemServiceList.forEach(
                      itemService => {
                        this.procurementSowList.controls.forEach(
                          procurementSow => {
                            procurementSow.value.procurementSowPaymentTermList.forEach(
                              payment => {
                                payment.procurementSowPaymentTermItemServiceList.forEach(
                                  (sowProduct, j) => {
                                    if (
                                      itemService.procurementResult.id ===
                                      sowProduct.procurementResult.id
                                    ) {
                                      payment.procurementSowPaymentTermItemServiceList[
                                        j
                                      ].remainingQuantity =
                                        +sowProduct.remainingQuantity +
                                        +itemService.quantity;
                                    }
                                  }
                                );
                              }
                            );
                          }
                        );
                      }
                    );
                    paymentTerm.procurementSowPaymentTermItemProductList.forEach(
                      itemProduct => {
                        this.procurementSowList.controls.forEach(
                          procurementSow => {
                            procurementSow.value.procurementSowPaymentTermList.forEach(
                              payment => {
                                payment.procurementSowPaymentTermItemProductList.forEach(
                                  (sowProduct, j) => {
                                    if (
                                      itemProduct.procurementResult.id ===
                                      sowProduct.procurementResult.id
                                    ) {
                                      payment.procurementSowPaymentTermItemProductList[
                                        j
                                      ].remainingQuantity =
                                        +sowProduct.remainingQuantity +
                                        +itemProduct.quantity;
                                    }
                                  }
                                );
                              }
                            );
                          }
                        );
                      }
                    );
                  });

                  this.procurementSowList.controls.forEach(procurementSow => {
                    procurementSow.value.procurementSowPaymentTermList.forEach(
                      payment => {
                        payment.procurementSowPaymentTermItemList = [];
                        payment.procurementSowPaymentTermItemProductList.forEach(
                          itemProduct => {
                            payment.procurementSowPaymentTermItemList.push(
                              itemProduct
                            );
                          }
                        );
                        payment.procurementSowPaymentTermItemServiceList.forEach(
                          itemService => {
                            payment.procurementSowPaymentTermItemList.push(
                              itemService
                            );
                          }
                        );
                      }
                    );
                  });
                }
              }
            }
          });
          this.procurementScopeWorkResponse.procurementSowList =
            this.procurementSowList.value;
          this.tableResponseStageOfWork.page.records =
            this.procurementSowList.value;
          this.tableResponseStageOfWork.page.totalRecords =
            this.procurementSowList.value.length;
          this.tableResponseStageOfWork.page.records =
            this.procurementSowList.value;

          this.tableResponseStageOfWork.sortOrder = 'DESC';
          this.tableResponseStageOfWork.setSortField('stageOfWork.name');
          this.tableResponseStageOfWork.reload();
        }
      });
  }

  public doDetailStageOfWork(procurementSowEdit: any): void {
    this.doSetTotalAmountSow();
    const todo = 'view';
    const header = this.translateService.instant(
      'procurement-scope-work.popup.title.viewStageOfWork'
    );
    const stageOfWorkList =
      this.procurementScopeWorkResponse.stageOfWorkList.filter(stageOfWork =>
        this.procurementSowList.value.every(
          procurementSow =>
            procurementSow.stageOfWork.id !== stageOfWork.id ||
            procurementSow.stageOfWork.id === procurementSowEdit.stageOfWork.id
        )
      );
    const paymentTermList = this.procurementScopeWorkResponse.paymentTermList;
    const billingTermList = this.procurementScopeWorkResponse.billingTermList;
    const procurementVendor =
      this.procurementScopeWorkResponse.procurementVendor;
    const procurementResultProductList =
      this.procurementScopeWorkResponse.procurementResultProductList;
    const procurementResultServiceList =
      this.procurementScopeWorkResponse.procurementResultServiceList;
    const availableAmountSow =
      this.availableAmountSow + +procurementSowEdit.amount;
    let procurementSowPaymentList: any;
    if (this.procurementSowList.length > 0) {
      procurementSowPaymentList =
        this.procurementSowList.value[this.procurementSowList.length - 1]
          .procurementSowPaymentTermList[0];
    }
    const procurementSowList = this.procurementSowList;

    this.appPopupProcurementScopeWorkService.open(
      todo,
      header,
      procurementSowEdit,
      stageOfWorkList,
      paymentTermList,
      billingTermList,
      procurementResultServiceList,
      procurementResultProductList,
      null,
      procurementVendor,
      availableAmountSow,
      null,
      procurementSowList,
      procurementSowPaymentList,
      null,
      null,
      true
    );
  }

  public doAddGuarantee(): void {
    const todo = 'add';
    const header = this.translateService.instant(
      'procurement-scope-work.popup.title.addGuarantee'
    );
    const guaranteeList =
      this.procurementScopeWorkResponse.guaranteeBondTypeList.filter(
        guarantee =>
          this.procurementScopeWorkResponse.procurementGuaranteeList.every(
            procurementGuarantee =>
              procurementGuarantee.guaranteeBondType.id !== guarantee.id
          )
      );
    const guaranteeBondTypeList = guaranteeList;
    const procurementVendor =
      this.procurementScopeWorkResponse.procurementVendor;

    this.appPopupProcurementGuaranteeService
      .open(todo, header, null, guaranteeBondTypeList, null, procurementVendor)
      .subscribe(procurementGuarantee => {
        this.procurementScopeWorkResponse.procurementGuaranteeList.push(
          procurementGuarantee
        );

        this.tableResponseGuarantee.page.records =
          this.procurementScopeWorkResponse.procurementGuaranteeList;
        this.tableResponseGuarantee.page.totalRecords =
          this.procurementScopeWorkResponse.procurementGuaranteeList.length;
        this.tableGuarantee.reloadClient();
      });
  }
  public doEditGuarantee(procurementGuaranteeEdit: ProcurementGuarantee): void {
    const todo = 'edit';
    const header = this.translateService.instant(
      'procurement-scope-work.popup.title.editGuarantee'
    );
    const guaranteeList =
      this.procurementScopeWorkResponse.guaranteeBondTypeList.filter(
        guarantee =>
          this.procurementScopeWorkResponse.procurementGuaranteeList.every(
            procurementGuarantee =>
              procurementGuarantee.guaranteeBondType.id !== guarantee.id ||
              procurementGuarantee.guaranteeBondType.id ===
                procurementGuaranteeEdit.guaranteeBondType.id
          )
      );
    const guaranteeBondTypeList = guaranteeList;
    const procurementVendor =
      this.procurementScopeWorkResponse.procurementVendor;

    this.appPopupProcurementGuaranteeService
      .open(
        todo,
        header,
        procurementGuaranteeEdit,
        guaranteeBondTypeList,
        null,
        procurementVendor
      )
      .subscribe(procurementGuaranteeTemp => {
        this.procurementScopeWorkResponse.procurementGuaranteeList.forEach(
          (procurementGuarantee, index) => {
            if (procurementGuarantee === procurementGuaranteeEdit) {
              this.procurementScopeWorkResponse.procurementGuaranteeList[
                index
              ] = procurementGuaranteeTemp;
            }
          }
        );

        this.tableResponseGuarantee.page.records =
          this.procurementScopeWorkResponse.procurementGuaranteeList;
        this.tableResponseGuarantee.page.totalRecords =
          this.procurementScopeWorkResponse.procurementGuaranteeList.length;
        this.tableGuarantee.reloadClient();
      });
  }
  public doDeleteGuarantee(
    procurementGuaranteeDelete: ProcurementGuarantee
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.procurementScopeWorkResponse.procurementGuaranteeList.forEach(
            (procurementGuarantee, index) => {
              if (procurementGuarantee === procurementGuaranteeDelete) {
                this.procurementScopeWorkResponse.procurementGuaranteeList.splice(
                  index,
                  1
                );
              }
            }
          );
          this.tableResponseGuarantee.page.records =
            this.procurementScopeWorkResponse.procurementGuaranteeList;
          this.tableResponseGuarantee.page.totalRecords =
            this.procurementScopeWorkResponse.procurementGuaranteeList.length;
          this.tableGuarantee.reloadClient();
        }
      });
  }

  public doAddFines(): void {
    const todo = 'add';
    const header = this.translateService.instant(
      'procurement-scope-work.popup.title.addFines'
    );
    const finesList = this.procurementScopeWorkResponse.finesMasterList.filter(
      fines =>
        this.procurementScopeWorkResponse.procurementFinesList.every(
          procurementFines => procurementFines.fines.id !== fines.id
        )
    );
    const procurementVendor =
      this.procurementScopeWorkResponse.procurementVendor;

    this.appPopupProcurementFinesService
      .open(todo, header, null, finesList, null, procurementVendor)
      .subscribe(procurementFines => {
        this.procurementScopeWorkResponse.procurementFinesList.push(
          procurementFines
        );

        this.tableResponseFines.page.records =
          this.procurementScopeWorkResponse.procurementFinesList;
        this.tableResponseFines.page.totalRecords =
          this.procurementScopeWorkResponse.procurementFinesList.length;
        this.tableFines.reloadClient();
      });
  }
  public doEditFines(procurementFinesEdit: ProcurementFines): void {
    const todo = 'edit';
    const header = this.translateService.instant(
      'procurement-scope-work.popup.title.editFines'
    );
    const finesList = this.procurementScopeWorkResponse.finesMasterList.filter(
      fines =>
        this.procurementScopeWorkResponse.procurementFinesList.every(
          procurementFines =>
            procurementFines.fines.id !== fines.id ||
            procurementFines.fines.id === procurementFinesEdit.fines.id
        )
    );
    const procurementVendor =
      this.procurementScopeWorkResponse.procurementVendor;

    this.appPopupProcurementFinesService
      .open(
        todo,
        header,
        procurementFinesEdit,
        finesList,
        null,
        procurementVendor
      )
      .subscribe(procurementFinesTemp => {
        this.procurementScopeWorkResponse.procurementFinesList.forEach(
          (procurementFines, index) => {
            if (procurementFines === procurementFinesEdit) {
              this.procurementScopeWorkResponse.procurementFinesList[index] =
                procurementFinesTemp;
            }
          }
        );

        this.tableResponseFines.page.records =
          this.procurementScopeWorkResponse.procurementFinesList;
        this.tableResponseFines.page.totalRecords =
          this.procurementScopeWorkResponse.procurementFinesList.length;
        this.tableFines.reloadClient();
      });
  }
  public doDeleteFines(procurementFinesDelete: ProcurementFines): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.procurementScopeWorkResponse.procurementFinesList.forEach(
            (procurementFines, index) => {
              if (procurementFines === procurementFinesDelete) {
                this.procurementScopeWorkResponse.procurementFinesList.splice(
                  index,
                  1
                );
              }
            }
          );
          this.tableResponseFines.page.records =
            this.procurementScopeWorkResponse.procurementFinesList;
          this.tableResponseFines.page.totalRecords =
            this.procurementScopeWorkResponse.procurementFinesList.length;
          this.tableFines.reloadClient();
        }
      });
  }
  public doSetTotalAmountSow(): void {
    const amount = this.procurementScopeWorkResponse.procurementVendor.amount;

    let totalAmountSow = 0;
    this.procurementSowList.value.forEach(procurementSow => {
      totalAmountSow = totalAmountSow + +procurementSow.amount;
    });
    this.totalAmountSow = totalAmountSow;
    this.availableAmountSow = +amount - +this.totalAmountSow;
  }

  public doSetProcSowPaymentTermItem(): void {
    this.procurementSowList.controls.forEach(procurementSow => {
      procurementSow.value.procurementSowPaymentTermList.forEach(payment => {
        payment.procurementSowPaymentTermItemList = [];
        payment.procurementSowPaymentTermItemProductList.forEach(
          itemProduct => {
            payment.procurementSowPaymentTermItemList.push(itemProduct);
          }
        );
        payment.procurementSowPaymentTermItemServiceList.forEach(
          itemService => {
            payment.procurementSowPaymentTermItemList.push(itemService);
          }
        );
      });
    });
  }

  public doSave(): void {
    this.doSetProcSowPaymentTermItem();
    this.procurementScopeWorkRequest.procurementSowList =
      this.formGroup.get('procurementSowList').value;
    this.isSave = true;
    this.procurementScopeWorkRequest.procurementFinesList =
      this.procurementScopeWorkResponse.procurementFinesList;
    this.procurementScopeWorkRequest.procurementGuaranteeList =
      this.procurementScopeWorkResponse.procurementGuaranteeList;
    this.validate();
    if (
      this.formGroup.valid &&
      this.procurementScopeWorkRequest.procurementSowList.length > 0
    ) {
      this.doSetTotalAmountSow();
      if (this.availableAmountSow !== 0) {
        this.global.alertService.showError(
          this.translateService.instant(
            'procurement-scope-work.alert.contractValue'
          )
        );
      } else {
        this.global.modalService
          .saveConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              this.procurementScopeWorkRequest.isSaveDraft = false;
              this.procurementScopeWorkRequest.procurementScopeWork =
                this.procurementScopeWorkResponse.procurementScopeWork;
              this.procurementScopeWorkRequest.procurementVendor =
                this.procurementScopeWorkResponse.procurementVendor;
              this.httpClientService
                .post<Response<ProcurementScopeWork>>(
                  '/procurement-scope-work/update',
                  this.procurementScopeWorkRequest
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.alertService.showSuccessSavingOnNextRoute();
                    this.router.navigate(['/pages/procurement-scope-work/']);
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      }
    }
  }

  public doSaveDraft(): void {
    this.doSetProcSowPaymentTermItem();
    this.procurementScopeWorkRequest.procurementSowList =
      this.formGroup.get('procurementSowList').value;
    this.procurementScopeWorkRequest.procurementFinesList =
      this.procurementScopeWorkResponse.procurementFinesList;
    this.procurementScopeWorkRequest.procurementGuaranteeList =
      this.procurementScopeWorkResponse.procurementGuaranteeList;
    this.isSaveDraft = true;
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.procurementScopeWorkRequest.procurementSowList =
              this.formGroup.get('procurementSowList').value;
            this.procurementScopeWorkRequest.procurementScopeWork =
              this.procurementScopeWorkResponse.procurementScopeWork;
            this.procurementScopeWorkRequest.isSaveDraft = true;
            this.procurementScopeWorkRequest.procurementFinesList =
              this.procurementScopeWorkResponse.procurementFinesList;
            this.procurementScopeWorkRequest.procurementGuaranteeList =
              this.procurementScopeWorkResponse.procurementGuaranteeList;
            this.procurementScopeWorkRequest.procurementVendor =
              this.procurementScopeWorkResponse.procurementVendor;
            this.httpClientService
              .post<Response<ProcurementScopeWork>>(
                '/procurement-scope-work/update',
                this.procurementScopeWorkRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/procurement-scope-work/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
  public doCancel(): void {
    this.router.navigate(['/pages/procurement-scope-work']);
  }

  public setDecimal(field: number): string {
    return this.currency.transform(field, '', '', this.CURRENCY_DIGITS_INFO);
  }
}
