import { Fines } from 'src/app/core/bean/fines';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { ItemType } from 'src/app/core/bean/item-type';
import { Module } from 'src/app/core/bean/module';
import { Order } from 'src/app/core/bean/order';
import { OrderActivityHistory } from 'src/app/core/bean/order-activity-history';
import { OrderCancellation } from 'src/app/core/bean/order-cancellation';
import { OrderDoc } from 'src/app/core/bean/order-doc';
import { OrderItem } from 'src/app/core/bean/order-item';
import { OrderShipping } from 'src/app/core/bean/order-shipping';
import { OrderShippingItem } from 'src/app/core/bean/order-shipping-item';
import { OrderTc } from 'src/app/core/bean/order-tc';
import { OrderType } from 'src/app/core/bean/order-type';
import { PrShipping } from 'src/app/core/bean/pr-shipping';
import { Sow } from 'src/app/core/bean/sow';
import { SowPaymentTerm } from 'src/app/core/bean/sow-payment-term';
import { SowPaymentTermBilling } from 'src/app/core/bean/sow-payment-term-billing';
import { SowPaymentTermItem } from 'src/app/core/bean/sow-payment-term-item';
import { TcDetail } from 'src/app/core/bean/tc-detail';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { ApprovalPathResponseModel } from 'src/app/core/components/app-approval-path-x';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { ContractShipping } from '../../../core/bean/contract-shipping';
import { ContractShippingDetail } from '../../../core/bean/contract-shipping-detail';
import { Tc } from '../../../core/bean/tc';
import { PrItem } from 'src/app/core/bean/pr-item';
import { Currency } from 'src/app/core/bean/currency';
import { Pr } from 'src/app/core/bean/pr';
import { ProcurementType } from 'src/app/core/bean/procurement-type';
import { CatalogType } from 'src/app/core/bean/catalog-type';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { PrItemBudget } from 'src/app/core/bean/pr-item-budget';
import { BudgetType } from 'src/app/core/bean/budget-type';
import { CostCenter } from 'src/app/core/bean/cost-center';
import { WorkProgram } from 'src/app/core/bean/work-program';
import { Coa } from 'src/app/core/bean/coa';
import { Organization } from 'src/app/core/bean/organization';

export class OrderAddEditResponse {
  order: Order;
  orderTypeList: OrderType[] = [];
  orderDocList: OrderDoc[] = [];
  tcDetailList: TcDetail[] = [];
  itemTypeList: ItemType[] = [];
  tcList: Tc[];
  orderItemList: OrderItem[] = [];
  orderShippingList: OrderShipping[] = [];
  orderShippingItemList: OrderShippingItem[] = [];

  orderTcList: OrderTc[] = [];

  orderActivityHistory: OrderActivityHistory; /** vendor confirm */
  orderActivityHistoryList: OrderActivityHistory[] = [];
  orderCancellation: OrderCancellation;
  vendorAddress: VendorAddress;

  sowList: Sow[] = [];
  sowPaymentTermList: SowPaymentTerm[] = [];
  sowPaymentTermItemList: SowPaymentTermItem[] = [];
  sowPaymentTermBillingList: SowPaymentTermBilling[] = [];

  finesList: Fines[] = [];
  guaranteeList: Guarantee[] = [];
  contractShippingList: ContractShipping[] = [];
  contractShippingDetailList: ContractShippingDetail[] = [];
  vendorAddressList: VendorAddress[] = [];
  // prShippingList: PrShipping[] = [];

  isUserApproval: boolean;
  approvalPathResponseModel: ApprovalPathResponseModel =
    new ApprovalPathResponseModel();
  module: Module = new Module();
  appOfficialReportModel: AppOfficialReportModel = new AppOfficialReportModel();

  // penyesuaian direct PO
  prItemList: PrItem[] = [];


  
  isModuleGroupESMActive: boolean;
  currencyList: Currency[] = [];
  pr: Pr = new Pr();
  procurementTypeList: ProcurementType[];
  prShippingList: PrShipping[] = [];
  catalogType: CatalogType;
  vendorViewList: PopupVendorView[] = [];
  companyCurrency: Currency;
  prItemBudgetList: PrItemBudget[] = [];
  budgetTypeList: BudgetType[] = [];
  costCenterList: CostCenter[] = [];
  workProgramList: WorkProgram[] = [];
  coaList: Coa[] = [];

  //exisitng di pr response
  organization: Organization = new Organization();
  isValidatePr: boolean;
  procTypeMasterList: ProcurementType[] = [];

}
