import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseModuleComponent } from '../../base/angular/base-module.component';
import { Currency } from '../../bean/currency';
import { Organization } from '../../bean/organization';
import { ProcurementWorklistStatus } from '../../bean/procurement-worklist-status';
import { ReportRecapColumn } from '../../bean/report-recap-column';
import { AppPopupProgressBarDownloadComponent } from '../../components/app-popup/app-popup-progress-bar-download/app-popup-progress-bar-download.component';
import { ProgressBarDownloadModel } from '../../components/app-popup/app-popup-progress-bar-download/progress-bar-download-model';
import { AppPopupService } from '../../components/app-popup/app-popup.service';
import { ToastService } from '../../components/app-toast/app-toast.service';
import { TableColumnModel } from '../../components/table/model/table-column-model';
import { TableResponseModel } from '../../components/table/model/table-response-model';
import { OptionListModel } from '../../model/option-list-model';
import { RecapPrintService } from '../../services/recap-print.service';
import { ReportRecapRfpView } from '../../view/entity/bean/report-recap-rfp-view';
import { ReportRecapRfpPopupChooseColumnComponent } from './report-recap-rfp-popup-choose-column.component';
import { ReportRecapRfpPopupFullViewComponent } from './report-recap-rfp-popup-full-view.component';
import { ReportRecapRfpRequest } from './report-recap-rfp.request';
import { ReportRecapRfpResponse } from './report-recap-rfp.response';

@Component({
  templateUrl: './report-recap-rfp.component.html'
})
export class ReportRecapRfpComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<ReportRecapRfpView>;
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );
  public statusOptionList: OptionListModel<ProcurementWorklistStatus> =
    new OptionListModel(true);
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);
  public reportRecapColumnList: ReportRecapColumn[] = [];
  public module: string;
  public currentPosition: string;
  public customData = '';
  public reportBuilderId: number;
  public reportRecapId: number;
  public generatedTableResponse: string;
  public progressBarDownload = new ProgressBarDownloadModel();
  public httpRequest: Subscription;
  public isShowPopupModifyTable = false;
  public isFullViewTable = false;

  constructor(
    public translateService: TranslateService,
    public appPopupService: AppPopupService,
    public recapPrintService: RecapPrintService,
    public toastService: ToastService
  ) {
    super('report-recap-rfp', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.reportBuilderId = this.global.routerParams.get('reportBuilderId');
    this.reportRecapId = this.global.routerParams.get('reportRecapId');
    this.generatedTableResponse = this.global.routerParams.get(
      'generatedTableResponse'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
      organizationList: [null],
      statusList: [null],
      minimumAmount: [null],
      maximumAmount: [null],
      responseStartDateFrom: [null],
      responseStartDateTo: [null],
      responseEndDateFrom: [null],
      responseEndDateTo: [null]
    });
  }

  public doGenerate(): void {
    if (this.formGroup.value.startDate) {
      this.formGroup.patchValue({
        responseStartDateFrom: this.formGroup.value.startDate.from,
        responseStartDateTo: this.formGroup.value.startDate.to
      });
    } else {
      this.formGroup.value.responseStartDateFrome = null;
      this.formGroup.value.responseStartDateTo = null;
    }
    if (this.formGroup.value.endDate) {
      this.formGroup.patchValue({
        responseEndDateFrom: this.formGroup.value.endDate.from,
        responseEndDateTo: this.formGroup.value.endDate.to
      });
    } else {
      this.formGroup.value.responseStartDateFrome = null;
      this.formGroup.value.responseStartDateTo = null;
    }
    if (this.formGroup.value.minimumAmount) {
      this.formGroup.value.minimumAmount =
        this.formGroup.value.minimumAmount.price;
    }
    if (this.formGroup.value.maximumAmount) {
      this.formGroup.value.maximumAmount =
        this.formGroup.value.maximumAmount.price;
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.patchValue({
      startDate: null,
      endDate: null,
      organizationList: null,
      statusList: null,
      minimumAmount: null,
      maximumAmount: null,
      responseStartDateFrom: null,
      responseStartDateTo: null,
      responseEndDateFrom: null,
      responseEndDateTo: null
    });
    this.tableResponse.setCustomData(null);
    this.tableResponse.reload();
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<ReportRecapRfpResponse>(
        '/report-recap-rfp/get-option-list?reportRecapId=' +
          this.reportRecapId +
          (this.reportBuilderId
            ? '&reportBuilderId=' + this.reportBuilderId
            : '')
      )
      .subscribe(response => {
        if (response != null) {
          this.statusOptionList.setRequestValues(response.statusList);
          this.organizationOptionList.setRequestValues(
            response.organizationList
          );
          this.currencyOptionList.setRequestValues(response.currencyList);
          this.reportRecapColumnList = response.reportRecapColumnList;
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    const arrayColumn: Array<TableColumnModel> = this.generatedTableResponse
      ? JSON.parse(this.generatedTableResponse)
      : null;
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      this.reportBuilderId
        ? arrayColumn
        : [
            {
              field: 'rfp.code',
              header: 'table.column.rfpNumber'
            },
            {
              field: 'rfp.title',
              header: 'table.column.title'
            },
            {
              field: 'rfp.organization.name',
              header: 'table.column.department'
            },
            {
              field: 'createdDate',
              header: 'table.column.createdDate',
              plugins: {
                name: 'date',
                format: 'short-date'
              }
            },
            {
              field: 'creator',
              header: 'table.column.creator'
            },
            {
              field: 'rfp.startDate',
              header: 'table.column.responseStartDate',
              plugins: {
                name: 'date',
                format: 'short-date'
              }
            },
            {
              field: 'rfp.endDate',
              header: 'table.column.responseEndDate',
              plugins: {
                name: 'date',
                format: 'short-date'
              }
            },
            {
              field: 'rfp.note',
              header: 'table.column.note'
            }
          ]
    );
  }

  public doExport(): void {
    if (this.reportBuilderId) {
      this.appPopupService
        .open(AppPopupProgressBarDownloadComponent, {
          model: this.progressBarDownload
        })
        .subscribe(isCancel => {
          if (isCancel) {
            this.httpRequest.unsubscribe();
          }
        });
      const request = new ReportRecapRfpRequest();
      request.reportRecapColumnList = this.reportRecapColumnList;
      request.customData = JSON.stringify(this.tableResponse.getCustomData());

      this.recapPrintService
        .print('/report-recap-rfp/export-list', request)
        .subscribe(
          response => {
            if (response) {
              this.progressBarDownload.setProgress(100);
              setTimeout(() => {
                this.global.modalService.downloadSuccess();
              }, 500);
            }
          },
          error => {
            error.error.text().then(text => this.toastService.showError(text));
          }
        );
    } else {
      this.appPopupService.open(ReportRecapRfpPopupChooseColumnComponent, {
        customData: this.tableResponse.getCustomData(),
        reportRecapColumnList: this.reportRecapColumnList
      });
    }
  }

  public doFullView(): void {
    this.appPopupService.open(
      ReportRecapRfpPopupFullViewComponent,
      { reportRecapColumnList: this.reportRecapColumnList },
      { size: 'xl' }
    );
  }

  public doCustomSetting(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('reportBuilderId', this.reportBuilderId);
    this.router.navigate(['/pages/report-recap/edit']);
  }
}
