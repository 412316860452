import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppContractRequestViewModule } from 'src/app/core/components/app-contract-request-view/app-contract-request-view.module';
import { AppProcurementInformationViewModule } from 'src/app/core/components/app-procurement-information-view/app-procurement-information-view.module';
import { AppVendorPaymentInformationModule } from 'src/app/core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AppPopupContractMonitoringEditContractComponent } from './app-popup-contract-monitoring-edit-contract.component';
import { AppPopupContractMonitoringSendEmailContractComponent } from './app-popup-contract-monitoring-send-email-contract.component';
import { AppPopupContractMonitoringSendEmailGuaranteeComponent } from './app-popup-contract-monitoring-send-email-guarantee.component';
import { AppPopupContractMonitoringSignedDocumentComponent } from './app-popup-contract-monitoring-signed-document.component';
import { ContractMonitoringContractClosedComponent } from './contract-monitoring-contract-closed.component';
import { ContractMonitoringEditAddComponent } from './contract-monitoring-edit-add.component';
import { ContractMonitoringComponent } from './contract-monitoring.component';

export const routes = [
  {
    path: '',
    component: ContractMonitoringComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: ContractMonitoringEditAddComponent,
    data: { breadcrumb: 'contract-monitoring.breadcrumb.view' }
  },
  {
    path: 'contract-closed',
    component: ContractMonitoringContractClosedComponent,
    data: { breadcrumb: 'contract-monitoring.breadcrumb.contractClosed' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppProcurementInformationViewModule,
    AppContractRequestViewModule,
    AppVendorPaymentInformationModule
  ],
  declarations: [
    ContractMonitoringComponent,
    ContractMonitoringEditAddComponent,
    ContractMonitoringContractClosedComponent,
    AppPopupContractMonitoringSendEmailContractComponent,
    AppPopupContractMonitoringSendEmailGuaranteeComponent,
    AppPopupContractMonitoringSignedDocumentComponent,
    AppPopupContractMonitoringEditContractComponent
  ],
  entryComponents: [
    AppPopupContractMonitoringSendEmailContractComponent,
    AppPopupContractMonitoringSendEmailGuaranteeComponent,
    AppPopupContractMonitoringSignedDocumentComponent,
    AppPopupContractMonitoringEditContractComponent
  ],
  exports: [
    AppPopupContractMonitoringSendEmailContractComponent,
    AppPopupContractMonitoringSendEmailGuaranteeComponent,
    AppPopupContractMonitoringSignedDocumentComponent,
    AppPopupContractMonitoringEditContractComponent
  ]
})
export class ContractMonitoringModule {}
