import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Holiday } from '../../core/bean/holiday';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: 'holiday.component.html',
  styles: [
    '.width-org-name { min-width : 250px; white-space: normal ! important; text-align: center }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class HolidayComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Holiday>;
  constructor(
    translateService: TranslateService,
    public appPoupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('holiday', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organizationId: [null],
      organizationName: [null]
    });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'organization.name',
        header: 'table.column.organization',
        customClass: 'width-org-name'
      },
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'isYearly',
        header: 'table.column.annual',
        datamap: '{"false" : "No" , "true" : "Yes" }',
        customClass: 'text-center'
      },
      {
        field: 'isActive',
        header: 'table.column.status',
        datamap: '{"false" : "Not Active" , "true" : "Active" }',
        customClass: 'text-center'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/holiday/add']);
  }

  public doEdit(holiday: Holiday): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('holidayId', holiday.id);
    this.router.navigate(['/pages/holiday/edit']);
  }

  public doDelete(holiday: Holiday): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          return this.httpClientService
            .post<Response<Holiday>>('/holiday/delete', holiday)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public onChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPoupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(organization => {
        const { name: organizationName, id: organizationId } =
          organization.pop();
        this.formGroup.patchValue({ organizationName, organizationId });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }
}
