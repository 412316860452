import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../base/angular/base-component.component';
@Component({
  selector: 'app-widget',
  templateUrl: './app-widget.component.html',
  styleUrls: ['./app-widget.component.scss'],
})
export class AppWidgetComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() isLoading: string;
  constructor() {
    super('app-widget');
  }
  public onInit(): void { }
}
