<app-popup [isShowHeaderCloseButton]="true">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="text-center"
      ><br />
      <h3 [ngClass]="'text-primary'">{{
        'pr.popup.cancellation.title' | translate
      }}</h3
      ><br />
      <div innerHTML="{{ 'pr.popup.cancellation.detail' | translate }}"></div>
    </div>
    <div class="row text-sm-left">
      <div class="col-sm-8 text-left">
        <p>{{ 'pr.popup.cancellation.reason' | translate }}</p>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <div class="col-sm-12 text-left">
        <app-text-area
          formControlName="note"
          size="XL"
          maxLength="255"
        ></app-text-area>
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button
          color="LIGHT"
          [outline]="true"
          (onClick)="activeModal.close(false)"
        >
          {{ 'app.button.cancel' | translate }}
        </app-button>
        <app-button (onClick)="doSave()">
          {{ 'pr.button.sure' | translate }}
        </app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
