import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { VendorDocMaster } from '../bean/vendor-doc-master';
import { VendorHistory } from '../bean/vendor-history';
export class VendorDocHistoryEntity extends BaseEntity {
  crudInfo: string;
  vendorHistory: VendorHistory;
  file: File;
  vendorDocMaster: VendorDocMaster;
  createdDate: Date;
  endDate: Date;
  startDate: Date;
  isVerified: boolean;
  verifiedDate: Date;
  docNumber: string;
  docPublisher: string;
  vendorDocId: number;
}
