import { Claim } from "src/app/core/bean/claim";
import { ClaimDoc } from "src/app/core/bean/claim-doc";
import { ClaimStatus } from "src/app/core/bean/claim-status";
import { ClaimVerification } from "src/app/core/bean/claim-verification";
import { Counter } from "src/app/core/bean/counter";
import { ExecutorAssignment } from "src/app/core/bean/executor-assignment";
import { File } from "src/app/core/bean/file";
import { GlStatus } from "src/app/core/bean/gl-status";
import { Verificator } from "src/app/core/bean/verificator";
import { ApprovalPathResponseModel } from "src/app/core/components/app-approval-path-x";
import { ClaimDocMaster } from "../../core/bean/claim-doc-master";
import { ClaimRequestAmount } from "src/app/core/bean/claim-request-amount";

export class ClaimVerificationResponse {
    claimDocList: ClaimDoc[] = [];
    fileList: File[] = [];
    claim: Claim;
    isUserApproval: boolean;
    approvalPathResponseModelBean: ApprovalPathResponseModel;
    executorAssignment: ExecutorAssignment;
    verificator: Verificator;
    counter: Counter;
    claimStatusList: ClaimStatus[] = [];
    claimVerificationList: ClaimVerification[] = [];
    glStatus: GlStatus;
    claimDocMasterList: ClaimDocMaster[] = [];
    fileOtherList: File[] = [];
    claimRequestAmount: ClaimRequestAmount;
}