<div class="row main-row">
  <div class="col-12">
    <app-card
      header="holiday.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'holiday.form.organization' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="organization"
              stringUrl="/organization"
              isLazy="true"
              header="Organization"
            ></app-text-tree>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'holiday.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="name" maxlength="32">
            </app-text-field>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'holiday.form.startDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-date-picker formControlName="startDate" maxDate="endDate">
            </app-date-picker>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'holiday.form.endDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-date-picker formControlName="endDate" minDate="startDate">
            </app-date-picker>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <div class="col-sm-3 text-left"> </div>
          <div class="col-sm-9 text-left">
            <app-check-box formControlName="isYearly">
              {{ 'holiday.form.annual' | translate }}
            </app-check-box>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'holiday.form.status' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              formControlName="isActive"
              [optionList]="statusOptionList"
            >
            </app-combo-box>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            color="PRIMARY"
            (onClick)="doSave()"
            [disabled]="formSaving"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
