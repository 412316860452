<app-popup
  [header]="header"
  [isLoading]="formLoading"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-performance-history.form.vendorName' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ vendor.name }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-performance-history.form.vendorCode' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ vendor.code }}
      </div>
    </div>

    <br />

    <app-table
      [model]="tableResponse"
      [isServerSide]="true"
      stringUrl="/tab-performance-history/index-vendor-performance"
    >
    </app-table>

    <app-fieldset legend="tab-performance-history.performanceResult">
      <div
        class="table-responsive custom-table float-none"
        [ngStyle]="{ margin: '0 auto' }"
      >
        <table
          class="table table-striped box-shadow"
          aria-describedby="Table"
        >
          <thead>
            <tr class="text-center">
              <th scope="col">{{ 'app.table.column.no' | translate }}</th>
              <th scope="col">{{
                'tab-performance-history.table.column.performance' | translate
              }}</th>
              <th scope="col">{{
                'tab-performance-history.table.column.weight' | translate
              }}</th>
              <th scope="col">{{
                'tab-performance-history.table.column.result' | translate
              }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let data of vendorPerformanceOverallDetailList;
                let i = index
              "
            >
              <td class="text-center">{{ i + 1 }}.</td>
              <td class="text-center"
                >{{ data.moduleScoringCriteria.name }}
              </td>
              <td class="text-center">{{ data.weight }} % </td>
              <td class="text-center">{{ data.result | number: '1.2-2' }}</td>
            </tr>
            <tr>
              <td class="text-center"></td>
              <td class="text-center"></td>
              <td class="text-center">{{
                'tab-performance-history.table.column.total' | translate
              }}</td>
              <td class="text-center">{{ rating | number: '1.2-2' }}</td>
            </tr>
            <tr>
              <td class="text-center"></td>
              <td class="text-center"></td>
              <td class="text-center">{{
                'tab-performance-history.table.column.rating' | translate
              }}</td>
              <td class="text-center">
                <app-rating [value]="rating" [isViewOnly]="true"></app-rating>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </app-fieldset>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
  