<div *ngIf="onBoardingResponse.vendorHistory.vendor.workflowPrcs?.workflowStatus.code !== 'FINISH'">
  <app-work-path [model]="workPathModel" (onChange)="doOnChangeWorkpath($event)"
  ></app-work-path>
</div>

<app-card [isSaving]="formSaving" [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
        <router-outlet></router-outlet>
    </div>
  </div>
</app-card>

