<div class="row main-row">
  <div class="col-12">
    <app-card header="rfi.title" [isLoading]="formLoading">
      <h5 class="separator">{{ 'rfi.generalInformation' | translate }}</h5>
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'rfi.form.title' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ rfiDTO?.rfi.title }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'rfi.form.projectAmount' | translate
          }}</label>
          <div
            class="col-sm-9 text-left"
            *ngIf="
              rfiDTO?.rfi.currency !== null ||
              rfiDTO?.rfi.projectAmountFrom !== null ||
              rfiDTO?.rfi.projectAmountTo !== null
            "
          >
            <div
              >{{ rfiDTO?.rfi.currency.code }}
              {{ formGroup.value.projectAmountFrom }}
              {{ 'rfi.form.until' | translate }}
              {{ rfiDTO?.rfi.currency.code }}
              {{ formGroup.value.projectAmountTo }}</div
            >
          </div>
          <div
            class="col-sm-9 text-left"
            *ngIf="
              rfiDTO?.rfi.currency === null ||
              rfiDTO?.rfi.projectAmountFrom === null ||
              rfiDTO?.rfi.projectAmountTo === null
            "
          >
            <div>{{ 'rfi.form.undisclosed' | translate }}</div>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'rfi.form.responseDeadline' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              rfiDTO?.rfi.responseDeadlineDate
                | date: global.config.parameterModel.dateFormatShort
            }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'rfi.form.publishDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              rfiDTO?.rfi.publishDate
                | date: global.config.parameterModel.dateFormatShort
            }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'rfi.form.templateRFI' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <a
              href="#"
              (click)="
                doDownload(
                  $event,
                  '/request-for-information/file/view/' +
                    rfiDTO?.rfi.file.uploadedFileName +
                    '/' +
                    global.appConstant.fileType.DOC_RFI_TEMPLATE,
                  rfiDTO?.rfi.file.fileName
                )
              "
            >
              <div>{{ rfiDTO?.rfi.file.fileName }}</div>
            </a>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'rfi.form.note' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ rfiDTO?.rfi.note }}
          </div>
        </div>

        <br />

        <h5 class="separator">{{ 'rfi.item' | translate }}</h5>
        <app-table
          #selectorItem
          [tableResponse]="tableResponseRfiItem"
          [isServerSide]="false"
          *ngIf="tableReady"
        >
        </app-table>

        <h5 class="separator">{{ 'rfi.vendorData' | translate }}</h5>
        <app-table
          #selectorVendor
          [tableResponse]="tableResponseVendor"
          [isServerSide]="false"
          *ngIf="tableReady"
        >
          <ng-template #actionButtons let-rowData>
            <app-button
              *ngIf="rowData.isSubmitted"
              size="TN"
              color="PRIMARY"
              (onClick)="doViewResponseVendor(rowData)"
            >
              <em class="fas fa-search"></em>
            </app-button>
            <div *ngIf="!rowData.isSubmitted">-</div>
          </ng-template>
        </app-table>

        <!-- untuk saat ini vendor non-promise di-hide dulu -->
        <!-- <h5 class="separator">Vendor Non Promise</h5>
        <app-table 
        #selectorVendorNonPromise [tableResponse]="tableResponseVendorNonPromise" 
        [isServerSide]="false" *ngIf="tableReady">
          <ng-template #actionButtons 
          let-rowData>
            <app-button *ngIf="rowData.statusVendor === 'Response 
            size="TN" 
            color="PRIMARY" (onClick)="doViewResponseVendorNonPromise(rowData)">
              <em class="fas fa-search"></em>
            </app-button>
            <div 
            *ngIf="rowData.statusVendor === 'Response Not Available'">
            -</div>
          </ng-template>
        </app-table> -->
        <div class="row justify-content-center mt-5">
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doCancel()"
            class="mr-1"
            >{{ 'app.button.back' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
