import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  selector: 'app-alert',
  templateUrl: './app-alert.component.html',
  styleUrls: ['./app-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppAlertComponent extends BaseComponentComponent {
  @Input() public message: string;
  constructor() {
    super('app-alert');
  }

  public onInit(): void {
    this.setStateReady();
  }
}
