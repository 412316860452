import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';

@Component({
  selector: 'app-content-slider-item',
  templateUrl: './app-content-slider-item.component.html',
  styleUrls: ['./app-content-slider-item.component.scss']
})
export class AppContentSliderItemComponent extends BaseComponentComponent {
  @Input() position: 'outer-left' | 'outer-right';
  @Input() backgroundColor: string = 'var(--white)';
  @Input() color: string;
  @Input() width: string;
  @Input() disable: boolean;
  @Output() onClick: EventEmitter<PointerEvent> = new EventEmitter();
  constructor() {
    super('app-content-slider-item');
  }

  onInit(): void {
    this.setStateReady();
  }

  public doClickItem(event: PointerEvent): void {
    if (!this.disable) {
      this.onClick.emit(event);
    }
  }
}
