import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ContractNegotiationHistory } from '../../bean/contract-negotiation-history';
import { TablePluginData } from '../table/interface/table-plugin-data';
import { TableResponseModel } from '../table/model/table-response-model';
import { FileUploader } from '../upload';

@Component({
  selector: 'app-contract-negotiation-view',
  templateUrl: './app-contract-negotiation-view.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppContractNegotiationViewComponent extends BaseComponentComponent {
  @Input() public contractId: number;
  public tableResponse: TableResponseModel<ContractNegotiationHistory>;
  constructor() {
    super('app-contract-negotiation-view');
  }

  public onInit(): void {
    this.getAndsetNegotiationInformationData();
    this.buildTableResponse();
  }

  public getAndsetNegotiationInformationData(): void {
    this.httpClientService
      .get<ContractNegotiationHistory[]>(
        '/app-contract-negotiation-view/index-negotiation-information/' +
          this.contractId
      )
      .subscribe(response => {
        if (response) {
          this.tableResponse.setRecordList(response);
          this.tableResponse.reload();
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'docFile',
        header: 'table.column.file',
        plugins: {
          name: 'upload',
          fileUploader: new FileUploader(
            '/app-contract-negotiation-view/',
            '',
            this.global.appConstant.fileType.DOC_CONTRACT,
            true
          ),
          isView: true
        },
        isSearchTable: false
      },
      {
        field: 'userType',
        header: 'table.column.uploaderRole',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (
              tablePlugin.value &&
              tablePlugin.value === this.global.appConstant.cm.USER_TYPE_VENDOR
            ) {
              tablePlugin.element.innerText = 'Vendor';
            } else if (tablePlugin.value) {
              tablePlugin.element.innerText = 'Staff';
            }
          }
        }
      },
      {
        field: 'note',
        header: 'table.column.note'
      },
      {
        field:
          this.global.userSession.activeUserRole.role.code ===
          this.global.appConstant.ROLE_CODE_VENDOR
            ? 'vendorNegotiationStatus.name'
            : 'userNegotiationStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField:
            this.global.userSession.activeUserRole.role.code ===
            this.global.appConstant.ROLE_CODE_VENDOR
              ? 'vendorNegotiationStatus.code'
              : 'userNegotiationStatus.code',
          colorMap: {
            NEW: 'INFO',
            APPROVED: 'SUCCESS',
            REVISION: 'FEEDBACK',
            WAITING_CONFIRMATION: 'GOOD',
            ON_PROGRESS: 'PROGRESS',
            REVISION_BY_VENDOR: 'FEEDBACK'
          }
        }
      }
    ]);
  }
}
