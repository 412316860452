import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  selector: 'app-widget-announcement-item',
  templateUrl: './app-widget-announcement-item.component.html',
  styleUrls: ['./app-widget-announcement-item.component.scss']
})
export class AppWidgetAnnouncementItemComponent extends BaseComponentComponent {
  @Input() announcement: any;
  constructor() {
    super('app-widget-announcement-item');
  }

  public onInit(): void {
    this.setStateReady();
  }
}
