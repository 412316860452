import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { EvaluationCriteriaEditAddComponent } from './evaluation-criteria-edit-add.component';
import { PopupAddAdministrationCriteriaComponent } from './popup-add-administration-criteria.component';
import { PopupAddTechnicalCriteriaComponent } from './popup-add-technical-criteria.component';

const routes = [
  {
    path: 'add',
    component: EvaluationCriteriaEditAddComponent,
    data: { breadcrumb: 'evaluation-criteria.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: EvaluationCriteriaEditAddComponent,
    data: { breadcrumb: 'evaluation-criteria.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: EvaluationCriteriaEditAddComponent,
    data: { breadcrumb: 'evaluation-criteria.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AppProcurementInfoModule,
    AppWorkflowStepInfoModule
  ],
  declarations: [
    EvaluationCriteriaEditAddComponent,
    PopupAddAdministrationCriteriaComponent,
    PopupAddTechnicalCriteriaComponent
  ]
})
export class EvaluationCriteriaModule {}
