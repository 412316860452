import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppButtonModule } from '../../components/app-button/app-button.module';
import { AppChartModule } from '../../components/app-chart/app-chart.module';
import { AppComboBoxModule } from '../../components/app-combo-box/app-combo-box.module';
import { AppPopupModule } from '../../components/app-popup/app-popup.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetContractReportStatisticalFilterPopupComponent } from './app-widget-contract-report-statistical-filter-popup.component';
import { AppWidgetContractReportStatisticalComponent } from './app-widget-contract-report-statistical.component';
@NgModule({
  imports: [
    VendorModule,
    AppWidgetModule,
    AppChartModule,
    AppComboBoxModule,
    AppButtonModule,
    AppPopupModule
  ],
  declarations: [
    AppWidgetContractReportStatisticalComponent,
    AppWidgetContractReportStatisticalFilterPopupComponent
  ],
  entryComponents: [
    AppWidgetContractReportStatisticalComponent,
    AppWidgetContractReportStatisticalFilterPopupComponent
  ],
  exports: [AppWidgetContractReportStatisticalComponent]
})
export class AppWidgetContractReportStatisticalModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      'app-widget-contract-report-statistical',
      AppWidgetContractReportStatisticalComponent
    );
  }
}
