import { BaseEntity } from '../base/base-entity';
import { Region } from '../bean/region';
import { VendorHistory } from '../bean/vendor-history';

export class VendorProductLocationHistoryEntity extends BaseEntity {
    crudInfo: string;

    vendorHistory: VendorHistory;
    region: Region;
    createdDate: Date;
    vendorShippingLocationId: number;
}
