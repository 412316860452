<app-popup
  header="address-book.popupChooseAddressPic.title"
  [isLoading]="formLoading"
>
  <div *ngIf="!formLoading">
    <app-table-xx
      [model]="tableResponse"
      [isShowCheckBox]="true"
      [isShowPagination]="true"
      [isMultipleSelect]="true"
    >
    </app-table-xx>
  </div>

  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
      >
        {{ 'app.button.cancel' | translate }}
      </app-button>
      <app-button (onClick)="doChoose()">
        {{ 'app.button.add' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>
