import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Guarantee } from '../../core/bean/guarantee';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './contract-history-popup-guarantee.component.html'
})
export class ContractHistoryPopupGuaranteeComponent extends BaseComponentComponent {
  @Input() guaranteeList: Guarantee[] = [];
  @Output() public onChange: EventEmitter<Guarantee> = new EventEmitter();

  public guaranteeOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'guaranteeBondType.name'
  );

  public stringList = ['A', 'B', 'C'];

  constructor(public ngbActiveModal: NgbActiveModal) {
    super('contract-history');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setOptionList();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      guarantee: [this.guaranteeList[0], Validators.required()]
    });
  }

  public setOptionList(): void {
    this.guaranteeOptionList.setRequestValues(this.guaranteeList);
    this.setStateReady();
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }

  public doSelect(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value.guarantee);
    }
  }
}
