import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { FileObject } from 'src/app/core/components/upload';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorExperienceHistory } from '../../core/bean/vendor-experience-history';
import { VendorExperienceSegmentationHistory } from '../../core/bean/vendor-experience-segmentation-history';
import { VendorExperienceTitle } from '../../core/bean/vendor-experience-title';
import { VendorType } from '../../core/bean/vendor-type';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { VendorEntity } from '../../core/entity/vendor.entity';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfileExperienceDTO } from './dto/tab-profile-experience.dto';
import { AppPopupExperienceComponent } from './popup/app-popup-experience.component';

@Component({
  templateUrl: './tab-profile-experience.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }',
    '.segmentation-column-width { white-space: normal ! important; text-align: center; min-width: 300px }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TabProfileExperienceComponent extends BaseModuleComponent {
  @ViewChild('selectorVendorExperience')
  public tableVendorExperience: AppTableComponent;

  public userId: number;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfileExperienceDTO: TabProfileExperienceDTO =
    new TabProfileExperienceDTO();
  public vendor: Vendor = new Vendor();
  public now = new Date().getTime();
  public sessionId = null;
  public vendorTypeList: VendorType[] = [];
  public regionList: Region[] = [];
  public vendorEntityList: VendorEntity[] = [];
  public vendorExperienceTitle: VendorExperienceTitle;
  public tabExperience: TabModel;
  public vendorExperienceSegmentationHistory: VendorExperienceSegmentationHistory;
  public vendorExperienceSegmentationHistoryList: VendorExperienceSegmentationHistory[] =
    [];
  public vendorExperienceHistory: VendorExperienceHistory =
    new VendorExperienceHistory();
  public segmentation: string;
  public fileData: any;
  public isVendorExperience = true;
  public isView: boolean;
  public fromOutside: string;
  public isVendorList: boolean;

  public tableResponse: TableResponseModel<VendorExperienceHistory>;

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-experience', translate);
  }

  public onInit(): void {
    this.getDataFromRouterParams();
    if (this.tabExperience.routerParams.get('tabProfileExperienceDTO')) {
      this.tabProfileExperienceDTO = this.tabExperience.routerParams.get(
        'tabProfileExperienceDTO'
      );
      this.buildTableResponse();
      this.getAndSetVendorToFormGroup();
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .get<TabProfileExperienceDTO>(
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_VENDOR
          ? '/tab-profile-experience/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-experience/index'
      )
      .subscribe(tabProfileExperienceDTO => {
        this.tabProfileExperienceDTO = tabProfileExperienceDTO;
        this.tabExperience.routerParams.set(
          'tabProfileExperienceDTO',
          tabProfileExperienceDTO
        );
        this.buildTableResponse();
        this.getAndSetVendorToFormGroup();
      });
  }

  public buildTableResponse(): void {
    if (
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
    ) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'projectName',
          header: 'table.column.projectName',
          customClass: 'width-company-name'
        },
        {
          field: 'businessPartnerName',
          header: 'table.column.businessPartner',
          customClass: 'width-company-name'
        },
        {
          field: 'countryRegion.name',
          header: 'table.column.country'
        },
        { field: 'projectOwner', header: 'table.column.projectOwner' },
        {
          field: 'projectType',
          header: 'table.column.projectType'
        },
        {
          field: 'segmentationNamesHistory',
          header: 'table.column.segmentation',
          customClass: 'segmentation-column-width'
        },
        {
          field: 'vendorExperienceStatus.name',
          header: 'table.column.experienceStatus'
        },
        {
          field: 'contractNumber',
          header: 'table.column.contractNumber'
        },
        {
          field: 'contractDate',
          header: 'table.column.contractDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'contractValue',
          header: 'table.column.contractValue',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'file.fileName',
          header: 'table.column.proofOfContract',
          format: FieldFormatEnum.DownloadableFile,
          urlFile: 'file.uploadedFileName'
        },
        {
          field: 'crudInfo',
          header: 'table.column.status',
          format: FieldFormatEnum.Html,
          customClass: 'text-center'
        }
      ]);
    } else {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'projectName',
          header: 'table.column.projectName',
          customClass: 'width-company-name'
        },
        {
          field: 'businessPartnerName',
          header: 'table.column.businessPartner',
          customClass: 'width-company-name'
        },
        {
          field: 'countryRegion.name',
          header: 'table.column.country'
        },
        { field: 'projectOwner', header: 'table.column.projectOwner' },
        {
          field: 'projectType',
          header: 'table.column.projectType'
        },
        {
          field: 'segmentationNamesHistory',
          header: 'table.column.segmentation',
          customClass: 'segmentation-column-width'
        },
        {
          field: 'vendorExperienceStatus.name',
          header: 'table.column.experienceStatus'
        },
        {
          field: 'contractNumber',
          header: 'table.column.contractNumber'
        },
        {
          field: 'contractDate',
          header: 'table.column.contractDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'contractValue',
          header: 'table.column.contractValue',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'file.fileName',
          header: 'table.column.proofOfContract',
          format: FieldFormatEnum.DownloadableFile,
          urlFile: 'file.uploadedFileName'
        }
      ]);
    }
    this.tableResponse.setRecords(
      this.tabProfileExperienceDTO.experienceHistoryList
    );
    this.tableResponse.setTotalRecords(
      this.tabProfileExperienceDTO.experienceHistoryList.length
    );
  }

  public doAdd(): void {
    const todo = 'add';
    this.appPopupService
      .open(
        AppPopupExperienceComponent,
        { todo },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(experienceHistory => {
        this.segmentation = '';

        this.vendorExperienceSegmentationHistoryList = [];

        // tslint:disable-next-line: no-shadowed-variable
        experienceHistory.segmentationList.forEach((element, index) => {
          this.vendorExperienceSegmentationHistory =
            new VendorExperienceSegmentationHistory();
          this.vendorExperienceSegmentationHistory.segmentation = element;
          this.vendorExperienceSegmentationHistoryList.push(
            this.vendorExperienceSegmentationHistory
          );
          if (index !== experienceHistory.segmentationList.length - 1) {
            this.segmentation += element.name + ' , ';
          } else {
            this.segmentation += element.name;
          }
        });
        this.vendorExperienceTitle = new VendorExperienceTitle();
        this.vendorExperienceTitle.id = 1;
        experienceHistory.vendorExperienceSegmentationHistoryList =
          this.vendorExperienceSegmentationHistoryList;
        experienceHistory.segmentationNamesHistory = this.segmentation;
        experienceHistory.vendorExperienceTitle = this.vendorExperienceTitle;
        if (experienceHistory.cityRegion !== null) {
          if (experienceHistory.cityRegion.id === undefined) {
            experienceHistory.cityRegion = null;
          }
        }
        this.vendorExperienceHistory = experienceHistory;
        if (experienceHistory.currencyValue !== null) {
          this.vendorExperienceHistory.currency =
            experienceHistory.currencyValue.currency;
          this.vendorExperienceHistory.contractValue = parseFloat(
            experienceHistory.currencyValue.price
          );
        } else {
          this.vendorExperienceHistory.currency = null;
          this.vendorExperienceHistory.contractValue = null;
        }
        const file = experienceHistory.file;
        if (file !== null && file.length !== 0) {
          const fileObjectList: FileObject[] = [];
          fileObjectList.push(experienceHistory.file[0]);
          this.vendorExperienceHistory.file = file[0].isDeleted
            ? null
            : experienceHistory.file[0].file;
          this.vendorExperienceHistory.fileObjectList = !file[0].isDeleted
            ? fileObjectList
            : file[0].file.id
            ? fileObjectList
            : [];
        } else {
          this.vendorExperienceHistory.file = null;
          this.vendorExperienceHistory.fileObjectList = [];
        }
        this.tabProfileExperienceDTO.experienceHistoryList.push(
          this.vendorExperienceHistory
        );
        this.tableResponse.setRecords(
          this.tabProfileExperienceDTO.experienceHistoryList
        );
        this.tableResponse.setTotalRecords(
          this.tabProfileExperienceDTO.experienceHistoryList.length
        );
        this.tableResponse.reloadClient();
      });
  }

  public doEdit(vendorExperienceHistoryEdit: VendorExperienceHistory): void {
    const todo = 'edit';
    this.appPopupService
      .open(
        AppPopupExperienceComponent,
        { todo, vendorExperienceHistoryEdit },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(experience => {
        this.tabProfileExperienceDTO.experienceHistoryList.forEach(
          (vendorExperienceHistory, index) => {
            if (vendorExperienceHistory === vendorExperienceHistoryEdit) {
              this.segmentation = '';

              this.vendorExperienceSegmentationHistoryList = [];

              // tslint:disable-next-line: no-shadowed-variable
              experience.segmentationList.forEach((element, index1) => {
                this.vendorExperienceSegmentationHistory =
                  new VendorExperienceSegmentationHistory();
                this.vendorExperienceSegmentationHistory.segmentation = element;
                if (experience.experienceSegmentationHistoryList !== null) {
                  experience.experienceSegmentationHistoryList.forEach(data => {
                    if (data.segmentation.code === element.code) {
                      this.vendorExperienceSegmentationHistory.vendorExperienceSegmentationId =
                        data.vendorExperienceSegmentationId;
                    }
                  });
                }
                this.vendorExperienceSegmentationHistoryList.push(
                  this.vendorExperienceSegmentationHistory
                );
                if (index1 !== experience.segmentationList.length - 1) {
                  this.segmentation += element.name + ' , ';
                } else {
                  this.segmentation += element.name;
                }
              });
              this.vendorExperienceTitle = new VendorExperienceTitle();
              this.vendorExperienceTitle.id = 1;
              experience.vendorExperienceSegmentationHistoryList =
                this.vendorExperienceSegmentationHistoryList;
              experience.segmentationNamesHistory = this.segmentation;
              experience.vendorExperienceTitle = this.vendorExperienceTitle;
              if (experience.cityRegion !== null) {
                if (experience.cityRegion.id === undefined) {
                  experience.cityRegion = null;
                }
              }
              if (experience.currencyValue !== null) {
                experience.currency = experience.currencyValue.currency;
                experience.contractValue = parseFloat(
                  experience.currencyValue.price
                );
              } else {
                experience.currency = null;
                experience.contractValue = null;
              }
              experience.id = null;
              this.tabProfileExperienceDTO.experienceHistoryList[index] =
                experience;
              const file = experience.file;
              if (file !== null && file.length !== 0) {
                const fileObjectList: FileObject[] = [];
                fileObjectList.push(experience.file[0]);
                this.tabProfileExperienceDTO.experienceHistoryList[index].file =
                  file.length !== undefined
                    ? file[0].isDeleted
                      ? null
                      : experience.file[0].file
                    : experience.file;
                this.tabProfileExperienceDTO.experienceHistoryList[
                  index
                ].fileObjectList =
                  file.length !== undefined
                    ? !file[0].isDeleted
                      ? fileObjectList
                      : file[0].file.id
                      ? fileObjectList
                      : []
                    : experience.fileUploader
                    ? experience.fileUploader.fileObjectList
                    : [];
              } else {
                this.tabProfileExperienceDTO.experienceHistoryList[index].file =
                  null;
                this.tabProfileExperienceDTO.experienceHistoryList[
                  index
                ].fileObjectList = [];
              }
            }
          }
        );
        this.tableResponse.setRecords(
          this.tabProfileExperienceDTO.experienceHistoryList
        );
        this.tableResponse.setTotalRecords(
          this.tabProfileExperienceDTO.experienceHistoryList.length
        );
        this.tableResponse.reloadClient();
      });
  }

  public doDelete(
    vendorExperienceHistoryDelete: VendorExperienceHistory
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileExperienceDTO.experienceHistoryList.forEach(
            (vendorExperienceHistory, index) => {
              if (vendorExperienceHistory === vendorExperienceHistoryDelete) {
                this.tabProfileExperienceDTO.experienceHistoryList.splice(
                  index,
                  1
                );
              }
            }
          );
          this.tableResponse.page.records =
            this.tabProfileExperienceDTO.experienceHistoryList;
          this.tableResponse.page.totalRecords =
            this.tabProfileExperienceDTO.experienceHistoryList.length;
          this.tableResponse.reloadClient();
        }
      });
  }

  public doView(vendorExperienceHistoryEdit: VendorExperienceHistory): void {
    const todo = 'detail';
    const vendorExperienceHistoryList =
      this.tabProfileExperienceDTO.experienceHistoryList;
    const vendorExperienceList = this.tabProfileExperienceDTO.experienceList;
    this.appPopupService.open(
      AppPopupExperienceComponent,
      {
        todo,
        vendorExperienceHistoryEdit,
        vendorExperienceHistoryList,
        vendorExperienceList
      },
      { size: 'lg' }
    );
  }

  public getDataFromRouterParams(): void {
    this.tabExperience = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_EXPERIENCE);
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.todo = this.global.routerParams.get('todo');
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
  }

  public getAndSetVendorToFormGroup(): void {
    this.tabExperience.formGroup.patchValue(this.companyProfileDTO);
    this.setStateReady();
  }

  public getVendorByIdFromServer(
    urlIndex: string,
    userId: number
  ): Observable<CompanyProfileDTO> {
    return this.httpClientService.post<CompanyProfileDTO>(
      urlIndex,
      new RouteRequestModel('', userId)
    );
  }
}
