import { BaseEntity } from '../base/base-entity';
import { Pr } from '../bean/pr';
import { Vendor } from '../bean/vendor';

export class PrVendorReferenceEntity extends BaseEntity {
  crudOperation: number;

  pr: Pr = new Pr();
  vendor: Vendor = new Vendor();
}
