import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Period } from '../../core/bean/period';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './period-edit-add.component.html'
})
export class PeriodEditAddComponent extends BaseModuleComponent {
  public periodId: number;

  constructor(translateService: TranslateService) {
    super('period', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.periodId = this.global.routerParams.get('periodId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null, Validators.required()],
      name: [null, Validators.required()],
      startMonth: [null, Validators.required()],
      endMonth: [null, Validators.required()],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<Period>(
        '/period/add-edit',
        new RouteRequestModel(this.todo, this.periodId)
      )
      .subscribe(period => {
        if (period != null) {
          this.formGroup.patchValue(period);
        }

        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url =
              this.todo === 'edit' ? '/period/update' : '/period/insert';
            const period: Period = this.global.copyFormAttributeToModel(
              new Period(),
              this.formGroup
            );

            this.httpClientService
              .post<Response<Period>>(url, period)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/period']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/period']);
  }
}
