import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Contract } from '../../core/bean/contract';
import { ContractClosed } from '../../core/bean/contract-closed';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { ContractHistoryCloseRequest } from './contract-history-close-request';

@Component({
  templateUrl: './contract-history-popup-close-contract.component.html'
})
export class ContractHistoryPopupCloseContractComponent extends BaseComponentComponent {
  @Input() contract: Contract;
  @Input() maxFile = 5;

  public fileUploader: FileUploader;
  public startDate: Date;

  constructor(public ngbActiveModal: NgbActiveModal) {
    super('contract-history');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.fileUploader = new FileUploader(
      '/contract-history/',
      'contract-history.popup.form.doc',
      this.global.appConstant.fileType.DOC_CLOSED_CONTRACT,
      false,
      this.maxFile
    );
    this.startDate = new Date(this.contract.startDate);
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      closureDate: [new Date(), Validators.required()],
      description: [null, Validators.required()],
      file: [null]
    });
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      const prompt = 'contract-history.confirmation.closeContract.prompt';
      const title = 'contract-history.confirmation.closeContract.title';
      this.global.modalService
        .confirmation(
          prompt,
          title,
          'app.button.no',
          'contract-history.button.yesImSure',
          null,
          false
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const request = new ContractHistoryCloseRequest();
            request.contract = this.contract;
            const contractClosed = new ContractClosed();
            contractClosed.processedDate = this.formGroup.value.closureDate;
            contractClosed.description = this.formGroup.value.description;
            request.contractClosed = contractClosed;
            request.fileObjectList = this.formGroup.value.file;

            this.httpClientService
              .post<Response<Contract>>(
                '/contract-history/close-contract',
                request
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.ngbActiveModal.close();
                  this.global.modalService
                    .saveSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/contract-history']);
                      } else {
                        this.router
                          .navigateByUrl('/', {
                            skipLocationChange: true
                          })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set(
                              'contractId',
                              this.contract.id
                            );
                            this.global.routerParams.set('todo', 'edit');
                            this.global.routerParams.set(
                              'header',
                              'contract-history.detail.contractClosure.header'
                            );
                            this.global.routerParams.set(
                              'urlBackOutside',
                              '/pages/contract-history'
                            );
                            this.router.navigate([
                              '/pages/contract-history/detail'
                            ]);
                          });
                      }
                    });
                }
              });
          } else {
            this.ngbActiveModal.close();
          }
        });
    }
  }
}
