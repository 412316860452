<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="user-toc.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-tab formArrayName="userTocList" bordered="true">
          <app-tab-item
            header="{{ userToc.value.lang.name }}"
            *ngFor="
              let userToc of formGroup
                | resolveFormArray: 'userTocList';
              let i = index
            "
            [formGroupName]="i"
          >
            <div class="form-group row text-sm-right mt-3">
              <label class="col-sm-2 control-label">{{
                'user-toc.form.text' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-wysiwyg formControlName="value" size="XL"> </app-wysiwyg>
              </div>
            </div>
            <div class="form-group row text-sm-right mt-3">
                <label class="col-sm-2 control-label">{{
                  'principle-permit-submission.form.uploadFile' | translate
                  }}</label>
                  <app-upload-x 
                    formControlName="file" 
                    [fileUploader]="fileUploader"
                  >
                  </app-upload-x>
            </div>
          </app-tab-item>
        </app-tab>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
