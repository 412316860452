import { PurchaseRequestBudget } from './../bean/purchase-request-budget';
import { ProcurementStatus } from './../bean/procurement-status';
import { BaseEntity } from './../base/base-entity';
export class PurchaseRequestDataEntity extends BaseEntity {
    purchaseRequestBudgetList: PurchaseRequestBudget[] = [];
    procurementStatus: ProcurementStatus;
    number: string;
    createdDate: Date;
    status: string;
}
