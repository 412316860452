import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Optional,
  Renderer2
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { ResolveFieldDataPipe } from '../../pipe/resolve-field-data.pipe';
import { ArrayUtils, TextUtils } from '../../utils';
@Component({
  selector: 'app-chosen',
  templateUrl: './app-chosen.component.html',
  styleUrls: ['./app-chosen.component.scss'],
  providers: makeProvider(AppChosenComponent)
})
export class AppChosenComponent
  extends BaseValueAccessorComponent<any>
  implements AfterViewInit
{
  generatedId = TextUtils.generateRandomString();
  optionsCopy: Array<any> = [];
  selectElement: any;
  selectSize: number;
  divSelectContainerElement: any;
  divAppChosenLabelElement: any;
  appChosenElement: any;
  isFocus = false;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public renderer2: Renderer2,
    public resolveFieldDataPipe: ResolveFieldDataPipe
  ) {
    super('app-chosen', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    if (!this.ISVIEW) {
      this.setFormControlValue();
      this.setOptionsCopy();
    } else {
      this.formControl.disable();
    }
  }

  ngAfterViewInit(): void {
    if (!this.ISVIEW) {
      this.setAppChosenElement();
      this.setDisabledAppChosenLabelElement();
    }
  }

  setFormControlValue(): void {
    if (!this.formControl.value || this.formControl.value === null) {
      this.formControl.patchValue([]);
    }
  }

  setDisabledAppChosenLabelElement(): void {
    if (this.disabled) {
      this.renderer2.addClass(this.divAppChosenLabelElement, 'disabled');
    }
  }

  setAppChosenElement(): void {
    this.appChosenElement = this.parentElement.children[0];
    this.divAppChosenLabelElement = this.appChosenElement.children[0];
    this.divSelectContainerElement = this.appChosenElement.children[1];
  }

  setOptionsCopy(): void {
    if (!this.disabled) {
      if (!this.optionList.isWaitFromServer) {
        this.optionsCopy = ArrayUtils.sortArray(
          this.optionList.getRequestValues(),
          this.optionList.sortBy || String(this.optionList.viewPath)
        );
      }

      this.optionList.requestValueChanges.subscribe((values: any) => {
        this.optionsCopy = ArrayUtils.sortArray(
          values,
          this.optionList.sortBy || String(this.optionList.viewPath)
        );
      });
    }
  }

  handleInput(event: any): void {
    if (!this.disabled) {
      event.target.size = event.target.value.length + 1;
      if (this.selectElement) {
        this.removeSelectElement();
      }
      const optionsFilter = ArrayUtils.filterArrayByText(
        this.optionList.getRequestValues(),
        event.target.value,
        String(this.optionList.viewPath)
      );
      this.optionsCopy =
        ArrayUtils.sortArray(optionsFilter, this.optionList.sortBy) || [];
      this.setSelectSize();
      this.builderSelectElement();
    }
  }

  handleClickAppChosenLabel(event: any): void {
    if (!this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      const inputElement = document.getElementById(this.generatedId + 'input');
      inputElement.focus();
      this.builder();
    }
  }

  builder(): void {
    if (this.selectElement) {
      this.removeSelectElement();
    } else {
      if (this.optionsCopy && this.optionsCopy.length > 0) {
        this.setSelectSize();
        this.builderSelectElement();
      }
    }
  }

  setSelectSize(): void {
    const optionsLength =
      this.optionsCopy.length -
      ((this.formControl.value && this.formControl.value.length) || []);
    this.selectSize =
      optionsLength > 5 ? 5 : optionsLength < 2 ? 2 : optionsLength;
  }

  builderSelectElement(): void {
    this.selectElement = this.renderer2.createElement('select');
    this.renderer2.addClass(this.selectElement, 'custom-select');
    this.builderSelectOption();
    this.renderer2.appendChild(
      this.divSelectContainerElement,
      this.selectElement
    );
  }

  builderSelectOption(): void {
    this.selectElement.size = this.selectSize;
    if (this.optionsCopy.length > 0) {
      this.optionsCopy.forEach((option: any) => {
        const formControlValues = this.formControl.value || [];
        const indexOfOption = formControlValues.findIndex(
          (value: any) => JSON.stringify(value) === JSON.stringify(option)
        );
        if (indexOfOption === -1) {
          const selectOption = this.renderer2.createElement('option');
          const optionValue = this.optionList.valuePath
            ? this.resolveFieldDataPipe.transform(
                option,
                this.optionList.valuePath
              )
            : option.id || option;
          selectOption.value = optionValue;
          const text = this.optionList.viewPath
            ? this.resolveFieldDataPipe.transform(
                option,
                String(this.optionList.viewPath)
              )
            : option.name || option;
          const selectOptionText = this.renderer2.createText(text);
          this.renderer2.listen(selectOption, 'click', (event: MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            this.isFocus = true;
            this.handleChangeSelect(option);
            this.removeSelectElement();
          });
          this.renderer2.appendChild(selectOption, selectOptionText);
          this.renderer2.appendChild(this.selectElement, selectOption);
        }
      });
    } else {
      const selectOption = this.renderer2.createElement('option');
      const selectOptionText = this.renderer2.createText(
        this.global.translateService.instant('app.info.notFound')
      );
      this.renderer2.appendChild(selectOption, selectOptionText);
      this.renderer2.addClass(this.selectElement, 'not-found');
      this.renderer2.appendChild(this.selectElement, selectOption);
    }
  }

  handleClickOption(option: any): void {
    const optionValues: Array<any> =
      this.formControl.value === null ? [] : this.formControl.value;
    optionValues.push(option);
    this.formControl.patchValue(optionValues);
  }

  handleChangeSelect(option: any): void {
    this.resetInputElement();
    this.divSelectContainerElement.classList.toggle('show');
    const formControlValues: Array<any> =
      this.formControl.value === null ? [] : this.formControl.value;
    formControlValues.push(option);
    this.formControl.patchValue(formControlValues);
    this.onChange.emit(formControlValues);
    this.optionList.setResponseValues(formControlValues);
    this.isFocus = false;
  }

  removeSelectElement(): void {
    this.renderer2.removeChild(
      this.divSelectContainerElement,
      this.selectElement
    );
    this.selectElement = null;
  }

  handleAppTaggingChange(): void {
    if (this.selectElement) {
      this.removeSelectElement();
    }
  }

  resetInputElement(): void {
    const inputElement: any = document.getElementById(
      this.generatedId + 'input'
    );
    inputElement.value = null;
    inputElement.size = 1;
  }

  handleFocusOut(): void {
    this.formControl.markAsTouched();
    this.resetInputElement();
  }

  @HostListener('document:click')
  handleDocumentClick(): void {
    if (!this.isFocus && this.selectElement) {
      this.removeSelectElement();
      this.isFocus = false;
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  public onKeyDown(event: KeyboardEvent): void {
    this.log.debug(event);
  }
}
