<div class="attendance-form">
  <div
    class="mb-4"
    [ngStyle]="{
      'max-width': '576px',
      margin: 'auto'
    }"
  >
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <!-- <div class="border"> -->
      <app-attendance-form-card
        header="attendance-form.title.attendanceList"
        color="PRIMARY"
        *ngIf="aanwijzing || !formLoading"
      >
        <span class="py-1">
          {{ aanwijzing.code }}
        </span>
        <span class="py-1">
          {{
            aanwijzing.periodStartDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
          -
          {{
            aanwijzing.periodEndDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
        </span>
        <span class="py-1">
          {{ aanwijzing.location }}
        </span>

        <span class="py-1 text-primary font-weight-bold">
          {{ aanwijzing.subject }}
        </span>
      </app-attendance-form-card>
      <div
        class="input-group input-group-search"
        [ngStyle]="{
          'padding-bottom': '12px',
          'max-width': '100%'
        }"
        *ngIf="aanwijzing || !formLoading"
      >
        <div class="input-group-prepend">
          <span class="input-group-text">
            <em class="pir pi-search"></em>
          </span>
        </div>
        <input
          #inputSearch
          type="text"
          class="form-control"
          [placeholder]="'attendance-form.search' | translate"
          [attr.aria-label]="'attendance-form.search' | translate"
          (input)="onInputSearch($event.target.value)"
        />
        <em
          class="pir pi-times btn-delete pi-3x text-danger"
          *ngIf="paginationRequestModel.globalFilter"
          (click)="doResetInputSearch(inputSearch)"
        ></em>
      </div>
      <app-attendance-form-card *ngIf="formLoading">
        <div class="promise-loading">
          <div class="loading"></div>
        </div>
      </app-attendance-form-card>
      <app-lazy-load
        [isLoading]="formLoading"
        target="body"
        loadingText="{{ 'attendance-form.msg.loading' | translate }}"
        (onScrollEnd)="onScrollEnd()"
      >
        <!-- <div style="height: 500px; width: 100%; display: block">
          <br />
          <h2>Test</h2>
        </div> -->
        <ng-container
          *ngIf="
            aanwijzingParticipantList && aanwijzingParticipantList.length > 0
          "
        >
          <app-attendance-form-card
            [header]="val.name"
            *ngFor="let val of aanwijzingParticipantList; index as i"
          >
            <ng-template #customHeader>
              <app-badge-catalog
                [color]="badgeColor[val.participantRole.code]"
                >{{ val.participantRole.name }}</app-badge-catalog
              >
            </ng-template>
            <span class="py-1">
              {{ val.participantRole.code === global.appConstant.pm.PARTICIPANT_ROLE_INTERNAL ? val.departmentName : val.companyName }}
            </span>
            <span class="py-1">
              {{ val.phoneRegion?.callingCode }}{{ val.phone }}
            </span>
            <span class="py-1">
              {{ val.email }}
            </span>
            <span class="py-1">
              {{
                val.attendanceDate
                  | date: global.config.parameterModel.datetimeFormatShort
              }}
            </span>
          </app-attendance-form-card>
        </ng-container>
        <ng-container
          *ngIf="
            !(aanwijzingParticipantList && aanwijzingParticipantList.length > 0)
          "
        >
          <app-attendance-form-card>
            <div class="no-data-table-wrapper lpy-4">
              <div class="ndtw-icon">
                <div
                  class="pvc"
                  [ngClass]="
                    paginationRequestModel.globalFilter
                      ? 'pv-search'
                      : 'pv-file-list'
                  "
                ></div>
              </div>
              <div class="ndtw-content">
                <ng-container
                  *ngIf="!formLoading && paginationRequestModel.globalFilter"
                >
                  <span class="ndtwc-text">
                    {{ 'attendance-form.noResultsFor' | translate }} "{{
                      paginationRequestModel.globalFilter
                    }}"
                  </span>
                </ng-container>
                <ng-container
                  *ngIf="!formLoading && !paginationRequestModel.globalFilter"
                >
                  <span class="ndtwc-text">
                    {{ 'attendance-form.noDataAvailable' | translate }}
                  </span>
                  <span class="ndtwc-text">
                    {{ 'attendance-form.letsTryToAddList' | translate }}
                  </span>
                </ng-container>
              </div>
            </div>
          </app-attendance-form-card>
        </ng-container>
      </app-lazy-load>
    </form>
  </div>
</div>
