import { BaseEntity } from '../base/base-entity';
import { AddressBook } from '../bean/address-book';
import { Order } from '../bean/order';

export class OrderShippingEntity extends BaseEntity {

  crudOperation: number;
  order: Order;
  address: AddressBook;
}
