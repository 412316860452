import { Adjustment } from '../../core/bean/adjustment';
import { ApprovalModelPrcsView } from '../../core/bean/approval-model-prcs-view';
import { TransactionLog } from '../../core/bean/transaction-log';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { SlaContractView } from '../../core/view/entity/bean/sla-contract-vew';
export class SLAContractResponse {
  slaContractView: SlaContractView;
  workflowModelPrcsMainList: WorkflowModelPrcs[] = [];
  workflowModelPrcsAddList: WorkflowModelPrcs[] = [];
  approvalModelPrcsViewList: ApprovalModelPrcsView[] = [];
  transactionLogList: TransactionLog[] = [];
  adjustmentList: Adjustment[] = [];
  totalRealizationWorkflowMain: string;
  totalRealizationWorkflowAdditional: string;
  totalRealizationApproval: string;
}
