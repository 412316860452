import { FileObject } from 'src/app/core/components/upload';
import { Dr } from '../../../core/bean/dr';
import { DrDetail } from '../../../core/bean/dr-detail';
import { SowPaymentTerm } from '../../../core/bean/sow-payment-term';
import { SowPaymentTermItem } from '../../../core/bean/sow-payment-term-item';

export class DrRequest {
  fileObjectList: FileObject[];
  dr: Dr;
  drDetail: DrDetail;
  sowPaymentTerm: SowPaymentTerm;
  sowPaymentTermItem: SowPaymentTermItem;
}
