import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { File } from '../../bean/file';
import { AppPopupService } from '../app-popup/app-popup.service';
import { AppPopupImageSlideshowComponent } from './app-popup-image-slideshow.component';

@Component({
  selector: 'app-image-slideshow',
  templateUrl: './app-image-slideshow.component.html',
  styleUrls: ['./app-image-slideshow.component.scss']
})
export class AppImageSlideshowComponent extends BaseComponentComponent {
  @Input() fileList: File[];
  @Input() isPopupEnable: boolean;
  @Input() popupTitle: string;
  @Input() parentModuleCode: string;
  public activeIndex = 0;
  public activeCarousel = 0;
  public imageUrl: string;
  public urlList = [];

  constructor(public appPopupService: AppPopupService) {
    super('');
  }

  public onInit(): void {
    if (this.fileList && this.fileList.length > 0) {
      this.imageUrl = this.global.config.BACKEND_ADDRESS + '/' + this.parentModuleCode +
        '/thumbnail/view/' + this.fileList[0].uploadedFileName + '/' + this.fileList[0].fileType.code;
    }
    this.createList();
  }

  public createList(): void {
    for (let i = 0; i < Math.ceil(this.fileList.length / 3); i++) {
      const tempList = [];
      for (let j = 0; j < 3; j++) {
        if (this.fileList[3 * i + j]) {
          tempList.push(this.fileList[3 * i + j]);
        } else {
          tempList.push('No Image');
        }
      }
      this.urlList.push(tempList);
    }
  }

  public onClickShowImage(): void {
    if (this.isPopupEnable) {
      this.appPopupService.open(
        AppPopupImageSlideshowComponent,
        {
          activeIndex: this.activeIndex,
          title: this.popupTitle,
          fileList: this.fileList,
          parentModuleCode: this.parentModuleCode
        },
        { size: 'xl' }
      );
    }
  }

  public onClickImage(url: File, indexA: number, indexB: number): void {
    this.activeIndex = 3 * +indexA + +indexB;
    this.imageUrl =
      this.global.config.BACKEND_ADDRESS +
      '/' + this.parentModuleCode + '/thumbnail/view/' +
      url.uploadedFileName +
      '/' +
      url.fileType.code;
  }

  public onNext(): void {
    if (this.fileList && this.fileList.length > 0) {
      this.activeIndex += 1;
      if (this.activeIndex > this.fileList.length - 1) {
        this.activeIndex = 0;
      }
      this.activeCarousel = Math.floor(this.activeIndex / 3);
      this.imageUrl =
        this.global.config.BACKEND_ADDRESS +
        '/' + this.parentModuleCode + '/thumbnail/view/' +
        this.fileList[this.activeIndex].uploadedFileName +
        '/' +
        this.fileList[this.activeIndex].fileType.code;
    }
  }

  public onPrevious(): void {
    if (this.fileList && this.fileList.length > 0) {
      this.activeIndex -= 1;
      if (this.activeIndex < 0) {
        this.activeIndex = this.fileList.length - 1;
      }
      this.activeCarousel = Math.floor(this.activeIndex / 3);
      this.imageUrl =
        this.global.config.BACKEND_ADDRESS +
        '/' + this.parentModuleCode + '/thumbnail/view/' +
        this.fileList[this.activeIndex].uploadedFileName +
        '/' +
        this.fileList[this.activeIndex].fileType.code;
    }
  }
}
