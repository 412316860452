import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { DrDetail } from '../../bean/dr-detail';
import { PaymentBillingItem } from '../../bean/payment-billing-item';
import { RouteRequestModel } from '../../model/route-request-model';
import { AppPopupService } from '../app-popup/app-popup.service';
import { TablePluginData } from '../table/interface/table-plugin-data';
import { TableResponseModel } from '../table/model/table-response-model';
import { AppDeliverableItemResponse } from './app-deliverable-item.response';
import { AppPopupDeliverableItemDetailComponent } from './app-popup-deliverable-item-detail.component';

@Component({
  selector: 'app-deliverable-item',
  templateUrl: './app-deliverable-item.component.html'
})
export class AppDeliverableItemComponent extends BaseComponentComponent {
  @Input() public paymentId: number;
  @Input() public paymentBillingId: number;
  public totalCost: number;
  public appDeliverableItemResponse: AppDeliverableItemResponse =
    new AppDeliverableItemResponse();
  public tableResponseProduct: TableResponseModel<DrDetail>;
  public tableResponseService: TableResponseModel<PaymentBillingItem>;

  constructor(
    public appPopupService: AppPopupService,
    public currency: CurrencyPipe,
    public translateService: TranslateService
  ) {
    super('app-deliverable-item');
  }
  public onInit(): void {
    this.buildFormGroup();
    this.buildTableResponseDrDetail();
    this.setDeliverableItemData();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      drDetailGoodsList: this.formBuilder.array([]),
      drDetailServiceList: this.formBuilder.array([])
    });
  }

  public get drDetailGoodsList(): FormArray {
    return this.formGroup.get('drDetailGoodsList') as FormArray;
  }

  public get drDetailServiceList(): FormArray {
    return this.formGroup.get('drDetailServiceList') as FormArray;
  }

  public setDeliverableItemData(): void {
    this.httpClientService
      .post<AppDeliverableItemResponse>(
        '/app-deliverable-item/index',
        new RouteRequestModel(
          this.paymentId ? 'payment' : 'termin',
          this.paymentId ? this.paymentId : this.paymentBillingId
        )
      )
      .subscribe(appDeliverableItemResponse => {
        this.appDeliverableItemResponse = appDeliverableItemResponse;
        this.appDeliverableItemResponse.paymentBillingItemGoodsList.forEach(
          paymentBilling => {
            const remaining =
              paymentBilling.sowPaymentTermItem.remainingQuantity;
            this.drDetailGoodsList.push(
              this.formBuilder.group({
                sowPaymentTermItem: paymentBilling.sowPaymentTermItem,
                receivedQuantity: paymentBilling.quantity,
                passQuantity: paymentBilling.quantity,
                remainingQuantity: remaining
              })
            );
          }
        );

        this.appDeliverableItemResponse.paymentBillingItemServiceList.forEach(
          billingItem => {
            const remaining = billingItem.sowPaymentTermItem.remainingQuantity;
            this.drDetailServiceList.push(
              this.formBuilder.group({
                sowPaymentTermItem: billingItem.sowPaymentTermItem,
                receivedQuantity: null,
                passQuantity: billingItem.quantity,
                remainingQuantity: remaining
              })
            );
          }
        );
        this.tableResponseProduct.setRecordList(this.drDetailGoodsList.value);
        this.tableResponseProduct.reload();
        this.tableResponseService.setRecordList(this.drDetailServiceList.value);
        this.tableResponseService.reload();
        this.setStateReady();
      });
  }

  public buildTableResponseDrDetail(): void {
    this.tableResponseProduct = new TableResponseModel(this.moduleCode, [
      {
        field: 'sowPaymentTermItem.prItemReleased.prItem.item.name',
        header: 'table.column.itemName',
        plugins: {
          name: 'hyperlink',
          onClick: this.doDetail.bind(this)
        }
      },
      {
        field: 'passQuantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'receivedQuantity',
        header: 'table.column.delivered',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'remainingQuantity',
        header: 'table.column.remaining',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (!tablePlugin.value) {
              tablePlugin.element.innerText = '0.00';
            } else {
              tablePlugin.element.innerText =
                this.global.converterService.convertDecimal(tablePlugin.value);
            }
          }
        },
        className: 'text-right'
      }
    ]);

    this.tableResponseService = new TableResponseModel(this.moduleCode, [
      {
        field: 'sowPaymentTermItem.prItemReleased.prItem.item.name',
        header: 'table.column.itemName',
        plugins: {
          name: 'hyperlink',
          onClick: this.doDetail.bind(this)
        }
      },
      {
        field: 'passQuantity',
        header: 'table.column.quantity',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (tablePlugin.value) {
              tablePlugin.element.innerText =
                this.global.converterService.convertDecimal(tablePlugin.value) +
                '%';
            }
          }
        },
        className: 'text-right'
      }
    ]);
  }

  public doDetail(paymentBillingItem): void {
    const sowPaymentTermItem = paymentBillingItem.sowPaymentTermItem;
    const stageOfWork = this.appDeliverableItemResponse.stageOfWork;
    const paymentTerm = this.appDeliverableItemResponse.paymentTerm;

    this.appPopupService
      .open(
        AppPopupDeliverableItemDetailComponent,
        { paymentBillingItem, sowPaymentTermItem, stageOfWork, paymentTerm },
        { size: 'xl' }
      )
      .subscribe();
  }
}
