import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { CertificatePeriod } from '../../core/bean/certificate-period';
import { DueDiligenceTemplate } from '../../core/bean/due-diligence-template';
import { Organization } from '../../core/bean/organization';
import { Vendor } from '../../core/bean/vendor';
import { VendorCategory } from '../../core/bean/vendor-category';
import { VendorCertificate } from '../../core/bean/vendor-certificate';
import { VendorHistory } from '../../core/bean/vendor-history';
import { VendorVerification } from '../../core/bean/vendor-verification';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { TabResponseModel } from '../../core/model/tab/tab-response-model';
import { Validators } from '../../core/validators';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { DueDiligenceTemplateResponse } from '../due-diligence-template/due-diligence-template-response';
import { TabProfileVerificationDTO } from './dto/tab-profile-verification.dto';
import { AppPopupDelegationComponent } from './popup/app-popup-delegation.component';

@Component({
  templateUrl: './tab-profile-verification.component.html'
})
export class TabProfileVerificationComponent extends BaseModuleComponent {
  public tabProfileVerificationDTO: TabProfileVerificationDTO =
    new TabProfileVerificationDTO();
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public today: Date = new Date();
  public vendorId: number;
  public vendorHistory: VendorHistory = new VendorHistory();
  public vendorCategoryList: VendorCategory[] = [];
  public dueDiligenceTemplateResponse: DueDiligenceTemplateResponse =
    new DueDiligenceTemplateResponse();
  public tabResponse: TabResponseModel;

  public approvalPathResponseModel: ApprovalPathResponseModel;
  public isApproval = false;
  public objectId: number;
  public workflowModelPrcs: WorkflowModelPrcs;
  public isRecommended = false;
  public certificateNumber: string = null;
  public approvalNote: string = null;

  public isShowDueDiligenceTemplate = false;
  public vendorHistoryId: number;
  public showNoteVendor = false;
  public isShowApprovalActivity = false;
  public isShowButtonDelegation = false;
  public isLoadingTooltip = false;

  public tabVerification: TabModel;

  public recommendationVerificationOptionList: OptionListModel<any> =
    new OptionListModel();
  public certificatePeriodOptionList: OptionListModel<CertificatePeriod> =
    new OptionListModel();
  public dueDiligenceTemplateOptionList: OptionListModel<DueDiligenceTemplate> =
    new OptionListModel();
  public vendorCategoryOptionList: OptionListModel<VendorCategory> =
    new OptionListModel();

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-verification', translate);
    this.tabVerification = this.companyProfileService
      .getTabResponse()
      .tabs.get('CHECKLIST');
    this.vendorHistoryId = this.global.routerParams.get('vendorHistoryId');
    this.isApproval = this.global.routerParams.get('isApproval');
    this.objectId = this.global.routerParams.get('objectId');
    this.tabResponse = this.companyProfileService.getTabResponse();
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
  }

  onInit(): void {
    if (this.tabVerification.routerParams.get('tabProfileVerificationDTO')) {
      this.tabProfileVerificationDTO = this.tabVerification.routerParams.get(
        'tabProfileVerificationDTO'
      );
      this.buildRecommendation();
      this.getAndSetOnBoardingToFormGroup();
      this.setCertificateIsView();
      this.setVerificationIsView();
      this.enableButtonDelegation();
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    this.httpClientService
      .get<TabProfileVerificationDTO>(
        '/tab-profile-verification/index/' + this.vendorHistoryId
      )
      .subscribe(tabProfileVerificationDTO => {
        this.tabProfileVerificationDTO = tabProfileVerificationDTO;
        this.tabVerification.routerParams.set(
          'tabProfileVerificationDTO',
          tabProfileVerificationDTO
        );
        this.buildRecommendation();
        this.buildFormGroup();
        this.global.routerParams['VERIFICATION'] =
          this.tabVerification.formGroup;
        this.getAndSetOnBoardingToFormGroup();
        this.setCertificateIsView();
        this.setVerificationIsView();
        this.enableButtonDelegation();
      });
  }

  public buildRecommendation(): void {
    const recommendationVerificationList = [
      { id: 0, name: 'Not Recommended' },
      { id: 1, name: 'Recommended' }
    ];
    this.recommendationVerificationOptionList.setRequestValues(
      recommendationVerificationList
    );
  }

  public buildFormGroup(): void {
    if (!this.tabVerification.isFormGroupReady) {
      this.tabVerification.formGroup = this.formBuilder.group({
        recommendationVerification: [null, Validators.required()],
        noteForVendor: [null, Validators.required()],
        approvalNote: [null],

        isDueDiligence: [false],
        multipleVendorCategory: [null, Validators.required()],
        certificatePeriod: [null, Validators.required()],
        certificateNumber: [null],
        adminNote: [null, Validators.required()],
        dueDiligenceTemplate: [null]
      });
    }
  }

  public setCertificateIsView(): void {
    if (
      this.tabProfileVerificationDTO.vendorCertificate.certificateStatus.code.toUpperCase() ===
      'ACTIVE'
    ) {
      if (this.tabVerification.formGroup.get('certificatePeriod')) {
        this.tabVerification.formGroup.get('certificatePeriod')['isView'] =
          true;
      }
    }
  }

  public enableButtonDelegation(): void {
    if (
      this.tabProfileVerificationDTO.vendorHistory.vendor.organization.id ===
      this.tabProfileVerificationDTO.vendorHistory.vendor
        .onBoardingByOrganization.id
    ) {
      this.isShowButtonDelegation = true;
    }
  }

  public showFormOption(param?: any): void {
    this.showNoteVendor = true;

    const formList: any[] = [
      'multipleVendorCategory',
      'certificatePeriod',
      'certificateNumber',
      'adminNote',
      'dueDiligenceTemplate'
    ];

    this.tabVerification.formGroup.patchValue({
      recommendationVerification: param
    });
    if (
      this.tabVerification.formGroup.get('recommendationVerification').value &&
      this.tabVerification.formGroup.get('recommendationVerification').value
        .name === 'Not Recommended'
    ) {
      this.isRecommended = false;
      formList.forEach(form => {
        // tidak bisa clearvalidators.
        this.tabVerification.formGroup.removeControl(form);
      });
    } else if (
      this.tabVerification.formGroup.get('recommendationVerification').value &&
      this.tabVerification.formGroup.get('recommendationVerification').value
        .name === 'Recommended'
    ) {
      this.isRecommended = true;
      this.isShowDueDiligenceTemplate =
        this.tabVerification.formGroup.get('isDueDiligence').value === true
          ? true
          : false;
      formList.forEach(form => {
        if (form === 'certificateNumber') {
          this.tabVerification.formGroup.addControl(
            form,
            new FormControl(null)
          );
        } else {
          this.tabVerification.formGroup.addControl(
            form,
            new FormControl(null, Validators.required())
          );
          this.tabVerification.formGroup.patchValue({ isDueDiligence: false });
        }
      });

      this.tabVerification.formGroup.patchValue({
        isDueDiligence:
          this.tabProfileVerificationDTO.vendorVerification &&
          this.tabProfileVerificationDTO.vendorVerification
            .dueDiligenceTemplate !== null
            ? true
            : false,
        multipleVendorCategory:
        this.tabProfileVerificationDTO.vendorCategory,
        certificatePeriod:
          this.tabProfileVerificationDTO.vendorCertificate.certificatePeriod
      });
      this.setCertificateIsView();

      this.isShowDueDiligenceTemplate =
        this.tabVerification.formGroup.value.isDueDiligence;
      if (this.isShowDueDiligenceTemplate) {
        this.dueDiligenceTemplateOptionList.setRequestValues(
          this.tabProfileVerificationDTO.dueDiligenceTemplateList
        );
        if (this.tabProfileVerificationDTO.vendorVerification !== null) {
          this.tabVerification.formGroup.patchValue({
            dueDiligenceTemplate:
              this.tabProfileVerificationDTO.vendorVerification
                .dueDiligenceTemplate
          });
        }
      } else {
        this.tabVerification.formGroup.removeControl('dueDiligenceTemplate');
      }

      this.checkIsApprovalWorkflow();

      this.showChangeTooltip();
    }
  }

  public showDueDiligenceTemplate(isDueDiligence?): void {
    this.isShowDueDiligenceTemplate =
      isDueDiligence || this.tabVerification.formGroup.value.isDueDiligence;
    this.log.debug('isDueDiligence ' + isDueDiligence);
    this.log.debug(
      'isShowDueDiligenceTemplate ' + this.isShowDueDiligenceTemplate
    );

    if (this.isShowDueDiligenceTemplate) {
      this.tabVerification.formGroup.addControl(
        'dueDiligenceTemplate',
        new FormControl(null, Validators.required())
      );
      this.dueDiligenceTemplateOptionList.setRequestValues(
        this.tabProfileVerificationDTO.dueDiligenceTemplateList
      );
      if (this.tabProfileVerificationDTO.vendorVerification !== null) {
        this.tabVerification.formGroup.patchValue({
          dueDiligenceTemplate:
            this.tabProfileVerificationDTO.vendorVerification
              .dueDiligenceTemplate
        });
      }
    } else {
      this.tabVerification.formGroup.removeControl('dueDiligenceTemplate');
    }
  }

  public getAndSetOnBoardingToFormGroup(): void {
    this.certificatePeriodOptionList.setRequestValues(
      this.tabProfileVerificationDTO.certificatePeriodList
    );
    this.vendorCategoryOptionList.setRequestValues(
      this.tabProfileVerificationDTO.vendorCategoryList
    );
    this.setOnBoardingToFormGroup(this.tabProfileVerificationDTO);
    this.setStateReady();
  }

  public setVendorCategoryOptionList(
    tabProfileVerificationDTO: TabProfileVerificationDTO
  ): void {
    if (
      tabProfileVerificationDTO.vendorCategoryList !== null ||
      tabProfileVerificationDTO.vendorCategoryList.length !== 0
    ) {
      this.vendorCategoryOptionList.setRequestValues(
        tabProfileVerificationDTO.vendorCategoryList
      );
    }
  }

  public setOnBoardingToFormGroup(
    tabProfileVerificationDTO: TabProfileVerificationDTO
  ): void {
    this.setVendorCategoryOptionList(tabProfileVerificationDTO);

    if (tabProfileVerificationDTO.vendorHistory != null) {
      this.tabProfileVerificationDTO = tabProfileVerificationDTO;
      this.vendorHistory = this.tabProfileVerificationDTO.vendorHistory;
      this.workflowModelPrcs = this.vendorHistory.vendor.workflowModelPrcs;

      const recomendationVerificationList =
        this.recommendationVerificationOptionList.getRequestValues();

      this.tabVerification.formGroup.patchValue({
        certificateNumber:
          this.tabProfileVerificationDTO.vendorCertificate.certificateNumber
      });
      this.certificateNumber =
        this.tabProfileVerificationDTO.vendorCertificate.certificateNumber;

      if (this.tabProfileVerificationDTO.vendorVerification !== null) {
        this.showFormOption(
          this.tabProfileVerificationDTO.vendorVerification.isRecommended
            ? recomendationVerificationList[1]
            : recomendationVerificationList[0]
        ); // show form based on isRecommended

        this.tabVerification.formGroup.patchValue({
          isDueDiligence:
            this.tabProfileVerificationDTO.vendorVerification
              .dueDiligenceTemplate !== null
              ? true
              : false,
          recommendationVerification: this.tabProfileVerificationDTO
            .vendorVerification.isRecommended
            ? recomendationVerificationList[1]
            : recomendationVerificationList[0],
          noteForVendor:
            this.tabProfileVerificationDTO.vendorVerification.vendorNote,
          adminNote:
            this.tabProfileVerificationDTO.vendorVerification.adminNote,
          certificatePeriod:
            this.tabProfileVerificationDTO.vendorCertificate.certificatePeriod,
          multipleVendorCategory:
            this.tabProfileVerificationDTO.vendorCategory
        });

        // approvalNote show only if workflow_status = REVISION, already set by backend
        this.tabVerification.formGroup.patchValue({
          approvalNote: this.tabProfileVerificationDTO.approvalNote
        });

        if (this.tabProfileVerificationDTO.vendorVerification.isRecommended) {
          this.showDueDiligenceTemplate();
          this.showChangeTooltip();
        }
        this.checkIsApprovalWorkflow();
      } else {
        this.showFormOption(recomendationVerificationList[0]);
      }
    }
  }

  public setVerificationIsView(): void {
    if (
      !this.tabProfileVerificationDTO.vendorHistory.vendor.vendorActivityStatus.code.includes(
        'VERIFICATION'
      ) ||
      this.tabProfileVerificationDTO.isUserApproval
    ) {
      this.tabVerification.setIsView(true);
    } else if (
      this.global.userSession.user.organization.id !==
      this.tabProfileVerificationDTO.vendorHistory.vendor
        .onBoardingByOrganization.id
    ) {
      this.tabVerification.setIsView(true);
    }
  }

  public checkIsApprovalWorkflow(): void {
    if (
      this.tabProfileVerificationDTO.vendorHistory.vendor.vendorActivityStatus.code.includes(
        'VERIFICATION'
      ) &&
      this.tabProfileVerificationDTO.vendorHistory.vendor.workflowPrcs
        .workflowStatus &&
      this.tabProfileVerificationDTO.vendorHistory.vendor.workflowPrcs.workflowStatus.code
        .toUpperCase()
        .includes('REVISI')
    ) {
      this.isShowApprovalActivity = true;
      this.tabVerification.formGroup.get('isDueDiligence').setIsView(true);
      this.tabVerification.formGroup.get('approvalNote').setIsView(true);
      if (this.tabProfileVerificationDTO.vendorVerification.isRecommended) {
        if (this.isShowDueDiligenceTemplate) {
          this.tabVerification.formGroup.get('dueDiligenceTemplate')['isView'] =
            true;
        }
      }
    } else {
      this.isShowApprovalActivity = false;
    }
  }

  public showChangeTooltip(): void {
    this.isLoadingTooltip = true;
    if (
      this.tabVerification.formGroup.get('dueDiligenceTemplate') &&
      this.tabVerification.formGroup.get('dueDiligenceTemplate').value
    ) {
      const dueDiligenceTemplateId = this.tabVerification.formGroup.get(
        'dueDiligenceTemplate'
      ).value.id;
      const todo = 'edit';

      this.httpClientService
        .post<DueDiligenceTemplateResponse>(
          '/due-diligence-template/add-edit',
          new RouteRequestModel(todo, dueDiligenceTemplateId)
        )
        .subscribe(dueDiligenceTemplateResponse => {
          this.isLoadingTooltip = false;
          this.dueDiligenceTemplateResponse = dueDiligenceTemplateResponse;
        });
    } else {
      this.dueDiligenceTemplateResponse = new DueDiligenceTemplateResponse();
      this.isLoadingTooltip = false;
    }
  }

  public doPrevious(): void {
    const previousTab = this.tabResponse.currentTab.previousTab;
    this.tabResponse.setCurrentTab(previousTab);
    this.router.navigate([previousTab.routeUrl]);
  }

  public doSave(): void {
    this.tabResponse.setIsSubmitted();
    if (this.tabVerification.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            if (this.companyProfileDTO.vendorHistory) {
              this.vendorHistory.name =
                this.companyProfileDTO.vendorHistory.name;
            }
            this.tabProfileVerificationDTO.vendorHistory = this.vendorHistory;
            if (this.companyProfileDTO.tabProfileDocumentDTO) {
              this.tabProfileVerificationDTO.vendorDocGroupList =
                this.companyProfileDTO.tabProfileDocumentDTO.vendorDocGroupList;
              this.tabProfileVerificationDTO.vendorAdditionalDocHistoryList =
                this.companyProfileDTO.tabProfileDocumentDTO.vendorAdditionalDocHistoryList;
            }
            const vendorVerification: VendorVerification =
              new VendorVerification();
            const vendorCertificate: VendorCertificate =
              new VendorCertificate();

            vendorVerification.isRecommended =
              this.tabVerification.formGroup.get('recommendationVerification')
                .value.id === 1
                ? true
                : false;
            vendorVerification.vendorNote =
              this.tabVerification.formGroup.get('noteForVendor').value;
            vendorVerification.vendorHistory =
              this.tabProfileVerificationDTO.vendorHistory;

            if (vendorVerification.isRecommended) {
              vendorVerification.dueDiligenceTemplate = this
                .isShowDueDiligenceTemplate
                ? this.tabVerification.formGroup.get('dueDiligenceTemplate')
                    .value
                : null;
              vendorVerification.adminNote =
                this.tabVerification.formGroup.get('adminNote').value;
              vendorCertificate.certificatePeriod =
                this.tabVerification.formGroup.get('certificatePeriod').value;
              vendorCertificate.certificateNumber = this.certificateNumber;
              vendorCertificate.certificateStatus =
                this.tabProfileVerificationDTO.vendorCertificate.certificateStatus;
              vendorCertificate.id =
                this.tabProfileVerificationDTO.vendorCertificate.id;
                
              this.tabProfileVerificationDTO.vendorCertificate =
                vendorCertificate;
            }

            this.tabProfileVerificationDTO.vendorVerification =
              vendorVerification;
            this.httpClientService
              .post<Response<TabProfileVerificationDTO>>(
                '/tab-profile-verification/update',
                this.tabProfileVerificationDTO
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/on-boarding']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else if (
                  response.statusText ===
                  'tab-profile-verification.validation.documentVerified'
                ) {
                  this.tabProfileVerificationDTO = response.body;
                  this.setStateReady();
                  this.global.routerParams.set('isCheckDocument', true);
                  this.tabResponse.setCurrentTab(
                    this.tabResponse.tabs.get('DOC')
                  );
                  this.global.alertService.showErrorOnNextRoute(
                    'tab-profile-verification.validation.documentVerified'
                  );
                  this.router.navigate([
                    this.tabResponse.tabs.get('DOC').routeUrl
                  ]);
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doDelegate(): void {
    const vendor: Vendor = this.tabProfileVerificationDTO.vendorHistory.vendor;
    const onBoardingByOrganizationList: Organization[] =
      this.tabProfileVerificationDTO.organizationList;
    this.appPopupService.open(
      AppPopupDelegationComponent,
      { vendor, onBoardingByOrganizationList },
      { size: 'lg', backdrop: 'static' }
    );
  }

  public changeVendorCategory(): void {
    this.tabProfileVerificationDTO.vendorCategory = this.tabVerification.formGroup.get(
      'multipleVendorCategory'
    ).value;
  }
}
