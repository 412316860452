import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { File } from 'src/app/core/bean/file';
import { AppPopupImageSlideshowComponent } from 'src/app/core/components/app-image-slideshow/app-popup-image-slideshow.component';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { PriceReferenceView } from 'src/app/core/view/entity/bean/price-reference-view';

@Component({
  templateUrl: './app-popup-oep-price-reference.component.html'
})
export class AppPopupPriceReferenceComponent extends BaseModuleComponent {
  @Input() itemCode: string;
  @Input() itemName: string;
  public header: string;
  @Output() onChange: EventEmitter<PriceReferenceView[]> = new EventEmitter();

  public tableResponse: TableResponseModel<PriceReferenceView>;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('oep', translateService);
  }

  onInit(): void {
    this.header =
      this.translateService.instant('oep.popup.title') + ' - ' + this.itemName;
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      itemCode: [this.itemCode]
    });
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
    this.formGroup.get('fromDate').reset();
    this.formGroup.get('untilDate').reset();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'file',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value) {
                  return tablePluginData.value;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'item.itemType.code',
              onClick: this.onClickShowImage.bind(this)
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'date',
          header: 'table.column.dateOfPrice',
          plugins: 'date'
        },
        {
          field: 'source',
          header: 'table.column.source'
        },
        {
          field: 'vendorName',
          header: 'table.column.vendorName'
        },
        {
          field: 'price',
          header: 'table.column.price',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        }
      ],
      { recordPerPage: 5 }
    );
    this.tableResponse.setCustomData(this.formGroup.value);
  }

  public doChoose(): void {
    if (this.tableResponse.selectedRow.getSelectedRecordList().length !== 0) {
      this.onChange.emit(
        this.tableResponse.selectedRow.getSelectedRecordList()
      );
    } else {
      this.global.alertService.showError(
        'oep.validation.chooseBudget',
        '.main-row-popup-price-reference'
      );
    }
  }

  public onClickShowImage(priceReferenceView: PriceReferenceView): void {
    const fileList: File[] = [];
    fileList.push(priceReferenceView.file);
    this.appPopupService.open(
      AppPopupImageSlideshowComponent,
      {
        activeIndex: 0,
        title: this.itemName,
        fileList: fileList,
        parentModuleCode: 'oep'
      },
      { size: 'xl' }
    );
  }
}
