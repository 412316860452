<app-layout
  [isLoading]="formLoading"
  header="procurement-reg-buyer.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="!formLoading && isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      *ngIf="todo !== 'add'"
      class="cml-2"
      [color]="badgeColor[procurement.procurementWorklistStatus.code]"
    >
      {{ getTranslateKey(procurement.procurementWorklistStatus) | translate }}
    </app-badge>
    <app-badge *ngIf="todo === 'add'" color="INFO" class="cml-2">
      {{ getTranslateKey(procurement.procurementWorklistStatus) | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          (procurement?.procurementStatus?.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT)
        "
      >
        {{ canceledOrFailedMessage | translate }}
      </app-alert-x>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="procurement-reg-buyer.procDetail.general.title"
          tips="procurement-reg-buyer.procDetail.general.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-item-request
          [objectList]="procurementItemList"
          [isView]="true"
          [isShowPrice]="true"
          [itemTypeList]="itemTypeList"
        >
        </app-item-request>

        <app-card>
          <app-table-xx
            [model]="tableResponse"
            header="procurement-reg-buyer.registeredVendor.general.title"
            tips="procurement-reg-buyer.registeredVendor.general.tips"
            stringUrl="/procurement-reg-buyer/index-registered-vendor"
            (onClick)="doClick($event)"
          >
            <ng-template #headerButtons>
              <app-button
                *ngIf="
                  todo !== 'view' &&
                  procurement.maxInvitingVendor > 0 &&
                  procurement.procurementMethod.procurementRegType.code ===
                    global.appConstant.pm.PROC_REG_TYPE_INVITATION &&
                  registeredProcVendorViewList.length <
                    procurementParticipant.maximumParticipant
                "
                (onClick)="doInviteVendor()"
              >
                <em class="pir pi-plus-circle"></em>&nbsp;
                {{
                  'procurement-reg-buyer.registeredVendor.button.inviteVendor'
                    | translate
                }}
              </app-button>
            </ng-template>
            <ng-container *ngIf="isWarningAlert()">
              <ng-template #alertWrapper>
                <app-alert-x color="WARNING">{{
                  'procurement-reg-buyer.alert.warn.consider' | translate
                }}</app-alert-x>
              </ng-template>
            </ng-container>
          </app-table-xx>
        </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            procurementRegBuyerResponse.approvalPathResponseModel &&
            !procurementRegBuyerResponse.isUserApproval &&
            (procurement.workflowModelPrcs.code !== 'PROCUREMENT-REG-BUYER' ||
              (procurement.workflowModelPrcs.code === 'PROCUREMENT-REG-BUYER' &&
                procurement.workflowPrcs?.workflowStatus?.code ===
                  global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION))
          "
          [workflowPrcs]="
            procurementRegBuyerResponse?.procurement?.workflowPrcs
          "
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="
            procurementRegBuyerResponse.approvalPathResponseModel
          "
        >
        </app-approval-monitoring>

        <div class="button-group button-group-center" *ngIf="!formGroup.isView">
          <app-button (onClick)="doSubmit()" [disabled]="formSaving">{{
            'app.button.submit' | translate
          }}</app-button>
        </div>

        <app-approval-prcs-x
          *ngIf="!formLoading && procurementRegBuyerResponse.isUserApproval"
          [workflowModelPrcs]="
            procurementRegBuyerResponse.procurement.workflowModelPrcs
          "
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
