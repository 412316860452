import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { ContactComponent } from './contact.component';
const routes = [{ path: '', component: ContactComponent, pathMatch: 'full' }];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ContactComponent]
})
export class ContactModule {}
