import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Coa } from './../../core/bean/coa';
import { WebSocketBudgetService } from './../budget-allocation-bjb/websocket-budget.service';

@Component({
  templateUrl: './coa-bjb.component.html',
  providers: [{ provide: WebSocketBudgetService }]
})
export class CoaBjbComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Coa>;
  public isSyncRunning = false;

  constructor(
    translateService: TranslateService,
    public websocketBudgetService: WebSocketBudgetService
  ) {
    super('coa-bjb', translateService);
  }

  public onInit(): void {
    this.initializeWebSocketConnection();
    this.isSynchronizeRunning();
  }

  public isSynchronizeRunning(): void {
    this.httpClientService
      .get<Boolean>('/budget-allocation-bjb/is-budget-synchronize-running/onlyBudget')
      .subscribe((isSynchronizeRunning: boolean) => {
        this.isSyncRunning = isSynchronizeRunning;
        this.setStateReady();
        if (!this.isSyncRunning) {
          this.buildTableResponse();
        }
      });
  }

  public initializeWebSocketConnection(): void {
    this.websocketBudgetService.connect().then(result => {
      if (!this.isSyncRunning && result) {
        this.tableResponse.reload();
      } else if (this.isSyncRunning) {
        this.isSyncRunning = false;
        this.buildTableResponse();
      }
      this.websocketBudgetService.disconnect();
      this.initializeWebSocketConnection();
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.col.code', customClass: 'text-center' },
      { field: 'name', header: 'table.col.name' }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/coa-bjb/add']);
  }

  public doEdit(coa: Coa): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('coaId', coa.id);
    this.router.navigate(['/pages/coa-bjb/edit']);
  }

  public doDelete(coa: Coa): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<Coa>>('/coa-bjb/delete', coa)
            .subscribe((response: Response<Coa>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
