<form class="form-horizontal" *ngIf="!model.isLoading">
  <h5 class="separator">{{ 'tab-company-data.form-group.basicInformation' |
    translate }}</h5>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-company-data.form.registrationNumber' | translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.registrationNumber !== null ?
      vendorHistory.registrationNumber : '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{ 'tab-company-data.form.companyName'
      | translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.name !== null ? vendorHistory.name : '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-company-data.form.abbreviation' | translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.abbreviation !== null ? vendorHistory.abbreviation :
      '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-company-data.form.trademarkName' | translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.trademarkName !== null ? vendorHistory.trademarkName :
      '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{ 'tab-company-data.form.taxNumber' |
      translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.taxNumber !== null ? vendorHistory.taxNumber : '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{ 'tab-company-data.form.pkpNumber' |
      translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.pkpNumber !== null ? vendorHistory.pkpNumber : '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-company-data.form.certificateOfEstablishmentNo' | translate
      }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.certificateOfEstablishment !== null ?
      vendorHistory.certificateOfEstablishment : '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-company-data.form.registeredOn' | translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.organization?.name != null ?
      vendorHistory.organization?.name : '-' }}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-company-data.form.companyEmail' | translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.email !== null ? vendorHistory.email : '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{ 'tab-company-data.form.phone' |
      translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.phone ? vendorHistory.phoneRegion.callingCode +
      vendorHistory.phone : '-'}}
    </div>
  </div>

  <h5 class="separator">{{ 'tab-company-data.form-group.vendorType' | translate
    }}</h5>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{ 'tab-company-data.form.ownership' |
      translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.vendorOwnership?.name !== null ?
      vendorHistory.vendorOwnership.name : '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{ 'tab-company-data.form.entity' |
      translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.vendorEntity?.name !== null ?
      vendorHistory.vendorEntity.name : '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{ 'tab-company-data.form.location' |
      translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.vendorLocation?.name !== null ?
      vendorHistory.vendorLocation.name : '-'}}
    </div>
  </div>

  <h5 class="separator">{{ 'tab-company-data.form-group.companyData' | translate
    }}</h5>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-company-data.form.qualification' | translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.qualification?.name !== null ?
      vendorHistory.qualification.name : '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-company-data.form.establishmentYear' | translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.establishmentYear !== null ?
      vendorHistory.establishmentYear : '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{ 'tab-company-data.form.employee' |
      translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.totalEmployee !== null ? vendorHistory.totalEmployee :
      '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{ 'tab-company-data.form.website' |
      translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.website !== null ? vendorHistory.website : '-'}}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-company-data.form.productLocations' | translate }}</label>
    <div class="col-sm-9 text-left">
      {{ tabCompanyDataDto?.productLocationNames || '-' }}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{ 'tab-company-data.form.industry' |
      translate }}</label>
    <div class="col-sm-9 text-left">
      {{ tabCompanyDataDto?.industryNames || '-' }}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{ 'tab-company-data.form.keywords' |
      translate }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.keyword !== null ? vendorHistory.keyword : '-'}}

    </div>
  </div>

  <div class="form-group row">
    <div class="col-lg-12">
      <h5 class="separator">{{ 'tab-company-data.form-group.address' | translate
        }} <span class="control-label"></span></h5>
      <app-table #selectorVendorAddress [isServerSide]="true"
        stringUrl="/tab-company-data/index-address"
        [model]="tableAddressResponse">
        <ng-template #actionButtons let-rowData>
          <ng-container>
            <app-button size="TN" (onClick)="doDetail(rowData)"
              title="{{ 'app.tooltip.detail' | translate }}">
              <span class="fas fa-search"></span>
            </app-button>
          </ng-container>
        </ng-template>
      </app-table>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-lg-12">
      <h5 class="separator">{{ 'tab-company-data.form-group.PIC' | translate }}
        <span class="control-label"></span></h5>
      <app-table #selectorVendorPIC [isServerSide]="true"
        stringUrl="/tab-company-data/index-pic" [model]="tablePICResponse">
      </app-table>
    </div>
  </div>
</form>