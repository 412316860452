import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAlertXModule } from '../app-alert-x/app-alert-x.module';
import { AppFullfilledComponent } from './app-fullfilled.component';

@NgModule({
  imports: [VendorModule, AppAlertXModule],
  declarations: [AppFullfilledComponent],
  entryComponents: [AppFullfilledComponent],
  exports: [AppFullfilledComponent]
})
export class AppFullfilledModule {}
