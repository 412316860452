import { BaseDTO } from '../../../core/base/base-dto';
import { VendorAdditionalDoc } from '../../../core/bean/vendor-additional-doc';
import { VendorAdditionalDocHistory } from '../../../core/bean/vendor-additional-doc-history';
import { VendorDocGroup } from '../../../core/bean/vendor-doc-group';
import { VendorDocTemplate } from '../../../core/bean/vendor-doc-template';

export class TabProfileDocumentDTO extends BaseDTO {
    indexDocMasterSIUP: number;
    indexDocMasterTDP: number;
    indexDocGroupSIUP: number;
    indexDocGroupTDP: number;
    indexDocGroupNIB: number;
    indexDocMasterNIB: number;
    vendorDocGroupList: VendorDocGroup[];
    vendorDocTemplateList: VendorDocTemplate[];
    vendorAdditionalDocHistoryList: VendorAdditionalDocHistory[];
    vendorAdditionalDocList: VendorAdditionalDoc[];

    isSIUJKMandatory: boolean;
    isFCIUPJTKMandatory: boolean;
}
