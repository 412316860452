<app-popup
  header="{{
    ('refutation-vendor.popup.title' | translate) +
      ' ' +
      procurementVendor.vendor.name
  }}"
  [isShowHeaderCloseButton]="true"
  [isLoading]="formLoading"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="popup-choose-vendor">
      <app-table-xx
        [model]="tableResponse"
        *ngIf="!formLoading"
        stringUrl="/refutation-vendor/index/quotation-item-reference"
      >
        <ng-template #headerFilter>
          <div class="app-table-filter-item" style="height: 34px">
            <div class="form-group row text-sm-left">
              <div class="col text-left" [ngStyle]="{ 'min-width': '10rem' }">
                <app-dropdown-select
                  *ngIf="!formLoading"
                  size="XL"
                  formControlName="itemType"
                  type="COMBOBOX"
                  [optionList]="itemTypeOptionList"
                  (onChange)="onChangeItemType()"
                  container="body"
                  placeholder="{{
                    'refutation-vendor.placeholder.type' | translate
                  }}"
                ></app-dropdown-select>
              </div>
            </div>
          </div>
        </ng-template>
      </app-table-xx>
    </div>
  </form>
</app-popup>
