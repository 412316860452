import { EventEmitter, Injectable } from '@angular/core';
import { BaseChatEntity } from '../../core/base/base-chat-entity';
import { WebSocketService } from '../../core/services/websocket/websocket.service';

@Injectable({ providedIn: 'root' })
export class ChatWebsocketService extends WebSocketService<BaseChatEntity> {
  constructor() {
    super(`chat`);
  }
  public chat: EventEmitter<BaseChatEntity> = new EventEmitter();

  protected onReceivedMessage(message: string): Promise<any> {
    return new Promise(resolve => {

    const baseChat: BaseChatEntity = JSON.parse(message);
    if (baseChat) {
        this.chat.emit(baseChat);
        resolve(this.setReceivedMessage(baseChat));
      }
    });
  }
}
