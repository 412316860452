<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="delegation.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'delegation.form.nameApprovel' | translate }}</label>
          <div class="col-sm-9 text-left">
            {{ user.name }}
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'delegation.form.user' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-choose formControlName="delegatedUserName"
              (onClick)="doChooseUser($event)"></app-choose>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'delegation.form.startDate' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-date-picker formControlName="startDate" minDate="currentDate" 
            (onChange)="doSetMinDateEndDate($event)">
            </app-date-picker>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'delegation.form.endDate' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-date-picker formControlName="endDate" minDate="startDate">
            </app-date-picker>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'delegation.form.uploadDocFile' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-upload formControlName="docFile" [fileUploader]="fileUploader">
            </app-upload>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button color="SECONDARY" (onClick)="doCancel()"
            [disabled]="formSaving">{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate }}</app-button>
        </div>
      </form>
      <div class="mt-4" *ngIf="todo == 'edit'">
        <app-card header="delegation.titleDelegationLog">
          <app-table [tableResponse]="tableResponse" [isServerSide]="true"
            stringUrl="/delegation/log" customData="{{ user.id }}">
            <ng-template #actionButtons let-rowData>
              <app-button *ngIf="rowData.isActive" size="TN" (onClick)="doDeactivateDelegation(rowData)"
                title="{{ 'delegation.tooltip.deactivate' | translate }}">
                <em class="fas fa-power-off"></em>
              </app-button>
            </ng-template>
          </app-table>
        </app-card>
      </div>
    </app-card>
  </div>
</div>