import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppItemRequestModule } from 'src/app/core/components/app-item-request/app-item-request.module';
import { AppOfficialReportModule } from 'src/app/core/components/app-official-report/app-official-report.module';
import { AppProcurementInfoModule } from 'src/app/core/components/app-procurement-info/app-procurement-info.module';
import { AppTableModule } from 'src/app/core/components/app-table/app-table.module';
import { AppWorkflowStepInfoModule } from 'src/app/core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppPopupPriceVendorComponent } from './app-popup-price-vendor.component';
import { PriceEvaluationVendorEditComponent } from './price-evaluation-vendor-edit.component';
import { PriceEvaluationVendorComponent } from './price-evaluation-vendor.component';

const routes: Routes = [
  {
    path: '',
    component: PriceEvaluationVendorComponent,
    data: { breadcrumb: 'price-evaluation-vendor.breadcrumb.index' }
  },
  {
    path: 'edit',
    component: PriceEvaluationVendorEditComponent,
    data: { breadcrumb: 'price-evaluation-vendor.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppProcurementInfoModule,
    AppWorkflowStepInfoModule,
    AppItemRequestModule,
    AppTableModule,
    AppOfficialReportModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    PriceEvaluationVendorComponent,
    PriceEvaluationVendorEditComponent,
    AppPopupPriceVendorComponent
  ],
  entryComponents: [AppPopupPriceVendorComponent]
})
export class PriceEvaluationVendorModule {}
