import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
@Component({
  templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent extends BaseModuleComponent {
  public title: string;
  public paragraph: string;
  public validOrInvalid: number;

  constructor(
    translateService: TranslateService,
    public activatedRoute: ActivatedRoute
  ) {
    super('confirmation', translateService);
  }
  public onInit(): void {
    this.setParam();
    this.setStateReady();
  }

  public setParam(): void {
    this.title = this.global.routerParams.get('title');
    this.paragraph = this.global.routerParams.get('paragraph');
    this.validOrInvalid = this.global.routerParams.get('validOrInvalid');
  }

  doCancel(): void {
    if (this.global.config.parameterModel.isInterfacing) {
      window.location.href = this.global.config.BACKEND_ADDRESS.replace(
        '/api',
        ''
      );
    } else {
      this.router.navigate(['/portal']);
    }
  }
}
