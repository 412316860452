<app-popup header="app-popup-doc-group.title">
  <app-table
    [tableResponse]="tableResponse"
    [isServerSide]="true"
    customData="{{ customData }}"
    stringUrl="{{ urlString }}"
  >
    <ng-template #actionButtons let-rowData>
      <button
        type="button "
        class="btn btn-primary ml-2"
        (click)="doChoose(rowData)"
      >
        {{ 'app.button.select' | translate }}
      </button>
    </ng-template>
  </app-table>
</app-popup>
