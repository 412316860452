import { Vendor } from '../../core/bean/vendor';
import { Wanprestasi } from '../../core/bean/wanprestasi';
import { FileObject } from '../../core/components/upload';

export class WanprestasiResponse {
    vendor: Vendor = new Vendor();
    wanprestasiList: Wanprestasi[] = [];
    wanprestasiByProcurementList: Wanprestasi[] = [];
    wanprestasiByContractList: Wanprestasi[] = [];
    fileObjectList: FileObject[] = [];

}
