<app-layout [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form [formGroup]="formGroup" *ngIf="!formLoading">
        <app-card
          header="sla-pm.detail"
          tips="sla-pm.details.tips"
          *ngIf="historyType === 'procurement'"
        >
          <app-sla-info
            [workflowPrcsId]="workflowPrcsId"
            [historyType]="historyType"
          >
          </app-sla-info>
          <p></p>
        </app-card>
      </form>
      <form [formGroup]="formGroup" *ngIf="!formLoading">
        <app-card
          header="sla-pm.detail"
          tips="sla-pm.details.tips"
          *ngIf="historyType === 'purchase request'"
        >
          <app-sla-info
            [workflowPrcsId]="workflowPrcsId"
            [historyType]="historyType"
          >
          </app-sla-info>
          <p></p>
        </app-card>
      </form>
      <form [formGroup]="formGroup" *ngIf="!formLoading">
        <app-card
          header="sla-pm.detail"
          tips="sla-pm.details.tips"
          *ngIf="historyType === 'rfp'"
        >
          <app-sla-info
            [workflowPrcsId]="workflowPrcsId"
            [historyType]="historyType"
          >
          </app-sla-info>
          <p></p>
        </app-card>
      </form>
      <form [formGroup]="formGroup" *ngIf="!formLoading">
        <app-card
          header="sla-pm.detail"
          tips="sla-pm.details.tips"
          *ngIf="historyType === 'rfq'"
        >
          <app-sla-info
            [workflowPrcsId]="workflowPrcsId"
            [historyType]="historyType"
          >
          </app-sla-info>
          <p></p>
        </app-card>
      </form>
      <form [formGroup]="formGroup" *ngIf="!formLoading">
        <app-card
          header="sla-pm.detail"
          tips="sla-pm.details.tips"
          *ngIf="historyType === 'order process'"
        >
          <app-sla-info
            [workflowPrcsId]="workflowPrcsId"
            [historyType]="historyType"
          >
          </app-sla-info>
          <p></p>
        </app-card>
      </form>
    </div>
  </div>

  <app-card header="sla-pm.sla.report" tips="sla-pm.slaReport.tips">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <app-table-xx
        [model]="tableResponseSlaReport"
        (onClick)="doAdjustmentWorkflow($event)"
      >
        <!--<ng-template #headerFilterGroup>
                    <form class="form-horizontal pt-4" [formGroup]="formGroup" novalidate *ngIf="!formLoading">
                        <div class="form-group row text-sm-left">
                            <div class="cml-3 text-left">
                                <app-dropdown-select size="XL" formControlName="workflow" (onChange)="onChangeFilter()"
                                    placeholder="{{
                  'sla-pm.placeholder.workflow' | translate
                }}" [optionList]="workflowList" type="CHOSEN" [ngStyle]="{
                  'margin-right': '7px',
                  width: '500px'
                }" showLimitValue="1">
                                    <ng-template #selectAll>
                                        <p>
                                            {{
                      'sla-pm.placeholder.allWorkflow'
                        | translate
                    }}
                                        </p>
                                    </ng-template>
                                </app-dropdown-select>
                            </div>
                        </div>
                    </form>
                </ng-template>-->

        <ng-template #headerFilter>
          <div class="app-table-filter-item" style="height: 34px">
            <div class="form-group row text-sm-left">
              <div class="col text-left" [ngStyle]="{ 'min-width': '10rem' }">
                <app-dropdown-select
                  size="XL"
                  formControlName="workflow"
                  (onChange)="onChangeFilter()"
                  container="body"
                  placeholder="{{ 'sla-pm.placeholder.workflow' | translate }}"
                  [optionList]="workflowList"
                  type="CHOSEN"
                  showLimitValue="1"
                >
                  <ng-template #selectAll>
                    <p>
                      {{ 'sla-pm.placeholder.allWorkflow' | translate }}
                    </p>
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>
          </div>
        </ng-template>
      </app-table-xx>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'sla-pm.form.totalRealizationWorkflowMain' | translate }} :
        </label>
        <div class="col-sm-4 text-left">
          {{ totalRealizationWorkflowMain }}
        </div>
      </div>
    </form>
  </app-card>

  <app-card header="sla-pm.history" tips="sla-pm.historyTips">
    <app-tab [bordered]="true" *ngIf="!formLoading">
      <app-tab-item header="{{ 'sla-pm.tab.header.histoySla' | translate }}">
        <app-table-xx [model]="tableResponseHistorySla" *ngIf="!formLoading">
        </app-table-xx>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'sla-pm.form.totalRealizationSlaHistory' | translate }} :
          </label>
          <div class="col-sm-4 text-left">
            {{ totalRealizationSlaHistory }}
          </div>
        </div>
      </app-tab-item>
      <app-tab-item
        header="{{ 'sla-pm.tab.header.histoyAdjusment' | translate }}"
      >
        <app-table-xx
          [model]="tableResponseHistoryAdjusment"
          *ngIf="!formLoading"
        >
        </app-table-xx>
      </app-tab-item>
    </app-tab>
  </app-card>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
