export class ArrayUtils {
  public static sortArray(
    array = [],
    sortBy = 'name',
    order: 'ASC' | 'DESC' | '' = 'ASC'
  ): any {
    if (array.length === 0) {
      return;
    }
    if (sortBy === null || sortBy === '') {
      sortBy = 'name';
    }
    let arrayTemp = Array.from(array);
    arrayTemp = arrayTemp.sort((dataA: any, dataB: any) => {
      dataA =
        typeof this.resolveFieldData(dataA, sortBy) === 'number'
          ? this.resolveFieldData(dataA, sortBy)
          : (this.resolveFieldData(dataA, sortBy) || '')
              .toString()
              .toLowerCase();
      dataB =
        typeof this.resolveFieldData(dataB, sortBy) === 'number'
          ? this.resolveFieldData(dataB, sortBy)
          : (this.resolveFieldData(dataB, sortBy) || '')
              .toString()
              .toLowerCase();
      return dataA < dataB
        ? order === 'ASC'
          ? -1
          : 1
        : dataA > dataB
        ? order === 'ASC'
          ? 1
          : -1
        : 0;
    });

    return arrayTemp;
  }

  public static filterArrayByText(
    array = [],
    textFilter = '',
    filterBy?: string | Array<string>
  ): any {
    let arrayCopy = Array.from(array);
    if (arrayCopy.length === 0) {
      return arrayCopy;
    }
    if (filterBy === null || filterBy === '' || !filterBy) {
      filterBy = 'name';
    }

    if (typeof filterBy === 'string') {
      arrayCopy = arrayCopy.filter((data: any) => {
        const resolveValue = this.resolveFieldData(data, filterBy as string);
        const isNumber = typeof resolveValue === 'number';
        data = isNumber
          ? this.resolveFieldData(data, filterBy as string).toString()
          : this.resolveFieldData(data, filterBy as string)
              .toString()
              .toLowerCase();
        textFilter = isNumber ? textFilter : textFilter.toLowerCase();
        if (data.indexOf(textFilter.toLowerCase()) !== -1) {
          return data;
        }
      });
    } else {
      arrayCopy = arrayCopy.filter((data: any) => {
        let isMatch = false;
        (filterBy as Array<string>).forEach(filter => {
          if (!isMatch) {
            let value: any = ArrayUtils.resolveFieldData(data, filter);
            if (
              value &&
              (typeof value === 'number' ? value.toString() : value)
                .toLowerCase()
                .includes(textFilter)
            ) {
              isMatch = true;
            }
          }
        });
        if (isMatch) {
          return data;
        }
      });
    }
    return arrayCopy;
  }

  public static resolveFieldData(
    record: any,
    field: string | { fn: (record: any) => string },
    datamap?: string
  ): any {
    if (typeof field === 'string' || typeof field === 'undefined') {
      field = field === 'undefined' ? 'name' : field;
      const fieldSplit = field.split('.');
      let value = Object.assign({}, record);
      while (fieldSplit.length > 0) {
        const firstField = fieldSplit.shift();
        const hasValue = !(
          value[firstField] === null || typeof value[firstField] === 'undefined'
        );
        if (!hasValue) {
          fieldSplit.splice(0);
          value =
            typeof record === 'string' || typeof record === 'number'
              ? record
              : null;
        } else {
          value = value[firstField];
        }
      }
      return datamap && value !== null ? JSON.parse(datamap)[value] : value;
    } else if (field !== null) {
      return field.fn(record);
    }
  }

  public static removeRecordFromArray(
    array = [],
    record: any,
    field?: string
  ): Array<any> {
    const arrayCopy = Array.from(array);
    const indexOfRecord = arrayCopy.findIndex(
      r =>
        (field
          ? JSON.stringify(this.resolveFieldData(r, field))
          : JSON.stringify(r)) ===
        (field
          ? JSON.stringify(this.resolveFieldData(record, field))
          : JSON.stringify(record))
    );
    if (indexOfRecord !== -1) {
      arrayCopy.splice(indexOfRecord, 1);
    }
    return arrayCopy;
  }
}
