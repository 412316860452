import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class MatchValidator {
  public static match(
    matchWith: string,
    message?: string,
    errorType = 'match'
  ): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      const ACParent = abstractControl.parent as FormGroup;
      if (ACParent && ACParent.get(matchWith) instanceof AbstractControl) {
        const matchValue = ACParent.get(matchWith).value;
        if (abstractControl.value !== matchValue) {
          return CustomValidatorError.create(errorType, message);
        }
        ACParent.get(matchWith).valueChanges.subscribe((value: any) => {
          if (
            abstractControl.value &&
            !abstractControl.valid &&
            abstractControl.value === value
          ) {
            abstractControl.updateValueAndValidity();
          }
          if (abstractControl.valid && abstractControl.value !== value) {
            abstractControl.updateValueAndValidity();
          }
        });
      } else {
        if (abstractControl.value !== matchWith) {
          return CustomValidatorError.create(errorType, message);
        }
      }
      return null;
    };
  }
}
