<app-layout [isLoading]="formLoading" [backTo]="urlBackOutside">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge
      class="cml-2"
      *ngIf="
        prStatus.code === global.appConstant.pm.PR_STATUS_WAITING_VERIFICATION
      "
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="prStatus.code === global.appConstant.pm.PR_STATUS_HOLD"
      color="DANGER"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="prStatus.code === global.appConstant.pm.PR_STATUS_REVISION"
      color="FEEDBACK"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="prStatus.code === global.appConstant.pm.PR_STATUS_VERIFIED"
      color="INFO"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        prStatus?.code === global.appConstant.pm.PR_STATUS_WAITING_APPROVAL
      "
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
  </ng-template>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-card
          header="pr-verification.detail.general.title"
          tips="pr-verification.detail.general.tips"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr-verification.form.prNumber' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ pr.code }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr-verification.form.requestorDepartment' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ pr.organization.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr-verification.form.requestor' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ prVerificationResponse.requestor.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr-verification.form.requiredDate' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                pr.requiredDate
                  | date: global.appConstant.core.FORMAT_SHORT_DATE
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr-verification.form.category' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ pr.procurementType.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr-verification.form.description' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              {{ pr.description }}
            </div>
          </div>
        </app-card>
        <app-card
          header="pr-verification.detail.itemRequest.title"
          tips="pr-verification.detail.itemRequest.tips"
        >
          <app-table-xx
            #selectorItem
            [model]="tableResponseItem"
            (onClick)="doViewItem($event)"
          >
            <ng-template #headerFilter>
              <form
                class="form-horizontal"
                [formGroup]="formGroup"
                novalidate
                *ngIf="!formLoading"
              >
                <div class="form-group row text-sm-left">
                  <div
                    class="col-sm-9 text-left"
                    [ngStyle]="{ 'min-width': '10rem' }"
                  >
                    <app-dropdown-select
                      size="XL"
                      [formControl]="itemType"
                      [optionList]="itemTypeOptionList"
                      (onChange)="onChangeItem()"
                      container="body"
                      placeholder="{{
                        'pr-verification.placeholder.type' | translate
                      }}"
                      [ngStyle]="{ 'min-width': '10rem' }"
                    >
                      <ng-template #value let-value>
                        {{ getTranslateKey(value) | translate }}
                      </ng-template>
                      <ng-template #option let-data>
                        {{ getTranslateKey(data) | translate }}
                      </ng-template></app-dropdown-select
                    >
                  </div>
                </div>
              </form>
            </ng-template>
            <ng-template #row let-state>
              <ng-container *ngIf="state.rowList.length > 0">
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'pr-verification.form.goodsAmount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{ pr.goodsAmount | resolveNumberToCurrency }}
                      {{ prVerificationResponse.companyCurrency.code }}
                    </strong>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'pr-verification.form.serviceAmount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{ pr.serviceAmount | resolveNumberToCurrency }}
                      {{ prVerificationResponse.companyCurrency.code }}
                    </strong>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'pr-verification.form.amount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{ pr.amount | resolveNumberToCurrency }}
                      {{ prVerificationResponse.companyCurrency.code }}
                    </strong>
                    <br />
                    <i>
                      ({{
                        pr.amount | resolveNumberToWords: global.currentLang
                      }}
                      {{ prVerificationResponse.companyCurrency.name }})
                    </i>
                  </td>
                </tr>
              </ng-container>
            </ng-template>
          </app-table-xx>
        </app-card>
        <app-card
          header="pr-verification.detail.shippingAddress.title"
          tips="pr-verification.detail.shippingAddress.tips"
        >
          <app-select
            [optionList]="addressBookOptionList"
            formControlName="addressBook"
            maxHeight="max-content"
          >
            <ng-template #content let-data>
              <div
                style="
                  border: 1px solid var(--gray-400);
                  border-radius: 5px;
                  box-sizing: border-box;
                  padding: 1rem;
                "
              >
                <p>{{ data.organization.name }}</p>
                <h5>{{ getUserNameList(data.addressPicList) }}</h5>
                <p>{{ data.addressDetail }}</p>
                <p>{{ data.phone }}</p>
              </div>
            </ng-template>
          </app-select>
        </app-card>
        <app-card
          header="pr-verification.detail.officialReport.title"
          tips="pr-verification.detail.officialReport.tips"
        >
          <app-official-report
            [objectId]="prId"
            [templateCode]="global.appConstant.pm.ORT_PURCHASE_REQUEST_LETTER"
            [appOfficialReportModel]="appOfficialReportModel"
            mode="view"
            numberGeneratorCode="NUMBER"
            [isShowButtonGenerate]="false"
          ></app-official-report>
        </app-card>

        <app-card
          *ngIf="!workloadPrcsId"
          header="pr-verification.detail.verification.title"
          tips="pr-verification.detail.verification.tips"
          [isSaving]="formSaving"
        >
          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr-verification.form.status' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                formControlName="prStatus"
                [optionList]="prStatusOptionList"
              >
                <ng-template #value let-value>
                  <em
                    *ngIf="
                      value &&
                      value.code === global.appConstant.pm.PR_STATUS_VERIFIED
                    "
                    class="pic pi-thumbs-up-circle"
                  ></em>
                  <span
                    *ngIf="
                      value &&
                      value.code === global.appConstant.pm.PR_STATUS_VERIFIED
                    "
                    style="color: 'var(--success)'"
                  >
                    {{ value.name }}
                  </span>

                  <em
                    *ngIf="
                      value &&
                      value.code === global.appConstant.pm.PR_STATUS_HOLD
                    "
                    class="pic pi-clock-circle"
                  ></em>
                  <span
                    style="color: 'var(--warning)'"
                    *ngIf="
                      value &&
                      value.code === global.appConstant.pm.PR_STATUS_HOLD
                    "
                  >
                    {{ value.name }}
                  </span>

                  <em
                    *ngIf="
                      value &&
                      value.code === global.appConstant.pm.PR_STATUS_REVISION
                    "
                    class="pic pi-pencil-circle"
                  ></em>
                  <span
                    style="color: 'var(--primary)'"
                    *ngIf="
                      value &&
                      value.code === global.appConstant.pm.PR_STATUS_REVISION
                    "
                  >
                    {{ value.name }}
                  </span>
                </ng-template>
              </app-dropdown-select>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr-verification.form.note' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-text-area size="XL" formControlName="note" maxLength="255">
              </app-text-area>
            </div>
          </div>
        </app-card>

        <app-card
          header="pr-verification.detail.changeHistory.title"
          tips="pr-verification.detail.changeHistory.tips"
          *ngIf="
            prStatus.code !=
            global.appConstant.pm.PR_STATUS_WAITING_VERIFICATION
          "
        >
          <app-change-history
            *ngIf="!formLoading"
            [moduleId]="prVerificationResponse.module?.id"
            [objectId]="prVerificationResponse.prVerification?.id"
          ></app-change-history>
        </app-card>
        <app-assignment-delegation
          *ngIf="workloadPrcsId"
          [workloadPrcsId]="workloadPrcsId"
        >
        </app-assignment-delegation>
        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  *ngIf="!formGroup.isView"
                  (onClick)="doSubmit()"
                  [disabled]="formSaving"
                  >{{ 'app.button.submit' | translate }}</app-button
                >
              </div>
            </div>
          </div>
        </div>
        <app-approval-prcs-x
          *ngIf="!formLoading && prVerificationResponse?.isUserApproval"
          [workflowModelPrcs]="prVerificationResponse?.pr?.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips
      *ngIf="!formLoading"
      [activeTipHeader]="
        workloadPrcsId
          ? 'app-assignment-delegation.detail.assignedUser.title'
          : 'pr-verification.detail.verification.title'
      "
    ></app-tips>
  </ng-template>
</app-layout>
