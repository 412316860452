import { PrItem } from 'src/app/core/bean/pr-item';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementFilter } from 'src/app/core/bean/procurement-filter';
import { ProcurementItem } from 'src/app/core/bean/procurement-item';
import { ProcurementPq } from 'src/app/core/bean/procurement-pq';
import { ProcurementRegion } from 'src/app/core/bean/procurement-region';
import { ProcurementSegQualification } from 'src/app/core/bean/procurement-seg-qualification';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { RequirementDoc } from 'src/app/core/bean/requirement-doc';
import { AppUploadDocumentTableModel } from 'src/app/core/components/app-upload-document-table/app-upload-document-table-model';
import { DirectPurchaseDelivery } from '../../../core/bean/direct-purchase-delivery';
import { DirectPurchasePayment } from '../../../core/bean/direct-purchase-payment';
import { DirectPurchaseRealization } from '../../../core/bean/direct-purchase-realization';
import { DirectPurchaseShipping } from '../../../core/bean/direct-purchase-shipping';
import { ProcurementResult } from '../../../core/bean/procurement-result';
import { Vendor } from '../../../core/bean/vendor';
import { VendorAddress } from '../../../core/bean/vendor-address';
import { VendorBank } from '../../../core/bean/vendor-bank';
import { VendorPIC } from '../../../core/bean/vendor-pic';
import { AppOfficialReportModel } from '../../../core/components/app-official-report/app-official-report-model';
import { FileObject } from '../../../core/components/upload';

export class InitiationRequest {
  isSubmit = false;
  procurement: Procurement;
  procurementVendorList: ProcurementVendor[] = [];
  procurementItemList: ProcurementItem[] = [];
  requirementDocList: RequirementDoc[] = [];
  procurementPqList: ProcurementPq[] = [];
  procurementSegQualificationList: ProcurementSegQualification[] = [];
  procurementFilter: ProcurementFilter;
  procurementRegionList: ProcurementRegion[] = [];

  appUploadDocumentTableModelList: AppUploadDocumentTableModel[] = [];

  /** DIRECT PURCHASE */
  vendor: Vendor;
  procurementVendor: ProcurementVendor;
  vendorPIC: VendorPIC;
  vendorAddress: VendorAddress;
  vendorBank: VendorBank;
  directPurchasePayment: DirectPurchasePayment;
  fileObjectList: FileObject[] = [];
  directPurchaseShippingList: DirectPurchaseShipping[] = [];
  directPurchaseDeliveryList: DirectPurchaseDelivery[] = [];
  directPurchaseRealizationList: DirectPurchaseRealization[] = [];
  procurementResultList: ProcurementResult[] = [];
  appOfficialReportModel: AppOfficialReportModel;

  prItemList: PrItem[] = [];
  initiationTypeCode: string;
  isShowVendorResult: boolean;
}
