import { Injectable } from '@angular/core';
import { BudgetAllocation } from '../../../bean/budget-allocation';
import { AppPopupService } from '../app-popup.service';
import { AppPopupBudgetWorkPlanInfoComponent } from './app-popup-budget-work-plan-info.component';

@Injectable()
export class AppPopupBudgetWorkPlanInfoService {
  constructor(public appPopupService: AppPopupService) {}

  public open(budget?: BudgetAllocation) {
    return this.appPopupService.open(
      AppPopupBudgetWorkPlanInfoComponent,
      { budget },
      { size: 'lg', windowClass: 'modal-custom' }
    );
  }
}
