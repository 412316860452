<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card *ngIf="isSyncRunning">
            <div id="employee-data-sync-info" class="alert alert-warning" role="alert"
                *ngIf="(websocketEmployeeDataService.message | async)">
                <strong class="mr-2"><em
                        class="fa fa-info-circle mr-2"></em>{{ 'employee-data.sync.info' | translate }}</strong>
                {{ (websocketEmployeeDataService.message | async) }}
            </div>
        </app-card>
        <app-card header="employee-data.title" [isLoading]="formLoading" [isSaving]="formSaving" *ngIf="!isSyncRunning">
            <div *ngIf="!formLoading">
                <div id="employee-data-sync-info" class="alert alert-warning" role="alert"
                    *ngIf="(websocketEmployeeDataService.message | async)">
                    <strong class="mr-2"><em
                            class="fa fa-info-circle mr-2"></em>{{ 'employee-data.sync.info' | translate }}</strong>
                    {{ (websocketEmployeeDataService.message | async) }}
                </div>
                <app-table [tableResponse]="tableResponse" [isServerSide]="true" stringUrl="/employee-data/index">
                    <ng-template #headerButtons>
                        <!-- BJB -->
                        <ng-container>
                            <app-button (onClick)="doSync()"><em class="fas fa-sync-alt"></em>
                                {{'app.button.synchronize' | translate }}</app-button>
                        </ng-container>
                    </ng-template>
                </app-table>
            </div>
        </app-card>
    </div>
</div>