import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { AddressBook } from '../../core/bean/address-book';
import { AddressPic } from '../../core/bean/address-pic';
import { Dr } from '../../core/bean/dr';
import { DrShipping } from '../../core/bean/dr-shipping';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { AppPopupUserService } from '../../core/components/app-popup/app-popup-user/app-popup-user.service';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './dr-popup-add-dr-address.component.html'
})
export class DrPopupAddDrAddressComponent extends BaseComponentComponent {
  public addressBook: AddressBook = new AddressBook();
  public drShippingList: DrShipping[] = [];
  public addressBookOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'addressDetail'
  );
  public isAddressChecked = false;
  public isLoadingFilter = false;
  @Input() dr: Dr;
  @Output() onChange: EventEmitter<DrShipping> = new EventEmitter();

  constructor(
    public appPopupUserService: AppPopupUserService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public activeModal: NgbActiveModal
  ) {
    super('dr');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      addressBook: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<DrShipping[]>('/dr/get-dr-shipping?id=' + this.dr.id)
      .subscribe(drShippingList => {
        this.drShippingList = drShippingList;
        if (drShippingList && drShippingList.length !== 0) {
          const addressBookList = drShippingList
            ? (drShippingList as Array<DrShipping>).map(oship => oship.address)
            : [];
          this.addressBookOptionList.setRequestValues(addressBookList);
          this.addressBookOptionList.searchFieldList = [
            'organization.name',
            'user.name',
            'addressDetail',
            'phone'
          ];
        }
      });

    this.setStateReady();
  }

  doSubmit(): void {
    this.addressBook = this.formGroup.get('addressBook').value;
    if (this.addressBook) {
      const drShipping = this.drShippingList.find(
        x => x.address.id === this.addressBook.id
      );
      this.onChange.emit(drShipping);
    } else {
      this.global.alertService.showError(
        'dr.validation.chooseAddress',
        '.popup-address'
      );
    }
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }
}
