import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { DocGroup } from '../../core/bean/doc-group';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  selector: 'app-popup-doc-group',
  templateUrl: './app-popup-document-group.component.html'
})
export class AppPopupDocumentGroupComponent extends BaseComponentComponent {
  @Input() public docGroupIdList: number[];
  @Input() public docGroupIdCustomData: any;
  @Output() public onChange: EventEmitter<DocGroup> = new EventEmitter();

  public tableResponse: TableResponseModel<DocGroup>;

  constructor() {
    super('app-popup-doc-group');
  }

  onInit(): void {
    this.setDocGroupIdCustomData();
    this.buildTableResponse();
  }
  public setDocGroupIdCustomData(): void {
    this.docGroupIdCustomData =
      this.docGroupIdList.length !== 0
        ? JSON.stringify(this.docGroupIdList)
        : null;
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: '0.code', header: 'table.column.code' },
      { field: '1', header: 'table.column.translationKey' }
      // { field: 'code', header: 'table.column.code' },
      // { field: 'nameTranslated', header: 'table.column.translationKey' },
    ]);
  }

  public doChoose(docGroup: DocGroup): void {
    this.onChange.emit(docGroup);
  }
}
