import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { VendorCertificate } from './../../core/bean/vendor-certificate';
import { ReportService } from './../../core/services/report.service';
@Component({
  templateUrl: './vendor-certificate-detail.component.html'
})
export class VendorCertificateDetailComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<VendorCertificate>;
  public vendorId: number;
  public vendorName: string;
  public vendorCode: string;
  public isLoading: boolean;
  public vendorCertificateId: number;

  constructor(
    translateService: TranslateService,
    public reportService: ReportService
  ) {
    super('vendor-certificate', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setDataFromRouterParams();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    const vendorId = this.global.routerParams.get('id');
    this.tableResponse.setCustomData(vendorId);
    this.vendorId = this.global.routerParams.get('id');
    this.vendorName = this.global.routerParams.get('name');
    this.vendorCode = this.global.routerParams.get('code');
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'certificateNumber',
        header: 'table.col.certificateNumber',
        customClass: 'text-center'
      },
      {
        field: 'certificateStatus.name',
        header: 'table.col.certificateStatus',
        customClass: 'text-center'
      },
      { field: 'approvedByUser.name', header: 'table.col.approver' },
      {
        field: 'periodName',
        header: 'table.col.certificatePeriod',
        customClass: 'text-center'
      },
      {
        field: 'releaseDate',
        header: 'table.col.releaseDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'expiredDate',
        header: 'table.col.expiredDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      }
    ]);
  }

  public doViewDetailVendor(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorId', this.vendorId);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/vendor-certificate/detail'
    );
    this.router.navigate(['/pages/company-profile']);
  }

  public doReport(vendorCertificate: VendorCertificate): void {
    this.isLoading = true;
    this.vendorCertificateId = vendorCertificate.id;
    this.reportService
      .print('/vendor-certificate/print/certificate', vendorCertificate)
      .subscribe(() => {
        this.isLoading = false;
        this.vendorCertificateId = null;
      });
  }

  public doBack(): void {
    this.router.navigate(['/pages/vendor-certificate/']);
  }
}
