import { BaseEntity } from '../base/base-entity';
import { Order } from '../bean/order';
import { OrderActivityStatus } from '../bean/order-activity-status';
import { User } from '../bean/user';

export class OrderActivityHistoryEntity extends BaseEntity {
  order: Order;
  orderActivityStatus: OrderActivityStatus;
  processedByUser: User;
  processedDate: Date;
  note: string;
}
