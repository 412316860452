import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { RecapPrintService } from 'src/app/core/services/recap-print.service';
import { ItemExportView } from 'src/app/core/view/entity/bean/item-export-view';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Item } from '../../core/bean/item';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';


@Component({
  templateUrl: './item.component.html'
})
export class ItemComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<ItemExportView>;
  public isLoading: boolean;
  constructor(translateService: TranslateService,
    public recapPrintService: RecapPrintService) {
    super('item', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'white-space-normal'
      },
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      {
        field: 'dose',
        header: 'table.column.dose',
        customClass: 'white-space-normal'
      },
      { field: 'uomName', header: 'table.column.uom' },
      {
        field: 'categoryName',
        header: 'table.column.category',
        sortable: false,
        customClass: 'white-space-normal'
      },
      {
        field: 'itemCategoryName',
        header: 'table.column.subItemCategory',
        customClass: 'white-space-normal'
      },
      {
        field: 'minPrice',
        header: 'table.column.minPrice',
        customClass: 'text-right',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'maxPrice',
        header: 'table.column.maxPrice',
        customClass: 'text-right',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'itemStatusActive',
        header: 'table.column.statusActive'
      },
      {
        field: 'itemStatusName',
        header: 'table.column.status'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/item/add']);
  }

  public doEdit(item: ItemExportView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('itemId', item);
    this.router.navigate(['/pages/item/edit']);
  }

  public doView(item: ItemExportView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('itemId', item);
    this.router.navigate(['/pages/item/detail']);
  }

  public doDelete(item: ItemExportView): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Item>>('/item/delete', item)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService
      .print('/item/export-item', this.tableResponse.request)
      .subscribe(() => {
        this.isLoading = false;
      });
  }

}
