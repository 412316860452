import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';

export const AppTableCheckBoxPlugin: TablePlugin = {
  name: 'check-box',
  before: (data: TablePluginData) => {
    console.log('Info: Come from before');
    console.log(data);
  },
  onCreate: (data: TablePluginData) => {
    console.log('Info: Come from onCreate');
    console.log(data);
  },
  after: (data: TablePluginData) => {
    console.log('Info: Come from after');
    console.log(data);
  }
};
