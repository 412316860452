import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeEvent, TreeModel } from '../../core/components/app-tree-org';
import { RecapPrintService } from '../../core/services/recap-print.service';
import { Validators } from '../../core/validators';
import { BaseModuleComponent } from './../../core/base/angular/base-module.component';
@Component({
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent extends BaseModuleComponent {
  public treeModel: TreeModel;
  public isLoading: boolean;

  constructor(translateService: TranslateService,public recapPrintService: RecapPrintService) {
    super('organization', translateService);
  }

  public onInit(): void {
    this.doBuildTreeModel();
    this.doBuildTreeFormGroup();
    this.setStateReady();
  }

  public doBuildTreeModel(): void {
    this.treeModel = new TreeModel(
      this.moduleCode,
      'WRITE',
      this.global.userSession.user.organization.id
    );
    this.treeModel.disableRootButton = true;
  }

  public doBuildTreeFormGroup(): void {
    this.treeModel.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      hierarchyLevel: [null, Validators.compose([Validators.maxLength(8)])],
      letterNumberCode: [null, Validators.maxLength(64)],
      tempId: [null],
      parentId: [null],
      hasChild: [null]
    });
  }

  public doOnEventChange(treeEvent: TreeEvent): void {
    if (treeEvent.type === 'ON-SHOW-POPUP') {
      const id = this.formGroup.value.id;
      const treeProcessItemCodeList = this.treeModel.treeProcess.treeProcessItemList
        .map(
          treeProcessItem =>
            treeProcessItem.type !== 'DELETE' && treeProcessItem.data.code
        )
        .filter(code => code);
      treeEvent.formGroup
        .get('code')
        .setValidators(Validators.exists(treeProcessItemCodeList));
      treeEvent.formGroup.get('code').setAsyncValidators(
        Validators.existsAsync('/organization/isAlreadyExist', null, null, {
          id
        })
      );
    } else if (treeEvent.type === 'ON-CLOSE-POPUP') {
      treeEvent.formGroup.get('code').clearAsyncValidators();
      treeEvent.formGroup.get('code').clearValidators();
    }
  }

public doExport(): void {
    this.isLoading = true;
    this.recapPrintService
      .print('/organization/export',null)
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  public doCodeChange(code: string): void {
    const id = this.formGroup.value.id;
    let treeProcessItemCodeList = this.treeModel.treeProcess.treeProcessItemList
      .map(
        treeProcessItem =>
          treeProcessItem.type !== 'ADD' && treeProcessItem.origin.code
      )
      .filter(code => code);
    if (treeProcessItemCodeList.indexOf(code) !== -1) {
      this.treeModel.formGroup.get('code').clearAsyncValidators();
    } else {
      treeProcessItemCodeList = this.treeModel.treeProcess.treeProcessItemList
        .map(
          treeProcessItem =>
            treeProcessItem.type === 'ADD' && treeProcessItem.data.code
        )
        .filter(code => code);
      this.treeModel.formGroup.get('code').setAsyncValidators(
        Validators.existsAsync('/organization/isAlreadyExist', null, null, {
          id
        })
      );
    }
  }
}
