<nav class="content-navigation">
  <ul class="content-menu">
    <li
      class="menu-item"
      *ngFor="let item of tipItemList"
      [ngClass]="item.active ? 'active' : ''"
      (click)="doScrollToItem(item)"
      [attr.id]="'tips' + item.id"
    >
      {{ item.header }}
    </li>
    <!-- <li class="menu-item">Item Request</li>
            <li class="menu-item">Monitoring Quotation</li> -->
  </ul>
</nav>
<div class="tips-wrapper text-center">
  <h4 class="title">
    Tips
    <em class="fas fa-lightbulb tips-icon"></em>
  </h4>
  <div
    class="tips-content text-justify"
    *ngFor="let content of activeTipContent"
  >
    {{ content | translate }}
    <br />
    <br />
    <button class="btn btn-primary" style="width: 100px; margin: 0 auto">
      OK
    </button>
    <br />
    <br />
    <a href="#" class="font-weight-bold">Don't show this anymore'</a>
  </div>
</div>
