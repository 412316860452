<!-- <div class="app-widget-contract">
  <app-widget [header]="moduleCode + '.title'">
    <app-widget-content-slider>
      <ng-template #contentOuterLeft>
        <app-widget-card header="{{ 'app-widget-contract.request' | translate }}">
          <em class="fas fa-2x mr-2 fa-file"></em>
          <span class="h3 m-0">{{ totalContractRequest }}</span>
          <div class="widget-card-footer mt-2">
            <p class="m-0 cursor-pointer more-detail" (click)="goToMoreDetail()" (keyup)="onKeyUp($event)">
              {{ 'app-widget-contract.moreDetail' | translate }}
            </p>
          </div>
        </app-widget-card>
      </ng-template>
      <app-widget-card header="{{ 'app-widget-contract.draft' | translate }}">
        <em class="fas fa-2x mr-2 fa-file"></em>
        <span class="h3 m-0">{{ totalDraft }}</span>
      </app-widget-card>
      <app-widget-card header="{{ 'app-widget-contract.negotiation' | translate }}">
        <em class="fas fa-2x mr-2 fa-file"></em>
        <span class="h3 m-0">{{ totalNegotiation }}</span>
      </app-widget-card>
      <app-widget-card header="{{ 'app-widget-contract.finalization' | translate }}">
        <em class="fas fa-2x mr-2 fa-file"></em>
        <span class="h3 m-0">{{ totalFinalization }}</span>
      </app-widget-card>
      <app-widget-card header="{{ 'app-widget-contract.cancel' | translate }}">
        <em class="fas fa-2x mr-2 fa-file"></em>
        <span class="h3 m-0">{{ totalCancel }}</span>
      </app-widget-card>
      <app-widget-card header="{{ 'app-widget-contract.onProgress' | translate }}">
        <em class="fas fa-2x mr-2 fa-file"></em>
        <span class="h3 m-0">{{ totalOnProgress }}</span>
      </app-widget-card>
      <app-widget-card header="{{ 'app-widget-contract.close' | translate }}">
        <em class="fas fa-2x mr-2 fa-file"></em>
        <span class="h3 m-0">{{ totalClose }}</span>
      </app-widget-card>
      <app-widget-card header="{{ 'app-widget-contract.termination' | translate }}">
        <em class="fas fa-2x mr-2 fa-file"></em>
        <span class="h3 m-0">{{ totalTermination }}</span>
      </app-widget-card>
    </app-widget-content-slider>
  </app-widget>
</div>
<br>
<br> -->
<div class="app-widget-contract" *ngIf="!formLoading">
  <app-widget [header]="moduleCode + '.title'">
    <app-widget-content-slider>
      <ng-template #contentOuterLeft>
        <div class="widget-card" (click)="goToMoreDetail()" (keyup)="onKeyUp($event)">
          <h6 class="position-relative">
            {{ 'app-widget-contract.request' | translate }}
            <em class="fas fa-info-circle" title="Detail"></em>
          </h6>
          <span class="m-0">{{ totalContractRequest }}</span>
          <em class="fas fa-hand-pointer icon-bg"></em>
        </div>
      </ng-template>
      <div class="widget-card" *ngFor="let data of contractDataList">
        <h6>{{ data.translationKey | translate }}</h6>
        <span class="m-0">{{ data.value }}</span>
        <em class="icon-bg" [ngClass]="data.icon"></em>
      </div>
    </app-widget-content-slider>
  </app-widget>
</div>