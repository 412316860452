import { Organization } from '../../core/bean/organization';
import { Role } from '../../core/bean/role';
import { User } from '../../core/bean/user';
import { UserRole } from '../../core/bean/user-role';
import { Vendor } from '../../core/bean/vendor';

export class UserResponse {
    user: User;
    vendor: Vendor;
    isVendor: boolean;
    roleList: Role[];
    userRoleList: UserRole[];
    organizationList: Organization[];
}