import { Component } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { WidgetService } from '../widgets.service';
@Component({
  templateUrl: './app-widget-vendor-status-information.component.html',
  styleUrls: ['./app-widget-vendor-status-information.component.scss']
})
export class AppWidgetVendorStatusInformationComponent extends BaseComponentComponent {
  public total = 100;
  public vendorStatusInformationList: Array<any> = new Array();
  public vendorStatusIconList = {
    CANDIDATE: 'fa-user-circle',
    ACTIVE: 'fa-user-circle-o',
    BLACKLIST: 'fa-user-times',
    INACTIVE: 'fa-user'
  };
  constructor(public widgetService: WidgetService) {
    super('app-widget-vendor-status-information');
  }

  public onInit(): void {
    this.getAndSetDataFromWidgetService();
    this.getAndSetVendorStatusInformation();
  }

  public getAndSetDataFromWidgetService(): void {
    this.vendorStatusInformationList = this.widgetService.getData(this.moduleCode) || [];
  }

  public getAndSetVendorStatusInformation(): void {
    this.httpClientService.get('/widget-vendor-status-information/index').subscribe((response: Array<any>) => {
      this.widgetService.setData(this.moduleCode, response);
      this.vendorStatusInformationList = response;
      this.global.widgetLoadingStatus.set(this.moduleCode, true);
      this.setStateReady();
    });
  }

  public goToMoreDetail(url: string, code: string): void {
    this.global.routerParams.set('vendorStatusCodeFromWidget', code);
    this.router.navigate([url]);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
