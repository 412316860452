import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTabItemComponent } from './app-tab-item.component';
import { AppTabComponent } from './app-tab.component';
@NgModule({
  imports: [VendorModule],
  declarations: [AppTabComponent, AppTabItemComponent],
  exports: [AppTabComponent, AppTabItemComponent]
})
export class AppTabModule {}
