import { BaseEntity } from '../base/base-entity';
import { DeliveryStatus } from '../bean/delivery-status';
import { DrType } from '../bean/dr-type';
import { Organization } from '../bean/organization';
import { Vendor } from '../bean/vendor';

export class DrEntity extends BaseEntity {
  drType: DrType;
  vendor: Vendor;
  deliveryStatus: DeliveryStatus;
  title: string;
  percentage: number;
  requiredDate: Date;
  description: string;
  organizationDistributor: Organization = new Organization ();
}
