import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AssociationEditAddComponent } from './association-edit-add.component';
import { AssociationComponent } from './association.component';

export const routes = [
  { path: '', component: AssociationComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: AssociationEditAddComponent,
    data: { todo: 'edit', breadcrumb: 'association.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: AssociationEditAddComponent,
    data: { todo: 'add', breadcrumb: 'association.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgbModule],
  declarations: [AssociationComponent, AssociationEditAddComponent],
  providers: []
})
export class AssociationModule {}
