import { BaseDTO } from '../../../core/base/base-dto';
import { VendorSegmentation } from '../../../core/bean/vendor-segmentation';
import { VendorSegmentationHistory } from '../../../core/bean/vendor-segmentation-history';

export class TabProfileSegmentationDTO extends BaseDTO {
    public maxSegmentation: number;
    public mandatorySIUJK: string;
    public vendorSegmentationHistoryList: VendorSegmentationHistory[];
    public vendorSegmentationList: VendorSegmentation[];
}
