interface IAttribute {
  fieldName: string;
  isMandatory: boolean;
  isApproval?: boolean;
}

const basicInformation: IAttribute[] = [
  { fieldName: 'Company Name', isMandatory: false, isApproval: false },
  { fieldName: 'Obbreviation', isMandatory: false, isApproval: false },
  { fieldName: 'Trademark Name', isMandatory: false, isApproval: false },
  { fieldName: 'NPWP/Tax Number', isMandatory: true, isApproval: false },
  { fieldName: 'Non-PKP/PKP', isMandatory: false, isApproval: false },
  { fieldName: 'PKP Number', isMandatory: false, isApproval: false },
  {
    fieldName: 'Certificate of Estabillishment',
    isMandatory: false,
    isApproval: false
  },
  { fieldName: 'Registered in Unit', isMandatory: false, isApproval: false },
  { fieldName: 'email', isMandatory: false, isApproval: false },
  { fieldName: 'Phone', isMandatory: false, isApproval: false }
];

const vendorType: IAttribute[] = [
  { fieldName: 'Ownership', isMandatory: true, isApproval: false },
  { fieldName: 'Entity', isMandatory: true, isApproval: false },
  { fieldName: 'Location', isMandatory: false, isApproval: false }
];

const companyDataChild: IAttribute[] = [
  { fieldName: 'Qualification', isMandatory: true, isApproval: false },
  { fieldName: 'Establishment Year', isMandatory: true, isApproval: false },
  { fieldName: 'Employee', isMandatory: false, isApproval: false },
  { fieldName: 'Website', isMandatory: false, isApproval: false },
  {
    fieldName: 'Product & Shipping Categories',
    isMandatory: true,
    isApproval: false
  },
  { fieldName: 'Shipping Location', isMandatory: true, isApproval: false },
  { fieldName: 'Industry', isMandatory: true, isApproval: false },
  { fieldName: 'Keywords', isMandatory: false, isApproval: false }
];

const companyData = {
  fieldName: 'Company Data',
  childrens: {
    basicInformation: {
      fieldName: 'Basic Information',
      childrens: basicInformation
    },
    vendorType: {
      fieldName: 'Vendor Type',
      childrens: vendorType
    },
    companyData: {
      fieldName: 'Company Data',
      childrens: companyDataChild
    }
  }
};

// ----------------------------------------------------------------

const commisioner: IAttribute[] = [
  { fieldName: 'Name', isMandatory: true, isApproval: false },
  { fieldName: 'ID', isMandatory: true, isApproval: false },
  { fieldName: 'Position', isMandatory: true, isApproval: false },
  { fieldName: 'DOB', isMandatory: true, isApproval: false }
];

const director: IAttribute[] = [
  { fieldName: 'Name', isMandatory: true, isApproval: false },
  { fieldName: 'ID', isMandatory: true, isApproval: false },
  { fieldName: 'Position', isMandatory: true, isApproval: false },
  { fieldName: 'DOB', isMandatory: true, isApproval: false }
];

const shareHolder: IAttribute[] = [
  { fieldName: 'Name', isMandatory: true, isApproval: false },
  { fieldName: 'ID', isMandatory: true, isApproval: false },
  { fieldName: 'Position', isMandatory: true, isApproval: false },
  { fieldName: 'Type', isMandatory: true, isApproval: false },
  { fieldName: 'DOB', isMandatory: true, isApproval: false },
  { fieldName: 'Share Value', isMandatory: true, isApproval: false }
];

const companyOrganization = {
  fieldName: 'Company Organization',
  childrens: {
    commisioner: {
      fieldName: 'Commisioner',
      childrens: commisioner
    },
    director: {
      fieldName: 'Director',
      childrens: director
    },
    shareHolder: {
      fieldName: 'Share Holder',
      childrens: shareHolder
    }
  }
};

export const addtionalInformation: IAttribute[] = [
  { fieldName: 'Jenis Kelamin', isMandatory: false },
  { fieldName: 'Total Karyawan', isMandatory: true }
];

export const dataTempList = { companyData, companyOrganization };

export const announcementList = [
  {
    title: 'Pengadaan Laptop dan Infrastruktur IT 1',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: true
  },
  {
    title: 'Pengadaan Aplikasi Security Perbankan 2',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Jasa SMS Gateway 3',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Gedung Kantor Baru 4',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Laptop dan Infrastruktur IT 5',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Jasa Aplikasi Security Perbankan 6',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Jasa Barang Dan Jasa 7',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Komputer Dan Laptop Apple 8',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Lapangan Olahraga 9',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Barang Lain-Lain 10',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Laptop dan Infrastruktur IT  11',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: true
  },
  {
    title: 'Pengadaan Aplikasi Security Perbankan 12',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Jasa SMS Gateway 13',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Gedung Kantor Baru 14',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Laptop dan Infrastruktur IT 15',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Jasa Aplikasi Security Perbankan 16',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Jasa Barang Dan Jasa 17',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Komputer Dan Laptop Apple 18',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Lapangan Olahraga 19',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Barang Lain-Lain 20',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Laptop dan Infrastruktur IT 21',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: true
  },
  {
    title: 'Pengadaan Aplikasi Security Perbankan 22',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Jasa SMS Gateway 23',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Gedung Kantor Baru 24',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Laptop dan Infrastruktur IT 25',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Jasa Aplikasi Security Perbankan 26',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Jasa Barang Dan Jasa 27',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Komputer Dan Laptop Apple 28',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Lapangan Olahraga 29',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  },
  {
    title: 'Pengadaan Barang Lain-Lain 30',
    date: new Date(),
    badge: 'Coming Soon',
    location: 'Kantor Pusat Kuningan Jakarta',
    featured: false
  }
];
