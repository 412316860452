import { Injectable } from '@angular/core';
import { AppPopupService } from '../app-popup.service';
import { AppPopupResourceComponent } from './app-popup-resource.component';

@Injectable()
export class AppPopupResourceService {
  constructor(public appPopupService: AppPopupService) {}

  public open() {
    return this.appPopupService.open(AppPopupResourceComponent, { size: 'lg' });
  }
}
