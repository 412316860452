import { Coa } from '../../../bean/coa';
import { CostCenter } from '../../../bean/cost-center';

export class AppPopupChooseBudgetModel {
  isCheckedAll = false;
  recordPageMap: Map<number, ChangeBudget> = new Map();
}

export interface ChangeBudget {
  id: number;
  isChecked: boolean;
  costCenter: CostCenter;
  coa: Coa;
  availableBudget: number;
}
