 <div class="row main-row">
  <div class="col-lg-12 mb-4" [formGroup]="formGroup">
    <app-card header="approval-workflow.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/approval-workflow/workflow/index">
        <ng-template #headerFilterTable>
          <div class="app-table-filter-item col-sm-3 p-0">
            <label for="moduleGroup">{{ 'app.filter.module' | translate
              }}</label>
              <app-combo-box size="XL" formControlName="moduleGroup" [optionList]="moduleGroupList" 
              (onChange)="doOnChangeModuleGroup()"></app-combo-box>
            <!-- <select (change)="doOnChangeModuleGroup()" class="form-control"
              formControlName="moduleGroup" aria-label="moduleGroup">
              <option [ngValue]="null">{{ 'app.chooseData' | translate }}
              </option>
              <option *ngFor="let moduleGroup of moduleGroupList"
                [ngValue]="moduleGroup">
                {{ moduleGroup.name }}
              </option>
            </select> -->
          </div>
          <div class="app-table-filter-item">
            <label for="organizationName">{{ 'app.filter.organization' |
              translate }}</label>
            <app-choose size="XL" formControlName="organizationName"
              (onClick)="onChooseOrganization($event)"></app-choose>
          </div>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button mode="BYPASS" size="TN" (onClick)="doView(rowData)"
            title="{{ 'app.tooltip.view' | translate }}">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>