import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupRfiItemComponent } from './app-popup-item.component';
import { AppPopupVendorNonPromiseComponent } from './app-popup-vendor-non-promise.component';
import { AppPopupVendorComponent } from './app-popup-vendor.component';
import { RfiDetailComponent } from './rfi-detail.component';
import { RfiEditAddComponent } from './rfi-edit-add.component';
import { RfiQuestionDetailComponent } from './rfi-question-detail.component';
import { RfiQuestionComponent } from './rfi-question.component';
import { RfiComponent } from './rfi.component';
const routes = [
  { path: '', component: RfiComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: RfiEditAddComponent,
    data: { breadcrumb: 'rfi.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: RfiEditAddComponent,
    data: { breadcrumb: 'rfi.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'question',
    component: RfiQuestionComponent,
    data: { breadcrumb: 'rfi.breadcrumb.question' }
  },
  {
    path: 'detail',
    data: { breadcrumb: 'Detail' },
    children: [
      { path: '', component: RfiDetailComponent },
      {
        path: 'response',
        component: RfiQuestionDetailComponent,
        data: { breadcrumb: 'Response Detail' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    RfiComponent,
    RfiEditAddComponent,
    AppPopupRfiItemComponent,
    RfiQuestionComponent,
    AppPopupVendorNonPromiseComponent,
    AppPopupVendorComponent,
    RfiDetailComponent,
    RfiQuestionDetailComponent
  ],
  providers: [],

  entryComponents: [
    AppPopupRfiItemComponent,
    AppPopupVendorNonPromiseComponent,
    AppPopupVendorComponent
  ],
  exports: [
    AppPopupRfiItemComponent,
    AppPopupVendorNonPromiseComponent,
    AppPopupVendorComponent
  ]
})
export class RfiModule {}
