import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Order } from 'src/app/core/bean/order';
import { OrderStatus } from 'src/app/core/bean/order-status';
import { Organization } from 'src/app/core/bean/organization';
import { Vendor } from 'src/app/core/bean/vendor';
import { FieldFormatEnum } from 'src/app/core/components/app-table-x/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { OrderOptionListResponse } from './response/order-option-list.response';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { AppModalConfirmDialogComponent } from 'src/app/core/components/app-modal/app-modal-confirm-dialog/app-modal-confirm-dialog.component';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TableRequestModel } from 'src/app/core/components/app-table-x/model/table-request-model';

@Component({
  templateUrl: './order.component.html'
})
export class OrderComponent extends BaseModuleComponent implements OnInit {
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  public filter: string;
  public tableResponse: TableResponseModel<Order>;
  public statusOptionList: OptionListModel<OrderStatus> = new OptionListModel(true);
  public organizationOptionList: OptionListModel<Organization> = new OptionListModel(true);
  public vendorOptionList: OptionListModel<Vendor> = new OptionListModel(true);
  public postDateFrom: String;
  public postDateTo: String;
  public requiredDateFrom: String;
  public requiredDateTo: String;

  constructor(translateService: TranslateService, public appPopupService: AppPopupService) {
    super('order', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.doBuildTableResponse();
    this.buildSetFormGroup();
    this.doSetCustomData();
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'code',
          header: 'table.column.orderNumber',
          plugins: {
            name: 'hyperlink',
            className: 'white-space-normal'
          }
        },
        {
          field: 'orderType.name',
          header: 'table.column.orderType'
        },
        {
          field: 'postDate',
          header: 'table.column.postDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'requiredDate',
          header: 'table.column.requiredDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'title',
          header: 'table.column.title'
        },
        {
          field: 'organization.name',
          header: 'table.column.department'
        },
        {
          field: 'vendor.name',
          header: 'table.column.vendorName'
        },
        {
          field: 'amount',
          header: 'table.column.totalCost',
          format: FieldFormatEnum.Currency
        },
        {
          field: 'orderStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              DRAFT: 'SECONDARY',
              REVISION: 'FEEDBACK',
              WAITING_APPROVAL: 'WARNING',
              ON_PROGRESS: 'PROGRESS',
              DONE: 'SUCCESS',
              CLOSED: 'DANGER',
              CANCELED: 'DANGER',
              WAITING_CONFIRMATION: 'WARNING',
              VENDOR_REVISION: 'FEEDBACK',
              REJECTED: 'DANGER',
              REVISION_VERIFICATION: 'FEEDBACK',
              VERIFIED_VERIFICATION: 'WARNING',
              WAITING_VERIFICATION: 'WARNING'
            },
            field: 'orderStatus.code'
          }
        }
      ],
      { checkBoxFn: this.isShowCheckBox.bind(this) }
    );
  }

  public isShowCheckBox(record: Order): boolean {
    let isStatusDraft = false;
    if (
      record.orderStatus.code !== this.global.appConstant.pm.ORDER_STATUS_DRAFT
    ) {
      isStatusDraft = false;
    } else {
      isStatusDraft = true;
    }
    return isStatusDraft;
  }

  public doDelete(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Order[]>>('/order/delete', event.checkedRecordList)
            .subscribe((response: Response<Order[]>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public buildSetFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      postDate: [null],
      requiredDate: [null],
      organizationList: [null],
      statusList: [null],
      vendorList: [null]
    });

    this.httpClientService
      .get<OrderOptionListResponse>('/order/get-option-list', {})
      .subscribe((response: OrderOptionListResponse) => {
        this.statusOptionList.setRequestValues(response.statusList);
        this.organizationOptionList.setRequestValues(response.organizationList);
        this.vendorOptionList.setRequestValues(response.vendorList);

        this.setStateReady();
      });
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customData = {
        statusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customData);
      this.tableResponse.reload();
    }
  }

  public onChangeFilter(event: any): void {
    this.log.debug(event);
    const customData = this.formGroup.value;
    customData.postDateFrom = this.formGroup.value.postDate?.from || null;
    this.postDateFrom = customData.postDateFrom;
    customData.postDateTo = this.formGroup.value.postDate?.to || null;
    this.postDateTo = customData.postDateTo;
    customData.requiredDateFrom =
      this.formGroup.value.requiredDate?.from || null;
    this.requiredDateFrom = customData.requiredDateFrom;
    customData.requiredDateTo = this.formGroup.value.requiredDate?.to || null;
    this.requiredDateTo = customData.requiredDateTo;

    this.tableResponse.setCustomData(customData);
    this.tableResponse.reload();
  }

  public doPrintReport(): void {
    if (this.table.state.pagination.totalRecords <= this.global.config.parameterModel.maxDataPrintReport) {
      this.loadingBlockService.showInfo(
        this.translateService.instant('app.info.preparingDownload')
      );
      this.httpClientService.post<TableRequestModel>('/order/print-report', this.table.state.tableRequest, { responseType: 'arrayBuffer' as 'json' })
        .subscribe(response => {
          const file = new File([response], 'JRCARE - PO REPORT.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = URL.createObjectURL(file);
          const a = document.createElement('a');
          a.href = url;
          a.download = file.name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.loadingBlockService.showCompleteAndClose(
            this.translateService.instant(
              'app.info.successDownloaded'
            )
          );
        });
    } else {
      this.appPopupService.open(AppModalConfirmDialogComponent, {
        prompt: this.translateService.instant('app.infromation.print.report.prompt.one') + this.global.config.parameterModel.maxDataPrintReport + this.translateService.instant('app.infromation.print.report.prompt.two'),
        title: 'app.infromation.print.report.title',
        icon: 'pvc pv-exclamation-triangle-alert',
        isSingleButton: true,
        buttonRight: 'app.button.ok'
      },
        { size: 'md' });
    }
  }

  public doClick(order: Order): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('orderId', order.id);
    this.global.routerParams.set('urlBackOutside', '/pages/order');
    if (
      order.orderStatus.code == this.global.appConstant.pm.ORDER_STATUS_DRAFT ||
      order.orderStatus.code == this.global.appConstant.pm.ORDER_STATUS_REVISION ||
      order.orderStatus.code == this.global.appConstant.pm.ORDER_STATUS_REVISION_VERIFICATION ||
      order.orderStatus.code == this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
    ) {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate(['/pages/order/edit']);
    } else {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/order/detail']);
    }
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/order/add']);
    this.global.routerParams.set('urlBackOutside', '/pages/order');
  }
}
