import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from './../../core/shared/shared.module';
import { TabActivityHistoryComponent } from './tab-activity-history.component';
import { TabActivityComponent } from './tab-activity.component';
@NgModule({
  imports: [SharedModule],
  declarations: [TabActivityComponent, TabActivityHistoryComponent],
  entryComponents: [TabActivityComponent, TabActivityHistoryComponent],
  exports: [TabActivityComponent, TabActivityHistoryComponent]
})
export class TabActivityModule {
  constructor(accordionService: AccordionService) {
    accordionService.register('tab-activity-history', TabActivityComponent);
    // appAccordionService.register('tab-activity-history', TabActivityHistoryComponent);
  }
}
