import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { AppBadgeColor } from './app-badge-color-type';

@Component({
  selector: 'app-badge',
  templateUrl: './app-badge.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppBadgeComponent
  extends BaseComponentComponent
  implements OnChanges {
  @Input() public text: string;
  @Input() public color: AppBadgeColor = 'PRIMARY';
  @Input() public pill = true;
  @Input() public customClass = '';

  public badgeClassName: string;
  constructor(public translate: TranslateService) {
    super('app-badge');
  }

  public readonly BADGE_COLOR: object = {
    PRIMARY: 'badge-primary',
    SECONDARY: 'badge-secondary',
    INFO: 'badge-info',
    SUCCESS: 'badge-success',
    WARNING: 'badge-warning',
    'WARNING-LIGHT': 'badge-warning-light',
    DANGER: 'badge-danger',
    LIGHT: 'badge-light',
    DARK: 'badge-dark',
    GOOD: 'badge-good',
    SERVICE: 'badge-service',
    PROGRESS: 'badge-progress',
    FEEDBACK: 'badge-feedback',
    DEFAULT: 'badge-default'
  };

  public onInit(): void {
    this.setBadgeClassNameColorState();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.color) {
      this.setBadgeClassNameColorState();
    }
  }

  private setBadgeClassNameColorState(): void {
    this.badgeClassName = this.BADGE_COLOR[this.color || 'PRIMARY'];
  }
}
