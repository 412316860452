import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { NewsEditAddComponent } from './news-edit-add.component';
import { NewsComponent } from './news.component';
const routes: Routes = [
  { path: '', component: NewsComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: NewsEditAddComponent,
    data: { todo: 'edit', breadcrumb: 'news.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: NewsEditAddComponent,
    data: { todo: 'add', breadcrumb: 'news.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [NewsComponent, NewsEditAddComponent]
})
export class NewsModule {}
