import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from './../../core/shared/shared.module';
import { AddressBookEditAddComponent } from './address-book-edit-add.component';
import { AddressBookComponent } from './address-book.component';
import { PopupChooseAddressLocationComponent } from './popup-choose-address-location.component';
import { PopupChooseAddressPicComponent } from './popup-choose-address-pic.component';
const routes: Routes = [
  { path: '', component: AddressBookComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: AddressBookEditAddComponent,
    data: { breadcrumb: 'address-book.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: AddressBookEditAddComponent,
    data: { breadcrumb: 'address-book.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    AddressBookComponent,
    AddressBookEditAddComponent,
    PopupChooseAddressPicComponent,
    PopupChooseAddressLocationComponent
  ]
})
export class AddressBookModule {}
