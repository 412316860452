import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class FormGroupService {
  public formControlName: string;
  constructor(public formBuilder: FormBuilder) {}
  public getFormControlName(
    formGroup: FormGroup,
    formControl: FormControl
  ): string {
    this.formControlName = null;
    Object.keys(formGroup.controls).forEach((formControlName: string) => {
      if (this.formControlName === null) {
        const childControl = formGroup.get(formControlName);
        if (childControl === formControl) {
          this.formControlName = formControlName;
        }
      } else {
        return this.formControlName;
      }
    });
    return this.formControlName;
  }

  public builder(data: any): FormGroup | FormArray {
    if (Array.isArray(data)) {
      const formGroup = [];
      for (const d of data) {
        formGroup.push(this.builder(d));
      }
      return this.formBuilder.array(formGroup);
    } else {
      const formGroup = {};
      Object.keys(data).forEach(key => {
        if (
          typeof data[key] === 'object' &&
          data[key] &&
          !Array.isArray(data[key])
        ) {
          Object.assign(formGroup, {
            [key]: this.builder(data[key])
          });
        } else if (Array.isArray(data[key])) {
          Object.assign(formGroup, {
            [key]: this.formBuilder.array([])
          });

          data[key].forEach((newData: any) => {
            formGroup[key].push(this.builder(newData));
          });
        } else {
          Object.assign(formGroup, { [key]: [data[key]] });
        }
      });
      return this.formBuilder.group(formGroup);
    }
  }

  public validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field: string) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        const formArrayControls = control.controls;
        formArrayControls.forEach((fg: FormGroup) => {
          this.validateAllFormFields(fg);
        });
      }
    });
  }

  public bulkResetFormControl(
    formGroup: FormGroup,
    formControlNameList: Array<string>
  ): void {
    formControlNameList.forEach((formControlName: string) => {
      formGroup.get(formControlName).reset();
    });
  }

  public bulkRemoveFormControl(
    formGroup: FormGroup,
    formControlNameList: Array<string>
  ): void {
    formControlNameList.forEach((formControlName: string) => {
      formGroup.removeControl(formControlName);
    });
  }
}
