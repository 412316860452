import { BaseDTO } from '../../../base/base-dto';
import { WorkPlan } from '../../../bean/work-plan';
import { WorkPlanDoc } from '../../../bean/work-plan-doc';
import { WorkPlanItem } from '../../../bean/work-plan-item';
import { Organization } from '../../../bean/organization';

export class WorkPlanInfoRequest extends BaseDTO {
  workPlan: WorkPlan;
  workPlanDocList: WorkPlanDoc[];
  workPlanItemProductList: WorkPlanItem[] = [];
  workPlanItemServiceList: WorkPlanItem[] = [];
  organizationList: Organization[] = [];
  totalProductCost: number;
  totalServiceCost: number;
}