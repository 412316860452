import { Procurement } from '../../core/bean/procurement';
import { ProcurementCriteria } from '../../core/bean/procurement-criteria';
import { ProcurementCriteriaDetail } from '../../core/bean/procurement-criteria-detail';
import { ProcurementFinalizationCriteria } from '../../core/bean/procurement-finalization-criteria';

export class EvaluationCriteriaRequest {
  isSaveDraft: boolean;
  procurement: Procurement;
  procurementCriteria: ProcurementCriteria = new ProcurementCriteria();
  procurementCriteriaDetailList: ProcurementCriteriaDetail[] = [];
  procurementFinalizationCriteria: ProcurementFinalizationCriteria =
    new ProcurementFinalizationCriteria();
}
