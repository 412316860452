import { CheckedRecord } from './checked-record';
import { TableRequestModel } from './table-request-model';

export class TableRecord {
  isExpanded: boolean;
  isLoading: boolean;
  tableRequest: TableRequestModel = new TableRequestModel();
  isShowButtonLoadMore: boolean;
  selectedRecord: CheckedRecord = new CheckedRecord();
  constructor(public record: any, public childrens: Array<TableRecord> = []) {
    this.isExpanded = false;
  }

  public setStateLoading(): void {
    this.isLoading = true;
  }
  public setStateReady(): void {
    this.isLoading = false;
  }
}
