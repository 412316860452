import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { Scheduler } from './../../core/bean/scheduler';
import { OptionListModel } from './../../core/model/option-list-model';
@Component({
  templateUrl: 'scheduler-edit-add.component.html'
})
export class SchedulerEditAddComponent extends BaseModuleComponent {
  public schedulerId: number;
  public schedulerStatusList: OptionListModel<any> = new OptionListModel();
  public scheduler: Scheduler = new Scheduler();
  constructor(translateService: TranslateService) {
    super('scheduler', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doSetSchedulerStatusList();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.schedulerId = this.global.routerParams.get('schedulerId');
  }

  public doSetSchedulerStatusList(): void {
    const schedulerStatus = [
      { name: 'app.status.active', value: true },
      { name: 'app.status.inActive', value: false }
    ];
    this.schedulerStatusList.setRequestValues(schedulerStatus);
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      name: [null],
      cron: [null, Validators.required()],
      isActive: [null, Validators.required()],
      lastActive: [null],
      className: [null]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<Scheduler>(
        '/scheduler/edit',
        new RouteRequestModel(this.todo, this.schedulerId)
      )
      .subscribe(scheduler => {
        if (scheduler != null) {
          const status = this.schedulerStatusList
            .getRequestValues()
            .filter(
              valueOption => valueOption.value === scheduler.isActive
            )[0];
          this.formGroup.patchValue({
            id: scheduler.id,
            code: scheduler.code,
            name: scheduler.name,
            cron: scheduler.cron,
            isActive: status,
            lastActive: scheduler.lastActive,
            className: scheduler.clasName
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.scheduler = this.global.copyFormAttributeToModel(
              this.scheduler,
              this.formGroup
            );
            this.scheduler.isActive = this.formGroup.get(
              'isActive'
            ).value.value;
            const url = this.todo === 'edit' ? '/scheduler/update' : '';
            this.httpClientService
              .post<Response<Scheduler>>(url, this.scheduler)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/scheduler']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/scheduler']);
  }
}
