import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../../core/guard/confirm.guard';
import { SharedModule } from '../../../core/shared/shared.module';
import { TocEditAddComponent } from './toc-edit-add.component';
const routes = [
  {
    path: '',
    component: TocEditAddComponent,
    data: { breadcrumb: 'edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [TocEditAddComponent]
})
export class TocModule {}
