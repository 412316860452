import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WebContent } from '../../core/bean/web-content';
@Component({
  templateUrl: './vendor-policy.component.html',
  styleUrls: ['./vendor-policy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorPolicyComponent extends BaseModuleComponent {
  public vendorPolicy: string;
  constructor(translate: TranslateService) {
    super('vendor-policy', translate);
  }

  public onInit(): void {
    this.doGetVendorPolicy();
  }

  doGetVendorPolicy(): void {
    this.httpClientService
      .get<WebContent>(
        '/vendor-policy/get-vendor-policy/' + this.global.currentLang
      )
      .subscribe(vendorPolicy => {
        this.vendorPolicy = vendorPolicy.value;
        this.setStateReady();
      });
  }
}
