import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Officer } from '../../../bean/officer';
import { Organization } from '../../../bean/organization';
import { TableResponseModel } from '../../app-table/model/table-response-model';
import { AppPopupTreeOrganizationService } from '../app-popup-tree/app-popup-tree-organization.service';
@Component({
  templateUrl: './app-popup-officer.component.html'
})
export class AppPopupOfficerComponent extends BaseComponentComponent {
  @Input() public officerList: any;
  @Input() public organization: Organization;
  @Output() onChange: EventEmitter<Officer> = new EventEmitter();
  tableResponse: TableResponseModel<Officer>;
  organizationId: number;
  constructor(
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('approval');
  }

  onInit(): void {
    this.setOrganizationId();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setCustomData();
    setTimeout(() => {
      this.setStateReady();
    }, 500);
  }

  buildFormGroup(): void {
    const officerList =
      this.officerList instanceof FormArray
        ? this.officerList
        : this.formBuilder.array(this.officerList);
    this.formGroup = this.formBuilder.group({
      organizationName: [null],
      organizationId: this.organizationId,
      officerList
    });
  }

  setCustomData(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
  }

  buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'position.name', header: 'form.table.position' },
      { field: 'user.name', header: 'form.table.user' },
      { field: 'organization.name', header: 'form.table.organization' }
    ]);
  }

  setOrganizationId(): void {
    this.organization != null || this.organization !== undefined
      ? (this.organizationId = this.organization.id)
      : (this.organizationId = null);
  }

  onChooseOrganization(): void {
    this.appPopupOrganizationService
      .open(this.organizationId)
      .subscribe(organization => {
        const { id: organizationId, name: organizationName } =
          organization.pop();
        this.formGroup.patchValue({ organizationName, organizationId });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }

  doChoose(officer: Officer): void {
    this.onChange.emit(officer);
  }
}
