import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ItemType } from 'src/app/core/bean/item-type';
import { QuotationItem } from 'src/app/core/bean/quotation-item';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { PopupPriceVendorResponse } from './popup-price-vendor.response';

@Component({
  templateUrl: './app-popup-price-vendor.component.html'
})
export class AppPopupPriceVendorComponent extends BaseComponentComponent {
  @Input() public quotationId: number;
  @Input() public isFinal: boolean;
  public popupPriceVendorResponse: PopupPriceVendorResponse;
  public header: string;
  public finalGoodsAmount = 0;
  public finalServiceAmount = 0;

  public tableResponseQuotationItem: TableResponseModel<QuotationItem>;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );

  public constructor() {
    super('price-evaluation-vendor');
  }
  public onInit(): void {
    this.buildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      itemType: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<PopupPriceVendorResponse>(
        '/price-evaluation-vendor/popup-price/' + this.quotationId
      )
      .subscribe(response => {
        this.header = this.isFinal
          ? 'price-evaluation-vendor.header.finalPrice'
          : 'price-evaluation-vendor.header.bidPrice';
        this.popupPriceVendorResponse = response;
        this.tableResponseQuotationItem.setRecordList(
          this.popupPriceVendorResponse.quotationItemList
        );
        if (this.isFinal) {
          this.popupPriceVendorResponse.quotationItemList.forEach(
            quotationItem => {
              if (
                quotationItem.procurementItem.prItem.item.itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ) {
                this.finalGoodsAmount += quotationItem.totalFinalPrice;
              } else if (
                quotationItem.procurementItem.prItem.item.itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
              ) {
                this.finalServiceAmount += quotationItem.totalFinalPrice;
              }
            }
          );
        }
        this.itemTypeOptionList.setRequestValues(
          this.popupPriceVendorResponse.itemTypeList
        );
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponseQuotationItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.prItemImageList',
        header: 'table.column.thumbnail',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): File => {
              if (tablePluginData.value && tablePluginData.value.length > 0) {
                return tablePluginData.value[0].file;
              }
              return null;
            }
          },
          {
            name: 'img',
            classNameMap: {
              MATERIAL: 'pvr pv-box',
              JASA: 'pvr pv-tools'
            },
            fieldClassName: 'procurementItem.prItem.item.itemType.code'
          }
        ],
        isSortable: false,
        isSearchTable: false,
        className: 'text-center'
      },
      {
        field: 'procurementItem.prItem.pr.requiredDate',
        header: 'table.column.requiredDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'procurementItem.prItem.item.code',
        header: 'table.column.itemCode'
      },
      {
        field: 'procurementItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'procurementItem.prItem.item.itemType.code'
        }
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'procurementItem.prItem.item.uom.name',
        header: 'table.column.unit'
      },
      this.isFinal
        ? {
            field: 'finalPrice',
            header: 'table.column.finalPrice',
            plugins: {
              name: 'default',
              type: 'currency'
            },
            className: 'text-right'
          }
        : {
            field: 'price',
            header: 'table.column.bidPrice',
            plugins: {
              name: 'default',
              type: 'currency'
            },
            className: 'text-right'
          },
      this.isFinal
        ? {
            field: 'totalFinalPrice',
            header: 'table.column.totalFinalPrice',
            plugins: {
              name: 'default',
              type: 'currency'
            },
            className: 'text-right'
          }
        : {
            field: 'totalPrice',
            header: 'table.column.totalBidPrice',
            plugins: {
              name: 'default',
              type: 'currency'
            },
            className: 'text-right'
          }
    ]);
  }

  public onChangeItem(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      const quotationItemList = [];
      this.popupPriceVendorResponse.quotationItemList.forEach(
        (quotationItem: QuotationItem) => {
          if (
            quotationItem.procurementItem.prItem &&
            quotationItem.procurementItem.prItem.item.itemType.id ===
              itemType.id
          ) {
            quotationItemList.push(quotationItem);
          }
        }
      );
      this.tableResponseQuotationItem.setRecordList(quotationItemList);
    } else {
      this.tableResponseQuotationItem.setRecordList(
        this.popupPriceVendorResponse.quotationItemList
      );
    }
    this.tableResponseQuotationItem.reload();
  }
}
