import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppChartModule } from '../app-chart/app-chart.module';
import { AppPlanComparisonComponent } from './app-plan-comparison.component';
@NgModule({
  imports: [VendorModule, AppCardModule, AppChartModule],
  declarations: [AppPlanComparisonComponent],
  exports: [AppPlanComparisonComponent]
})
export class AppPlanComparisonModule {}
