<div class="app-tooltip">
  <div class="app-tooltip-trigger" (mouseenter)="handleMouseEnter()">
    <ng-content></ng-content>
    <div class="app-tooltip-content" [attr.id]="generatedId">
      <div class="content-wrapper">
        <ng-content select=".tooltip-content"></ng-content>
        <ng-container *ngTemplateOutlet="tooltipContentTmpl"></ng-container>
      </div>
    </div>
  </div>
</div>
