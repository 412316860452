import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './contract-request.component.html'
})
export class ContractRequestComponent
  extends BaseModuleComponent
  implements OnInit {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Contract>;
  public filter: string;
  constructor(translateService: TranslateService) {
    super('contract-request', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildTableResponse();
    this.doSetCustomData();
    this.setStateReady();
    localStorage.removeItem('temp');
    localStorage.removeItem('todo');
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.object'
      },
      {
        field: 'requestDate',
        header: 'table.column.date',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'organization.name',
        header: 'table.column.organization',
        customClass: 'text-center'
      },
      {
        field: 'contractType.name',
        header: 'table.column.contractType',
        customClass: 'text-center'
      },
      {
        field: 'dealingAgreementType.name',
        header: 'table.column.dealingAgreementType',
        customClass: 'text-center'
      },
      {
        field: 'requestNumber',
        header: 'table.column.requestNumber',
        customClass: 'text-center'
      },
      {
        field: 'createdDate',
        header: 'table.column.date',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'number',
        header: 'table.column.contractNumber',
        customClass: 'text-center'
      },
      {
        field: 'startDate',
        header: 'table.column.startDateContract',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDateContract',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'vendor.name',
        header: 'table.column.vendor',
        customClass: 'white-space-normal'
      },
      {
        field: 'contractStatus.name',
        header: 'table.column.contractStatus',
        customClass: 'text-center'
      },
      {
        field: 'stage',
        header: 'table.column.stage',
        customClass: 'text-center'
      }
    ]);
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customFilter = {
        contractStatusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customFilter);
      this.tableResponse.reload();
    }
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('urlBackOutside', '/pages/contract-request');
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/contract-request/add']);
  }

  public doEdit(contract: Contract): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('contractId', contract.id);
    this.global.routerParams.set('urlBackOutside', '/pages/contract-request');
    this.router.navigate(['/pages/contract-request/edit']);
  }
  public doDetail(contract: Contract): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('contractId', contract.id);
    this.global.routerParams.set('urlBackOutside', '/pages/contract-request');
    this.global.routerParams.set('isCanceled', contract.isCanceled);
    this.router.navigate(['/pages/contract-request/detail']);
  }
  public doCancel(contract: Contract): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'cancel');
    this.global.routerParams.set('urlBackOutside', '/pages/contract-request');
    this.global.routerParams.set('contractId', contract.id);
    this.router.navigate(['/pages/contract-request/cancel']);
  }
}
