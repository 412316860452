import { DueDiligenceAssigner } from 'src/app/core/bean/due-diligence-assigner';
import { DueDiligenceExecutor } from 'src/app/core/bean/due-diligence-executor';
import { DueDiligenceSchedule } from 'src/app/core/bean/due-diligence-schedule';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { VendorHistory } from 'src/app/core/bean/vendor-history';
import { VendorVerification } from 'src/app/core/bean/vendor-verification';

export class DueDiligenceResponse {
    vendorHistory: VendorHistory = new VendorHistory();
    vendorVerification: VendorVerification = new VendorVerification();
    isUserApproval: boolean;

    isTemplateDifferent: boolean;
    isPreviousDueDiligenceExist: boolean;
    vendorAddress: VendorAddress = new VendorAddress();
    dueDiligenceScheduleOTS: DueDiligenceSchedule = new DueDiligenceSchedule();
    dueDiligenceScheduleInterview: DueDiligenceSchedule = new DueDiligenceSchedule();
    dueDiligenceExecutorOTSList: DueDiligenceExecutor[] = [];
    dueDiligenceExecutorInterviewList: DueDiligenceExecutor[] = [];
    dueDiligenceAssignerTaskGiverList: DueDiligenceAssigner[] = [];
    dueDiligenceAssignerAcknowledgedByList: DueDiligenceAssigner[] = [];
}
