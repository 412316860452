import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FileType } from '../../core/bean/file-type';
import { Vendor } from '../../core/bean/vendor';
import { VendorType } from '../../core/bean/vendor-type';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
@Component({
  templateUrl: './vendor-expired-document.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class VendorExpiredDocumentComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Vendor>;

  public vendorTypeList: OptionListModel<VendorType> = new OptionListModel(
    true
  );
  public docList: OptionListModel<FileType> = new OptionListModel(
    true,
    this.global.currentLang === 'id'
      ? 'translationKey.translationList.0.value'
      : 'translationKey.translationList.1.value',
    'code',
    'id'
  );

  constructor(translateService: TranslateService) {
    super('vendor-expired-document', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setVendorTypeList();
    this.setFileTypeList();
    this.buildFormGroup();
    this.setStateReady();
  }

  public setVendorTypeList(): void {
    this.httpClientService
      .get<VendorType[]>('/vendor-type/vendor-type-list')
      .subscribe((vendorTypeList: VendorType[]) => {
        this.vendorTypeList.setRequestValues(vendorTypeList);
      });
  }

  public setFileTypeList(): void {
    this.httpClientService
      .get<FileType[]>('/document-type/file-type-list')
      .subscribe(docList => {
        this.docList.setRequestValues(docList);
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.col.vendorName',
        customClass: 'text-left'
      },
      {
        field: 'code',
        header: 'table.col.vendorCode',
        customClass: 'text-center'
      },
      {
        field: 'vendorType.name',
        header: 'table.col.vendorType',
        customClass: 'text-center'
      },
      { field: 'email', header: 'table.col.email' },
      {
        field: 'vendorStatus.name',
        header: 'table.col.vendorStatus',
        customClass: 'text-center'
      }
    ]);
  }

  public doViewDetail(vendor: Vendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('vendorId', vendor.id);
    this.global.routerParams.set('name', vendor.name);
    this.global.routerParams.set('code', vendor.code);
    this.router.navigate(['/pages/vendor-expired-document/detail']);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorName: [null],
      vendorType: [null],
      docList: [null]
    });
  }

  public doApply(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }
}
