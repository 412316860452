import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppPopupModule } from '../../components/app-popup/app-popup.module';
import { AppTableModule } from '../../components/app-table/app-table.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppPopupWidgetPaymentMonitoringComponent } from './app-popup-widget-payment-monitoring.component';
import { AppWidgetPaymentMonitoringComponent } from './app-widget-payment-monitoring.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, AppWidgetContentSliderModule, AppTableModule, AppPopupModule],
  declarations: [AppWidgetPaymentMonitoringComponent, AppPopupWidgetPaymentMonitoringComponent],
  entryComponents: [AppWidgetPaymentMonitoringComponent, AppPopupWidgetPaymentMonitoringComponent],
  exports: [AppWidgetPaymentMonitoringComponent]
})
export class AppWidgetPaymentMonitoringModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-payment-monitoring', AppWidgetPaymentMonitoringComponent);
  }
}
