import { BaseEntity } from '../base/base-entity';
import { CommitteeType } from '../bean/committee-type';
import { Organization } from '../bean/organization';
import { User } from '../bean/user';

export class CommitteeEntity extends BaseEntity {
  committeeType: CommitteeType = new CommitteeType();
  organization: Organization = new Organization();
  picUser: User = new User();
  name: string;
  description: string;
}
