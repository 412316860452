import { Component, Input, EventEmitter } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { WebSocketResponseModel } from 'src/app/core/model/websocket-response-model';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './app-popup-sync-employee-data.component.html'
})
export class AppPopupSyncEmployeeDataComponent
    extends BaseComponentComponent {
    @Input() isLoadingSync: boolean;
    @Input() webSocketResponseModel: WebSocketResponseModel;
    @Input() onCloseModal: EventEmitter<boolean>;
    public isUser: boolean;
    public isSyncButtonClicked = false;

    constructor(
        public translateService: TranslateService,
        public activeModal: NgbActiveModal
    ) {
        super('employee-data');
    }

    onInit(): void {
        this.onCloseModal.subscribe(() => this.activeModal.close());
        this.setStateReady();
    }

    public doSyncronize(): void {
        /*this.httpClientService
            .post<EmployeeData>('/employee-data/synchronize', null)
            .subscribe(() => {
                this.activeModal.close();
            });*/
        this.doGenerateSync();
        this.isLoadingSync = false;
    }

    public doGenerateSync(): void {
        this.isSyncButtonClicked = true;
        this.httpClientService
            .post<Response>('/employee-data/bjb-gift-sync', {})
            .subscribe();
    }

    public doCancel(): void {
        if (
            this.webSocketResponseModel &&
            this.webSocketResponseModel.progressValue
        ) {
            this.httpClientService
                .post<Response>('/employee-data/cancel-bjb-gift-sync', {})
                .subscribe();
            this.activeModal.dismiss();
        }
    }
}
