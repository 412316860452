import { createDatePickerDateList } from '../helper/create-date-picker-date-list-helper';
import { createTotalSelectedDate } from '../helper/create-total-selected-date-helper';
import { DateRangeValue } from '../interface/date-range-value';
import { DatePickerDate, DatePickerState } from '../model';
export const setDatePickerDate = (
  state: DatePickerState,
  payload: { datePickerDate: DatePickerDate }
): void => {
  state.currentDate = payload.datePickerDate;
  state.model.range
    ? setDatePickerRangeValue(state, payload.datePickerDate)
    : setDatePickerValue(state, payload.datePickerDate);
};

const setDatePickerValue = (
  state: DatePickerState,
  datePickerDate: DatePickerDate
): void => {
  if (datePickerDate.monthPosition !== 'CURRENT') {
    state.dateList = createDatePickerDateList(datePickerDate.date);
  }
  state.currentDate = datePickerDate;
  state.currentDateValue = datePickerDate;
  if (state.model.time && Object.keys(state.currentTime).length > 0) {
    state.currentDate.date.setHours(
      +state.currentTime.hoursString,
      +state.currentTime.minutesString,
      0
    );
    state.setValue(state.currentDate.date);
  } else if (!state.model.time) {
    state.setValue(state.currentDate.date);
  }
};

const setDatePickerRangeValue = (
  state: DatePickerState,
  datePickerDate: DatePickerDate
): void => {
  if (!state.currentDateRange.start) {
    state.currentDateRange.start = datePickerDate;
  } else if (state.currentDateRange.start && !state.currentDateRange.end) {
    const isStartDateGreaterThanEndDate =
      state.currentDateRange.start.dateString > datePickerDate.dateString;
    if (isStartDateGreaterThanEndDate) {
      state.currentDateRange.end = state.currentDateRange.start;
      state.currentDateRange.start = datePickerDate;
    } else {
      state.currentDateRange.end = datePickerDate;
    }
    state.setValue({
      from: new Date(state.currentDateRange.start.date),
      to: new Date(state.currentDateRange.end.date)
    } as DateRangeValue);
    state.currentDateRange.totalSelectedDate = createTotalSelectedDate(
      state.currentDateRange.start,
      state.currentDateRange.end
    );
    state.currentDateRange.on = null;
  } else {
    state.currentDateRange.start = datePickerDate;
    state.currentDateRange.end = null;
  }
};
