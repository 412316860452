import { Module } from 'src/app/core/bean/module';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { File } from '../../core/bean/file';
import { ItemType } from '../../core/bean/item-type';
import { ProposalCriteria } from '../../core/bean/proposal-criteria';
import { Rfp } from '../../core/bean/rfp';
import { RfpCancellation } from '../../core/bean/rfp-cancellation';
import { RfpProposalCriteria } from '../../core/bean/rfp-proposal-criteria';
import { RfpVendorScoring } from '../../core/bean/rfp-vendor-scoring';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path-x';
import { RfpVendorView } from '../../core/view/entity/bean/rfp-vendor-view';

export class RfpResponse {
  isUserApproval: boolean;
  rfp: Rfp = new Rfp();
  module: Module = new Module();
  rfpCancellation: RfpCancellation = new RfpCancellation();
  approvalPathResponseModel: ApprovalPathResponseModel =
    new ApprovalPathResponseModel();
  fileList: File[] = [];
  prItemList: PrItem[] = [];
  itemTypeList: ItemType[] = [];
  vendorViewList: PopupVendorView[] = [];
  rfpVendorViewList: RfpVendorView[] = [];
  proposalCriteriaList: ProposalCriteria[] = [];
  rfpVendorScoringList: RfpVendorScoring[] = [];
  rfpProposalCriteriaList: RfpProposalCriteria[] = [];
}
