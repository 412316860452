import { Catalog } from 'src/app/core/bean/catalog';
import { Currency } from 'src/app/core/bean/currency';
import { ItemType } from 'src/app/core/bean/item-type';
import { Module } from 'src/app/core/bean/module';
import { Pr } from 'src/app/core/bean/pr';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrShipping } from 'src/app/core/bean/pr-shipping';
import { PrStatus } from 'src/app/core/bean/pr-status';
import { PrVerification } from 'src/app/core/bean/pr-verification';
import { User } from 'src/app/core/bean/user';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { WorkflowStepInfoModel } from 'src/app/core/components/app-workflow-step-info/model/workflow-step-info-model';
import { VendorView } from 'src/app/core/view/entity/bean/vendor-view';
import { AppUploadDocumentTableModel } from '../../core/components/app-upload-document-table-x/app-upload-document-table-model';

export class PrVerificationResponse {
  prVerification: PrVerification = new PrVerification();
  pr: Pr = new Pr();
  companyCurrency: Currency;
  prShippingList: PrShipping[] = [];
  vendorViewList: VendorView[] = [];
  prItemList: PrItem[] = [];
  prStatusList: PrStatus[] = [];
  itemTypeList: ItemType[] = [];
  requestor: User;
  module: Module;
  workflowStepInfoModel: WorkflowStepInfoModel;
  appUploadDocumentTableModelList: AppUploadDocumentTableModel[] = [];
  appOfficialReportModel: AppOfficialReportModel = new AppOfficialReportModel();
  isUserApproval: boolean;
  catalogList: Catalog[] = [];
}
