import { BaseEntity } from '../base/base-entity';
import { Contract } from '../bean/contract';
import { Module } from '../bean/module';
import { User } from '../bean/user';

export class ContractCancelationEntity extends BaseEntity {
  contract: Contract = new Contract();
  module: Module;
  cancelByUser: User;
  cancelDate: Date;
  statusHistory: string;
  note: string;
}
