<div class="alert-top lmb-2">
  <div class="alert-top-content">
    <div
      class="alert alert-dismissible show pr-0"
      role="alert"
      [ngClass]="color"
    >
      <div
        class="alert-message"
        style="margin: 0 2rem 0"
        [ngStyle]="isBold ? { 'font-weight': '600' } : ''"
      >
        <em class="fas mr-2" [ngClass]="isShowIcon ? icon : ''"></em>
        <ng-content></ng-content>
      </div>
      <div class="button-close" *ngIf="isShowCloseButton">
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          ><em class="pir pi-times"></em>
        </button>
      </div>
    </div>
  </div>
</div>
