import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { CostCenter } from './../../core/bean/cost-center';
import { WebSocketBudgetService } from './../budget-allocation-bjb/websocket-budget.service';
import { AppPopupSyncCostCenterComponent } from './app-popup-sync-cost-center.component';
import { WebSocketCostCenterService } from './websocket-cost-center.service';
@Component({
  templateUrl: './cost-center.component.html',
  providers: [
    { provide: WebSocketBudgetService },
    { provide: WebSocketCostCenterService },
    { provide: AppPopupService }
  ]
})
export class CostCenterComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<CostCenter>;
  public isSyncRunning = false;

  constructor(
    translateService: TranslateService,
    public websocketBudgetService: WebSocketBudgetService,
    public appPopupService: AppPopupService,
    public websocketCostCenterService: WebSocketCostCenterService
  ) {
    super('cost-center', translateService);
  }

  public onInit(): void {
    this.initializeWebSocketConnection();
    this.initializeWebSocketSyncCostCenterConnection();
    this.isSynchronizeRunning();
  }

  public isSynchronizeRunning(): void {
    this.httpClientService
      .get<Boolean>(
        '/budget-allocation-bjb/is-budget-synchronize-running/onlyBudget'
      )
      .subscribe((isSynchronizeRunning: boolean) => {
        this.isSyncRunning = isSynchronizeRunning;
        this.setStateReady();
        if (!this.isSyncRunning) {
          this.buildTableResponse();
        }
      });
  }

  public initializeWebSocketSyncCostCenterConnection(): void {
    this.websocketCostCenterService.connect().then(result => {
      if (!this.isSyncRunning && result) {
        this.tableResponse.reload();
      } else if (this.isSyncRunning) {
        this.isSyncRunning = false;
        this.buildTableResponse();
      }
      this.websocketCostCenterService.disconnect();
      this.initializeWebSocketSyncCostCenterConnection();
    });
  }

  public initializeWebSocketConnection(): void {
    this.websocketBudgetService.connect().then(result => {
      if (!this.isSyncRunning && result) {
        this.tableResponse.reload();
      } else if (this.isSyncRunning) {
        this.isSyncRunning = false;
        this.buildTableResponse();
      }
      this.websocketBudgetService.disconnect();
      this.initializeWebSocketConnection();
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code'
      },
      { field: 'name', header: 'table.column.name' }
    ]);
    this.setStateReady();
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/cost-center/add']);
  }

  public doEdit(costCenter: CostCenter): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('costCenterId', costCenter.id);
    this.router.navigate(['/pages/cost-center/edit']);
  }

  public doDelete(costCenter: CostCenter): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<CostCenter>>('/cost-center/delete', costCenter)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public doSync(): void {
    this.appPopupService
      .open(
        AppPopupSyncCostCenterComponent,
        { onCloseModal: this.websocketCostCenterService.onCloseModal },
        {
          backdrop: 'static',
          size: 'lg'
          // container: '#work-program',
          // backdropClass: 'work-program-backdrop',
          // windowClass: 'work-program-window'
        }
      )
      .subscribe();
    this.websocketCostCenterService.appPopupSyncCostCenterComponent =
      this.appPopupService.componentInstance;
  }

  public doImport(): void {}
}
