import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { BaseModuleComponent } from '../core/base/angular/base-module.component';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PagesComponent extends BaseModuleComponent {
  public showMenu = false;
  public showSetting = false;
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption: string;
  public settings: Settings;
  constructor(
    translateService: TranslateService,
    public appSettings: AppSettings,
    public router: Router
  ) {
    super('pages', translateService);
    this.settings = this.appSettings.settings;
    if (sessionStorage.skin) {
      this.settings.theme.skin = sessionStorage.skin;
    }
  }

  public onInit(): void {
    if (window.innerWidth <= 768) {
      this.settings.theme.showMenu = false;
    }
    this.showMenu = this.settings.theme.showMenu;
    this.menuOption = this.settings.theme.menu;
    this.menuTypeOption = this.settings.theme.menuType;
  }

  public chooseMenu(menu: any): void {
    this.settings.theme.menu = menu;
    this.router.navigate(['/']);
  }

  public chooseMenuType(menuType): void {
    this.settings.theme.menuType = menuType;
    if (menuType === 'mini') {
      jQuery('.menu-item-link').tooltip('enable');
    } else {
      jQuery('.menu-item-link').tooltip('disable');
    }
  }

  public changeTheme(theme): void {
    this.settings.theme.skin = theme;
    sessionStorage.skin = theme;
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    const showMenu = !this._showMenu();

    if (this.showMenu !== showMenu) {
      this.showMenuStateChange(showMenu);
    }
    this.showMenu = showMenu;
  }

  public showMenuStateChange(showMenu: boolean): void {
    this.settings.theme.showMenu = showMenu;
  }

  public _showMenu(): boolean {
    return window.innerWidth <= 768;
  }
}
