<div class="app-binary-tree vertical">
  <div class="app-binary-tree-main-wrapper">
    <div
      class="app-binary-tree-content-wrapper"
      [ngStyle]="{ display: 'inline-flex' }"
      #contentWrapper
    >
      <ng-container
        *ngTemplateOutlet="ulTreeTemplate; context: { $implicit: treeItemList }"
      ></ng-container>
    </div>
  </div>
  <div class="app-binary-tree-controls">
    <span class="zoom-percentage">{{ zoomPercentage }}%</span>
    <div class="zoom-button">
      <em class="pir pi-minus" (click)="doZoom('out')"></em>
      <em class="pir pi-plus" (click)="doZoom('in')"></em>
    </div>
    <app-button size="SM" [outline]="true" (onClick)="doReset()">
      Reset
    </app-button>
  </div>
</div>

<ng-template #ulTreeTemplate let-itemList let-parentId="parentId">
  <ul class="tree" [ngStyle]="{ margin: 'auto' }">
    <li class="tree-item" *ngFor="let item of itemList">
      <div class="item-content">
        <ng-container
          *ngTemplateOutlet="
            itemContentTemplate || itemDefaultContentDefaultTemplate;
            context: { $implicit: item }
          "
        ></ng-container>
      </div>
      <ng-container *ngIf="item.childrens && item.childrens.length > 0">
        <ng-container
          *ngTemplateOutlet="
            ulTreeTemplate;
            context: { $implicit: item.childrens }
          "
        ></ng-container>
      </ng-container>
    </li>
  </ul>
</ng-template>

<ng-template #itemDefaultContentDefaultTemplate let-item>
  <div class="item-box">
    {{ item.name }}
  </div>
</ng-template>
