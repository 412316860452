import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  Optional,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { TextUtils } from '../../utils';
@Component({
  selector: 'app-check-box',
  templateUrl: './app-check-box.component.html',
  styleUrls: ['./app-check-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: makeProvider(AppCheckBoxComponent)
})
export class AppCheckBoxComponent extends BaseValueAccessorComponent<any> {
  @Input() public indeterminate: boolean;
  @Input() private position = 'VERTICAL';
  @ContentChild('content') public contentTmpl: TemplateRef<any>;

  public generatedId: string;
  public generatedFormGroup: FormGroup;
  public arrayPosition: Array<string>;
  public positionClassName: string;
  public totalChecked = 0;
  public viewValue: Array<any>;
  public isMultipleCheckBox = false;
  public isInlineCheckBox = false;

  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-check-box', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setInitializationState();
    this.ISVIEW ? this.setViewValue() : this.setPosition();
    if (!this.ISVIEW) {
      this.setPosition();
    }
  }

  private setInitializationState(): void {
    this.viewValue = [];
    this.generatedFormGroup = new FormGroup({});
    this.arrayPosition = ['VERTICAL', 'HORIZONTAL'];
    this.generatedId = TextUtils.generateRandomString();
  }

  public setViewValue(): void {
    if (this.formControl.value || this.formControl.value === false) {
      if (Array.isArray(this.formControl.value)) {
        this.viewValue = this.formControl.value.filter(
          (value: any) => value.isChecked
        );
        this.totalChecked = this.viewValue.length;
        this.isMultipleCheckBox = true;
      } else {
        this.viewValue.push(this.formControl.value);
      }
    } else {
      this.formControl.valueChanges.subscribe(values => {
        if (Array.isArray(values)) {
          this.viewValue = values.filter((value: any) => value.isChecked);
          this.totalChecked = this.viewValue.length;
          this.isMultipleCheckBox = true;
        } else {
          this.viewValue.push(this.formControl.value);
        }
      });
    }
  }

  public handleChange(event: any, value: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.formControl.patchValue(value);
    this.onChange.emit(value);
  }

  public setPosition(): void {
    const indexOfPosition = this.arrayPosition.indexOf(
      this.position.toUpperCase()
    );
    if (indexOfPosition === -1) {
      throw new Error(`${this.position} is not position of 'position'`);
    }
    this.setPositionClassName();
  }

  public setPositionClassName(): void {
    if (this.position === 'HORIZONTAL') {
      this.positionClassName = 'custom-control-inline';
      this.isInlineCheckBox = true;
    }
  }
}
