import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { TemplateGroup } from '../bean/template-group';

export class ContractTemplateEntity extends BaseEntity {
    name: string;
    description: string;
    file: File;
    templateGroup: TemplateGroup;
    listDealingAggrTypeCode: string;
}
