import { state, style, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  expandCollapseAnimation,
  rotateAnimation
} from '../../../../../core/animation';
@Component({
  selector: 'dashboard-widget-card',
  templateUrl: './widget-card.component.html',
  animations: [
    expandCollapseAnimation('0', {
      opacity: 1,
      visibility: 'visible',
      marginTop: 0,
      marginBottom: 0
    }),
    rotateAnimation(),
    trigger('changeHeaderHeight', [
      state('default', style({})),
      state(
        'height',
        style({
          height: 'auto'
        })
      )
    ])
  ]
})
export class WidgetCardComponent implements AfterViewInit, OnChanges {
  @Input() isLoading: boolean;
  @Input() header: string;
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() headerSize: 'sm' | 'md' | 'lg' = 'md';
  @Input() isShowButtonExpandCollapse: boolean;
  @Input() isExpanded = true;
  @Input() customClass: string;
  @Input() customClassHeader: string;
  @Input() customClassBody: string;
  @Input() customClassFooter: string;

  @Output() onClick: EventEmitter<PointerEvent> = new EventEmitter();

  @ContentChild('headerLeft') headerLeftTmpl: TemplateRef<any>;
  @ContentChild('header') headerTmpl: TemplateRef<any>;
  @ContentChild('headerRight') headerRightTmpl: TemplateRef<any>;
  @ContentChild('customHeader') customHeaderTmpl: TemplateRef<any>;
  @ContentChild('footer') footerTmpl: TemplateRef<any>;

  @ViewChild('cardBody') cardBodyElementRef: ElementRef;

  ngAfterViewInit(): void {
    if (!this.isLoading) {
      this.addCardBodyPadding();
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (
      simpleChanges.isLoading &&
      !simpleChanges.isLoading.firstChange &&
      !simpleChanges.isLoading
    ) {
      this.addCardBodyPadding();
    }
  }

  private addCardBodyPadding(): void {
    setTimeout(() => {
      const cardBodyElement = this.cardBodyElementRef
        .nativeElement as HTMLElement;
      if (cardBodyElement) {
        cardBodyElement.scrollHeight > cardBodyElement.offsetHeight
          ? cardBodyElement.classList.add('pr-4')
          : cardBodyElement.classList.remove('pr-4');
      }
    });
  }
}
