import { BaseEntity } from '../base/base-entity';
import { Payment } from '../bean/payment';

export class PaymentVoucherEntity extends BaseEntity {
  public payment: Payment;
  public totalTaxBaseAmount: number;
  public totalVatAmount: number;
  public totalIncomeTaxAmount: number;
  public totalOtherCostAmount: number;
  public totalVoucherAmount: number;
  public voucherDate: Date;
  public sayVoucherAmount: string;
  public isIncludeVat: boolean;
}
